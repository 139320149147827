<div *ngIf="showMe" class="holaDiv" [ngClass]="{includeFooter: isNotBmi && (preparadoFirma || preparadoFirmaCI || preparadoFirmaFI || loadingCustom)}">
  
  <div *ngIf="isNotBmi" class="fixed-wrapper">
    <header-profile></header-profile> 
  </div> 
  
  <!-- Mensaje INFO ALTAVOZ -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="limiteDisminucion">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡INFORMACIÓN IMPORTANTE!</div>
        <img class="cmessage__imagenB--sm" src="{{imgAltavoz}}">
        <div class="cmessage__subtitulo--list">
            <p class="cmessage__subtitulo--list__description2"><span class="semibold">Si quieres reducir tu límite de uso temporalmente</span>,
              manteniendo tu límite de crédito actual llama al <span class="semibold">910 900 900</span>. Así
              podrás cambiarlo cuando quieras con efecto inmediato. Debes saber que:</p>
            <ul class="cmessage__list">
              <li class="cmessage__list__item_2">
                Al solicitar ampliar tu límite, tendremos que evaluar de nuevo tu petición.
              </li>
              <li class="cmessage__list__item_2">
                El importe que tengas pendiente de pago en tu tarjeta, se cargará al mes siguiente con independencia de tu
                forma de pago.
              </li>
            </ul>
          </div>
      </div>
      <div class="btn-group_fix_2">
        <button class="btn-common btn-common_fix btn-common_fix_bold btn-common_small" (click)="mantenerSolicitud()">
          MANTENER MI LIMITE
        </button>
        <button class="btn-common btn-secondary_fix--white btn-common_fix_bold btn-common_small" (click)="continuarSolicitud()">
          Continuar
        </button>
      </div>
    </div>
  </div>
  <!-- Mensaje INFO ALTAVOZ -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="limiteCero">
    <div class="cmessage__container2">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡INFORMACIÓN IMPORTANTE!</div>
          <img class="cmessage__imagenB--sm" src="{{imgAltavoz}}">
          <div class="cmessage__subtitulo--list">
              <p class="cmessage__subtitulo--list__description2"><span class="semibold">Si quieres reducir tu límite de uso temporalmente</span>,
                manteniendo tu límite de crédito actual llama al <span class="semibold">910 900 900</span>. Así
                podrás cambiarlo cuando quieras con efecto inmediato. Debes saber que:</p>
              <ul class="cmessage__list">
                <li class="cmessage__list__item_2">
                  Al solicitar ampliar tu límite, tendremos que evaluar de nuevo tu petición.
                </li>
                <li class="cmessage__list__item_2">
                  El importe que tengas pendiente de pago en tu tarjeta, se cargará al mes siguiente con independencia de tu
                  forma de pago.
                </li>
              </ul>
            </div>
      </div>
      <div class="btn-group_fix_2">
        <button class="btn-common btn-common_fix btn-common_fix_bold btn-common_small" (click)="mantenerSolicitud()">
          MANTENER MI LIMITE
        </button>
        <button class="btn-common btn-secondary_fix--white btn-common_fix_bold btn-common_small" (click)="continuarSolicitud()">
          Continuar
        </button>
      </div>
    </div>
  </div>
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="modificarDisminucion">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡INFORMACIÓN IMPORTANTE!</div>
        <img class="cmessage__imagenB--sm" src="{{imgAltavoz}}">
        <div class="cmessage__subtitulo--list">
            <p class="cmessage__subtitulo--list__description2"><span class="semibold">Si quieres reducir tu límite de uso temporalmente</span>,
              manteniendo tu límite de crédito actual llama al <span class="semibold">910 900 900</span>. Así
              podrás cambiarlo cuando quieras con efecto inmediato. Debes saber que:</p>
            <ul class="cmessage__list">
              <li class="cmessage__list__item_2">
                Al solicitar ampliar tu límite, tendremos que evaluar de nuevo tu petición.
              </li>
              <li class="cmessage__list__item_2">
                El importe que tengas pendiente de pago en tu tarjeta, se cargará al mes siguiente con independencia de tu
                forma de pago.
              </li>
            </ul>
          </div>
      </div>
      <div class="btn-group_fix_2">
        <button class="btn-common btn-common_fix btn-common_fix_bold btn-common_small" (click)="mantenerSolicitud()">
          MANTENER MI LIMITE
        </button>
        <button class="btn-common btn-secondary_fix--white btn-common_fix_bold btn-common_small" (click)="continuarSolicitud()">
          Continuar
        </button>
      </div>
    </div>
  </div>
  <!-- Mensaje INFO ALTAVOZ -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="limiteCancelar">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡INFORMACIÓN IMPORTANTE!</div>
        <img class="cmessage__imagen--sm" src="{{imgAltavoz}}">
        <div class="cmessage__subtitulo--list">
          <p class="cmessage__subtitulo--list__description">¿Estas seguro que quieres cancelar la modificación
            solicitada? </p>
          <ul class="cmessage__list">
            <li class="cmessage__list__item">
              Si cancelas la modificación, cualquier nueva solicitud deberá ser evaluada de nuevo
            </li>
          </ul>
        </div>
      </div>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="mantenerSolicitud()">
          MANTENER SOLICITUD
        </button>
        <button class="btn-common btn-secondary_fix--white btn-common_fix_bold" (click)="continuarSolicitud()">
          Cancelar solicitud
        </button>
      </div>
    </div>
  </div>

  <!-- Mensaje VAYA OVNI -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="faltaInformacion">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo_2">
          ¡VAYA!</div>
        <img class="cmessage__imagen" src="{{imgVayaVaya}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Hemos visto que nos falta información. Llámanos para que te podamos ayudar a continuar tu contratación. </p>
        </div>
      </div>
      <div class="btn-group_fix" *ngIf="isNotBmi">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="entendido()">Entendido</button>
      </div>
    </div>
  </div>

  <!-- Mensaje ENVIADA LA COMUNICACIÓN -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="revisandoDocumentacion">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
        <img class="cmessage__imagen" src="{{imgAstroboy}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Estamos revisando tu documentación, cuando esté todo listo te avisaremos para que puedas continuar con tu contratación.</p>
        </div>
      </div>
      <div class="btn-group_fix" *ngIf="isNotBmi">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold"  (click)="entendido()">Entendido</button>
      </div>
    </div>
  </div>
  <!-- Mensaje ENVIADA LA COMUNICACIÓN -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="astronauta">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
        <img class="cmessage__imagen" src="{{imgAstroboy}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Algo no nos cuadra, tenemos que revisar tu solicitud. Nos pondremos
            en contacto contigo por email lo antes posible.</p>
        </div>
      </div>
      <div class="btn-group_fix" *ngIf="isNotBmi">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="entendido()">Entendido</button>
      </div>
    </div>
  </div>

  <!-- Mensaje IMPREVISTO MALETIN -->
  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="imprevistoMorosidad">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡HA SURGIDO UN IMPREVISTO!</div>
        <img class="cmessage__imagen" src="{{imgMaletin}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Lo sentimos, no hemos podido completar tu solicitud. Hemos
            detectado que tus datos aparecen en un fichero de
            morosidad.</p>
          <p class="cmessage__subtitulo__description">Si quieres conocer todos los detalles o crees que hay algún error,
            puedes ponerte en contacto con Experian Bureau de Crédito en el teléfono  91 625 89
              11 y te informaremos de todo.
              En caso de ser informado por Equifax tienes que dirigirte al teléfono 902 12 60 00 opción 1, o a través del correo SAC@equifax.es.
              O bien accediendo a través de la web www.equifax.es , en la opción E-DERECHOS, puede encontrar información más detallada y gratuita.</p>
        </div>
      </div>
      <div class="btn-group_fix" *ngIf="isNotBmi">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="entendido()">Entendido</button>
      </div>
    </div>
  </div>

  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="imprevistoRiesgos">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡VAYA!</div>
        <img class="cmessage__imagen" src="{{imgSecuestro}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Lo sentimos, 
            no podemos seguir con la solicitud online.
             Llámanos al 910 900 900 y te explicamos los motivos.</p>
        </div>
      </div>
      <div class="btn-group_fix" *ngIf="isNotBmi">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="entendido()">Entendido</button>
      </div>
    </div>
  </div>

  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="preparadoFirma">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          PREPARADO PARA FIRMAR</div>
        <img class="cmessage__imagen" src="{{imgLapiz}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Te estamos redirigiendo a Logalty, nuestro tercero de confianza
            para realizar la firma de la ampliación
            del límite de crédito de tu tarjeta de forma totalmente segura.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="backFromLogalty">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
         REDIRIGIENDO</div>
        <img class="cmessage__imagen" src="{{imgLapiz}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">El proceso de firma se ha completado. Te estamos redirigiendo a la web. Por favor espera.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="preparadoFirmaCI">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          PREPARADO PARA FIRMAR</div>
        <img class="cmessage__imagen" src="{{imgLapiz}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Te estamos redirigiendo a Logalty, nuestro tercero de confianza
            para realizar la firma del contrato de tu Cuenta Inversora de forma totalmente segura.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="preparadoFirmaFI">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          PREPARADO PARA FIRMAR</div>
        <img class="cmessage__imagen" src="{{imgLapiz}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Te estamos redirigiendo a Logalty, nuestro tercero de confianza
            para realizar la firma del contrato de tu Fondo Inteligente de forma totalmente segura.</p>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'loading-custom-bmi' : !isNotBmi, 'loading-custom' : isNotBmi}"  [ngClass]="{includePretitle: mostrarPretitulo}" *ngIf="loadingCustom">
    <div class="inner">
      <div class="container-center">
        <div class="titulo">
          <h1 *ngIf="mostrarPretitulo">{{pretitulo}}</h1>
          <h2>{{titulo}}</h2>
          <img class="imagen" src="{{imagen}}">
          <div class="container-info">
            <div class="subtitulo">
              <h3>
                <span>{{subtitulo}}</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="mobile-container btn-group">
            <div class="btn-common btn-common_fix_bold">BUTTON</div>
          </div>
      </div>
    </div>
  </div>

  <!-- Mensaje AMIGOS -->
<div class="cmessage" [ngClass]="isNotBmi? 'cmessage-nofooter': 'cmessage-bmi'" *ngIf="activarSegundoTitular">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HORA DE HABLAR CON LOS AMIGOS!</div>
      <img class="cmessage__imagen" src="{{imgAmigosOk}}">
      <div class="cmessage__subtitulo">
        <p *ngIf="isInversora" class="cmessage__subtitulo__description">Hemos enviado un email al segundo titular. En cuanto {{nombreSegundoTitular}} lo haya firmado, te enviaremos un email a {{mailCliente}} para que puedas firmarlo tú y abrir tu cuenta de {{textProduct}}</p>
        <p *ngIf="!isInversora" class="cmessage__subtitulo__description">Hemos enviado un email al segundo titular. En cuanto {{nombreSegundoTitular}} lo haya firmado, te enviaremos un email a {{mailCliente}} para que puedas firmarlo tú y abrir tu {{textProduct}}</p>
      </div>
    </div>
    <div class="btn-group_fix" *ngIf="isNotBmi">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold btn-margin-btm" (click)="entendido()">Entendido</button>
    </div>
  </div>
</div>

<!-- Mensaje AMIGOS FINAL SEGUNDO TITULAR-->
<div class="cmessage" [ngClass]="isNotBmi? '': 'cmessage-bmi'" *ngIf="preparadoFirmaST">
  <div class="cmessage__container_header">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HORA DE HABLAR CON LOS AMIGOS!</div>
      <img class="cmessage__imagen" src="{{imgAmigosOk}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos enviado un email al titular {{nameFirstHolder}} para que firme la contratación de la cuenta.</p>
        <p class="cmessage__subtitulo__description">En cuanto firme te enviaremos un email a {{emailSecondHolder}} felicitandote por la contratación de tu {{textProduct}}.</p>
      </div>
    </div>
    <div class="btn-group_fix" *ngIf="isNotBmi">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="entendido()">Entendido</button>
    </div>
  </div>
</div>

<!-- Mensaje AMIGOS ESPERA FIRMA SEGUNDO TITULAR CUENTA INVERSORA-->
<div class="cmessage" [ngClass]="isNotBmi? 'cmessage-nofooter': 'cmessage-bmi'" *ngIf="esperaFirmaST">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HORA DE HABLAR CON LOS AMIGOS!</div>
      <img class="cmessage__imagen" src="{{imgAmigosOk}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Seguimos esperando a que firme el segundo titular para que puedas continuar la contratación de tu {{textProduct}}.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold btn-margin-btm" (click)="entendido()">Entendido</button>
    </div>
  </div>
</div>

<app-footer-center *ngIf="isNotBmi && (preparadoFirma || preparadoFirmaCI || preparadoFirmaFI || loadingCustom)"></app-footer-center>
</div>
