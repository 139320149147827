import { BySideCarService } from 'app/services/bysidecar.service';
import { ActivatedRoute, Params } from '@angular/router';
import {Component, OnInit, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { FormValidatorService } from '../../../services/form-validator.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import {FormBuilder,FormGroup, Validators} from '@angular/forms';
import { HelperService } from 'app/services/helpers.service';
import { IberpayService } from 'app/services/iberpay.service';
import { AuthService } from 'app/services/auth.service';
import { ErrorService } from 'app/services/error.service';
import { ConstantsService } from 'app/services/constants.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { InactivityService } from 'app/service/inactivity.service';


@Component({
  selector: 'identify-iban-import',
  templateUrl: './identify-iban-import.component.html',
  styleUrls: ['./identify-iban-import.component.scss'],
  providers: [FormValidatorService, ModalService, DeviceService]
})
export class IdentifyIbanImportComponent implements OnInit , AfterViewInit {

  //Variables para text e imagen box
  classImgFile: string = 'segundoTitular05';
  icon: string = 'ic_segundo-titular';
  titleBox: string = 'SEGUNDO TITULAR';
  subtitle: string = 'Si ya tienes una cuenta, pero quieres incluir un nuevo titular, lo puedes hacer desde tu App.';

  isSecondSigner = false;
  textModalVaya = 'Te invitamos a completar el proceso a través de la videoidentificación.';
  textModalVayaTwoHolders = 'Para volver a intentarlo, la persona que te ha invitado debe volver a enviarte la invitación';

  identifyIbanConfirmForm: FormGroup;

  modalImporte = 'mdimporte';
  modalVaya = 'mdvaya';
  modalAutoriza = 'mdautoriza';
  modal_ko = 'modalko';

  transferValue = undefined;
  public invalidImport = true;
  public showErrorImporte = false;

  persona;
  public iban;
  idSFC;

  public loading = false;

  constructor(
    public modService: ModalService,
    private fb: FormBuilder,
    private helpers: HelperService,
    private iberpaySrv: IberpayService,
    private deviceService: DeviceService,
    private authService: AuthService,
    private errService: ErrorService,
    private analytics: AnalyticsService,
    private inactivitySrv: InactivityService,
    private bySideCarSrv: BySideCarService
    ) {
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };
    this.inactivitySrv.eventShowModal.subscribe(data => {
      this.modService.open(this.modalImporte);
    });
    this.createForm();
  }

  createForm() {
    this.identifyIbanConfirmForm = this.fb.group({
      ibanImport: ['']
    });

    this.identifyIbanConfirmForm = this.fb.group({
      importe_entero: ['', [Validators.required]],
      importe_decima: ['', [Validators.required]],
      importe_centesima: ['', [Validators.required]],
    });

    this.identifyIbanConfirmForm.valueChanges.subscribe(transferForm => {
      this.showErrorImporte = false;
      this.inactivitySrv.activatedModalTimeout = false;
      this.transferValue = parseFloat(transferForm.importe_entero + '.' + transferForm.importe_decima + transferForm.importe_centesima);
      const filledFields = transferForm.importe_entero && transferForm.importe_decima && transferForm.importe_centesima;
      this.transferValue = filledFields && parseFloat(transferForm.importe_entero + '.' + transferForm.importe_decima + transferForm.importe_centesima);
      if (this.transferValue === 0 || this.transferValue) {
        this.invalidImport = false;
      } else {
        this.invalidImport = true;
      }
    });
  }

  ngOnInit() {
    this.persona = JSON.parse(sessionStorage.getItem('currentProspect')) ? JSON.parse(sessionStorage.getItem('currentProspect')) : undefined;
    this.iban = this.persona && this.persona.datosSocioEco && this.persona.datosSocioEco.IBAN;
    this.idSFC = this.authService.getSessionProspect().datosPersonales ?
                      this.authService.getSessionProspect().datosPersonales.id_cliente_EVO :
                      this.errService.navToError();
                      this.createForm();

  }

  ngAfterViewInit() {
    this.bySideCarSrv.cancelTimeOut();
    this.inactivitySrv.initTimeInactivity(0.5);
  }

  openModalImporte(){
    this.analytics.triggerNavData({step:'Paso 07B - Identificación IBAN - verificación importe - transferencia'});
    this.modService.open(this.modalImporte);
    this.modService.onclose.subscribe(() => {
      this.bySideCarSrv.eventShowModal = this.inactivitySrv.eventShowModalC2C;
      this.bySideCarSrv.initTimeInactivity(3);
      const status = 'verificación importe - transferencia cerrar';
      this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', status, this.idSFC);
    });
  }

  closeModalImporteEntendido() {
    this.modService.onclose.subscribe(() => {
      this.bySideCarSrv.eventShowModal = this.inactivitySrv.eventShowModalC2C;
      this.bySideCarSrv.initTimeInactivity(3);
      this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', 'transferencia entendido - cerrar', this.idSFC);
    });
    this.modService.close(this.modalImporte);
  }

  closeModalVaya() {
    this.modService.close(this.modalVaya);
  }

  validateImport(){
    this.loading = true;
    this.iberpaySrv.verifyAmount(this.idSFC, this.transferValue).subscribe(
      data => {
        this.loading = false;
        console.log(data);
        if (data.codigo){
          switch (data.codigo) {
            case ConstantsService.VerifyImportResponseCode.ValidValue: // Si el valor es válido continuar
              this.analytics.triggerFormDataIdentificationCICJ('Identificación por IBAN', 'verificación importe OK', this.idSFC);
              this.helpers.navigateTo('/casi-lo-tenemos');
              break;
            case ConstantsService.VerifyImportResponseCode.InvalidValue: // Si el valor es incorrecto mostrar error
              this.analytics.triggerFormDataIdentificationCICJ('Identificación por IBAN',
                'verificación importe KO - código' + ConstantsService.VerifyImportResponseCode.InvalidValue, this.idSFC);
              this.identifyIbanConfirmForm.reset();
              this.invalidImport = true;
              this.showErrorImporte = true;
              break;
            case ConstantsService.VerifyImportResponseCode.NoPendingValidation: // Sin validación pendiente
              this.analytics.triggerFormDataIdentificationCICJ('Identificación por IBAN',
                'verificación importe KO - código' + ConstantsService.VerifyImportResponseCode.NoPendingValidation, this.idSFC);
              this.helpers.navigateTo('/casi-lo-tenemos');
              break;
            case ConstantsService.VerifyImportResponseCode.TooManyRetries: // Si se han sobrepasado el numero de reintentos
              this.analytics.triggerFormDataIdentificationCICJ('Identificación por IBAN',
                'verificación importe KO - Incorrecto - código' + ConstantsService.VerifyImportResponseCode.TooManyRetries, this.idSFC);
              this.analytics.triggerNavData({step:'Paso 07B - Identificación IBAN - verificación importe - error'});
              this.openModalVaya();
              break;
            default:
              this.analytics.triggerNavData({step:'Paso 07B - Identificación IBAN - verificación importe - error'});
              this.openModalVaya();
              break;
          }
        }
      },
      error => {
        this.loading = false;
        this.modService.open(this.modal_ko);
        this.modService.onclose.subscribe(() => {
          this.bySideCarSrv.eventShowModal = this.inactivitySrv.eventShowModalC2C;
          this.bySideCarSrv.initTimeInactivity(3);
          this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', 'verificación importe - error - cerrar', this.idSFC);
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.inactivitySrv.eventShowModal.unsubscribe();
  }

  openModalVaya(){
    this.modService.open(this.modalVaya);
    this.modService.onclose.subscribe(() => {
      this.loading = true;
      this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', 'verificación importe - error - ir a bienvenido', this.idSFC);
      this.helpers.navigateTo('bienvenido');
    });
  }

}
