<p>
  Al acceder al presente sitio web y aceptar los Términos y Condiciones (en adelante “T&C”) a través del botón habilitado al efecto, aceptas
  la prestación por parte de EVO Banco, S.A.U. de Servicios a Distancia al margen de su oferta de productos de carácter financiero en tanto
  entidad de crédito sometida a la supervisión prudencial del Banco de España.
</p>
<p>
  <strong>CONDICIÓN DE USUARIO.</strong>- Una vez aceptados los mismos pasamos a considerarte USUARIO a los efectos de los presentes T&C. En
  este sentido, consideramos que cuando USUARIO persona física no nos indique que interviene en el marco de su actividad profesional o
  comercial, y siempre que tal circunstancia tampoco resulte evidente a partir de la información que nos aporte, recibirá por parte de EVO
  el trato de consumidor.
</p>
<p>
  <strong>OBJETO.</strong>- Los presentes T&C tienen por objeto establecer el marco jurídico/negocial que regule el uso de los servicios a
  distancia ofertados por EVO, al margen de su oferta de productos de carácter financiero, al aceptar el USUARIO los presentes T&C. Estos
  servicios te permiten acceder a través de los diferentes canales de los que dispone EVO (i.e.(i) vía telefónica; (ii) por vía electrónica
  a través de internet y/o (iii) mediante el teléfono móvil) así como a través de cualquier otro medio que EVO habilite en un futuro que, en
  su caso, serán debidamente comunicados al efecto, a los servicios a distancia proporcionados por EVO y detallados a continuación.
</p>
<p>
  <strong>SERVICIOS A DISTANCIA ASOCIADOS.</strong>- Los servicios a distancia asociados son todos los que EVO pone a disposición del
  USUARIO, en virtud de los presentes T&C, y que se encuentran debidamente detallados en la App Móvil de EVO habilitada para clientes de
  productos no financieros (en adelante los “Servicios a distancia”).
</p>
<p>
  EL USUARIO acepta que el sistema recogido en los presentes T&C tiene carácter meramente instrumental y operativo, a los efectos aquí
  recogidos. Por ello, tanto el USUARIO como EVO expresamente manifiestan que en el caso de que el USUARIO pase a ser cliente de EVO,
  dándose de alta como tal y mediante la contratación de cualesquiera de los productos o servicios financieros ofertados por EVO en su
  calidad de entidad de crédito, la relación jurídica que vincula a ambos en virtud de los presentes T&C pasará a quedar sin efecto alguno y
  cancelados entre ellos, ya que su relación jurídico/negocial pasará a regirse por lo establecido en la documentación y contratos
  reguladores de los productos o servicios en cuestión que en cada caso EVO te requiera, de los que los Servicios a Distancia asociados a
  los presentes T&C también forma parte.
</p>
<br />
<p>
  FORMA DE OPERAR.- El USUARIO conoce y acepta que para navegar y operar con los Servicios a Distancia ofertadas por EVO no se permite
  identificación del USUARIO mediante firma autógrafa.Por ello, para poder acceder y para el funcionamiento de los Servicios a Distancia
  objeto de los presentes T&C, EVO facilita al USUARIO las siguientes claves secretas: (i) un usuario único (“Usuario”) y (ii) una
  contraseña (“Contraseña”) de acceso a Servicios a Distancia. El Usuario y Contraseña proporcionados por EVO son personales e
  intransferibles. El USUARIO reconoce como operaciones por él realizadas las efectuadas una vez incluídos el Usuario y Contraseña al efecto
  proporcionados por EVO y que figuren registradas en la propia App Móvil de EVO que el USUARIO se descarga.
</p>
<p>
  En todo caso, las operaciones que realice el USUARIO a través del servicio se regirán además por las condiciones específicamente
  dispuestas/proporcionadas para cada una de ellas, de ser el caso. Las órdenes e instrucciones que, reuniendo las condiciones aquí
  señaladas, EVO reciba del USUARIO a través de los Servicios a Distancia recogidos en los presentes T&C, se presumirán en todo caso
  correctas, considerándose que han sido autorizadas y cursadas por el USUARIO o por persona/s con facultades suficientes al efecto. En todo
  caso, las órdenes cursadas por el USUARIO y ejecutadas por EVO en virtud de los presentes T&C, gozarán de plenos efectos y presunción de
  legitimidad, sin que aquél pueda alegar u oponer excusa alguna en contra o la falta de firma para excepcionar la validez de la operación o
  incumplir las obligaciones que hubiera asumido como consecuencia de su orden, de ser el caso. Aceptando expresamente que su conformidad,
  en la forma habilitada por EVO al efecto, sirve como perfeccionamiento de las operaciones a las que pueda acceder a través de este
  sistema.
</p>
<p>
  El USUARIO autoriza a EVO a no ejecutar aquellas órdenes recibidas utilizando las claves secretas, cuando tenga dudas razonables de la
  identidad de la persona que está emitiendo la orden, hasta que EVO haya entrado en contacto directo y personal con el USUARIO y
  esclarecido la situación. Asimismo, por razones de seguridad, los Servicios a Distancia quedarán bloqueados en el caso de que se produzcan
  tres errores consecutivos en la consignación de cualquiera de las claves secretas. En tal caso EVO dará aviso de esta incidencia al
  USUARIO. En estos casos, si las circunstancias así lo aconsejan, EVO procederá a anular las claves anteriores y a proporcionar al USUARIO
  unas nuevas con las debidas medidas de seguridad, previo contacto con el USUARIO y a solicitud por escrito de éste.
</p>
<p>
  Asimismo, el Usuario y Contraseña podrán también ser bloqueadas por EVO o a petición del USUARIO, para preservar la seguridad de la
  información.
</p>
<p>
  <strong>REGISTRO DE LAS OPERACIONES.</strong>- El USUARIO autoriza a EVO a grabar y registrar telefónica, magnetofónica, informática,
  electrónicamente o por cualquier otro medio, la totalidad de los datos y circunstancias de las operaciones o consultas que realice a
  través de los Servicios a Distancia. Los registros resultantes de las grabaciones antedichas podrán ser utilizadas como medio de prueba en
  cualquier procedimiento judicial o extrajudicial que pudiera derivarse de la presente relación negocial. EVO se compromete a guardarlos
  durante el periodo establecido por la legislación vigente en cada momento. En todo caso, el USUARIO podrá solicitar de EVO copia sonora de
  su voz en dichas conversaciones.
</p>
<p>
  <strong>SEGURIDAD.</strong>- EVO podrá adoptar cuantas medidas, generales o especiales, de registro, acreditación y verificación considere
  oportunas para salvaguardar el buen uso y garantizar la confidencialidad del servicio, incluida, en su caso, la confirmación escrita de
  las órdenes cursadas por el USUARIO.
</p>
<p>
  <strong>DURACIÓN.</strong>- La relación jurídica/negocial recogida en los presente T&C se pacta con una duración indefinida, pudiéndose
  resolver a instancia de cualquier de las partes, sin necesidad de alegar motivo alguno. El USUARIO podrá resolver el contrato en cualquier
  momento de su vigencia, previa comunicación a EVO por cualquiera de las vías de comunicación habilitadas por EVO y previa liquidación de
  obligaciones respectivas, EVO, por su parte, podrá resolverlo transcurridos dos meses desde que lo haya comunicado al USUARIO, salvo que
  éste no sea consumidor, en cuyo caso el preaviso será de un mes.
</p>
<p>
  Igualmente, tal y como se ha indicado anteriormente, si el USUARIO pasa a ser cliente de EVO (i.e. dándose de alta como tal y mediante la
  contratación de cualesquiera de los productos o servicios financieros ofertados por EVO en su calidad de entidad de crédito), los
  presentes T&C quedarían sin efecto alguno y cancelados entre el USUARIO y EVO, ya que su relación jurídico/negocial pasaría a regirse por
  lo establecido en la documentación y contratos reguladores de los productos o servicios contratados en cuestión, en los que los Servicios
  a Distancia asociados a los presentes T&C se encuentran incluidos.
</p>
<p>
  <strong>FORMA DE EFECTUAR NOTIFICACIONES O COMUNICACIONES.</strong>- EVO queda autorizada para efectuar las comunicaciones, requerimientos
  y cualquier clase de notificación a través de la dirección de correo electrónico, por correo ordinario o por número de teléfono móvil que
  el USUARIO le haya proporcionado al obtener las claves secretas, teniendo en todo caso preferencia la dirección de correo electrónico
  frente a las otras dos, y el número de teléfono móvil frente al correo postal.
</p>
<p><strong>OBLIGACIONES DE EVO.-</strong></p>
<br />
<ul>
  <li>
    a) Garantizar al USUARIO el secreto bancario, en los términos establecidos en la normativa aplicable, de todas las operaciones y
    actuaciones relacionadas con la utilización del sistema, y específicamente en relación con las claves de acceso.
  </li>
  <li>b) Mantener en perfecto estado y en buen funcionamiento los sistemas de uso de los servicios a distancia.</li>
  <li>
    c) Actuar conforme a las indicaciones del USUARIO en cada momento, con la diligencia debida y observándose todos los requisitos formales
    y de seguridad de acceso, así como cualesquiera otros exigidos por EVO en virtud de regulación que pueda considerarse de aplicación.
  </li>
  <li>
    d) Establecer las normas de seguridad adecuadas para la protección de los datos contenidos en los registros informáticos que se hubieran
    creado como consecuencia de la utilización del servicio a distancia objeto de los presentes T&C
  </li>
  <li>e) Establecer las medidas de seguridad adecuadas para la protección del presente sistema y del Servicio a Distancia</li>
</ul>
<br />
<p><strong>OBLIGACIONES DEL USUARIO.-</strong></p>
<br />
<ul>
  <li>
    a) El USUARIO se compromete a guardar y se responsabiliza de la correcta utilización de las claves de acceso, facilitadas por carácter
    estrictamente confidencial por EVO. En caso de pérdida o sustracción de las claves o si el USUARIO tuviera dudas de que las claves
    secretas pudieran ser conocidas por terceras personas, deberá comunicarlo a EVO sin demora indebida en cuanto tuviere conocimiento de
    ello. De no producirse comunicación, EVO no incurrirá en responsabilidad como consecuencia de las operaciones efectuadas indebidamente
    en la medidad en que haya observado las cautelas legalmente exigibles para comprobar la identidad y circunstancias del USUARIO. La no
    exoneración de responsabilidad para EVO se producirá a partir del acuse de recibo de la citada comunicación. No cesará la
    responsabilidad del USUARIO hasta que no obre en poder de EVO la notificación de utilización fraudulenta, no autorizada o incorrecta, en
    cuyo momento EVO procederá a anular el nombre de usuario y/o contraseña correspondiente.
  </li>
  <li>
    b) Asumir los gastos de conservación, mantenimiento y reposición de los equipos propiedad del USUARIO necesarios para la utilización de
    los Servicios a Distancia, que deberán reunir las condiciones técnicas suficientes para la utilización del Servicio a Distancia
    contratado, sin coste alguno para el USUARIO los derivados del uso del servicio.
  </li>
  <li>
    c) El USUARIO deberá realizar todas las operaciones según las especificaciones recibidas por EVO y será responsable de la mala o
    incorrecta utilización de este Servicio a Distancia.
  </li>
  <li>
    d) El USUARIO responde de su buen uso de las conexiones, comprometiéndose expresamente a evitar cualquier tipo de acción que pueda dañar
    a sistemas, equipos o servicios accesibles directa o indirectamente a través de EVO, incluyendo la congestión intencionada de sistemas o
    enlaces, todo ello de acuerdo lo establecido en los presentes T&C
  </li>
  <li>
    e) Admitir cualquier modificación técnica introducida por EVO en relación con los medios a través de los cuales presta el Servicio a
    Distancia, y siempre que tales modificaciones no impliquen costes adicionales para el USUARIO de los asumidos en el momento de contratar
    el Servicio a Distancia.
  </li>
  <li>
    f) Disponer de los elementos necesarios para la conexión, entre ellos el ordenador personal, módem, línea telefónica y teléfono móvil.
  </li>
  <li>
    g) Aceptar cualquier modificación técnica del Servicio a Distancia que EVO considere necesario introducir, procurando que no afecte a la
    calidad del mismo, incluido el cambio del Usuario y Contraseña, las cuales serán notificadas previamente al USUARIO
  </li>
  <li>
    h) Notificar a la mayor prontitud a EVO la modificación de cualesquiera circunstancias personales de las proporcionadas al aceptar los
    presentes T&C, siendo responsable de su veracidad y de cualesquiera consecuencias que se deriven de su inexactitud
  </li>
  <li>i) El USUARIO no podrá anular o revocar una orden que haya realizado por medio de este Servicio a Distancia</li>
  <li>
    j) El USUARIO está conforme con el hecho de que el Servicio a Distancia se presta a través de terceras entidades, principalmente la que
    presta el Servicio a Distancia telefónico o electrónico en cuanto se refiere a la llamada al Servicio, sin coste alguno para el USUARIO
  </li>
</ul>
<br />
<p>
  <strong>LIMITACIÓN DE LA RESPONSABILIDAD.</strong>- Atendiendo a las características del servicio prestado, EVO no será responsable en los
  siguientes casos:
</p>
<p>1.- De los daños y perjuicios que pudieran ocasionarse al USUARIO o a un tercero como consecuencia de:</p>
<p>
  - Deficiencias en el funcionamiento o mal uso por parte del USUARIO de los canales habilitados por EVO, salvo cuando se trate de
  deficiencias en el funcionamiento que sean directamente imputables a EVO.
</p>
<br />
<p>
  -Interferencias, omisiones, interrupciones, suspensiones o desconexiones en el funcionamiento de los Servicios a Distancia o de acceso a
  la red de Internet o cualquier avería en la red o en los sistemas informáticos utilizados.
</p>
<br />
<p>
  2.- Del acceso por terceras personas a información relativa al USUARIO, cuando tal acceso se produzca a consecuencia de actuaciones
  delictivas o indebidas y no esté relacionado con un posible incumplimiento por parte de EVO de sus obligaciones conforme a los presentes
  T&C. En caso de pérdida, robo o sustracción de las claves o si el USUARIO tuviera dudas de que las claves secretas pudieran ser conocidas
  por terceras personas, deberá comunicarlo a EVO sin demora indebida en cuanto tuviere conocimiento de ello. De no producirse comunicación,
  EVO no incurrirá en responsabilidad como consecuencia de las operaciones efectuadas indebidamente en la medida en que haya observado las
  cautelas legalmente exigibles para comprobar la identidad y circunstancias del USUARIO. La no exoneración de responsabilidad para EVO se
  producirá a partir del acuse de recibo de la citada comunicación. No cesará la responsabilidad del USUARIO hasta que no obre en poder de
  EVO la notificación de utilización fraudulenta, no autorizada o incorrecta, en cuyo momento EVO procederá a anular el nombre de usuario
  y/o contraseña correspondiente.
</p>
<br />
<p>
  3.- EVO aplicará los medios razonables para que la información suministrada a través de los Servicios a Distancia sea correcta y se
  encuentre actualizada, sin que sea responsable de los daños y perjuicios que el USUARIO o un tercero puedan sufrir como consecuencia de
  errores, defectos y omisiones en dicha información, si la misma procede del propio USUARIO o de terceros, en su caso.
</p>
<br />
<p>
  <strong>JURISDICCIÓN Y COMPETENCIA.</strong>-Los presentes T&C se rigen por la legislación española. El USUARIO y EVO se someten, para
  cuantas cuestiones y controversias se susciten respecto de la interpretación, aplicación, cumplimiento y ejecución, así como para el
  ejercicio de cuantas acciones y reclamaciones pudieran corresponderles a los Juzgados y Tribunales de Madrid capital. La lengua que regirá
  las relaciones comerciales de la presente relación negocial será el castellano.
</p>
<p>
  <strong>RECLAMACIONES Y QUEJAS.</strong>- Para la resolución de cuantas reclamaciones o quejas pueda plantear el USUARIO, en relación con
  la interpretación, aplicación, cumplimiento y ejecución de estos T&C, sin perjuicio de las acciones judiciales que le correspondan, podrá
  dirigirse al Servicio de Atención al Cliente EVO, calle Don Ramón de la Cruz 84 con C.P.28006 de Madrid, o por correo electrónico a
  atencioncliente@evobanco.com, incluyendo: nombre apellidos, NIF, firma, dirección del cliente y el motivo de la misma. El Servicio de
  Atención Cliente resolverá las quejas y reclamaciones recibidas en los plazos legalmente establecidos a tal efecto, que están debidamente
  recogidos en el Reglamento del Servicio de Atención al Cliente de EVO que tiene a su disposición en su web: www.evobanco.com o
  solicitándolo por escrito o bien llamando al 902 123 445. En última instancia, si la respuesta no fuese satisfactoria o si transcurridos
  los plazos correspondientes no obtuviera respuesta, puede dirigirse a organismos públicos de consumo y/o Departamento de Conducta de
  Mercado y Reclamaciones del Banco de España (Calle Alcalá 48 C.P.28014 Madrid).
</p>
<br />
<p>
  Del mismo modo, EVO informa al USUARIO de su posibilidad de acudir a otras entidades u organismos de resolución alternativa de litigios
  sobre servicios y operaciones de pago así como del procedimiento aplicable, de acuerdo con la normativa vigente en cada momento, que
  estarán a disposición del USUARIO en internet:www.evobanco.com
</p>
<br />
<p>
  EVO Banco, S.A. está adherida a la Asociación para la Autorregulación de la Comunicación Comercial (AUTOCONTROL), en caso de controversias
  relativas a la publicidad, podrá acudir al sistema de resolución extrajudicial de controversias del Jurado de la Publicidad de AUTOCONTROL
  (www.autocontrol.es).
</p>