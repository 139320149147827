<loading [showMe]="loading"></loading>
<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal modalId="faqModal" [modalTitle]="'Faq'" class="modal">
  <div class="modalContent">

    <h3 class="collapsible" (click)="estadoCollapse0 = !estadoCollapse0" >
      ¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?
    </h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse0, 'collapse-none' : !estadoCollapse0}">
      <p>Ahora, disfrutar de las ventajas de tu Cuenta Inteligente es más fácil que nunca. Lo único que tienes que hacer es mantener 10 puntos.
        No te preocupes, es muy fácil conseguirlo ¿cómo? Solo cumpliendo con una de estas opciones tendrás esos 10 puntos.
      </p>
      <ul>
        <li>Domiciliar tu nómina, pensión o desempleo</li>
        <li>Domiciliar 4 recibos</li>
        <li>Mantener 10.000 euros de saldo en cualquier producto EVO</li>
        <li>Domiciliar ingresos mensuales de al menos 600€</li>
        <li>Tener con nosotros una hipoteca, préstamo o tarjeta revolving</li>
        <li>Contratar 1 producto distintos a la Cuenta Inteligente y domiciliar 2 recibos</li>
        <li>Contratar 2 productos distintos a la Cuenta Inteligente</li>
      </ul>
      <br/>
      <h4 class="collapsible" (click)="estadoCollapse1 = !estadoCollapse1">¿Y SI A PESAR DE TODO NO TENGO LOS 10 PUNTOS?</h4>
      <div [ngClass]="{'collapse-block' : estadoCollapse1, 'collapse-none' : !estadoCollapse1}">
        <p>
          No te preocupes, puedes seguir disfrutando de todos los productos, servicios y ventajas de la cuenta inteligente por solo 8€/mes y con una
          remuneración en la cuenta a plazo del 0,01% TIN.Condiciones exclusivas Cuenta Inteligente EVO. Cuenta a plazo remunerada durante 4 meses al
          0,20% TIN  para cualquier importe y sin comisiones de ningún tipo. Liquidación mensual de intereses. Sin penalización por reintegros antici
          pados. Renovable automáticamente cada 4 meses según remuneración vigente. Para disfrutar de las ventajas de la Cuenta Inteligente tendrás que
          conseguir 10 puntos a través del esquema de vinculación  que puedes consultar en www.evobanco.com/cuenta-inteligente , en el caso de no conse
          guir los 10 puntos, pasarás a la Cuenta Sin Vínculos  con la que sigues manteniendo todos los productos, servicios y ventajas de la Cuenta In
          teligente pero con un coste   por mantenimiento  de 8€ al mes y una remuneración en la cuenta a plazo del  0,01% TIN. Ejemplos representativos
          del cálculo de la TAE bajo la hipótesis de que la remuneración se mantiene invariable durante todo el año: Cuenta inteligente: 9.000 euros en
          plazo al 0,20% TIN, TAE del 0,20% y Cuenta Sin Vínculos: 9.000 euros en plazo al 0,01% TIN y comisión mantenimiento 8€/mes, TAE del -1,056%.
          Debes saber que aunque EVO no cobre nada por retirar dinero a débito en el extranjero, algunas entidades propietarias de cajeros pueden aplicar
          una tasa de recargo por su utilización. Debes mantener todos los productos gratuitos que integran la Cuenta Inteligente EVO (Cuenta corriente,
          Cuenta a plazo, Tarjeta Inteligente, Banca a Distancia y Servicio de Alertas). Puedes disponer de un máximo de dos Tarjetas Inteligentes bonificadas.
          Puedes cancelar tu Cuenta Inteligente sin coste de cancelación.

        </p>
      </div>
    </div>
  </div>
  <div class="modalContent">

  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse2 = !estadoCollapse2">¿HAY COMISIONES DE MANTENIMIENTO?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse2, 'collapse-none' : !estadoCollapse2}">
      <p>
        Olvídate de las comisiones. La Cuenta Inteligente no tiene ninguna. Y por supuesto, con tu dinero siempre disponible. Tiene tarjetas de Débito y
        Crédito GRATIS, sin comisión de emisión ni renovación. Puedes sacar dinero gratis de cualquier cajero de España si retiras 120€ o más. Sea del
        color que sea. Y para retiradas gratis sin cantidad mínima y sin límite de operaciones, tienes a tu disposición una de las mayores redes de cajeros
         de España, formada por los cajeros EVO, la red EURO6000 (excepto CaixaBank). Kutxabank, Ibercaja, Grupo Liberbank, Abanca, Unicaja, Grupo Caja Rural
         y Grupo Cooperativo Cajamar, cajeros de Banco Popular y EAc, Banco Pastor y Targo Bank, Bankinter y Caja de Ingenieros. . ¡Son más de 16.000!
        Y con muchos más servicios gratis: transferencias y traspasos nacionales y comunitarios en euros, ingreso de cheques nacionales, Banca Electrónica,
        alertas a móvil...
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse3 = !estadoCollapse3">VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN COMISIONES?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse3, 'collapse-none' : !estadoCollapse3}">
      <p>
        Sí, las tarjetas EVO te acompañan allí donde vayas.
        Con tu Tarjeta de Débito (la negra) o con la Tarjeta Inteligente, EVO no te cobra ni un céntimo por retirar dinero a débito de cajeros en el extranjero.
        Puedes hacerlo todas las veces que quieras y sin cantidad mínima. Estés en el país que estés. Eso sí, es posible que, aunque EVO no te cobre ninguna comisión
        por sacar dinero con la Tarjeta de Débito o la Tarjeta Inteligente, fuera de la Unión Europea las entidades propietarias de los cajeros pueden aplicar una tasa
        de recargo por su utilización. En todo caso, y para tu tranquilidad (y la nuestra) es obligatorio que, para que puedan cobrarte esta comisión, el cajero te
        avise por pantalla y te especifique cuánto te va a costar. Si quieres, podrás cancelar la operación, porque no todos los cajeros cobran esta tasa e incluso
        puede no ser siempre la misma.
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse4 = !estadoCollapse4">¿PUEDO AÑADIR UN SEGUNDO TITULAR?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse4, 'collapse-none' : !estadoCollapse4}">
      <p>
        ¡No hay problema! Porque la Cuenta Inteligente admite dos titulares. Eso sí, cada cliente, solo podrá ser titular de una única Cuenta Inteligente EVO, así
        que tú decides: solo/a, o con pareja.
      </p>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>
<modal  [modalTitle]="'modalLopd'"
        [blocking]="true"
        [modalId]="modalLopd"
        class="modal">
  <div class="row">
    <p class="ic-triangulo-exclamacion"></p>
  </div>
  <div>
    <p class="text-1">Lo sentimos...</p>
  </div>
  <div>
    <p class="text-2">
      No cumples con los requisitos para disfrutar de una Cuenta Inteligente
    </p>
  </div>
  <div class="row-button">
    <button (click)="closeModalLopd()"
            class="bt-info"
            name="button1"
    >
      <p class="etiqueta">Entendido</p>
    </button>
  </div>
</modal>
<div *ngIf="modalBureau" class="modal-backdrop" id="backdropId">
  <div class="modal-container" (click)="modalClick($event)">
    <div class="image-modal"></div>
    <span class="evo close ko cross" (click)='closeModal()'></span>
    <div class="content" style="">
        <h2>¡VAYA!</h2>
        <p class="invalid-text">
          Lo sentimos, no podemos seguir con la solicitud online. Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te explicamos los motivos.</p>
    </div>
  </div>
</div>


<modal [modalId]="alreadyClientModal" [blocking]="true" class="modal">
  <div class="container-user-info">
    <div class="row-ic-triangulo-exclamacion">
      <p class="ic-triangulo-exclamacion"></p>
    </div>
    <div class="row-title">
      <p class="oh-vaya">¡Vaya! Algo no cuadra...</p>
    </div>
    <div class="row-info">
      <p class="nuestro-flujo-de-con">
        Si quieres operar con tu cuenta o añadir a un segundo titular, por favor,<span style="font-weight: bold">
          accede a nuestra Banca Electrónica o descárgate nuestra App.</span
        >
      </p>
    </div>
    <div class="row-info1">
      <p class="nuestro-flujo-de-con">
        Si lo que quieres es abrir una nueva Cuenta Inteligente,<span style="font-weight: bold">
          lamentamos decirte que solo puedes tener una.</span
        >
      </p>
    </div>
    <div class="row-button">
      <button (click)="goToBE()" class="bt-info" name="button1">
        <p class="etiqueta">Entendido</p>
      </button>
    </div>
  </div>
</modal>

<div class="personalDetails">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <div class="row">
          <h2>DATOS PERSONALES</h2>
            <span *ngIf="esCuentaInteligente()">
              <!-- (mouseleave)="onMouseOut()" -->
              <img (click)="onMouseClick()" (mouseenter)="onMouseOver()"
                   class="icon-info" src="assets/images/icon-info.svg">
            </span>
            <div class="" *ngIf="mostrarFaq">
              <ul>
                <li>¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?</li>
                <li>¿HAY COMISIONES DE MANTENIMIENTO?</li>
                <li>VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN COMISIONES?</li>
                <li>¿PUEDO AÑADIR UN SEGUNDO TITULAR?</li>
              </ul>
            </div>
        </div>
        <p>Ahora que vas a formar parte de la Banca Inteligente, necesitamos conocerte un poco más.</p>
      </legend>
    </div>
    <form [formGroup]="personalDetailsForm"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="onSubmit()" autocomplete="off" novalidate>
      <!-- <div class="row">
        <div class="mobile-container">
          <dropdown class="drop-mobile" id="documento" name="documento" formControlName="documento"
          [options]="documents"></dropdown>
        </div>
      </div> -->
      <div class="row">
        <div class="mobile-container">
          <input type="text" id="dni" name="dni"
            autocomplete="off"
            placeholder="DNI/NIE"
            formControlName="numeroDocumento"
            maxlength="9">
            <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('numeroDocumento'), 'numeroDocumento')">
              {{errService.desktopErrors(personalDetailsForm.get('numeroDocumento'), 'numeroDocumento')}}
            </div>
        </div>
        <div class="mobile-container">
          <dropdown class="dropdown drop-mobile" id="pais" name="pais" formControlName="pais"
          [options]="countries"></dropdown>
        </div>
        <div *ngIf='isSpain' class="mobile-container">
          <dropdown  class="dropdown drop-mobile" id="provincia" name="provincia" formControlName="provincia"
          [options]="provinces"></dropdown>
        </div>
        <div *ngIf='!isSpain' class="mobile-container hollow">

        </div>
      </div>
      <div class="row">
        <div *ngIf='!isSpain' class="mobile-container">
          <input type="text" id="localidad" name="localidad" formControlName="localidadnacimiento"
          [maxlength]="!isCountrySelected ? 0 : 45"
          placeholder="Localidad de nacimiento"
          autocomplete="off">
          <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('localidadnacimiento'), !isCountrySelected ? 'paisNacimiento':'localidadnacimiento')">
            {{errService.desktopErrors(personalDetailsForm.get('localidadnacimiento'), !isCountrySelected ? 'paisNacimiento':'localidadnacimiento')}}
          </div>
        </div>
        <div class="mobile-container">
          <dropdown class="drop-mobile" #estadoCivil id="estadoCivil" name="estadoCivil" formControlName="estadoCivil"
          [options]="civilStatus"></dropdown>
        </div>
        <div *ngIf="isMarried" class="mobile-container">
          <dropdown class="dropdown drop-mobile" id="regimenEconomico" name="regimenEconomico" formControlName="regimenEconomico"
          [options]="economicSystems"></dropdown>
        </div>
          <!-- <div *ngIf="!isMarried" class='mobile-container hollow'>
          </div> -->
          <!-- Propósito de negocio MEIF0-7074  -->
           <!-- <div class="switch-title text">
              Selecciona el uso principal que le vas a dar a tu cuenta
            </div> -->
            <div class="mobile-container">
            <div class="container-multiselect-secondHolder">

                <p-multiSelect
                  [options]="businessRelationship"
                  [(ngModel)]="selectedBusinessRelationship"
                  formControlName="businessRelationship"
                  [panelStyle]="{ minWidth: '12em' }"
                  [filter]="false"
                  [showToggleAll]="false"
                >
                  <ng-template let-value pTemplate="selectedItems">
                    <div
                      *ngFor="let val of value"
                      class="ui-multiselected-item-token ui-corner-all"
                    >
                      <span>{{ val }}</span>
                    </div>
                    <span
                      *ngIf="!value || value.length === 0"
                      class="ui-multiselected-empty-token ui-corner-all"
                      >Uso de la cuenta</span
                    >
                  </ng-template>
                  <ng-template let-businessRelationship pTemplate="item">
                    <div style="font-size: 14px; float: right; margin-top: 4px">
                      {{ businessRelationship.label }}
                    </div>
                  </ng-template>
                </p-multiSelect>
                <div
                  class="error-text"
                  *ngIf="
                    personalDetailsForm
                      .get('businessRelationship').invalid && personalDetailsForm
                      .get('businessRelationship').touched
                  "
                >
                  {{ errors.propositoNegocio }}
              </div>
            </div>
            </div>


      </div>
      <div class="mobile-container">
        <div class="group-radio ">
          <p class="false-label">¿Tienes obligaciones fiscales fuera de España?</p>
          <div class="options">
            <input type="radio" id="si" value="si" name="obligacionesFiscales"
            formControlName="obligacionesFiscales">
            <label id="si-label"class="button-label" for="si"></label>
            <input type="radio" id="no" value="no" name="obligacionesFiscales"
            formControlName="obligacionesFiscales">
            <label id="no-label" class="button-label"  for="no"></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input type="checkbox" name="check_perfilado" id="check_perfilado" value="Acepto"
              formControlName="reactiveCheckPerfilado">
            <label id="check_perfilado_label" for="check_perfilado" (keypress)="selectCheckPerfilado($event)" tabindex="2">
              <div class="checkbox-label-text-container">
                {{checks.perfilado}}
              </div>
            </label>
          </div>
        </div>
      </div>
       <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()">
        <div class="checkbox-group">
          <div class="form-checkbox">
            <input type="checkbox" name="check_publicity" id="check_publicity" value="Acepto" formControlName="publicidad">
            <label id="check_publicity_label" for="check_publicity" (keypress)="selectCheckPubli($event)">
              <div class="checkbox-label-text-container">
                {{checks.publicidad}}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input type="checkbox" name="check_cesion" id="check_cesion" value="Acepto"
              formControlName="reactiveCheckCesion">
            <label id="check_cesion_label" for="check_cesion" (keypress)="selectCheckCesion($event)" tabindex="2">
              <div class="checkbox-label-text-container">
                {{checks.cesion}}
              </div>
            </label>
          </div>
        </div>
      </div>
      <br/>

      <div class="btn-group">
          <button type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
      </div>
  </form>
  </div>
</div>
