import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { ModalService } from 'app/services/modal.service';
import { BySideCarService } from 'app/services/bysidecar.service';
import { ApiBySideCarService } from 'app/services/api/api.bysidecar.service';
import { AuthService } from 'app/services/auth.service';
import { ErrorService } from 'app/services/error.service';
import { AuthGuard } from 'app/services/auth-guard.service';
import { DeviceService } from 'app/services/device.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss'],
  providers: [ModalService,
    BySideCarService,
    ApiBySideCarService,
    DeviceService]
})
export class HeaderNewComponent implements OnInit {
  public callMeModal = 'call-me';
  private step;
  public isPhoneSent: boolean = false;
  public modalTitle = '¿Quieres que te llamemos?';
  public desktopPhone: string = '';
  public isCampanyaCaptacion: boolean =  location.href.indexOf('codRecommendation') !== -1;
  public mobilePhone: number = 0;
  userHasPhone: boolean = false;
  public isYoungAccount: boolean = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);


  private desktopStepsToPhone: Object = {
    'bienvenido': '',
    'datos-personal': '910900900',
    'datos-contacto': '910900900',
    'datos-personales': '910900900',
    'datos-de-contacto': '910900900',
    'datos-laboral': '910900900',
    'confirmacion-otp': '',
    'metodo-validacion': '',
    'identificacion-video': '',
    'identificacion-iban': '910900900',
    'confirmacion-datos': '',
    'previa-firma': '910900900',
    'proceso-firma': '910900900',
    'contratacion-ci': '',
    'ya-esta': '',
    'casi-esta': '',
    'casi-lo-tenemos': '',
    '404': '',
    'OCR': '',
    'verificacion-cuenta': '910900900',
    'pendiente-verificacion-transferencia': '910900900'
  };

  private mobileStepsToPhone: Object = {
    'bienvenido': 0,
    'datos-personal': 910900900,
    'datos-contacto': 910900900,
    'datos-personales': 910900900,
    'datos-de-contacto': 910900900,
    'datos-laboral': 910900900,
    'confirmacion-otp': 0,
    'metodo-validacion': 0,
    'identificacion-video': 0,
    'identificacion-iban': 910900900,
    'confirmacion-datos': 0,
    'previa-firma': 910900900,
    'proceso-firma': 910900900,
    'contratacion-ci': 0,
    'ya-esta': 0,
    'casi-esta': 0,
    'casi-lo-tenemos': 0,
    '404': 0,
    'OCR': 0,
    'verificacion-cuenta': 910900900,
    'pendiente-verificacion-transferencia': 910900900
  };

  constructor(public modService: ModalService,
    private bysidecar: BySideCarService,
    private authService: AuthService,
    private authGuard: AuthGuard,
    public errService: ErrorService,
    private route: ActivatedRoute,
    public deviceService: DeviceService,
    private router: Router,
    public analytics: AnalyticsService
  ) { }

  ngOnInit() {
    if (!this.isYoungAccount) {
      this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    };
    if(!this.isCampanyaCaptacion) {
      this.isCampanyaCaptacion =  location.href.indexOf('codRecommendation') !== -1;
    }
    this.getPhoneNumber();
    if (this.router.url) {
      this.refreshPhones(this.router.url.replace('/', ''))
    }
  }

  getTitleResponsive() {
    // if (this.isYoungAccount) {
    //   return 'CUENTA JOVEN';
    // }

    return '';
  }

  openModal() {
    if (this.userHasPhone) this.callUserWithPhone();
    return this.modService.open(this.callMeModal);
  }

  informAnalytics() {
    return this.analytics.triggerInteractionDataCICJ('C2C', 'Click en DDI desde móvil')
  }

  getPhoneNumber() {
    this.router.events.subscribe(params => {
      if (params instanceof NavigationEnd) {
        let route = params.url.replace('/', '');
        if (route === 'undefined') route = 'bienvenido';
        this.refreshPhones(route);
      }
    })
  }

  refreshPhones(step) {
    let stepWithoutParams = step.toLocaleLowerCase().split('?')[0];
    this.step = this.desktopStepsToPhone[stepWithoutParams];
    this.desktopPhone = this.desktopStepsToPhone[stepWithoutParams];
    this.mobilePhone = this.mobileStepsToPhone[stepWithoutParams];
    if (this.desktopPhone !== '') {
      if (this.authService.getSessionProspect().datosPersonales && this.authService.getSessionProspect().datosPersonales.telefono) {
        this.userHasPhone = true;
      }
      this.bysidecar.initTimeInactivity(3);
    }
    else{
      this.bysidecar.cancelTimeOut();
    }
  }

  callUserWithPhone() {
    this.isPhoneSent = true;
    const phone = this.authService.getSessionProspect().datosPersonales.telefono;
    const name = this.authService.getSessionProspect().datosPersonales.nombreCliente;
    this.modalTitle = '¡Oído!'
    this.bysidecar.callUser().subscribe(
      data => {
        this.analytics.triggerInteractionData('C2C', 'Formulario enviado OK');
        this.bysidecar.cscService(phone, name,data).subscribe(
          data =>{}
        )
    }, error => console.error(error));
  }

  callUser(f: NgForm) {
    const phone = f.value.phone;
    const name = f.value.name;
    if (f.valid) {
      this.isPhoneSent = true;
      this.modalTitle = '¡Oído!'
      this.bysidecar.callUser().subscribe(
      data =>{
        this.analytics.triggerInteractionData('C2C', 'Formulario enviado OK');
        this.bysidecar.cscService(phone, name,data).subscribe(
          data =>{}
        )
      },
       error => console.error(error));
      f.value.phone = '';
      f.value.name = '';
    }
  }
}
