import { Injectable } from "@angular/core";
import { ConsultaTipoTransferenciaResponse, GenericResponse, UserValidationResponse } from "app/collections/iberpay";
import { Observable } from "rxjs";
import { ApiIberpayService } from "./api/api.iberpay.service";

@Injectable()
export class IberpayService {

    constructor(private api: ApiIberpayService) { }

    validateUser(idSfc: string): Observable<UserValidationResponse> {
        return this.api.validateUser(idSfc);
    }

    verifyIban(iban: string): Observable<ConsultaTipoTransferenciaResponse> {
        return this.api.verifyIban(iban);
    }

    verifyAmount(idUser: string, amount: string): Observable<GenericResponse> {
        return this.api.verifyAmount(idUser, amount);
    }
}
