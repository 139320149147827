import { Component, Injectable, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { PersonaService } from './persona.service';
import { AuthService } from './auth.service';
import { ApiPersonService } from './api/api.persona.service';
import { HelperService } from './helpers.service';
import { ErrorService } from '../services/error.service';
import { ExtendLimitStep1Component } from 'app/modules/profile/extend-limit-step1/extend-limit-step1.component';
//import { DivShowComponent } from 'app/components/div-show/div-show.component';
import { DeviceService } from './device.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AmpliacionService implements OnInit {

  public clientID;
  public tarjetaSeleccionada;
  public myLimit;
  public casoEnVuelo: boolean = false;
  public lastDig;
  public limCredito;
  public tarjetasBloqueadas: Array<any> = [];
  public tarjetasActivas: Array<any> = [];
  public tieneSolicitudes: boolean = false;
  public solicitudesEnCurso = [];
  public tipoSolicitud;
  public idCaso;
  public oblig: boolean = false;
  public origen: string = 'Desktop';

  //@ViewChild(DivShowComponent) errorShow: DivShowComponent;
  //@ViewChild('extend-limit-step1') public eAutocomplete: ElementRef;

  constructor(public persona: PersonaService,
    private helpers: HelperService,
    private authService: AuthService, 
    public errService: ErrorService,
    private deviceService: DeviceService
  ) {

  }

  ngOnInit() {
    this.errService.modDataError = true;
    this.cargarTarjeta();
    this.changeLimit();
  }

  disminuirLimite() {
    // Llamar a componente de error para mantener el limite o continuar
    // En continuar hacer la llamada
    this.cargarTarjeta();
    this.tarjetaSeleccionada = JSON.parse(sessionStorage.getItem("tarjetaSeleccionada"));
    this.myLimit = sessionStorage.getItem('limite-solicitado');

    let acu = this.tarjetaSeleccionada.acuerdo.toString();
    let objeto: Object = {
      "canalOrigen": this.origen ,
      "acuerdo": this.tarjetaSeleccionada.acuerdo,
      "limiteSolicitado": parseInt(this.myLimit),
      "limiteActual": parseInt(this.tarjetaSeleccionada.limiteCredito),
    };

    this.persona.disminuirLimite(objeto).subscribe(data => {
      console.log("peticion realizada", data);
      sessionStorage.setItem('origen', 'ampliacion');
      this.helpers.navigateTo('/confirma-limite'); // si se hace la ampliacion, mandar a confirmar limite
    }, error => {
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'login');
    });
  }

  /**Busca si ya hay previamente una tarjeta en la sesión, si no es así se coge la primera tarjeta operativa
     * de la lista del cliente
     */
  cargarTarjeta() {
    if (sessionStorage.getItem('tarjetaSeleccionada')) {
      this.tarjetaSeleccionada = JSON.parse(sessionStorage.getItem('tarjetaSeleccionada'));
    } else {
      this.searchFirstCard();
    }
    this.consultarCasoTarjeta();
    this.showCard();
  }

  /**Se busca la primera tarjeta operativa(no bloqueada) del cliente */
  searchFirstCard() {
    let tarjetas = JSON.parse(sessionStorage.getItem("userData")).listaTarjetas;
    //let i = 0;
    if (tarjetas.length > 0) {

      for (var i = 0; i < tarjetas.length; i++) {
        if (tarjetas[i].codigoLinea === "01") {
          if (tarjetas[i].codBloqueo === "10" || tarjetas[i].codBloqueo === "20" ||
            tarjetas[i].codBloqueo === "21" || tarjetas[i].codBloqueo === "32" ||
            tarjetas[i].codBloqueo === "33" || tarjetas[i].codBloqueo === "71" ||
            tarjetas[i].codBloqueo === "72" || tarjetas[i].codBloqueo === "80") {
            this.tarjetasBloqueadas.push(tarjetas[i]);
          }
          else {
            this.tarjetasActivas.push(tarjetas[i]);
          }
        }
      }
      /*
      while(!this.tarjetaSeleccionada){
        if(!tarjetas[i].desBloqueo){
          this.tarjetaSeleccionada = tarjetas[i];
        }
        i++;
      }*/
    }

    if (this.tarjetasActivas.length > 0) {
      this.tarjetaSeleccionada = this.tarjetasActivas[0];
    }
  }

  /**Se cambia el límite en función de la tarjeta */
  changeLimit() {
    if (this.tarjetaSeleccionada) {
      this.myLimit = this.tarjetaSeleccionada.limiteCredito;
    }
  }

  showCard() {
    if (this.tarjetaSeleccionada) {
      this.lastDig = this.tarjetaSeleccionada.numTarjeta.substr(-4, 4);
      this.limCredito = this.tarjetaSeleccionada.limiteCredito;
    }
  }

  consultarCasoTarjeta() {
    this.casoEnVuelo = false;
    //Consula de solicitudes en vuelo
    this.persona.consultarCasosCliente().subscribe(data => {
      if (this.tarjetaSeleccionada) {
        let acuerdo = this.tarjetaSeleccionada.acuerdo;
        // buscar dentro de data si coincide con el acuerdo de la tarjeta seleccionada
        for (var i = 0; i < data.listaCasos.length; i++) {
          if (data.listaCasos[i].tipoCaso == 200 || data.listaCasos[i].tipoCaso == 201) {
            if (data.listaCasos[i].acuerdo == acuerdo) {
              this.casoEnVuelo = true;
            }
          }
        }
      }
    });
  }

  cancelarLimite() {
    let data: Object = {
      'idCaso': sessionStorage.getItem("casoSeleccionado"),
    };
    this.persona.cancelarAmpliacion(data).subscribe(data => {
      console.log("Response cancelar: ", data);
      if (data.codigo === 'OK') {
        if (sessionStorage.getItem('operacion-ampliacion') === "modificar") {
          if (this.myLimit > this.tarjetaSeleccionada.limiteCredito) {
            this.aumentarLimite();
          } else {
            this.disminuirLimite();
          }
        } else {
          this.helpers.navigateTo('/confirma-limite');
        }
      }
    }, error => {
      console.log("Error en la cancelarLimite");
      this.errService.modDataError = true; 
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }


  aumentarLimite() {
    this.myLimit = sessionStorage.getItem('limite-solicitado');
    this.cargarTarjeta();
    //Consula de solicitudes en vuelo
    this.persona.consultarCasosCliente().subscribe(data => {
      console.log("Casos cliente: ", data);

      //Se comprueban los warnings
      if (this.getWarnings()) {
        //Se comprueban si hay solicitudes en validación
        if (this.getInfoSolicitudValidandose()) {
          // PUT REVISANDO DOCUMENTACION
          // REALIZAR PUT PARA IR A SUBIDA DE DOCUMENTACION
          this.putLimite("pendiente-revision", "revision");

        } else {
          //Si no hay solicitudes validandose se abre el caso de ampliación
          //Creación del objeto para enviar 
          let objeto: Object = {
            "canalOrigen": this.origen ,
            "acuerdo": this.tarjetaSeleccionada.acuerdo.toString(),
            "limitePrevio": this.tarjetaSeleccionada.limiteCredito,
            "limiteSolicitado": this.myLimit,
            "IBAN": this.tarjetaSeleccionada.iban,
            "estado": 'registro',
            "codigoLinea": this.tarjetaSeleccionada.codigoLinea,
            "codigoGrupo": this.tarjetaSeleccionada.codigoGrupo,
            "codigoGrupoDesc": this.tarjetaSeleccionada.codigoGrupoDesc,
            "codigoPV": this.tarjetaSeleccionada.codigoPV,
            "codigoTarifa": this.tarjetaSeleccionada.codigoTarifa
          };
          this.persona.crearCasoLimite(objeto).subscribe(data => {
            this.idCaso = data.idCaso;
            console.log("Peticion realizada", data);
            if (data.codigo === 'KO') {
              console.log("Peticion fallida")
            } else {
              //TODO
              //Hacer PUT al servicio modificar
              //Se hace un put añadiendole el idCaso
              //PAsarle idCaso, DRF, obligaciones fiscales, logaltySignatureId y signatureId 
              let data: Object = {
                "idCaso": this.idCaso,
                "DRF": false,
                "obligacionesFiscales": "",
                "logaltySignatureId": "",
                "signatureId": ""
              };
              //this.persona.modificarLimite(data);
              //Si hay algún warning por falta de documentación se lleva al usuario a subida de documentación
              //01->DNI caducado, 02->No tiene documentación RSI
              if (this.tipoSolicitud === '01' || this.tipoSolicitud === '02' || this.tipoSolicitud === '03') {
                //TODO incluid idCaso en la sesion

                // REALIZAR PUT PARA IR A SUBIDA DE DOCUMENTACION
                this.putLimite("warning-documentacion", "subir")

              }
            }
          }, error => {
            console.log("error en la peticion a ampliacionLimite");
            this.errService.modDataError = true; 
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
          });
        }
        //Si no hay warnings abrimos la llamada a ampliación
      } else {
        console.log("tarjeta seleccionada: ", this.tarjetaSeleccionada);
        //Abrimos el caso de ampliación
        //Creación del objeto para enviar 
        let objeto: Object = {
          "canalOrigen": this.origen ,
          "acuerdo": this.tarjetaSeleccionada.acuerdo,
          "limitePrevio": this.tarjetaSeleccionada.limiteCredito,
          "limiteSolicitado": this.myLimit,
          "IBAN": this.tarjetaSeleccionada.iban,
          "estado": 'registro',
          "codigoLinea": this.tarjetaSeleccionada.codigoLinea,
          "codigoGrupo": this.tarjetaSeleccionada.codigoGrupo,
          "codigoGrupoDesc": this.tarjetaSeleccionada.codigoGrupoDesc,
          "codigoPV": this.tarjetaSeleccionada.codigoPV,
          "codigoTarifa": this.tarjetaSeleccionada.codigoTarifa
        };
        this.persona.crearCasoLimite(objeto).subscribe(data => {
          console.log("Peticion realizada", data);
          if (data.codigo === 'KO') {
            console.log("Peticion fallida")
          } else {
            //TODO
            //Hacer PUT al servicio modificar
            //Si no hay ningún warning se lleva al usuario a consola de modificación
            this.helpers.navigateTo('/mi-perfil');
          }
        }, error => {
          console.log("error en la peticion a ampliacionLimite");
          this.errService.modDataError = true; 
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        });
      }

    });


  }



  /**
   * Devuelve true si tiene solicitudes validandose, false si no tiene ninguna solicitud
   */
  getInfoSolicitudValidandose() {
    return this.persona.getInfoSolicitud(this.clientID).pipe(
      catchError(() => {
        console.log("error en la peticion a ampliacionLimite");
        //Redirige al 404 de Modificación de datos
        this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'login');
        return of()
      }),
      map( response => {
        this.tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.solicitudesEnCurso = [];
      if (!this.tieneSolicitudes) {
        return false;
      } else {
        this.solicitudesEnCurso = response.solicitudes;
        for (const solicitud of this.solicitudesEnCurso) {
          if (solicitud.tipoSolicitud === "01" || solicitud.tipoSolicitud === "02") {
            if (solicitud.estado != "Nueva" || solicitud.estado != "Enviada") {
              return true;
            }
          }
        }
        this.errService.navToErrorModData('ASTRONAUTA-C', 'ENTENDIDO', 'login');
      }
      })
    );
  }

  getWarnings(): Observable<boolean> {

    return this.persona.getWarnings(this.clientID).pipe(map(response => {

      this.tipoSolicitud = response.message;

      if (this.tipoSolicitud) {

        return true;

      } else {

        return false;

      }

    }));

  }

  modificarLimite() {
    this.cancelarLimite();
  }

  putLimite(status, type) {
    let idCaso = sessionStorage.getItem("casoSeleccionado");
    if (status === "obligaciones-tributarias") {
      this.oblig = true;
    }
    else {
      this.oblig = false;
    }
    let obj: Object = {
      "estado": status,
      "idCaso": idCaso,
      "DRF": false,
      "obligacionesFiscales": this.oblig,
      "logaltySignatureId": "",
      "signatureId": ""
    };
    this.persona.modificarLimite(obj).subscribe(data => {

      //Se añade la variable a la sesión y mediante el método ngOnChanges de div-show 
      //abre la ventana de error
      console.log("PUT realizado");
      if( data.codigo === 'OK'){
        if (type === "revision") {
          sessionStorage.setItem('operacion-ampliacion', 'revisandoDocumentacion');
          //this.extendLimit.nameError = "revisandoDocumentacion";
          //this.extendLimit.showError = true; // mostramos el error de div-show
        }
        else if (type === "subir") { // con warnings --> a subir documentacion
          console.log("PUT realizado");
          this.helpers.navigateTo("/subir-documentacion");
        }
        else if (type === "modificacion") {
          console.log("PUT realizado"); // put para redirigir a modificacion de datos
          this.helpers.navigateTo("mi-perfil");
        }
        else if (type === "documentacion") {
          this.consultaExperianAsnef();
        }
        else if (type === "aportar-documentacion") {
          this.helpers.navigateTo("documentacion");
        }
        else if (type === "obligaciones-tributarias") {
          //Se redirije desde la pantalla del error al darle a entendido.
          //this.helpers.navigateTo("perfil");
        }
      }
      else if ( data.codigo === '01' || data.codigo === '02' || data.codigo === '03' || data.codigo === '04'){
        sessionStorage.setItem('operacion-ampliacion', 'imprevistoRiesgos');
        this.helpers.navigateTo("documentacion");
        //this.showError = true; // mostramos el error de div-show
        //o hago asi this.nameError = "imprevistoRiesgos";
      }
      else {
        if (type === "obligaciones-tributarias") {
          //Se redirije desde la pantalla del error al darle a entendido.
          //this.helpers.navigateTo("perfil");
        }
        else {
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        }
        
      }

    }, error => {
      console.log("Error en la cancelarLimite");
      this.errService.modDataError = true; 
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }


  consultaExperianAsnef() {
    let idUser = JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.id_cliente_EVO;
    this.persona.getConsultaExperian(idUser, this.authService.product).subscribe(data => {

      if (data.codigo === "01" || data.codigo === "03") {
        // codigo: 01 OK
        // codigo: 03 Error Experian,
        console.log("Consulta Experian OK");
        console.log(data.message);
        this.getConsultaAsnef();
      } else if (data.codigo === "02") {
        // codigo: 03 Desestimar
        console.log("Consulta Experian KO");
        console.log(data.message);

        //MOSTRAR ERROR - ha surgido un imprevisto
        //this.extendLimit.nameError = "surgidoImprevisto";
        //this.extendLimit.showError = true; // mostramos el error de div-show
        //this.modalBureau = true;
        window.scrollTo(0, 0);
        return false;
      }
      else { //En cualquier otro caso se deja continuar
        this.getConsultaAsnef();
      }
    }, error => {
      this.getConsultaAsnef();
    });
  }

  getConsultaAsnef() {
    let idUser = JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.id_cliente_EVO;
    this.persona.getConsultaAsnef(idUser, this.authService.product).subscribe(data => {

      if (data.codigo === "01" || data.codigo === "03") {
        // codigo: 01 OK
        // codigo: 03 Error Experian,
        console.log("Consulta Asnef OK ", data.message);
        //this.continuarSubmit();
        this.putLimite("aportar-documentacion", "aportar-documentacion");
      } else if (data.codigo === "02") {
        // codigo: 03 Desestimar
        console.log("Consulta Asnef KO", data.message);
        //MOSTRAR ERROR - ha surgido un imprevisto
        //this.extendLimit.nameError = "surgidoImprevisto";
        //this.extendLimit.showError = true; // mostramos el error de div-show
        //this.modalBureau = true;
        window.scrollTo(0, 0);
        return false;
      } else { //En cualquier otro caso se deja continuar
        //this.continuarSubmit();
      }
    }, error => {
      //ERROR
      //this.continuarSubmit();
    });
  }
}
