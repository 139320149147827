import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { GENEROS } from 'app/resources/genders.model';
import { PAISES } from 'app/resources/countries.model';
import { REGIMENES } from 'app/resources/regimen-economico.model';
import { DOCUMENTOS } from 'app/resources/document.model';
import { PROVINCES } from 'app/resources/provincias.model';
import { ACCOUNTS } from 'app/resources/account.model';

import { Register } from 'app/collections/register';
import { Persona } from 'app/collections/persona';

import { AuthService } from 'app/services/auth.service';
import { ApiLocalidadesService } from 'app/services/api/api.localidades.service';
import { LocalidadesService } from 'app/services/localidades.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ErrorService } from 'app/services/error.service';
import { ModalService } from 'app/services/modal.service';
import { DeviceService } from 'app/services/device.service';
import { JsonService } from 'app/services/json.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { HelperService } from 'app/services/helpers.service';

import { Modal } from 'app/collections/modal';
import { environment } from 'environments/environment';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ERRORSFORM } from 'app/resources/errors-form';

@Component({
  selector: 'app-personal-details-register',
  templateUrl: './personal-details-register.component.html',
  styleUrls: ['./personal-details-register.component.scss'],
  providers: [FormValidatorService,
    ModalService,
    DeviceService,
    JsonService,
    LocalidadesService,
    ApiLocalidadesService
  ]
})

export class PersonalDetailsRegisterComponent implements OnInit, AfterViewInit {

  loading = false;
  personalDetailsForm: FormGroup;
  personalDetailsValues: Register;
  private persona: Persona = this.authService.getSessionProspect();
  firstElement: any;
  modalLegal = 'legal-modal'
  modalTipoA = 'tipoA-modal';
  modal = new Modal();
  public bureauModal = 'bureauModal';
  alreadyClientModal = 'already-client';
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg = 'Lo sentimos, no podemos seguir con la solicitud online. Llámanos al ' + this.telephoneMsg + ' y te explicamos los motivos.';
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  dniErrorMsg = 'Parece que ha habido algún error. Por favor, vuelve a introducir tus datos.';
  public modalBureau = false;
  documents = DOCUMENTOS;
  countries = PAISES;
  genders = GENEROS;
  provinces = PROVINCES;
  civilStatus = ESTADO_CIVIL;
  economicSystems = REGIMENES;
  localizations;
  loginUser: FormGroup;
  errMsg;
  private passStr = '';
  public firstTouch = true;
  public firstTouchPrevented = true;
  public isYoungAccount = false;
  modalLog = false;
  dniExist = false;
  public mouseover = false;
  public mostrarFaq = false;
  // Propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep = true;
  sessionDatosPrimerTitular: any;
  public codigoRetornoDni: number;
  // Variables collapse
  public estadoCollapse0 = false;
  public estadoCollapse1 = false;
  public estadoCollapse2 = false;
  public estadoCollapse3 = false;
  public estadoCollapse4 = false;
  proveedor: any;
  retorno: any;
  public errors = ERRORSFORM;

  constructor(private fb: FormBuilder,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    private authService: AuthService,
    public deviceService: DeviceService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private activatedRoute: ActivatedRoute,

  ) {
    // this.createForm();
    // this.initModal();
    window.location.href = "https://www.evobanco.com/cuenta-inteligente/";
  }

  private createForm() {

    const datosPersonales = sessionStorage.getItem('persona') && JSON.parse(sessionStorage.getItem('persona')).datosPersonales;

    this.personalDetailsForm = this.fb.group({
      telefonoMovil: [(datosPersonales && datosPersonales.telefonoMovil) || '', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(this.helpers.phoneRexpWithoutException)]],
      numeroDocumento: [(datosPersonales && datosPersonales.idExterno) || '', [Validators.required, this.evoValidator.validateID]],
      documento: [(datosPersonales && datosPersonales.documento) || 'dni', [Validators.required]],
      nombre: [(datosPersonales && datosPersonales.nombreCliente) || '', [Validators.required, this.evoValidator.validateName]],
      primerApellido: [(datosPersonales && datosPersonales.apellido1Cliente) || '', [Validators.required, this.evoValidator.validateName]],
      segundoApellido: [(datosPersonales && datosPersonales.apellido2Cliente) || '', []],
      email: [(datosPersonales && datosPersonales.email) || '', [Validators.minLength(1), Validators.maxLength(50), this.evoValidator.validateEmail]],
      reactiveCheckLegal: [false, Validators.required],
      reactiveCheckTipoA: [false, Validators.required],
      publicidad: [false],
      reactiveCheckSolvencia: [false],
      reactiveCheckPublicidad: [false],
      password: ['', [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
      confirmPassword: ['', [Validators.required, this.evoValidator.validatePasswordConfirmation]],
    });

    this.formSubscribe();
  }

  initModal() {
    this.modal = Object.assign({
      name: 'modal-errores-genericos',
      title: '¡Vaya!',
      withImage: false,
      message: `Tenemos un problema con tu solicitud online.
      <br>
      Llámanos al <a href='tel:910900900'>910 900 900</a> para que podamos ayudarte a continuar.`
    })
  }


  ngOnInit() {
    window.location.href = "https://www.evobanco.com/cuenta-inteligente/";
    // this.getQueryParams();
    // this.authService.product = ACCOUNTS.TipoACode;
    // sessionStorage.setItem("altaClienteA", "true");
    // this.errService.mobileErrors(this.personalDetailsForm);
    // this.firstElement = this.documents[0];
    // this.validateDocuments(this.personalDetailsForm.get('documento').value);
    // //control if the users has sent the id (email|phone)
    // this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||  this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('responseAlta')) { // 1002
      sessionStorage.removeItem('responseAlta');
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Oh-Oh!',
        message: 'Existe un Problema con tu registro. Revisa tus datos.',
        blocking: false,
        withImage: true
      })
      this.modService.open(this.modal.name);
    }
  }


  getQueryParams() {
    this.loading = true;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.client_id && params.redirect_uri) {
        this.proveedor = params.client_id;
        this.retorno = params.redirect_uri;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  }

  setFields(modalLog) {
    const canal = this.deviceService.getDevice();

    if (!modalLog) {
      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        datosPersonales: {
          documento: this.helpers.returnValue(this.personalDetailsForm.get('documento')),
          nombreCliente: this.helpers.returnValue(this.personalDetailsForm.get('nombre')),
          apellido1Cliente: this.helpers.returnValue(this.personalDetailsForm.get('primerApellido')),
          apellido2Cliente: this.helpers.returnValue(this.personalDetailsForm.get('segundoApellido')),
          email: this.helpers.returnValue(this.personalDetailsForm.get('email')),
          telefonoMovil: this.helpers.returnValue(this.personalDetailsForm.get('telefonoMovil')),
          oficinaCliente: environment.oficina,
          codigoIdExterno: this.getDocumentType,
          idExterno: this.helpers.returnValue(this.personalDetailsForm.get('numeroDocumento')) ? this.helpers.returnValue(this.personalDetailsForm.get('numeroDocumento')).toUpperCase() : undefined,
          password: btoa(this.helpers.returnValue(this.personalDetailsForm.get('password')))
        },
        activeOtpFirstStep: this.activeOtpFirstStep,
        indConsentimientoTratamientoTipoA: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckLegal')) || false,
        indConsentimientoTipoA: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckTipoA')) || false,
        indPerfiladoCliente: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckSolvencia')) || false,
        otrosDatos: {
          indPubliPe: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckPublicidad')) || false,
        },
        proveedor: this.proveedor,
        retorno: this.retorno
      });

    } else {

      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        estado: "cliente-existente"
      });
    }
    sessionStorage.setItem('persona', JSON.stringify(this.persona));
  }

  goToMap() {
    window.open('https://www.evobanco.com/donde-estamos/');
  }

  filterValidControls() {
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  hasNoPassword() {
    // mail with pass
    this.modService.close(this.alreadyClientModal);
  }

  isNotMe() {
    // mail user
    this.modService.close(this.alreadyClientModal);
  }

  triggerErrorAnalytics() {
    const invalidFields = this.errService.errorsArray.map(error => {
      return error = error.text
    })
    const invalidString = invalidFields.join(', ');

    if (invalidString !== '')
      return;
  }

  onSubmit() {
    this.helpers.updateFormStatus(this.personalDetailsForm);
    this.triggerErrorAnalytics();
    //Se comprueba si el formulario es válido
    if (!this.personalDetailsForm.valid) {
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        withImage: true,
        blocking: false,
        message: this.incompleteMsg
      })

      this.modService.open(this.modal.name);
      if (!this.personalDetailsForm.get('reactiveCheckLegal').value){
        this.personalDetailsForm.get('reactiveCheckLegal').setErrors({ notChecked: true });
      }
      if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
        this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      }
      window.scrollTo(0, 0);
      return false;
    }
    else if (!this.personalDetailsForm.get('reactiveCheckLegal').value && !this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        message: 'Debes haber leído y aceptado el Régimen de Tratamiento de Datos de Carácter Personal y los Términos y Condiciones Legales para poder continuar.',
        withImage: true
      })
      this.modService.open(this.modal.name);
      this.personalDetailsForm.get('reactiveCheckLegal').setErrors({ notChecked: true });
      this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      window.scrollTo(0, 0);
      this.personalDetailsForm.get('reactiveCheckLegal').setErrors({ notChecked: true });
      this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      return false;
    }
    else if (!this.personalDetailsForm.get('reactiveCheckLegal').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        message: 'Debes haber leído y aceptado Régimen de Tratamiento de Datos de Carácter Personal para poder continuar.',
        withImage: true
      })

      this.modService.open(this.modal.name);
      this.personalDetailsForm.get('reactiveCheckLegal').setErrors({ notChecked: true });
      window.scrollTo(0, 0);
      this.personalDetailsForm.get('reactiveCheckLegal').setErrors({ notChecked: true });
      return false;
    }
    else if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        message: 'Debes haber leído y aceptado los Términos y Condiciones Legales para poder continuar.',
        withImage: true
      })

      this.modService.open(this.modal.name);
      this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      window.scrollTo(0, 0);
      this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      return false;
    }

    this.loading = true;
    this.setFields(this.modalLog);


    this.continuarSubmit();
  }

  continuarSubmit() {
    const incompleteModal = {
      name: 'modal-errores-genericos',
      title: '¡Ups!',
      blocking: false,
      withImage: true,
      message: this.incompleteMsg
    }

    this.helpers.updateFormStatus(this.personalDetailsForm);

    const numeroDocumento = this.personalDetailsForm.get('numeroDocumento');

    if (numeroDocumento && (numeroDocumento.value.length !== 9 ||
      this.isValidID(numeroDocumento.value) === false)) {
      this.loading = false;
      numeroDocumento.setErrors({ valid: false });

      this.modal = Object.assign(incompleteModal)
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    /**
     * El mensaje aparece si no se ha marcado la casilla de aceptar las condiciones
     */
    if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      this.loading = false;
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        message: 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.',
        withImage: true
      })
      this.modService.open(this.modal.name);
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
    }

    if (!this.personalDetailsForm.valid) {
      this.loading = false;
      this.modal = Object.assign(incompleteModal)
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    this.helpers.navigateTo('/confirmacion-otp-primer-paso');
  }

  private formSubscribe() {

    this.personalDetailsForm.get('password').valueChanges.subscribe(pass => {
      this.personalDetailsForm.get('confirmPassword').updateValueAndValidity();
    });

    this.personalDetailsForm.get('numeroDocumento').valueChanges.subscribe(numero => {
      if (this.isDNI) { this.validateDocuments('dni') };
      if (!this.isDNI) { this.validateDocuments('nie') };
    })

    this.personalDetailsForm.valueChanges.subscribe(form => {
      const numDocumento = this.personalDetailsForm.get('numeroDocumento').value;
      this.errService.mobileErrors(this.personalDetailsForm);
    });

  }

  validateDocuments(documento) {

    if (documento === 'dni') {
      this.personalDetailsForm.get('segundoApellido').setValidators([Validators.required, this.evoValidator.validateName]);
    } else {
      this.personalDetailsForm.get('segundoApellido').setValidators([]);
    }

    this.personalDetailsForm.get('segundoApellido').updateValueAndValidity();

    const numDocumento = this.personalDetailsForm.get('numeroDocumento');

    this.helpers.updateFieldValue(this.personalDetailsForm, 'documento', documento);
  }

  handleError() {
    this.loading = false;
    this.errService.navToError();
  }

  get isDNI() {
    const reg = /^\d+$/;
    return reg.test(this.personalDetailsForm.get('numeroDocumento').value[0]);
  }

  get getDocumentType() {
    return this.personalDetailsForm.get('documento').value === 'dni' ? '51' : '66';
  }

  get hideDNI() {
    return this.getDocumentType === '51';
  }

  get hideNIF() {
    return this.getDocumentType === '66';
  }

  modalClick(_ev) {
    _ev.stopPropagation();
  }

  closeModal() {
    this.modalBureau = false;
    this.helpers.navigateTo('/bienvenido');
  }

  searchSelectCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['value'] === value && item['value'] !== null) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item['selected'] && item['value'] !== null) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  private isValidID(value) {
    const invalid = false,
      validChars = 'TRWAGMYFPDXBNJZSQVHLCKET',
      nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      str = value.toString().toUpperCase(),
      nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2'),
      letter = str.substr(-1),
      charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (str.length !== 9) { return invalid };

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return invalid;
    }

    if (validChars.charAt(charIndex) === letter) {
      return true;
    }

    return invalid;
  }

  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? 'móvil' : 'correo';
  }

  modalOpenPrivacidad(e) {
    e.preventDefault();
    if (!this.personalDetailsForm.get('reactiveCheckLegal').value) {
      this.modService.open(this.modalLegal);
    }
    else {
      this.personalDetailsForm.controls['reactiveCheckLegal'].setValue(false);
    }
  }

  checkLegal() {
    this.personalDetailsForm.controls['reactiveCheckLegal'].setValue(true);
    this.modService.close(this.modalLegal);
  }

  openLegal() {
    this.modService.open(this.modalLegal);
  }

  modalOpenTipoA(e) {
    e.preventDefault();
    if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      this.modService.open(this.modalTipoA);
    }
    else {
      this.personalDetailsForm.controls['reactiveCheckTipoA'].setValue(false);
    }
  }

  checkTipoA() {
    this.personalDetailsForm.controls['reactiveCheckTipoA'].setValue(true);
    this.modService.close(this.modalTipoA);
  }

  openTipoA() {
    this.modService.open(this.modalTipoA);
  }

  selectCheckLegal(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckLegal', newValue);
  }

  selectCheckTipoA(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckTipoA', newValue);
  }

  selectCheckConsentimiento(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckSolvencia', newValue);
  }

  selectCheckPubli(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'publicidad', newValue);
  }

  selectCheckPublicidad(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckPublicidad', newValue);
  }

  public onMouseClick() {
    this.closeQuestCollapse();
    this.modService.open('faqModal');
  }

  public onMouseOver() {
    this.closeQuestCollapse();
    this.modService.open('faqModal');
  }

  public closeQuestCollapse() {
    this.estadoCollapse0 = false;
    this.estadoCollapse1 = false;
    this.estadoCollapse2 = false;
    this.estadoCollapse3 = false;
    this.estadoCollapse4 = false;
  }

  public esCuentaInteligente() {
    return this.persona.logalty && this.persona.logalty.codProductoContratado === '006';
  }


  showModal(codigo) {

    const modal: Modal = {
      name: 'modal-errores-genericos',
      title: '¡Ups!',
      blocking: false,
      withImage: true,
      message: 'Error genérico.'
    }

    switch (codigo) {
      case 'KO':
        this.loading = false;
        this.errService.altaClienteError = true;
        this.helpers.navigateTo('/404');
        break;
        case 'OK':
        break;
      case '1002': // Datos vacíos
        this.modService.open(this.bureauModal);
        modal.title = ' ¡Oh-Oh! ';
        modal.message = 'Existe un Problema con tu registro. Revisa tus datos.'
        modal.blocking = false;
        break;
      case '1009': // Debería llevar a la ventana del formulario con un pop-up y los datos completos borrando las contraseñas
        modal.title = '¡OH-OH!';
        modal.message = 'Existe un problema con tu registro. Revisa tus datos';
        modal.blocking = false;
        break;
      default:
      // Datos rellenos
      //this.helpers.navigateTo('/alta-cliente'); mantener params y recupera datos
    }
  }

}
