import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'description-text',
  templateUrl: './description-text.component.html',
  styleUrls: ['./description-text.component.scss']
})
export class DescriptionTextComponent implements OnInit {
  @Input() ciName: String;
  @Output() beginEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  startProcess(){
    this.beginEvent.emit('continuar');
  }
}
