<div class="consent-container">
    <p class="consent-container__strong">RESPONSABLE</p>
    <p>EVO Banco, S.A. (EVO). </p>
    <p>Contacto del Delegado de Protección de Datos: dpo.evo@evobanco.com</p>

    <p class="consent-container__strong">FINALIDADES Y BASES DE LEGITIMACIÓN</p>
    <p>Con tu <u>consentimiento explícito </u>procederemos a verificar tu identidad a través del servicio de video identificación.</p>

    <p>Las actividades del tratamiento que realizamos con esta finalidad son:</p>

    <ul>
      <li class="consent-container__lista">
        <p>1. Confirmación de la autenticidad y vigencia de tu Documento Nacional de Identidad. </p>
      </li>
      <li class="consent-container__lista">
        <p>2. Comparación de la fotografía de tu Documento Nacional de Identidad con la imagen captada a través de video. </p>
      </li>
      <li class="consent-container__lista">
        <p>3. Comprobación de la coincidencia de tu identidad con la información recibida. </p>
      </li>
    </ul>

    <p>Estas actividades supondrán el tratamiento de tus datos biométricos mediante tecnología de reconocimiento facial, considerados datos de categoría especial.</p>
    <p class="consent-container__strong">DESTINATARIOS</p>
    <p>No se cederán tus datos a terceros, salvo que tengamos tu consentimiento previo o sea necesario por obligación legal o para la ejecución del contrato. </p>
 
    <p class="consent-container__strong">DERECHOS</p>
    <p> Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación y portabilidad. El ejercicio de cualquier de estos
        derechos se podrá realizar a través de la dirección de correo electrónico protecciondedatos@evobanco.com 
    </p>
 
    <p> Puedes obtener información ampliada sobre cómo tratamos tus datos personales a través del siguiente enlace: https://www.evobanco.com/politica-de-privacidad/</p>
</div>