import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { navigationSteps } from '../collections/navigation';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';

import { RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ACCOUNTS } from 'app/resources/account.model';


@Injectable()
export class NavigationService implements OnInit, OnDestroy {

  codPaisDesconocido = '999';
  navs = navigationSteps;
  private personaStorage;
  private estado;
  private newProspect;
  private finalState = true;
  private currentStep: string;
  private nextStep: string;

  constructor(private authService: AuthService,
    private loginSrv: LoginService,
    private route: ActivatedRoute) {
  }
  ngOnDestroy() {
    this.newProspect = undefined;
    this.currentStep = undefined;
    this.nextStep = undefined;
    this.estado = undefined;
    this.personaStorage = undefined;
  }
  ngOnInit() {
    this.newProspect = undefined;
    this.currentStep = undefined;
    this.nextStep = undefined;
    this.estado = undefined;
    this.personaStorage = undefined;
  }

  isNavigationAllowed(nextStep: string, state: RouterStateSnapshot): any {
    const currentURI = window.location.hash.replace('#', '');
    this.nextStep = nextStep;
    let isAllowed = false;
    this.personaStorage = this.authService.getSessionProspect();
    this.currentStep = this.personaStorage ? this.personaStorage.estado : 'registro';
    this.currentStep = (this.currentStep === 'registro') ? 'bienvenido' : this.currentStep;
    
    if (this.currentStep === 'casi-lo-tenemos'
        && this.personaStorage.data
        && this.personaStorage.data.otrosDatos
        && this.personaStorage.data.otrosDatos.tipoIdentificacion === "identificacion-video"){
      this.nextStep = "ahora-nosotros";
    }
    let url: string = state.url;
    this.newProspect = true;

    // Conservar parametros si 404
    const params = location.hash.substr(location.hash.indexOf('?'));
    if (this.nextStep === '404') {
      this.nextStep = this.nextStep + params;
    }

    if (location.hash.indexOf('?') !== -1 &&
      (this.nextStep.split('?')[0] !== '404' && this.nextStep.split('?')[0] !== 'confirmacion-otp-primer-paso')) {
      url = location.hash.substring(0, location.hash.indexOf('?'));
      if (this.authService.isAuthenticated()) {
        this.newProspect = false;
        isAllowed = true;
      }
    }
    this.nextStep = url.replace('/', '').replace('#', '');

    // new propspect
    if (this.newProspect) {
      isAllowed = this.passProspectFlow(this.personaStorage);
    }

    return {
      'isAllowed': isAllowed,
      'defaultPath': currentURI
    };
  }

  passProspectFlow(personaStorage) {
    if (this.nextStep.split('?')[0] === '404') { return true; } // 404
    if (this.nextStep.split('?')[0] === 'OCR') { return true; } // OCR
    if ([18].indexOf(this.navs[this.currentStep]) !== -1) { return true; } // OCR
    if (this.loginSrv.loginData && this.loginSrv.loginData.access_token) {
      if ([8].indexOf(this.navs[this.currentStep]) !== -1) { // step by step confirm and logalty
        return this.navs[this.currentStep] === 8 && this.navs[this.nextStep] === 9;
      } else if ([9].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.navs[this.currentStep] === 9 && this.navs[this.nextStep] === 10;
      } else if ([10].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.passSignatureSection();
      } else if ([11, 28, 13, 14].indexOf(this.navs[this.currentStep]) !== -1) { // end of the process
        return !(this.navs[this.nextStep] < 11);
      }
      return true
    } // logged user
    if (this.navs[this.nextStep] === 0) { return true; } // welcome page

    let nextStep = this.nextStep.indexOf('?') === -1 ? this.nextStep : this.nextStep.substring(0, this.nextStep.indexOf('?'));
    // Pasamos de bienvenido(0) a otp-confirmacion-primer-paso(16)
    if (this.navs[this.currentStep] === 0 && this.navs[nextStep] === 16) {
      return true;
    }
    // En dos titulares se admite el paso de la pantalla de bienvenida a datos personales
    if ((this.navs[this.currentStep] === 29 && this.navs[nextStep] === 1) || 
        (this.navs[this.currentStep] === 23 && this.navs[nextStep] === 29) ) {
      return true;
    }

    if (!this.authService.isAuthenticated()) { this.currentStep = undefined; return false; } // has no session

    if (this.navs[this.nextStep] === 15) { return true; } // register identificacion edad
    // redireccion de cuenta inteligente a cuenta joven
    if (this.navs[this.nextStep] === 21) { return true; }
    // redireccion de cuenta joven a cuenta inteligente
    if (this.navs[this.nextStep] === 22) { return true; }



      // Si pasamos por el flujo de otp-confirmacion-primer-paso
      if (personaStorage.flujoOtpPrimerPaso) {
        if (this.authService.isAuthenticated()) { // has token

          // Comprobamos si es un abandono
          if (personaStorage.abandono) {
             personaStorage.abandono = false;
             this.authService.setSessionProspect(personaStorage);
           return true;
          }
          // Pasamos de otp-confirmacion-primer-paso(16) a datos-personales(1) o datos-personal(23).
          if ([16].indexOf(this.navs[this.currentStep]) !== -1) {
           return this.passOTPValidationFirstStep();
          }
          // Pasamos de datosLaboral(3) a metodoValidacion(5)
           // Al acceder a traves del path de iconos:
           // Pasamos de datosContacto(2) a datosPersonal(1)
          if ([3].indexOf(this.navs[this.currentStep]) !== -1 ||
          (this.navs[this.nextStep]) < (this.navs[this.currentStep]) &&
          ([2].indexOf(this.navs[this.currentStep]) !== -1)) {
            return this.passRegisterValidationFirstStep();
          }

          if ([3].indexOf(this.navs[this.currentStep]) !== -1 ||
            ([24].indexOf(this.navs[this.currentStep]) !== -1 &&
              this.navs[this.nextStep] == 23)) {
            return this.passRegisterValidationFirstStep();
          }

        }
      }
    if (this.authService.isAuthenticated()) { // has token
      // refresh page
      if (this.nextStep === this.currentStep) {
        return true;
      }
      // register steps
      if ([1, 2, 3, 15, 23, 24].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.passRegisterValidation();
      } else if ([4].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.passOTPValidation();
      } else if ([5].indexOf(this.navs[this.currentStep]) !== -1) { // identification
        this.authService.validatedOtp = false;
        return this.passIdentification();
      } else if ([6, 7,27].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.passConfirmData();
      } else if ([8].indexOf(this.navs[this.currentStep]) !== -1) { // step by step confirm and logalty
        return this.navs[this.currentStep] === 8 && this.navs[this.nextStep] === 9 ||
        this.navs[this.nextStep] === 17 || // pantalla Edad
        this.navs[this.nextStep] === 10; // cuenta joven redirect
      } else if ([9].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.navs[this.currentStep] === 9 && this.navs[this.nextStep] === 10;
      } else if ([10].indexOf(this.navs[this.currentStep]) !== -1) {
        return this.passSignatureSection();
      } else if ([11, 28, 13, 14].indexOf(this.navs[this.currentStep]) !== -1) { // end of the process
        return !(this.navs[this.nextStep] < 11);
      } else if (this.navs[this.nextStep] === 26) { // Verificar importe
        return true;
      } else if (this.navs[this.currentStep] === 26) {
        if (this.navs[this.nextStep] === 28) { // Ahora nosotros desde verificar importe
          return true;
        } else if (this.navs[this.nextStep] === 12) { // Casi lo tenemos desde verificar importe
          return true;
        }
        else if (this.navs[this.nextStep] === 6) { // Cambio a 'identificacion-video' desde verificar importe
          return true;
        }
      }
    }
    return false;
  }

  passSignatureSection() {
    // Tras la firma, pantalla de información de validación de la transferencia
    if (this.navs[this.currentStep] === 10 && this.navs[this.nextStep] === 25) {
      return true;
    }
    // Tras la inclusión de iberpay y la validación de iban, no se puede acceder a casi lo tenemos desde la firma
    if (this.navs[this.currentStep] === 10 && this.navs[this.nextStep] === 12) {
      return false;
    }
    if (this.navs[this.currentStep] === 10 && this.navs[this.nextStep] === 28) {
      return true;
    }
    if (this.navs[this.currentStep] === 10 &&
      (this.navs[this.nextStep] > 10 && this.navs[this.nextStep] < 15)) {
      return true;
    }
    return false;
  }

  passConfirmData() {
    const goesToConfirmData = ((this.navs[this.currentStep] === 6 || this.navs[this.currentStep] === 7|| this.navs[this.currentStep] === 27) &&
                              this.navs[this.nextStep] === 8);
    const changesIdentification = (((this.navs[this.nextStep] === 7 || this.navs[this.nextStep] === 27) && this.navs[this.currentStep] === 6)
                              || ((this.navs[this.nextStep] === 6|| this.navs[this.nextStep] === 27) && this.navs[this.currentStep] === 7)
                              || ((this.navs[this.nextStep] === 6|| this.navs[this.nextStep] === 7) && this.navs[this.currentStep] === 27));
    if (goesToConfirmData || changesIdentification) {
      return true;
    }

    return false;
  }

  passIdentification() {
    if (this.navs[this.currentStep] === 5 && (this.navs[this.nextStep] === 6 ||
      this.navs[this.nextStep] === 7 || this.navs[this.nextStep] === 27)) {
      return true;
    }
    return false;
  }

  passRegisterValidation() {

    let nextStep = this.nextStep.indexOf('?') === -1 ? this.nextStep : this.nextStep.substring(0, this.nextStep.indexOf('?'));

    let currentStepNumber: number = this.navs[this.currentStep];
    let nextStepNumber: number = this.navs[nextStep];

    // Steps 'datos-personales' y 'datos-de-contacto'
    if (currentStepNumber === 23) {
      currentStepNumber = 1;
    }
    else if (currentStepNumber === 24) {
      currentStepNumber = 2;
    }

    if (nextStepNumber === 23) {
      nextStepNumber = 1;
    }
    else if (nextStepNumber === 24) {
      nextStepNumber = 2;
    }

    if (this.personaStorage.datosDomicilio && this.personaStorage.datosDomicilio.codigoPaisResidencia === this.codPaisDesconocido) {
      this.currentStep = 'datos-contacto';
      return false;
    }

    if (nextStepNumber <= currentStepNumber && this.validateOtpNotSend()
      || (nextStepNumber === 1 && currentStepNumber === 15)
      || (nextStepNumber === 23 && currentStepNumber === 15)) {
      return true;
    } else {
      let pageSubmittedNumber: number = this.navs[this.authService.pageSubmitted];
      if (pageSubmittedNumber == 23) {
        pageSubmittedNumber = 1;
      }
      if (pageSubmittedNumber == 24) {
        pageSubmittedNumber = 2;
      }
      if (pageSubmittedNumber + 1 === nextStepNumber || nextStepNumber === 15) {
        // if the page is changing and submitting
        return true;
      }
    }

    return false;
  }


  passRegisterValidationFirstStep() {
    let currentStepNumber: number = this.navs[this.currentStep];
    let nextStepNumber: number = this.navs[this.nextStep];

    // Metodo validacion
    if ((currentStepNumber === 3) && (nextStepNumber === 5) &&
        (this.navs[this.authService.pageSubmitted] + 2 === nextStepNumber)) {
      return true;
    }
    if ((currentStepNumber === 24) && (nextStepNumber === 5) &&
      (this.navs[this.authService.pageSubmitted] - 17 === nextStepNumber)) {
      return true;
    }

    // DatosContacto - DatosPersonal para segundo titular
    if ([1, 2].indexOf(currentStepNumber) !== -1) {
      if (this.personaStorage.datosDomicilio && this.personaStorage.datosDomicilio.codigoPaisResidencia === this.codPaisDesconocido) {
        this.currentStep = 'datos-de-contacto';
        return false;
      }
      if (nextStepNumber <= currentStepNumber) {
        return true;
      }
    }

    // New CICJ - Datos de Contacto - Datos Personales
    if ([23, 24].indexOf(currentStepNumber) !== -1) {
      const recoverOldNavSteps = [24].indexOf(currentStepNumber) !== -1 && nextStepNumber === 3;
      if (recoverOldNavSteps)
      {
        return true;
      }

      // Si la ventana es datos-personal o datos-contacto, se toma como los steps 1 y 2 respectivamente.
      if (currentStepNumber == 23) {
        currentStepNumber = 1;
      }
      else if (currentStepNumber == 24) {
        currentStepNumber = 2;
      }
      if (nextStepNumber == 23) {
        nextStepNumber = 1;
      }
      else if (nextStepNumber == 24) {
        nextStepNumber = 2;
      }
      if (nextStepNumber <= currentStepNumber) {
        return true;
      }
    }

    return false;
  }

  validateOtpNotSend() {
    const currentStep = parseInt(this.navs[this.currentStep]);
    const newRegisterSteps = (currentStep ===  23 || currentStep ===  24);
    const beforeIbanValidation = newRegisterSteps || currentStep <= 4;

    if (beforeIbanValidation &&
      (!this.authService.validatedOtp ||
        this.authService.product !== ACCOUNTS.STCode)) {
      return true;
    }
    return false;
  }

  passOTPValidation(): boolean {
    if ((this.currentStep === 'confirmacion-otp' && this.navs[this.nextStep] === 5 && this.authService.validatedOtp)) {
      return true;
    }
    return false;
  }

  passOTPValidationFirstStep(): boolean {

    const validateFirstStepConfirmOTP  = this.currentStep === 'confirmacion-otp-primer-paso' && this.authService.validatedOtp;
    const toPersonalDetails = validateFirstStepConfirmOTP && this.navs[this.nextStep] === 1 || this.navs[this.nextStep] === 23;
    const toYoungAccount = validateFirstStepConfirmOTP && this.navs[this.nextStep] === 15 ;

    return (toPersonalDetails || toYoungAccount);
  }
}
