import { BusinessRelationship } from "app/collections/business-relationship";

export const BUSINESSRELATIONSHIP: BusinessRelationship[] = [
  { value: "Nómina", label: "Nómina, Pensión u Otras Compensaciones"},
  { value: "Ahorro", label: "Ahorro o inversión" },
  { value: "Financiación", label: "Financiación" },
  { value: "Actividad Profesional", label: "Actividad Profesional o Empresarial"},
  { value: "Servicio de Pago", label: "Servicio de Pago" },
  { value: "Otros", label: "Otros productos en particular" },
];
