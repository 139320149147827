<div *ngIf="showHeader" class="fixed-wrapper">
  <app-header *ngIf="!modDataError"></app-header>
  <app-header-new *ngIf="modDataError"></app-header-new>
</div>
<div class="error-modData" *ngIf="modDataError">
  <div class="inner">
    <div class="container-center">
      <div class="titulo">
        <h2>{{titulo}}</h2>
        <img class="imagen" src="{{imagen}}">
        <div class="container-info">
          <div class="subtitulo">
              <h3 *ngIf="defaultIni">
                <span *ngIf="!auth.isUnauth && !errService.logaltyCancel || (auth.isUnauth && isSegundoTitular)">{{subtitulo}}</span>
                <span *ngIf='name'>, {{name}}</span>
              </h3>
              <h3 *ngIf="!defaultIni">{{name}}</h3>
          </div>
        </div>
      </div>
        <div *ngIf="button.text.length > 0" class="mobile-container btn-group">
          <button (click)='navToModData(button.action)' type="submit" class="btn-common btn-common_fix_bold">{{button.text}}</button>
        </div>
      </div>
  </div>
</div>

<!-- Mensaje con Div contenedor background -->
<div class="main-content--error" *ngIf="false">
  <section class="c-header">
    <div class="responsive-header">
      <div class="backbutton" (click)="botonVolver()">
        <i class="evo flecha"></i>
      </div>
      <div class="page-title">
        <span>Modificar límite de crédito</span>
      </div>
      <div class="closebutton">
        <i class="evo close"></i>
      </div>
    </div>
  </section>
  <div class="cmessage" *ngIf="false">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          PREPARADO PARA FIRMAR</div>
        <img class="cmessage__imagen" src="{{imgLapiz}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Te estamos redirigiendo a Logalty, nuestro tercero de confianza
            para realizar la firma de la ampliación
            del límite de crédito de tu tarjeta de forma totalmente segura.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN Mensaje con Div contenedor background -->
<!-- Mensaje con Cabecera -->
<div class="cmessage" *ngIf="false">
  <section class="c-header">
    <div class="responsive-header">
      <div class="backbutton" (click)="botonVolver()">
        <i class="evo flecha"></i>
      </div>
      <div class="page-title">
        <span>Modificar límite de crédito</span>
      </div>
      <div class="closebutton">
        <i class="evo close"></i>
      </div>
    </div>
  </section>
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        PREPARADO PARA FIRMAR</div>
      <img class="cmessage__imagen" src="{{imgLapiz}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Te estamos redirigiendo a Logalty, nuestro tercero de confianza
          para realizar la firma de la ampliación del límite de crédito de tu tarjeta de forma totalmente segura.</p>
      </div>
    </div>
  </div>
</div>
<!-- FIN Mensaje con Cabecera -->
<!-- Mensaje ERROR EN LAS COMUNICACIONES -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES</div>
      <img class="cmessage__imagen" src="{{imgSatelite}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos perdido el contacto con Houston. ¡Por favor, vuelve a
          intentarlo más tarde!</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje ERROR EN LAS COMUNICACIONES -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES</div>
      <img class="cmessage__imagen" src="{{imgSatelite}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos perdido el contacto con Houston.  ¡Por favor, intenta subir tu DNI/NIE de nuevo!</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Reintentar</button>
    </div>
  </div>
</div>
<!-- Mensaje TARJETA BLOQUEADA -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        TU TARJETA ESTÁ BLOQUEADA</div>
      <img class="cmessage__imagen--sm" src="{{imgLock}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">No puedes modificar el límite de crédito de tu tarjeta cuando está
          bloqueada, si quieres desbloquearla
          llámanos al 910 900 900.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">CERRAR Y VOLVER</button>
    </div>
  </div>
</div>
<!-- Mensaje ENVIADA LA COMUNICACIÓN -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
      <img class="cmessage__imagen" src="{{imgAstroboy}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Estamos revisando tu documentación, cuando esté todo listo te avisaremos para que puedas continuar con tu contratación.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje ENVIADA LA COMUNICACIÓN -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
      <img class="cmessage__imagen" src="{{imgAstroboy}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Algo no nos cuadra, tenemos que revisar tu solicitud. Nos pondremos
          en contacto contigo por email lo antes posible.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje IMPREVISTO MALETIN -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HA SURGIDO UN IMPREVISTO!</div>
      <img class="cmessage__imagen" src="{{imgMaletin}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Lo sentimos, no hemos podido completar tu solicitud. Hemos
          detectado que tus datos aparecen en un fichero de
          morosidad.</p>
        <p class="cmessage__subtitulo__description">Si quieres conocer todos los detalles o crees que hay algún error,
          puedes ponerte en contacto con <span class="semibold"> Experian Bureau de Crédito en el teléfono  91 625 89
            11</span> y te informarán de todo.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje IMPREVISTO  -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HA SURGIDO UN IMPREVISTO!</div>
      <img class="cmessage__imagen" src="{{imgSecuestro}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Lo sentimos, no podemos continuar con los trámites de tu petición
          al tratarse de una solicitud incompatible
          con nuestra política de riesgos.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje VAYA OVNI -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡VAYA!</div>
      <img class="cmessage__imagen" src="{{imgVayaVaya}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos visto que nos falta información. Llámanos para que te podamos ayudar a continuar tu contratación. </p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje VAYA OVNI -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡VAYA!</div>
      <img class="cmessage__imagen" src="{{imgVayaVaya}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos visto que nos falta información. Llámanos al 910 900 900 para
          que te podamos ayudar a continuar tu modificación de límite de crédito.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje INFO ALTAVOZ -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡INFORMACIÓN IMPORTANTE!</div>
      <img class="cmessage__imagen--sm" src="{{imgAltavoz}}">
      <div class="cmessage__subtitulo--list">
        <p class="cmessage__subtitulo--list__description">Debes saber que:</p>
        <ul class="cmessage__list">
          <li class="cmessage__list__item">
            Si más adelante quieres solicitar una ampliación de límite, tendremos que volver a valorar tu solicitud.
          </li>
          <li class="cmessage__list__item">
            El importe pendiente que tengas en tu tarjeta, se cargará al mes siguiente con independencia de tu forma de
            pago, siempre y cuando el saldo dispuesto sea superior a tu nueva línea de crédito.
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-group_fix">
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
        MANTENER MI SOLICITUD
      </button>
      <button class="btn-common btn-secondary_fix--white" (click)="submit()">
        Continuar
      </button>
    </div>
  </div>
</div>
<!-- Mensaje INFO ALTAVOZ -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡INFORMACIÓN IMPORTANTE!</div>
      <img class="cmessage__imagen--sm" src="{{imgAltavoz}}">
      <div class="cmessage__subtitulo--list">
        <p class="cmessage__subtitulo--list__description"><strong>Si quieres reducir tu límite de uso temporalmente</strong>,
          manteniendo tu límite de crédito actual llama al <span class="semibold">910 900 900</span> 910 900 900. Así
          podrás cambiarlo cuando quieras con efecto inmediato.</p>
        <p class="cmessage__subtitulo--list__description">Debes saber que:</p>
        <ul class="cmessage__list">
          <li class="cmessage__list__item">
            Al solicitar ampliar tu límite, tendremos que evaluar de nuevo tu petición.
          </li>
          <li class="cmessage__list__item">
            El importe que tengas pendiente de pago en tu tarjeta, se cargará al mes siguiente con independencia de tu
            forma de pago.
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-group_fix">
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
        MANTENER MI SOLICITUD
      </button>
      <button class="btn-common btn-secondary_fix--white" (click)="submit()">
        Continuar
      </button>
    </div>
  </div>
</div>
<!-- Mensaje INFO ALTAVOZ -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡INFORMACIÓN IMPORTANTE!</div>
      <img class="cmessage__imagen--sm" src="{{imgAltavoz}}">
      <div class="cmessage__subtitulo--list">
        <p class="cmessage__subtitulo--list__description">¿Estas seguro que quieres cancelar la modificación
          solicitada? </p>
        <ul class="cmessage__list">
          <li class="cmessage__list__item">
            Si cancelas la modificación, cualquier nueva solicitud deberá ser evaluada de nuevo
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-group_fix">
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
        MANTENER SOLICITUD
      </button>
      <button class="btn-common btn-secondary_fix--white" (click)="submit()">
        Cancelar solicitud
      </button>
    </div>
  </div>
</div>
<!-- Mensaje VALIDANDO PETICIÓN -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ESTAMOS VALIDANDO TU PETICIÓN</div>
      <img class="cmessage__imagen" src="{{imgRocket}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">¡No toques nada que ya casi estamos ahí!</p>
      </div>
    </div>
  </div>
</div>
<!-- Mensaje AMIGOS -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HORA DE HABLAR CON LOS AMIGOS!</div>
      <img class="cmessage__imagen" src="{{imgAmigosOk}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">En cuanto el segundo titular XXXX lo haya firmado, te enviaremos un email a xx@gmail.com para que puedas firmarlo tu y abrir tu Fondo Inteligente</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje AMIGOS -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡HORA DE HABLAR CON LOS AMIGOS!</div>
      <img class="cmessage__imagen" src="{{imgAmigosOk}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">Hemos enviado un email al titular para que firme la contractación de la cuenta.</p>
        <p class="cmessage__subtitulo__description">En cuanto firme te enviaremos un email a xx@gmail.com felicitandote por la contratación de tu  Fondo Inteligente.</p>
      </div>
    </div>
    <div class="btn-group_fix">
      <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Entendido</button>
    </div>
  </div>
</div>
<!-- Mensaje ALGO NO CUADRA -->
<div class="cmessage" *ngIf="false">
  <div class="cmessage__container">
    <div class="cmessage__info">
      <div class="cmessage__titulo">
        ¡ALGO NO CUADRA DEL TODO!</div>
      <img class="cmessage__imagen" src="{{imgAlien}}">
      <div class="cmessage__subtitulo">
        <p class="cmessage__subtitulo__description">La imagen del documento que nos has pasado no es correcta. Intentalo de nuevo. </p>
      </div>
    </div>
    <div *ngIf="button.text.length > 0" class="mobile-container btn-group_fix">
      <button (click)='navToModData(button.action)' type="submit" class="btn-common btn-common_fix btn-common_fix_bold">{{button.text}}</button>
    </div>
  </div>
</div>

<div class="error-default" *ngIf="!modDataError">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend *ngIf="errService.altaClienteCancel || errService.altaClienteError ">
        <span class="main-icon evo error-computer"></span>
        <h2>{{title}}</h2>
        <h3>{{name}}</h3>
      </legend>
      <legend *ngIf="!errService.altaClienteCancel && !errService.altaClienteError">
        <span class="main-icon evo error-computer"></span>
        <h2>{{title}}</h2>
        <h3 *ngIf="defaultIni"><span *ngIf="!auth.isUnauth && !errService.logaltyCancel">Parece que algo está fallando</span><span *ngIf='name'>, {{name}}</span></h3>
        <h3 *ngIf="!defaultIni">{{name}}</h3>
        </legend>
    </div>
    <div class="row">
      <p *ngIf="!telf">
        {{text}}
      </p>
      <p *ngIf="telf">
        {{text}}
        <br>
        {{textPre}}
        <a class='link-white' href='tel:910900900'>910 900 900</a>
        {{textPost}}
      </p>
    </div>
    <div class="row btn-group" *ngIf="button.text.length > 0">
      <button class="btn-common btn-common_fix_bold" (click)="navTo(button.action)">
        <span>{{button.text}}</span>
      </button>
      <span class="row"></span>
    </div>
  </div>
</div>
<app-footer-center *ngIf="modDataError && showFooter"></app-footer-center>
