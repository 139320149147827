export class VideoId {
  id: string;
  process: string;
  status: string;
  tenantId: string;
  request: {
    date: string;
    securityFeaturesRequired: Boolean;
    minSimilarityLevel: string;
  };
  completion: {
    date: string;
  };
  document: {
    type: string;
    issuer: string;
    expiryDate: string;
    subject: {
      primaryName: string;
      secondaryName: string;
      firstSurname: string;
      secondSurname: string;
      birthDate: string;
      sex: string;
      nationality: string;
      personalNumber: string;
    };
    documentNumber: string;
    front: string;
    back: string;
  };
  biometrics: {
    face: {
      image: string;
      similarityLevel: string;
    };
  }

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class VerifiEID {
  videoId: string;
  rauthorityId: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class VideoParams {
  videoParams:any;
  constructor(width, height, cameraUnavailableMessage){
    this.videoParams = {
      // flip_horiz: true,
      // minSimilarityLevel: "Low",
      // quality: 80,
      ui: {
        enable: true, //controla toda la UI
        stepIntro: {
          enable: false
        }
      }
    }
  }
  getVideoParams(){
    return this.videoParams;
  }
}
