import { Injectable, Inject } from "@angular/core";
import { fromPromise } from "rxjs/observable/fromPromise";
import { Observable } from "rxjs/Observable";
import { DeviceService } from "./device.service";
import { PersonaService } from "./persona.service";
import { LoginService } from "./login.service";
import { Persona } from "../collections/persona";
import { ResponseLogin } from "../collections/login";
import { NestedObject } from "./nestedObject.service";
import { ProductSelection } from "./product-selection.service";
import "rxjs/add/observable/of";
import "rxjs/add/operator/do";
import "rxjs/add/operator/delay";
import {
  ListPoliciesResponse,
  ListPolicies,
} from "../collections/list-policies";
import { MicrosegConfigService } from "./microinsurance-config.service";
import { ACCOUNTS } from "app/resources/account.model";
import { ErrorService } from "./error.service";
import { of } from "rxjs";

@Injectable()
export class AuthService {
  isLoggedIn = false;
  pageSubmitted = "";
  private countFormFields = 0;
  private prevVal: Object = {};
  private evaluatedControls = {};
  private prevForm: Object;
  personaService: PersonaService;
  sessionProspect: Persona;

  constructor(
    @Inject(PersonaService) personaService: PersonaService,
    private loginSrv: LoginService,
    public deviceService: DeviceService,
    public nestedObject: NestedObject,
    public productSelect: ProductSelection,
    public errService: ErrorService,
    public microConfigSrv: MicrosegConfigService
  ) {
    this.personaService = personaService;
    this.sessionProspect = this.getSessionProspect();
  }

  public getToken(): string {
    return sessionStorage.getItem("auth_token");
  }
  public isAuthenticated(): boolean {
    return !!this.getToken();
  }

  login(login): Observable<any> {
    const promiseLogin = new Promise((resolve, reject) => {
      this.loginSrv.getLogin(login).subscribe(
        (data) => {
          resolve(true);
        },
        (error) => {
          reject(false);
        }
      );
    });
    return fromPromise(promiseLogin);
  }

  logout(): void {
    sessionStorage.clear();
    this.isLoggedIn = this.isAuthenticated();
  }

  registerProspect(prospect): Observable<any> {
    let product = this.productSelect.getProductSelected();
    if (!!product) {
      product = product.codProductoContratado;
    } else {
      product = this.product;
    }
    prospect.logalty = Object.assign({ codProductoContratado: product });
    const promiseProspect = new Promise((resolve, reject) => {
      return this.personaService.addPersona(prospect).subscribe(
        (data) => {
          prospect.datosPersonales.id_cliente_EVO = data["idPersona"];
          // this.deletePreviousSession();
          this.setSessionProspect(prospect);
          this.isLoggedIn = this.isAuthenticated();
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });

    return fromPromise(promiseProspect);
  }

  updateProspect(prospect): Observable<any> {
    let product = this.productSelect.getProductSelected();
    let isSecondSigner = false;
    if (!!product) {
      if (product && product.isSecondSigner) {
        isSecondSigner = (product.isSecondSigner) ? true : false;
      }
      product = product.codProductoContratado;      
    } else {
      product = this.product;
    }
    if (typeof prospect.logalty === "undefined")
      prospect.logalty = this.nestedObject.nestedObjectAssign({});
      prospect.logalty.codProductoContratado = product;
      prospect.logalty.isSecondSigner = isSecondSigner;
    if (product === ACCOUNTS.TipoACode) {
      return of();
    }
    if (sessionStorage.getItem("codigoError")) {
      const codError = Number(sessionStorage.getItem("codigoError"));
      prospect.codigoError = codError;
    }
    if (sessionStorage.getItem("discriminatorInfo")) {
      prospect.discriminador = JSON.parse(
        sessionStorage.getItem("discriminatorInfo")
      );
    }
    let promiseProspect = new Promise((resolve, reject) => {
      return this.personaService.updatePersona(prospect).subscribe(
        (data) => {
          if (data["idPersona"]&&prospect.datosPersonales)
            prospect.datosPersonales.id_cliente_EVO = data["idPersona"];
          if(data.promo !== undefined)
            prospect.promo = {
              numeroPack: data.promo["numeroPack"] ? data.promo["numeroPack"] : '',
              idPersona: data.promo["idPersona"] ? data.promo["idPersona"] : '',
              fechaInicioPromo: data.promo["fecha_inicio_Promo"] ? data.promo["fecha_inicio_Promo"] : '',
              fechaFinPromo: data.promo["fecha_fin_Promo"] ? data.promo["fecha_fin_Promo"] : '',
              activo: data.promo["activo"] ? data.promo["activo"] : false
            }
          this.setSessionProspect(prospect);
          this.isLoggedIn = this.isAuthenticated();
          resolve(data);
          if (data.clienteBloqueado) {
            this.errService.navToErrorEid();
          }
          sessionStorage.setItem("clienteBloqueado", data.clienteBloqueado);
          if (data.confirma) {
            const cProspect = data;
            sessionStorage.setItem("cProspect", JSON.stringify(cProspect));
          }
        },
        (error) => {
          this.pageSubmitted = "";
          reject(error);
        }
      );
    });
    return fromPromise(promiseProspect);
  }

  updatePersonConfirma(prospect): Observable<any> {
    let product = this.productSelect.getProductSelected();
    if (!!product) {
      product = product.codProductoContratado;
    } else {
      product = this.product;
    }
    prospect.logalty = Object.assign({ codProductoContratado: product });
    return this.personaService.updatePersonConfirma(prospect);
  }

  deletePreviousSession() {
    sessionStorage.clear();
    this.isLoggedIn = this.isAuthenticated();
  }

  setSessionProspect(prospect) {
    const previousProspect =
      JSON.parse(sessionStorage.getItem("currentProspect")) || {};
    const currentProspect = this.nestedObject.nestedObjectAssign(
      {},
      previousProspect,
      prospect
    );
    sessionStorage.setItem(
      "currentProspect",
      JSON.stringify(currentProspect) || null
    );
  }

  getSessionProspect() {
    const storageProspect = sessionStorage.getItem("currentProspect");
    const prospect = storageProspect ? JSON.parse(storageProspect) : null;

    if (
      prospect &&
      prospect.datosPersonales &&
      prospect.datosPersonales.id_cliente_EVO
    )
      return prospect;

    return new Persona();
  }

  set validatedOtp(valid: boolean) {
    sessionStorage.setItem("otp", String(valid));
  }

  get validatedOtp(): boolean {
    return Boolean(sessionStorage.getItem("otp"));
  }

  getProspect(clientID: string): Observable<Persona> {
    let product = this.productSelect.getProductSelected();
    if (!!product) {
      product = product.codProductoContratado;
    } else {
      product = this.product;
    }
    return this.personaService.getPersonaById(clientID, product);
  }

  checkDuplicateDNI(rsi_data: Object): Observable<any> {
    return this.personaService.checkDuplicateDNI(rsi_data);
  }

  checkPhoneEmail(data: Object): Observable<any> {
    return this.personaService.checkPhoneEmail(data);
  }

  public updateState(state: string) {
    const canal = this.deviceService.getDevice();
    if(state === "404"){
      state = "";
    }
    const updatedPersona = {
      canal: canal,
      estado: state,
      codigoEntidad: "0239",
    };

    this.updateProspect(updatedPersona).subscribe(
      (data) => {},
      (error) => {
        this.pageSubmitted = "";
      }
    );
  }

  public preventAbandon(view, event?) {
    view.setFields();
    this.updateProspect(view.persona).subscribe(
      (data) => console.log("save"),
      (error) => this.deletePreviousSession()
    );
    if (event) return (event.returnValue = "prevent");
  }

  private _resetEvaluation(view) {
    if (view !== this.prevForm) {
      this.prevForm = view;
      this.countFormFields = 0;
      this.evaluatedControls = {};
    }
  }

  private _checkEvalControls(form, view) {
    Object.keys(form.controls).forEach((control) => {
      const field = form.get(control);
      if (field.disabled || field.value === "") return;
      if (field.valid && !field.pristine) {
        if (!this.evaluatedControls[control]) {
          this.evaluatedControls[control] = field;
          this.countFormFields++;
        }

        if (this.countFormFields !== 0 && this.countFormFields % 3 === 0) {
          this.countFormFields = 0;
          view.setFields();
          this.updateProspect(view.persona);
        }
      }
    });
  }

  public saveUpdatedForm(form, view, val) {
    this._resetEvaluation(view);

    if (!this.prevVal) this.prevVal = val;

    if (JSON.stringify(this.prevVal) !== JSON.stringify(val)) {
      this.prevVal = val;
      this._checkEvalControls(form, view);
    }
  }

  set product(product) {
    sessionStorage.setItem("product", product);
  }

  get product() {
    return sessionStorage.getItem("product");
  }

  getIbanProduct() {
    const userData = this.loginSrv.userData;
    const listProductosContratados = userData.listaContratados;
    let iban = null;
    for (const product of listProductosContratados) {
      if (product.idProducto === ACCOUNTS.CICode) {
        iban = product.ibanCC;
        break;
      }
      if (product.idProducto === ACCOUNTS.CJCode) {
        iban = product.ibanCC;
        break;
      }
      if (product.idProducto === ACCOUNTS.CDCode) {
        iban = product.ibanCC;
        break;
      }
    }
    return iban;
  }

  checkExistIbanInProducts(iban) {
    const userData = this.loginSrv.userData;
    const listProductosContratados = userData.listaContratados;
    let exist = false;
    for (const product of listProductosContratados) {
      if (product.iban === iban) {
        exist = true;
        break;
      }
    }
    return exist;
  }

  loadPolicies(): Observable<ListPoliciesResponse> {
    return new Observable((observer) => {
      const listPolicies: ListPolicies = Object.assign({
        clientNif: this.loginSrv.userData.persona.idExterno,
        branchAlias: "0019",
        status: ["1", "2"],
      });
      this.microConfigSrv.listPolicies(listPolicies).subscribe(
        (res) => {
          const response: ListPoliciesResponse = res;
          if (response.list != null && response.list.length > 0) {
            const policy = response.list[0];
            this.loginSrv.listPolicies = policy;
          }
          observer.next(res);
          observer.complete();
        },
        (error) => {
          console.log(error);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }
}
