import { Component, Input, OnInit, ElementRef, ComponentRef, Output, EventEmitter, ViewChild} from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modalId:string;
  @Input() modalTitle: string;
  @Input() blocking = false;
  @Input() removePadding = false;
  @Input("full-screen") fullScreen: boolean = false;
  @Input("modal-wide") modalWide: boolean = false;

  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();
  @Output('scroll') scroll: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalContainer') public modalContainer: ElementRef;

  backdropId:string;

  hidden: boolean = true;

  constructor(public modService: ModalService, private el:ElementRef) {
    this.scroll = new EventEmitter<any>();
  }

  ngOnInit() {
    this.el.nativeElement.id = this.modalId;
    this.backdropId = `backdrop-${this.modalId}`;
    this.modService.registerModal(this);
    this.listenScrollChange();
  }

  private listenScrollChange(): void {
    if (this.modalContainer.nativeElement) {
      this.modalContainer.nativeElement.onscroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = this.modalContainer.nativeElement;
        this.scroll.emit({top: scrollTop, bottom: scrollHeight - scrollTop - clientHeight})
      }
    }
  }

  close(): void {
    if(this.modalId === "modal-identify-video" || this.modalId === "call-me"){
      this.reload.emit(this.modalId);
    }
    this.modService.close(this.modalId, true);
  }
  //Evitamos que se al clickar sobre el modal se cierre
  modalClick(_ev) {
    _ev.stopPropagation();
  }

  getPadding() {
    if(this.removePadding) {
      return "0";
    } else {
      return "";
    }
  }
}
