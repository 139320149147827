<div class="dropdown-group" (click)="btnClick($event)">
  <button type="button" 
          [ngClass]="show? 'open': ''" 
          [class]="isCi ?getWith() : ''" 
          class="dropdown-toggle"
          [title]="_selectName" 
          role="buttom" 
          aria-expanded="false" 
          [attr.disabled]="_disabled?'':null">
    <span class="filter-option"
          [ngClass]='_selectClass'>
          {{_selectName && _selectName.length > 20 && needSplit ? _selectName.substr(0,20)+'...': _selectName }}
    </span>
    <span class="caret" [ngClass]="show?'up':''" *ngIf="!_autocomplete"></span>
    <span class="evo info" *ngIf="_autocomplete"
    user-tooltip [specificClass]="'autocomplete'"
    [tooltip]="'INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ'"></span>
  </button>
  <div  class="dropdown-menu" 
        [ngClass]="show? getDropWith(): ''" 
        [class]="isUp?'up':''"
        role="combobox" 
        [ngStyle]="{'max-height': maxHeight}" >
    <input type="text" class="autocomplete" #autocomplete placeholder="Introduce el código CNAE o las letras" name="autocomplete" *ngIf="_autocomplete" (keyup)="filterOptions($event)">
    <ul class="inner" role="listbox" aria-expanded="false">
      <li *ngFor="let option of optiontreated | autoFilter:_keyup;">
        <a role="option"
        id='{{option.name}}'
        [ngClass]='option.value === null? "placeholder" : ""'
        (click)="selectItem(option)">
          <span>
            {{option.name }}
          </span>
        </a>
      </li>
    </ul>
  </div>
  <select [id]="id" [name]="name" class="selectpicker" tabindex="-398">
    <option *ngFor="let option of optiontreated" [value]="option.value" [attr.aria-selected]="option.selected === true?true:false"
      [attr.checked]="option.selected === true?true:false">{{option.name }}</option>
  </select>
</div>
