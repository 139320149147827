import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ACCOUNTS } from "app/resources/account.model";
import { ESTADO_CIVIL } from "app/resources/civil-status.model";
import { DOCUMENTOS } from "app/resources/document.model";
import { GENEROS } from "app/resources/genders.model";
import { PROVINCES } from "app/resources/provincias.model";
import { REGIMENES } from "app/resources/regimen-economico.model";

import { Persona } from "app/collections/persona";
import { Register } from "app/collections/register";

import { Modal } from "app/collections/modal";
import { User } from "app/collections/user";
import { BUSINESSRELATIONSHIP } from "app/resources/business-relationship.model";
import { ERRORSFORM } from "app/resources/errors-form";
import { REGISTERLITERALS } from "app/resources/register-literals";
import { AnalyticsService } from "app/services/analytics.service";
import { ApiLocalidadesService } from "app/services/api/api.localidades.service";
import { AuthUserService } from "app/services/auth-user.service";
import { AuthService } from "app/services/auth.service";
import { DeviceService } from "app/services/device.service";
import { ErrorService } from "app/services/error.service";
import { FormValidatorService } from "app/services/form-validator.service";
import { HeaderService } from "app/services/headers.service";
import { HelperService } from "app/services/helpers.service";
import { InjectScriptService } from "app/services/inject-script.service";
import { JsonService } from "app/services/json.service";
import { LocalidadesService } from "app/services/localidades.service";
import { LoginService } from "app/services/login.service";
import { ModalService } from "app/services/modal.service";
import { PersonaService } from "app/services/persona.service";
import { ProductSelection } from "app/services/product-selection.service";
import { SecurityService } from "app/services/security-registration.service";
import { SpecificAnalyticsService } from "app/services/specific-analytics.service";
import { environment } from "environments/environment";
import { SelectItem } from "primeng/api";
import { Router } from "@angular/router";

declare var addClientId: any;

@Component({
  selector: "app-personal-details",
  templateUrl: "./personal-details.component.html",
  styleUrls: ["./personal-details.component.scss"],
  providers: [
    FormValidatorService,
    ModalService,
    DeviceService,
    JsonService,
    LocalidadesService,
    ApiLocalidadesService,
  ],
})
export class PersonalDetailsCICJComponent implements OnInit {
  loading = false;
  personalDetailsForm: FormGroup;
  personalDetailsValues: Register;
  private persona: Persona = this.authService.getSessionProspect();
  firstElement: any;
  modalResidenceCard = "modalResidenceCard";
  modalNeedYourAprobation = "modalNeedYourAprobation";
  modalInfoNie = "modalInfoNie";
  modal = new Modal();
  public bureauModal = "bureauModal";
  alreadyClientModal = "already-client";
  alreadyClientTestAB = "already-client-a-b";
  modalLopd = "modal-lopd";
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg =
    "Lo sentimos, no podemos seguir con la solicitud online. Llámanos al " +
    this.telephoneMsg +
    " y te explicamos los motivos.";
  incompleteMsg =
    "Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.";
  dniErrorMsg =
    "Parece que ha habido algún error. Por favor, vuelve a introducir tus datos.";
  public modalBureau = false;
  documents = DOCUMENTOS;
  genders = GENEROS;
  provinces = PROVINCES;
  civilStatus = ESTADO_CIVIL;
  economicSystems = REGIMENES;
  localizations;
  loginUser: FormGroup;
  errMsg;
  private passStr = "";
  public firstTouch = true;
  public firstTouchPrevented = true;
  public isYoungAccount = false;
  private userData: User;
  modalLog = false;
  dniExist = false;
  hideInfoResidenceCard = true;
  videoElementId: any = "identifyVideo";
  public idUser: string;
  public mouseover = false;
  public mostrarFaq = false;
  // Propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep = true;
  sessionDatosPrimerTitular: any;
  public codigoRetornoDni: number;
  // Variables collapse
  public estadoCollapse0 = false;
  public estadoCollapse1 = false;
  public estadoCollapse2 = false;
  public estadoCollapse3 = false;
  public estadoCollapse4 = false;
  public errors = ERRORSFORM;
  public checks = REGISTERLITERALS["checks"];
  public isCIAccount: boolean = false;
  showModalActacionPerfilado: number = 0;
  optionExistUserInfo;

  tooltipEnabled = true;
  saveModal = "saveDataSuccessModalCI";
  backModal = "backModalCI";
  errorSendModalEmail = "errorSendModalEmail";
  placeholderEmailValue: string = "";
  placeholderConfirmEmailValue: string = "";
  domains = ["gmail.com", "hotmail.com", "outlook.com", "yahoo.com"];
  public CLIENTE_RELACIONES_CANCELADAS: boolean = false;
  public isDniEquals = false;

  businessRelationship: SelectItem[] = BUSINESSRELATIONSHIP;
  selectedBusinessRelationship: string[] = [];
  items: SelectItem[];

  item: string;

  datosSocioEco = {
    ahorroInversion: "N",
    nominaPensionOtros: "N",
    financiacion: "N",
    actividadProfesionalPBC: "N",
    ServiciosPago: "N",
    OtrosProductos: "N",
  };
  placeHolderText: string = "Escribe";


  @HostListener("window:beforeunload", ["$event"])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }

  constructor(
    private fb: FormBuilder,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    private authService: AuthService,
    private authUserService: AuthUserService,
    private loginSrv: LoginService,
    public deviceService: DeviceService,
    private jsonService: JsonService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private product: ProductSelection,
    private inject: InjectScriptService,
    private securitySrv: SecurityService,
    private personaSrv: PersonaService,
    private route: Router
  ) {
    this.isCIAccount = true;
    // location.href.indexOf(ACCOUNTS[ACCOUNTS.CICode].constPath) !== -1 ||
    // this.authService.product === ACCOUNTS.CICode;
  }
  ngOnInit() {
    this.CLIENTE_RELACIONES_CANCELADAS =
      JSON.parse(sessionStorage.getItem("currentProspect")).claseDeCliente ===
      "03";
    this.createForm();
    this.createFormLogin();
    this.initModal();
    this.modService.open(this.bureauModal);
    this.idUser = JSON.parse(
      sessionStorage.getItem("currentProspect")
    ).datosPersonales.id_cliente_EVO;
    this.errService.mobileErrors(this.personalDetailsForm);
    this.firstElement = this.documents[0];
    this.validateDocuments(this.personalDetailsForm.get("documento").value);
    //control if the users has sent the id (email|phone)
    this.isYoungAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CJCode ||
      location.host === environment.hostYoungAccount;
    addClientId.addId(this.idUser);

    // llamada al checkout analitica
    this.analytics.triggerBeginCheckout({ step: "Paso 02 - Datos personales" });

  }

  private createForm() {
    // Si SF devuelve la cadena "A RELLENAR" en los apellidos, se toma como un campo vacío.

    let primerApellido: string = this.CLIENTE_RELACIONES_CANCELADAS
      ? ""
      : this.jsonService.getKeyFromJSON(
          this.persona,
          "datosPersonales.apellido1Cliente"
        ) || "";
    if (
      primerApellido &&
      primerApellido.toUpperCase().indexOf("A RELLENAR") > -1
    ) {
      primerApellido = "";
    }
    this.personalDetailsForm = this.fb.group(
      {
        numeroDocumento: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? ""
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "datosPersonales.idExterno"
              ) || "",
          [Validators.required, this.evoValidator.validateID],
        ],
        documento: [this.setInitialValueDocument(), [Validators.required]],
        nombre: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? ""
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "datosPersonales.nombreCliente"
              ) || "",
          [Validators.required, this.evoValidator.validateName],
        ],
        primerApellido: [
          primerApellido || "",
          [Validators.required, this.evoValidator.validateName],
        ],
        segundoApellido: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? ""
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "datosPersonales.apellido2Cliente"
              ) || "",
          [Validators.required, this.evoValidator.validateName],
        ],
        email: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? ""
            : this.jsonService
                .getKeyFromJSON(this.persona, "datosPersonales.email")
                || "",
          [Validators.minLength(1), Validators.maxLength(50), Validators.email, Validators.required],
        ],
        confirmEmail: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? ""
            : this.jsonService
                .getKeyFromJSON(this.persona, "datosPersonales.email")
                || "",
          [Validators.minLength(1), Validators.maxLength(50), Validators.email, Validators.required],
        ],
        selectAllPermissions: [false],
        reactiveCheckPerfilado: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? false
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "indPerfiladoCliente"
              ) || false,
        ],
        reactiveCheckPublicidad: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? false
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "otrosDatos.indPubliPe"
              ) || false,
        ],
        reactiveCheckCesion: [
          this.CLIENTE_RELACIONES_CANCELADAS
            ? false
            : this.jsonService.getKeyFromJSON(
                this.persona,
                "indCesiEmpGrupo"
              ) || false,
        ],
        publicidad: [false],
        residenceCardT: [true],
        residenceCardF: [false],
        businessRelationship: [
          this.loadAccountPurposeData(),
          [Validators.required],
        ],
      }
    );
    this.isTouchedOfhaveValue();
    this.formSubscribe();
  }
  loadAccountPurposeData() {

    const datosSocioEco = {
      ahorroInversion: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.ahorroInversion"
      ),
      nominaPensionOtros: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.nominaPensionOtros"
      ),
      financiacion: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.financiacion"
      ),
      actividadProfesionalPBC: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.actividadProfesionalPBC"
      ),
      ServiciosPago: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.ServiciosPago"
      ),
      OtrosProductos: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.OtrosProductos"
      ),
    };

    const purposeData = [];

    if (datosSocioEco.ahorroInversion === "S") {
      purposeData.push("Ahorro");
    }
    if (datosSocioEco.nominaPensionOtros === "S") {
      purposeData.push("Nómina");
    }
    if (datosSocioEco.financiacion === "S") {
      purposeData.push("Financiación");
    }
    if (datosSocioEco.actividadProfesionalPBC === "S") {
      purposeData.push("Actividad Profesional");
    }
    if (datosSocioEco.ServiciosPago === "S") {
      purposeData.push("Servicio de Pago");
    }
    if (datosSocioEco.OtrosProductos === "S") {
      purposeData.push("Otros");
    }
    this.selectedBusinessRelationship = purposeData;
  }
  removeItem(item: any, event: any) {
    event.stopPropagation();

    const index = this.selectedBusinessRelationship.indexOf(item);
    if (index >= 0) {
      this.selectedBusinessRelationship.splice(index, 1);
    }
  }

  //autocompletar del campo email
  inputChangeEmail() {
    this.placeholderEmailValue = "";
    const valueSplit = this.personalDetailsForm.get("email").value.split("@");
    const domain = valueSplit && valueSplit.length > 1 ? valueSplit[1] : null;
    if (domain) {
      const filteredDomains = this.domains.filter(
        (e) => e.indexOf(domain) === 0
      );
      if (filteredDomains && filteredDomains.length > 0) {
        this.placeholderEmailValue =
          this.personalDetailsForm.get("email").value +
          filteredDomains[0].substring(domain.length);
      }
    }

    if (this.personalDetailsForm.get("email").value === "") {
      this.personalDetailsForm.get("confirmEmail").setValue("");
      this.placeholderConfirmEmailValue = "";
    }
  }
  tabKeyPressedEmail() {
    if (this.placeholderEmailValue) {
      this.personalDetailsForm
        .get("email")
        .setValue(this.placeholderEmailValue);
    }
  }

  //autocompletar del campo confirmEmail
  inputChangeConfirmEmail() {
    this.placeholderConfirmEmailValue = "";
    const valueSplit = this.personalDetailsForm
      .get("confirmEmail")
      .value.split("@");
    const domain = valueSplit && valueSplit.length > 1 ? valueSplit[1] : null;
    if (domain) {
      const filteredDomains = this.domains.filter(
        (e) => e.indexOf(domain) === 0
      );
      if (filteredDomains && filteredDomains.length > 0) {
        this.placeholderConfirmEmailValue =
          this.personalDetailsForm.get("confirmEmail").value +
          filteredDomains[0].substring(domain.length);
      }
    }
  }
  tabKeyPressedConfirmEmail() {
    if (this.placeholderConfirmEmailValue) {
      this.personalDetailsForm
        .get("confirmEmail")
        .setValue(this.placeholderConfirmEmailValue);
    }
  }

  isTouchedOfhaveValue() {
    //Pone el foco en el input del telefono
    let isTouched = false;
    if (this.personalDetailsForm.get("numeroDocumento").touched) {
      isTouched = true;
    }
    return isTouched;
  }
  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [""],
      pass: [""],
    });

    this.loginUser.get("user").valueChanges.subscribe((user) => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ["user"]);
      }
    });

    this.loginUser.valueChanges.subscribe(() => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  initModal() {
    this.modal = Object.assign({
      name: "modal-errores-genericos",
      title: "¡Vaya!",
      withImage: false,
      message: `Tenemos un problema con tu solicitud online.
      <br>
      Llámanos al <a href='tel:910900900'>910 900 900</a> para que podamos ayudarte a continuar.`,
    });
  }

  setFields(modalLog) {
    const canal = this.deviceService.getDevice();

    if (!modalLog) {

      let codigoIdExterno: string;
      if(this.route.url === '/datos-personal') {
        codigoIdExterno = this.isDNI ? "51" : this.persona.datosPersonales.codigoIdExterno;
      } else {
        codigoIdExterno = this.isDNI ? "51" : "66"
      }

      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        //Asigna el valor 0 si el usuario no tiene NIE con fotografía
        codigoError: this.persona.codigoError ? this.persona.codigoError : undefined,
        datosPersonales: {
          email: this.helpers.returnValue(
            this.personalDetailsForm.get("email")
          ),
          nombreCliente: this.helpers.returnValue(
            this.personalDetailsForm.get("nombre")
          ),
          apellido1Cliente: this.helpers.returnValue(
            this.personalDetailsForm.get("primerApellido")
          ),
          apellido2Cliente: this.helpers.returnValue(
            this.personalDetailsForm.get("segundoApellido")
          ),
          oficinaCliente: environment.oficina,
          codigoIdExterno: codigoIdExterno,
          idExterno: this.helpers.returnValue(
            this.personalDetailsForm.get("numeroDocumento")
          )
            ? this.helpers
                .returnValue(this.personalDetailsForm.get("numeroDocumento"))
                .toUpperCase()
            : undefined,
        },
        datosSocioEco: {
          ...this.datosSocioEco,
        },
        activeOtpFirstStep: this.activeOtpFirstStep,
        indPerfiladoCliente:
          this.helpers.returnValue(
            this.personalDetailsForm.get("reactiveCheckPerfilado")
          ) || false,
        indCesiEmpGrupo:
          this.helpers.returnValue(
            this.personalDetailsForm.get("reactiveCheckCesion")
          ) || false,
        otrosDatos: {
          indPubliPe:
            this.personalDetailsForm.get("reactiveCheckPublicidad").value ||
            false,
        },
        consentimientoTGSS: true, // TODO Revisar cuando actualizar este valor, antes se enviaba en previa-firma con los checks
      });
    } else {
      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        estado: "cliente-existente",
      });
    }
  }

  nombre: string;
  apellido1: string;
  apellido2: string;

  toUpperCase(field: string, text: string): void {
    if (!text || !field) return;

    const textWithoutAccents = this.removeAccents(text);
    this.personalDetailsForm.get(field).setValue(textWithoutAccents.toUpperCase());

  }

  removeAccents(text: string): string {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  goToMap() {
    window.open("https://www.evobanco.com/donde-estamos/");
  }

  filterValidControls() {}

  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, "user", [
      Validators.required,
      Validators.minLength(9),
    ]);
    this.helpers.updateFieldValidation(this.loginUser, "pass", [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get("pass");
    const userInput = this.loginUser.get("user");
    if (userInput.invalid || passInput.invalid) {
      return;
    }

    const login: string = `username=${userInput.value.toUpperCase()}&password=${
      passInput.value
    }`;

    this.loading = true;
    const tag = "GTM-TFMGJVG";
    this.inject.addScriptGoogle("script", tag);
    this.authService.login(login).subscribe(
      () => {
        if (this.loginSrv.isLoggedIn) {
          if (
            !this.helpers.isEmpty(this.loginSrv.userData) &&
            !sessionStorage.getItem("reloadUserData")
          ) {
            this.userData = this.loginSrv.userData;
            this.redirectLog();
          } else {
            sessionStorage.removeItem("reloadUserData");
            this.authUserService.getUserData().subscribe(
              (data) => {
                this.userData = data;
                this.loginSrv.userData = this.userData;
                this.redirectLog();
              },
              () => {
                this.errService.rsi = true;
                this.errService.navToError();
              }
            );
          }
        } else {
          this.helpers.navigateTo("/login");
        }
      },
      () => {
        this.loading = false;
        this.errMsg = `Parece que hay algún error, comprueba que has introducido
      correctamente los datos. Recuerda que por seguridad,
      tres errores consecutivos en la contraseña bloquearán el acceso`;
        if (passInput.value.length === 6) {
          this.helpers.updateFieldValue(this.loginUser, "pass", "");
        }
      }
    );
  }

  redirectLog() {
    if (
      this.product.product &&
      this.product.product.codProductoContratado === ACCOUNTS.STCode
    ) {
      const listaContratados = this.userData.listaContratados;
      for (const product of listaContratados) {
        if (product.idProducto === ACCOUNTS.CICode) {
          this.errService.isTitularInteligente = true;
          this.errService.navToError();
          break;
        }
        if (product.idProducto === ACCOUNTS.CJCode) {
          this.errService.isTitularJoven = true;
          this.errService.navToError();
          break;
        }
        this.helpers.navigateTo("confirmacion-datos");
      }
    } else {
      this.helpers.navigateTo("perfil");
    }
  }

  loadUserData() {
    if (this.loginSrv.isLoggedIn) {
      this.loading = true;
      if (
        !this.helpers.isEmpty(this.loginSrv.userData) &&
        !sessionStorage.getItem("reloadUserData")
      ) {
        this.loading = false;
        this.userData = this.loginSrv.userData;
        console.log("USERDATA", this.userData);
        return;
      }
      sessionStorage.removeItem("reloadUserData");
      this.authUserService.getUserData().subscribe(
        (data) => {
          this.loading = false;
          this.userData = data;
          console.log("USERDATA2", this.userData);
          this.loginSrv.userData = this.userData;
        },
        () => {
          this.errService.rsi = true;
          this.errService.navToError();
        }
      );
    } else {
      this.helpers.navigateTo("/login");
    }
  }

  validUser() {
    const userInput = this.loginUser.get("user");
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, "user", [
        Validators.required,
        Validators.minLength(9),
      ]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get("pass").value.length < inputMaxLength) {
      this.passStr =
        this.loginUser.get("pass").value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, "pass", []);
      this.helpers.updateFieldValue(this.loginUser, "pass", this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, "pass", [
        Validators.required,
        Validators.minLength(6),
      ]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, "user", []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, "pass", this.passStr);
  }

  hasNoPassword() {
    // mail with pass
    this.modService.close(this.alreadyClientModal);
  }

  isNotMe() {
    // mail user
    this.modService.close(this.alreadyClientModal);
  }

  triggerErrorAnalytics() {
    const invalidString = this.setTipeError();
    if (invalidString !== "") return;
  }
  setTipeError(): any {
    let countWrongLabel = 0;
    let countWhiteLabel = 0;
    let typeError = "";
    const invalidFields = this.errService.errorsArray.map((error) => {
      countWhiteLabel++;
      typeError = "Envío KO: Campos pendientes de completar";
      if (error.field && this.personalDetailsForm.get(error.field).value) {
        countWrongLabel++;
      }
    });
    if (countWhiteLabel > 0 && countWhiteLabel === countWrongLabel) {
      typeError = "Envío KO: Campos erróneos";
    }
    return typeError;
  }
  onSubmit() {
    let returned = false;

    this.helpers.updateFormStatus(this.personalDetailsForm);
    //Se comprueba si el formulario es válido
    if (!this.personalDetailsForm.valid) {
      this.modal = Object.assign({
        name: "modal-errores-genericos",
        title: "¡Ups!",
        withImage: true,
        blocking: false,
        message: this.incompleteMsg,
      });

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      this.triggerErrorAnalytics();
      //return false;
      returned = true;
    } else if (
      !this.personalDetailsForm.get("reactiveCheckPerfilado").value &&
      this.showModalActacionPerfilado < 1
    ) {
      this.modService.open(this.modalNeedYourAprobation);
      returned = true;
    } else if (
      this.validateEmailPattern(this.personalDetailsForm.get("email"))
    ) {
      this.modService.open(this.errorSendModalEmail);
      returned = true;
    }

    if (returned) {
      return false;
    }

    this.loading = true;
    this.fillSocioeconomicData();
    this.setFields(this.modalLog);
    this.authService.updateProspect(this.persona).subscribe((data) => {
      if (
        this.personalDetailsForm.get("numeroDocumento").value == "00000000T" &&
        JSON.parse(sessionStorage.getItem("currentProspect")).datosPersonales
          .telefonoMovil === "888888888"
      ) {
        this.continuarSubmit();
      } else {
        if (data.response.codigo !== "OK") {
          this.loading = false;
          let modalToShow = this.modService.selectStatusModal(data.response);
          this.modal = modalToShow;
          this.modService.open(this.modal.name, this.modal.blocking);
          window.scrollTo(0, 0);
        } else {
          this.dniExist = false;
          this.continuarSubmit();
        }
      }
    });
  }
  fillSocioeconomicData() {
    this.datosSocioEco = {
      ahorroInversion: "N",
      nominaPensionOtros: "N",
      financiacion: "N",
      actividadProfesionalPBC: "N",
      ServiciosPago: "N",
      OtrosProductos: "N",
    }
    let selectedData = this.personalDetailsForm.get(
      "businessRelationship"
    ).value;

    if (selectedData.length) {
      selectedData.forEach((selected) => {
        switch (selected) {
          case "Ahorro":
            this.datosSocioEco.ahorroInversion = "S";
            break;
          case "Nómina":
            this.datosSocioEco.nominaPensionOtros = "S";
            break;

          case "Financiación":
            this.datosSocioEco.financiacion = "S";
            break;
          case "Actividad Profesional":
            this.datosSocioEco.actividadProfesionalPBC = "S";
            break;

          case "Servicio de Pago":
            this.datosSocioEco.ServiciosPago = "S";
            break;

          case "Otros":
            this.datosSocioEco.OtrosProductos = "S";
            break;
        }
      });
    } else {
      this.personalDetailsForm.controls["businessRelationship"].setValue("");
      this.personalDetailsForm.controls["businessRelationship"].markAsTouched();
    }
    return this.datosSocioEco;
  }

  refusePerfil() {
    this.showModalActacionPerfilado++;
    this.modService.close(this.modalNeedYourAprobation);
  }
  aceptPerfil() {
    this.personalDetailsForm.get("reactiveCheckPerfilado").setValue(true);
    this.modService.close(this.modalNeedYourAprobation);
  }
  selectResidenceCardOption(option) {
    switch (option) {
      case "residenceCardT": {
        if(this.route.url === '/datos-personal'){
          this.persona.datosPersonales.codigoIdExterno = "66";
        }
        sessionStorage.setItem("codigoError", undefined);
        this.personalDetailsForm.get("residenceCardF").setValue(false);
        this.personalDetailsForm.get("residenceCardT").setValue(true);
        break;
      }
      case "residenceCardF": {
        this.personalDetailsForm.get("residenceCardF").setValue(true);
        this.personalDetailsForm.get("residenceCardT").setValue(false);
        // Se aplica en cuenta inteligente
        if(this.route.url === '/datos-personal'){
          this.persona.datosPersonales.codigoIdExterno = "53";
        } else {
          this.modService.open(this.modalResidenceCard);
        }

        break;
      }
    }
  }
  continuarSubmit() {
    const incompleteModal = {
      name: "modal-errores-genericos",
      title: "¡Ups!",
      blocking: false,
      withImage: true,
      message: this.incompleteMsg,
    };
    this.helpers.updateFormStatus(this.personalDetailsForm);
    const numeroDocumento = this.personalDetailsForm.get("numeroDocumento");
    this.triggerErrorAnalytics();
    if (
      numeroDocumento &&
      (numeroDocumento.value.length !== 9 ||
        this.isValidID(numeroDocumento.value) === false)
    ) {
      this.loading = false;
      this.personalDetailsForm
        .get("numeroDocumento")
        .setErrors({ valid: false });

      this.modal = Object.assign(incompleteModal);
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.personalDetailsForm.valid) {
      this.loading = false;
      this.modal = Object.assign(incompleteModal);
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    this.sendProspect(this.modalLog);
  }

  sendProspect(modalLog, useAnlitics?) {
    this.loading = true;
    this.setFields(modalLog);
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        if (
          this.personalDetailsForm.get("numeroDocumento").value === "00000000T"
        ) {
          this.continueRegister();
        } else {
          if (data.response.codigo !== "OK") {
            let modalToShow = this.modService.selectStatusModal(data.response);
            this.modal = modalToShow;
            this.modService.open(this.modal.name, this.modal.blocking);
          } else {
            if (data.mostrarVideoTutorial) {
              sessionStorage.setItem(
                "mostrarVideoTutorial",
                data.mostrarVideoTutorial
              );
            }
            this.continueRegister();
          }
        }
      },
      () => {
        this.handleError();
      }
    );
  }

  continueRegister() {
    const nextStep = "/datos-contacto";
    this.authService.pageSubmitted = "datos-personal";
    if (this.dniExist === false) {
      this.helpers.navigateTo(nextStep);
    }
  }

  private formSubscribe() {
    this.personalDetailsForm
      .get("numeroDocumento")
      .valueChanges.subscribe((numero) => {
        if (this.isDNI) {
          this.validateDocuments("dni");
        }
        if (!this.isDNI) {
          this.validateDocuments("nie");
        }
      });

    this.personalDetailsForm.valueChanges.subscribe((form) => {
      const numDocumento =
        this.personalDetailsForm.get("numeroDocumento").value;
      if (this.isValidID(numDocumento) && this.isDniEquals) {
        this.authService.saveUpdatedForm(this.personalDetailsForm, this, form);
      }

      this.errService.mobileErrors(this.personalDetailsForm);
    });

    this.personalDetailsForm
      .get("selectAllPermissions")
      .valueChanges.subscribe((value) => {
        if (value) {
          this.personalDetailsForm
            .get("reactiveCheckCesion")
            .setValue(true, { emitEvent: false });
          this.personalDetailsForm
            .get("reactiveCheckPublicidad")
            .setValue(true, { emitEvent: false });
          this.personalDetailsForm
            .get("reactiveCheckPerfilado")
            .setValue(true, { emitEvent: false });
        } else {
          this.personalDetailsForm
            .get("reactiveCheckCesion")
            .setValue(false, { emitEvent: false });
          this.personalDetailsForm
            .get("reactiveCheckPublicidad")
            .setValue(false, { emitEvent: false });
          this.personalDetailsForm
            .get("reactiveCheckPerfilado")
            .setValue(false, { emitEvent: false });
        }
      });
    this.personalDetailsForm
      .get("reactiveCheckCesion")
      .valueChanges.subscribe((value) => {
        this.validateCheks();
      });
    this.personalDetailsForm
      .get("reactiveCheckPublicidad")
      .valueChanges.subscribe((value) => {
        this.validateCheks();
      });
    this.personalDetailsForm
      .get("reactiveCheckPerfilado")
      .valueChanges.subscribe((value) => {
        this.validateCheks();
      });

    this.personalDetailsForm.get('businessRelationship').valueChanges.subscribe((selectData) => {
      selectData.forEach(element => {
        switch (element) {
          case "Ahorro":
            this.datosSocioEco.ahorroInversion = "S";
            break;
          case "Nómina":
            this.datosSocioEco.nominaPensionOtros = "S";
            break;
          case "Financiación":
            this.datosSocioEco.financiacion = "S";
            break;
          case "Actividad Profesional":
            this.datosSocioEco.actividadProfesionalPBC = "S";
            break;
          case "Servicio de Pago":
            this.datosSocioEco.ServiciosPago = "S";
            break;
          case "Otros":
            this.datosSocioEco.OtrosProductos = "S";
            break;
        }
      });
    })

    // this.personalDetailsForm
    //   .get("confirmEmail")
    //   .valueChanges.pipe(first(f => !!f)).subscribe((value) => {
    //     this.personalDetailsForm.setValidators(
    //       this.evoValidator.emailsEquals("email", "confirmEmail"));
    //   });
  }

  validateCheks() {
    const chekCesion = this.personalDetailsForm.get(
      "reactiveCheckCesion"
    ).value;
    const chekPublicidad = this.personalDetailsForm.get(
      "reactiveCheckPublicidad"
    ).value;
    const chekPerfilado = this.personalDetailsForm.get(
      "reactiveCheckPerfilado"
    ).value;
    if (chekCesion && chekPublicidad && chekPerfilado) {
      this.personalDetailsForm
        .get("selectAllPermissions")
        .setValue(true, { emitEvent: false });
    } else {
      this.personalDetailsForm
        .get("selectAllPermissions")
        .setValue(false, { emitEvent: false });
    }
  }

  validateDocuments(documento) {
    if (documento === "dni") {
      this.personalDetailsForm
        .get("segundoApellido")
        .setValidators([Validators.required, this.evoValidator.validateName]);
    } else {
      this.personalDetailsForm.get("segundoApellido").setValidators([]);
    }

    this.personalDetailsForm.get("segundoApellido").updateValueAndValidity();

    const numDocumento = this.personalDetailsForm.get("numeroDocumento");

    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "documento",
      documento
    );

    if (
      numDocumento.value.length === 9 &&
      this.isValidID(numDocumento.value) === true
    ) {
      this.checkDNI(numDocumento);
    }
  }

  checkDNI(documento) {
    const consulta = {
      EE_I_Consulta: {
        codigoEntidad: "0239",
        idExterno: documento.value,
        idCliente: this.idUser,
      },
    };

    this.isDniEquals = this.checkDniEquals(documento);

    this.loading = true;
    this.setFields(this.modalLog);
    this.authService.checkDuplicateDNI(consulta).subscribe(
      (data) => {
        // Si en la respuesta se devuelve data.response, se toma como un error.
        if (data.response) {
          this.modal = Object.assign({
            name: "modal-errores-genericos",
            title: "¡VAYA!",
            withImage: true,
            blocking: false,
            message: this.dniErrorMsg,
          });

          this.modService.open(this.modal.name);
          this.loading = false;
          window.scrollTo(0, 0);

          this.personalDetailsForm.patchValue({ numeroDocumento: "" });
          return false;
        }
        this.codigoRetornoDni = data.codigoRetorno;
        if (
          Number(data.codigoRetorno) === 1 &&
          documento.value !== "00000000T" &&
          !data.lopd
        ) {
          if (this.persona.datosPersonales.id_cliente_EVO !== data.idCliente) {
            this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
            this.idUser = data.idCliente;
            this.authService.setSessionProspect(this.persona);
            if (data.access_token && data.refresh_token) {
              sessionStorage.setItem("auth_token", data.access_token);
              sessionStorage.setItem("refresh_token", data.refresh_token);
            }
          }
          this.dniExist = true;
          this.modalLog = true;
          this.sendProspect(this.modalLog);
          this.initModal();
          this.modal.blocking = false;
          this.modal.withImage = true;
          if (this.openModalTemporalAlreadyClient()) {
            this.modService.open(this.alreadyClientTestAB);
          } else {
            this.modService.open(this.alreadyClientModal);
          }
        } else if (data.lopd) {
          this.modService.open(this.modalLopd);
        } else if (data.claseCliente === "03") {
          if (this.persona.datosPersonales.id_cliente_EVO !== data.idCliente) {
            this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
            this.idUser = data.idCliente;
            this.authService.setSessionProspect(this.persona);
            // this.updatePersonData(data.idCliente);
          }
        } else {
          this.dniExist = false;
          //Si es NIE se muestra la información de NIE
          this.hideInfoResidenceCard = !this.isDNI ? false : true;
          this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
          this.authService.setSessionProspect(this.persona);
          if (data.access_token && data.refresh_token) {
            sessionStorage.setItem("auth_token", data.access_token);
            sessionStorage.setItem("refresh_token", data.refresh_token);
          }
        }
        this.loading = false;
      },
      () => {
        this.authService.pageSubmitted = "";
        this.handleError();
      }
    );
  }

  checkDniEquals(documento): boolean {
    const currentDni =
      this.authService.getSessionProspect().datosPersonales.idExterno;
    const consultaCurrentDni = {
      EE_I_Consulta: {
        codigoEntidad: "0239",
        idExterno: currentDni,
        idCliente: this.idUser,
      },
    };

    if(!!currentDni) {
      this.authService.checkDuplicateDNI(consultaCurrentDni).subscribe((data) => {
        if (documento.value !== currentDni && data.claseCliente === "03") {
          this.modal = Object.assign({
            name: "modal-errores-genericos",
            title: "¡VAYA!",
            withImage: true,
            blocking: false,
            message:
              "Parece que hay un error en tus datos. Contacta con nosotros llamando al " +
              this.telephoneMsg,
          });

          this.modService.open(this.modal.name);
          this.loading = false;
          window.scrollTo(0, 0);

          this.personalDetailsForm.patchValue({ numeroDocumento: "" });
          return false;
        }
        return true;
      });
    }
    return true;
  }

  handleError() {
    this.loading = false;
    this.errService.navToError();
  }

  get isDNI() {
    const reg = /^\d+$/;
    return reg.test(this.personalDetailsForm.get("numeroDocumento").value[0]);
  }

  get getDocumentType() {
    return this.personalDetailsForm.get("documento").value === "dni"
      ? "51"
      : "66";
  }

  get hideDNI() {
    return this.getDocumentType === "51";
  }

  get hideNIF() {
    return this.getDocumentType === "66";
  }


  modalClick(_ev) {
    _ev.stopPropagation();
  }

  closeModal() {
    this.modalBureau = false;
    this.helpers.navigateTo("/bienvenido");
  }
  closeModalResidenceCard() {
    this.loading = true;
    this.persona.codigoError = 0;
    sessionStorage.setItem("codigoError", this.persona.codigoError.toString());
    this.setFields(this.modalLog);
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      },
      (error) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      }
    );
  }
  closeErrorEmailSend() {
    this.modService.close(this.errorSendModalEmail);
  }
  goToBE() {
    window.location.href = "https://evobanco.com/";
  }
  closeModalResidenceCardandchangeResponse() {
    this.modService.close(this.modalResidenceCard);
    this.selectResidenceCardOption("residenceCardT");
  }
  openInfoModalNie() {
    this.modService.open(this.modalInfoNie);
  }
  closeInfoModalNie() {
    this.modService.close(this.modalInfoNie);
  }

  searchSelectCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item["value"] === value && item["value"] !== null) {
        name = item;
      }
    });
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item["selected"] && item["value"] !== null) {
        name = item;
      }
    });
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  private isValidID(value) {
    const invalid = false,
      validChars = "TRWAGMYFPDXBNJZSQVHLCKET",
      nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      str = value.toString().toUpperCase(),
      nie = str.replace(/^[X]/, "0").replace(/^[Y]/, "1").replace(/^[Z]/, "2"),
      letter = str.substr(-1),
      charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (str.length !== 9) {
      return invalid;
    }

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return invalid;
    }

    if (validChars.charAt(charIndex) === letter) {
      return true;
    }

    return invalid;
  }

  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? "móvil" : "correo";
  }

  modalOpenCondiciones(e) {
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "reactiveCheckPerfilado",
      newValue
    );
  }

  selectCheckTipoA(e) {
    if (e.key !== " ") {
      return;
    }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "reactiveCheckTipoA",
      newValue
    );
  }

  selectCheckConsentimiento(e: any, fieldName: string) {
    if (e.key !== " ") {
      return;
    }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      fieldName,
      newValue
    );
  }

  selectCheckPubli(e) {
    if (e.key !== " ") {
      return;
    }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "publicidad",
      newValue
    );
  }

  public onMouseClick() {
    this.closeQuestCollapse();
    this.modService.open("faqModal");
  }

  public onMouseOver() {
    this.closeQuestCollapse();
    this.modService.open("faqModal");
  }

  public closeQuestCollapse() {
    this.estadoCollapse0 = false;
    this.estadoCollapse1 = false;
    this.estadoCollapse2 = false;
    this.estadoCollapse3 = false;
    this.estadoCollapse4 = false;
  }

  public esCuentaInteligente() {
    return (
      this.persona.logalty &&
      this.persona.logalty.codProductoContratado === "006"
    );
  }

  /**
   * @param inputLabel : Etiqueta seleccionada en el HTML
   * @description El objetivo es obtener datos para analizar la claridad de los formularios.
   * Se lleva a cabo la siguiente validacion :
   *  -Campo seleccionado : al hacer click en el input.
   */
  updateAnalyticsIn(inputLabel) {
    switch (inputLabel) {
      case "email": {
        this.clearValidatorEmail();
        break;
      }
      case "confirmEmail": {
        this.clearValidatorConfirmEmail();
        break;
      }
    }
  }

  /**
   * @param inputLabel : Etiqueta seleccionada en el HTML
   * @description El objetivo es obtener datos para analizar la claridad de los formularios.
   * Se lleva a cabo la siguiente validacion :
   *  -Campo seleccionado : al perder el foco en el input.
   */
  updateAnalyticsOut(inputLabel) {
    switch (inputLabel) {
      case "email": {
        this.setValidatorEmail();
        break;
      }
      case "confirmEmail": {
        this.setValidatorConfirmEmail();
        break;
      }
    }
  }
  /**
   *
   * @param beforeLabel Etiqueta del label anterior por orden de aparicion en pantalla a fecha de 26/05/2020
   * @description Valida si se he rellenado el imput y si su valor es valido
   */
  validateBeforeLabel(beforeLabel) {
    const formLabel = this.personalDetailsForm.controls[beforeLabel];
    const validation = !formLabel.valid ? "error" : "completado";
    return validation;
  }
  returnInit() {
    this.helpers.navigateTo("bienvenido");
  }

  setInitialValueDocument() {
    let initValue = "dni";
    if (this.persona.datosPersonales) {
      if (this.persona.datosPersonales.codigoIdExterno === "66") {
        initValue = "nie";
      }
    }
    return initValue;
  }
  showInfoUserExist(option) {
    this.optionExistUserInfo = option;
  }

  openModalTemporalAlreadyClient() {
    const day = new Date();
    const hour = day.getHours();
    const minutes = day.getMinutes();
    let open = false;
    if (hour === 16 && minutes >= 30 && minutes <= 59) {
      open = true;
    }
    return open;
  }

  closeModalLopd() {
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      },
      (error) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      }
    );
  }

  closeTooltip(event) {
    event.preventDefault();
    event.stopPropagation();
    this.tooltipEnabled = false;
  }

  saveData() {
    this.setFields(this.modalLog);
    this.authService.setSessionProspect(this.persona);
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        this.modService.open(this.saveModal, this.modal.blocking);
      },
      (error) => {
        this.errService.navToError();
      }
    );
  }

  updatePersonData(idSfc) {
    this.authService.product = this.isYoungAccount
      ? ACCOUNTS.CJCode
      : ACCOUNTS.CICode;
    this.personaSrv.getPersonaById(idSfc, this.authService.product).subscribe(
      (data) => {
        this.persona = data;
        this.createForm();
        this.loading = true;
        this.authService.updateProspect(this.persona).subscribe(
          (ok) => {
            this.personalDetailsForm.markAsDirty();
            this.personalDetailsForm.markAsTouched();
            this.loading = false;
          },
          (ko) => {
            this.loading = false;
          }
        );
      },
      (_error) => {
        this.loading = false;
        this.helpers.navigateTo("/404");
      }
    );
  }

  setValidatorEmail() {
    this.personalDetailsForm
      .get("email")
      .setValidators(this.evoValidator.validateEmail);
    this.personalDetailsForm.get("email").updateValueAndValidity();
  }

  setValidatorConfirmEmail() {
    this.personalDetailsForm
      .get("confirmEmail")
      .setValidators(this.evoValidator.emailConfirmation);
    this.personalDetailsForm.get("confirmEmail").updateValueAndValidity();
  }

  clearValidatorEmail() {
    this.personalDetailsForm.get("email").clearValidators();
    this.personalDetailsForm.get("email").updateValueAndValidity();
  }

  clearValidatorConfirmEmail() {
    this.personalDetailsForm.get("confirmEmail").clearValidators();
    this.personalDetailsForm.get("confirmEmail").updateValueAndValidity();
  }

  validateEmailPattern = (email) => {
    return email.value.match(/[ñáéíóú!#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/);
  };

}
