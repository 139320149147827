<modal  [modalTitle]="'Call me'"
        [blocking]='false'
        [modalId]='callMeModal'
        class='modal exception-modal' >
  <div>
    <div class="row">
      <p class="ic-operadora"></p>
    </div>
    <!-- Antigüo modal C2C -->
     <div class="row">
      <p class="call-me-title">
        ¿Quieres que te llamemos?
      </p>
    </div>
    <div class="row">
      <p class="call-me-subtitle">
        Déjanos un teléfono para que uno de nuestros agentes se pueda poner en contacto contigo y resolver tus dudas.
      </p>
    </div>
    <div class="row">
      <form #bysidecarForm="ngForm" (ngSubmit)="callUser(bysidecarForm)" novalidate>
        <div class="equal-buttons">
          <input  placeholder="Nombre"
                  autocomplete="off"
                  class="input-call-me"
                  type="text"
                  name="name"
                  ngModel
                  #userName="ngModel">
          <input  placeholder="Teléfono"
                  autocomplete="off"
                  class="input-call-me"
                  type="text"
                  name="phone"
                  pattern="^[6-7][0-9]{8}|8{9}"
                  maxlength="9"
                  required
                  ngModel
                  #phoneNumber="ngModel" >
        </div>
        <div class="error-call-me error-text" *ngIf="errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')">
          {{errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')}}
        </div>
        <div class="equal-buttons friendly buttom-row-desktop">
            <button type="submit" class="btn-common btn-common_fix_bold" name="button1">
              Enviar
            </button>
        </div>
      </form>
    </div>

    <!-- NUEVO
    <div class="row">
      <p class="call-me-title">
        ¿Necesitas ayuda?
      </p>
    </div>
    <div class="row">
      <p class="call-me-subtitle">
        Si tienes dudas sobre el proceso de contratación de la Cuenta Inteligente, puedes llamarnos al número de teléfono que aparece a continuación.
      </p>
    </div>
    <div class="row border-call">
      <a href="tel:910900900">910900900</a>
    </div>-->
  </div>
   <div  class="image-modal"
        *ngIf='isYoungAccount'></div>
  <div  class="false-container"
        *ngIf='isYoungAccount'>
        <h2>{{modalTitle}}</h2>

    <div *ngIf='!isPhoneSent'>
    <p>
      Déjanos un teléfono para que uno de nuestros agentes se pueda poner en contacto contigo
      y resolver tus dudas.
    </p>
    <form #bysidecarForm="ngForm" (ngSubmit)="callUser(bysidecarForm)" novalidate>
      <div class="equal-buttons">
        <input  placeholder="Nombre"
                autocomplete="off"
                class="input-call-me"
                type="text"
                name="name"
                ngModel
                #userName="ngModel">
        <input  placeholder="Teléfono"
                autocomplete="off"
                class="input-call-me"
                type="text"
                name="phone"
                pattern="^[6-7][0-9]{8}|8{9}"
                maxlength="9"
                required
                ngModel
                #phoneNumber="ngModel" >
      </div>
      <div class="error-call-me error-text" *ngIf="errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')">
        {{errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')}}
      </div>
      <div class="equal-buttons friendly buttom-row-desktop">
          <button type="submit" class="btn-common btn-common_fix_bold" name="button1">
            Enviar
          </button>
      </div>
    </form>
    </div>
    <p *ngIf='isPhoneSent' class="invalid-text">
    Uno de nuestros agentes se pondrá en contacto contigo para resolver tus dudas.
    </p>
  </div>
</modal>


<header id="header"
  [ngClass]="isYoungAccount ? 'youngAccount': '' "
>
  <h1 class="logo desktop"
  [ngClass]="{'newLogo': isYoungAccount}"
  > </h1>
  <div class="mobile">
    <h1 class="logo tablet"></h1>
    <div class="title" [ngClass]='(isYoungAccount) ? "bottom-margin":"title-ci"'><a class="title-text" [ngClass]="''">{{ getTitleResponsive() }}</a></div>
  </div>
  <!-- Borro logo de telefono :Pendiente de confirmación por la campaña de publicidad  -->
  <div  class="phone-mobile"
        *ngIf='(mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion '>
    <div class="phone-container">
      <p class="phone-image"></p>
      <a class="phone-text" (click)="openModal()" >Te llamamos</a>
    </div>
  </div>
  <div class="desktop">
    <div class="title" [ngClass]='(!mobilePhone || mobilePhone === 0 || isYoungAccount) ? "bottom-margin":""'><a class="title-text" [ngClass]="''">{{ getTitleResponsive() }}</a></div>
    <div class="phone" *ngIf='((mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion )'><span class="phone-text pink">¿Tienes dudas?</span></div>
    <div class="access" *ngIf='((mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion )' (click)="openModal()"><span class="phone-text" >Te llamamos</span>  </div>
  </div>
</header>
