import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup ,Validators} from "@angular/forms";
import { Persona } from "app/collections/persona";
import { ACCOUNTS } from "app/resources/account.model";
import { AnalyticsService } from "app/services/analytics.service";
import { ApiVideoeidService } from "app/services/api/api.video-eid.service";
import { AuthService } from "app/services/auth.service";
import { ConstantsService } from "app/services/constants.service";
import { ErrorService } from "app/services/error.service";
import { FormValidatorService } from "app/services/form-validator.service";
import { HeaderService } from "app/services/headers.service";
import { HelperService } from "app/services/helpers.service";
import { IberpayService } from "app/services/iberpay.service";
import { SpecificAnalyticsService } from "app/services/specific-analytics.service";
import { VideoEidService } from "app/services/video-eid.service";
import { environment } from "environments/environment";
import { DeviceService } from "../../../services/device.service";
import { ModalService } from "../../../services/modal.service";
import { REGISTERLITERALS } from "app/resources/register-literals";

declare var EID;
@Component({
  selector: "app-verify-identity-select",
  templateUrl: "./verify-identity-select.component.html",
  styleUrls: ["./verify-identity-select.component.scss"],
  providers: [
    IberpayService,
    FormValidatorService,
    ModalService,
    DeviceService,
    VideoEidService,
    ApiVideoeidService,
  ],
})
export class VerifyIdentitySelectComponent implements OnInit {
  @Input() username: string = "";
  auth;
  videoId;
  discriminatorEid;
  public imgConfig = "./assets/images/ic-engranaje-ajustes.svg";
  public imgVideo = "./assets/images/ic-play-video.svg";
  identifyDetailsForm: FormGroup;
  mobileErrors = [];
  modalIdVideo = "video-modal";
  modalSpecVideo = "spec-dni-modal";
  modalSpecIban = "spec-iban-modal";
  modalIphone = "modalIphone";
  modalInfoEid = "modalInfoEid";
  modalConsent = "modal-consent";
  videoUrl: String =
    "https://www.youtube.com/embed/U7QoYXoBDGg?controls=1&vq=large&autoplay=0&rel=0";
  toggle: Boolean = false;
  persona: Persona;
  noPhotoIdCard: boolean = false;
  prospect;
  loading: boolean = false;
  eidDisabled: boolean = false;
  private click: any;
  msgSrvError: string = "";
  modalSrvError: string = "modal-srv-error";
  public isYoungAccount: boolean = false;
  public noIexplorer: boolean = true;
  cameraAvailable: boolean = false;
  public isCIAccount: boolean = false;
  public isSecondHolderAccount: boolean = false;
  public userInBL = true;
  clientId: string;
  public tipoCuenta = "Cuenta Inteligente";
  public tipoTarjeta = "Tarjeta Inteligente";
  public varifiIdentityForm: FormGroup;
  public promoConditionText = REGISTERLITERALS["legalCondition"];
  public showMandatoryCheckInfo:boolean = false;
  standardModalTextNIE: string = 'Corre a por tu DNI o NIE'; 
  constructor(
    private fb: FormBuilder,
    private iberpayService: IberpayService,
    private authService: AuthService,
    public modService: ModalService,
    public devService: DeviceService,
    public errService: ErrorService,
    private specificAnalytics: SpecificAnalyticsService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
  ) {
    this.isCIAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CICode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CICode;
    this.isSecondHolderAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.STCode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.STCode;
    this.clientId = this.authService.getSessionProspect().datosPersonales
      ? this.authService.getSessionProspect().datosPersonales.id_cliente_EVO
      : this.errService.navToError();

    if (!this.isCIAccount && !this.isSecondHolderAccount) {
      this.tipoCuenta = "Cuenta Joven";
      this.tipoTarjeta = "Tarjeta Joven";
    }
    this.createForm();
  }

  ngOnInit() {
    this.prospect = JSON.parse(sessionStorage.getItem("currentProspect"));
    if (this.prospect.datosPersonales) {
      this.noPhotoIdCard = (this.prospect.datosPersonales.codigoIdExterno == '53')
    }
    this.isYoungAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CJCode ||
      location.host === environment.hostYoungAccount;
    //https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    if (!!window["MSInputMethodContext"] && !!document["documentMode"])
      this.noIexplorer = !this.noIexplorer;
    //Comprobamos si el dispositivo tiene camara disponible
    this.checkCameraAvaible();
    // Llamada al servicio de validación del cliente.
    //OJO COMENTADO MIENTRAS ESTE DESHABILITADO CONFIRMA
    // this.loading = true;
    // const idSfc = this.prospect.datosPersonales.id_cliente_EVO;
    // this.iberpayService.validateUser(idSfc).subscribe(
    //   (data) => {
    //     this.loading = false;
    //     if (data.description) {
    //       switch (data.description) {
    //         case ConstantsService.RespuestasValidation.UsuarioNoEncontrado: // Ok, Usuario no encontrado
    //           this.userInBL = false;
    //           break;
    //         case ConstantsService.RespuestasValidation.UsuarioEncontrado: // Ok, Usuario encontrado
    //           this.userInBL = true;
    //           break;
    //         default:
    //           this.userInBL = true;
    //           break;
    //       }
    //     } else {
    //       this.userInBL = true;
    //     }
    //   },
    //   (_error) => {
    //     // '401': Unauthorized - Usuario en BlackList
    //     // Si falla el servicio sólo mostrar EID
    //     this.loading = false;
    //     this.userInBL = true;
    //   }
    // );
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem("mostrarVideoTutorial")) {
      this.getVideo("U7QoYXoBDGg");
    }
  }

  setVideoClass() {
    if (this.devService.isIphone()) {
      let iPhoneVersion = this.devService.getIphoneVersion();
      if (iPhoneVersion != undefined && iPhoneVersion < 11) {
        return "disabled";
      } else {
        return "";
      }
    }
  }
  onSubmit() {
    this.setTipoIdentificacionProspec();
    this.sendToIdentification();
  }
  redirect() {
    //if (!this.devService.isMobile())
    this.onSubmit();
  }
  showModalEid() {
    if (this.noPhotoIdCard) {
      this.standardModalTextNIE = 'Corre a por tu DNI del país de origen o tu pasaporte y tu certificado de registro de Ciudadanos de la Unión Europea'
    } 
    this.modService.open(this.modalInfoEid);
  }
  sendToIdentification() {
    this.loading = true;
    this.persona.estado = 'identificacion-video';
    this.persona.consentimientoEID = true;
    if (JSON.parse(sessionStorage.getItem("discriminatorInfo"))) {
      let discriminator = JSON.parse(
        sessionStorage.getItem("discriminatorInfo")
      );
      this.persona.discriminador = discriminator;
    }
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        // this.loading = false;
        if (data.response.codigo === "KO") {
          this.msgSrvError = !!data.response.message
            ? data.response.message
            : "Error genérico";
          this.modService.open(this.modalSrvError);
          return;
        }
        let nextstep = "previa-eid";

        if (this.click.id === "video") {
          nextstep = "previa-eid";
        } else {
          nextstep = "identificacion-iban";
        }

        // Redirigir a pagina de NIE verde
        if (this.noPhotoIdCard) {
          nextstep = "previa-nie-comunitario"
        }
        this.loading = false;
        this.helpers.navigateTo(nextstep);
      },
      (error) => {
        this.error();
      }
    );
  }

  error() {
    this.errService.navToError();
  }

  setTipoIdentificacionProspec() {
    const canal = this.devService.getDevice();
    if (!this.click) {
      this.click.id = "video";
    }
    this.specificAnalytics.emitIdentificationType(this.click.id);
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      otrosDatos: {
        tipoIdentificacion:
          this.click.id === "video"
            ? "identificacion-video"
            : "identificacion-iban",
      },
    });
  }
  getSpecify(s) {
    if (s === "video") {
      this.modService.open(this.modalSpecVideo);
    } else {
      this.modService.open(this.modalSpecIban);
    }
  }

  getVideo(id) {
    this.modService.open(this.modalIdVideo);
  }

  toggleIdentify(el, e) {
    this.click = undefined;
    for (let i = 0; i < el.children.length; i++) {
      const item = el.children[i];
      //item.classList.add('not-selected');
    }
    //e.currentTarget.parentElement.classList.remove('not-selected');
    this.click = e.currentTarget;
  }

  goToVideoEid() {
    if(this.varifiIdentityForm.valid){
      this.click = { id: 'video'};
      this.onSubmit();
    }else{
      this.showMandatoryCheckInfo = true;
    }
  }

  redirectToIban() {
    this.helpers.navigateTo("identificacion-iban");
  }

  isDisabled(el) {
    if (this.click) return false;
    return true;
  }

  checkCameraAvaible() {
    this.initVideo();
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return false;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          if (device.kind == "videoinput") {
            this.cameraAvailable = true;
          }
        });
        if (!this.cameraAvailable) {
          console.error("Camara no detectada");
        }
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
  }

  clickLink(identify, event) {
    //Si el dispositivo es un móvil al pinchar en el cuadro, te muestra las instrucciones necesarias
    if (this.devService.isMobile()) {
      this.toggleIdentify(identify, event);
      this.redirect();
    } else {
      return;
    }
  }
  initVideo() {
    this.discriminatorEid = EID.videoId.checkRequirements();
    this.startDiscriminator(this.discriminatorEid);
  }
  startDiscriminator(videoId) {
    let requeriments = videoId;
    requeriments
      .then((checks) => {
        let discriminatorInfo = this.getDiscriminatorInfo(checks);
        sessionStorage.setItem(
          "discriminatorInfo",
          JSON.stringify(discriminatorInfo)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getDiscriminatorInfo(checks) {
    let discriminatorInfo = {
      browser: {
        data: checks.result.browser.data,
        passed: checks.result.browser.passed,
      },
      camera: {
        data: checks.result.camera.data,
        passed: checks.result.camera.passed,
      },
      microphone: {
        data: checks.result.microphone.data,
        passed: checks.result.microphone.passed,
      },
      os: {
        data: checks.result.os.data,
        passed: checks.result.os.passed,
      },
    };
    return discriminatorInfo;
  }

  private createForm() {
    this.varifiIdentityForm = this.fb.group({
      indConsentimientoEid: [false, Validators.requiredTrue]
    });
    this.setValue();
  }

  checkConsentimiento() {
    const newValue= !this.varifiIdentityForm.get("indConsentimientoEid").value;
    this.varifiIdentityForm.controls["indConsentimientoEid"].setValue(newValue);
  }
  openConsetModal(){
    this.modService.open(this.modalConsent);
  }
  closeModal(modalId) {
    this.varifiIdentityForm.controls["indConsentimientoEid"].setValue(true);
    this.modService.close(modalId);
  }
  setValue(){
    const personaSesion = this.authService.getSessionProspect();
    const consentimientoTGSS = personaSesion.consentimientoEid ? personaSesion.consentimientoEid : false;
    if(consentimientoTGSS){
      this.varifiIdentityForm.controls["indConsentimientoEid"].setValue(consentimientoTGSS);
    }
  }
}
