<p>
  <strong>RESPONSABLE</strong>
  <br />
  EVO Banco, S.A. (EVO). Contacto del Delegado de Protección de Datos: dpo.evo@evobanco.com
</p>
<br />

<p>
  <strong>FINALIDADES Y BASES DE LEGITIMACIÓN</strong>
  <br />
</p>
<ul>
  <li>
    <p><strong>1. </strong>Cumplir con las obligaciones legales aplicables a EVO. (Obligación legal). </p>
  </li>
  <li>
    <p><strong>2. </strong>Formalizar, mantener y ejecutar las relaciones precontractuales y/o contractuales que firme con EVO. (Ejecución del contrato). </p>
  </li>
  <li>
    <p><strong>3. </strong>Consultar sus datos en los sistemas comunes de información crediticia para evaluar su solvencia.  (Interés legítimo). </p>
  </li>
  <li>
    <p><strong>4. </strong>Prevenir situaciones de fraude relacionadas con los productos y/o servicios que tenga contratados con el EVO. (interés legítimo). </p>
  </li>
  <li>
    <p><strong>5. </strong>Informarle sobre productos y/o servicios similares a los que usted tiene contratados con nosotros. (Interés legítimo). </p>
  </li>
  <li>
    <p><strong>6. </strong>Elaborar un perfil comercial sobre usted a partir de los datos que nos ha facilitado y los derivados de los productos y/o servicios que tenga contratados con EVO para personalizar las acciones comerciales sobre los mismos. (Interés legítimo). </p>
  </li>
  <li>
    <p><strong>7. </strong>Informarle sobre productos y/o servicios, del Grupo Bankinter o terceros que no tengan similitud con los que usted tiene contratados y que sean ofrecidos por EVO. (Consentimiento). </p>
  </li>
  <li>
    <p><strong>8. </strong>Elaborar un perfil comercial sobre usted a partir de datos obtenidos de fuentes externas y datos personales facilitados por usted, los derivados de sus productos y/o servicios, así como de origen externo, para tener un mayor conocimiento sobre usted. (Consentimiento). </p>
  </li>
  <li>
    <p><strong>9. </strong>Compartir sus datos con empresas del Grupo Bankinter y sus participadas con la finalidad de elaborar un perfil comercial sobre usted. (Consentimiento).</p>
  </li>
</ul>
<br />

<p>
  <strong>DESTINATARIOS</strong>
  <br />
  No se cederán tus datos a terceros, salvo que tengamos tu consentimiento previo o sea necesario por obligación legal o para la ejecución del contrato. 
</p>
<br />

<p>
  <strong>DERECHOS</strong>
  <br />
  Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación y portabilidad. El ejercicio de cualquier de estos
  derechos se podrá realizar a través de la dirección de correo electrónico protecciondedatos@evobanco.com 

</p>
<br />

<p>
  Puedes obtener información ampliada sobre cómo tratamos tus datos personales a través del siguiente enlace: https://www.evobanco.com/politica-de-privacidad/
</p>
