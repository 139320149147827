import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiRecoveryService {
  private options = {};
  constructor(private http: HttpClient) {
    this.options = { withCredentials: true, observe: 'response' };
  };
  public recoverProcess(token: string, clientId: string): Observable<any> {
    sessionStorage.setItem('auth_token',token);
    const params = `clientId=${clientId}`;

    return this.http
      .get(`${API_URL}/fullonline/recovery_validation?${params}`, this.options)
      .pipe(
        map(res => {
          return res['body'];
        })
      )
  };
};
