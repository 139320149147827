
export const ERRORSFORM = {
  nombre: "Introduce tu nombre",
  apellido1: "Introduce tu apellido",
  dni: "Introduce tu dni",
  fechaCad: "Introduce la fecha de caducidad",
  nacionalidad: "Introduce tu nacionalidad",
  paisNacimiento: "Introduce tu pais",
  localidadNacimiento: "Introduce tu localidad",
  provNacimiento: "Introduce tu provincia",
  fechaNacimiento: "Introduce tu fecha de nacimiento",
  genero: "Introduce tu genero",
  estadoCivil: "Selecciona una opción",
  regEconomico: "Selecciona una opción",
  correo: "Introduce tu correo",
  localidadResidencia: "Introduce tu localidad",
  provResidencia: "Introduce tu provincia",
  ciudadResidencia: "Introduce tu ciudad",
  codPostal: "Introduce tu código postal",
  tipoVia: "Selecciona una opción",
  nombreVia: "Introduce tu dirección",
  num: "Introduce tu número",
  planta: "Introduce tu planta",
  puerta: "Introduce tu puerta",
  portal: "Introduce tu portal",
  bloque: "Introduce tu bloque",
  escalera: "Introduce tu escalera",
  fase: "Introduce tu fase",
  parcela: "Introduce tu parcela",
  situacionVivienda: "Selecciona una opción",
  situacionLaboral: "Selecciona una opción",
  tipoContrato: "Selecciona una opción",
  tipoActividad: "Selecciona una opción",
  cnae: "Selecciona una opción",
  profesion: "Selecciona una opción",
  fechaAltaAutonomo: "Introduce tu fecha de alta",
  alquilerVivienda: "Introduce tu alquiler",
  antiguedadLaboral: "Introduce tu antigüedad laboral",
  antiguedadLaboralInvalida: "Esta fecha es inválida. Recuerda que como mínimo tenías 14 años cuando empezaste a trabajar",
  anioAdquisicion: "Introduce tu año de adquisición",
  nombreEmpresa: "Introduce tu empresa",
  antiguedad: "Introduce tu antigüedad",
  ingresosMensuales: "Introduce tus ingresos",
  numeroPagas: "Introduce tus pagas",
  fechaAltaAutonomoInvalida: "Esta fecha es inválida",
  correosNoCoinciden: "¡Ups! Los correos no coinciden",
  condicionesLegales: "Debes leer y aceptar las condiciones legales para continuar",
  checkObligatorio: "Debes leer y aceptar para continuar",
  checkMandatory: "Es necesario que aceptes este check legal para poder continuar con la contratación",
  checksMandatoryToContinue: "Para poder continuar necesitas aceptar las condiciones legales.",
  emailValido: "Introduce un email válido",
  politicaPrivacidad: "Debes leer y aceptar la Política de privacidad para continuar",
  terminosCondiciones: "Debes leer y aceptar los Términos y Condiciones para continuar",
  regimenTratamientoDatos: "Debes leer y aceptar el Régimen de Tratamiento de Datos de Carácter Personal para continuar",
  propositoNegocio: "Debes escoger al menos un propósito para crear la cuenta",
};
