import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Persona } from 'app/collections/persona';

@Component({
  selector: 'app-welcome-second-holder',
  templateUrl: './welcome-second-holder.component.html',
  styleUrls: ['./welcome-second-holder.component.scss']
})
export class WelcomeSecondHolderComponent implements OnInit {
  fistHolderData: Persona;
  completeName: String;
  constructor(private router: Router) { }

  ngOnInit() {
    this.fistHolderData = JSON.parse(sessionStorage.getItem('currentProspect'));
    this.completeName = this.returnProperty(this.fistHolderData.datosPrimerTitular , 'nombreCliente')+ ' '+
                        this.returnProperty(this.fistHolderData.datosPrimerTitular , 'apellido1Cliente')+ ' '+
                        this.returnProperty(this.fistHolderData.datosPrimerTitular , 'apellido2Cliente');
  }

  returnProperty(objeto ,clave){
    return objeto.hasOwnProperty(clave) ? objeto[clave]+' ' : '';
  }
  begin(event){
    this.router.navigateByUrl('/datos-personales');
  }
  
}
