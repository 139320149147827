
<div class="box"

     [ngClass]="sizeBox">
    <div [ngClass]="classImgFile"></div>
    <div *ngIf="showTextBox" class="text-img"
         [ngClass]="textBox"   >
        <p  [hidden]="!icon"
            class="icon" 
            [ngClass]="icon"></p>
        <p [ngClass]="titleCss"
            [innerHTML]="title">
            <!-- {{title}} -->
        </p>
        <p  [ngClass]="subtitleCss"
            [innerHTML]="subtitle">
            <!-- {{subtitle}} -->
        </p>
    </div>
</div>