export const appRoutes = [
  {
    path: '',
    loadChildren: 'app/modules/register/register.module#RegisterModule'
  },
  {
    path: '',
    loadChildren: 'app/modules/profile/profile.module#ProfileModule'
  },
  {
    path: '',
    loadChildren: 'app/modules/profile/microinsurances/microinsurances.module#MicroinsurancesModule'
  },
  { path: '', redirectTo:'/', pathMatch: 'full'},
  { path: '**', redirectTo:'/404', pathMatch: 'full'}
];
