<loading [showMe]="loading"></loading>


<modal [modalTitle]="'Preparando transferencia...'" [blocking]='false' [modalId]='modalTransfer'>
  <div class="container modal-transfer">
    <a  class="close"
        (click)="closeModalTransfer()"></a>


    <i class="ic-euro-pink"></i>
    <h4 class="title">Preparando transferencia...</h4>
    <p *ngIf="!isSecondSigner">
      En breve <strong>te enviaremos un correo al email: {{mail}}</strong>
       para que confirmes el importe transferido a tu cuenta.
    </p>

    <p *ngIf="isSecondSigner">
      En breve <strong>le enviaremos un correo al email que ha incluido</strong>
      para que confirme el importe transferido a su cuenta.
    </p>

    <p>
      {{modalTranferFinalText}}
    </p>

    <div class="btn-group">
      <button class="btn-common" (click)="redirectToEvoBanco()"><span class="text-button">Entendido</span></button>
    </div>

  </div>
</modal>


<div class="main-register flex-direction">
  <box-image  [classImgFile]="classImgFile"
              [icon]="icon"
              [titleCss]="'title-2 '"
              [subtitleCss]="'subtitle-2'"
              [title]="titleBox"
              [subtitle]="subtitle"
              class="image-div"></box-image>
  <div class="form-and-step-div">
    <steps-ci class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil">
    <div class="form-div">
      <p class="title"> Verificación de la cuenta</p>
      <p>{{textAboveInput}}</p>
      <form  [formGroup]='identifyIbanConfirmForm' class="flex-form" >
        <div class="floating-placeholder-group row-ci iban-line input-icons" >
          <i class="green-check"></i>
          <input  type="tel-ci"
                  required
                  [disabledIban]="true"
                  iban-input
                  formControlName="ibanConfirm"
                  name="ibanConfirm"
                  maxlength="27"
                  autocomplete="off"
                  disabled=true>
          <div class="error-text">
          </div>
        </div>
      </form>
      <p>{{textBelowInput}}</p>
      <div>
        <hr class="line-separator hidden-movil">
        <div>
          <button (click)="submit()" class="btn-common btn-submit">CONTINUAR</button>
        </div>
      </div>
    </div>
  </div>
</div>
