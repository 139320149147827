import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as sha256 from 'crypto-js/sha256';

@Injectable()
export class RecomiendaService {

  entry:string = 'EVO_ENTRADA3';
  id:string;
  idHash:string;
  product:string;
  opiURL:string = `https://www.opinator.com/opi/${this.entry}?id=${this.id}&carry_hash=${this.idHash}&product=${this.product}`;

  constructor(private authService: AuthService) {}

  hashId(email) {
    return sha256(email);
  }

  getUserId() {

  }

  getPersonaId(email) {
      this.id = email;
      this.idHash = this.hashId(this.id);
      this.opiURL = `https://www.opinator.com/opi/${this.entry}?id=${this.id}&carry_hash=${this.idHash}&product=${this.product}`
    return this.opiURL;
  }

  getRecomiendaURL(email, productoContratado?) {
    this.id = email;
    this.idHash = this.hashId(this.id);
    if (!!productoContratado) {
      this.product = productoContratado;
    }
    this.opiURL = `https://www.opinator.com/opi/${this.entry}?id=${this.id}&carry_hash=${this.idHash}&product=${this.product}`
  return this.opiURL;
}

}
