import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params} from '@angular/router';
import { AnalyticsService } from './services/analytics.service';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/filter';
import { environment } from '../environments/environment';
import { ErrorService } from './services/error.service';
import { InjectScriptService } from './services/inject-script.service';
import { Title }     from '@angular/platform-browser';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import {Meta} from '@angular/platform-browser';
import { DataLayerManager } from '@agm/core';
import { filter } from 'rxjs/operators';
import { HelperService } from './services/helpers.service';

@Component({
  selector: 'evo-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  routerSubscription: Subscription;
  eventRoute: any = [];
  isCookiesAccepted: Boolean = false;
  interval: any;
  constructor(
    public meta: Meta,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public inject: InjectScriptService,
    private authservice: AuthService,
    private helpers: HelperService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if ((params.codProductoContratado && params.codProductoContratado === "999")
        || (params.isSecondSigner && params.isSecondSigner === "true")) {
        sessionStorage.setItem("isSegTit", "true");
      }
      this.googleTagManager();
      this.inject.createScript(environment.videoUrl);
      this.inject.createScript(environment.videoUrl.replace("videoid.js", "videoscan.js"));
    });
  }
 
  ngOnInit() {
    this.router.resetConfig(environment.appRoutes);
    const prospect = JSON.parse(sessionStorage.getItem('currentProspect')),
      urlAdrum = environment.urlAdrum,
      appKey = environment.appKey;
    this.setTitle();
    this.withOutIndex();
    window.parent['iframe'] = undefined;
    if (prospect && prospect.datosPersonales && !prospect.datosPersonales.id_cliente_EVO) {
      sessionStorage.clear();
    }
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.errService.errorsArray = [];
      });

    this.addrum(urlAdrum, appKey);
    if (prospect && prospect.datosPersonales) {
      this.addHotjarSalesforce(prospect.datosPersonales.id_cliente_EVO);
    }
    window['version'] = environment.version;
  }

  addHotjarSalesforce(idSalesforce){
    if (location.hash.indexOf('datos-personal') !== -1) {
      this.inject.createScriptHotjar(idSalesforce);
    }
  }

  googleTagManager() {
    const routeCookieScript = environment.urlCookies;
    this.inject.createScriptCookies(routeCookieScript);
    // google analytics
    // CI
    let tag = 'GTM-PSG44DQ';
    if (location.host === environment.hostYoungAccount) {
      // CJ 'cuenta-joven'
      tag = 'GTM-NL3N2GF';
      let hotjarTag = "3902223";
      this.inject.addNewScriptHotjar(hotjarTag)

    }
    else{
      let hotjarTag = "818531";
      this.inject.addNewScriptHotjar(hotjarTag)
    }

    const segundoTitular = location.hash.endsWith('login-invitacion') || location.hash.indexOf('segundo-titular') !== -1 || (sessionStorage.getItem("isSegTit") && sessionStorage.getItem("isSegTit") == "true");
    if (segundoTitular) {
      tag = 'GTM-TFMGJVG';
    }

    //Acceso de ecommerce y login
    const ecommerce = (location.hash.indexOf('login') !== -1 && !location.hash.endsWith('login-invitacion')) || location.hash.indexOf('perfil') !== -1 || location.hostname === 'ecommerce.evobanco.com';
    const microseg = location.hash.indexOf('evo-protect') !== -1;
    const altaClienteA = location.hash.indexOf('alta-cliente') !== -1;
    if (microseg || altaClienteA || ecommerce) {
      tag = 'GTM-P5QQPP5';
    }
    this.inject.addScriptGoogle("script", tag);
    this.inject.addBodyNoScriptGTM("noscript", tag);
  }

  ngAfterContentInit() {
    this.analytics.subscribeToNav(this.router);
    this.router.events.subscribe(event => {
      this.eventRoute.push(event);
    });
    // this.isCookisAccpeted();
  }
  getDataLayerParams() {
    let dataLayer = [] = window['dataLayer'];
    for (let dL of dataLayer) {
      if(dL.event==='OneTrustLoaded'){
        if(dL.OnetrustActiveGroups !==',C0001,'){
          this.isCookiesAccepted = true;
        }
      }
    }
  }
  /**
   * Funcion que comprueba cada 5 segundos si el usuario ha aceptado las cookies
   * Si el usuario las acepta lanza el evento de virtual page siempre que no lo hubiera lanzado anteriormente.
   * Una vez aceptadas las cookies deje de ejecutarse la funcion
   */
  isCookisAccpeted(){
    this.interval = setInterval(() => {
      this.getDataLayerParams();
      if(this.isCookiesAccepted){
        clearInterval(this.interval);
        let dataLayer = [] = window['dataLayer'];
        let callAnalitics = true;
        for (let dL of dataLayer) {
          //Comprabamos si el evento de virtual page ya se ha ejecutado previamente
          if(dL.event==='virtual_page'){
            callAnalitics = false;
          }
        }
        if(callAnalitics){
          this.analytics.validationAfeterViewInit();
        }
      }
    }, 5000);
  }
  addrum(url, appKey) {
    window['adrum-start-time'] = new Date().getTime();
    (function (config) {
      config.appKey = appKey;
      config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
      config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
      config.beaconUrlHttp = 'http://' + url;
      config.beaconUrlHttps = 'https://' + url;
      config.xd = { enable: true };
    })(window['adrum-config'] || (window['adrum-config'] = {}));
    this.inject.createScript('https://cdn.appdynamics.com/adrum/adrum-4.3.5.0.js');
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
  /**
   * @description Obtein url to add tag noindex in CINV, LOGIN and assets
   */
  withOutIndex(){
    const urlCinv = 'https://cuentainversora.evobanco.com/';
    const urlEccomerce = 'https://ecommerce.evobanco.com/#/login';
    const urlAssets = 'https://v2.cuentainteligente.evobanco.com/assets/images/';
    const urlCiPRO = 'https://v2.cuentainteligente.evobanco.com/ ';
    const urlCiUAT = 'https://apiuat.evobanco.com:8443';
    const registroUAT = 'https://registrouat.evobanco.com/';
    const registroPro = 'https://registro.evobanco.com/';
    let currentUrl = window.location.href;
    let originUrl = window.location.origin;
    if ( currentUrl === urlCinv || currentUrl === urlEccomerce || currentUrl=== urlAssets || currentUrl === registroUAT || currentUrl=== registroPro){
      this.meta.addTag({
        name: 'robots',
        content: 'noindex,nofollow'
      })
    }
    if ( originUrl === urlCiPRO || originUrl === urlCiUAT ){
      this.meta.addTag({
        name: 'google-site-verification',
        content: 'gDN9mWf_PLSFbQmB-xljl5AgWH4hsiKRTE2WvqgSPcU'
      })
    }
  }
  setTitle() {
    //CI
    let newTitle = "EVO Banco | Contratación Cuenta Inteligente";
    //MICSEG
    const isMicSeg = location.href.indexOf('sin-tarificador') !== -1;
    if (isMicSeg) {
      newTitle = "EVO Banco | Contratación Evo Protect";
    }
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authservice.product === ACCOUNTS.CJCode );
    if (isYoungAccount) {
      // CJ
      newTitle = 'Abrir cuenta joven online sin comisiones EVO BANCO';
      this.meta.addTag({
        name: "description",
        content:
          "Si eres mayor de edad y tienes menos de 25 años, accede y conoce la Cuenta joven." +
          " Una cuenta online, sin comisiones y con tarjeta de débito GRATIS.",
      });
    }
    const ecommerce = location.hash.indexOf('login') !== -1 || location.hash.indexOf('perfil') !== -1;
    if (ecommerce){
      // CEF
      newTitle = "EVO Banco | Área Personal eCommerce";
    }

    const isAltaClienteA = sessionStorage.getItem("altaClienteA") === "true" || environment.type === 'REG' || environment.hostRegister === location.host;
    if (isAltaClienteA) {
      // Alta Clientes A
      newTitle = "Hazte EVO Lover | EVO Banco";
      this.titleService.setTitle( newTitle );
      this.helpers.navigateTo('/alta-cliente');
    }

    this.titleService.setTitle( newTitle );
  }
}
