import { Component, HostListener, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { NestedObject } from 'app/services/nestedObject.service';
import { AuthService } from 'app/services/auth.service';
import { Logalty, CheckLogalty, signLogalty, setCheckLogalty } from 'app/collections/logalty';
import { LogaltyService } from 'app/services/logalty.service';
import { ApiLogaltyService } from 'app/services/api/api.logalty.service';
import { DeviceService } from 'app/services/device.service';
import { ErrorService } from 'app/services/error.service';
import { ModalService } from 'app/services/modal.service';
import { ApiVideoeidService } from 'app/services/api/api.video-eid.service';
import { VideoEidService } from 'app/services/video-eid.service';
import { ApiIbanService } from 'app/services/api/api.iban.service';
import { HelperService } from 'app/services/helpers.service';
import { IbanService } from 'app/services/iban.service';
import { ProductSelection } from 'app/services/product-selection.service'
import { Persona } from 'app/collections/persona';
import { environment } from 'app/../environments/environment';
import { PAISES } from 'app/resources/countries.model';
import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { VIAS } from 'app/resources/tipo-via.model';
import { SITUACIONES } from 'app/resources/situacion-vivienda.model';
import { SITUACIONESLABORALES } from 'app/resources/situacion-laboral.model';
import { TIPOACTIVIDADPROFESIONAL } from 'app/resources/tipo-actividad-profesional.model';
import { ACCOUNTS } from 'app/resources/account.model';
import { REGISTERLITERALS } from 'app/resources/register-literals';
declare var $;

@Component({
  selector: 'logalty',
  templateUrl: './logalty.component.html',
  styleUrls: ['./logalty.component.scss'],
  providers: [LogaltyService, ApiLogaltyService, ApiVideoeidService, VideoEidService, ApiIbanService, IbanService, DeviceService, ModalService]
})
export class LogaltyComponent implements OnInit {

  public logaltySamlAction: string = environment.urlLogalty;
  private samlLoaded: Boolean = false;
  private persona: Persona;
  public loading: boolean = false;
  public msgSrvError: string = '';
  public modalSrvError: string = 'modal-srv-error';
  public isYoung: boolean = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
  public urlCallback: string = environment.urlBackFragment;
  public logaltyData;
  private secondSignerId;
  public logaltyLoaded = false;

  constructor(private elementRef: ElementRef,
    private router: Router,
    private authService: AuthService,
    private logaltySrv: LogaltyService,
    public deviceService: DeviceService,
    private videoSrv: VideoEidService,
    private ibanSrv: IbanService,
    public errService: ErrorService,
    public modService: ModalService,
    private helpers: HelperService,
    private productSelect: ProductSelection,
    public nestedObject: NestedObject) {
  }

  ngOnInit() {
    this.checkLogaltySigned();
  }

  ngAfterViewInit(){
    if(sessionStorage.getItem('logaltySigned') === "true"){
      this.checkLogaltyResponse();
    }
  }

  checkLogaltyResponse(){
    let logaltySigId = sessionStorage.getItem('logaltySignatureId');
    sessionStorage.removeItem('logaltySigned');
    this.watchForIframeClosing(logaltySigId);
  }

  setLogaltyProspec(logaltySignatureId, signatureId, office?) {
    sessionStorage.setItem('logaltySignatureId', logaltySignatureId);
    const canal = this.deviceService.getDevice();
    let isSecondSigner = false;
    if (this.productSelect.product && this.productSelect.product.isSecondSigner) {
      isSecondSigner = (this.productSelect.product.isSecondSigner) ? true : false;
    }

    let oficina =  office? office : environment.oficina;

    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      producto: this.productSelect.product,
      datosPersonales: {
        oficinaCliente: oficina
      },
      logalty: {
        logaltySignatureId,
        signatureId,
        isSecondSigner
      }
    })
  }

  loadSaml(persona) {
    this.loading = true;
    const logalty: signLogalty = new signLogalty();
    this.authService.getProspect(persona.datosPersonales.id_cliente_EVO).subscribe((data) => {
      this.loading = false;
      const dataFirst = data.datosPrimerTitular;

      let product = this.productSelect.getProductSelected();
      (!product) ? product = this.authService.product : product = product.codProductoContratado;

      this.productSelect.product = this.nestedObject.nestedObjectAssign({ codProductoContratado: product }, this.productSelect.product);
      if (dataFirst && dataFirst.id_cliente_EVO || this.productSelect.product.isSecondSigner || product === ACCOUNTS.STCode) {
        this.loadTwiceLogalty(data, logalty, true);
      } else {
        this.loadLogalty(data, logalty, false);
      }
    }, (_error) => {
      this.goToError();
    });

  }

  loadLogalty(data: Persona, logalty, isSecond) {
    this.loading = true;
    this.logaltySrv.signLogalty(this.firstCallLogalty(data)).subscribe((dataLogalty) => {
      this.responseLogalty(dataLogalty, data, logalty, isSecond);
    }, _error => {
      this.goToError();
    });
  }

  loadTwiceLogalty(data: Persona, logalty, isSecond) {
    this.loading = true;
    this.logaltySrv.signTwiceLogalty(this.firstCallLogalty(data)).subscribe((dataLogalty) => {
      this.responseLogalty(dataLogalty, data, logalty, isSecond);
    }, _error => {
      this.goToError();
    });
  }

  responseLogalty(dataLogalty, data, logalty, isSecond) {
    let office;
    if (dataLogalty.logaltySignatureId === null) {
      if (dataLogalty.operationErrorCode === 800 && dataLogalty.operationErrorMessage === 'Duplicated Key') {
        const num = Math.floor((Math.random() * 100) + 100);
        const modifyPersonData = this.nestedObject.nestedObjectAssign({}, data);
        if (typeof modifyPersonData.datosPersonales !== 'undefined') {
          office = modifyPersonData.datosPersonales.oficinaCliente;
        }
        if (typeof modifyPersonData.logalty === 'undefined') {
          modifyPersonData.logalty = Object.assign({});
        }
        if (isSecond) {
          modifyPersonData.logalty = Object.assign({
            signatureId: `${data.datosPersonales.id_cliente_EVO}_${num}`
          });
          this.loadTwiceLogalty(modifyPersonData, logalty, isSecond);
        }else {
          modifyPersonData.logalty = Object.assign({
            signatureId: `${data.datosPersonales.id_cliente_EVO}_${num}`
          });
          this.loadLogalty(modifyPersonData, logalty, isSecond);
        }
        return;
      } else {
        this.goToError(); return;
      }
    }
    if (this.productSelect.product.isSecondSigner) {
      this.setLogaltyProspec(dataLogalty.logaltySignatureId, data.logalty.signatureId, office);
    }else {
      this.setLogaltyProspec(dataLogalty.logaltySignatureId, dataLogalty.signatureId, office);
    }
    logalty = dataLogalty;
    this.logaltyData = dataLogalty;
    this.samlLoaded = !!logalty;
    this.authService.updateProspect(this.persona).subscribe((data) => {
      this.loading = false;
      //this.logaltyLoaded = true;
      if (data.response.codigo === 'KO') {
        this.msgSrvError = !!data.response.message ? data.response.message : 'Error genérico';
        this.modService.open(this.modalSrvError);
      }
      this.submitForm(this.helpers.encode(logalty.saml));
      this.logaltyLoaded = true;
    }, _error => {
      this.goToError();
    });
  }

  goToError() {
    this.loading = false;
    this.errService.navToError();
  }

  submitForm(saml) {
    $('form:first input[name=saml_assertion]').val(saml);
    $('form:first').submit();
  }

  watchForIframeClosing(logaltySignatureId) {
    if (logaltySignatureId) {
      const isYoungAccount = this.authService.product === ACCOUNTS.CJCode;
      const logalty: setCheckLogalty = new setCheckLogalty({
        product: isYoungAccount ? 'cuentajov' : 'fullonline',
        guids: [logaltySignatureId ]
      });
      this.loading = true;
      this.logaltySrv.checkState(logalty).subscribe(data => {
        if (data.operationErrorMessage === null && data.states[0].stateCode == 12 && data.states[0].resultCode == 7) {
          this.persona = this.authService.getSessionProspect();
          this.persona.complete = true;
          this.authService.setSessionProspect(this.persona);
          this.changeContratacion(true);
        } else {
          if (data.states[0].resultCode == 8) {
            this.errService.logaltyCancel = true;
          } else {
            this.errService.logaltyWrong = true;
          }
          this.changeContratacion(false);
        }
        this.loading = false;
      }, _error => {
        this.goToError();
      });
    } else {
      this.changeContratacion(false);
    }
  }

  changeContratacion(isOk: Boolean) {
    this.persona = this.authService.getSessionProspect();
    if (isOk) {
      if (this.authService.isAuthenticated()) {
        let nextstep = '/404';

      let product = this.productSelect.getProductSelected();
      (!product) ? product = this.authService.product : product = product.codProductoContratado;

      this.productSelect.product = this.nestedObject.nestedObjectAssign({ codProductoContratado: product }, this.productSelect.product);


        if (this.productSelect.product && (this.productSelect.product.codProductoContratado === ACCOUNTS.STCode || this.productSelect.product.isSecondSigner)) {
          if (this.productSelect.product.isSecondSigner) nextstep = 'ya-esta';
          else nextstep = 'casi-esta';
        } else {
          //Ahora solo hay identificacion por EID por lo que siempre deberia llevar al ahora-nosotros
          //if (!this.isEmpty(this.videoSrv.getLocalVideo()) || (this.persona.otrosDatos && this.persona.otrosDatos.tipoIdentificacion === 'identificacion-video')) {
            nextstep = '/ahora-nosotros';
          //} else {
          //  nextstep = '/verificacion-cuenta';
          //}
        }

        this.helpers.navigateTo(nextstep);
      } else {
        this.goToError();
      }
    } else {
      this.goToError();
    }
  }

  isEmpty(obj) {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
  }

  searchValue(values, value) {
    let name;
    values.forEach((item) => {
      if(item.descripcion_cno__c){
        item.descripcion_cno__c.forEach(cno => {
          if (cno['value'] && value && cno['value'].toUpperCase() === value.toUpperCase()) {
            name = cno;
          }
        });
      }else if (item['value'] && value && item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    return (name) ? name.name : name;
  }

  searchCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['code'] && value && item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    return (name) ? name.name : name;
  }

  getFieldUndefinedEscaped(field) {
    if (field === undefined || field === '' || field === null)
      return ' ';
    if (typeof field === 'string')
      return field.toUpperCase();
    else
      return field;
  }

  findProduct(products, identificador) {
    let product;
    // var productAccount = ACCOUNTS[this.productSelect.product.codProductoContratado];
    for (let i = 0; i < products.length; i++) {
      if (identificador === 'cc' && products[i].cc) {
        product = products[i].iban;
        break;
      }
      if (identificador === 'cd' && products[i].cd) {
        product = products[i].iban;
        break;
      }
    }
    return product;
  }

  firstCallLogalty(persona: Persona): Logalty {
    const data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();
    let logalty = <Logalty>{};
    /******** BREAKPOINT NACHO **********/
    logalty = this.nestedObject.nestedObjectAssign({}, logalty);
    logalty.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logalty.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logalty.bankInformation = this.nestedObject.nestedObjectAssign({});
    logalty.bankInformation2Signer = this.nestedObject.nestedObjectAssign({});
    logalty.jobInformation = this.nestedObject.nestedObjectAssign({});
    logalty.ownershipConfirmation = this.nestedObject.nestedObjectAssign({});
    logalty.personalInformation = this.nestedObject.nestedObjectAssign({});
    logalty.personalInformation.address = this.nestedObject.nestedObjectAssign({});
    logalty.promoData = this.nestedObject.nestedObjectAssign({});
    logalty.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
    logalty.consents = this.nestedObject.nestedObjectAssign({});

    logalty.businessPurpose = this.nestedObject.nestedObjectAssign({});
    logalty.product = 'fullonline';
    if (this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.CJCode) {
      logalty.product = 'cuentajov';
    }
    logalty.urlBackFragment = this.urlCallback;
    logalty.signatureUserData.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
    logalty.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
    logalty.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
    logalty.signatureUserData.email = this.getFieldUndefinedEscaped(persona.datosPersonales.email);
    logalty.signatureUserData.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logalty.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
    logalty.signatureUserData.signatureDate = `${date}/${month}/${year}`;
    logalty.signatureUserData.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';

    logalty.consents.perfilado = persona.indPerfiladoCliente === true ? `SÍ ${REGISTERLITERALS.checks.perfilado}` : `NO ${REGISTERLITERALS.checks.perfilado}`;
    logalty.consents.publi = persona.otrosDatos.indPubliPe === true ? `SÍ ${REGISTERLITERALS.checks.publicidad}` : `NO ${REGISTERLITERALS.checks.publicidad}`;
    logalty.consents.cesion = persona.indCesiEmpGrupo === true ? `SÍ ${REGISTERLITERALS.checks.cesion}` : `NO ${REGISTERLITERALS.checks.cesion}`;

    let signerId = `${persona.datosPersonales.id_cliente_EVO}_0`;
    if (persona.datosPrimerTitular && persona.datosPrimerTitular.id_cliente_EVO ||
      (this.productSelect.product && (this.productSelect.product.isSecondSigner || this.productSelect.product.codProductoContratado === ACCOUNTS.STCode))) {
      signerId = `${persona.datosPersonales.id_cliente_EVO}_second_signer_0`;
      if(persona.logalty){
        let personaSignatureID:any = persona.logalty.signatureId ;
        personaSignatureID = persona.logalty.signatureId.includes('_second_signer') ? `${personaSignatureID}` : `${personaSignatureID}_second_signer_0`
        persona.logalty.signatureId = `${personaSignatureID}`;
      }
    }
    let signatureiD = persona.logalty ? persona.logalty.signatureId || signerId : signerId;

    let includeSecondSigner = "";
    if(signatureiD.includes('_second_signer')){
      includeSecondSigner = signatureiD.split('_second_signer')[1];
    }

    let number = 0;
    const regex = /(_\d+)/g;
    let resultreg = signatureiD.match(regex)[0];
    signatureiD = signatureiD.split(resultreg)[0];
    resultreg = resultreg.split('_')[1];
    number = Number(resultreg) + 1;
    if (number && number !== NaN) {
      signatureiD = `${signatureiD}_${number}`;
      if(includeSecondSigner){
        signatureiD = `${signatureiD}_second_signer${includeSecondSigner}`;
      }
    }

    logalty.signatureUserData.signatureId = signatureiD;
    logalty.signatureUserData.nif = persona.datosPersonales.idExterno;
    logalty.signatureUserData.userId = persona.datosPersonales.id_cliente_EVO;
    logalty.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPersonales.paisResidencia);
    logalty.signatureUserData.countryResidence.alpha2Code = "Alpha2";
    logalty.signatureUserData.countryResidence.englishDescription = "N/A";
    logalty.jobInformation.labourStatus = this.searchValue(SITUACIONESLABORALES, persona.datosSocioEco.codigoSituacionLaboral);
    logalty.jobInformation.salaryRank = this.getFieldUndefinedEscaped(persona.datosSocioEco.nivelSalarial);
    logalty.jobInformation.salaryMonth = this.getFieldUndefinedEscaped(persona.datosSocioEco.ingresosMensuales);
    logalty.jobInformation.cno = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL, persona.datosSocioEco.codigoCNO));
    logalty.jobInformation.currentJobSeniority = this.getFieldUndefinedEscaped(persona.datosSocioEco.fechaAntiguedadLaboral);
    logalty.jobInformation.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(persona.datosSocioEco.fechaAntiguaCargo);
    logalty.jobInformation.companyName = this.getFieldUndefinedEscaped(persona.datosSocioEco.nombreEmpresa);
    logalty.jobInformation.jobTitle = this.getFieldUndefinedEscaped(persona.datosSocioEco.profesion);
    logalty.jobInformation.monthlySalary = this.getFieldUndefinedEscaped(persona.datosSocioEco.ingresosMensuales);
    logalty.jobInformation.globalLaborSeniority = this.getFieldUndefinedEscaped(persona.datosSocioEco.aniosGlobalesTrabajados);
    logalty.jobInformation.salaryNumber = this.getFieldUndefinedEscaped(persona.datosSocioEco.numeroPagas);
    logalty.ownershipConfirmation.ibanThirdParty = this.getFieldUndefinedEscaped(persona.datosSocioEco.IBAN);
    logalty.ownershipConfirmation.officeName = '0806 – Oficina Virtual';
    logalty.bankInformation.ibanAccount = 'IBAN proporcionado una vez formalizada la contratación';
    logalty.bankInformation.ibanInvestment = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.cardNumber = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.userElectronicBank = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.userAlerts = 'Dato proporcionado una vez formalizada la contratación';
    logalty.personalInformation.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logalty.personalInformation.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
    logalty.personalInformation.firstLastName = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
    logalty.personalInformation.secondLastName = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
    logalty.personalInformation.nationality = this.searchCodeFromValue(PAISES, persona.datosPersonales.paisNacionalidad);
    logalty.personalInformation.countryResidence = this.getFieldUndefinedEscaped(this.searchValue(PAISES, persona.datosPersonales.paisResidencia));
    logalty.personalInformation.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    logalty.personalInformation.identityNumber = persona.datosPersonales.idExterno;
    logalty.personalInformation.identityDocumentExpirationDate = this.getFieldUndefinedEscaped(persona.datosPersonales.fechaCaducidadDocumento);
    logalty.personalInformation.maritalStatus = this.searchValue(ESTADO_CIVIL, persona.datosPersonales.codigoEstadoCivil);
    logalty.personalInformation.birthdate = this.getFieldUndefinedEscaped(persona.datosPersonales.fechaNacimiento);
    logalty.personalInformation.birthCountry = this.searchValue(PAISES, persona.datosPersonales.codigoPaisNacimiento);
    logalty.personalInformation.province = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreProvinciaDomicilio);
    logalty.personalInformation.localization = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreLocalidad);
    logalty.personalInformation.roadType = this.searchValue(VIAS, persona.datosDomicilio.codigoDomicilioTipoVia);
    logalty.personalInformation.homeSituation = this.searchValue(SITUACIONES, persona.datosSocioEco.situacionVivienda);
    logalty.personalInformation.monthlyRentImport = this.getFieldUndefinedEscaped(persona.datosDomicilio.cuotaAlquilerMensual);
    logalty.personalInformation.address.street = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreVia);
    logalty.personalInformation.address.buildingNumber = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioNumero);
    logalty.personalInformation.address.floorLevel = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPlanta);
    logalty.personalInformation.address.door = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPuerta);
    logalty.personalInformation.address.portal = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPortal);
    logalty.personalInformation.address.block = this.getFieldUndefinedEscaped(persona.datosDomicilio.bloque);
    logalty.personalInformation.address.phase = this.getFieldUndefinedEscaped(persona.datosDomicilio.fase);
    logalty.personalInformation.address.plot = this.getFieldUndefinedEscaped(persona.datosDomicilio.parcela);
    logalty.personalInformation.address.postalCode = this.getFieldUndefinedEscaped(persona.datosDomicilio.codigoPostal);
    logalty.personalInformation.address.stairway = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioEscalera);
    logalty.personalInformation.address.locality = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreLocalidad);
    logalty.consents.perfilado = persona.indPerfiladoCliente === true ? `SÍ ${REGISTERLITERALS.checks.perfilado}` : `NO ${REGISTERLITERALS.checks.perfilado}`;
    logalty.consents.publi = persona.otrosDatos.indPubliPe === true ? `SÍ ${REGISTERLITERALS.checks.publicidad}` : `NO ${REGISTERLITERALS.checks.publicidad}`;
    logalty.consents.cesion = persona.indCesiEmpGrupo === true ? `SÍ ${REGISTERLITERALS.checks.cesion}` : `NO ${REGISTERLITERALS.checks.cesion}`;

    //Añadidos los campos de proposito de negocio MEIF0-7631
    logalty.businessPurpose.financing = persona.datosSocioEco.financiacion;
    logalty.businessPurpose.otherProducts = persona.datosSocioEco.OtrosProductos;
    logalty.businessPurpose.payrollPensionOthers = persona.datosSocioEco.nominaPensionOtros;
    logalty.businessPurpose.professionalActivity = persona.datosSocioEco.actividadProfesionalPBC;
    logalty.businessPurpose.savingsInvestment = persona.datosSocioEco.ahorroInversion;
    logalty.businessPurpose.servicePayment = persona.datosSocioEco.ServiciosPago;

    const signatureId = !!(this.productSelect.product && this.productSelect.product.signatureId);
    if (persona.datosPrimerTitular && persona.datosPrimerTitular.id_cliente_EVO && !signatureId) {
      logalty.isSecondSigner = false;
      logalty.signatureUserData2Signer = this.nestedObject.nestedObjectAssign({});
      logalty.signatureUserData2Signer.countryResidence = this.nestedObject.nestedObjectAssign({});

      logalty.signatureUserData2Signer.firstName = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.nombreCliente);
      logalty.signatureUserData2Signer.lastName1 = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.apellido1Cliente);
      logalty.signatureUserData2Signer.lastName2 = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.apellido2Cliente);
      logalty.signatureUserData2Signer.email = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.email);
      logalty.signatureUserData2Signer.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.telefonoMovil);
      logalty.signatureUserData2Signer.treatment = persona.datosPrimerTitular.sexo === 'H' ? 'M' : 'F';
      logalty.signatureUserData2Signer.signatureDate = `${date}/${month}/${year}`;
      logalty.signatureUserData2Signer.identityDocumentType = persona.datosPrimerTitular.codigoIdExterno === '51' ? 'DNI' : 'NIE';
      logalty.signatureUserData2Signer.signatureId = `${persona.datosPrimerTitular.id_cliente_EVO}_second_signer_1`;
      logalty.signatureUserData2Signer.nif = persona.datosPrimerTitular.idExterno;
      logalty.signatureUserData2Signer.userId = persona.datosPrimerTitular.id_cliente_EVO;
      logalty.signatureUserData2Signer.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.paisResidencia);
      logalty.signatureUserData2Signer.countryResidence.alpha2Code = "Alpha2";
      logalty.signatureUserData2Signer.countryResidence.englishDescription = "N/A";
      logalty.consents.perfilado = persona.indPerfiladoCliente === true ? `SÍ ${REGISTERLITERALS.checks.perfilado}` : `NO ${REGISTERLITERALS.checks.perfilado}`;
      logalty.consents.publi = persona.otrosDatos.indPubliPe === true ? `SÍ ${REGISTERLITERALS.checks.publicidad}` : `NO ${REGISTERLITERALS.checks.publicidad}`;
      logalty.consents.cesion = persona.indCesiEmpGrupo === true ? `SÍ ${REGISTERLITERALS.checks.cesion}` : `NO ${REGISTERLITERALS.checks.cesion}`;

      if (persona.datosPrimerTitular && !this.helpers.isEmpty(persona.datosPrimerTitular.productos)){
        logalty.bankInformation2Signer.ibanAccount = this.findProduct(persona.datosPrimerTitular.productos, 'cc');
        logalty.bankInformation2Signer.ibanInvestment = this.findProduct(persona.datosPrimerTitular.productos,'cd');
        logalty.bankInformation2Signer.ibanAccount = 'IBAN proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.ibanInvestment = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.cardNumber = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.userElectronicBank = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.userAlerts = 'Dato proporcionado una vez formalizada la contratación';
      }
    } else {
      if (this.productSelect.product && this.productSelect.product.isSecondSigner) {
        logalty.isSecondSigner = true;
        logalty.signatureUserData2Signer = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData.signatureId = this.productSelect.product.signatureId;
        this.secondSignerId = signatureiD;
        logalty.signatureUserData2Signer.signatureId = signatureiD;
        logalty.signatureUserData2Signer.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
        logalty.signatureUserData2Signer.lastName1 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
        logalty.signatureUserData2Signer.lastName2 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
        logalty.signatureUserData2Signer.email = this.getFieldUndefinedEscaped(persona.datosPersonales.email);
        logalty.signatureUserData2Signer.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
        logalty.signatureUserData2Signer.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
        logalty.signatureUserData2Signer.signatureDate = `${date}/${month}/${year}`;
        logalty.signatureUserData2Signer.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
        logalty.signatureUserData2Signer.nif = persona.datosPersonales.idExterno;
        logalty.signatureUserData2Signer.userId = persona.datosPersonales.id_cliente_EVO;
        logalty.signatureUserData2Signer.countryResidence = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData2Signer.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPersonales.paisResidencia);
        logalty.signatureUserData2Signer.countryResidence.alpha2Code = "Alpha2";
        logalty.signatureUserData2Signer.countryResidence.englishDescription = "N/A";
        logalty.signatureUserData2Signer.nif = persona.datosPersonales.idExterno;
        logalty.signatureUserData2Signer.userId = persona.datosPersonales.id_cliente_EVO;
        logalty.bankInformation.ibanAccount = this.findProduct(persona.datosSocioEco.productos, 'cc');
        logalty.bankInformation.ibanInvestment = this.findProduct(persona.datosSocioEco.productos, 'cd');
        logalty.consents.perfilado = persona.indPerfiladoCliente === true ? `SÍ ${REGISTERLITERALS.checks.perfilado}` : `NO ${REGISTERLITERALS.checks.perfilado}`;
        logalty.consents.publi = persona.otrosDatos.indPubliPe === true ? `SÍ ${REGISTERLITERALS.checks.publicidad}` : `NO ${REGISTERLITERALS.checks.publicidad}`;
        logalty.consents.cesion = persona.indCesiEmpGrupo === true ? `SÍ ${REGISTERLITERALS.checks.cesion}` : `NO ${REGISTERLITERALS.checks.cesion}`;

      }
    }
    //Solo se activa la promocion si es cuenta Inteligente o 2 titularres
    if (this.isCiOrSecondHolder()) {
      const promocion= JSON.parse(sessionStorage.getItem("currentProspect")).promo;
      logalty.promoData.activo = promocion ? this.getFieldUndefinedEscaped(promocion.activo) : true;
      logalty.promoData.fechaFinPromo = promocion ?this.getFieldUndefinedEscaped(promocion.fechaFinPromo) : '';
      logalty.promoData.fechaInicioPromo = promocion ?this.getFieldUndefinedEscaped(promocion.fechaInicioPromo) : '';
      logalty.promoData.idPersona = promocion ?this.getFieldUndefinedEscaped(promocion.idPersona) : '';
      logalty.promoData.numeroPack = promocion ?this.getFieldUndefinedEscaped(this.getNumPackPromo(promocion)) : '';
    }else{
      logalty.promoData.activo=false;
    }
    return logalty;
  }

  isCiOrSecondHolder(){
    const isCI =  this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.CICode && JSON.parse(sessionStorage.getItem("currentProspect")).promo ||
                this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.CICode && JSON.parse(sessionStorage.getItem("promoActiva"))===true ||
                this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.CICode && JSON.parse(sessionStorage.getItem("currentProspect")).promoTemporal;
    const issecondHolder =  this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.STCode && JSON.parse(sessionStorage.getItem("currentProspect")).promo ||
                          this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.STCode && JSON.parse(sessionStorage.getItem("promoActiva"))===true ||
                          this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.STCode && JSON.parse(sessionStorage.getItem("currentProspect")).promoTemporal;
    return isCI || issecondHolder;
  }

  getNumPackPromo(promocion){
    const numeroPack = promocion.numeroPack ? promocion.numeroPack : sessionStorage.getItem('numPromo');
    return numeroPack;
  }
  checkPromoActive(){
    const prospect = JSON.parse(sessionStorage.getItem('currentProspect'))
    console.log(prospect.promo);
    return prospect.promo;
  }
  getPromoActive(persona){
    let isSecondSigner = false;
    if (this.productSelect.product && this.productSelect.product.isSecondSigner) {
      isSecondSigner = (this.productSelect.product.isSecondSigner) ? true : false;
    }
    const data={
      canal: this.deviceService.getDevice(),
      estado:persona.estado,
      codigoEntidad:"0239",
      logalty:{
        codProductoContratado:sessionStorage.getItem('product'),
        isSecondSigner:isSecondSigner
      }
    }
    this.authService.updateProspect(data).subscribe(
      goToSign=>{
        this.loadSaml(persona)
      }, _error => {
        this.goToError();
      }
    )

  }
  checkLogaltySigned() {
    if(sessionStorage.getItem('logaltySigned') !== "true"){
      const persona = this.authService.getSessionProspect();
      if(this.checkPromoActive()){
        this.loadSaml(persona);
      }else{
        this.getPromoActive(persona);
      };
    }
  }
}
