import { Injectable } from '@angular/core';
import { IbanImage, getIban } from '../collections/iban';
import { ApiIbanService } from './api/api.iban.service';
import { Persona } from '../collections/persona';
import { Observable } from 'rxjs';

@Injectable()
export class IbanService {
  constructor(private api:ApiIbanService) { }

  getLocalIban():getIban{
    return this.api.getSessionImagesIban();
  }

  setSessionImagesIban(iban:getIban){
    return this.api.setSessionImagesIban(iban);
  }
  setSessionPreviousIban(iban:getIban){
    return this.api.setSessionPreviuosIban(iban);
  }
  getLocalPreviousIban():getIban{
    return this.api.getSessionPreviousIban();
  }

  sendImages(persona: Persona): Observable<any>{
    return this.api.sendImages(persona);
  }
}
