import { Directive, ElementRef, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[iban-input]'
})
export class IbanInputDirective {
  limit:number = 27;

  @Input() disabledIban = false;

  constructor(
    private elementRef: ElementRef,
    private ngControl : NgControl
  ) {
    this.elementRef.nativeElement.addEventListener('keydown',this.onKeyDown.bind(this));
    this.elementRef.nativeElement.addEventListener('keypress', this.onKeyPressBlur.bind(this));
    this.elementRef.nativeElement.addEventListener('blur', this.onKeyPressBlur.bind(this));
    this.elementRef.nativeElement.addEventListener('paste', this.onPaste.bind(this));
    this.elementRef.nativeElement.addEventListener('keyup', this.onKeyUp.bind(this));
  }


  onPaste(_e){
    _e.preventDefault();
    const pastedString = _e.clipboardData.getData('text/plain'),
          validatedString = this.validateString(pastedString),
          finalString = this.formatString(validatedString);

    return !this.disabledIban && this.ngControl.control.setValue(finalString);
  }

  validateString(string){
    const isNumberRegExp = /^\d+$/;

    let validatedString : string = '';

    for(let i = 0; i < string.length; i++) {
      let currentChar = string.charAt(i);

      if(currentChar.match(isNumberRegExp)) {
         validatedString += currentChar;
      }
    }
    return validatedString;
  }

  formatString(string) {
    let finalString = '';
    if(string.length >= 18){
      finalString = string.substr(0, 2)
                    + ' ' + string.substr(2, 4)
                    + ' ' + string.substr(6, 4)
                    + ' ' + string.substr(10, 4)
                    + ' ' + string.substr(14, 4)
                    + ' ' + string.substr(18);
    } else {
      if(string.length >= 14){
        finalString = string.substr(0, 2)
                      + ' ' + string.substr(2, 4)
                      + ' ' + string.substr(6, 4)
                      + ' ' + string.substr(10, 4)
                      + ' ' + string.substr(14);
      } else {
        if(string.length >= 10){
          finalString = string.substr(0, 2)
                        + ' ' + string.substr(2, 4)
                        + ' ' + string.substr(6, 4)
                        + ' ' + string.substr(10);
        } else {
          if(string.length >= 6){
            finalString = string.substr(0, 2)
                          + ' ' + string.substr(2, 4)
                          + ' ' + string.substr(6);
          } else {
            if(string.length >= 2){
              finalString = string.substr(0, 2)
                            + ' ' + string.substr(2);
            }
          }
        }
      }
    }
    return finalString;
  }

  onKeyPressBlur(_e) {
    this.checkNumber(_e);
    this.checkLength(_e);
    this.validateIban(_e);
  }

  onKeyUp(_e) {
    this.validateIban(_e);
  }

  onKeyDown(e) {
    if(e.keyCode === 16) e.preventDefault;
  }

  checkLength(_e) {
    let textSelected = window.getSelection().toString();
    if(_e.target.value.length === this.limit && textSelected.length == 0) _e.preventDefault();
  }

  checkNumber(_e) {
    const regEx = new RegExp('^\\d+$');
    let pressedKey = _e.key;
    if(!regEx.test(pressedKey))  {
      _e.preventDefault();
    }
  }

  validateIban(_e) {
    const placeholder = '00 0000 0000 0000 0000 0000';
    const selector = this.elementRef.nativeElement.selection;
    const elemento = this.elementRef.nativeElement;
    let char =  _e.key;
    let auxini = elemento.selectionStart;

    if((auxini == 2 || auxini == 7 || auxini == 12 || auxini == 17 || auxini == 22) && char != 'Backspace' && elemento.value[auxini] != ' '){
      elemento.value = elemento.value.substr(0, auxini) + " " + elemento.value.substr(auxini);
    }
  }
}
