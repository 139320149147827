<loading [showMe]="loading"></loading>
<!-- <mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone> -->

<modal
  modalTitle="SaveDataSuccessModalCI"
  modalId="SaveDataSuccessModalCI"
  class="modal"
>
  <div class="save-data-success-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">Tus datos han sido guardados correctamente</p>
      <p class="sub-title">
        Recuerda que <strong>puedes volver donde lo dejaste.</strong> Solo
        tendrás que incluir tu teléfono y un código de seguridad que recibirás
        en tu móvil.
      </p>
      <button
        class="button btn-common"
        (click)="this.modService.close(this.saveModal)"
      >
        Entendido
      </button>
    </div>
  </div>
</modal>

<modal modalTitle="BackModalCI" modalId="BackModalCI" class="modal">
  <div class="back-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">¿Quieres guardar antes de salir?</p>
      <p class="sub-title">
        Guarda la información para retomar el proceso donde lo dejaste la
        próxima vez que entres.
      </p>
      <button
        class="btn-common button"
        (click)="this.modService.close(this.backModal); saveData()"
      >
        Guardar
      </button>
      <button
        class="btn-common button button-back"
        (click)="this.modService.close(this.backModal)"
      >
        Ahora no
      </button>
    </div>
  </div>
</modal>

<modal
  [modalId]="alreadyClientModal"
  [blocking]="true"
  class="modal modal-login"
>
  <mobile-error-zone
    *ngIf="errService.errorsArray.length > 0"
    [errors]="errService.errorsArray"
    class="custom-erros"
  >
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <form
      (keydown.enter)="$event.preventDefault()"
      [formGroup]="loginUser"
      (ngSubmit)="sendClient()"
      novalidate
    >
      <div class="equal-buttons">
        <input
          placeholder="Usuario (NIF/NIE)"
          type="text"
          name="user"
          formControlName="user"
          (focus)="closeKeyboard()"
          (blur)="validUser()"
          autocomplete="off"
          maxlength="9"
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(loginUser.get('user'), 'user')"
        >
          {{ errService.desktopErrors(loginUser.get("user"), "user") }}
        </div>
        <input
          placeholder="Contraseña"
          type="password"
          name="pass"
          formControlName="pass"
          maxlength="6"
          (numberEmmited)="numberEmmited($event)"
          autocomplete="off"
          (deleteLast)="deleteLast($event)"
          onfocus="blur()"
          keyboard
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(loginUser.get('pass'), 'pass')"
        >
          {{ errService.desktopErrors(loginUser.get("pass"), "pass") }}
        </div>
      </div>
      <div class="row">
        <button
          class="btn-common btn-common_fix_bold"
          type="submit"
          (click)="closeKeyboard()"
        >
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class="label">
        ¿No tienes claves para entrar? Llámanos al
        <a href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>
<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>

<!--Nuevo diseño CJ MEIF0-7385-->
<div *ngIf="!isCIAccount">
  <div class="container-newCJDesigner">
    <div class="container-newCJDesigner__sideBar contact-detail-page">
      <div class="container-newCJDesigner__sideBar-img">
        <img class="desktopSizeImage" src="assets/images/img-fol-cj-4.png" alt="Datos de contacto">
        <img class="tabletSizeImage" src="assets/images/img-fol-cj-2.png" alt="Datos de contacto">
      </div>
      <div class="container-newCJDesigner__sideBar-content">
        <div class="container-newCJDesigner__sideBar-content-paragraph">
          <img src="assets/images/icon-lock-feel/ic-porcentaje-ic-mano-flecha.svg" alt="Porcentaje">
          <h3>CASHBACK</h3>
          <P>Un % de tus compras vuelve directo a tu cuenta.</P>
        </div>
      </div>
    </div>
    <div class="container-newCJDesigner__main">
        <div class="container-newCJDesigner__step-component">
          <steps-ci [youngAccount]="isYoungAccount" class="step-component"></steps-ci>
        </div>
        <hr class="screenSizeSeparator">
        <div class="container-newCJDesigner__content">
          <div class="container-newCJDesigner__content-title">
              <h2>Datos de contacto</h2>
              <p>¡Ya queda menos! Necesitamos que nos facilites tus datos de contacto para poder continuar con el proceso.</p>
          </div>
          <div class="container-newCJDesigner__form">
              <form
                    (keydown.enter)="$event.preventDefault()"
                    [formGroup]="contactDetailsForm"
                    (ngSubmit)="onSubmit()"
                    novalidate>
                <div class="container-newCJDesigner__form-row">
                    <div class="container-newCJDesigner__form-input">
                      <label for="pais" class="form-label">País de nacimiento</label>
                      <dropdown
                        class="dropdown"
                        id="pais"
                        name="pais"
                        formControlName="pais"
                        [options]="countries">
                        </dropdown>
                        <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('pais'),
                          'paisNacimiento'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("pais"),
                          "paisNacimiento"
                        )
                      }}
                      </div>
                    </div>
                    <div class="container-newCJDesigner__form-input"
                        *ngIf="isSpain">
                      <label for="provinciaNacimiento" class="form-label">Provincia de Nacimiento</label>
                      <dropdown
                        class="dropdown"
                        id="provinciaNacimiento"
                        name="provinciaNacimiento"
                        formControlName="provinciaNacimiento"
                        [options]="provinces"></dropdown>
                        <div
                        class="error-text"
                        *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('provinciaNacimiento'),
                          'provinciaNacimiento')
                        ">
                        {{
                          errService.desktopErrors(
                            contactDetailsForm.get("provinciaNacimiento"),
                            "provinciaNacimiento"
                          )
                        }}
                    </div>
                  </div>

                    <div class="container-newCJDesigner__form-input"
                        *ngIf="!isSpain">
                      <label for="localidadnacimiento" class="form-label">Localidad de nacimiento</label>
                      <input
                            class="dropdown"
                            type="text"
                            id="localidadnacimiento"
                            name="localidadnacimiento"
                            formControlName="localidadnacimiento"
                            [maxlength]="!isCountrySelected ? 0 : 45"
                            placeholder="Localidad de nacimiento"
                            autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('localidadnacimiento'),
                          'localidadnacimiento'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("localidadnacimiento"),
                          "localidadnacimiento"
                        )
                      }}
                      </div>
                    </div>
                </div>

                <div class="container-newCJDesigner__form-row">
                  <!-- <div class="mobile-container">
                    <div class="group-radio">
                      <p class="false-label">¿Tu país de residencia es España?</p>
                      <div class="options">
                        <input
                              type="radio"
                              id="si"
                              value="ESP"
                              name="paisResidencia"
                              formControlName="paisResidencia" />
                        <label id="si-label" class="button-label" for="si"></label>
                        <input
                              type="radio"
                              id="no"
                              value="noES"
                              name="paisResidencia"
                              formControlName="paisResidencia" />
                        <label id="no-label" class="button-label" for="no"></label>
                      </div>
                    </div>
                  </div> -->
                  <div class="container-newCJDesigner__form-input">
                    <label for="paisResidencia" class="form-label">¿Tu país de residencia es España?</label>
                    <dropdown
                      class="dropdown"
                      id="paisResidencia"
                      name="paisResidencia"
                      formControlName="paisResidencia"
                      [options]="options"
                      >
                    </dropdown>
                    <div class="error-text"
                    *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('paisResidencia'),
                          'paisResidencia')
                          ">
                      {{
                        errService.desktopErrors(
                          contactDetailsForm.get("paisResidencia"),
                          "paisResidencia"
                        )
                      }}
                    </div>
                  </div>
                    <div class="container-newCJDesigner__form-input">
                      <label for="pais" class="form-label">Obligaciones fiscales fuera de España</label>
                      <dropdown
                        class="dropdown"
                        id="obligacionesFiscales"
                        name="obligacionesFiscales"
                        formControlName="obligacionesFiscales"
                        [options]="options">
                      </dropdown>
                      <div class="error-text"
                      *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('obligacionesFiscales'),
                          'obligacionesFiscales')
                          ">
                      {{
                        errService.desktopErrors(
                          contactDetailsForm.get("obligacionesFiscales"),
                          "obligacionesFiscales"
                        )
                      }}
                      </div>
                    </div>

                </div>

                <div
                [ngClass]="isMarried ? 'container-newCJDesigner__form-row' : 'container-newCJDesigner__form-row half' ">
                  <div class="container-newCJDesigner__form-input">
                    <label for="estadoCivil" class="form-label">Estado Civil</label>
                      <dropdown
                        class="dropdown"
                        #estadoCivil
                        id="estadoCivil"
                        name="estadoCivil"
                        formControlName="estadoCivil"
                        [ngClass]="isMarried ? 'dropdown': 'dropdown half' "
                        [options]="civilStatus"></dropdown>
                        <div
                            class="error-text"
                            *ngIf="
                          errService.desktopErrors(
                            contactDetailsForm.get('estadoCivil'),
                            'estadoCivil_ci'
                          )
                        ">
                          {{
                          errService.desktopErrors(
                            contactDetailsForm.get("estadoCivil"),
                            "estadoCivil_ci"
                          )
                        }}
                        </div>
                  </div>
                      <div *ngIf="isMarried" class="container-newCJDesigner__form-input">
                        <label for="regimenEconomico" class="form-label">Régimen Económico</label>
                        <dropdown
                          class="dropdown"
                          id="regimenEconomico"
                          name="regimenEconomico"
                          formControlName="regimenEconomico"
                          [options]="economicSystems"></dropdown>
                          <div
                          class="error-text"
                          *ngIf="
                            errService.desktopErrors(
                              contactDetailsForm.get('regimenEconomico'),
                              'regimenEconomico'
                            )
                            ">
                      {{
                        errService.desktopErrors(
                          contactDetailsForm.get("regimenEconomico"),
                          "regimenEconomico"
                        )
                      }}
                      </div>
                    </div>
                  <!-- <div *ngIf="!isMarried" class="container-newCJDesigner__form-row-input"></div> -->
                </div>

                <div class="container-newCJDesigner__form-row separator">
                  <div class="form-label">
                    <p>
                      Asegúrate de indicar la dirección completa de tu domicilio.
                    </p>
                  </div>
                </div>

                <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="tipoVia" class="form-label">Tipo de vía</label>
                    <dropdown
                      class="dropdown"
                      id="tipoVia"
                      name="genero"
                      formControlName="tipoVia"
                      [options]="vias"></dropdown>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                      <label for="direccion" class="form-label">Nombre de la vía</label>
                    <input
                          type="text"
                          class="dropdown"
                          id="direccion"
                          name="direccion"
                          required
                          #direction
                          placeholder="Escribe"
                          maxlength="45"
                          formControlName="direccion"
                          autocomplete="off" />
                    <div
                        class="error-text"
                        *ngIf="
                      errService.desktopErrors(
                        contactDetailsForm.get('direccion'),
                        'direccion'
                      )
                    ">
                      {{
                      errService.desktopErrors(
                        contactDetailsForm.get("direccion"),
                        "direccion"
                      )
                    }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-row-group">
                    <div class="container-newCJDesigner__form-input">
                      <label for="numero" class="form-label">Número</label>
                      <input
                            type="text"
                            class="dropdown"
                            id="numero"
                            name="numero"
                            required
                            placeholder="Escribe"
                            pattern="\d*"
                            size="4"
                            maxlength="4"
                            formControlName="numero"
                            autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('numero'),
                          'numero'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("numero"),
                          "numero"
                        )
                      }}
                      </div>
                    </div>
                    <div class="container-newCJDesigner__form-input">
                    <label for="planta" class="form-label">Piso</label>
                    <input
                          type="text"
                          class="dropdown"
                          id="planta"
                          name="planta"
                          placeholder="Escribe"
                          size="3"
                          maxlength="3"
                          formControlName="planta"
                          autocomplete="off" />
                    <div
                        class="error-text"
                        *ngIf="
                      errService.desktopErrors(
                        contactDetailsForm.get('planta'),
                        'planta'
                      )
                    ">
                      {{
                      errService.desktopErrors(
                        contactDetailsForm.get("planta"),
                        "planta"
                      )
                    }}
                    </div>
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-row-group">
                      <div class="container-newCJDesigner__form-input">
                      <label for="puerta" class="form-label">Puerta</label>
                      <input
                            type="text"
                            class="dropdown"
                            id="puerta"
                            name="puerta"
                            placeholder="Opcional"
                            maxlength="2"
                            formControlName="puerta"
                            autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('puerta'),
                          'puerta'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("puerta"),
                          "puerta"
                        )
                      }}
                      </div>
                      </div>
                      <div class="container-newCJDesigner__form-input">
                      <label for="portal" class="form-label">Portal</label>
                      <input
                          type="text"
                          class="dropdown"
                          id="portal"
                          name="portal"
                          placeholder="Opcional"
                          maxlength="2"
                          formControlName="portal"
                          autocomplete="off" />
                        <div
                            class="error-text"
                            *ngIf="
                          errService.desktopErrors(
                            contactDetailsForm.get('portal'),
                            'portal'
                          )
                        ">
                          {{
                          errService.desktopErrors(
                            contactDetailsForm.get("portal"),
                            "portal"
                          )
                        }}
                        </div>
                      </div>
                  </div>
                </div>

                <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-row-group">
                      <div class="container-newCJDesigner__form-input">
                    <label for="bloque" class="form-label">Bloque</label>
                    <input
                      type="text"
                      class="dropdown"
                      id="bloque"
                      name="bloque"
                      maxlength="2"
                      placeholder="Opcional"
                      formControlName="bloque"
                      autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('bloque'),
                          'bloque'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("bloque"),
                          "bloque"
                        )
                      }}
                      </div>
                      </div>
                      <div class="container-newCJDesigner__form-input">
                      <label for="escalera" class="form-label">Escalera</label>
                      <input
                            type="text"
                            class="dropdown"
                            id="escalera"
                            name="escalera"
                            placeholder="Opcional"
                            maxlength="2"
                            formControlName="escalera"
                            autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('escalera'),
                          'escalera'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("escalera"),
                          "escalera"
                        )
                      }}
                      </div>
                      </div>
                   </div>
                  <div class="container-newCJDesigner__form-row-group">
                      <div class="container-newCJDesigner__form-input">
                        <label for="fase" class="form-label">Fase</label>
                        <input
                              type="text"
                              class="dropdown"
                              id="fase"
                              name="fase"
                              placeholder="Opcional"
                              maxlength="3"
                              formControlName="fase"
                              autocomplete="off" />
                        <div
                            class="error-text"
                            *ngIf="
                          errService.desktopErrors(contactDetailsForm.get('fase'), 'fase')
                        ">
                          {{
                          errService.desktopErrors(contactDetailsForm.get("fase"), "fase")
                        }}
                        </div>
                      </div>
                      <div class="container-newCJDesigner__form-input">
                        <label for="parcela" class="form-label">Parcela</label>
                        <input
                              type="text"
                              class="dropdown"
                              id="parcela"
                              name="parcela"
                              placeholder="Opcional"
                              maxlength="45"
                              formControlName="parcela"
                              autocomplete="off" />
                        <div
                            class="error-text"
                            *ngIf="
                          errService.desktopErrors(
                            contactDetailsForm.get('parcela'),
                            'parcela'
                          )
                        ">
                          {{
                          errService.desktopErrors(
                            contactDetailsForm.get("parcela"),
                            "parcela"
                          )
                        }}
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="codigoPostal" class="form-label">Código postal</label>
                    <input
                          type="tel"
                          class="dropdown"
                          id="codigoPostal"
                          name="codigoPostal"
                          required
                          placeholder="Código postal"
                          size="5"
                          pattern="\d*"
                          number-input
                          inputmode="numeric"
                          maxlength="5"
                          formControlName="codigoPostal"
                          autocomplete="off"
                           />
                    <div
                        class="error-text"
                        *ngIf="
                      errService.desktopErrors(
                        contactDetailsForm.get('codigoPostal'),
                        'codigoPostal'
                      )
                    ">
                      {{
                      errService.desktopErrors(
                        contactDetailsForm.get("codigoPostal"),
                        "codigoPostal"
                      )
                    }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="localidad" class="form-label">Localidad</label>
                    <dropdown
                              class="dropdown"
                              id="Localidad"
                              name="Localidad"
                              formControlName="localidad"
                              [options]="selectLocalidades"
                              [isUp]="true"></dropdown>
                              <div
                              class="error-text"
                              *ngIf="
                      errService.desktopErrors(
                        contactDetailsForm.get('localidad'),
                        'localidad_ci'
                      )
                      ">
                      {{
                        errService.desktopErrors(
                          contactDetailsForm.get("localidad"),
                          "localidad_ci"
                        )
                      }}
                      </div>
                    </div>
                </div>
                <div class="container-newCJDesigner__form-row">
                <div class="container-newCJDesigner__form-input">
                  <label for="provincia" class="form-label">Provincia</label>
                      <input
                            type="text"
                            class="dropdown"
                            id="provincia"
                            name="provincia"
                            placeholder="Provincia"
                            disabled
                            formControlName="provincia"
                            autocomplete="off" />
                      <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('provincia'),
                          'provincia'
                        )
                      ">
                        {{
                        errService.desktopErrors(
                          contactDetailsForm.get("provincia"),
                          "provincia"
                        )
                      }}
                      </div>
                    </div>
                    <div class="container-newCJDesigner__form-input">
                      <label for="situacionVivienda" class="form-label">Situación de la vivienda</label>
                      <dropdown
                          class="dropdown"
                          id="situacionVivienda"
                          name="situacion"
                          formControlName="situacionVivienda"
                          [options]="situaciones"
                          [isUp]="true"></dropdown>
                          <div
                          class="error-text"
                          *ngIf="
                        errService.desktopErrors(
                          contactDetailsForm.get('situacionVivienda'),
                          'situacionVivienda'
                        )
                        ">
                        {{
                          errService.desktopErrors(
                            contactDetailsForm.get("situacionVivienda"),
                            "situacionVivienda"
                          )
                        }}
                        </div>
                      </div>
                </div>
                <div class="container-newCJDesigner__form-row">
                <div class="container-newCJDesigner__form-input input-last-row">
                  <div class="return-label">
                    <p (click)="returnInit()">
                      <img src="assets/images/icon-lock-feel/ic-flecha-black.svg" alt="icon">
                      Atrás
                    </p>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-input input-last-row">
                  <div class="btn-group">
                    <button type="submit" class="btn-common btn-common_fix_bold">
                      CONTINUAR
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
    </div>

  </div>
</div>


<div class="main-register flex-direction" *ngIf="isCIAccount">
  <box-image
    [classImgFile]="'datos_contacto03'"
    [icon]="'ic-movil-euro'"
    [titleCss]="'title-2 '"
    [subtitleCss]="'subtitle-2'"
    [title]="'PAGA CON EL MÓVIL'"
    [subtitle]="
      'Con la Cuenta Inteligente tienes Apple Pay, Google Pay, EVO Wallet, EVO Bizum, Hal Cash y otras soluciones para tu Smartphone.'
    "
    class="image-div"
  >
  </box-image>
  <div class="form-and-step-div">
    <steps-ci class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div">
      <div class="title-container">
        <p class="title letter title-form">Datos de contacto</p>
        <div class="save-button-container" (click)="saveData()">
          <p class="title letter save-button-text">Guardar</p>
          <div class="save-button-icon">
            <div
              *ngIf="tooltipEnabled"
              class="tooltip-button"
              tooltip
              [template]="tooltipTemplate"
              [showOnInit]="true"
              [hideOnScroll]="true"
              [showOnMobile]="false"
              [isMobile]="isMobile() === 'Móvil'"
            ></div>
          </div>
        </div>
      </div>
      <p class="letter subtitle">
        Ahora que vas a formar parte de EVO, necesitamos conocerte un poco más.
      </p>
      <form
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="contactDetailsForm"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="floating-placeholder-group row-ci">
          <div class="col-ci-left width-mobile-dropdown">
            <dropdown
              class="dropdown drop-mobile"
              id="pais"
              name="pais"
              formControlName="pais"
              [isCi]="true"
              [options]="countries"
            ></dropdown>
            <label
              class="floating-placeholder-check"
              *ngIf="helpers.returnValue(this.contactDetailsForm.get('pais'))"
              >País de nacimiento</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('localidadnacimiento'),
                  'paisNacimiento'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("localidadnacimiento"),
                  "paisNacimiento"
                )
              }}
            </div>
          </div>
          <div class="col-ci-right localidad-ci-mob">
            <div *ngIf="isSpain" class="width-mobile-dropdown">
              <dropdown
                class="dropdown drop-mobile"
                id="provinciaNacimiento"
                name="provinciaNacimiento"
                formControlName="provinciaNacimiento"
                [isCi]="true"
                [options]="provinces"
              ></dropdown>
              <label
                class="floating-placeholder-check"
                *ngIf="
                  helpers.returnValue(
                    this.contactDetailsForm.get('provinciaNacimiento')
                  )
                "
                >Localidad de nacimiento</label
              >
              <div
                class="error-text error-drop-ci"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('provinciaNacimiento'),
                    'provinciaNacimiento'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("provinciaNacimiento"),
                    "provinciaNacimiento"
                  )
                }}
              </div>
            </div>
            <div *ngIf="!isSpain">
              <input
                type="tel-ci"
                required
                no-whitespace
                class="tel-ci"
                formControlName="localidadnacimiento"
                [maxlength]="!isCountrySelected ? 0 : 45"
                autocomplete="off"
              />
              <label class="floating-placeholder"
                >Localidad de nacimiento</label
              >
              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('localidadnacimiento'),
                    'localidadnacimiento'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("localidadnacimiento"),
                    "localidadnacimiento"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="floating-placeholder-group row-ci">
          <div class="col-ci-left">
            <div class="checkbox-group">
              <p class="lable-check-ci">¿Tu país de residencia es España?</p>
              <div class="align-check-mobile">
                <div class="check-ci-date-contactact">
                  <div
                    class="form-checkbox-cd"
                    (click)="changeValueResidenctCountry(true)"
                  >
                    <input
                      type="checkbox"
                      id="si"
                      value="true"
                      formControlName="paisResidenciaE"
                    />
                    <label class="letter subtitle"> Si </label>
                  </div>
                </div>
                <div class="check-ci-date-contactact">
                  <div
                    class="form-checkbox-cd"
                    (click)="changeValueResidenctCountry(false)"
                  >
                    <input
                      type="checkbox"
                      id="no"
                      value="false"
                      formControlName="paisResidenciaNe"
                    />
                    <label class="letter subtitle"> No </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-ci-right">
            <div class="checkbox-group">
              <p class="lable-check-ci">
                ¿Tienes obligaciones fiscales fuera de España?
              </p>
              <div class="align-check-mobile">
                <div class="check-ci-date-contactact">
                  <div
                    class="form-checkbox-cd"
                    (click)="changeValueFiscalObligation(true)"
                  >
                    <input
                      type="checkbox"
                      id="si"
                      formControlName="obligacionesFiscalesTrue"
                    />
                    <label class="letter subtitle"> Si </label>
                  </div>
                </div>
                <div class="check-ci-date-contactact">
                  <div
                    class="form-checkbox-cd"
                    (click)="changeValueFiscalObligation(false)"
                  >
                    <input
                      type="checkbox"
                      id="no"
                      formControlName="obligacionesFiscalesFalse"
                    />
                    <label class="letter subtitle"> No </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="floating-placeholder-group row-ci">
          <div class="col-ci-left width-mobile-dropdown">
            <dropdown
              class="drop-mobile"
              #estadoCivil
              id="estadoCivil"
              name="estadoCivil"
              formControlName="estadoCivil"
              [isCi]="true"
              [options]="civilStatus"
            >
            </dropdown>
            <label
              class="floating-placeholder-check"
              *ngIf="
                helpers.returnValue(this.contactDetailsForm.get('estadoCivil'))
              "
              >Estado Civil</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('estadoCivil'),
                  'estadoCivil_ci'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("estadoCivil"),
                  "estadoCivil_ci"
                )
              }}
            </div>
          </div>
          <div class="col-ci-right width-mobile-dropdown" *ngIf="isMarried">
            <dropdown
              class="dropdown drop-mobile"
              id="regimenEconomico"
              name="regimenEconomico"
              formControlName="regimenEconomico"
              [isCi]="true"
              [options]="economicSystems"
            >
            </dropdown>
            <label
              class="floating-placeholder-check"
              *ngIf="
                helpers.returnValue(
                  this.contactDetailsForm.get('regimenEconomico')
                )
              "
              >Régimen Económico</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('regimenEconomico'),
                  'regimenEconomico'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("regimenEconomico"),
                  "regimenEconomico"
                )
              }}
            </div>
          </div>
        </div>
        <!-- Address container -->
        <div class="container-address">
          <div class="row">
            <p class="info-direction">
              Asegúrate de indicar la dirección completa de tu domicilio.
            </p>
          </div>

          <div
            class="floating-placeholder-group row-ci row-direction direction-mobile desktopAndTabletScreen"
          >
            <div class="direction-aditional-input directionInput">
              <input
                type="tel-ci"
                required
                id="direccion"
                name="direccion"
                class="tel-ci"
                #direction
                maxlength="45"
                formControlName="direccion"
                placeholder=""
                autocomplete="off"
                [ngClass]="address.value ? 'input-noEmpty' : 'input-empty'"
              />
              <label class="floating-placeholder">Dirección</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('direccion'),
                    'direccion'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("direccion"),
                    "direccion"
                  )
                }}
              </div>
            </div>
            <div
              class="floating-placeholder-group direction-aditional-input dataInput"
            >
              <input
                type="tel-ci"
                required
                class="tel-ci"
                pattern="[a-zA-Z0-9]*"
                size="4"
                maxlength="4"
                formControlName="numero"
                autocomplete="off"
                inputmode="numeric"
                [ngClass]="
                  addressNumber.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Nº</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('numero'),
                    'numero'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("numero"),
                    "numero"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput">
              <input
                type="tel-ci"
                class="tel-ci"
                size="3"
                maxlength="3"
                formControlName="planta"
                autocomplete="off"
                [ngClass]="addressFloor.value ? 'input-noEmpty' : 'input-empty'"
              />
              <label class="floating-placeholder">Piso</label>
              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('planta'),
                    'planta'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("planta"),
                    "planta"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="2"
                formControlName="puerta"
                autocomplete="off"
                [ngClass]="addressDoor.value ? 'input-noEmpty' : 'input-empty'"
              />
              <label class="floating-placeholder">Puerta</label>
              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('puerta'),
                    'puerta'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("puerta"),
                    "puerta"
                  )
                }}
              </div>
            </div>
            <!-- <div class="row-ci">
          <a class="link-otp" (click)="addAditionalData()">
            <span [hidden]="!aditionalData" class="data-aditional"
              >Añadir datos adicionales</span
            >
            <span [hidden]="aditionalData" class="data-aditional"
              >Ocultar datos adicionales</span
            >
            <span
              [ngClass]="
                aditionalData ? 'link-otp1 link-true' : 'link-otp2 link-false'
              "
            ></span>
          </a>
        </div> -->
            <div class="direction-aditional-input dataInput1">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="2"
                formControlName="portal"
                autocomplete="off"
                [ngClass]="
                  addressPortal.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Portal</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('portal'),
                    'portal'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("portal"),
                    "portal"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput1">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="2"
                formControlName="bloque"
                autocomplete="off"
                [ngClass]="
                  addressBuilding.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Bloque</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('bloque'),
                    'bloque'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("bloque"),
                    "bloque"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput1">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="2"
                formControlName="escalera"
                autocomplete="off"
                [ngClass]="
                  addressStairs.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Escalera</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('escalera'),
                    'escalera'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("escalera"),
                    "escalera"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput1">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="3"
                formControlName="fase"
                autocomplete="off"
                [ngClass]="addressPhase.value ? 'input-noEmpty' : 'input-empty'"
              />
              <label class="floating-placeholder">Fase</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('fase'),
                    'fase'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("fase"),
                    "fase"
                  )
                }}
              </div>
            </div>
            <div class="direction-aditional-input dataInput1">
              <input
                type="tel-ci"
                class="tel-ci"
                maxlength="45"
                formControlName="parcela"
                autocomplete="off"
                [ngClass]="
                  addressParcel.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Parcela</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('parcela'),
                    'parcela'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("parcela"),
                    "parcela"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="container-address-part2">
          <div class="floating-placeholder-group row-ci">
            <div class="col-ci-left">
              <input
                type="tel-ci"
                class="tel-ci"
                required
                size="5"
                pattern="\d*"
                number-input
                inputmode="numeric"
                maxlength="5"
                formControlName="codigoPostal"
                autocomplete="off"
                [ngClass]="
                  addressPostalCode.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Código postal</label>

              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('codigoPostal'),
                    'codigoPostal'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("codigoPostal"),
                    "codigoPostal"
                  )
                }}
              </div>
            </div>
            <div class="col-ci-right width-mobile-dropdown">
              <dropdown
                class="dropdown"
                id="Localidad"
                name="Localidad"
                formControlName="localidad"
                [options]="selectLocalidades"
                [isCi]="true"
                [isUp]="true"
              ></dropdown>
              <label
                class="floating-placeholder-check"
                *ngIf="
                  helpers.returnValue(this.contactDetailsForm.get('localidad'))
                "
                >Localidad</label
              >
              <div
                class="error-text error-drop-ci"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('localidad'),
                    'localidad_ci'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("localidad"),
                    "localidad_ci"
                  )
                }}
              </div>
            </div>
          </div>
          <div class="floating-placeholder-group row-ci mg-top">
            <!-- disabled             -->
            <div class="col-ci-left">
              <input
                type="tel-ci"
                class="tel-ci"
                required
                formControlName="provincia"
                autocomplete="off"
                [ngClass]="
                  addressProvince.value ? 'input-noEmpty' : 'input-empty'
                "
              />
              <label class="floating-placeholder">Provincia</label>

              <div
                class="error-text mg-bottom-7"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('provincia'),
                    'provincia'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("provincia"),
                    "provincia"
                  )
                }}
              </div>
            </div>
            <div class="col-ci-right width-mobile-dropdown">
              <dropdown
                class="dropdown"
                id="situacionVivienda"
                name="situacion"
                formControlName="situacionVivienda"
                [options]="situaciones"
                [isCi]="true"
                [isUp]="true"
              ></dropdown>
              <label
                class="floating-placeholder-check"
                *ngIf="
                  helpers.returnValue(
                    this.contactDetailsForm.get('situacionVivienda')
                  )
                "
                >Situación de la vivienda</label
              >
              <div
                class="error-text error-drop-ci"
                *ngIf="
                  errService.desktopErrors(
                    contactDetailsForm.get('situacionVivienda'),
                    'situacionVivienda'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    contactDetailsForm.get("situacionVivienda"),
                    "situacionVivienda"
                  )
                }}
              </div>
            </div>
          </div>
          <div
            class="floating-placeholder-group row-ci one-line mg-top"
            *ngIf="anioAdquisicion"
          >
            <!--<div
          class="floating-placeholder-group floating-placeholder-group row-ci margin-ci-mb margin-cd-dtp anio-adqui"
          *ngIf="anioAdquisicion">-->
            <input
              type="tel-ci"
              class="tel-ci fill-available"
              maxlength="4"
              pattern="\d*"
              formControlName="anioAdquisicion"
              autocomplete="off"
              [ngClass]="
                yearAcquisition.value ? 'input-noEmpty' : 'input-empty'
              "
            />
            <label class="floating-placeholder" *ngIf="isMobile() === 'Desktop'"
              >Año de Adquisición de la vivienda
            </label>
            <label class="floating-placeholder" *ngIf="isMobile() === 'Tablet'"
              >Año de Adquisición de la vivienda
            </label>
            <label class="floating-placeholder" *ngIf="isMobile() === 'Móvil'"
              >Año de Adquisición de la vivienda</label
            >
            <!--<p
            class="evo info tooltipStyle"
            *ngIf="isMobile() !== 'Móvil'"
            user-tooltip
            [tooltip]="tooltip"
          ></p>-->
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('anioAdquisicion'),
                  'anioAdquisicion'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("anioAdquisicion"),
                  "anioAdquisicion"
                )
              }}
            </div>
          </div>
        </div>

        <hr class="line-separator hidden-movil" />
        <div class="buttonCI">
          <div class="return-text">
            <p (click)="returnInit()">
              <span class="link-otp-black"></span>
              Atrás
            </p>
          </div>
          <button type="submit" class="btn-open-account open-account">
            <p>CONTINUAR</p>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #tooltipTemplate>
  <div class="tooltip" tabindex="0">
    <i class="icon-info"></i>
    <div class="text-container">
      <p class="tooltip-title">¡No pierdas ningún dato!</p>
      <p>
        Si lo prefieres, puedes guardar los datos y avanzar en el proceso cuando
        quieras.
      </p>
    </div>
    <hr />
    <div class="button-container">
      <span (click)="closeTooltip($event)">ENTENDIDO</span>
    </div>
  </div>
</ng-template>
