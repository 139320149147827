import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot,
  RouterStateSnapshot, CanActivateChild, NavigationExtras,
  CanLoad, Route
} from '@angular/router';

import { LoginService } from './login.service';

@Injectable()
export class AuthGuardUser implements CanActivate, CanActivateChild, CanLoad {

  logoutPages = ['/login', '/login-ampliacion', '/login-inversora'];

  redirectPages = ['/segundo-titular', '/login-invitacion'];

  constructor(private loginSrv: LoginService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

    let url: string = state.url;
    let result =  this.checkLogin(url, state);
    return result;
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;
    return true;
  }

  checkLogin(url: string, state: RouterStateSnapshot): boolean {

    const defaultPath = '/404';

    if (this.isRedirectPage(url) || this.isLogoutPage(url) || this.isErrorPage(url) || url === '/') {
      return true;
    }

    const resultEstate = this.checkState(url, state);

    if (resultEstate) {
      return true;
    }
    this.router.navigate([defaultPath], {queryParamsHandling: 'preserve'});
    return false;
  }

  checkState(url: string, state: RouterStateSnapshot){
    if (this.isLogoutPage(url)){
      this.loginSrv.logOut();
    }
    return url.indexOf('token=') !== -1 || this.loginSrv.isLoggedIn
  }

  isLogoutPage(url) {
    const currentPath = url.toLocaleLowerCase().split('?')[0];
    return this.logoutPages.find(path => path === currentPath);
  }

  isErrorPage(url){
    return url.toLocaleLowerCase().split('?')[0] === '/404';
  }

  isRedirectPage(url){
    const currentPath = url.toLocaleLowerCase().split('?')[0];
    return this.redirectPages.find(path => path === currentPath);
  }
}
