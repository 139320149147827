import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthUserService } from '../../../services/auth-user.service';
import { SpecificAnalyticsService } from '../../../services/specific-analytics.service';
import { LoginService } from '../../../services/login.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { HelperService } from '../../../services/helpers.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { RecomiendaService } from '../../../services/recomienda.service';
import { User } from '../../../collections/user';

import { NOTIFICATIONS } from '../../../resources/notifications.model'
import { ListPolicies, ListPoliciesResponse } from 'app/collections/list-policies';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { MyInterestService } from 'app/services/my-interest.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { MyProductsService } from '../../../services/my-products.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { Documents } from '../../../collections/documento';
import { PersonaService } from '../../../services/persona.service';
import { Persona } from 'app/collections/persona';
import { SecurityService } from '../../../services/security-registration.service';

@Component({
  selector: 'app-main-profile',
  templateUrl: './logged-profile.component.html',
  styleUrls: ['./logged-profile.component.scss'],
  providers: [ModalService, RecomiendaService]
})
export class LoggedProfileComponent implements OnInit {
  @ViewChild('iframereco') public iframereco: ElementRef;
  public ibanIdentified = false;
  public slides: Array<any> = [];
  public slidesEmulated: Array<any> = [
    { image: './assets/images/sliders/slider_1.jpg', title: '', text: '', goTo: '' }
  ];

  public photoInterval = 5000;
  public noLoopSlides = false;
  public loading = false;
  private secondHolderForm: FormGroup;
  private userData: User;
  public isSendSecondUser: boolean = false;
  public canHaveSecondHolder: boolean = false;
  public addSecondHolder: string = "añadir segundo titular";
  private user;
  private userName;
  private secondUser;
  private veilSecondUser = false;
  private producto = null;
  private accToken: string;
  recomiendaURL: string;
  recomiendaModal: string = 'recomienda';
  public contract = [];
  public waitContract = [];
  public clientID;
  public notifications = NOTIFICATIONS;
  public modalLegal = 'legalModal';
  public incompleteModal= 'incompleteModal';
  public errorSendModal = 'errorSendModal';
  public errorSecondHolder = 'errorSecondHolder';
  public modalText = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  public invalidError = {field: 'email', text: 'Introduce un email válido', code: 100};
  public fieldError = {field: 'reactiveEquals', text: 'Los campos tienen que coincidir', code: 100};
  public personWarnings: any;
  public showWarningUpdateDocument: boolean = false;
  public showWarningValidating: boolean = false;
  public showWarningNoDocument: boolean = false;
  public hiddeBanner: boolean = false;
  public hiddeNotification: boolean = false;
  public tipoSolicitud: any;
  public tieneSolicitudes: boolean = false;
  public solicitudesEnCurso = [];
  public porcentajeBarra = 0;
  public porcentanjeView: any ;
  public reload: boolean = false;
  public barraStyle: object;

  public camposError = [];

  constructor(
    private authService: AuthUserService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private helpers: HelperService,
    public errService: ErrorService,
    public modService: ModalService,
    private loginSrv: LoginService,
    private recomienda: RecomiendaService,
    private secondHolderSrv: SecondHolderService,
    private microConfigSrv: MicrosegConfigService,
    private analytics: AnalyticsService,
    public specificAnalytics: SpecificAnalyticsService,
    public authguardmicro: AuthGuardMicro,
    public myInteresetSrv: MyInterestService,
    public myProductsService: MyProductsService,
    private personaSrv: PersonaService,
    private activatedRoute: ActivatedRoute,
    private securitySrv: SecurityService
  ) {
    this.addNewSlides();
    this.createForm();
  }

  ngOnInit() {
    sessionStorage.removeItem("casoSeleccionado");
    sessionStorage.removeItem("codProdAmpliacion");
    sessionStorage.removeItem("operacion-ampliacion");
    sessionStorage.removeItem("limite-solicitado");
    sessionStorage.removeItem("tarjetaSeleccionada");
    sessionStorage.removeItem("submit");
    sessionStorage.removeItem("operacion-inversora");
    sessionStorage.removeItem("productoContratando");
    sessionStorage.removeItem("isSecondSigner");
    sessionStorage.removeItem("ibanCuentaSeleccionada");
    
    this.loadUserData();
    this.authguardmicro.empty();
    this.errService.rsi = true;
  }

  private createForm() {
    const emailRegex = /.+@.+\..+/i;
    this.secondHolderForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      confirmEmail: ['', [Validators.required, Validators.pattern(emailRegex)]],
      checkLegal: ['', [Validators.required]]
    })

    this.formSubscribe(this.secondHolderForm);
  }


  loadUserData() {
    if (this.loginSrv.isLoggedIn) {
      this.loading = true;
      if (!this.helpers.isEmpty(this.loginSrv.userData) && !sessionStorage.getItem("reloadUserData")) {
        //this.loading = false;
        this.userData = this.filterNotAvailableProducts(this.loginSrv.userData);
        this.clientID = this.userData.persona.id_cliente_EVO;
        this.user = `${this.userData.persona.nombreCliente} ${this.userData.persona.apellido1Cliente} ${this.userData.persona.apellido2Cliente === null ? '' : this.userData.persona.apellido2Cliente}`;
        this.userName= this.userData.persona.nombreCliente;
        this.listProducts();
        this.getInfoSolicitud(); 
        //Rellenamos la barra de progreso
        this.reload=true;
        this.rellenarBarraProgreso(this.userData.persona);
        return
      }
      sessionStorage.removeItem("reloadUserData");
      this.authService.getUserData().subscribe(data => {
        this.loading = false;
        this.userData = this.filterNotAvailableProducts(data);
        this.clientID = this.userData.persona.id_cliente_EVO;
        this.userName= this.userData.persona.nombreCliente;
        this.getInfoSolicitud();        
        this.loginSrv.userData = this.userData;
        //Rellenamos la barra de progreso
        this.rellenarBarraProgreso(this.userData.persona);
        this.specificAnalytics.emitUserId(this.userData.persona.id_cliente_EVO);
        this.user = `${this.userData.persona.nombreCliente} ${this.userData.persona.apellido1Cliente} ${this.userData.persona.apellido2Cliente === null ? '' : this.userData.persona.apellido2Cliente}`;
        this.auth.loadPolicies().subscribe(response => {
          if (response != null ) {
            if (response.error != null) {
              this.modalText = response.error;
              this.modService.open(this.incompleteModal);
              return ;
            }
            if (response.list != null && response.list.length > 0) {
              const policy = response.list[0];
              this.loginSrv.listPolicies = policy;
            }
          }
          this.listProducts();
        });

        let codigo = this.userData.listaContratados[0];
    
        this.personaSrv.getPersonaById(this.userData.persona.id_cliente_EVO, codigo.idProducto).subscribe(data => {
          this.loading = false;
          this.loginSrv.userInfo = data;
          this.loginSrv.comprobacionDatos();
          console.log(this.loginSrv.camposError);
  
        }, error => {
          this.errService.navToError();
        });
      }, error => {
        this.errService.rsi = true;
        this.errService.navToError();
      });
    } else {
      this.helpers.navigateTo('/login');
    }
  }

  filterNotAvailableProducts(userData) {
    const contratados = userData.listaContratados;
    const listaParaContratar = userData.listaParaContratar;

    if(userData) {
      this.showSecondHolder(contratados, listaParaContratar);
    }
    //Filtramos segundo titular

    /*
    ********** DESCOMENTAR ESTO PARA PRODUCCION ************
    const indexSecondHolder = listaParaContratar.findIndex(product => {
      return product.idProducto === ACCOUNTS.STCode;
    });
    if (indexSecondHolder !== -1) {
      listaParaContratar.splice(indexSecondHolder, 1);
      userData.listaParaContratar = listaParaContratar;
    }
    */

    //Filtramos cuenta dúo
    
    const indexDuoAccount = listaParaContratar.findIndex(product => {
      return product.idProducto === ACCOUNTS.CDCode;
    });
    if (indexDuoAccount !== -1) {
      listaParaContratar.splice(indexDuoAccount, 1);
      userData.listaParaContratar = listaParaContratar;
    }
    

    return userData;
  }

  showSecondHolder(contratados, listaContratar) {
    for (const prod of contratados) {
      if (prod.idProducto === ACCOUNTS.CICode) {
        for (const valueProd of listaContratar) {
          if (valueProd.idProducto === '999') {
            this.canHaveSecondHolder = true;
          }
        }
      }
    }
  }

  hideBanner() {
    this.hiddeBanner = true;
  }

  hideNotification(){
    this.hiddeNotification = true;
  }

    
  getWarnings() {
    this.personaSrv.getWarnings(this.clientID).subscribe(response => {
      
      this.hiddeBanner = false;
      this.tipoSolicitud = response.message;
      sessionStorage.setItem("tipoSolicitud", this.tipoSolicitud);
      if (this.tipoSolicitud === '00') {
        this.showWarningUpdateDocument = false;
        this.showWarningValidating = false;
        this.showWarningNoDocument = false;
        this.hiddeBanner = true;
        //console.log("Todo correcto");
      }
      else if (this.tipoSolicitud === '01') {
        this.showWarningUpdateDocument = true;
        this.showWarningValidating = false;
        this.showWarningNoDocument = false;
        //console.log("DNI está caducado");
      }
      else if (this.tipoSolicitud === '02') {
        this.showWarningUpdateDocument = false;
        this.showWarningValidating = false;
        this.showWarningNoDocument = true;
        //console.log("no tiene documentación en RSI");
      }
      else if (this.tipoSolicitud === '03') {
        this.showWarningUpdateDocument = false;
        this.showWarningValidating = false;
        this.showWarningNoDocument = true;
        //console.log("tiene el DNI caducado y no tiene documentación en RSI");
      }
      else if (this.tipoSolicitud !== '00' && this.tipoSolicitud !== '01' && this.tipoSolicitud !== '02' && this.tipoSolicitud !== '03'){
        this.showWarningUpdateDocument = false;
        this.showWarningValidating = false;
        this.showWarningNoDocument = false;
        //console.log("error al obtener los datos");
      }
      this.loading = false;
    }, error => {
      this.showWarningUpdateDocument = false;
      this.showWarningValidating = false;
      this.showWarningNoDocument = false;
      this.errService.navToError();
    });
  }

  getInfoSolicitud() {
    this.personaSrv.getInfoSolicitud(this.clientID).subscribe(response => {
      this.hiddeBanner = false;
      this.tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.solicitudesEnCurso = [];
      if(!this.tieneSolicitudes){
        this.showWarningValidating = false;
        this.hiddeBanner = true;
        this.getWarnings();
      }
      else{
        this.solicitudesEnCurso = response.solicitudes;

        for (const solicitud of this.solicitudesEnCurso) {
          if (solicitud.tipoSolicitud === "01" || solicitud.tipoSolicitud === "02") {
            if (solicitud.estado == "Nueva" || solicitud.estado == "Enviada"){
              this.showWarningUpdateDocument = false;
              this.showWarningValidating = false;
              sessionStorage.setItem("validacionPendiente", "false");
              this.showWarningNoDocument = false;
              break;
            }
            else {
              this.showWarningUpdateDocument = false;
              this.showWarningValidating = true;
              sessionStorage.setItem("validacionPendiente", "true");
              this.showWarningNoDocument = false;
              break;
            }
          }
          else{
            this.showWarningUpdateDocument = false;
            this.showWarningValidating = false;
            this.showWarningNoDocument = false;
            if (solicitud.tipoSolicitud === "03"){
              break; //TODO de momento no aplica y no dejarás hacer una nueva solicitud de modificación de datos.
            }
          }
        }
      }
    }, error => {
      this.showWarningUpdateDocument = false;
      this.showWarningValidating = false;
      this.showWarningNoDocument = false;
      //this.errService.navToError();
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'login');
    });
  }

  private listProducts() {
    const actualUserData = JSON.parse(sessionStorage.getItem("userData")),
          listaParaContratar = actualUserData.listaParaContratar;

    if (!this.helpers.isEmpty(actualUserData.listaContratados)) {
      this.loginSrv.userData = actualUserData;
      // devulve la lista de productos contratados
      // this.checkProducts(this.userData.listaContratados, 'contratados');
      this.producto = this.getCurrentProduct();
    }
    if (!this.helpers.isEmpty(listaParaContratar)) {
      // devuelve la lista de los que puede contratar
       if (this.loginSrv.listPolicies == null && this.helpers.isAdultNot65Years(actualUserData.persona.fechaNacimiento)) {
        this.slides.unshift({ image: './assets/images/profile/profile-extras/EVO_protect.png', title: 'Evo protect deportes', text: '', goTo: 'evoProtect' });
      }
      const slidesToContract = this.myInteresetSrv.prepareSlides(listaParaContratar, this.loginSrv.listPolicies);
      this.slides.push(...slidesToContract);

      //this.slidesDelete = Object.assign({}, this.slides);
      // borrar elemento del slide que sea de segundo titular o cuenta duo
     for(let i = this.slides.length-1; i >= 0; i--){
      if (this.slides[i].idProducto === ACCOUNTS.STCode) {
        this.slides.splice(i,1);
      }
      else if  (this.slides[i].idProducto === ACCOUNTS.CDCode) {
        this.slides.splice(i,1);
      }
    }
    
    }
  }

  getCurrentProduct() {
    let producto = null;
    if (typeof this.userData !== 'undefined') {
     const listaContratados =  this.userData.listaContratados;
     const listaParaContratar = this.userData.listaParaContratar;
      for (const product of listaContratados) {
        if (product.idProducto === ACCOUNTS.CICode) {
          if (product.segundoTitular) {
            this.secondUser = `${product.segundoTitular["persona"].nombreCliente} ${product.segundoTitular["persona"].apellido1Cliente} ${product.segundoTitular["persona"].apellido2Cliente === null ? '' : product.segundoTitular["persona"].apellido2Cliente}`;
            this.veilSecondUser = true;
          }else {
            this.veilSecondUser = false;
          }
          //this.canHaveSecondHolder = true;
          
          for (const valProd of listaParaContratar) {
            if (valProd.idProducto === '999') {
              this.canHaveSecondHolder = true;
            }
          }
          producto = product;
          break;
        }
        if (product.idProducto === ACCOUNTS.CJCode) {
          producto = product;
          break;
        }
        if (product.idProducto === ACCOUNTS.CDCode) {
          //this.canHaveSecondHolder = true;
          if (product.segundoTitular) {
            this.secondUser = `${product.segundoTitular["persona"].nombreCliente} ${product.segundoTitular["persona"].apellido1Cliente} ${product.segundoTitular["persona"].apellido2Cliente === null ? '' : product.segundoTitular["persona"].apellido2Cliente}`;
            this.veilSecondUser = true;
          }else {
            this.veilSecondUser = false;
          }
          producto = product;
        }
      }
    }
    return producto;
  }

  private formSubscribe(form) {
    const emailRegex = /.+@.+\..+/i;
    const userMail = form.get('email'),
    confirmEmail = form.get('confirmEmail');

    userMail.valueChanges.subscribe(mail => {
      if (userMail.value === "") {
        this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
      }
      /*
      else if (userMail.value !== "") {
        if (!this.secondHolderForm.get('email').valid){
          this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
        }
        else if (this.secondHolderForm.get('email').valid){
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
          this.errService.errorsArray = [];
        }
      }*/
      if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value !== confirmEmail.value) {
        if(userMail.valid) {
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
        //this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
        }
        else if (!userMail.valid){
          this.secondHolderForm.get('email').setErrors({notEquals: true});
        }
        this.helpers.updateFormStatus(this.secondHolderForm);
        this.errService.errorsArray.push(this.fieldError);
      }
      else if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value === confirmEmail.value) {
        this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        this.helpers.updateFieldValidation(this.secondHolderForm, 'confirmEmail', [Validators.required, Validators.pattern(emailRegex)]);
        this.errService.errorsArray = [];
      }
    })
    
    confirmEmail.valueChanges.subscribe(confirm => {
      const isNotEqual = !this.helpers.hasEqualsChars(userMail.value, confirmEmail.value)
      && !confirmEmail.pristine && userMail.valid;
      if (isNotEqual) {
        confirmEmail.setErrors({'notEquals': true});
        this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
        this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
      
        this.errService.errorsArray.push(this.fieldError);
        if (confirmEmail.value === "") {
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
          this.errService.errorsArray = [];
          
        }
      }
      else {
        /*
        if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value !== confirmEmail.value) {
          this.secondHolderForm.get('email').setErrors({notEquals: true});
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
          this.helpers.updateFormStatus(this.secondHolderForm);
        }
        else if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value === confirmEmail.value) {
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        }
        */

        this.errService.errorsArray = [];
        //this.errService.reactiveErrors(this.secondHolderForm);
        //this.helpers.updateFormStatus(this.secondHolderForm);
        this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
        this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        if (userMail.value === "") {
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
        }
      }
    })

    /*
    confirmEmail.valueChanges.subscribe(emailForm => {

      if (!this.helpers.hasEqualsChars(userMail, confirmEmail) &&
        !confirmEmail.pristine) {
        confirmEmail.setErrors({notEquals: true});
      }
      this.errService.reactiveErrors(this.secondHolderForm);
    });

    */

    this.secondHolderForm.get('checkLegal').valueChanges.subscribe(legal => {
      const conditionsCheck = this.secondHolderForm.get('checkLegal');
    if(conditionsCheck.value === false) {
      this.modService.close(this.modalLegal);
    }
    else if (conditionsCheck.value === true) {
      this.modService.open(this.modalLegal);
    }
    })
  }

  private openLegal() {
    const conditionsCheck = this.secondHolderForm.get('checkLegal');
    //if(conditionsCheck.value === "" || conditionsCheck.value === false) {
    if(conditionsCheck.value === false) {
      this.modService.close(this.modalLegal);
    }
    else if (conditionsCheck.value === true) {
      this.modService.open(this.modalLegal);
    }
  }

  outFocus (input) {
    const userMail = this.secondHolderForm.get('email'),
    confirmEmail = this.secondHolderForm.get('confirmEmail');

    if (input.name === "email") {
      if (userMail.value === "") {
        this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
      }
      else {
        if (!this.secondHolderForm.get('email').valid){
          this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
        }
        else if (this.secondHolderForm.get('email').valid){
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
          this.errService.errorsArray = [];
        }
        else if (confirmEmail.value !== "" && userMail.value !== confirmEmail.value){
          this.secondHolderForm.get('email').setErrors({notEquals: true});
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
          this.helpers.updateFormStatus(this.secondHolderForm);
          this.errService.errorsArray.push(this.fieldError);
        }
      }
    }
    else if (input.name === "confirmEmail") {
      if (confirmEmail.value === ""){
        if (this.secondHolderForm.get('email').valid){
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        }
        this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        if (userMail.value === "")
        {
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
        }
      }
      else {
        if (userMail.value !== confirmEmail.value && userMail.value === "") {
          this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
          this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
        }
        else if (userMail.value !== confirmEmail.value && userMail.value !== "") {
          //this.secondHolderForm.get('email').setErrors({notEquals: true});
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
          this.helpers.updateFormStatus(this.secondHolderForm);
          this.errService.errorsArray.push(this.fieldError);
          
        }
        else if (userMail.value !== confirmEmail.value) {
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        }
      }
    }
  }

  goToRecomienda() {
    this.recomiendaURL = this.recomienda.getPersonaId(this.loginSrv.loginData.userinfo.mail);
    this.modService.open(this.recomiendaModal);
    this.iframereco.nativeElement.src = this.recomiendaURL;
  }

  private onSubmit() {
    this.loading = true;
    const checkbox = this.secondHolderForm.get('checkLegal'),
          form = this.secondHolderForm;

    if (!form.get('email').valid || !form.get('confirmEmail').valid) {
      console.log(form.get('email').valid);
      console.log(form.get('confirmEmail').valid);
      
      this.loading = false; 
      this.modalText = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.'
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: Email inválido');
      this.helpers.updateFormStatus(this.secondHolderForm);
      this.errService.errorsArray.push(this.fieldError);
      return;
    }
    /*
    confirmEmail.valueChanges.subscribe(confirm => {
      const isNotEqual = !this.helpers.hasEqualsChars(userMail.value, confirmEmail.value)
      && !confirmEmail.pristine && userMail.valid;
      if (isNotEqual) {
        confirmEmail.setErrors({notEquals: true});
      }
    })*/

    if (form.get('email').value !== form.get('confirmEmail').value) {
      
      this.loading = false;
      this.modalText = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.'
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: Emails no coinciden');
      form.get('confirmEmail').setErrors({notEquals: true});
      this.helpers.updateFormStatus(this.secondHolderForm);
      this.errService.errorsArray.push(this.fieldError);
      return;
    }


    if (!checkbox.value) {
      this.loading = false;
      this.modalText = 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.'
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: No se han aceptado los términos y condiciones legales');
      return;
    }

    this.secondHolderSrv.createSecondHolder(form.get('email').value, ACCOUNTS.CICode, this.userData.persona.id_cliente_EVO).subscribe(
      data => {
        this.loading = false;
        sessionStorage.setItem("reloadUserData", "true");
        this.isSendSecondUser = true;
        this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio OK');
      },
      error => {
        this.loading = false;
        this.isSendSecondUser = false;
        /*
        if(error.error.resultMessage == "Ya existe un cliente con el email introducido y con el producto a contratar"){
          this.modalText = "El usuario "+form.get('email').value+" ya tiene otra petición para ser segundo titular.";
          this.resetForm();
          this.modService.close(this.modalLegal);
          this.modService.open(this.incompleteModal);
        }*/
        let mesageModal = 'Ya existe un cliente con el email introducido y con el producto a contratar';
        if(error.error.resultCode == 35) {
          this.modalText = "Ha ocurrido un error al enviar la peticion. Por favor ponte en contacto con nosotros en el 910 900 900.";
          this.resetForm();
          //this.modService.close(this.modalLegal);
          //this.modService.open(this.errorSendModal);
          this.modService.open(this.errorSendModal);

        }else if (error.error.resultCode == 0 && mesageModal === error.error.resultMessage){
          this.modService.open(this.errorSecondHolder);
        } else{
        this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: 500 Server error');
        }
      }
    );
  }
  closeErrorSend() {
    this.resetForm();
    this.modService.close(this.errorSecondHolder);
  }
  private goToDetail(notification) {
    this.analytics.triggerNotificationAction("Abrir notificación", notification.title);
    this.helpers.navigateTo(`/notificacion/${notification.title}`);
  }

  private addNewSlides() {
    this.slides = [
      //{ image: './assets/images/profile/profile-extras/laboratorio.jpg', title: 'Cuenta Duo', text: 'Porque vivir es compartir, pero ahora más fácil que nunca.', goTo: 'cuentaDuo' },
      { image: './assets/images/profile/profile-extras/inversion.jpg', title: 'Plan de inversión inteligente', text: '¡Invierte mientras ahorras!', goTo: 'inversion' },
      { image: './assets/images/profile/profile-extras/travel.jpg', title: 'Evo travel cash', text: 'Te llevamos tu moneda extranjera a casa.', goTo: 'travel' },
      { image: './assets/images/profile/profile-extras/laboratorio.jpg', title: 'Laboratorio de ideas', text: 'Lo más importante es tu opinión', goTo: 'laboratorio' }
    ];

  }

  addOtherSecondHolder() {
    this.addSecondHolder = "añadir otro segundo titular";
    this.isSendSecondUser = !this.isSendSecondUser;
    this.resetForm();
    this.modService.close(this.modalLegal);
  }

  resetForm () {
    this.secondHolderForm.reset({email: '', confirmEmail: '', checkConditions: false});
  }

  goToMyProfile() {
    this.helpers.navigateTo('mi-perfil');
  }

  subirDocumentacion() {
    sessionStorage.setItem('tipoSolicitud', this.tipoSolicitud);
    this.helpers.navigateTo('subir-documentacion');
  }

  rellenarBarraProgreso(persona){
    let porcentaje: number =0;
    //Sumamos porcertanjes
    if(persona.nombreCliente){
      porcentaje =porcentaje+2.6;
    }
    if(persona.apellido1Cliente){
      porcentaje =porcentaje+2.6;
    }   
    if(persona.apellido2Cliente){
     porcentaje =porcentaje+2.6;
    }
    if(persona.fechaNacimiento){
      porcentaje =porcentaje+2.6;
    }
    if(persona.id_cliente_EVO){
      porcentaje =porcentaje+1.9;
    }
    if(persona.idExterno){
      porcentaje =porcentaje+2.6;
    }
    if(persona.paisNacionalidad){
      porcentaje =porcentaje+2.6;
    }
    if(persona.nombreLocalidadNacimiento){
      porcentaje =porcentaje+1.9;
    }
    if(persona.nombreProvinciaNacimiento){
      porcentaje =porcentaje+2.6;
    }
    if(persona.codigoEstadoCivil){
      porcentaje =porcentaje+2.3;
    }
    if(persona.codigoRegimenEconomico){
      porcentaje =porcentaje+2,3;
    }
    if(persona.sexo){
      porcentaje =porcentaje+1,9;
    }
     //Direccion
     if(persona.direccion){
       if(persona.direccion.codigoPostal){
        porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.codigoPaisResidencia){
        porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.fechaAltaAlquiler){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.cuotaAlquilerMensual){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.codigoProvinciaDomicilio){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.nombreProvinciaDomicilio){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.nombreLocalidad){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.codigoDomicilioTipoVia){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.nombreVia){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.domicilioNumero){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.domicilioPortal){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.domicilioEscalera){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.domicilioPlanta){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.domicilioPuerta){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.bloque){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.parcela){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.fase){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.anioAdquisicion){
          porcentaje = porcentaje+1.6;
        }
       if(persona.direccion.codigoPaisResidencia){
          porcentaje = porcentaje+1.6;
        }
     }
     //DatosSocioEco
     if(persona.datosSocioEco){

      if(persona.datosSocioEco.regimenOcupacion){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.codigoCNO){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.codigoSituacionLaboral){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.codigoCNAE){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.fechaAltaAutonomo){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.fechaAntiguedadLaboral){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.codigoCNO){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.nombreEmpresa){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.nivelSalarial){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.duracion){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.numeroPagas){
        porcentaje = porcentaje+3.3;
      }
      if(persona.datosSocioEco.codigoContrato){
        porcentaje = porcentaje+3.3;
      }
     }
     this.porcentajeBarra=Math.round(porcentaje);
     let valorBarra = porcentaje.toString();
     
     this.barraStyle = Object.assign({
       width: valorBarra+"%",
    });
  
// Cambiamos width con valor de porcentaje.
//  dependiendo si entra por primera vez o es una recarga de pagina.
    // if(this.reload){
    //   window.addEventListener('load', function () {
    //   let bar = document.getElementsByClassName("progress-bar progress-bar-top")
    //   bar[0].setAttribute('style', "width:"+porcentaje+"%");
    //   })
    // }else{
    //   let bar = document.getElementsByClassName("progress-bar progress-bar-top")
    //   bar[0].setAttribute('style', "width:"+porcentaje+"%");
    // }
    
  
  }


}
