import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { LoginCuentaInversoraComponent } from '../login-inversora/login-inversora.component';
import { LoginCuentaInvitacionComponent } from '../login-invitacion/login-invitacion.component';
import { LoginService } from 'app/services/login.service';
import { Subscription } from 'rxjs/Subscription';


import 'rxjs/add/observable/fromEvent';
import { ACCOUNTS } from 'app/resources/account.model';
import { AuthService } from 'app/services/auth.service';



@Component({
  selector: 'profile-screen',
  templateUrl: './profile-screen.component.html',
  styleUrls: ['./profile-screen.component.scss']
})
export class ProfileScreenComponent implements OnInit, AfterContentChecked {

  public param = null;
  public showHeader = true;
  public showFooter = true;
  public showCookies = true;
  public subscription: Subscription;
  public customClass: string = "";
  public currentPath: string;



  @ViewChild(LoginCuentaInversoraComponent) loginInversora:LoginCuentaInversoraComponent;
  @ViewChild(LoginCuentaInvitacionComponent) loginInvitacion:LoginCuentaInvitacionComponent;


  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private loginService: LoginService) {

      router.events.subscribe((url:any) => {
        if(url instanceof NavigationEnd) {
          this.currentPath = url.url;
         if(this.currentPath.indexOf('/final-cuenta-inversora') !== -1){
          this.showHeader = false;
         }
  
        }
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen) {
        this.param = params.origen.toLowerCase();
        if (this.param === "bmi") {
          this.showHeader = false;
          this.showFooter = false;
        }
      }
    });

    // Si se accede mediante una redirección desde logalty, se comprueba si es bmi desde los datos de sesión.
    if (sessionStorage.getItem('isBMI') === 'true') {
      this.showHeader = false;
      this.showFooter = false;
    }

    if(sessionStorage.getItem('operacion-inversora')){
      this.showHeader = false;
    }

    if(sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente'){
      this.showHeader = false;
    }

    if(sessionStorage.getItem("isLogin")){
      this.showCookies = false;
    }
    this.subscription = this.loginService.itemsObservable$.subscribe((items: Boolean) => {
      if(items){
        this.showCookies=false;
      }
    });

  }

  ngAfterContentChecked(): void {
    if (this.authService.product === ACCOUNTS.STCode) {
      this.customClass = "profile-footer";
    }
  }



}
