import {Component, OnInit, AfterViewInit, HostListener} from '@angular/core';

import { FormValidatorService } from '../../../services/form-validator.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import {FormBuilder,FormGroup} from '@angular/forms';
import { AnalyticsService } from 'app/services/analytics.service';
import { AuthService } from 'app/services/auth.service';
import { ErrorService } from 'app/services/error.service';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'identify-iban-confirm',
  templateUrl: './identify-iban-confirm.component.html',
  styleUrls: ['./identify-iban-confirm.component.scss'],
  providers: [FormValidatorService, ModalService, DeviceService]
})
export class IdentifyIbanConfirmComponent implements OnInit , AfterViewInit {

  //Variables para text e imagen box
  classImgFile: string = 'segundoTitular05';
  icon: string = 'ic_segundo-titular';
  titleBox: string = 'SEGUNDO TITULAR';
  subtitle: string = 'Si ya tienes una cuenta, pero quieres incluir un nuevo titular, lo puedes hacer desde tu App.';

  identifyIbanConfirmForm: FormGroup;

  modalTransfer = 'mdtransfer';

  persona;
  mail;
  iban;
  clientId: string;
  isSecondSigner = false;

  textBelowInput = 'Para ello, te enviaremos una transferencia a esta cuenta. En el siguiente paso, deberás incluir el importe exacto que has recibido. ¡Es muy fácil!';
  textAboveInput = 'Como medida de seguridad, necesitamos comprobar que la cuenta que has introducido es de tu propiedad.';

  textAboveInputTwoHolders = 'Como medida de seguridad, necesitamos comprobar que la cuenta de la persona a la que has invitado es de su propiedad.';
  textBelowInputTwoHolders = 'Por ello, le enviaremos una transferencia a esta cuenta. En el siguiente paso, deberá incluir el importe exacto que ha recibido. ¡Es muy fácil!';

  modalTranferFinalText = '¡Solo te queda un paso para tener tu Cuenta Inteligente!';
  modalTranferFinalTextTwoHolders = '¡Solo le queda un paso para tener la Cuenta Inteligente!';

  public loading = false;

  constructor(
    public modService: ModalService,
    private fb: FormBuilder,
    private analytics: AnalyticsService,
    private authService: AuthService,
    public errService: ErrorService
  ) {
    this.clientId = this.authService.getSessionProspect().datosPersonales ?
                    this.authService.getSessionProspect().datosPersonales.id_cliente_EVO :
                    this.errService.navToError();
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).isSecondSigner === 'true') {
      this.isSecondSigner = true;
      this.textAboveInput = this.textAboveInputTwoHolders;
      this.textBelowInput = this.textBelowInputTwoHolders;
      this.modalTranferFinalText = this.modalTranferFinalTextTwoHolders;
    };
    this.persona = JSON.parse(sessionStorage.getItem("currentProspect"))
      ? JSON.parse(sessionStorage.getItem("currentProspect"))
      : undefined;
    this.mail = this.setMailEncoded(this.persona);
    this.iban = this.isSecondSigner ? '**** **** **** **** **** ****' :
      this.persona &&
      this.persona.datosSocioEco &&
      this.persona.datosSocioEco.IBAN;
    this.createForm();
  }

  @HostListener('window:paste', ['$event'])
  onPaste(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  createForm() {
    this.identifyIbanConfirmForm = this.fb.group({
      ibanConfirm: [this.iban]
    });

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  closeModalTransfer() {
    const status = 'envio mail cerrar';
    this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', status, this.clientId);
    this.modService.close(this.modalTransfer);
  }

  setMailEncoded(persona) {
    if (persona && persona.datosPersonales) {
      return "*****" + persona.datosPersonales.email.substr(5);
    } else {
      return 'xxxx@gmail.com';
    }
  }

  submit() {
    this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', 'verificación cuenta - continuar', this.clientId);
    this.analytics.triggerNavData({step:'Paso 07B - Identificación IBAN - envío mail'});
    this.modService.open(this.modalTransfer);
  }

  redirectToEvoBanco() {
    const status = 'envio mail entendido';
    this.analytics.triggerInteractionDataCICJ('Identificación por IBAN', status, this.clientId);
    this.modService.close(this.modalTransfer);
    window.location.href = "http://www.evobanco.com";
  }

}
