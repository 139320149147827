<mobile-error-zone
*ngIf="errService.errorsArray.length > 0"
[errors]="errService.errorsArray">
</mobile-error-zone>
<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<modal [modalId]='alreadyClientModal' [blocking]='true' class='modal modal-login'>
  <mobile-error-zone 
    *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray"
    class="custom-erros">
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="equal-buttons">
        <input  placeholder="Usuario (NIF/NIE)"
        autocomplete="off"
        type="text" name="user"
        formControlName="user"
        maxlength="9"
        (focus)="closeKeyboard()"
        (blur)="validUser()">
        <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('user'),'user')">
          {{errService.desktopErrors(loginUser.get('user'),'user')}}
        </div>
        <input keyboard placeholder="Contraseña" type="password"
        autocomplete="off"
        name="pass" formControlName="pass" (numberEmmited)="numberEmmited($event)"
        (deleteLast)="deleteLast($event)" onfocus="blur()">
        <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('pass'),'pass')">
          {{errService.desktopErrors(loginUser.get('pass'),'pass')}}
        </div>
      </div>
      <div class="row">
        <button class="btn-common btn-common_fix_bold" type="submit" (click)="closeKeyboard()">
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class = 'label'>
        ¿No tienes claves para entrar? Llámanos al <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>
<div class="confirma-otp" drag>

  <p class="description hide-mobile">
    Mira tu móvil. Acabamos de enviarte un código de seguridad para que nos confirmes la modificación de datos que has solicitado.
  </p>
  <div class="container">
    <div class="cod-img">
      <div class="imagen-telefono">
        <p class="subheader">
          <i class="evo icon-confirm"></i>
        </p>
        <p class="description hide-tablet">
          Mira tu móvil. Acabamos de enviarte un código de seguridad para que nos confirmes la modificación de datos que has solicitado.
        </p>
      </div>
      <div>
        <div class="clave">
          <p class="header-2">
            Código SMS
          </p>
        </div>
       <form [formGroup]="otpDetailsForm">
          <div class="input-confirm">
            <input type="tel" id="key-phone" name="key-phone" required
            placeholder="Código"
            #keyphone
            number-input
            [class]="setClassCleanPhone(keyphone)"
            [minLength]="maxlengthOTP != null ? maxlengthOTP: 6"
            [maxLength]="maxlengthOTP != null ? maxlengthOTP: 6"
            autocomplete="off"
            inputmode="numeric"
            formControlName="smsphone">
            <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('smsphone'),'smsphone')">
              {{errService.desktopErrors(loginUser.get('smsphone'),'smsphone')}}
            </div>
          </div>
      </form>
      </div>
    </div>
    <div class="re-enviar" (click)="resendSMS()">
      <span>Reenviar código a mi teléfono {{phoneNumber}}</span>
      <span>
        <i class="evo right-arrow"></i>
      </span>
    </div>
    <div class="btn-group_fix">
      <button (click)='sendCode()' type="submit" [disabled]="!otpDetailsForm.get('smsphone').valid" class="btn-common btn-common_fix btn-common_fix_bold">CONTINUAR</button>
    </div>
    <!-- formulario valido : {{identifyDetailsForm.valid}}
  {{identifyDetailsForm.value | json}} -->
  </div>
</div>
