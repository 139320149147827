import { IberpayService } from "./../../../services/iberpay.service";
import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Persona } from "../../../collections/persona";
import { VideoParams, VerifiEID } from "../../../collections/video";
import { environment } from "../../../../environments/environment";
import { DeviceService } from "../../../services/device.service";
import { ErrorService } from "../../../services/error.service";
import { AuthService } from "../../../services/auth.service";
import { PersonaService } from "../../../services/persona.service";
import { VideoEidService } from "../../../services/video-eid.service";
import { ModalService } from "./../../../services/modal.service";
import { HelperService } from "../../../services/helpers.service";
import { ApiVideoeidService } from "../../../services/api/api.video-eid.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { ACCOUNTS } from "app/resources/account.model";
import { map } from 'rxjs/operators'
import { DOCUMENTOS_VIDEO_ID } from "app/resources/documents-video-id.model";
import { VideoStatus, VideoType } from "app/collections/video-status";
declare var EID;
declare var $;

@Component({
  selector: "identify-video",
  templateUrl: "./identify-video.component.html",
  styleUrls: ["./identify-video.component.scss"],
  providers: [DeviceService, VideoEidService, ModalService, ApiVideoeidService],
})
export class IdentifyVideoComponent implements OnInit, OnDestroy {
  currentStep = "1";
  auth;
  videoService; 
  videoType; // Puede ser videoId (con identificación facial) o videoScan (sin identificación facial)
  VideoType = VideoType; // Para exponer el enum VideoType en la plantilla html
  videoStatus: VideoStatus;
  caption: string = "";
  koCounter: number = 0;
  videoElementId: any = "identifyVideo";
  videoScanId = "videoScanId";
  videoParams: any;
  API_URL;
  modal_id = "modal-identify-video";
  modal_notAdult = "modal-not-adult";
  modal_ko = "modal-ko";
  modalPermisionDenieged = "modal-Permision-Denieged";
    errorPermision: boolean = false;
  msg: string = "";
  title: string = "";
  //video: string = 'Probar con video';
  video: string = "Volver a intentarlo";
  //iban: string = "Probar con IBAN";
  link: string = "Continuar más tarde";
    persona: Persona;
  // isSpecifError: boolean = false;
  loading: boolean = false;
  public logaltyLoaded = false;
  public timeShowModal;
  idType: number;
  allowedIdTypes: number[];
  documentsVideoId = DOCUMENTOS_VIDEO_ID;
  hasNieIdentification: boolean;

  msgSrvError: string = "";
  msghelper: any = {
    current: "1",
    copy: "Coloca la parte delantera de tu documento de identidad frente a la cámara y espera a que se capture la imagen.",
    total: "3",
  };
  modalSrvError: string = "modal-srv-error";
  public isYoungAccount: boolean = false;

  timeout: boolean = false;

  public userInBL = false;
  
  public callMeModal = "call-me";
  isSecondSigner = false;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private deviceService: DeviceService,
    public errService: ErrorService,
    private videoEidSrv: VideoEidService,
    public modService: ModalService,
    private authService: AuthService,
    private personaSrv: PersonaService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private iberpayService: IberpayService
  ) {
    this.API_URL = environment.apiUrl;
    this.persona = this.authService.getSessionProspect();
    this.videoType = this.VideoType.VIDEO_ID;
    this.videoStatus = new VideoStatus();
  }

  ngOnDestroy() {
    this.videoService = undefined;
    this.caption = "";
    this.koCounter = 0;
    this.videoElementId = "identifyVideo";
    this.videoParams = undefined;
    this.API_URL = "";
    this.modal_id = "modal-identify-video";
    this.modal_notAdult = "modal-not-adult";
    this.modal_ko = "modal-ko";
        this.msg = "";
    this.title = "";
    this.video = "Probar con video";
    //this.iban = "Probar con IBAN";
    this.link = "Continuar más tarde";
    this.persona = undefined;
    this.loading = false;
    this.logaltyLoaded = false;
    this.msgSrvError = "";
    this.modalSrvError = "modal-srv-error";
    this.msghelper = { current: "", copy: "", total: "" };
  }

  ngOnInit() {
    // window.scrollTo(0,document.body.scrollHeight);
    this.isYoungAccount = location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount;
    const width = window.innerWidth,
      height = window.innerHeight;
    $("#" + this.videoElementId).css({
      width: width,
      height: height,
    });

    this._saveVideoIdDocumentType();

    const cameraUnavailableMessage =
      "¡Ups! Estamos teniendo problemas para identificarte a través de video.\n" +
      "No te preocupes, tiene solución. Para poder validar tu identidad puedes: volver a intentarlo de nuevo por video o facilitarnos un IBAN de otra entidad\
             en la que seas titular";
    this.videoParams = new VideoParams(
      width,
      height,
      cameraUnavailableMessage
    ).getVideoParams();
    // this.checkUserInBL();
    this.checkIsVideoProcess();
  }

  initLiterals() {
    this.video = "Probar con video";
    //this.iban = "Probar con IBAN";
  }

  //OJO COMENTADO MIENTRAS ESTE DESHABILITADO CONFIRMA
  // checkUserInBL() {
  //   this.loading = true;
  //   const idSfc = this.persona.datosPersonales.id_cliente_EVO;
  //   this.iberpayService.validateUser(idSfc).subscribe(
  //     (data) => {
  //       this.loading = false;
  //       if (data.description) {
  //         switch (data.description) {
  //           case ConstantsService.RespuestasValidation.UsuarioNoEncontrado: // Ok, Usuario no encontrado
  //             this.userInBL = false;
  //             break;
  //           case ConstantsService.RespuestasValidation.UsuarioEncontrado: // Ok, Usuario encontrado
  //             this.userInBL = true;
  //             break;
  //           default:
  //             this.userInBL = true;
  //             break;
  //         }
  //       } else {
  //         this.userInBL = true;
  //       }
  //     },
  //     (_error) => {
  //       this.loading = false;
  //       this.userInBL = true;
  //     }
  //   );
  // }

  checkIsVideoProcess() {
    this.loading = true;
    this.personaSrv
      .getPersonaById(
        this.persona.datosPersonales.id_cliente_EVO,
        this.authService.product
      )
      .subscribe(
        (data) => {
          this.loading = false;
          if (data.electronicID && data.electronicID.electronicId) {
            this.videoEidSrv.getVideo(data.electronicID.electronicId, this.videoType).subscribe(
              (data) => {
                this.loading = false;
                if (data.status.toLowerCase() === "completed") {
                  this.checkDocument(data);
                } else {
                  this.initVideo();
                }
              },
              (error) => {
                this.errService.navToError();
              }
            );
          } else {
            this.initVideo();
          }
        },
        (error) => {
          this.errService.navToError();
        }
      );
  }

  /**
   * Inicializa el proceso de grabación de video mediante videoId (con identificación facial) o videoScan (sin identificación facial)
   * @param {VideoType} videoType - tipo de video a grabar. Por defecto es videoId.
   */
  initVideo(videoType: VideoType = VideoType.VIDEO_ID) {
    const self = this;
    this.videoType = videoType;
    self.videoEidSrv
      .getAuthorizationEID(videoType === VideoType.VIDEO_ID ? VideoType.VIDEO_ID : VideoType.VIDEO_SCAN)
      .pipe(
        map((data) => {
          self.auth = data.body;
          // Inicializa el servicio de video correspondiente (videoId o videoScan)
          self.videoService = videoType === VideoType.VIDEO_ID ? EID.videoId("#" + self.videoElementId, { lang: "es" }) : EID.videoScan("#" + self.videoScanId, { lang: "es" });
          // Restablece el estado del video a 'no finalizado' antes de comenzar la grabación
          self.videoStatus.setVideoFinished(videoType, false);
          self.startRecording();
        })
      )
      .subscribe(
        (ok) => {},
        (ko) => {
          self.msg =
            "Por problemas técnicos no podemos continuar con tu proceso de video identificación";
          if (self.userInBL) {
            self.video = "Volver a intentarlo";
            //self.iban = "";
          } else {
            self.video = "";
            //self.iban = "Continuar por IBAN";
          }
          self.modService.open(self.modal_id);
        }
      );
  }

  destroyVideo(videoService) {
    if (videoService && videoService.turnOff) {
      $("#" + this.videoType === VideoType.VIDEO_ID ? this.videoElementId : this.videoScanId).html("");
      videoService.turnOff();
    }
  }
  destroyVideoKO(videoService) {
    $("#" + this.videoType === VideoType.VIDEO_ID ? this.videoElementId : this.videoScanId).html("");
    videoService.turnOff();
    videoService.destroy();
  }

  startRecording() {
    return this.validationProcess(this.videoService);
  }

  async validationProcess(videoService) {
    const that = this;
    that.persona = that.authService.getSessionProspect();

    videoService.on("phaseStarting", (phase) => {
      if(phase.name !== null){
        phase.name = phase.name.toUpperCase()
      }
      
      that.msghelper.total = that.videoType === VideoType.VIDEO_ID ? "3" : "2";
      switch (phase.name) {
        case "FRONT":
          //that.showModalTimeout();
          that.msghelper = {
            current: "1",
            copy: `Coloca la parte delantera de tu documento de identidad frente a la cámara y espera a que se capture la imagen.`,
            total: that.videoType === VideoType.VIDEO_ID ? "3" : "2",
          };
          that.analytics.triggerNavData({'step': `Paso 07A - Identificación vídeo/Subida de documento de identidad cara`,'clientType': 'Cliente Tipo A'});
          break;
        case "BACK":
          that.msghelper = {
            current: "2",
            copy: `Coloca la parte trasera de tu documento de identidad frente a la cámara`,
            total: that.videoType === VideoType.VIDEO_ID ? "3" : "2",
          };
          that.analytics.triggerNavData({'step': `Paso 07A - Identificación vídeo/Subida de documento de identidad dorso`,'clientType': 'Cliente Tipo A'});            
          break;
        case "FACE":
          that.msghelper = {
            current: "3",
            copy: "Pon tu cara en el centro de la pantalla. Evita llevar accesorios en la cabeza o gafas.",
            total: that.videoType === VideoType.VIDEO_ID ? "3" : "2",
          };
          that.analytics.triggerNavData({'step': 'Paso 07A - Identificación vídeo/Subida foto','clientType': 'Cliente Tipo A'});                  
          break;
        default:
          break;
      }
      phase.continue();
    });
    
    that.cancelTimeOut();
    if(that.videoType === VideoType.VIDEO_SCAN){
      that.allowedIdTypes = [];
      that.idType = 229;
    }
    return that.turnOnVideoService(videoService, that.idType, that.allowedIdTypes, that);
  }

  turnOnVideoService(videoService, idType, allowedIdTypes, that) {
    videoService.start({
      id: this.auth.id,
      authorization: this.auth.authorization,
      minSimilarityLevel: "Low", 
      ...(allowedIdTypes.length > 0 ? { allowedIdTypes: allowedIdTypes }: { idType: idType })
    });

    videoService.on("completed", (video) => {
      const self = this;
      self.cancelTimeOut();
      self.videoService = video.id;
      self.msghelper = { current: "1", copy: "Grabación completada", total: self.videoType === VideoType.VIDEO_ID ? "3" : "2" };
      // Establece el estado del video a 'finalizado' cuando la grabación es exitosa
      self.videoStatus.setVideoFinished(self.videoType, true);
      self.destroyVideo(videoService);
      self.loading = true;
      self.videoEidSrv.getVideo(video.id, self.videoType).subscribe((data) => {
        self.loading = false;
        // Muestra la pantalla de carga si se ha completado la identificación de uno (sólo videoId) o ambos videos (videoId y videoScan)
        self.logaltyLoaded = !self.hasNieIdentification || !(self.videoStatus.isVideoFinished(VideoType.VIDEO_ID) && !self.videoStatus.isVideoFinished(VideoType.VIDEO_SCAN));
        that.checkDocument(data);
      });
    });

    videoService.on("failed", (error) => {
      const self = this;
      self.koCounter++;
      self.initLiterals();
      self.msghelper = { current: "1", copy: "Error durante la grabación", total: self.videoType === VideoType.VIDEO_ID ? "3" : "2" };
      // Restablece el estado del video a 'no finalizado' cuando hay error durante la grabación
      self.videoStatus.setVideoFinished(self.videoType, false);
      self.destroyVideoKO(videoService);
      const isExceed = self.koCounter > 2;
      self.title = "¡Vaya!";
      if (self.userInBL) {
        self.msg =
          "No te preocupes, tiene solución. Para poder validar tu identidad puedes volver a intentarlo de nuevo por vídeo";
        //self.iban = "";
        self.link = "Continuar más tarde";
      } else {
        self.msg =
          "No te preocupes, tiene solución. Para poder validar tu identidad puedes: volver a intentarlo de nuevo por video o facilitarnos un IBAN\
          de otra entidad en la que seas titular.";
      }

      if (error && error.error && error.error.error && !isExceed) {
        that.isSpecificError(error.error.error);
      }
      if (isExceed) {
        if (self.userInBL) {
          self.msg =
            "No te preocupes, tiene solución. Para poder validar tu identidad puedes volver a intentarlo de nuevo por vídeo";
          self.video = "Volver a intentarlo";
          //self.iban = "";
          self.link = "Continuar más tarde";
        } else {
          self.msg =
            "No te preocupes, tiene solución. Para poder validar tu identidad puedes facilitarnos un IBAN.";
          self.video = "";
        }
      }
      if (self.errorPermision) {
        self.modService.open(self.modalPermisionDenieged);
      } else {
        self.modService.open(self.callMeModal);
      }
    });
  }

    changeStep() {
    // Comprueba si la identificación inicial (videoId) ha finalizado con éxito y aún queda pendiente la identificación por NIE (videoScan)
    if(this.hasNieIdentification && this.videoStatus.isVideoFinished(VideoType.VIDEO_ID) && !this.videoStatus.isVideoFinished(VideoType.VIDEO_SCAN)) {
            //this.logaltyLoaded = false;
      this.initVideo(VideoType.VIDEO_SCAN);
    } else {
      this.loading = true;
      const redirect = "confirmacion-datos";
      this.helpers.navigateTo(redirect);
    }
  }

  changeToIban() {
    this.cancelTimeOut();
    this.loading = true;
    const redirect = "identificacion-iban",
      canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      estado: redirect,
      otrosDatos: {
        tipoIdentificacion: redirect,
      },
    });
    this.helpers.navigateTo(redirect);
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {},
      (error) => {
        this.errService.navToError();
      }
    );
  }

  destroy() {
    this.modService.close(this.modal_id, false);
    $("#" + this.videoElementId).html("");
    $("#" + this.videoScanId).html("");
    this.videoService = undefined;
    this.caption = "";
    this.koCounter = 0;
    this.videoElementId = "identifyVideo";
    this.videoScanId = "videoScanId"
    this.videoParams = undefined;
    this.API_URL = "";
    this.modal_id = "modal-identify-video";
        this.modal_notAdult = "modal-not-adult";
    this.modal_ko = "modal-ko";
    this.msg = "";
    this.title = "";
    this.video = "Probar con video";
    this.link = "Continuar más tarde";
    this.persona = undefined;
    this.loading = false;
    this.logaltyLoaded = false;
    this.msgSrvError = "";
    this.modalSrvError = "modal-srv-error";
    this.msghelper = { current: "", copy: "", total: "" };
  }

  closeModalTimeout() {
    if (this.errorPermision) {
      this.modService.close(this.modalPermisionDenieged, false);
      this.errorPermision = false;
    } else {
      this.modService.close(this.modal_id, false);
    }
    this.timeout = false;
    // this.showModalTimeout();
  }
  isSpecificError(error) {
    this.timeout = false;
    switch (error) {
      case "VideoID.ConnectionLost":
        this.title = "¡Vaya!";
        this.msg =
          "Parece que tu señal de internet es muy débil. Prueba a cambiar de red, recuerda que el 4G va muy bien ;-)";
        this.video = "Volver a intentarlo";
        this.link = "";
        break;
      case "DocumentSides.DataMismatch":
        if (this.userInBL) {
          this.title = "¡Vaya!";
          this.msg =
            "No te preocupes, tiene solución. Para poder validar tu identidad puedes volver a intentarlo de nuevo por vídeo";
          this.video = "Volver a intentarlo";
          this.link = "Continuar más tarde";
        }
        break;
      case "Document.NotRecognized":
        if (this.userInBL) {
          this.title = "¡Vaya!";
          this.msg =
            "No te preocupes, tiene solución. Para poder validar tu identidad puedes volver a intentarlo de nuevo por vídeo";
          this.video = "Volver a intentarlo";
          this.link = "Continuar más tarde";
        }
      case "Camera.NotFound":
        this.errorPermision = true;
        break;
      case "Camera.Unavailable":
        this.errorPermision = true;
        break;
      case "MicrophonePermission.Denied":
        this.errorPermision = true;
        break;
      case "Camera.PermissionDenied":
        this.errorPermision = true;
        break;
      case "Service.NotAvailable":
        this.title = "¡Vaya!";
        if (this.userInBL) {
          this.msg =
            "No te preocupes, tiene solución. Para poder validar tu identidad puedes volver a intentarlo de nuevo por vídeo";
          this.video = "Volver a intentarlo";
          this.link = "Continuar más tarde";
        } else {
          this.msg =
            "Parece  que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.";
          this.video = "";
        }
        break;
      case "CameraPermission.Denied":
        this.errorPermision = true;
        break;
    }
    this.setTipoIdentificacionProspect(error);
    this.authService.updateProspect(this.persona);
  }

  isExpired(date) {
    const expire = new Date(Number(date));
    const today = new Date();
    if (expire.getTime() < today.getTime()) {
      return true;
    }
    return false;
  }

  isAdult(birthDateMilliseconds) {
    const currentDate = new Date();
    const currentDateMilliseconds = currentDate.getTime();
    const diffDate = currentDateMilliseconds - birthDateMilliseconds;
    const eighteenYears = 568024200000;

    if(diffDate >= eighteenYears) {
      return true;
    }
    return false;
  }

  tryAgain() {
    if (this.koCounter < 2) {
      this.modService.close(this.modal_ko);
      this.loading = true;
      this.videoEidSrv.getVideo(this.videoService, this.videoType).subscribe(
        (data) => {
          this.loading = false;
          if (data.status.toLocaleLowerCase() == "pending") {
            this.destroy();
            this.initVideo();
          }
        },
        (error) => {
          this.errService.navToError();
        }
      );
    } else {
      this.errService.navToError();
    }
  }

  isServerKo() {
    const name =
      this.persona.datosPersonales && this.persona.datosPersonales.nombreCliente
        ? this.persona.datosPersonales.nombreCliente
        : "";
    if (this.koCounter < 2) {
      this.title = "¡Oh-Oh!";
      this.msg = `Parece que algo está fallando ${name}`;
      this.link = "Inténtalo de nuevo";
      this.modService.open(this.modal_ko, true);
    } else {
      this.errService.navToErrorEid();
    }
  }

  checkDocument(data) {
    if (!this.isExpired(data.document.expiryDate)) {
      if (!this.isAdult(data.document.subject.birthDate) && data.idType !== 229) {
        this.title = "¡UPS!";
        this.msg = !this.isYoungAccount
          ? "Lo sentimos pero por política de admisión, de momento no puedes contratar la Cuenta Inteligente. Es necesario que seas mayor de edad."
          : "Por política de admisión no puedes contratar tu Cuenta Joven ya que el titular debe ser mayor de edad.";
        this.link = "¡Hasta pronto!";
        this.modService.open(this.modal_notAdult);
        data.electronicID = {};
        data.electronicID.descripcionError = "Person.Underage";
        this.sendParamsProspec(data);
      } else {
        this.sendParamsProspec(data);
      }
    } else {
      this.title = "¡Vaya!";
      this.msg =
        "Parece que tu DNI está caducado, comprueba que la fecha de caducidad es correcta. Chequea tu email para que puedas finalizar el proceso cuando lo hayas renovado. ";
      (this.video = ""), (this.link = "Continuar más tarde");
      this.modService.open(this.modal_id);
      data.electronicID = {};
      data.electronicID.descripcionError = "Document.Expired";
      this.sendParamsProspec(data);
    }
  }
  // crm verify
  setDataVerificated(data) {
    const videoVerifi: VerifiEID = new VerifiEID();
    videoVerifi.videoId = data.id;
    videoVerifi.rauthorityId = environment.videoId;
    if (data.electronicID === undefined) {
      // // Ambos debeen estar finalizados para entrar a nuestro nuevo servicio
      if (this.hasNieIdentification || (this.videoStatus.isVideoFinished(VideoType.VIDEO_ID) && this.videoStatus.isVideoFinished(VideoType.VIDEO_SCAN))) {
        // crear una nueva llamada para el segundo servicio nuevo que se esta creando en el api de multicuenta
        this.videoEidSrv.foreignVerificationEID(videoVerifi, this.videoScanId).subscribe(
          (data) => {
            console.info(data);
          },
          (error) => {
            console.error(data);
          }
        );
      } else {
        this.videoEidSrv.verificationEID(videoVerifi).subscribe(
          (data) => {
            console.info(data);
          },
          (error) => {
            console.error(data);
          }
        );
      }
    } 
  }

  setTipoIdentificacionProspect(data) {
    const canal = this.deviceService.getDevice();
    let description;
    let noErrorInEID =
      !!data.id || !!(data.electronicID && !data.electronicID.descripcionError);
    if (data.electronicID && data.electronicID.descripcionError) {
      description = data.electronicID.descripcionError;
      noErrorInEID = false;
    }
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      electronicID: {
        electronicId: data.id ? data.id : this.auth.id,
        errorEID: data.id ? "N" : "S",
        descripcionError: typeof data === "object" ? description : data,
      },
    });
  }

  sendParamsProspec(data) {
    const self = this;
    self.setTipoIdentificacionProspect(data);
    self.loading = true;
    self.setDataVerificated(data);
    self.authService.updateProspect(self.persona).subscribe(
      (datos) => {
        self.loading = false;
        // Muestra la pantalla de carga si se ha completado la identificación de uno (sólo videoId) o ambos videos (videoId y videoScan)
        self.logaltyLoaded = !self.hasNieIdentification || !(self.videoStatus.isVideoFinished(VideoType.VIDEO_ID) && !self.videoStatus.isVideoFinished(VideoType.VIDEO_SCAN));
        if (datos.response.codigo === "KO") {
          self.msgSrvError = !!datos.response.message
            ? datos.response.message
            : "Error genérico";
          self.modService.open(self.modalSrvError);
          return;
        }
        if (
          self.persona.electronicID &&
          !self.persona.electronicID.descripcionError
        ) {
          self.changeStep();
        }
      },
      (error) => {
        self.loading = false;
        self.errService.navToError();
      }
    );
  }

  click(param) {
    switch (param) {
      case "video-time-out":
        this.destroyVideoKO(this.videoService);
        this.initVideo();
        this.modService.close(this.callMeModal);
        this.closeModalTimeout();
        break;
      case "video":
        this.destroyVideoKO(this.videoService);
        this.initVideo();
        this.closeModalTimeout();
        break;
      case "iban":
        this.changeToIban();
        break;
      case "continuar":
        this.errService.navToError();
        break;
      case "welcome":
        this.errService.navToWelcome();
        break;
      default:
        this.destroy();
        this.initVideo();
    }
  }
  /**Muestra el modal_timeout una vez que han pasado 90 segundos */
  showModalTimeout() {
    this.cancelTimeOut();
    this.timeShowModal = setTimeout(() => {
      this.title = "¡Vaya!";
      this.msg =
        "No podemos captar correctamente el documento que estás utilizando.";
      this.video = "Continuar por vídeo";
      this.timeout = true;
      this.modService.open(this.modal_id);
    }, 90000);
  }
  reloadModal(event) {
    if (this.video) {
      this.reiniciarProceso();
    }
  }
  reiniciarProceso() {
    this.destroyVideoKO(this.videoService);
    this.initVideo();
    this.closeModalTimeout();
  }
  cancelTimeOut() {
    this.modal_id = "modal-identify-video";
    if (this.timeShowModal) {
      clearTimeout(this.timeShowModal);
      this.timeShowModal = null;
    }
  }

  get isDNI() {
    const reg = /^\d+$/;
    const idExterno = this.persona.datosPersonales.idExterno;
    return idExterno && idExterno[0] && reg.test(idExterno[0]) ? true : false;
  }

  private _saveVideoIdDocumentType() {
    this.allowedIdTypes = [];

    if (this.persona.datosPersonales.codigoIdExterno === "51" && this.isDNI) {
      this.idType = 62;
    } else if (this.persona.datosPersonales.codigoIdExterno === "66" && !this.isDNI) {
      this.idType = 188;
    } else if (this.persona.datosPersonales.codigoIdExterno === "53" && !this.isDNI) {
      this.hasNieIdentification = true;
      this.documentsVideoId.forEach(item => this.allowedIdTypes.push(...Object.values(item.documentsVideoId)));
    } else {
      if (this.isDNI && this.router.url !== '/datos-personal') {
        this.idType = 62;
        this.persona.datosPersonales.codigoIdExterno = "51";
      } else {
        this.idType = 188;
        this.persona.datosPersonales.codigoIdExterno = "66";
      }
    }
  }

}
