
export const REGISTERLITERALS = {
  young: {
    online: '',
    title: '',
    subtitle: '' +
              '',
    bienvenido: {
      title: 'CONTRATACIÓN DE LA CUENTA JOVEN',
      subtitle:'Primero, déjanos tu móvil. Antes de empezar, ten en cuenta lo siguiente:',
      title_p: [
        'Tener tu DNI o NIE (con foto) a mano.',
        'Debes tributar en España.',
        'Solo puedes ser titular de una Cuenta Joven.']
    }
  },
  default: {
    online: '100% ONLINE',
    title: 'ABRE TU CUENTA INTELIGENTE EN MENOS DE 10 MINUTOS.',
    //mainTitle: 'Ábrela en 10 minutos y 100% online',
    mainTitle: 'Abre tu cuenta al 3,05 % TAE sin comisiones y sin condiciones',
    mainTitleMobile: 'Abre tu cuenta al 3,05 % TAE<sup>1</sup> sin comisiones y sin condiciones.',
    subtitle: 'Una cuenta donde todo está en tu móvil. Porque desde la APP podrás gestionar tus gastos, ' +
              'ingresos y pagar con tu móvil de una manera fácil.',
    bienvenido: {
      title: 'ABRE TU CUENTA INTELIGENTE EN MENOS DE 10 MINUTOS',
      subtitle:'Primero, déjanos tu móvil. Antes de empezar, ten en cuenta lo siguiente:',
      title_p: [
        'Tener tu DNI o NIE con foto',
        'Tributar en España.',
        'No disponer de una Cuenta Inteligente EVO']
    }
  },
  modal: {
    literal:'De conformidad con lo establecido en el Reglamento 679/2016 General de Protección de Datos y normativa complementaria,' +
    'te informamos de que tus datos serán incorporados a los ficheros de EVO BANCO, S.A.U en tanto entidad responsable del tratamiento' +
    'automatizado o no, de los mismos.  Los datos facilitados a lo largo de la presente solicitud, entre ellos aquellos utilizados para ' +
    'la identificación no presencial mediante videoconferencia así como la grabación y conservación del presente proceso de contratación,' +
    'incluida la IP desde donde se realiza, serán tratados con la finalidad de estudiar tu solicitud y posterior gestión de tu cuenta. Asimismo,' +
    'tus datos serán tratados con finalidad estadística conforme a técnicas de agregación y seudonimización para analizar patrones globales sobre ingresos'+
    'y gastos de usuarios, pero en ningún momento como consecuencia de este tratamiento se generarán patrones de carácter longitudinal respecto del uso de '+
    'determinados servicios o la singularización de usuarios, no siendo posible la vinculación directa o indirecta de la información con un determinado usuario.'+
    'Para el estudio de tu solicitud, Evo Banco, S.A.U. podrá consultar ficheros de las empresas del grupo, datos que constan en redes sociales, así como en ficheros '+
    'de solvencia económica y podrán ponerse en contacto contigo tanto telefónicamente como por otros medios, incluidos los telemáticos, con la misma finalidad. '+
    'Al marcar la casilla anterior, consientes expresamente que estos datos sean almacenados y tratados en un fichero automatizado y manual del que Evo Banco, S.A.U.'+
    'es responsable. No aceptar estas condiciones implicaría no poder seguir adelante con el proceso de contratación.'+
    'Los datos objeto de dicho tratamiento se conservarán en la medida en que sean necesarios para las finalidades descritas y en cumplimiento de la normativa aplicable.'+
    'Conservaremos la información personal con las medidas de seguridad adecuadas en función de los datos y tratamientos por 10 años. No obstante, en todo momento podrás '+
    'ejercer tus derechos de acceso, rectificación, cancelación, portabilidad, limitación y oposición al tratamiento de datos a través del correo electrónico protecciondedatos@evobanco.com '+
    'o bien por escrito dirigido a Evo Banco, S.A. en la Calle Don Ramón de la Cruz 84, CP28006 de Madrid, indicando en el asunto “Protección de Datos” y adjuntando copia de tu DNI o documento identificativo '+
    'válido, a cualquiera de ellos. Asimismo, te informamos de que tienes derecho a recabar la tutela de la Agencia Española de Protección de Datos a través de su página Web www.aepd.es.'+
    'Por otro lado, quedas informado de la comunicación de los datos de la presente solicitud al Fichero CONFIRMA, para la finalidad de prevención de fraude.'+
    'Los responsables de dicho fichero son las Entidades Adheridas al Reglamento del Fichero CONFIRMA, siendo el encargado del tratamiento Confirma Sistemas de Información,'+
     'S.L., con domicilio en la Avda, de la Industria, 18, TRES CANTOS (28760) MADRID. Podrás consultar el listado de Entidades que actualmente están adheridas al Reglamento del'+
     'Fichero CONFIRMA en www.confirmasistemas.es. Los datos comunicados al Fichero CONFIRMA podrán ser cedidos a las Entidades Adheridas al Reglamento del Fichero CONFIRMA para '+
     'la finalidad previamente indicada. Podrás ejercer tus derechos de acceso, rectificación, cancelación, limitación, portabilidad y oposición, dirigiéndote al domicilio de '+
     'CONFIRMA SISTEMAS DE INFORMACIÓN, S.L. en la dirección arriba indicada.',
     comunicaciones_comerciales:'¿Consientes recibir comunicaciones comerciales sobre productos o servicios de terceros, adaptados a tu' +
     'perfil, que Evo comercialice por cualquier medio y la cesión de datos a terceros con esa finalidad, así como' +
     'recibir o acceder a ofertas sobre productos no similares a los contratados con nosotros?'
  },
  checks: {
    perfilado:
      // "Consiento que mis datos personales sean utilizados para que EVO Banco puedan acceder e incorporar " +
      // "en sus ficheros datos accesibles al público o de terceras empresas para elaborar perfiles " +
      // "y personalizar ofertas y adaptarlas a mis necesidades, gustos y preferencias.",
      "Consiento que EVO pueda incorporar en sus ficheros mis datos obtenidos de fuentes externas y evaluar "+
      "mis aspectos personales con datos propios y externos con la finalidad de elaborar un perfil comercial "+
      "para conocer mi interés sobre productos y/o servicios que la Entidad esté interesada en comercializar, "+
      "personalizar las acciones comerciales, crear nuevos productos y/o servicios, así como mejorar sus características.  ",

    publicidad:
      "Consiento que mis datos sean utilizados para que EVO Banco pueda informarme, a través de cualquier canal (incluido los"+
      " electrónicos), de productos y/o servicios, de EVO Banco, del Grupo Bankinter y sus participadas o empresas con las que"+
      " el Banco tenga acuerdos de colaboración, distintos a los que tengo contratados con el Banco.",
      // "Consiento que mis datos personales sean utilizando para que EVO Banco pueda ponerse en contacto conmigo " +
      // "a través de cualquier canal (incluido los electrónicos) para informarme de otros productos o servicios " +
      // "que no tengan similitud alguna con los que tengo con ustedes, que sean ofrecidos por EVO, " +
      // "empresas del grupo Bankinter y terceras empresas con las que haya suscrito acuerdos de colaboración.",
    cesion:
      // "Consiento que mis datos personales sean cedidos a empresas del grupo Bankinter y a sus filiales o participadas para que " +
      // "puedan informarme, por cualquier canal (incluido los electrónicos) sobre los productos o servicios que comercialicen que " +
      // "sean adecuados a mis necesidades, gustos y preferencias y puedan utilizar para el seguimiento, control y análisis del " +
      // "riesgo de los productos y servicios contratados.",
      "Consiento que EVO pueda compartir mis datos personales con las empresas del Grupo Bankinter y sus participadas para que"+
      " puedan evaluar y predecir aspectos personales sobre mi situación económica, preferencias, intereses o comportamientos, "+
      "con la finalidad de que puedan elaborar un perfil comercial sobre mí. ",
    newLegal1: `Informarme, a través de cualquier canal (incluido los electrónicos), de productos o servicios, distintos a los que 
        tengo contratados con el Banco, de empresas del Grupo Bankinter y terceras empresas con las que el Grupo tenga acuerdos de colaboración.`,	
    newLegal2: `Incorporar en sus ficheros y analizar mis datos obtenidos de fuentes externas para evaluar y predecir aspectos personales 
        sobre mi situación económica, preferencias, intereses o comportamientos y remitirme ofertas comerciales en las que pueda estar interesado.`,
    newLegal3: `Compartir mis datos personales con las empresas del Grupo Bankinter y sus participadas, para que puedan ofrecerme productos 
        y servicios a través de cualquier canal (incluido los electrónicos).`,
    newLegal4: `Compartir mis datos personales con las empresas del Grupo Bankinter y sus participadas para que puedan evaluar y predecir 
        aspectos personales sobre mi situación económica, preferencias, intereses o comportamientos, y remitirme ofertas comerciales 
        de sus productos o servicios en los que pueda estar interesado.`,
  },
  boxtitleDesktop:'<p class=initial-text><span class=pink-color>Cuenta Inteligente bienvenida<sup>1</sup></span></p><br>'+
                  // '<p class=camela-text>TU DINERO EN EVO</p>'+
                  // '<p class=camela-text><span class=pink-color>ES MÁS DINERO<span></p><br>'+
                  '<p class=camela-text>LLÉVATE</p>'+
                  '<p class=camela-text> HASTA <span class=pink-color-upper> 903 €</span><br>'+
                  //'<p class=camela-text>hasta 30.000 €.</p>'+
                  '<p class=limit_date>EL PRIMER AÑO PARA SALDOS HASTA 30.000€.</p>'+
                  '<p class=limit_date_second_line>Válido hasta el 31/10/24. Condiciones garantizadas hasta 31/01/25.</p><br>'+
                  '<p class=description-text> sin comisiones</p>'+
                  '<p class=description-text>sin condiciones </p>'+
                  '<p class=description-text>sin permanencia </p><br>'+
                  '<p class=description-text>LOS LISTOS</p>'+
                  '<p class=description-text><span class=pink-color>SE VIENEN A EVO<span></p><br>',
  boxtitleTablet: '<table>'+
                    '<tr>'+
                      '<td class=first_colum_tablet>'+
                        '<p class=initial-text><span class=pink-color>Cuenta Inteligente bienvenida<sup>1</sup></span></p>'+
                        '<p class=camela-text>LOS LISTOS</p>'+ 
                        '<p class=camela-text-1><span class=pink-color>SE VIENEN A EVO<span></p>'+
                        '<p class=camela-text>LLÉVATE HASTA<span class=pink-color-upper> 903 €</span></p>'+
                        '<p class=description-text-1>EL PRIMER AÑO PARA SALDOS HASTA 30.000€.</p>'+
                        '<p class=limit_date>Válido hasta el 31/10/24.</p>'+
                        '<p class=limit_date>Condiciones garantizadas hasta 31/01/25.</p>'+
                      '</td>'+
                      '<td class=second_colum_tablet>'+
                        '<p class=comision-text>sin comisiones</p>'+
                        '<p class=comision-text>sin condiciones</p>'+
                        '<p class=comision-text>sin permanencia</p>'+                        
                      '</td>'+
                      // '<td class=second_colum_tablet>'+
                      // '<p class=camela-text>TU DINERO EN EVO</p>'+ 
                      // '<p class=camela-text-1><span class=pink-color>ES MÁS DINERO<span></p>'+                        
                      // '</td>'+
                      
                    '</tr>'+
                  '</table>',
  laborBoxtitleDesktop:'<img class="datos_laborales04"></img>'+
                       '<div class=labor_border>'+
                        '<p class=description-text></p>'+
                        '<p class="icon-reloj-monedas"></p>'+
                        '<p class="labour-title-2">DESCUBRE NUESTRO DEPÓSITO</p>'+                    
                        '<p class="subtitle-2">Una vez termines de contratar tu Cuenta </p>'+
                        '<p class="subtitle-2">Inteligente Bienvenida, podrás abrir tu <span class="subtitle-bold">Depósito<sup>1</sup></span></p>'+
                        '<p class="subtitle-bold">a 4 meses al <span class="subtitle-labor">3,05% TAE</span></>.</p>'+
                       '</div>',
  laborBoxtitleTablet: '<table>'+
                    '<tr>'+
                      '<td class=labor_first_colum_tablet>'+
                        '<img class="datos_laborales04"></img>'+
                      '</td>'+
                      '<td class=labor_second_colum_tablet>'+
                        '<p class="icon-reloj-monedas"></p>'+
                        '<p class="labour-title-2">DESCUBRE NUESTRO</p>'+
                        '<p class="labour-title-2">DEPÓSITO</p>'+
                        '<p class="subtitle-2">Una vez termines de contratar tu Cuenta </p>'+
                        '<p class="subtitle-2">Inteligente Bienvenida, podrás abrir tu <span class="subtitle-bold">Depósito<sup>1</sup> a 4 meses al </span><span class="subtitle-labor">3,05% TAE</span></p>'+
                      '</td>'+
                    '</tr>'+
                  '</table>' ,
  legalCondition: [
    {
      text:'1. Para contratar la Cuenta Inteligente Bienvenida EVO debes ser mayor de 18 años. ' +
            'La Cuenta Inteligente Bienvenida EVO son dos cuentas conectadas sin comisiones: una corriente para tu día a día y, otra a plazo para tus ahorros. ' +
            'En la Cuenta Inteligente Bienvenida EVO, el día 15 de cada mes, el saldo de la cuenta corriente que exceda de 4.000 € pasará automáticamente a la cuenta a plazo. ' +
            'Sin penalización por reintegros anticipados y es renovable automáticamente cada 4 meses según remuneración vigente. ' +
            'Debes saber que, aunque EVO no cobra nada por retirar dinero a débito en el extranjero, algunas entidades propietarias de cajeros pueden aplicar una tasa de recargo por su utilización. ' +
            'Es necesario mantener todos los productos gratuitos que integran la Cuenta Inteligente Bienvenida EVO (Cuenta corriente, Cuenta a plazo, Tarjeta Inteligente, Banca a Distancia y Servicio de Alertas) y puedes disponer de un máximo de dos Tarjetas Inteligentes bonificadas.',
      class:"promoCondition"
    },
    {
      text: 'Cuenta corriente hasta 4.000 € al 0 % TIN y al <span class="taeUp">0 % TAE</span> y cuenta a plazo remunerada y sin comisiones de ningún tipo para un saldo total en '+
            'cuenta de plazo de 30.000 € al 3,01 % TIN y <span class="taeUp">3,05 % TAE</span>, (a un año bajo la hipótesis de que el TIN no varía), percibiendo una remuneración de 903 € el primer año y sin cumplir ningún requisito. '+
            'Siendo el importe máximo a remunerar de 30.000 €. El ejemplo representativo de la cuenta a plazo remunerada está calculado para contrataciones '+
            'hasta el 31/10/24. Te garantizamos esta remuneración hasta el 31/01/25. A partir del 01/02/25, la remuneración en la cuenta a plazo será la vigente en ese momento. ' +
            'Liquidación mensual de intereses. Renovable automáticamente cada 4 meses.',
      class:"promoCondition negrita"
    },
    {
      text:'EVO BANCO, S.A. ("la ENTIDAD") NIF A70386024 I.R.A.E. del BdE nº 0239. I.R.M. MADRID, T.31.840, F.88, H. M-572999, Insc. 2ª. Dom. Social: Don Ramón de la Cruz, número 84, 28006, Madrid.',
      class:"promoCondition"
    }
  ]                                                                                    
};