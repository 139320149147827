import { Country } from '../collections/country';

export let PAISES: Country[] = [

  { value: null, name: 'País de nacimiento', code: null},
  { value: '011', name: 'ESPAÑA', code: 'ESP' },
  { value: '070', name: 'ALBANIA', code: 'ALB' },
  { value: '330', name: 'ANGOLA', code: 'AGO' },
  { value: '478', name: 'ANT.NEERLANDESAS HASTA 28/02/11', code: 'ANT' },
  { value: '632', name: 'ARABIA SAUDITA', code: 'SAU' },
  { value: '208', name: 'ARGELIA', code: 'DZA' },
  { value: '528', name: 'ARGENTINA', code: 'ARG' },
  { value: '474', name: 'ARUBA', code: 'ABW' },
  { value: '453', name: 'BAHAMAS', code: 'BHS' },
  { value: '640', name: 'BAHREIN', code: 'BHR' },
  { value: '017', name: 'BELGICA', code: 'BEL' },
  { value: '508', name: 'BRASIL', code: 'BRA' },
  { value: '512', name: 'CHILE', code: 'CHL' },
  { value: '720', name: 'CHINA', code: 'CHN' },
  { value: '600', name: 'CHIPRE', code: 'CYP' },
  { value: '045', name: 'CIUDAD DEL VATICANO', code: 'VAT' },
  { value: '480', name: 'COLOMBIA', code: 'COL' },
  { value: '436', name: 'COSTA RICA', code: 'CRI' },
  { value: '475', name: 'CURAÇAO', code: 'CUW' },
  { value: '647', name: 'EMIRATOS ARABES UNIDOS', code: 'ARE' },
  { value: '336', name: 'ERITREA', code: 'ERI' },
  { value: '053', name: 'ESTONIA', code: 'EST' },
  { value: '334', name: 'ETIOPIA', code: 'ETH' },
  { value: '823', name: 'FEDERACION DE ESTADOS DE MICRONESIA', code: 'FSM' },
  { value: '815', name: 'FIJI', code: 'FJI' },
  { value: '708', name: 'FILIPINAS', code: 'PHL' },
  { value: '032', name: 'FINLANDIA', code: 'FIN' },
  { value: '314', name: 'GABON', code: 'GAB' },
  { value: '252', name: 'GAMBIA', code: 'GMB' },
  { value: '276', name: 'GHANA', code: 'GHA' },
  { value: '044', name: 'GIBRALTAR', code: 'GIB' },
  { value: '458', name: 'GUADALUPE', code: 'GLP' },
  { value: '416', name: 'GUATEMALA', code: 'GTM' },
  { value: '257', name: 'GUINEA-BISSAU', code: 'GNB' },
  { value: '488', name: 'GUYANA', code: 'GUY' },
  { value: '424', name: 'HONDURAS', code: 'HND' },
  { value: '700', name: 'INDONESIA', code: 'IDN' },
  { value: '007', name: 'IRLANDA', code: 'IRL' },
  { value: '041', name: 'ISLAS FEROE', code: 'FRO' },
  { value: '667', name: 'ISLAS MALDIVAS', code: 'MDV' },
  { value: '529', name: 'ISLAS MALVINAS (FALKLANDS)', code: 'FLK' },
  { value: '454', name: 'ISLAS TURCAS Y CAICOS', code: 'TCA' },
  { value: '811', name: 'ISLAS WALLIS/FUTUNA (INC.I.ALORI)', code: 'WLF' },
  { value: '457', name: 'ISLAS VIRGENES DE LOS ESTADOS UNIDOS', code: 'VIR' },
  { value: '910', name: 'INSTITUCIONES DE LA UNION EUROPEA', code: 'EU' },
  { value: '103', name: 'ISLAS ANGLONORMANDAS (GUERNSEY & JE)', code: 'JEY' },
  { value: '104', name: 'ISLA DE MAN', code: 'IMN' },
  { value: '468', name: 'ISLAS VIRGENES BRITANICAS', code: 'VGB' },
  { value: '005', name: 'ITALIA', code: 'ITA' },
  { value: '616', name: 'IRAN', code: 'IRN' },
  { value: '612', name: 'IRAQ', code: 'IRQ' },
  { value: '624', name: 'ISRAEL', code: 'ISR' },
  { value: '464', name: 'JAMAICA', code: 'JAM' },
  { value: '696', name: 'KAMPUCHEA (CAMBOYA)', code: 'KHM' },
  { value: '346', name: 'KENYA', code: 'KEN' },
  { value: '812', name: 'KIRIBATI', code: 'KIR' },
  { value: '997', name: 'KOSOVO', code: 'XK' },
  { value: '684', name: 'LAOS', code: 'LAO' },
  { value: '054', name: 'LETONIA', code: 'LVA' },
  { value: '604', name: 'LIBANO', code: 'LBN' },
  { value: '216', name: 'LIBIA', code: 'LBY' },
  { value: '108', name: 'LUXEMBURGO (ANTES)', code: 'LUX' },
  { value: '370', name: 'MADAGASCAR', code: 'MDG' },
  { value: '232', name: 'MALI', code: 'MLI' },
  { value: '373', name: 'MAURICIO', code: 'MUS' },
  { value: '101', name: 'MONACO', code: 'MCO' },
  { value: '095', name: 'MONTENEGRO', code: 'MNE' },
  { value: '470', name: 'MONTSERRAT', code: 'MSR' },
  { value: '366', name: 'MOZAMBIQUE', code: 'MOZ' },
  { value: '676', name: 'MYANMAR (ANTIGUA BIRMANIA)', code: 'MMR' },
  { value: '432', name: 'NICARAGUA', code: 'NIC' },
  { value: '240', name: 'NIGER', code: 'NER' },
  { value: '804', name: 'NUEVA ZELANDA', code: 'NZL' },
  { value: '921', name: 'ORGANISMOS INTERNACIONALES', code: 'TODO' },
  { value: '003', name: 'PAISES BAJOS', code: 'NLD' },
  { value: '958', name: 'PAISES Y TERRITORIOS NO DETERMINADO', code: 'TODO' },
  { value: '442', name: 'PANAMA', code: 'PAN' },
  { value: '801', name: 'PAPUA NUEVA GUINEA', code: 'PNG' },
  { value: '060', name: 'POLONIA', code: 'POL' },
  { value: '010', name: 'PORTUGAL', code: 'PRT' },
  { value: '006', name: 'REINO UNIDO', code: 'GBR' },
  { value: '247', name: 'REPUBLICA DE CABO VERDE', code: 'CPV' },
  { value: '322', name: 'REPUBLICA DEMOCRATICA DEL CONGO', code: 'CON' },
  { value: '063', name: 'REPUBLICA ESLOVACA', code: 'SVK' },
  { value: '819', name: 'SAMOA OCCIDENTAL', code: 'WSM' },
  { value: '047', name: 'SAN MARINO', code: 'SMR' },
  { value: '311', name: 'SANTO TOME Y PRINCIPE', code: 'STP' },
  { value: '248', name: 'SENEGAL', code: 'SEN' },
  { value: '094', name: 'SERBIA', code: 'SRB' },
  { value: '688', name: 'SERBIA Y MONTENEGRO(HASTA 01.01.07)', code: 'SCG' },
  { value: '039', name: 'SUIZA', code: 'CHE' },
  { value: '244', name: 'TCHAD', code: 'TCD' },
  { value: '357', name: 'TERRIT.BRITANICO DEL OCEANO INDICO', code: 'IOT' },
  { value: '702', name: 'TIMOR LESTE', code: 'TLS' },
  { value: '817', name: 'TONGA', code: 'TON' },
  { value: '080', name: 'TURKMENISTAN', code: 'TKM' },
  { value: '081', name: 'UZBEKISTAN', code: 'UZB' },
  { value: '484', name: 'VENEZUELA', code: 'VEN' },
  { value: '653', name: 'YEMEN', code: 'YEM' },
  { value: '378', name: 'ZAMBIA', code: 'ZMB' },
  { value: '660', name: 'AFGANISTAN', code: 'AFG' },
  { value: '630', name: 'PUERTO RICO', code: 'PRI' },
  { value: '236', name: 'BURKINA-FASO (ALTO VOLTA)', code: 'BFA' },
  { value: '524', name: 'URUGUAY', code: 'URY' },
  { value: '408', name: 'SAN PEDRO Y MIQUELON', code: 'SPM' },
  { value: '452', name: 'HAITI', code: 'HTI' },
  { value: '449', name: 'SAN CRISTOBAL Y NEVIS', code: 'KNA' },
  { value: '476', name: 'SAN MARTÍN', code: 'MAF' },
  { value: '608', name: 'SIRIA', code: 'SYR' },
  { value: '669', name: 'SRI LANKA', code: 'LKA' },
  { value: '806', name: 'ISLAS SALOMON', code: 'SLB' },
  { value: '393', name: 'SWAZILANDIA', code: 'SWZ' },
  { value: '500', name: 'ECUADOR', code: 'ECU' },
  { value: '009', name: 'GRECIA', code: 'GRC' },
  { value: '716', name: 'MONGOLIA', code: 'MNG' },
  { value: '706', name: 'SINGAPUR', code: 'SGP' },
  { value: '037', name: 'LIECHTENSTEIN', code: 'LIE' },
  { value: '078', name: 'AZERBAIJAN', code: 'AZE' },
  { value: '024', name: 'ISLANDIA', code: 'ISL' },
  { value: '732', name: 'JAPON', code: 'JPN' },
  { value: '477', name: 'BONAIRE,SAN EUSTAQUIO Y SABA', code: 'BES' },
  { value: '813', name: 'PITCAIRN (INC.I.HENDERSON/DUCIET OL', code: 'PCN' },
  { value: '920', name: 'ORGANISMOS INTERNACIONALES NO U.E.', code: 'TODO' },
  { value: '388', name: 'SUDAFRICA', code: 'ZAF' },
  { value: '680', name: 'THAILANDIA', code: 'THA' },
  { value: '284', name: 'BENIN', code: 'BEN' },
  { value: '516', name: 'BOLIVIA', code: 'BOL' },
  { value: '093', name: 'BOSNIA-HERZEGOVINA', code: 'BIH' },
  { value: '628', name: 'JORDANIA', code: 'JOR' },
  { value: '372', name: 'REUNION', code: 'REU' },
  { value: '091', name: 'ESLOVENIA', code: 'SVN' },
  { value: '728', name: 'COREA DEL SUR', code: 'KOR' },
  { value: '625', name: 'TERRITORIO PALESTINO OCUPADO', code: 'PSE' },
  { value: '675', name: 'BHUTAN', code: 'BTN' },
  { value: '228', name: 'MAURITANIA', code: 'MRT' },
  { value: '310', name: 'GUINEA ECUATORIAL', code: 'GNQ' },
  { value: '018', name: 'LUXEMBURGO', code: 'LUX' },
  { value: '075', name: 'RUSIA', code: 'RUS' },
  { value: '076', name: 'GEORGIA', code: 'GEO' },
  { value: '428', name: 'EL SALVADOR', code: 'SLV' },
  { value: '492', name: 'SURINAM', code: 'SUR' },
  { value: '740', name: 'HONG KONG', code: 'HKG' },
  { value: '724', name: 'COREA DEL NORTE', code: 'PRK' },
  { value: '329', name: 'SANTA HELENA Y DEPENDENCIAS', code: 'SHN' },
  { value: '463', name: 'ISLAS CAYMAN', code: 'CYM' },
  { value: '400', name: 'ESTADOS UNIDOS DE AMERICA', code: 'USA' },
  { value: '448', name: 'CUBA', code: 'CUB' },
  { value: '820', name: 'ISLAS MARIANAS DEL NORTE', code: 'MNP' },
  { value: '672', name: 'NEPAL', code: 'NPL' },
  { value: '096', name: 'MACEDONIA', code: 'MKD' },
  { value: '324', name: 'RWANDA', code: 'RWA' },
  { value: '395', name: 'LESOTHO', code: 'LSO' },
  { value: '649', name: 'OMAN', code: 'OMN' },
  { value: '459', name: 'ANTIGUA Y BARBUDA', code: 'ATG' },
  { value: '030', name: 'SUECIA', code: 'SWE' },
  { value: '280', name: 'TOGO', code: 'TGO' },
  { value: '386', name: 'MALAWI', code: 'MWI' },
  { value: '496', name: 'GUAYANA FRANCESA', code: 'GUF' },
  { value: '736', name: 'TAIWAN', code: 'TWN' },
  { value: '204', name: 'MARRUECOS', code: 'MAR' },
  { value: '462', name: 'MARTINICA', code: 'MTQ' },
  { value: '001', name: 'FRANCIA', code: 'FRA' },
  { value: '306', name: 'REPUBLICA CENTRO AFRICANA', code: 'CAF' },
  { value: '092', name: 'CROACIA', code: 'HRV' },
  { value: '061', name: 'REPUBLICA CHECA', code: 'CZE' },
  { value: '666', name: 'BANGLADESH', code: 'BGD' },
  { value: '822', name: 'POLINESIA FRANCESA', code: 'PYF' },
  { value: '816', name: 'VANUATU', code: 'VUT' },
  { value: '664', name: 'INDIA', code: 'IND' },
  { value: '930', name: 'BANCO CENTRL EUROPEO', code: 'TODO' },
  { value: '046', name: 'MALTA', code: 'MLT' },
  { value: '467', name: 'SAN VICENTE E ISLAS GRANADINAS', code: 'VCT' },
  { value: '520', name: 'PARAGUAY', code: 'PRY' },
  { value: '391', name: 'BOTSWANA', code: 'BWA' },
  { value: '004', name: 'ALEMANIA', code: 'DEU' },
  { value: '107', name: 'ISLAS COOK', code: 'COK' },
  { value: '743', name: 'MACAO', code: 'MAC' },
  { value: '027', name: 'ARCHIPIELAGO DE SVALBARD', code: 'SJM' },
  { value: '316', name: 'GUAM', code: 'GUM' },
  { value: '066', name: 'RUMANIA', code: 'ROU' },
  { value: '636', name: 'KUWAIT', code: 'KWT' },
  { value: '824', name: 'ISLAS MARSHALL', code: 'MHL' },
  { value: '055', name: 'LITUANIA', code: 'LTU' },
  { value: '998', name: 'JERSEY', code: 'JEY' },
  { value: '662', name: 'PAKISTAN', code: 'PAK' },
  { value: '406', name: 'GROENLANDIA', code: 'GRL' },
  { value: '413', name: 'BERMUDAS', code: 'BMU' },
  { value: '342', name: 'SOMALIA', code: 'SOM' },
  { value: '038', name: 'AUSTRIA', code: 'AUT' },
  { value: '268', name: 'LIBERIA', code: 'LBR' },
  { value: '809', name: 'NUEVA CALEDONIA', code: 'NCL' },
  { value: '302', name: 'CAMERUN', code: 'CMR' },
  { value: '272', name: 'COSTA DE MARFIL', code: 'CIV' },
  { value: '810', name: 'OCEANIA AMERICANA', code: 'TODO' },
  { value: '077', name: 'ARMENIA', code: 'ARM' },
  { value: '814', name: 'OCEANIA NEOZELANDESA', code: 'TODO' },
  { value: '288', name: 'NIGERIA', code: 'NGA' },
  { value: '404', name: 'CANADA', code: 'CAN' },
  { value: '328', name: 'BURUNDI', code: 'BDI' },
  { value: '375', name: 'COMORES/GRAN COMORES/ANJOUAN/MCHELI', code: 'COM' },
  { value: '220', name: 'EGIPTO', code: 'EGY' },
  { value: '382', name: 'ZIMBABWE', code: 'ZWE' },
  { value: '890', name: 'REGIONES POLARES', code: 'TODO' },
  { value: '701', name: 'MALASIA', code: 'MYS' },
  { value: '703', name: 'BRUNEI', code: 'BRN' },
  { value: '352', name: 'TANZANIA', code: 'TZA' },
  { value: '052', name: 'TURQUIA', code: 'TUR' },
  { value: '079', name: 'KAZAJISTAN', code: 'KAZ' },
  { value: '421', name: 'BELICE', code: 'BLZ' },
  { value: '072', name: 'UCRANIA', code: 'UKR' },
  { value: '064', name: 'HUNGRIA', code: 'HUN' },
  { value: '082', name: 'TAJIKISTAN', code: 'TJK' },
  { value: '016', name: 'SAMOA AMERICANA', code: 'ASM' },
  { value: '224', name: 'SUDAN', code: 'SDN' },
  { value: '083', name: 'KIRGUIZISTAN', code: 'KGZ' },
  { value: '825', name: 'PALAU', code: 'PLW' },
  { value: '338', name: 'DJIBOUTI', code: 'DJI' },
  { value: '043', name: 'ANDORRA', code: 'AND' },
  { value: '389', name: 'NAMIBIA', code: 'NAM' },
  { value: '690', name: 'VIETNAM', code: 'VNM' },
  { value: '504', name: 'PERU', code: 'PER' },
  { value: '472', name: 'TRINIDAD Y TOBAGO', code: 'TTO' },
  { value: '068', name: 'BULGARIA', code: 'BGR' },
  { value: '800', name: 'AUSTRALIA', code: 'AUS' },
  { value: '377', name: 'MAYOTTE, GRAN TIERRA Y PAMANZI', code: 'MYT' },
  { value: '264', name: 'SIERRA LEONA', code: 'SLE' },
  { value: '260', name: 'GUINEA', code: 'GIN' },
  { value: '212', name: 'TUNEZ', code: 'TUN' },
  { value: '350', name: 'UGANDA', code: 'UGA' },
  { value: '644', name: 'QATAR', code: 'QAT' },
  { value: '446', name: 'ANGUILLA', code: 'AIA' },
  { value: '073', name: 'BIELORRUSIA', code: 'BLR' },
  { value: '412', name: 'MEXICO', code: 'MEX' },
  { value: '002', name: 'BELGICA (ANTES)', code: 'BEL' },
  { value: '803', name: 'NAURU', code: 'NRU' },
  { value: '456', name: 'REPUBLICA DOMINICANA', code: 'DOM' },
  { value: '008', name: 'DINAMARCA', code: 'DNK' },
  { value: '807', name: 'TUVALU', code: 'TUV'},
  { value: '318', name: 'CONGO', code: 'COG' },
  { value: '028', name: 'NORUEGA', code: 'NOR' },
  { value: '074', name: 'MOLDAVIA', code: 'MDA' },
  { value: '469', name: 'BARBADOS', code: 'BRB' },
  { value: '999', name: 'DESCONOCIDO (CARGA)', code: 'TODO' },
  { value: '802', name: 'OCEANIA AUSTRALIANA', code: 'TODO' },
  { value: '465', name: 'SANTA LUCIA', code: 'LCA' },
  { value: '460', name: 'DOMINICA', code: 'DMA' },
  { value: '355', name: 'SEYCHELLES', code: 'SYC' },
  { value: '473', name: 'GRANADA', code: 'GRD' },
];
