import { ApiBySideCarService } from "./services/api/api.bysidecar.service";
// modules
import { NgModule, enableProdMode } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";

// other modules
import { AppRoutingModule } from "./app-routing.module";

import { SharedModule } from "./modules/shared/shared.module";
import { RegisterModule } from "./modules/register/register.module";
import { ProfileModule } from "./modules/profile/profile.module";

// services
import { NavigationService } from "./services/navigation.service";
import { ErrorService } from "./services/error.service";
import { HeaderService } from "./services/headers.service";
import { ApiRecoveryService } from "./services/api/api.recovery.service";
import { RecoveryService } from "./services/recovery.service";
import { SpecificAnalyticsService } from "./services/specific-analytics.service";
import { DeviceService } from "./services/device.service";
import { AuthGuard } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { AnalyticsService } from "./services/analytics.service";
import { HelperService } from "./services/helpers.service";
import { LoginService } from "./services/login.service";
import { ApiLoginService } from "./services/api/api.login.service";
import { RecomiendaService } from "./services/recomienda.service";
import { FormValidatorService } from "./services/form-validator.service";
import { ProductSelection } from "./services/product-selection.service";
import { NestedObject } from "./services/nestedObject.service";
import { AuthTokenService } from "./services/token/auth.token.service";
import { InjectScriptService } from "./services/inject-script.service";
import { ElementScrollService } from "./services/element-scroll.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

// components
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderNewComponent } from "./components/header-new/header-new.component";
import { MainComponent } from "./components/main/main.component";

import { environment } from "../environments/environment";
import { OtpService } from "app/services/otp.service";
import { ApiOtpService } from "app/services/api/api.otp.service";
import { InterceptorErrorSrv } from "app/services/token/interceptor.errors.service";
import { ApiIberpayService } from "./services/api/api.iberpay.service";
import { BySideCarService } from "./services/bysidecar.service";

// monitoring
import {
  HttpTracingInterceptor,
  MONITORING_CONFIG,
  MonitoringModule,
} from "@evobanco/arq-otel-ng-monitoring-legacy";
import { MocksInterceptor } from "./mocks/mock.interceptor";
import { LegalConditionComponent } from './components/legal-condition/legal-condition.component';

const ENV_MONITORING_CONFIG = environment.monitoringConfig;

if (environment.production) {
  enableProdMode();
}

@NgModule({
  declarations: [AppComponent, MainComponent],
  // only components, directives and pipes
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RegisterModule,
    ProfileModule,
    SharedModule,
    MonitoringModule,
  ], // only ngModules
  providers: [
    NavigationService,
    ErrorService,
    HeaderService,
    RecoveryService,
    SpecificAnalyticsService,
    AnalyticsService,
    DeviceService,
    AuthGuard,
    AuthService,
    LoginService,
    ApiLoginService,
    HelperService,
    ApiOtpService,
    OtpService,
    FormValidatorService,
    ApiRecoveryService,
    ProductSelection,
    NestedObject,
    AuthTokenService,
    InterceptorErrorSrv,
    InjectScriptService,
    Title,
    ElementScrollService,
    ApiIberpayService,
    ApiBySideCarService,
    BySideCarService,
    { provide: MONITORING_CONFIG, useValue: ENV_MONITORING_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTracingInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: MocksInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
