import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VideoId, VerifiEID } from '../../collections/video';
import { ErrorService } from '../error.service';
import { HeaderService } from '../headers.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { NestedObject } from '../nestedObject.service';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiVideoeidService {
  sessionVideoId: VideoId;
  headerSrv: HeaderService;
  options = {};
  body = {};
  constructor(private http: HttpClient,
    private errService: ErrorService,
    @Inject(HeaderService) headerSrv: HeaderService,
    public nestedObject: NestedObject) {
    this.headerSrv = headerSrv;
    this.sessionVideoId = this.getSessionVideo();
    this.options = { withCredentials: true, observe: 'response' };
  };

  public getVideo(id: string, videoType: string) {
    if (this.sessionVideoId.id === id && this.sessionVideoId.status.toLowerCase() !== 'completed') {
      return Observable.of(this.sessionVideoId);
    }
    return this.http
      .get(`${API_URL}/fullonline/terceros/electronicId/${videoType.toLowerCase()}/${id}`, this.options)
      .map(response => {
        const video = response['body'];
        //Se devuelve el tiempo en milisegundos directamente recibido de la respuesta de EID
        //Sin pasar por formatDate y evitar errores en las conversiones a dos digitos
        //video.document.expiryDate = this.formatDate(video.document.expiryDate);
        //video.document.subject.birthDate = this.formatDate(video.document.subject.birthDate);
        video.document.expiryDate = video.document.expiryDateTS;
        video.document.subject.birthDate = video.document.subject.birthDateTS;
        this.setSessionVideo(video);
        return video;
      });
  };

  private formatDate(date): string {
  
    return new Date(date.substr(0, 2), date.substr(2, 2) - 1, date.substr(4, 2)).getTime().toString();
    
  };

  public verificationEID(video: VerifiEID) {
    // Este servicio sirve para el dni y pasaporte nacionales/extranjeros
    return this.http
      .post(`${API_URL}/fullonline/terceros/electronicId/verification_requests`, video, this.options)
      .map(response => {
        const video = response['body'];
      });
  };

  public foreignVerificationEID(video: VerifiEID, videoScan: string) {
    // Este servicio sirve para el NIE verde comunitario
    return this.http
      .post(`${API_URL}/cuenta-inteligente/verify?videoId=${video.videoId}&videoScan=${videoScan}`,
       this.options)
      .map(response => {
        const video = response['body'];
      });
  };
  // /cuenta-inteligente/verify?videoId=9b6c87e6-e26b-42dc-b8dc-b4a05fc324ca&videoScan=5a73725e-343c-4021-a1cf-2159a41955fe
  // El token lo agrega automaticamente el interceptor
  // el videoScan es el paramid algo del NIE verde, revisar con Agustin
  
  // http://apiuat.evobanco.com:8443/evobanco/onboarding/multi-ci/v1/cuenta-inteligente/verify?videoId=9b6c87e6-e26b-42dc-b8dc-b4a05fc324ca&videoScan=5a73725e-343c-4021-a1cf-2159a41955fe 

  private setSessionVideo(videoId: VideoId) {
    const previousVideo = JSON.parse(sessionStorage.getItem('currentVideoId')) || {},
          currentVideo = this.nestedObject.nestedObjectAssign({}, previousVideo, videoId);

    sessionStorage.setItem('currentVideoId', JSON.stringify(currentVideo) || null);
  };

  public getSessionVideo(): VideoId {
    const videoId = JSON.parse(sessionStorage.getItem('currentVideoId'));
    if (videoId && videoId.id) { return videoId };
    return new VideoId();
  };
  
  public getAuthorizationEID(videoType: string){
    this.options = { withCredentials: true, observe: 'response'}
    this.body = {};
    const url = `${API_URL}/fullonline/terceros/electronicId/${videoType.toLowerCase()}.request`;
    return this.http
    .post(url, this.body, this.options)
    .map(response => {return response});
  }

  public getQrImage(body){
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'image/png',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    const addedHeaders = {headers: new HttpHeaders(headerDict)};

    return this.http
    .post(`${API_URL}/evobanco/v1/utilities/qr`, body, addedHeaders) 
    .map(response => {return response});
  }
  public getDynamicQrImage(body){
    const headerDict = {
      "Content-Type": "application/json",
    }
    
    const optionHeaders = {headers: new HttpHeaders(headerDict),withCredentials: true};
    let url = `${API_URL}/evobanco/boarding/fullonline/v1/createlink`
    return this.http
    .post(url, body) 
    .map(response => {
      return response});
  }

}
