<loading [showMe]="loading"></loading>
<!-- <mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone> -->

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>

<modal [modalTitle]="'Iphone users'" [blocking]="false" [modalId]="modalInfoEid" class="modal">
  <div class="mdl-cont-user-info">
    <div class="row-ic-bombilla">
      <p class="ic_bombilla_encendida"></p>
    </div>
    <div class="second-info-row">
      <p class="antes-de-continuar-e">
        Antes de continuar es importante que tengas esto en cuenta:
      </p>
    </div>
    <div class="options-info-1">
      <div class="marco">
        <p class="ic_bombilla_encendida"></p>
      </div>
      <div class="texto">
        <p class="estandar">
          En el proceso tendrás que
          <span class="negrita"> hacerte un selfie, </span> así que intenta
          estar en un sitio iluminado y evita que nada tape tu rostro.
        </p>
      </div>
    </div>
    <div class="options-info-2">
      <div class="marco">
        <p class="ic_webcam"></p>
      </div>
      <div class="texto">
        <p class="estandar negrita">
          Va a saltarte un aviso pidiéndote permiso para acceder a tu cámara.
          <span class="rosa">¡Acéptalo para poder continuar!</span>
        </p>
      </div>
    </div>
    <div class="options-info-3">
      <div class="marco">
        <p class="ic_persona_correr"></p>
      </div>
      <div class="texto">
        <p class="estandar">
          <span class="negrita">{{standardModalTextNIE}}</span>
          si aún no lo tienes en la mano ¡que empezamos!
        </p>
      </div>
    </div>
    <div class="options-button equal-buttons">
      <div id="video" class="access btn-eid" (click)="redirect()" name="button2">
        <a>
          <span class="text-button">Entendido</span>
        </a>
      </div>
    </div>
  </div>
</modal>

<div class="verify-identity-video" *ngIf="!isSecondSigner && !isYoungAccount else isOthers">
  <div class="verify-identity-video__container">
    <h2 class="verify-identity-video__title_2" *ngIf="!persona.datosPersonales.codigoIdExterno === '53'">Identificate con un <span class="easy-color">selfie</span></h2>
    <h2 class="verify-identity-video__title_2" *ngIf="persona.datosPersonales.codigoIdExterno === '53'">Videoidentificación</h2>
    <p class="verify-identity-video__description_2">{{subtitleText}}</p>
    <div class="verify-identity-video__options">
      <div class="verify-identity-video__option_2 highlighted">

        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--center-content verify-identity-video__option-item_2_--hide-mobile"
        >
          <div
            class="verify-identity-video__option-pre verify-identity-video__option-pre-icon_2"
          >
            <div
              class="verify-identity-video__option-icon_2 ic_movil_persona_marco"
            ></div>
          </div>
          <div
            class="verify-identity-video__option-title verify-identity-video__option-content_2"
          >
            Usa la <strong>App de EVO</strong>
          </div>
        </div>
        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>

          <div class="verify-identity-video__option-content_2">
           
            <strong>Escanea el QR con tu móvil.</strong>
            <br />
            <div class="verify-identity-video__qr">
              <!-- spinner -->
              <div class="loading-ci" [hidden]="imageQr">
                <svg class="spinner">
                  <circle cx="40" cy="40" r="36"></circle>
                </svg>
              </div>
              <!-- spinner end -->
              <img  [hidden]="!imageQr" [src]="imageQr"/></div>
          </div>
        </div>
        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
          <div class="verify-identity-video__option-content_2">
            <strong>Descarga la App de EVO.</strong> Si ya la tienes, accede.
            <br />
            <div class="verify-identity-video__store-content">
              <div class="verify-identity-video__store-icon ic_playstore"></div>
              <div
                class="verify-identity-video__store-icon ic_apple_appstore"
              ></div>
              <div
                class="verify-identity-video__store-icon ic_huawey_appstore"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
          <div class="verify-identity-video__option-content_2">
            Y <strong>sigue los pasos</strong> de forma fácil y rápida.
          </div>
        </div>

        <div
          class="verify-identity-video__item-mobile verify-identity-video__icon-mobile ic_movil_persona_marco"
        ></div>
        <div
          class="verify-identity-video__item-mobile verify-identity-video__title-mobile"
        >
          Usa la <strong>App de EVO</strong>
        </div>
        <div
          class="verify-identity-video__item-mobile verify-identity-video__desc-mobile"
        >
          <strong>Descarga la App de EVO</strong> o, si ya la tienes,
          accede y sigue los pasos.
        </div>

        <div
          class="verify-identity-video__item-mobile verify-identity-video__store-mobile"
        >
          <div class="verify-identity-video__store-icon ic_playstore"></div>
          <div
            class="verify-identity-video__store-icon ic_apple_appstore"
          ></div>
          <div
            class="verify-identity-video__store-icon ic_huawey_appstore"
          ></div>
        </div>

        <div
          class="verify-identity-video__item-mobile verify-identity-video__action-mobile"
        >
          <a [href]="dynamicQr">
          <button
            class="btn-common btn-common_fix_bold verify-identity-video__action-mobile-button">
            Continuar en la app
          </button>
          </a>
        </div>
        <div class="corner-start"></div>
      </div>

      <div class="verify-identity-video__option">
        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--center-content verify-identity-video__option-item--action-title verify-identity-video__option-item--hide-mobile"
        >
          <div
            class="verify-identity-video__option-pre verify-identity-video__option-pre-icon_2"
          >
            <div
              class="verify-identity-video__option-icon_2 ic_ordenador_webcam_persona"
            ></div>
          </div>
          <div
            class="verify-identity-video__option-title verify-identity-video__option-content_2" *ngIf="!isSecondSigner && !isYoungAccount"
          >
            También puedes continuar aquí, <strong>en la web de EVO</strong>
          </div>
        </div>

        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
          <div class="verify-identity-video__option-content_2">
            Acepta el <strong>permiso de la cámara</strong> para que el sistema
            pueda reconocerte.
          </div>
        </div>

        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
            <div class="verify-identity-video__option-content_2" *ngIf="!noPhotoIdCard">
            Ten tu <strong>DNI o Tarjeta de Residencia a mano</strong> con foto y en vigor.
            </div>
          <div class="verify-identity-video__option-content_2" *ngIf="noPhotoIdCard">
            Ten el <strong>DNI de tu país de origen o tu pasaporte</strong> 
            y tu <strong>Certificado de Registro de Ciudadano de la Unión Europea</strong> a mano.
          </div>
        </div>

        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
          <div class="verify-identity-video__option-content_2">
            Te recomendamos
            <strong
              >estar en un lugar bien iluminado y que nada tape tu
              rostro.</strong
            >
          </div>
        </div>

        <div
          class="verify-identity-video__option-item_2 verify-identity-video__option-item_2--center-content verify-identity-video__option-item--action verify-identity-video__option-item--hide-mobile"
        >
          <div class="verify-identity-video__option-pre">
            <div class="verify-identity-video__option-bullet"></div>
          </div>
          <div class="verify-identity-video__option-content_2">
            <button (click)="showModalEid()"
              class="btn-common btn-common_fix_bold verify-identity-video__option-action-button"
            >
              Continuar aquí
            </button>
          </div>
        </div>

        <div
          class="verify-identity-video__item-mobile verify-identity-video__icon-mobile ic_ordenador_webcam_persona"
        ></div>
        <div
          class="verify-identity-video__item-mobile verify-identity-video__title-mobile" *ngIf="!isSecondSigner && !isYoungAccount"
        >
          También puedes continuar aquí, <strong>en la web de EVO.</strong>
        </div>
        <div
          class="verify-identity-video__item-mobile verify-identity-video__desc-mobile"
        >
          <strong>Acepta el permiso de cámara </strong> y sigue las
          instrucciones.
        </div>

        <div
          class="verify-identity-video__item-mobile verify-identity-video__action-mobile"
        >
          <button (click)="showModalEid()"
            class="btn-common btn-common_fix_bold verify-identity-video__action-mobile-button"
          >
            Continuar aquí
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #isOthers>
  <div class="verify-identity-video my-60">
    <div class="verify-identity-video__container">
      <div class="verify-identity-video__options">
        <div class="verify-identity-video__option">
          <div class="left-side">
            <div class="image-top">
              <img src="../../../../assets/images/ic-web-cam.svg" alt="">
            </div>
            <h2 class="verify-identity-video__title">Identifícate <br>con un selfie</h2>
            <p class="verify-identity-video__description">Para poder seguir con tu contratación <strong>necesitamos estar seguros de que eres tú.</strong> Solo necesitas:</p>
          </div>
          <div class="right-side">
            <div class="verify-identity-video__option-item verify-identity-video__option-item--center-content verify-identity-video__option-item--action-title">
              <div class="label-image">
                <p>Aceptar el <strong>permiso de cámara</strong> para que el sistema pueda reconocerte.</p>
              </div>
              <div class="verify-identity-video__option-pre verify-identity-video__option-pre-icon">
                <div class="verify-identity-video__option-icon ic_ordenador_webcam_persona">
                </div>
              </div>
            </div>

            <div class="verify-identity-video__option-item verify-identity-video__option-item--center-content verify-identity-video__option-item--action-title">
              <div class="label-image">
                <p>Tener tu <strong>DNI o Tarjeta de Residencia a mano,</strong> con una foto y en vigor.</p>
              </div>
              <div class="verify-identity-video__option-pre verify-identity-video__option-pre-icon">
                <div class="verify-identity-video__option-icon ic_ordenador_webcam_dni"></div>
              </div>
            </div>

            <div class="verify-identity-video__option-item verify-identity-video__option-item--center-content verify-identity-video__option-item--action-title">
              <div class="label-image">
                <p>Te recordamos <strong>estar en un lugar bien iluminado y que nada tape tu rostro.</strong></p>
              </div>
              <div class="verify-identity-video__option-pre verify-identity-video__option-pre-icon">
                <div class="verify-identity-video__option-icon ic_ordenador_webcam_happy"></div>
              </div>
            </div>
            <div class="verify-identity-video__option-content" style="padding-bottom: 42px">
              <button (click)="showModalEid()"
                class="btn-common btn-common_fix_bold verify-identity-video__option-action-button"
              >
                Continuar aquí
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
