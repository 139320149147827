import { Component, OnInit, Input, Output, OnChanges, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-footer-center',
  templateUrl: './footer-center.component.html',
  styleUrls: ['./footer-center.component.scss']
})

export class FooterCenterComponent implements OnInit {

  constructor() { }

  

  @Input('showCookies') showCookies: boolean;


  ngOnInit() {

  }



}
