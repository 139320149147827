export enum navigationSteps {
     'bienvenido',
     'datos-personales',
     'datos-de-contacto',
     'datos-laboral',
     'confirmacion-otp',
     'metodo-validacion',
     'identificacion-video',
     'identificacion-iban',
     'confirmacion-datos',
     'previa-firma', // *
     'proceso-firma', // *
     'contratacion-ci', // *
     'casi-lo-tenemos',
     'casi-esta',
     'ya-esta',
     'identificacion-edad',
     'confirmacion-otp-primer-paso',
     'Edad',
     'OCR',
     '"404"',
     'abandono',
     'cuenta-joven-redirect',
     'cuenta-inteligente-redirect',
     'datos-personal',
     'datos-contacto',
     'verificacion-cuenta',
     'pendiente-verificacion-transferencia',
     'previa-eid',
     'ahora-nosotros',
     'bienvenida-segundo-titular'
}
