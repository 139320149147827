import { Component, OnInit, AfterViewInit} from '@angular/core';
import { HelperService } from '../../../services/helpers.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-less-25years',
  templateUrl: './less-25years.component.html',
  styleUrls: ['./less-25years.component.scss'],
  providers: []

})
export class Less25yearsComponent implements AfterViewInit {

  loading:boolean = false;

  constructor(
    private helper: HelperService,
    public analytics: AnalyticsService,
    private authService: AuthService
  ) {}

  ngAfterViewInit() {
    document.getElementById('user-path').classList.add('hide');
  }

  gotoCI() {
    //añadido para el trigger de analytics en caso de redirección a CI MEIFO-7517
    const isRedirectedToCI = true;
    this.analytics.triggerAddToCart({'step': 'Paso 01B - Identificación edad'}, isRedirectedToCI);
    window.location.href = 'https://v2.cuentainteligente.evobanco.com/';
  }
  gotoCJ() {
    document.getElementById('user-path').classList.remove('hide');
    this.authService.pageSubmitted = 'datos-personal';
    this.helper.navigateTo('datos-personal');
  }
}
