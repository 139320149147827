<loading [showMe]="loading"></loading>
<div-show [showMe]="showError" [nameError]="nameError"></div-show>

<curtain [showMe]="showCurtain" [nameError]="nameMessage"></curtain>

<modal [modalTitle]="'Excepcion'" [blocking]='modal.blocking' [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
    <div *ngIf='link != ""' class="link accesslink" name="iban" (click)="hasInvalidFiscalObligations()">
      <span>{{link | uppercase}}</span>
    </div>
  </div>
</modal> 

<!--
<modal [modalTitle]="'Incomplete'" [blocking]='false' [modalId]='incompleteModal' class='modal'>
    <div class="container">
      <h2>¡Ups!</h2>
      <p class="invalid-text">{{modalText}}</p>
    </div>
  </modal>
-->
<!--
<modal [modalTitle]="'apellidos'" [blocking]='true' [modalId]='modalSurname' class='surname-modal'>
  <div class="container">
    <form [formGroup]='surnameOptions' (ngSubmit)="updateSurnames()">
      <h2>¿Nos echas un cable?</h2>
      <p>Nuestro sistema no consigue reconocer tus apellidos. Elige entre las siguientes opciones</p>
      <div class="group-radio">
        <div class="options">
          <div class="row" *ngFor='let surname of surnames'>
            <input type="radio" class="option" value="{{surname}}" formControlName="surnameOption" id="{{surname}}">
            <label class="button-label" for="{{surname}}">
              <p>{{surname}}</p>
            </label>
          </div>
        </div>
      </div>
      <div class="equal-buttons flex-column">
        <div class="row">
          <button class='btn-common' type="submit" name="button">
            Listo, este es el mío
          </button>
        </div>
        <div class="row">
          <div (click)='hasIncorrectSurnames()' class="access">
            <span>No es ninguno de estos</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</modal>
-->
<!-- Error mo se puede seguir con la solicitud -->
<div class="banner banner-narrow banner-narrow_red" [ngClass]='isBmi? "banner-bmi" : ""'  *ngIf="!showCurtain && showErrorObligacionesFiscales">
  <div class="banner-content">
    <div class="message-container">
      <i class="evo ico-warning-white"></i>
      <span class="banner-message">
        ¡VAYA! Lo sentimos, no podemos seguir con la solicitud online. Llámanos al 910 900 900 y te explicamos los
        motivos
      </span>
    </div>
    <!-- <i class="evo close" (click)="hideNotificationObligaciones()"></i> -->
  </div>
</div>

<!--VERIFICAR CABECERA AMPLIACIÓN-->
<!--
<div class="datos-header" *ngIf="!editable">
  <section class="c-header--bginv">
    <div class="responsive-header">
      <div class="backbutton" (click)="botonVolver()" *ngIf="btnShow && !editable">
        <i class="evo flecha"></i>
      </div>
      <div class="page-title">
        <span>{{titlePage}}</span>
      </div>
    </div>
    
    <div class="c-header__wizard" *ngIf="false">
      <p class="c-header__wizard__title">CONFIRMA TUS DATOS</p>
      <ul class="c-header__wizard__steps">
          <li class="wizard__steps__item is-completed">
          </li>
          <li class="wizard__steps__item is-completed">
          </li>
          <li class="wizard__steps__item">
          </li>
          <li class="wizard__steps__item">
          </li>
        </ul> 
    </div>
  </section>
</div> -->

<div class="datos-header" *ngIf="!showCurtain && (!editable && !isContratacion) || (!editable && isContratacion) || (editable && !isContratacion)">
  <section class="responsive-header">
    <span class="backbutton" (click)="botonVolver()" *ngIf="btnShow && !editable && !isBmi &&!isContratacionInversora">
      <i class="evo flecha" height="28px"></i>
    </span>
      <div class="page-title" [ngClass]="{'page-title' : isBmi, 'page-title-noEditable' : !isBmi, 'page-title-centered' : true}">
        <span>{{titlePage}}</span>
        <span class="info-link" *ngIf="showInfo" (click)="infoMyProfile()">
          <i class="evo ico-info-blue"></i>
        </span>
    </div>
  </section>

  <section class="c-header--bginv" *ngIf="wizard">
    <div class="c-header__wizard" >
      <p class="c-header__wizard__title">{{subtitlePage}}</p>
      <ul class="c-header__wizard__steps">
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 1}" id="wizard-1">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 2}" id="wizard-2">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 3}" id="wizard-3">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 4}" id="wizard-4">
          </li>
        </ul> 
    </div>
  </section>

  <div class="header-content">
    <div class="contain-data">
      <!--
        <i *ngIf="!isBmi && !_isMobileOrTablet" class="evo icon-datos-personales"></i>
      -->
      <p class="header-2">{{modifyData?.name}} {{modifyData?.firstName}} {{modifyData?.secondName}}</p>
      <!-- Cuando se haga click sobre esta flecha, el div de abajo "data-flex" debe hacer toggle entre "display: none;" y "display: flex;"-->
      <!--
      <span [ngClass]="showDataFlex? 'up spanflecha':'flecha'" (click)="toggleDataFlex()" *ngIf='!editable && !isBmi'>
        <i class="evo flecha"></i>
      </span> 
      -->
    </div>
    <div *ngIf= "!isBmi" class="data-flex">
      <div class="contain-data full-col" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">E-mail</span>
        <p class="contain-data__description">{{modifyData?.email | obfuscate: 'maskEmail'}}</p>
      </div>
      <div class="contain-data col1" [ngClass]="{'hidden-container' : showDataFlex}">
        <i class="evo icon-reloj-arena" *ngIf="tieneSolicitudes && !showWarningUpdateDocument"></i>
        <span class="contain-data__title">DNI o NIE</span>
        <p class="contain-data__description">{{modifyData?.documentNumber}}</p>
      </div>
      <div class="contain-data col2" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">País de residencia</span>
        <p class="contain-data__description">{{modifyData?.residenceCountry}}</p>
      </div>
      <div class="contain-data col1" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">Teléfono</span>
        <p class="contain-data__description">{{modifyData.telephone | obfuscate: 'maskPhone'}}</p>
      </div>
      <div class="contain-data col2" [ngClass]="{'hidden-container' : showDataFlex}">
        <i class="evo icon-warning" *ngIf="showWarningUpdateDocument"></i>
        <span class="contain-data__title">Caducidad DNI</span>
        <p class="contain-data__description">{{formatDate(modifyData?.expiryDate)}}</p>
      </div>
      <div class="contain-data full-col" *ngIf="btnShow && !editable" [ngClass]="{'hidden-container' : showDataFlex}">
        <div class="row" *ngIf="showInfo">
          <div class="progress-contain">
            <div class="progress-bar-container">
              <div class="progress-bar progress-bar-bottom"></div>
              <!-- Solo basta añadir la clase "progress-bar-completed para que el estilo de css complete la barra al 100% y la coloree de verde-->
              <div class="progress-bar progress-bar-top"  [ngStyle]="barraStyle"></div>
            </div>
            <span>Perfil {{porcentajeBarra}}% completado</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isBmi" class="data-flex">
      <div class="contain-data full-col" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">E-mail</span>
        <p class="contain-data__description">{{modifyData?.email| obfuscate: 'maskEmail'}}</p>
      </div>
      <div class="contain-data col2" [ngClass]="{'hidden-container' : showDataFlex}">
        <i class="evo icon-reloj-arena" *ngIf="tieneSolicitudes && !showWarningUpdateDocument"></i>
        <span class="contain-data__title">DNI o NIE</span>
        <p class="contain-data__description">{{modifyData?.documentNumber}}</p>
      </div>
      <div class="contain-data col1" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">Teléfono</span>
        <p class="contain-data__description">{{modifyData.telephone| obfuscate: 'maskPhone'}}</p>
      </div>
      <div class="contain-data col2" [ngClass]="{'hidden-container' : showDataFlex}">
        <i class="evo icon-warning" *ngIf="showWarningUpdateDocument"></i>
        <span class="contain-data__title">Caducidad DNI</span>
        <p class="contain-data__description">{{formatDate(modifyData?.expiryDate)}}</p>
      </div>
      <div class="contain-data col1" [ngClass]="{'hidden-container' : showDataFlex}">
        <span class="contain-data__title">Pais de residencia</span>
        <p class="contain-data__description">{{modifyData?.residenceCountry}}</p>
      </div>
      <div class="contain-data full-col" *ngIf="btnShow && !editable" [ngClass]="{'hidden-container' : showDataFlex}">
        <div class="row" *ngIf="showInfo">
          <div class="progress-contain">
            <div class="progress-bar-container">
              <div class="progress-bar progress-bar-bottom"></div>
              <!-- Solo basta añadir la clase "progress-bar-completed para que el estilo de css complete la barra al 100% y la coloree de verde-->
              <div class="progress-bar progress-bar-top"  [ngStyle]="barraStyle"></div>
            </div>
            <span>Perfil {{porcentajeBarra}}% completado</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showWarningUpdateDocument">
    <div class="notification-dni" (click)="subirDocumentacion()">
      <span>
        <span>
          <i class="evo ico-warning-white" style="font-size: 28px;"></i>
        </span>
        <span>
          Tenemos tu DNI/NIE caducado, actualízalo aquí
        </span>
      </span>
      <span>
        <i class="evo right-arrow"></i>
      </span>
    </div>
  </div>

  <div class="row" *ngIf="showWarningNoDocument">
    <div class="notification-dni" (click)="subirDocumentacion()">
      <span>
        <span>
          <i class="evo ico-warning-white" style="font-size: 28px;"></i>
        </span>
        <span>
          Hemos detectado que falta la copia de tu DNI/NIE, súbelo desde aquí
        </span>
      </span>
      <span>
        <i class="evo right-arrow"></i>
      </span>
    </div>
  </div>
  <!-- Validando datos -->
  <div class="row" *ngIf="showWarningValidating && !hiddeNotification">
    <div class="notification-validando">
      <span>
        <span>
          <i class="evo ic-reloj" style="font-size: 28px;"></i>
        </span>
        <span>
          Estamos validando tu petición
        </span>
      </span>
      <div class="evo close banner-close" (click)="hideNotification()"></div>
    </div>
  </div>

</div>
<!-- Cabecera en Edición datos -->
<section class="c-header" *ngIf="!showCurtain && editable">
  <div class="responsive-header top18">
    <div class="backbutton" (click)="botonVolverEditar()" *ngIf="btnShow && !isBmi"> <!-- && !isContratacion-->
      <i class="evo flecha"></i>
    </div>
    <div class="page-title">
      <span *ngIf="!showWithContract">EDITAR DATOS </span>
    </div>
  </div>
  <p class="page-subtitle" *ngIf="showWithContract">Para poder comenzar con tu solicitud {{nombreProceso}} es necesario que verifiques 
    y completes tus datos personales</p>
</section>
<!-- Datos modificados correctamente -->
<div class="banner banner-todo-ok deviceBanner" *ngIf="!showCurtain && updateDni && !hideBannerNotificacionOk">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-todo-ok"></i>
      </span>
      <span class="banner-message">
        ¡Todo en orden! Tus datos han sido modificados con éxito.
      </span>
    </div>
  </div>
</div>

<!-- DNI/NIE caducado -->
<!-- <div class="banner banner-dni-caducado" *ngIf="showWarningUpdateDocument && !hiddeBanner">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo ico-warning-white" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        {{msgDniExpired}}
      </span>
    </div>
    <div class="banner-button" (click)="subirDocumentacion()">
      Actualizar
    </div>
  </div>
</div> -->

<!-- Falta el DNI/NIE -->
<div class="banner banner-dni-caducado" *ngIf="!showCurtain && showWarningNoDocument && !hiddeBanner">
  <!--SE VE OK-->
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo ico-warning-white" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        ¡Vaya! Hemos detectado que nos falta la copia de tu documento de identificación,necesitamos que lo actualices
      </span>
    </div>
    <div class="banner-button" (click)="subirDocumentacion()">
      Actualizar
    </div>
  </div>
</div>

<!-- <div class="banner banner-error">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-warning" style="font-size: 20px;"></i>
      </span>
      <span class="banner-message">
        Los campos marcados en rojo son obligatorios
      </span>
    </div>
  </div>
</div> -->

<div class="curtain-space" *ngIf="showCurtain"></div>
<div class="confirm-personal" *ngIf="!showCurtain">
  <div class="container" *ngIf='isLoggedUser && isDataLoaded'>
     <!--
    <div (click)="editData()" class="edit-btn-tablet" *ngIf="btnShow">
      <span class="edit-btn-content">
        <i class="evo icon-edit"></i>
        <span>
          Editar
        </span>
      </span>
    </div>
   
    <div>
      <h4>DATOS PERSONALES</h4>
      <div class="row">
        <div class="contain-data">
          <p>Estado Civil</p>
          <span>{{modifyData?.personalData.civilStatus}}</span>
        </div>
        <div class="contain-data">
          <p>Regimén Económico</p>
          <span>{{modifyData?.personalData.economicSystem}}</span>
        </div>
      </div>
      <h4>CONTACTO POSTAL</h4>
      <div class="row">
        <div class="contain-data">
          <p>Dirección</p>
          <span>{{direccion}}</span>
        </div>
        <div class="contain-data">
          <p>Situacion Vivienda</p>
          <span>{{modifyData?.postalContact.situacionVivienda}}</span>
        </div>
      </div>
      <h4>CONTACTO POSTAL</h4>
      <div class="row">
        <div class="contain-data">
          <p>Estado Laboral</p>
          <span>{{modifyData?.laborData.situacionLaboral}}</span>
        </div>
        <div class="contain-data">
          <p>Tipo de Contrato</p>
          <span>{{modifyData?.laborData.tipoContrato}}</span>
        </div>
      </div>
      <div class="row">
        <div class="contain-data">
          <p>Profesion</p>
          <span>{{modifyData?.laborData.profesion}}</span>
        </div>
        <div class="contain-data">
          <p>Tipo de Actividad</p>
          <span>{{modifyData?.laborData.tipoActividad}}</span>
        </div>
      </div>
      <div class="row">
          <div class="contain-data">
            <p>Empresa Actual</p>
            <span>{{modifyData?.laborData.nombreEmpresa}}</span>
          </div>
          <div class="contain-data">
            <p>Antigüedad</p>
            <span>{{formatFecha(modifyData?.laborData.antiguedadLaboral)}}</span>
          </div>
        </div>
        <div class="row">
            <div class="contain-data">
              <p>Ingresos Mensuales</p>
              <span>{{modifyData?.laborData.ingresosMensuales}}</span>
            </div>
            <div class="contain-data">
              <p>Número de Pagas</p>
              <span>{{modifyData?.laborData.numPagas}}</span>
            </div>
          </div>
    </div>
  -->
    <div>
      <form class="form" (keydown.enter)="$event.preventDefault()" [formGroup]="modifyDataForm" *ngIf='isLoggedUser && isDataLoaded'
        novalidate>
        <!-- *ngIf='isLoggedUser && isDataLoaded'-->
        <!-- Tab content -->
        <!-- DATOS PERSONALES-->
        <section class="profile-section profile-section--radiobtn">
          <div class="row" *ngIf="showObligaciones && !editable">
            <div class="question">
              <p class="question__title">OBLIGACIONES TRIBUTARIAS</p>
              <p class="question__description">¿Tienes obligaciones tributarias fuera de España?</p>
              <div class="group-radio--circle">
                <div class="options">
                  <input type="radio" id="si" value="si" name="obligacionesFiscales" formControlName="obligacionesFiscales">
                  <label id="si-label" class="button-label" for="si">
                    <span>SÍ</span>
                  </label>
                  <input type="radio" id="no" value="no" name="obligacionesFiscales" formControlName="obligacionesFiscales">
                  <label id="no-label" class="button-label" for="no">
                    <span>NO</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="profile-section">
          <div class="section-name-container">
            <span class="section-name">
              <div *ngIf="!isBmi" class="section-name-icon">
                <i class="evo icon-usercard"></i>
                <!--<img class="section-name-icon" src="..\assets\images\datos-personales.svg" alt="datos personales">-->
              </div>
              Datos personales
            </span>
            <span (click)="editData()" class="section-name-edit mobile" *ngIf="!editable && !showErrorObligacionesFiscales">
              <i class="evo icon-edit"></i>
            </span>
            <span (click)="editData()" class="section-name-edit desktop" *ngIf="!editable && !showErrorObligacionesFiscales">
              <i class="evo icon-edit"></i>
              <strong>EDITAR</strong>
            </span>
          </div>
          <div class="row row--flex row--flex--col">
            <div class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="estadoCivil" *ngIf="modifyData?.personalData.civilStatus || editable">Estado Civil</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.personalData.civilStatus}}</span>
              <!--
              <input *ngIf='editable' type="text" id="estadoCivil" name="estadoCivil" placeholder="Estado Civil" [value]="modifyData?.personalData.civilStatus"
                autocomplete="off" [disabled]="activeFields.estadoCivil">
              -->
              <dropdown-custom *ngIf="editable" class="dropdown" id="estadoCivil" name="estadoCivil" placeholder="estadoCivil" formControlName="estadoCivil"
                [options]="civilStatus" [isUp]="false" [classAdd]="clase" [nameSelect]='true' [required]="true"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('estadoCivil').hasError('required') && modifyDataForm.get('estadoCivil').touched && editable">
                {{errors.estadoCivil}}
              </div>
            </div>
            <div *ngIf="isMarried" class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="regEconomico" *ngIf="modifyData?.personalData.economicSystem || editable">Régimen Económico</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.personalData.economicSystem}}</span>
              <dropdown-custom *ngIf='editable' class="dropdown" [classAdd]="clase" id="regEconomico" name="regEconomico"
                formControlName="regEconomico" [options]="economicSystems" [isUp]="false" [nameSelect]='true'
                [disabled]="activeFields.regEconomico" [required]="isMarried"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('regEconomico').hasError('required') && modifyDataForm.get('regEconomico').touched && editable">
                {{errors.regEconomico}}
              </div>
            </div>
          </div>
        </section>
        <!-- CONTACTO POSTAL -->
        <section class="profile-section">
          <div class="section-name-container">
            <span class="section-name">
              <div *ngIf="!isBmi" class="section-name-icon">
                <i class="evo icon-Places"></i>
                <!--<img class="section-name-icon" src="..\assets\images\location.svg" alt="datos personales">-->
              </div>
              Contacto Postal
            </span>
            <span (click)="editData()" class="section-name-edit mobile" *ngIf="btnShow && !editable && !showErrorObligacionesFiscales">
              <i class="evo icon-edit"></i>
            </span>
          </div>
          <div class="row row--flex row--flex--col" [ngClass]="{'is-editable': editable, 'row--flex--col': !editable}">
            <div class="mobile-container" *ngIf='editable' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="tipoVia" *ngIf="direccion || editable">Tipo de vía</label>
              <dropdown-custom class="dropdown" id="tipoVia" name="tipoVia" formControlName="tipoVia" [options]="vias"
                [isUp]="false"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('direccion').hasError('required') && modifyDataForm.get('direccion').touched && editable">
                {{errors.direccion}}
              </div>
            </div>
            <div class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" *ngIf='!editable' for="direccion">Dirección</label>
              <label class="section-name_title" *ngIf='editable' for="direccion">Nombre de la vía</label>
              <span class="section-name_description" *ngIf='!editable'>{{direccion | titlecase}}</span>
              <input *ngIf='editable' type="text" #direction id="direccion" formControlName="direccion" placeholder="Calle"
                [value]="modifyData?.postalContact.streetName" autocomplete="off" [disabled]="activeFields.nombreVia" [required]="true">
              <div class="error-text" *ngIf="modifyDataForm.get('direccion').hasError('required') && modifyDataForm.get('direccion').touched && editable">
                {{errors.direccion}}
              </div>
            </div>
            <div *ngIf="editable" class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="numero" *ngIf="numero || editable">Número</label>
              <p class="section-name_description" *ngIf="editable">{{numero}}</p>
              <input class="xs" *ngIf="editable" type="text" id="numero" name="numero" placeholder="Número"
                formControlName="numero" [value]="modifyData?.postalContact.streetNumber" autocomplete="off" [disabled]="activeFields.num" [required]="true">
              <div class="error-text" *ngIf="modifyDataForm.get('numero').hasError('required') && modifyDataForm.get('numero').touched && editable">
                {{errors.numero}}
              </div>
            </div>
            <div *ngIf="editable" class="mobile-container " [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="planta" *ngIf="planta || editable">Planta</label>
              <p class="section-name_description" *ngIf="editable">{{planta}}</p>
              <input class="xs" *ngIf="editable" type="text" id="planta" name="planta" placeholder="Planta"
                formControlName="planta" [value]="modifyData?.postalContact.planta" autocomplete="off" [disabled]="activeFields.planta">
              <div class="error-text" *ngIf="modifyDataForm.get('planta').hasError('required') && modifyDataForm.get('planta').touched && editable">
                {{errors.planta}}
              </div>
            </div>
            <div *ngIf="editable" class="mobile-container " [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="puerta" *ngIf="puerta || editable">Puerta</label>
              <p class="section-name_title" *ngIf="editable">{{puerta}}</p>
              <input class="xs" *ngIf="editable" type="text" id="puerta" name="puerta" placeholder="Puerta"
                formControlName="puerta" [value]="modifyData?.postalContact.puerta" autocomplete="off" [disabled]="activeFields.puerta">
              <div class="error-text" *ngIf="modifyDataForm.get('puerta').hasError('required') && modifyDataForm.get('puerta').touched && editable">
                {{errors.puerta}}
              </div>
            </div>

            <div class="mobile-container" *ngIf="!editable">
              <label class="section-name_title" for="situacionVivienda" *ngIf="modifyData?.postalContact.situacionVivienda || editable">Situación de la Vivienda</label>
              <span class="section-name_description" *ngIf="!editable">{{modifyData?.postalContact.situacionVivienda}}</span>
            </div>

            <div class="mobile-container dom-adicional" *ngIf="editable" (click)="isHiddenAditional;" [ngClass]="{'is-editable': editable}">
              <span class="dato-incompleto dom-adicional" *ngIf="hidden">
                DESPLEGAR DATOS DE DOMICILIO ADICIONALES
                <i class="evo right-arrow"></i>
              </span>
              <span class="dato-incompleto dom-adicional" *ngIf="!hidden">
                OCULTAR DATOS DE DOMICILIO ADICIONALES
                <i class="evo right-arrow"></i>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="group-toggle group-toggle--flex" [ngClass]="!hidden?'open':'close'">
              <div class="mobile-container" [ngClass]="{'hidden-container' : hidden}" [ngClass]="{'is-editable': editable}">
                <label class="section-name_title" for="portal" *ngIf="portal || editable">Portal</label>
                <p class="section-name_description">{{portal}}</p>
                <input type="text" class="xs" id="portal" name="portal" placeholder="Portal" formControlName="portal"
                  [value]="modifyData?.postalContact.portal" autocomplete="off" [disabled]="activeFields.portal">
                <div class="error-text" *ngIf="modifyDataForm.get('portal').hasError('required') && modifyDataForm.get('portal').touched && editable">
                  {{errors.portal}}
                </div>
              </div>
              <div class="mobile-container" [ngClass]="{'hidden-container' : hidden, 'is-editable': editable}">
                <label class="section-name_title" for="bloque" *ngIf="bloque || editable">Bloque</label>
                <p class="section-name_description">{{bloque}}</p>
                <input type="text" class="xs" id="bloque" placeholder="Bloque" formControlName="bloque" [value]="modifyData?.postalContact.bloque"
                  autocomplete="off" [disabled]="activeFields.bloque">
                <div class="error-text" *ngIf="modifyDataForm.get('bloque').hasError('required') && modifyDataForm.get('bloque').touched && editable">
                  {{errors.bloque}}
                </div>
              </div>
              <div class="mobile-container" [ngClass]="{'hidden-container' : hidden, 'is-editable': editable}">
                <label class="section-name_title" for="escalera" *ngIf="escalera || editable">Escalera</label>
                <p class="section-name_description">{{escalera}}</p>
                <input type="text" class="xs" id="escalera" name="escalera" placeholder="Escalera" formControlName="escalera"
                  [value]="modifyData?.postalContact.escalera" autocomplete="off" [disabled]="activeFields.escalera">
                <div class="error-text" *ngIf="modifyDataForm.get('escalera').hasError('required') && modifyDataForm.get('escalera').touched && editable">
                  {{errors.escalera}}
                </div>
              </div>
              <div class="mobile-container" [ngClass]="{'hidden-container' : hidden, 'is-editable': editable}">
                <label class="section-name_title" for="fase" *ngIf="fase || editable">Fase</label>
                <p class="section-name_description">{{fase}}</p>
                <input type="text" class="xs" id="fase" name="fase" placeholder="Fase" formControlName="fase" [value]="modifyData?.postalContact.fase"
                  autocomplete="off" [disabled]="activeFields.fase">
                <div class="error-text" *ngIf="modifyDataForm.get('fase').hasError('required') && modifyDataForm.get('fase').touched && editable">
                  {{errors.fase}}
                </div>
              </div>
              <div class="mobile-container" [ngClass]="{'hidden-container' : hidden, 'is-editable': editable}">
                <label class="section-name_title" for="parcela" *ngIf="parcela || editable">Parcela</label>
                <p class="section-name_description">{{parcela}}</p>
                <input type="text" class="md" id="parcela" name="parcela" placeholder="Parcela" formControlName="parcela"
                  [value]="modifyData?.postalContact.parcela" autocomplete="off" [disabled]="activeFields.parcela">
                <div class="error-text" *ngIf="modifyDataForm.get('parcela').hasError('required') && modifyDataForm.get('parcela').touched && editable">
                  {{errors.parcela}}
                </div>
              </div>
            </div>
          </div>
          <div class="row row--flex" *ngIf="editable" [ngClass]="{'is-editable': editable}">

            <div *ngIf="editable" class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title"  *ngIf="postalCode || editable" for="codPostal">Código
                Postal</label>
              <p *ngIf="editable">{{postalCode}}</p>
              <input class="sm" *ngIf="editable" type="text" id="codPostal"
                name="codPostal" placeholder="Código Postal" formControlName="codPostal" maxlength="5" [value]="modifyData?.postalContact.postalCode"
                autocomplete="off" [disabled]="activeFields.codPostal" number-input inputmode="numeric" pattern="[0-9]*" [required]="true">
              <div class="error-text" *ngIf="modifyDataForm.get('codPostal').hasError('required') && modifyDataForm.get('codPostal').touched && editable">
                {{errors.codPostal}}
              </div>
            </div>
            
            <div class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="residenceLocality" *ngIf="modifyData?.postalContact.residenceLocality || editable">Localidad</label>
              <p class="section-name_description" *ngIf="!editable">{{modifyData?.postalContact.residenceLocality}}</p>
              <dropdown-custom *ngIf='editable' class="dropdown" id="residenceLocality" name="residenceLocality"
                formControlName="residenceLocality" [options]="selectLocalidades" [disabled]="activeFields.residenceLocality" [required]="true"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('residenceLocality').hasError('required') && modifyDataForm.get('residenceLocality').touched && editable">
                {{errors.localidadResidencia}}
              </div>
            </div>

            <div class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="residenceProvince" *ngIf="modifyData?.postalContact.residenceProvince || editable">Provincia</label>
              <dropdown-custom *ngIf='editable' class="dropdown" id="residenceProvince" name="residenceProvince"
              formControlName="residenceProvince" [options]="provinces" [disabled]="true" [required]="true"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('residenceProvince').hasError('required') && modifyDataForm.get('residenceProvince').touched && editable">
                {{errors.residenceProvince}}
              </div>
            </div>

            <div class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title lg" for="situacionVivienda">Situacion Vivienda</label>
              <dropdown-custom class="dropdown lg" id="situacionVivienda" name="situacionVivienda" formControlName="situacionVivienda"
                [options]="situaciones" [nameSelect]='true' [isUp]="false" [required]="true"></dropdown-custom>
           
              <div class="error-text" *ngIf="modifyDataForm.get('situacionVivienda').hasError('required') && modifyDataForm.get('situacionVivienda').touched && editable">
                {{errors.situacionVivienda}}
              </div>
            </div>

            <div *ngIf="isMortgage" class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title lg" for="adquisicion">Año de Adquisicion</label>
              <input type="tel" name="adquisicion" id="adquisicion" pattern="[0-9]*" placeholder="Año de adquisición"
                [required]="isMortgage" maxlength="4" minLength='4' inputmode="numeric" number-input formControlName="adquisicion"
                autocomplete="off" (keydown.tab)="onKeydown($event)">
              <div class="error-text" *ngIf="modifyDataForm.get('adquisicion').hasError('required') && modifyDataForm.get('adquisicion').touched && editable">
                {{errors.anioAdquisicion}}
              </div>
            </div>
            <div *ngIf="isRental" class="mobile-container" [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="alquilerVivienda">Alquiler Mensual</label>
              <input type="tel" name="alquilerVivienda" id="alquilerVivienda" placeholder="Renta mensual alquiler" [required]="isRental"
                maxlength="5" number-input inputmode="numeric" pattern="[0-9]*" formControlName="alquilerVivienda"
                autocomplete="off"  (keydown.tab)="onKeydown($event)">
              <div class="error-text" *ngIf="modifyDataForm.get('alquilerVivienda').hasError('required') && modifyDataForm.get('alquilerVivienda').touched && editable">
                {{errors.alquilerVivienda}}
              </div>
            </div>
          </div>
        </section>
        <!-- DATOS LABORALES -->
        <section class="profile-section">
          <div class="section-name-container">
            <div class="section-name">
              <div *ngIf="!isBmi" class="section-name-icon">
                <i class="evo icon-briefcase"></i>
                <!--<img class="section-name-icon" src="..\assets\images\maleti.svg" alt="datos personales">-->
              </div>
              Datos Laborales
            </div>
            <span (click)="editData()" class="section-name-edit mobile" *ngIf="btnShow && !editable && !showErrorObligacionesFiscales">
              <i class="evo icon-edit"></i>
            </span>
          </div>
          <div class="row row--flex row--flex--col">

            <div  class="mobile-container" 
                  [ngClass]="{'is-editable': editable}" 
                  *ngIf='modifyData?.laborData.situacionLaboral || editable'>
              <label  class="section-name_title" 
                      for="situacionLaboral" 
                      *ngIf="modifyData?.laborData.situacionLaboral || editable">Estado Laboral</label>
              <span   class="section-name_description" 
                      *ngIf='!editable'>{{modifyData?.laborData.situacionLaboral | titlecase}}</span>
              <dropdown-custom  *ngIf='editable' 
                                class="dropdown" 
                                [classAdd]="clase" 
                                id="situacionLaboral" 
                                name="situacionLaboral" 
                                formControlName="situacionLaboral"
                                [options]="situacioneslab" 
                                [isUp]="false" 
                                [required]="true"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('situacionLaboral').hasError('required') && modifyDataForm.get('situacionLaboral').touched && editable">
                {{errors.situacionLaboral}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isCuentaAjena && ( modifyData?.laborData.nombreEmpresa || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="nombreEmpresa" *ngIf="modifyData?.laborData.nombreEmpresa || editable">Empresa Actual</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.nombreEmpresa}}</span>
              <input *ngIf='editable' class="md" type="text" name="nombreEmpresa" id="nombreEmpresa" placeholder="Empresa" formControlName="nombreEmpresa"
                [value]="modifyData?.laborData.nombreEmpresa" autocomplete="off" [disabled]="activeFields.nombreEmpresa"
                [required]='isCuentaAjena'>
              <div class="error-text" *ngIf="modifyDataForm.get('nombreEmpresa').hasError('required') && modifyDataForm.get('nombreEmpresa').touched && editable">
                {{errors.nombreEmpresa}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isCuentaAjena && ( modifyData?.laborData.tipoContrato || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="tipoContrato" *ngIf="modifyData?.laborData.tipoContrato || editable">Tipo de Contrato</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.tipoContrato | titlecase}}</span>
              <dropdown-custom *ngIf='editable' class="dropdown" [classAdd]="clase" id="tipoContrato" name="tipoContrato"
                formControlName="tipoContrato" [options]="tiposContrato" [isUp]="false" [nameSelect]='true' [required]="isCuentaAjena"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('tipoContrato').hasError('required') && modifyDataForm.get('tipoContrato').touched && editable">
                {{errors.tipoContrato}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isCuentaAjena && ( modifyData?.laborData.antiguedadLaboral || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="antiguedad" *ngIf="modifyData?.laborData.antiguedadLaboral || editable">Antigüedad laboral</label>
              <span *ngIf='!editable'>{{modifyData?.laborData.antiguedadLaboral}}</span>
              <input id="antiguedadLaboral" *ngIf='editable' date-input type="text" class="datepicker sm" name="antiguedadLaboral"
                formControlName="antiguedadLaboral" placeholder="DD/MM/YYYY" [disabled]="activeFields.antiguedad"
                
                autocomplete="off" maxlength="10" required pattern="^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$">
              <div class="error-text" *ngIf="modifyDataForm.get('antiguedadLaboral').hasError('required') && modifyDataForm.get('antiguedadLaboral').touched && editable">
                {{errors.antiguedadLaboral}}
              </div>
              <div class="error-text" *ngIf="fechaInvalida || !modifyDataForm.get('antiguedadLaboral').valid && editable">
                {{errors.antiguedadLaboralInvalida}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isAutonomo && ( modifyData?.laborData.fechaAltaAutonomo || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="autonomoFecha" *ngIf="modifyData?.laborData.fechaAltaAutonomo || editable">Fecha de alta como autónomo</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.fechaAltaAutonomo}}</span>
              <input *ngIf='editable' date-input type="tel" class="form-control sm" id="autonomoFecha" name="autonomoFecha"
                required placeholder="DD/MM/YYYY" date-input formControlName="fechaAltaAutonomo" autocomplete="off"
                maxlength="10" pattern="^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$">
              <div class="error-text" *ngIf="modifyDataForm.get('fechaAltaAutonomo').hasError('required') && modifyDataForm.get('fechaAltaAutonomo').touched && editable">
                {{errors.fechaAltaAutonomo}}
              </div>
              <div class="error-text" *ngIf="modifyDataForm.get('fechaAltaAutonomo').hasError('pattern') || !modifyDataForm.get('fechaAltaAutonomo').valid">
                {{errors.fechaAltaAutonomoInvalida}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isAutonomo && ( modifyData?.laborData.tipoActividadEconomica || editable )' [ngClass]="{'is-editable': editable}">
              <!-- TODO autocomplete -->
              <label class="section-name_title" for="tipoActividadEconomica" *ngIf="modifyData?.laborData.tipoActividadEconomica || editable">Tipo de Actividad Económica</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.tipoActividadEconomica}}</span>
              <dropdown-custom *ngIf='editable' class="dropdown" [classAdd]="clase" id="cnae" name="cnae" formControlName="cnae" [options]="cnae"
                [autocomplete]="true" [required]="isAutonomo"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('cnae').hasError('required') && modifyDataForm.get('cnae').touched && editable">
                {{errors.cnae}}
              </div>
              <!-- {{laborDetailsForm.controls.situacionLaboral.valid}} -->
            </div>

            <div class="mobile-container" *ngIf='( isCuentaAjena || isAutonomo )  && ( modifyData?.laborData.profesion || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="profesion" *ngIf="modifyData?.laborData.profesion || editable">Profesión</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.profesion}}</span>
              <dropdown-custom *ngIf='editable' class="dropdown" [classAdd]="clase" id="profesion" name="profesion" formControlName="profesion"
               [nameSelect]='true' [options]="profesiones" placeholder="Introduce las letras" 
               tooltip="Introduce las letras de la profesión para seleccionar entre las distintas opciones"
               [autocomplete]="true" [required]="isCuentaAjena || isAutonomo" maxHeight="300px"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('profesion').hasError('required') && modifyDataForm.get('profesion').touched && editable">
                {{errors.profesion}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isCuentaAjena && ( modifyData?.laborData.ingresosMensuales || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="ingresosMensuales" *ngIf="modifyData?.laborData.ingresosMensuales || editable">Ingresos Mensuales</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.ingresosMensuales}}</span>
              <div class="inptcoin">
                <input *ngIf='editable' class="sm" type="text" name="ingresosMensuales" id="ingresosMensuales" placeholder="Ingresos Mensuales Netos"
                  formControlName="ingresosMensuales" [value]="modifyData?.laborData.ingresosMensuales" autocomplete="off"
                  [disabled]="activeFields.ingresosMensuales" required number-input maxlength="6" inputmode="numeric"
                  pattern="\d*">
                  <span class="inptcoin__type" *ngIf='editable' [ngClass]="{'is-error': modifyDataForm.get('ingresosMensuales').hasError('required') && modifyDataForm.get('ingresosMensuales').touched && editable}">€</span>
              </div>
              <div class="error-text" *ngIf="modifyDataForm.get('ingresosMensuales').hasError('required') && modifyDataForm.get('ingresosMensuales').touched && editable">
                {{errors.ingresosMensuales}}
              </div>

            </div>

            <div class="mobile-container"  *ngIf='( isCuentaAjena || isAutonomo ) && ( modifyData?.laborData.tipoActividad || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="tipoActividad" *ngIf="modifyData?.laborData.tipoActividad || editable">Tipo de Actividad profesional</label>
              <span class="section-name_description"  *ngIf='!editable'>{{modifyData?.laborData.tipoActividad}}</span>
              <!--
              <input *ngIf='editable' type="text" name="tipoActividad" id="tipoActividad" placeholder="Tipo de Actividad" formControlName="tipoActividad" [value]="modifyData?.laborData.tipoActividad"
              autocomplete="off" [disabled]="activeFields.tipoActividad"
              error-tooltip [tooltip]="errService.desktopErrors(modifyDataForm.controls.tipoActividad, 'tipoActividad')">
              -->
              <dropdown-custom *ngIf='editable' class="dropdown" [classAdd]="clase" id="tipoActividad" name="tipoActividad" [autocomplete]="true"
                 formControlName="tipoActividad" [options]="tipoActividadProfesional" [isUp]="false" [nameSelect]='true' [required]="isCuentaAjena || isAutonomo"
                 placeholder="Introduce el código CNO o las letras" 
                 tooltip="Introduce los primeros dígitos del código CNO o las letras de la actividad económica para seleccionar entre las distintas opciones"
                 maxHeight="300px"></dropdown-custom>
              <div class="error-text" *ngIf="modifyDataForm.get('tipoActividad').value === 'DESCONOCIDO' && modifyDataForm.get('tipoActividad').touched && editable">
                {{errors.tipoActividad}}
              </div>
            </div>

            <div class="mobile-container" *ngIf='isCuentaAjena && ( modifyData?.laborData.numeroPagas || editable )' [ngClass]="{'is-editable': editable}">
              <label class="section-name_title" for="numeroPagas" *ngIf="modifyData?.laborData.numeroPagas || editable">Número de Pagas</label>
              <span class="section-name_description" *ngIf='!editable'>{{modifyData?.laborData.numeroPagas}}</span>
              <input *ngIf='editable' class="xs"  type="text" name="numeroPagas" id="numeroPagas" placeholder="Número de Pagas"
                formControlName="numeroPagas" [value]="modifyData?.laborData.numeroPagas" autocomplete="off" [disabled]="activeFields.numeroPagas"
                number-input pattern="[0-9]*" maxlength="2" required>
              <div class="error-text" *ngIf="modifyDataForm.get('numeroPagas').hasError('required') && modifyDataForm.get('numeroPagas').touched && editable">
                {{errors.numeroPagas}}
              </div>
            </div>

          </div>
        </section>
        <!-- END DATOS LABORALES -->
        <div class="btn-group_fix" *ngIf='!editable && btnConfirmar'>
          <button type="button" class="btn-common btn-common_fix btn-common_fix_bold" (click)="onSubmit()">CONFIRMAR</button>
        </div>
        <div class="btn-group_fix" *ngIf='editable'>
          <!--<button type="button" class="btn-common btn-common_fix_bold" (click)="noEditable()">NO MODIFICAR</button>-->
          <button type="button" class="btn-common btn-common_fix btn-common_fix_bold" [disabled]="btnModificar" (click)="onSubmit()">Guardar</button>
        </div>

      </form>
    </div>
  </div>
</div>