import { TipoVia } from "../collections/tipo-via";

export var VIAS: TipoVia[] = [
  { value: "AC", name: "ACEQUIA" },
  { value: "AD", name: "ALDEA" },
  { value: "AG", name: "AGREGADO" },
  { value: "AL", name: "ALAMEDA" },
  { value: "AP", name: "APARTAMENTO" },
  { value: "AR", name: "AREA,ARRABAL" },
  { value: "AT", name: "APTDO. CORREOS" },
  { value: "AU", name: "AUTOMOVIL" },
  { value: "AV", name: "AVENIDA" },
  { value: "AY", name: "ARROYO" },
  { value: "BA", name: "BARCO,NAVE" },
  { value: "BD", name: "BARRIADA" },
  { value: "BJ", name: "BAJADA" },
  { value: "BL", name: "BLOQUE" },
  { value: "BO", name: "BARRIO" },
  { value: "BR", name: "BARRANCO" },
  { value: "BL", name: "BULEVAR" },
  { value: "CA", name: "CARRIL" },
  { value: "CD", name: "CORREGIDOR" },
  { value: "CG", name: "COLEGIO" },
  { value: "CH", name: "CHALET" },
  { value: "CI", name: "CORTIJO" },
  { value: "CJ", name: "CALLEJA/JON" },
  { value: "CL", name: "CALLE" },
  { value: "CM", name: "CAMINO" },
  { value: "CN", name: "CONJUNTO" },
  { value: "CO", name: "COLONIA" },
  { value: "CP", name: "CAMPA" },
  { value: "CR", name: "CARRETERA" },
  { value: "CS", name: "CASERIO" },
  { value: "CT", name: "CUESTA" },
  { value: "CV", name: "CUEVA" },
  { value: "DP", name: "DIPUTACION" },
  { value: "DS", name: "DISEMINADOR" },
  { value: "ED", name: "EDIFICIO" },
  { value: "EJ", name: "EXTRANJERO" },
  { value: "EM", name: "EXTRAMUROS" },
  { value: "EN", name: "ENTRADA" },
  { value: "ER", name: "EXTRARRADIO" },
  { value: "ES", name: "ESCALINATA" },
  { value: "EX", name: "EXPLANADA" },
  { value: "FC", name: "FERROCARRIL" },
  { value: "GA", name: "GALERIA" },
  { value: "GL", name: "GLORIETA" },
  { value: "GR", name: "GRUPO" },
  { value: "GV", name: "GRAN VIA" },
  { value: "HT", name: "HUERTA" },
  { value: "JR", name: "JARDINES" },
  { value: "KM", name: "KILOMETRO" },
  { value: "LD", name: "LADO" },
  { value: "LG", name: "LUGAR" },
  { value: "MC", name: "MERCADO" },
  { value: "ML", name: "MUELLE" },
  { value: "MN", name: "MUNICIPIO" },
  { value: "MS", name: "MASIA" },
  { value: "MT", name: "MONTE" },
  { value: "MZ", name: "MANZANA" },
  { value: "PA", name: "PARAJE" },
  { value: "PB", name: "POBLADO" },
  { value: "PC", name: "PARCELA" },
  { value: "PD", name: "PARTIDA" },
  { value: "PE", name: "PARQUE" },
  { value: "PG", name: "POLIGONO" },
  { value: "BL", name: "BULEVAR" },
  { value: "PJ", name: "PASAJE" },
  { value: "PL", name: "PLAZUELA" },
  { value: "PQ", name: "PARROQUIA" },
  { value: "PR", name: "PROLONGACION" },
  { value: "PS", name: "PASEO" },
  { value: "PT", name: "PUENTE" },
  { value: "PU", name: "PUERTA" },
  { value: "PZ", name: "PLAZA" },
  { value: "QT", name: "QUINTA" },
  { value: "RB", name: "RAMBLA" },
  { value: "RC", name: "RINCON" },
  { value: "RD", name: "RONDA" },
  { value: "RM", name: "RAMAL" },
  { value: "RP", name: "RAMPA" },
  { value: "RR", name: "RIERA" },
  { value: "RS", name: "RESIDENCIAL" },
  { value: "RU", name: "RUA" },
  { value: "SA", name: "SALIDA" },
  { value: "SB", name: "SUBIDA" },
  { value: "SD", name: "SENDA,SENDERO" },
  { value: "SL", name: "SOLAR,SALON" },
  { value: "TN", name: "TERRENOS" },
  { value: "TO", name: "TORRENTE" },
  { value: "TR", name: "TRAVESIA" },
  { value: "UR", name: "URBANIZACION" },
  { value: "VI", name: "VIA" },
  { value: "VP", name: "VIA PUBLICA" },
  { value: "VV", name: "VIVIENDAS" },
];
