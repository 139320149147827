import { Component, HostListener, OnInit, OnDestroy, Input , Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Persona } from '../../../collections/persona';
import { Otp } from '../../../collections/otp';
import { ValidateOtp } from '../../../collections/validate.otp';
import { Register } from '../../../collections/register';
import { ERRORES } from '../../../resources/errors.model';
import { OtpService } from '../../../services/otp.service';
import { ApiOtpService } from '../../../services/api/api.otp.service';
import { AuthService } from '../../../services/auth.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { DeviceService } from '../../../services/device.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Observable } from 'rxjs';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { environment } from 'environments/environment';
import { Modal } from '../../../collections/modal';
import { LoginService } from 'app/services/login.service';
import { Subscription } from 'rxjs/Subscription';
import { InjectScriptService } from '../../../services/inject-script.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'otp-details-profile',
  templateUrl: './otp-details-profile.component.html',
  styleUrls: ['./otp-details-profile.component.scss'],
  providers : [ FormValidatorService,
                ModalService,
                DeviceService ]
})
export class OtpDetailsProfileComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  otpDetailsForm: FormGroup;
  loginUser: FormGroup;
  modal = new Modal();
  alreadyClientModal = 'already-client';
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg = 'Lo sentimos, no podemos seguir con la solicitud online. Llámanos al ' + this.telephoneMsg + 'y te explicamos los motivos.';
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  codeError = 'Parece que hay un error. Introduce el código que te hemos enviado por SMS.';
  timeOut = 'Parece que has tardado demasiado tiempo en introducir el código de confirmación de tu número de teléfono. ' +
  'Envía de nuevo un SMS a tu móvil e introduce el nuevo código para continuar con la contratación.';
  maxMessages =  'Parece que hay un problema con el envío del código. Comprueba que el teléfono que nos has proporcionado es el correcto e inténtalo más tarde a través del email que recibirás en tu correo electrónico.';
  errorBT = 'Parece que hay un problema con el envío del código. Inténtalo de nuevo pasados unos minutos.';
  errors = ERRORES;
  mobileErrors = [];
  hidephone = false;
  otpId = '';
  phoneRegExp = '^[6-7][0-9]{8}|8{9}';
  phoneNumber: number = null;
  temporalMobilePhone = null;
  otpSentCount: number = 0;
  _paramsSMS: Otp;
  subscription: Subscription;
  private passStr = '';
  errMsg;
  public firstTouch: boolean = true;
  public firstTouchPrevented: boolean = true;
  public isYoungAccount: boolean = false;

  @Input()
  set paramsSMS(value:Otp) {
    this._paramsSMS = value;
  }

  get paramsSMS():Otp {
    return this._paramsSMS;
  }

  @Input('isLogged') isLogged;
  @Input('maxlengthOTP') maxlengthOTP = null;
  @Input('allowEditPhone') allowEditPhone = true;
  @Input('ofuscatePhone') ofuscatePhone = false;
  @Output('eventSendCode') eventSendCode = new EventEmitter();
  @Output('eventResendSMS') eventResendSMS = new EventEmitter();
  @Output('eventSendSMS') eventSendSMS = new EventEmitter();

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    $event.returnValue = '¿Estás seguro?'
    return $event.returnValue;
  }
  constructor(private fb: FormBuilder,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    private authService: AuthService,
    public modService: ModalService,
    private otpService: OtpService,
    public deviceService: DeviceService,
    public analytics: AnalyticsService,
    private helpers: HelperService,
    private loginService: LoginService,
    private headerSrv: HeaderService,
    private inject: InjectScriptService) {
      this.createForm();
      this.createFormLogin();
    }

  ngOnInit() {
    const persona = JSON.parse(sessionStorage.getItem("userInfo"));
    this.phoneNumber = persona.datosPersonales.telefonoMovil,
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    this.otpSentCount = 0;
    this.subscription = this.otpService.obsModal.subscribe((data) => {
      setTimeout(() => {
        this.modService.open(this.modal.name, data);
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToMap() {
    window.open('https://www.evobanco.com/donde-estamos/');
  }

  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');

    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };

    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;

    this.loading = true;

    const tag = 'GTM-TFMGJVG';
    this.inject.addScriptGoogle('script', tag);
    //this.inject.addScriptGoogle('noscript', tag);
    this.authService.login(login).subscribe(data => {
      this.helpers.navigateTo('perfil');
      this.modService.close(this.alreadyClientModal);
      this.analytics.triggerProfileFormData('Log in', 'Envio OK');
      }, error => {
        this.analytics.triggerProfileFormData('Log in', 'Envio KO: 500 server error');
        this.loading = false;
        this.errMsg = `Parece que hay algún error, comprueba que has introducido
        correctamente los datos. Recuerda que por seguridad,
        tres errores consecutivos en la contraseña bloquearán el acceso`;
        if (passInput.value.length === 6) {
          this.helpers.updateFieldValue(this.loginUser, 'pass', "");
        }
      });
  }

  setPhoneEncoded(phone) {
    if(this.ofuscatePhone) {
      return "*****"  +  phone.substr(5);
    }

    return phone;
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.get('user').valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass',[Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  onSubmit() {
    Object.keys(this.otpDetailsForm.controls).forEach(key => {
      this.otpDetailsForm.get(key).markAsTouched();
    });
      if (!this.otpDetailsForm.valid) {
        window.scrollTo(0, 0);

        this.modal = Object.assign({
          title : '¡Ups!',
          withImage : true,
          blocking : false,
          message : this.incompleteMsg
        })

        this.modService.open(this.modal.name)
        return false;
      }
  }
  setClassKeyPhone() {
    // !otpDetailsForm.get('smsphone').valid && !otpDetailsForm.get('smsphone').untouched ? 'error' : 'key'
    if(this.otpDetailsForm.get('smsphone').valid) {
      return 'key';
    } else {
      return 'error';
    }
  }
  setClassCleanPhone(_keyphone) {
    // !otpDetailsForm.get('smsphone').valid && !otpDetailsForm.get('smsphone').untouched ? 'error' : 'clean'
    // if(this.errService.desktopErrors(this.otpDetailsForm.get('smsphone'), 'smsphone') != ''){
    //   return 'error';
    // }
    if (_keyphone.value.length < 1) {
      return '';
    } else {
      if (this.otpDetailsForm.get('smsphone').valid) {
        return 'clean';
      } else {
        return 'error';
      }
    }
  }
  createForm() {
    this.otpDetailsForm = this.fb.group({
      smsphone: ['', [Validators.required, Validators.pattern('^[0-9]+'), Validators.minLength(6)]],
      mobilePhone: ['', [Validators.required, Validators.pattern(this.phoneRegExp) ]]
    });

    this.otpDetailsForm.valueChanges.subscribe(otpForm => {
      this.errService.mobileErrors(this.otpDetailsForm);
    });
  }

  focusIn() {
    document.getElementById('phone').focus();
  }

  get showInputPhone(){
    return this.hidephone = !this.hidephone;
  }

  hidePhone() {
    this.hidephone = !this.hidephone;
  }

  resendSMS() {
    this.eventResendSMS.emit();
  }

  sendSMS() {
    this.temporalMobilePhone = this.otpDetailsForm.get('mobilePhone').value;
    this.eventSendSMS.emit(this.temporalMobilePhone);
  }

  updatePersona() {

  }

  sendCode() {
    if (this.otpSentCount <= 3) {
      const paramsCode = this.getParamsCode()
      this.eventSendCode.emit(paramsCode);
      return
    }
    if (this.otpSentCount > 3) {
      this.modal = Object.assign({
        title : '¡Ups!',
        message : 'Parece que no puedes usar este código. Reenvíate otro SMS para continuar.',
        blocking : false,
        withImage : false
      })

      this.modService.open(this.modal.name)
      return
    }
  }

  getParamsCode() {
    return new ValidateOtp({
      code : this.helpers.returnValue(this.otpDetailsForm.get('smsphone')),
      otpId : this.otpId
    });
  }

  showDatePhones() {
    return !this.hidephone && (this.temporalMobilePhone!= null || ( typeof this.paramsSMS != 'undefined' && this.paramsSMS.telephone != null));
  }

  getPhone() {
    return this.paramsSMS.telephone;
  }

  validatePhone(e) {
    const validations = [
      Validators.minLength(9),
      Validators.maxLength(9),
      Validators.pattern(this.helpers.phoneRexp)
    ]

    this.helpers.updateFieldValidation(this.otpDetailsForm, 'mobilePhone', validations);

  }

}
