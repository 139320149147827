import { Injectable } from '@angular/core';

@Injectable()
export class AuthTokenService {
  token;
  isUnauth: boolean = false;

  public getToken(): string {
    return sessionStorage.getItem('auth_token');
  }
  public isAuthenticated(): boolean {
    this.token = this.getToken();
    return !!this.token;
  }
  public setToken(token) {
    sessionStorage.setItem('auth_token', token);
  }
  public setRefreshToken(refreshToken) {
    sessionStorage.setItem('refresh_token', refreshToken);
  }
}
