<app-header></app-header>
<div class="everything-second-sign">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <!--<span class="main-icon evo casi-esta-img"></span>-->
        <h2 class="ico-clock"></h2>
        <h2>¡Casi está!</h2>
        <p class="constrain margin-top">
          En cuanto nos confirme el primer titular te enviaremos un email para
          confirmarte que está todo listo.
        </p>
        <p class="constrain">
          Podrás entonces acceder a tu área privada y recibirás la tarjeta
          inteligente.
        </p>
        <p></p>
        <!-- <p class="constrain margin-height margin-bottom">Si quieres conocer ya los detalles de la Cuenta Inteligente pincha aquí:</p> -->
      </legend>
    </div>
    <!-- <div class="row">
      <div class="mobile-container center">
        <button class='btn-common tall' (click)='goToInfo()'>
          ERES INTELIGENTE
        </button>
      </div>
    </div> -->
  </div>
</div>
