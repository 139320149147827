import {  Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'box-image',
  templateUrl: './box-image.component.html',
  styleUrls: ['./box-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoxImageComponent  {

  @Input() title: String;
  @Input() titleCss: String = 'title';
  @Input() subtitle: String;
  @Input() subtitleCss: String = 'subtitle';
  @Input() icon: any = undefined;
  @Input() classImgFile: String;
  @Input() textBox: String;
  @Input() sizeBox: String;
  @Input() showTextBox: boolean = true;
  constructor() { }
}