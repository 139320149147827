<loading [showMe]="loading"></loading>

<!-- <modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal not-adult'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal> -->

<modal [modalTitle]="'modalOld'" [modalId]='modalOld' class='modalOld'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
    <button class="btn-common letter-white btn-common_fix_bold" (click)="onClose()">
      {{txtButtonModal}}
    </button>
  </div>
</modal>

<modal [modalTitle]="'apellidos'" [blocking]='true' [modalId]='modalSurname' class='surname-modal'>
  <div class="container">
    <form [formGroup]='surnameOptions' (ngSubmit)="updateSurnames()">
      <h2>¿Nos echas un cable?</h2>
      <p>Nuestro sistema no consigue reconocer tus apellidos. Elige entre las siguientes opciones</p>
      <div class="group-radio">
        <div class="options">
          <div class="row" *ngFor='let surname of surnames'>
            <input type="radio" class="option" value="{{surname}}"
                   formControlName="surnameOption" id="{{surname}}">
            <label class="button-label" for="{{surname}}">
              <p>{{surname}}</p>
            </label>
          </div>
        </div>
      </div>
      <div class="equal-buttons flex-column">
        <div class="row">
          <button class='btn-common' type="submit" name="button">
            Listo, este es el mío
          </button>
        </div>
        <div class="row">
          <div (click)='hasIncorrectSurnames()' class="access">
            <span>No es ninguno de estos</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</modal>

<div *ngIf="!isCIAccount"
     class="contactDetails">
  <h2 *ngIf='!isDataLoaded' class='loading'>Estamos cargando tus datos...</h2>
  <div class="container-newCJDesigner">
    <div class="container-newCJDesigner__sideBar">
      <div class="container-newCJDesigner__sideBar-img">
        <img class="desktopSizeImage" src="assets/images/img-fol-cj-7.png" alt="Confirmación de datos">
        <img class="tabletSizeImage" src="assets/images/img-fol-cj-8.png" alt="Confirmación de datos">
      </div>
      <div class="container-newCJDesigner__sideBar-content">
        <div class="container-newCJDesigner__sideBar-content-paragraph">
          <img src="assets/images/icon-lock-feel/ic-avion.svg" alt="Avion">
          <h3>SEGUROS DE VIAJE</h3>
          <P>Viaja tranquilo vayas donde vayas.</P>
        </div>
      </div>
    </div>

    <div class="container-newCJDesigner__main">
      <div class="container-newCJDesigner__step-component">
        <steps-ci class="step-component"></steps-ci>
      </div>
      <hr class="screenSizeSeparator">
      <div class="container-newCJDesigner__content screenSizeError"
          [ngClass]="{'confirmationDataPage': !isLoggedUser && isDataLoaded}"
          >
        <div class="container-newCJDesigner__content-title">
          <h2>{{title}}</h2>
          <p>Asegúrate de que son correctos, si no es así, selecciona el que esté mal y modifícalo.
          </p>
          <p> Ya casi has
          terminado,
          solo te queda firmar.</p>
        </div>
        <div class="container-newCJDesigner__form"
          *ngIf='!isLoggedUser && isDataLoaded'
          >
          <form>
            <div class="container-newCJDesigner__form-row">
              <div class="container-newCJDesigner__form-input">
                <label for="nombre" class="form-label">Nombre</label>
                <input class="dropdown" type="text" id="nombre"
                name="nombre"
                placeholder="Nombre"
                [value]="confirmPersonal.name"
                [(ngModel)]="confirmPersonal.name"
                (ngModelChange)="onNameChange($event)"
                autocomplete="off">
                <div class="container-newCJDesigner__form-input-icon">
                  <svg class="editIcon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.964 1.036a3.528 3.528 0 0 0-4.995 0L.812 10.19a.458.458 0 0 0-.13.263l-.678 5.025c-.02.144.031.287.13.386A.466.466 0 0
                          0 .458 16c.02 0 .04 0 .061-.003l3.028-.41a.461.461 0 0 0-.123-.913L.997 15l.473-3.505 3.689 3.69a.451.451 0 0 0 .648 0l9.157-9.155A3.51
                          3.51 0 0 0 16 3.53c0-.944-.368-1.83-1.036-2.495zM10.147 2.16l1.537 1.537-8.356 8.357-1.537-1.537 8.356-8.357zM5.487 14.21l-1.504-1.504
                          8.356-8.356 1.503 1.503-8.356 8.357zm8.996-9.015-3.678-3.679A2.604 2.604 0 0 1 12.468.92 2.605 2.605 0 0 1 15.08 3.53c0 .618-.212 1.197-.597 1.664z"
                           fill="#000" fill-rule="nonzero" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="container-newCJDesigner__form-row">
              <div class="container-newCJDesigner__form-input">
                <label for="primerApellido" class="form-label">Primer Apellido</label>
                <input type="text" class="dropdown" id="primerApellido"
                      name="primerApellido"
                      placeholder="Primer apellido"
                      [value]="confirmPersonal.firstName"
                      (ngModelChange)="onFirstNameChange($event)"
                      [(ngModel)]="confirmPersonal.firstName"
                      autocomplete="off">
                      <div class="container-newCJDesigner__form-input-icon">
                        <svg class="editIcon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.964 1.036a3.528 3.528 0 0 0-4.995 0L.812 10.19a.458.458 0 0 0-.13.263l-.678 5.025c-.02.144.031.287.13.386A.466.466 0 0
                                                0 .458 16c.02 0 .04 0 .061-.003l3.028-.41a.461.461 0 0 0-.123-.913L.997 15l.473-3.505 3.689 3.69a.451.451 0 0 0 .648 0l9.157-9.155A3.51
                                                3.51 0 0 0 16 3.53c0-.944-.368-1.83-1.036-2.495zM10.147 2.16l1.537 1.537-8.356 8.357-1.537-1.537 8.356-8.357zM5.487 14.21l-1.504-1.504
                                                8.356-8.356 1.503 1.503-8.356 8.357zm8.996-9.015-3.678-3.679A2.604 2.604 0 0 1 12.468.92 2.605 2.605 0 0 1 15.08 3.53c0 .618-.212 1.197-.597 1.664z"
                                fill="#000" fill-rule="nonzero" />
                        </svg>
                      </div>
              </div>
              <div class="container-newCJDesigner__form-input" *ngIf="confirmPersonal.secondName !== ''">
                  <label for="segundoApellido" class="form-label">Segundo Apellido</label>
                  <input type="text" class="dropdown" id="segundoApellido"
                      name="segundoApellido"
                      placeholder="Segundo apellido"
                      [value]="confirmPersonal.secondName"
                      (ngModelChange)="onSecondNameChange($event)"
                      [(ngModel)]="confirmPersonal.secondName"
                      autocomplete="off">
                      <div class="container-newCJDesigner__form-input-icon">
                        <svg class="editIcon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.964 1.036a3.528 3.528 0 0 0-4.995 0L.812 10.19a.458.458 0 0 0-.13.263l-.678 5.025c-.02.144.031.287.13.386A.466.466 0 0
                                                0 .458 16c.02 0 .04 0 .061-.003l3.028-.41a.461.461 0 0 0-.123-.913L.997 15l.473-3.505 3.689 3.69a.451.451 0 0 0 .648 0l9.157-9.155A3.51
                                                3.51 0 0 0 16 3.53c0-.944-.368-1.83-1.036-2.495zM10.147 2.16l1.537 1.537-8.356 8.357-1.537-1.537 8.356-8.357zM5.487 14.21l-1.504-1.504
                                                8.356-8.356 1.503 1.503-8.356 8.357zm8.996-9.015-3.678-3.679A2.604 2.604 0 0 1 12.468.92 2.605 2.605 0 0 1 15.08 3.53c0 .618-.212 1.197-.597 1.664z"
                                fill="#000" fill-rule="nonzero" />
                        </svg>
                      </div>
              </div>
            </div>
            <div class="container-newCJDesigner__form-row">
              <div class="container-newCJDesigner__form-input">
                <label for="fechaCaducidad" class="form-label">Fecha de caducidad</label>
                <input date-input type="text"
                      class="dropdown"
                      name="fechaCaducidad"
                      id="fechaCaducidad"
                      placeholder="dd/mm/yyyy"
                      (change)="change(($event))"
                      [value]="formatDate(confirmPersonal.expiryDate)"
                      disabled
                      autocomplete="off" />
              </div>
              <div class="container-newCJDesigner__form-input">
                <label for="fechaNacimiento" class="form-label">Fecha de nacimiento</label>
                <input date-input type="text"
                      class="dropdown"
                      name="fechaNacimiento"
                      id="fechaNacimiento"
                      placeholder="dd/mm/yyyy"
                      (change)="change(($event))"
                      [value]="formatDate(confirmPersonal.birthDate)"
                      disabled
                      autocomplete="off" />
              </div>
            </div>
            <div class="container-newCJDesigner__form-row">
              <div class="container-newCJDesigner__form-input">
                <label for="genero" class="form-label">Género</label>
                <input type="text" id="genero"
                      class="dropdown"
                      name="genero"
                      id="genero"
                      placeholder="Género"
                      (change)="change(($event))"
                      [value]="confirmPersonal.sex"
                      disabled
                      autocomplete="off">
              </div>
              <div class="container-newCJDesigner__form-input">
                <label for="nacionalidad" class="form-label">Nacionalidad</label>
                <input type="text" id="nacionalidad"
                      class="dropdown"
                      name="nacionalidad"
                      id="nacionalidad"
                      placeholder="Nacionalidad"
                      (change)="change(($event))"
                      [value]="confirmPersonal.nationality"
                      disabled
                      autocomplete="off">
              </div>
            </div>
            <div class="container-newCJDesigner__form-row last-row">
              <div class="container-newCJDesigner__form-input input-last-row">
                <div class="return-label">
                  <p (click)="returnInit()">
                    <img src="assets/images/icon-lock-feel/ic-flecha-black.svg" alt="icon">
                    Atrás
                  </p>
                </div>
              </div>
              <div class="container-newCJDesigner__form-input input-last-row">
                <div class="btn-group">
                  <button type="button" class="btn-common btn-common_fix_bold" (click)="onSubmit()">CONFIRMAR</button>
                  </div>
              </div>
            </div>
          </form>
        </div>

        <div class="container-newCJDesigner__form"
          [ngClass]="{'completeForm': isLoggedUser && isDataLoaded}"
          *ngIf='isLoggedUser && isDataLoaded'
          >
            <form>
              <h4 class="subtitle-form">DATOS PERSONALES</h4>
              <div class="container-newCJDesigner__form-row">
                <div class="container-newCJDesigner__form-input">
                  <label for="nombre"class="form-label">Nombre</label>
                  <input type="text" id="nombre"
                        name="nombre"
                        class="dropdown"
                        placeholder="Nombre"
                        disabled
                        [value]="confirmPersonal?.name"
                        autocomplete="off">
                </div>
              </div>
              <div class="container-newCJDesigner__form-row">
              <div class="container-newCJDesigner__form-input">
                  <label for="primerApellido" class="form-label">Primer Apellido</label>
                  <input type="text"
                        class="dropdown"
                        name="primerApellido"
                        id="primerApellido"
                        placeholder="Primer Apellido"
                        disabled
                        [value]="confirmPersonal.firstName"
                        autocomplete="off">
                </div>
                <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal?.secondName !== ""'>
                  <label for="segundoApellido" class="form-label">Segundo Apellido</label>
                  <input type="text"
                        class="dropdown"
                        id="segundoApellido"
                        name="segundoApellido"
                        placeholder="Segundo Apellido"
                        disabled
                        [value]="confirmPersonal?.secondName"
                        autocomplete="off">
                </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                <div class="container-newCJDesigner__form-input">
                  <label for="documentoIdentidad" class="form-label">Documento de Identidad</label>
                  <input type="text"
                        class="dropdown"
                        name="documentoIdentidad"
                        disabled
                        placeholder="Documento de identidad"
                        [value]="confirmPersonal?.documentNumber">
                </div>
                <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal?.expiryDate'>
                  <label for="fechaCaducidad" class="form-label">Fecha de caducidad</label>
                  <input date-input type="text"
                        class="dropdown" name="date" value=""
                        placeholder="dd/mm/yyyy" disabled
                        [value]="formatDate(confirmPersonal?.expiryDate)"
                        autocomplete="off" />
                </div>
                <div class="container-newCJDesigner__form-input">
                  <label for="nacionalidad" class="form-label">Nacionalidad</label>
                  <input type="text" id="nacionalidad"
                        class="dropdown"
                        name="nacionalidad" disabled
                        placeholder="Nacionalidad"
                        [value]="confirmPersonal?.nationality"
                        autocomplete="off">
                </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="paisNacimiento" class="form-label">País de Nacimiento</label>
                    <input type="text" id="paisNacimiento"
                          class="dropdown"
                          name="paisNacimiento"
                          disabled
                          placeholder="País de nacimiento"
                          [value]="confirmPersonal?.birthCountry"
                          autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.birthProvince !== ""'>
                    <label for="provinciaNacimiento" class="form-label">Provincia de Nacimiento</label>
                    <input type="text"
                          class="dropdown"
                          name="provinciaNacimiento"
                          disabled
                          placeholder="Provincia de Nacimiento"
                          [value]="confirmPersonal?.birthProvince"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                    <div class="container-newCJDesigner__form-input">
                      <label for="localidadNacimiento" class="form-label">Localidad de Nacimiento</label>
                      <input type="text"
                            class="dropdown"
                            name="localidadNacimiento"
                            disabled
                            placeholder="Localidad de Nacimiento"
                            [value]="confirmPersonal?.birthLocation"
                            autocomplete="off">
                    </div>
                    <div class="container-newCJDesigner__form-input">
                      <label for="fechaNacimiento" class="form-label">Fecha de nacimiento</label>
                      <input date-input type="text"
                            class="dropdown"
                            name="date"
                            value=""
                            placeholder="dd/mm/yyyy" disabled
                            [value]="formatDate(confirmPersonal.birthDate)"
                            autocomplete="off" />
                    </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                    <div class="container-newCJDesigner__form-input">
                      <label for="genero" class="form-label">Género</label>
                      <input type="text"
                            class="dropdown"
                            name="genero"
                            disabled
                            placeholder="genero"
                            [value]="confirmPersonal.sex"
                            autocomplete="off">
                    </div>
                    <div class="container-newCJDesigner__form-input">
                      <label for="estadoCivil" class="form-label">Estado Civil</label>
                      <input type="text"
                            class="dropdown"
                            name="estadoCivil"
                            disabled
                            placeholder="Estado Civil"
                            [value]="confirmPersonal.civilStatus"
                            autocomplete="off">
                    </div>
              </div>
              <div class="container-newCJDesigner__form-row">

                  <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.economicSystem !== ""'>
                    <label for="regimenEconomico" class="form-label">Régimen Económico</label>
                    <input type="text"
                          class="dropdown"
                          name="regimenEconomico"
                          disabled
                          placeholder="Regimen Economico"
                          [value]="confirmPersonal.economicSystem"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <h4 class="subtitle-form">DATOS DE CONTACTO</h4>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="email" class="form-label">Correo Electrónico</label>
                    <input type="text"
                          class="dropdown"
                          name="email"
                          disabled
                          placeholder="Email"
                          [value]="confirmPersonal.contactDetails?.email"
                          autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="telephone" class="form-label">Teléfono móvil</label>
                    <input type="text"
                          class="dropdown"
                          name="telephone"
                          disabled
                          placeholder="XXXXXXXXX"
                          [value]="confirmPersonal.contactDetails?.telephone"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                  <label for="paisResidencia" class="form-label">País de Residencia</label>
                  <input type="text"
                        class="dropdown"
                        name="paisResidencia"
                        disabled
                        placeholder="País de Residencia"
                        [value]="confirmPersonal?.residenceCountry"
                        autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="provinciaResidencia" class="form-label">Provincia de Residencia</label>
                    <input type="text"
                          class="dropdown"
                          name="provinciaResidencia"
                          disabled
                          placeholder="Provincia de Residencia"
                          [value]="confirmPersonal?.residenceProvince"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                      <div class="container-newCJDesigner__form-input">
                        <label for="ciudadResidencia" class="form-label">Ciudad de Residencia</label>
                        <input type="text"
                              class="dropdown"
                              name="ciudadResidencia"
                              disabled
                              placeholder="Ciudad de Residencia"
                              [value]="confirmPersonal?.residenceLocation"
                              autocomplete="off">

                    </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="tipoVia" class="form-label">Tipo de vía</label>
                    <input type="text"
                          class="dropdown"
                          name="tipoVia"
                          disabled
                          placeholder="Tipo de vía"
                          [value]="confirmPersonal.contactDetails?.streetType"
                          autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="nombreVia" class="form-label">Nombre vía</label>
                    <input type="text"
                          class="dropdown"
                          name="nombreVia"
                          disabled
                          placeholder="Nombre vía"
                          [value]="confirmPersonal.contactDetails?.streetName"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                    <div class="container-newCJDesigner__form-row-group">
                      <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.streetNumber !== ""'>
                        <label for="numero" class="form-label">Nº</label>
                        <input type="text"
                              class="dropdown"
                              name="numero"
                              disabled
                              placeholder="N°"
                              [value]="confirmPersonal.contactDetails?.streetNumber"
                              autocomplete="off">
                      </div>
                      <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.piso !== ""'>
                          <label for="piso" class="form-label">Piso</label>
                          <input type="text"
                                class="dropdown"
                                name="piso"
                                disabled
                                placeholder="Piso"
                                [value]="confirmPersonal.contactDetails?.piso"
                                autocomplete="off">
                      </div>
                    </div>
                    <div class="container-newCJDesigner__form-row-group">
                      <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.puerta !== ""'>
                      <label for="puerta" class="form-label">Puerta</label>
                      <input type="text"
                            class="dropdown"
                            name="puerta"
                            disabled
                            placeholder="Puerta"
                            [value]="confirmPersonal.contactDetails?.puerta"
                            autocomplete="off">
                        </div>
                      <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.portal !== ""'>
                        <label for="portal" class="form-label">Portal</label>
                        <input type="text"
                              class="dropdown"
                              name="portal"
                              placeholder="Portal"
                              disabled
                              [value]="confirmPersonal.contactDetails?.portal"
                              autocomplete="off">
                      </div>
                    </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-row-group">
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.bloque !== ""'>
                      <label for="Bloque" class="form-label">Bloque</label>
                      <input type="text"
                            class="dropdown"
                            name="Bloque"
                            placeholder="Bloque"
                            disabled
                            [value]="confirmPersonal.contactDetails?.bloque"
                            autocomplete="off">
                    </div>
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.escalera !== ""'>
                      <label for="escalera" class="form-label">Escalera</label>
                      <input type="text"
                            class="dropdown"
                            name="escalera"
                            placeholder="Escalera"
                            disabled
                            [value]="confirmPersonal.contactDetails?.escalera"
                            autocomplete="off">
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-row-group">
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.fase !== ""'>
                      <label for="Fase" class="form-label">Fase</label>
                      <input type="text"
                            class="dropdown"
                            name="Fase"
                            placeholder="Fase"
                            disabled
                            [value]="confirmPersonal.contactDetails?.fase"
                            autocomplete="off">
                    </div>
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.contactDetails?.parcela !== ""'>
                      <label for="parcela" class="form-label">Parcela</label>
                      <input type="text"
                            class="dropdown"
                            name="parcela"
                            placeholder="Parcela"
                            disabled
                            [value]="confirmPersonal.contactDetails?.parcela"
                            autocomplete="off">
                    </div>
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="postalCode" class="form-label">Código Postal</label>
                    <input type="text"
                          class="dropdown"
                            name="postalCode"
                            disabled
                            placeholder="Código Postal"
                            [value]="confirmPersonal.contactDetails?.postalCode"
                            autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="situacionVivienda" class="form-label">Situación de la vivienda</label>
                    <input type="text"
                          class="dropdown"
                          name="situacionVivienda"
                          disabled
                          placeholder="Situación de la vivienda"
                          [value]="confirmPersonal.contactDetails?.situacionVivienda"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <h4 class="subtitle-form">DATOS LABORALES</h4>
              </div>
              <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                    <label for="situacionLaboral" class="form-label">Situación Laboral</label>
                    <input type="text"
                          class="dropdown"
                          name="situacionLaboral"
                          disabled
                          placeholder="Situación Laboral"
                          [value]="confirmPersonal.laborDetails?.situacionLaboral"
                          autocomplete="off">
                  </div>
                  <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.laborDetails?.tipoContrato !== ""'>
                    <label for="tipoContrato" class="form-label">Tipo de contrato</label>
                    <input type="text"
                          class="dropdown"
                          name="tipoContrato"
                          disabled
                          placeholder="Tipo de Contrato"
                          [value]="confirmPersonal.laborDetails?.tipoContrato"
                          autocomplete="off">
                  </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.laborDetails?.nombreEmpresa !== ""'>
                      <label for="empresaActual"  class="form-label">Empresa actual</label>
                      <input type="text"
                              class="dropdown"
                              name="empresaActual"
                              disabled
                              placeholder="Empresa actual"
                              [value]="confirmPersonal.laborDetails?.nombreEmpresa"
                              autocomplete="off">
                    </div>
                    <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.laborDetails?.antiguedadLaboral !== ""'>
                      <label for="antiguedadLaboral" class="form-label">Antiguedad Laboral</label>
                      <input type="text"
                              class="dropdown"
                              name="antiguedadLaboral"
                              disabled
                              placeholder="Antiguedad Laboral"
                              [value]="confirmPersonal.laborDetails?.antiguedadLaboral"
                              autocomplete="off">
                    </div>
              </div>
              <div class="container-newCJDesigner__form-row">
                <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.laborDetails?.tipoActividad !== ""'>
                  <label for="tipoActividad" class="form-label">Tipo de actividad profesional</label>
                  <input type="text"
                          class="dropdown"
                          name="tipoActividad"
                          disabled
                          placeholder="Tipo de actividad profesional"
                          [value]="confirmPersonal.laborDetails?.tipoActividad"
                          autocomplete="off">
                </div>
                  <div class="container-newCJDesigner__form-input" *ngIf='confirmPersonal.laborDetails?.fechaAltaAutonomo !== ""'>
                    <label for="fechaAltaAutonomo" class="form-label">Fecha de alta como autónomo</label>
                    <input type="text"
                          name="fechaAltaAutonomo"
                          class="dropdown"
                          disabled
                          placeholder="Fecha de alta como autónomo"
                          [value]="confirmPersonal.laborDetails?.fechaAltaAutonomo"
                          autocomplete="off">
                  </div>

              </div>
              <div class="container-newCJDesigner__form-row last-row">
                  <div class="container-newCJDesigner__form-input">
                    <div class="return-label">
                      <p (click)="returnInit()">
                        <img src="assets/images/icon-lock-feel/ic-flecha-black.svg" alt="icon">
                        Atrás
                      </p>
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <button type="button" class="btn-common btn-common_fix_bold" (click)="onSubmit()">CONFIRMO QUE ESTÁN
                      CORRECTOS
                    </button>
                  </div>
              </div>
            </form>
        </div>
      </div>
  </div>


  <div class="main-register flex-direction"
       *ngIf="isCIAccount">
    <box-image [classImgFile]="'revisar_datos05'"
               [icon]="'ic-telefono-movil'"
               [titleCss]="'title-2 '"
               [subtitleCss]="'subtitle-2'"
               [title]="'EVO WALLET'"
               [subtitle]="'Paga hasta 50€ sin pin con EVO Wallet, ¡solo tienes que actualizar la App!'"
               class="image-div"></box-image>
    <div class="form-and-step-div">
      <steps-ci class="step-component"></steps-ci>
      <hr class="line-separator not-hidden-movil">
      <div class="form-div">
        <p class="letter title">Revisa los datos de tu DNI</p>
        <p class="letter entradilla"> Asegúrate de que todos son correctos. Si no es así, selecciona el que esté mal y
          modifícalo. Ya casi has terminado, solo te queda firmar. </p>
        <div class="row" [hidden]='editForm'>
          <div class="col-info-personal-date">
            <p class="label-info">Nombre</p>
            <p class="value-info">{{confirmPersonal.name | uppercase }}</p>
          </div>
          <div class="col-info-personal-date">
            <p class="label-info">Primer apellido</p>
            <p class="value-info">{{confirmPersonal.firstName | uppercase }}</p>
          </div>
          <div class="col-info-personal-date">
            <p class="label-info">Segundo apellido</p>
            <p class="value-info">{{confirmPersonal.secondName | uppercase }}</p>
          </div>
        </div>
        <div class="row-ci">
          <a class="link-infoperso"
             (click)='showInputPersonConfirm()'>
            <span *ngIf="!editForm"> EDITAR </span> <span *ngIf="editForm"> VER </span> NOMBRE Y APELLIDOS
            <span [class]="editForm ? 'link-otp3' : 'link-otp1'"></span>
          </a>
        </div>
        <form class="form" [hidden]='!editForm'>
          <div class="floating-placeholder-group row-iban">
            <div class="col-input-personal-date">
              <input type="text-ci"
                     id="nombre"
                     name="nombre"
                     placeholder=""
                     [value]="confirmPersonal.name"
                     [(ngModel)]="confirmPersonal.name"
                     [ngClass]="confirmPersonal.name ? 'input-noEmpty' : 'input-empty'"
                     (ngModelChange)="onNameChange($event)"
                     autocomplete="off">
              <label class="floating-placeholder"
                     [ngClass]="validName? '': 'error-label'">Nombre</label>
              <div class="error-text" *ngIf="!validName">
                Introduce un nombre válido
              </div>
            </div>
            <div class="col-input-personal-date">
              <input type="text-ci"
                     name="primerApellido"
                     id="primerApellido"
                     placeholder=""
                     [value]="confirmPersonal.firstName"
                     [(ngModel)]="confirmPersonal.firstName"
                     [ngClass]="confirmPersonal.firstName ? 'input-noEmpty' : 'input-empty'"
                     (ngModelChange)="onFirstNameChange($event)"
                     autocomplete="off">
              <label class="floating-placeholder"
                     [ngClass]="validApelido1? '': 'error-label'">Apellido 1</label>
              <div class="error-text" *ngIf="!validApelido1">
                Introduce un primer apellido válido
              </div>
            </div>
            <div class="col-input-personal-date" *ngIf='showInputApellido2'>
              <input type="text-ci"
                     id="segundoApellido"
                     name="segundoApellido"
                     placeholder=""
                     [value]="confirmPersonal.secondName"
                     [(ngModel)]="confirmPersonal.secondName"
                     [ngClass]="confirmPersonal.secondName ? 'input-noEmpty' : 'input-empty'"
                     (ngModelChange)="onSecondNameChange($event)"
                     autocomplete="off">
              <label class="floating-placeholder"
                     [ngClass]="validApelido2? '': 'error-label'">Apellido 2</label>
              <div class="error-text" *ngIf="!validApelido2">
                Introduce un segundo apellido válido
              </div>
            </div>
          </div>
        </form>
        <div class="row opacity-ci">
          <div class="col-info-personal-date">
            <p class="label-info">Fecha de caducidad</p>
            <p class="value-info">{{formatDate(confirmPersonal.expiryDate)}}</p>
          </div>
          <div class="col-info-personal-date">
            <p class="label-info">Fecha de nacimiento</p>
            <p class="value-info">{{formatDate(confirmPersonal.birthDate)}}</p>
          </div>
        </div>
        <div class="row opacity-ci">
          <div class="col-info-personal-date">
            <p class="label-info">Género</p>
            <p class="value-info">{{confirmPersonal.sex}}</p>
          </div>
          <div class="col-info-personal-date">
            <p class="label-info">Nacionalidad</p>
            <p class="value-info">{{confirmPersonal.nationality}}</p>
          </div>
        </div>
        <hr class="line-separator hidden-movil">
        <div class="buttonCI">
          <button type="button"
                  class="btn-open-account open-account"
                  [disabled]="!validName || !validApelido1 || !validApelido2"
                  [ngClass]="!validName || !validApelido1 || !validApelido2 ? 'disabled-button': ''"
                  (click)="onSubmit()">CONFIRMAR</button>
        </div>
      </div>
    </div>
  </div>
