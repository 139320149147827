<loading [showMe]="loading"></loading>
<modal [modalTitle]="'Modal srv-error'" [blocking]="false" [modalId]="modalSrvError" class="modal">
  <div class="mdl-cont">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{ msgSrvError }}</p>
  </div>
</modal>
<modal [modalTitle]="'Selección método de identificación.'" [blocking]="false" [modalId]="modalIdVideo">
  <div class="mdl-cont mdl-cont-video">
    <p class="title-modal">
      ¡Identifícate con un <span class="easy-color">Selfie</span> !
    </p>
    <iframe allowfullscreen [src]="videoUrl | safe"></iframe>
  </div>
</modal>
<modal [modalTitle]="'Selección método de identificación.'" [blocking]="false" [modalId]="modalSpecVideo">
  <div class="mdl-cont">
    <p>
      A continuación te explicamos las
      <strong>especificaciones para el proceso de videoidentificación:</strong>
    </p>
    <ul class="titled-list">
      <li class="title">Navegadores que soportan el proceso:</li>
      <li>Chrome 50 o superior.</li>
      <li>Firefox 44 o superior.</li>
      <li>Microsoft Edge 80 o superior.</li>
      <li>Safari 11 o superior (*).</li>
      <li>* Requiere Flash Player instalado.</li>
    </ul>
    <ul class="titled-list">
      <li class="title">Notas:</li>
      <li>Para dispositivos iOS, versión 13 o superior.</li>
      <li>Para dispositivos Android, versión 4.4.2 o superior.</li>
    </ul>
    <ul class="titled-list">
      <li class="title">Webcam:</li>
      <li>3Mp (Megapixels) o superior.</li>
    </ul>
  </div>
</modal>

<modal [modalTitle]="'Guías para sacar las fotos de forma correcta:'" [blocking]="false" [modalId]="modalSpecIban">
  <div class="mdl-cont">
    <strong>1. ENCUADRE Y FONDO</strong>
    <p style="color: #999; line-height: 25px; margin-bottom: 10px">
      Toma la foto sobre un fondo liso (sin objetos ni fondo) y evita cortar
      información del documento.
    </p>
    <strong>2. ILUMINACIÓN</strong>
    <p style="color: #999; line-height: 25px; margin-bottom: 10px">
      Asegúrate de hacer la foto con buena iluminación, evitar brillos y
      desactivar el flash de tu cámara.
    </p>
    <strong>3. NITIDEZ Y CALIDAD DE LA IMAGEN</strong>
    <p style="color: #999; line-height: 25px; margin-bottom: 10px">
      Asegúrate de que la foto no este borrosa y que la resolución de la misma
      no sea inferior a 2 megapixeles ni supere 15MB de tamaño.
    </p>
    <strong>4. DOCUMENTO ORIGINAL</strong>
    <p style="color: #999; line-height: 25px; margin-bottom: 10px">
      La foto debe ser de tu DNI o Tarjeta de residencia original, evita
      fotocopias o fotos a pantallas.
    </p>
  </div>
</modal>

<modal [modalTitle]="'Iphone users'" [blocking]="false" [modalId]="modalIphone">
  <div class="mdl-cont">
    <h2>Ups!</h2>
    <p>
      Hemos detectado que estás realizando este proceso desde un dispositivo con
      sistema operativo IOS. Para continuar con el proceso puedes:
    </p>

    <div class="equal-buttons">
      <div class="access" name="button1">
        <a href="https://itunes.apple.com/es/app/evo-contrata/id1190237547?mt=8" target="_blank">
          <span>Descargarte nuestra app</span>
        </a>
      </div>
      <div class="access" name="button2">
        <a (click)="redirectToIban()">
          <span>Continuar con IBAN</span>
        </a>
      </div>
    </div>
  </div>
</modal>
<modal [modalTitle]="'Iphone users'" [blocking]="false" [modalId]="modalInfoEid" class="modal">
  <div class="mdl-cont-user-info">
    <div class="row-ic-bombilla">
      <p class="ic_bombilla_encendida"></p>
    </div>
    <div class="second-info-row">
      <p class="antes-de-continuar-e">
        Antes de continuar es importante que tengas esto en cuenta:
      </p>
    </div>
    <div class="options-info-1">
      <div class="marco">
        <p class="ic_bombilla_encendida"></p>
      </div>
      <div class="texto">
        <p class="estandar">
          En el proceso tendrás que
          <span class="negrita"> hacerte un selfie, </span> así que intenta
          estar en un sitio iluminado y evita que nada tape tu rostro.
        </p>
      </div>
    </div>
    <div class="options-info-2">
      <div class="marco">
        <p class="ic_webcam"></p>
      </div>
      <div class="texto">
        <p class="estandar negrita">
          Va a saltarte un aviso pidiéndote permiso para acceder a tu cámara.
          <span class="rosa">¡Acéptalo para poder continuar!</span>
        </p>
      </div>
    </div>
    <div class="options-info-3">
      <div class="marco">
        <p class="ic_persona_correr"></p>
      </div>
      <div class="texto">
        <p class="estandar">
          <span class="negrita">{{standardModalTextNIE}}</span>
          si aún no lo tienes en la mano ¡que empezamos!
        </p>
      </div>
    </div>
    <div class="options-button equal-buttons">
      <div class="access btn-eid" (click)="goToVideoEid()" name="button2">
        <a>
          <span class="text-button">Entendido</span>
        </a>
      </div>
    </div>
  </div>
</modal>
<modal  [blocking]="false"
        [modalId]="modalConsent"
        [modal-wide]="true"
        class="modal"
>
  <div class="mdl-cont">
    <h2 >
      Información básica sobre protección de datos
    </h2>
    <consent></consent>
  </div>
  <div class="cont-button-mod">
    <button class="close-boton btn-common btn-common_fix_bold"
            (click)="closeModal(modalConsent)">Aceptar</button>
  </div>
</modal>
<!-- Desactivamos el acceso por confirma + iberpay -->
<!-- <div class="verify-identity" *ngIf="!userInBL"> -->
<div class="verify-identity" *ngIf="false">
  <div class="verify-identity__container">
    <h2 class="verify-identity__title">Verifica tu identidad</h2>
    <p class="verify-identity__description">
      Para poder hacerlo todo desde el móvil o el ordenador, necesitamos estar
      seguros de que eres tú. Es muy sencillo, solo tienes que elegir una de
      estas dos opciones.
    </p>
    <div class="verify-identity__options">
      <div #identify class="verify-identity__option" (click)="clickLink(identify, $event)">
        <div class="verify-identity__option-title">Identificación por IBAN</div>
        <div class="verify-identity__option-desc">¿Qué necesitas?</div>
        <div class="verify-identity__option-steps">
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_banco"></div>
            <div class="verify-identity__option-step-content">
              <strong>Tu IBAN en cualquier otro banco</strong> (debes de ser el
              titular de la cuenta).
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic-carnet-marco"></div>
            <div class="verify-identity__option-step-content">
              <strong>Tu DNI o Tarjeta de residencia (NIE)</strong> con foto
              original, en vigor.
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_antena_señal"></div>
            <div class="verify-identity__option-step-content">
              <strong>Te enviaremos una transferencia</strong> y deberás
              <strong>indicarnos el importe.</strong>
            </div>
          </div>
        </div>
        <div class="verify-identity__option-action">
          <button type="submit" class="btn-common btn-common_fix_bold verify-identity__option-action-button"
            (click)="toggleIdentify(identify, $event); redirect()">
            ¡vamos!
          </button>
        </div>

        <div class="verify-identity__option-footer">
          <div class="verify-identity__option-footer-item" (click)="getSpecify('iban')">
            <span class="verify-identity__option-footer-icon ic-engranaje"></span>
            Especificaciones
          </div>
        </div>
      </div>

      <div class="verify-identity__option">
        <div class="verify-identity__option-title">Videoidentificación</div>
        <div class="verify-identity__option-desc">¿Qué necesitas?</div>
        <div class="verify-identity__option-steps">
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_cara_sonrisa_marco"></div>
            <div class="verify-identity__option-step-content">
              <strong>Un lugar bien iluminado</strong> y que nada tape tu rostro
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic-carnet-marco"></div>
            <div class="verify-identity__option-step-content">
              <strong>Tu documento original</strong> en vigor y sin funda
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_antena_señal"></div>
            <div class="verify-identity__option-step-content">
              Conexión<strong> wifi o máxima cobertura 4G</strong>
            </div>
          </div>
        </div>
        <div class="verify-identity__option-action">
          <button type="submit" class="btn-common btn-common_fix_bold verify-identity__option-action-button"
           (click)="goToVideoEid()" >
            ¡vamos!
          </button>
        </div>

        <div  class="verify-identity__option-footer">
          <div class="verify-identity__option-footer-item" (click)="getSpecify('video')">
            <span class="verify-identity__option-footer-icon ic-engranaje"></span>
            Especificaciones
          </div>
          <div *ngIf="isCIAccount" class="verify-identity__option-footer-item" (click)="getVideo('U7QoYXoBDGg')">
            <span class="verify-identity__option-footer-icon ic-play-video"></span>
            Videotutorial
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Usuario en la lista negra, no se permite identificación por IBAN-->
<!-- Desactivamos el acceso por confirma + iberpay -->

<div class="main-register flex-direction" *ngIf="!noPhotoIdCard">
  <div class="verify-identity" *ngIf="true">
    <steps-ci class="step-component-verify"></steps-ci>
    <hr class="line-separator-visibility" />
    <form class="form-checks" (ngSubmit)="goToVideoEid()" [formGroup]="varifiIdentityForm">
    <div class="verify-identity__container">
      <h2 class="verify-identity__title">Identificate con un <span class="easy-color">selfie</span></h2>
      <p  class="verify-identity__description"
          *ngIf="isCIAccount">
          Necesitamos ponerte cara  <strong>¡Es inmediato y muy sencillo!</strong> y podras disfrutar de tu Cuenta Inteligente Bienvenida<sup>1</sup>
          remunerada al <strong>3,05 % TAE</strong> hasta 30.000 €. ¡No queda nada!
      </p>
      <p  class="verify-identity__description"
          *ngIf="!isCIAccount">
          Necesitamos ponerte cara  <strong>¡Es inmediato y muy sencillo!</strong>
      </p>
      <div class="verify-identity__options">
        <div class="verify-identity__option">
          <div class="verify-identity__option-title"></div>
          <div class="verify-identity__option-desc">
          <strong>En tan solo unos minutos tu {{tipoCuenta}} estará activa.</strong>
            Además, mientras se emite tu {{tipoTarjeta}} puedes empezar a
            pagar con ella <strong>descargándote la App y activando Wallet Inmediato</strong>
            tanto en iOS como Android.</div>
          <div class="verify-identity__option-desc">Solo necesitas:</div>
          <div class="verify-identity__option-steps">
            <div class="verify-identity__option-step">
              <div class="verify-identity__option-step-icon ic_cara_sonrisa_marco"></div>
              <div class="verify-identity__option-step-content">
                <strong>Un lugar bien iluminado</strong> y que nada tape tu rostro
              </div>
            </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic-carnet-marco"></div>
            <div class="verify-identity__option-step-content">
              <strong>Tu documento original</strong> en vigor y sin funda
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_antena_señal"></div>
            <div class="verify-identity__option-step-content">
              Conexión<strong> wifi o máxima cobertura 4G</strong>
            </div>
          </div>
        </div>
        <div class="form-checkbox-ci verify-identity__checks"
                (click)="checkConsentimiento()">
            <input  type="checkbox"
                    name="check_consent"
                    id="check_consent"
                    value="Acepto"
                    formControlName="indConsentimientoEid" />
            <label class="verify-identity__text-label">
              <p class="verify-identity__text-label__texto">
                Autorizo el uso de mis datos biométricos durante el proceso de videoidentificación para verificar mi identidad, de acuerdo con la&nbsp;
                <span class="verify-identity__text-label__enlace"
                      (click)="openConsetModal()">Política de privacidad.</span>
              </p>
            </label>
        </div>
        <div  class="error-text verify-identity__container-text-warning"
              *ngIf="showMandatoryCheckInfo && !varifiIdentityForm.valid">
          <div class="verify-identity__text-warning">
            <i class="evo ico-warning-white verify-identity__icon-warning"></i>
            <p class="verify-identity__text-warning__text">
              Para poder continuar necesitas autorizar el uso de datos.</p>
          </div>

        </div>
        <div class="verify-identity__option-action">
          <button type="submit"
                  class="btn-common btn-common_fix_bold verify-identity__option-action-button"
                  [ngClass]="{ 'verify-identity__option-action-button__disabled': !varifiIdentityForm.valid }"
                  (click)="goToVideoEid()" >
            Continuar
          </button>
        </div>
        <div class="verify-identity__option-footer">
          <div class="verify-identity__option-footer-item" (click)="getSpecify('video')">
            <span class="verify-identity__option-footer-icon ic-engranaje"></span>
            Especificaciones
          </div>
          <div *ngIf="isCIAccount" class="verify-identity__option-footer-item" (click)="getVideo('U7QoYXoBDGg')">
            <span class="verify-identity__option-footer-icon ic-play-video"></span>
            Videotutorial
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
</div>

<!-- Pagina a mostrar si identificacion es NIE comunitario (Sin FOTO) -->
<div class="main-register flex-direction" *ngIf="noPhotoIdCard">
  <div class="verify-identity" *ngIf="true">
    <steps-ci class="step-component-verify"></steps-ci>
    <hr class="line-separator-visibility" />
    <form class="form-checks" (ngSubmit)="goToVideoEid()" [formGroup]="varifiIdentityForm">
    <div class="verify-identity__container">
      <h2 class="verify-identity__title">Videoidentificación</h2>
      <p  class="verify-identity__description"
          *ngIf="isCIAccount">
          Ahora vamos a comenzar con la identificación a través de video
      </p>

      <div class="verify-identity__options">
        <div class="verify-identity__option">
          <div class="verify-identity__option-steps">
            <div class="verify-identity__option-step">
              <div class="verify-identity__option-step-icon ic_cara_sonrisa_marco"></div>
              <div class="verify-identity__option-step-content">
                Acepta el <strong>permiso de cámara</strong> para que el sistema pueda reconocerte
              </div>
            </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic-carnet-marco"></div>
            <div class="verify-identity__option-step-content">
              Ten el <strong>DNI de tu país de origen o tu pasaporte </strong> y tu 
              <strong>Certificado de Registro de Ciudadano de la Unión Europea</strong> a mano
            </div>
          </div>
          <div class="verify-identity__option-step">
            <div class="verify-identity__option-step-icon ic_cara_sonrisa_marco"></div>
            <div class="verify-identity__option-step-content">
              Te recomendamos<strong> estar en un lugar bien iluminado y que nada tape tu rostro.</strong>
            </div>
          </div>
        </div>
        <div class="form-checkbox-ci verify-identity__checks" 
                (click)="checkConsentimiento()">
            <input  type="checkbox" 
                    name="check_consent" 
                    id="check_consent" 
                    value="Acepto" 
                    formControlName="indConsentimientoEid" />
            <label class="verify-identity__text-label">
              <p class="verify-identity__text-label__texto">
                Autorizo el uso de mis datos biométricos durante el proceso de videoidentificación para verificar mi identidad, de acuerdo con la&nbsp;
                <span class="verify-identity__text-label__enlace"
                      (click)="openConsetModal()">Política de privacidad.</span>
              </p>
            </label>
        </div>
        <div  class="error-text verify-identity__container-text-warning"
              *ngIf="showMandatoryCheckInfo && !varifiIdentityForm.valid">
          <div class="verify-identity__text-warning">
            <i class="evo ico-warning-white verify-identity__icon-warning"></i> 
            <p class="verify-identity__text-warning__text">
              Para poder continuar necesitas autorizar el uso de datos.</p>
          </div>
            
        </div>
        <div class="verify-identity__option-action">
          <button type="submit" 
                  class="btn-common btn-common_fix_bold verify-identity__option-action-button"
                  [ngClass]="{ 'verify-identity__option-action-button__disabled': !varifiIdentityForm.valid }"
                  (click)="goToVideoEid()" >
            Continuar
          </button>
        </div>
        <div class="verify-identity__option-footer">
          <div class="verify-identity__option-footer-item" (click)="getSpecify('video')">
            <span class="verify-identity__option-footer-icon ic-engranaje"></span>
            Especificaciones
          </div>
          <div *ngIf="isCIAccount" class="verify-identity__option-footer-item" (click)="getVideo('U7QoYXoBDGg')">
            <span class="verify-identity__option-footer-icon ic-play-video"></span>
            Videotutorial
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
</div>


<legal-condition  *ngIf="isCIAccount"
                  [textLegal] = "promoConditionText"
                  class="legal-text" ></legal-condition>
