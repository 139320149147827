import { Output, ElementRef, EventEmitter, Input, ViewContainerRef, Directive, HostListener, Component, ComponentFactoryResolver } from '@angular/core';
import { OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { HelperService } from 'app/services/helpers.service';

@Directive({
  selector: '[keyboard]'
})
export class KeyboardDirective {

  @Output('numberEmmited') numberEmmited = new EventEmitter();
  @Output('deleteLast') deleteLast = new EventEmitter();

  private show: boolean = false;
  keyboard: any;
  elemPosition: any;
  @Input() horizontal: boolean = false;
  @Input() modal: boolean = true;

  constructor(
    private elementRef: ElementRef,
    private viewContainer: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
    public helpers: HelperService
  ) { }

  @HostListener('keydown', ['$event'])
  prevent(event) { event.preventDefault() };

  @HostListener('click')
  showKeyboard() {
    this.viewContainer.clear();
    this.createKeyboard();
  }

  createKeyboard() {
    this.helpers.keyboardClose.subscribe(val => { this.removeKeyboard() });
    this.getElemPosition().then((solved) => {
      this.createDialog(KeyboardComponent).then(userKeyboard => {
        const component: any = userKeyboard;
        this.setPosition(component);
      });
    })
  }

  getElemPosition() {
    return new Promise((resolve) => {
      resolve(this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect());
    })
  }

  createDialog(keyboard: { new(): KeyboardComponent }) {
    return new Promise((resolve) => {
      let factory =
        this.componentResolver.resolveComponentFactory(keyboard);
      let ref = this.viewContainer.createComponent(factory);
      ref.instance.numberEmmited.subscribe(val => this.numberEmmited.emit(val));
      ref.instance.deleteLast.subscribe(val => this.deleteLast.emit(val));
      ref.instance.closeKeyboard.subscribe(val => { 
        this.helpers.closeLoginKeyboard();
        this.removeKeyboard(); 
      });
      resolve(ref);
    })
  }

  removeKeyboard() {
    let keyboard = document.getElementsByClassName('clickKb')[0];
    if(keyboard){
      if (!('remove' in Element)) {
        Element.prototype.remove = function () {
          if (this.parentNode) {
            this.parentNode.removeChild(this);
          }
        };
      };
      keyboard.parentElement.remove();
    }
  }

  setPosition(component) {
    //TODO:Simplificar
    let elementBorderOffset = 2;
    let keyboardHeight = 296;
    let keyboardWidth = 278;
    let inputHeight = 57;
    let legendHeight = 20;
    this.keyboard = component.location.nativeElement;
    if (this.modal) {
      if (window.innerWidth >= 992) {
        this.keyboard.style.position = 'absolute';
        this.keyboard.style.zIndex = -1;
        this.keyboard.style.top = Math.abs(this.elemPosition.top / 2 - this.elemPosition.height - elementBorderOffset) + 'px';
        if (this.horizontal) {
          this.keyboard.style.left = Math.abs(this.elemPosition.left + this.elemPosition.width) + 'px';
        }
      } else {
        this.keyboard.style.position = 'relative';
      }
    } else {
      let keyboardChild = this.keyboard.children[0];
      keyboardChild.style.zIndex = 40;
      if (window.innerWidth >= 992) {
        keyboardChild.style.left = Math.abs(this.elemPosition.width - keyboardWidth) * 2 + 'px';
        keyboardChild.style.marginBottom = "15px";
      }
      else {
        this.keyboard.style.position = 'relative';
      }
    }
  }
}

@Component({
  selector: 'keyboard-elem',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {

  public matrix = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  @Output('numberEmmited') numberEmmited = new EventEmitter();
  @Output('deleteLast') deleteLast = new EventEmitter();
  @Output('closeKeyboard') closeKeyboard = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.matrix.sort(function () { return 0.5 - Math.random() });
  }

  addThis(e) {
    let pulsation = e.currentTarget.innerText;
    this.numberEmmited.emit(e);
  }
  removeLast(e) {
    this.deleteLast.emit(e);
  }

  close(e) {
    this.closeKeyboard.emit(e);
  }

  preventClose(e) {
    e.stopPropagation();
  }
}
