import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SpecificAnalyticsService } from './specific-analytics.service';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { ACCOUNTS } from 'app/resources/account.model';

export type GtmData = { [key: string]: any };
declare var dataLayer;
declare var ADRUM;
import * as sha256 from 'crypto-js/sha256';
import * as shajs from 'sha.js';
import { UITreeNode } from 'primeng/primeng';
import { environment } from 'environments/environment';

@Injectable()
export class AnalyticsService {
  registerType: string;
  identificationType: string;
  errorPage;
  userId;
  modDataErrorPageName;

  isInvestmentNav = false;
  isCreditLimitNav = false;
  isCampanyaModDatos = false;
  isAltaTipoA = false;
  isSecondSigner = false;
  isYoungAccount = false;
  origen: string;

  defaultNavData: Object = {
    'event': 'virtual_page',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,
    'holder': 'titular único',
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,
    'mail': undefined
  }

  profileBeginCheckout: Object = {
    'event': 'begin_checkout',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,    
    'holder': 'titular único',
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,    
    'ecommerce': {
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    },
    'mail': undefined
  };

  profileAddToCart: Object = {
    'event': 'add_to_cart',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,    
    'holder': 'titular único',
    'clientInfo': undefined,
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,    
    'ecommerce': {
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    },
    'mail': undefined
  };

  profileCTA: Object = {
    'event': 'CTA',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'abre_c2c',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,
    'holder': 'titular único',
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,
    'mail': undefined
  }

  profileC2C: Object = {
    'event': 'C2C',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'formulario_enviado_c2c',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,
    'holder': 'titular único',
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,
    'currency': 'EUR',
    'value': 5,
    'mail': undefined
  }

  profilePurchase: Object = {
    'event': 'purchase',
    'process': undefined,
    'product': undefined,
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'type': 'genérico',
    'origen': undefined,
    'seccion': undefined,        
    'holder': 'titular único',
    'clientType': 'Cliente Tipo A',
    'oferta': undefined,
    'registerType': undefined,
    'identificationType': undefined,        
    'ecommerce': {
      'transaction_id': undefined,
      'currency': 'EUR',
      'value': 20,
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    },
    'mail': undefined
  };

  defaultFormData: Object = {
    'event': 'form_sent',
    'eventCategory': undefined
  }

  defaultInteractionData: Object = {
    'event': 'CTA',
    'eventCategory': undefined
  }

  filteredSteps: Object = {
    '/bienvenido': 'Paso 01 - Selección identificación',
    '/cuenta-joven/bienvenido': 'Paso 01 - Selección identificación',
    '/confirmacion-otp-primer-paso': 'Paso 01.1 - Validación móvil',
    '/identificacion-edad': 'Paso 01B - Identificación edad',
    '/datos-personal': 'Paso 02 - Datos personales',
    '/datos-contacto': 'Paso 03 - Datos contacto',
    '/datos-personales': 'Paso 02 - Datos personales',
    '/datos-de-contacto': 'Paso 03 - Datos contacto',
    '/datos-laboral': 'Paso 04 - Datos laborales',
    '/metodo-validacion': 'Paso 06 - Método validación',
    '/previa-eid': 'Paso 06B - Previa identificación vídeo',
    '/identificacion-video': 'Paso 07A - Identificación vídeo',
    '/confirmacion-datos': 'Paso 08 - Confirmación datos',
    '/previa-firma': 'Paso 09 - Previa firma',
    '/proceso-firma': 'Paso 10 - Proceso firma',
    '/contratacion-ci': 'Paso 11 - Cuenta firmada',
    '/casi-lo-tenemos': 'Paso 11 - Cuenta firmada',
    '/casi-esta': 'Paso 11 - Cuenta firmada',
    '/ya-esta': 'Paso 13 - Cuenta firmada',
    '/ahora-nosotros': 'Paso 11 - Cuenta firmada',
    '/404': 'Error - 404',
    '/OCR': 'Error - OCR',
    '/Edad': 'Error - Mayor de 25'
  };

  profileFiltered: Object = {
    '/login': 'Log in',
    '/perfil': 'Perfil',
    '/mis-productos': 'Productos',
    '/detalle-producto': 'Detalle cuenta',
    '/notificacion': 'Notificaciones',
    '/mi-perfil': 'Perfil/Datos',
    '/modificar-datos': 'Perfil/Información Datos',
    '/subir-documentacion': 'Perfil/Subida de DNI',
    '/confirma-otp': 'Perfil/Confirmación OTP',
  }

  evoProtectFiltered: Object = {
    '/evo-protect/requisitos': 'EVO Protect/Requisitos',
    '/evo-protect/tarificador': 'EVO Protect/Simulador Formulario',
    '/evo-protect/simulador-publico': 'EVO Protect/inicio',
    // Contratación microseguros (movido de profileFiltered)
    '/evo-protect/pre-logalty': 'EVO Protect/Pre Logalty Boletín de Adhesión',
    '/evo-protect/configura-microseguro': 'EVO Protect/Paso 01 - Configurador',
    '/evo-protect/configura-beneficiarios': 'EVO Protect/Paso 03 – Selector Beneficiario',
    '/evo-protect/configura-cuenta': 'EVO Protect/Paso 04 – Selector Cuenta',
    '/evo-protect/confirmacion': 'EVO Protect/Paso 05 – OTP',
    '/evo-protect/todo-listo': 'EVO Protect/Paso 06 – OK Alta',
    '/evo-protect/cancelacion-adhesion': 'EVO Protect/Confirmación Cancelación Adhesión',
    '/evo-protect/boletin-adhesion': 'EVO Protect/Boletín Adhesión',
    '/evo-protect/404': 'Error - 404',
    '/404': 'Error - 404'
  }

  segundoTitularFiltered: Object = {
    '/segundo-titular-fin': 'Cuenta Inteligente/Segundo titular Confirmación',
    '/bienvenida-segundo-titular': 'Paso 01 - Bienvenida',
    '/identificacion-edad': 'Paso 01B - Identificación edad',
    '/datos-personal': 'Paso 02 - Datos personales',
    '/datos-personales': 'Paso 02 - Datos personales',    
    '/datos-contacto': 'Paso 03 - Datos contacto',
    '/datos-de-contacto': 'Paso 03 - Datos contacto',
    '/datos-laboral': 'Paso 04 - Datos laborales',
    '/confirmacion-otp': 'Paso 05 - Validación móvil',
    '/metodo-validacion': 'Paso 06 - Método validación',
    '/previa-eid': 'Paso 06B - Previa identificación vídeo',
    '/identificacion-video': 'Paso 07A - Identificación vídeo',
    '/confirmacion-datos': 'Paso 08 - Confirmación datos',
    '/previa-firma': 'Paso 09 - Previa firma',
    '/proceso-firma': 'Paso 10 - Proceso firma',
    '/casi-esta': 'Paso 11 - Confirmación de cuenta',
    '/404': 'Error - 99'
  }

  segundoTitularFilteredLogin: Object = {
    '/login-invitacion': 'Login Segundo titular',
    '/segundo-titular': 'Añadir segundo titular',
    '/ya-esta': 'Paso 12 - Cuenta firmada',
    '/404': 'Error - 99'
  }

  altaClienteFiltered: Object = {
    '/alta-cliente': 'Formulario datos',
    '/confirmacion-otp-primer-paso': 'Formulario OTP',
    '/404': 'Error - 404'
  }

  profileNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'userId': undefined,
    'errorPage': undefined
  }

  profileModDataNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'userId': undefined,
    'errorPage': undefined,
    'pageName': undefined
  }

  profileCreditLimitNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'userId': undefined,
    'errorPage': undefined,
    'pageName': undefined,
    'creditLimit': undefined
  }

  profileInvestmentNavData: Object = {
    'event': 'virtual_page',
    'process': 'Área Personal eCommerce',
    'product': undefined,
    'pageName': undefined,
    'userId': undefined,
    'errorPage': undefined,
  }

  profileTarificationNavData: Object = {
    'event': 'virtual_page',
    'process': 'Simulador de microseguros',
    'userId': undefined,
    'errorPage': undefined
  }

  profileSegundoTitularNavData: Object = {
    'event': 'virtual_page',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'pageName': undefined,
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'holder': 'titular 2',
    'clientType': 'Cliente Tipo A'
  }

  profileSegundoTitularBeginCheckout: Object = {
    'event': 'begin_checkout',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'holder': 'titular 1',
    'clientType': 'Cliente Tipo B',
    'ecommerce': {
      'items': [{
        'item_name': 'Cuenta Inteligente',
        'item_id': 'Cuenta Inteligente_generico'
      }]
    }
  };

  profileSegundoTitularPurchase: Object = {
    'event': 'purchase',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'holder': 'titular 2',
    'clientType': 'Cliente Tipo B',
    'ecommerce': {
      'transaction_id': undefined,
      'currency': 'EUR',
      'value': 20,
      'items': [{
        'item_name': 'Cuenta Inteligente',
        'item_id': 'Cuenta Inteligente_generico'
      }]
    }
  };

  profileInvSegundoTitularNavData: Object = {
    'event': 'virtual_page',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': 'titular 2',
    'clientType': undefined
  }
  
  profileInvSegundoTitularForm: Object = {
    'event': 'virtual_page',
    'process': 'Invitación segundo titular',
    'product': 'Cuenta Inteligente',
    'category': 'cuentas',
    'content_type': 'proceso_contratacion',
    'clientId': undefined,
    'step': undefined,
    'product_id': undefined,
    'origen': undefined,
    'seccion': undefined,
    'holder': 'titular 2',
    'clientType': 'Cliente Tipo B'
  }

  profileAltaClienteNavData: Object = {
    'event': 'virtual_page',
    'process': 'Captación clientes tipo A',
    'pageName': undefined,
    'userId': undefined,
    'clientId': undefined,
    'errorPage': undefined,
  }

  profileFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Área Personal eCommerce'
  }

  profileTarificationFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Simulador de microseguros'
  }

  segundoTitularFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Invitación segundo titular'
  }

  altaClienteFormData: Object = {
    'event': 'form_sent',
    'eventCategory': 'Captación clientes tipo A'
  }

  profileNotification: Object = {
    'event': 'notification',
    'eventCategory': 'Área Personal eCommerce'
  }

  profileTarificationNotification: Object = {
    'event': 'notification',
    'eventCategory': 'Simulador microseguros',
  }

  globalTarificationError: Object = {
    'event': 'error',
    'eventCategory': 'Simulador microseguros',
    'eventAction': 'Mensaje de error'
  }

  globalTarification: Object = {
    'event': 'simulador_microseguros',
    'eventCategory': 'Simulador de microseguros',
    'eventAction': 'Calcular precio microseguro'
  };

  unsubscribe: Object = {
    'event': 'CTA',
    'eventCategory': 'Área Personal eCommerce',
    'eventAction': 'Cancelar Boletín de Adhesión'
  }



  dataToHash;

  constructor(
    private router: Router,
    private specificAnalytics: SpecificAnalyticsService,
    public authService: AuthService,
    public loginSrv: LoginService) {
    this.subscribeToBroadcasts();
  }

  public subscribeToNav(router) {
    //Se ejecuta setTime para dar tiempo a comprobar si el usuario ha aceptado las cookies
    router.events.subscribe(event => {
      setTimeout(() => this.getDataLayerParams(event), 2000);
    })
  }
  getDataLayerParams(event) {
    let dataLayer = [] = window['dataLayer'];
    const isNotificationDetail = location.hash.indexOf('/notificacion/') !== -1;
    sessionStorage.setItem("isNotificationDetail" , isNotificationDetail.toString());
    const isUrlDefined = typeof event.url === "string";
    sessionStorage.setItem("isUrlDefined" , isUrlDefined.toString());
    const isUrlAferRedirectDefined = typeof event.urlAfterRedirects === "string";
    sessionStorage.setItem("isUrlAferRedirectDefined" , isUrlAferRedirectDefined.toString());
    const eventUrlOrigin = isUrlDefined ? event.url.split('?')[0] : event.url;
    sessionStorage.setItem("eventUrlOrigin" , eventUrlOrigin);
    const eventUrl = isUrlAferRedirectDefined ? event.urlAfterRedirects.split('?')[0] : event.urlAfterRedirects;
    sessionStorage.setItem("eventUrl" , eventUrl);
   /* for (let dL of dataLayer) {
      if (dL.event === 'OneTrustLoaded') {
        if (dL.OnetrustActiveGroups !== ',C0001,') {*/
          this.profileNavData['process'] = 'Área Personal eCommerce';
          let errorEvoProtect = false;
          let errorModData = false;

          this.isAltaTipoA = this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true";
          if ((sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') 
             || (sessionStorage.getItem('product') && sessionStorage.getItem('product') === '999'))
          {
            this.isSecondSigner = true;
          };
          if (sessionStorage.getItem('product') && sessionStorage.getItem('product') === '008') {
            this.isYoungAccount = true;
          };          
          if (sessionStorage.getItem('origen')) {
            this.origen = sessionStorage.getItem('origen');
          };

          if (event instanceof NavigationEnd) {

            if (eventUrl === '/login') {
              this.isCreditLimitNav = false;
              this.isInvestmentNav = false;
              this.isCampanyaModDatos = event.urlAfterRedirects.indexOf('subidaDocumentacion') !== -1;
            }

            let myProfileAndModData = this.isCampanyaModDatos && (eventUrl === '/mi-perfil');

            if (this.profileFiltered[eventUrl] !== undefined && !this.isCreditLimitNav && !this.isInvestmentNav && !myProfileAndModData && !this.isAltaTipoA) {
              this.triggerProfileNavData(
                { 'pageName': this.filterProfilePage(eventUrl) }
              );
            }
            else if (isNotificationDetail) {
              const notifTitle = decodeURI(eventUrl.replace('/notificacion/', ''))
              this.triggerProfileNavData(
                { 'pageName': `Notificaciones/ ${notifTitle}` }
              )
            }
            else if (this.evoProtectFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
              this.isCreditLimitNav = false;
              this.isInvestmentNav = false;
              this.profileNavData['process'] = 'Simulador de microseguros';
              this.triggerProfileTarificationNavData(
                { 'pageName': this.filterMicrosegPage(eventUrl) }
              );
            }
            else if (this.segundoTitularFiltered[eventUrl] !== undefined && this.isSecondSigner) {                
              this.triggerInvSegundoTitularNavData(
                { 'step': this.filterSegundoTitularPage(eventUrl),
                  'clientType': 'Cliente Tipo A'}
              );
            }             
            else if(this.segundoTitularFilteredLogin[eventUrl] !== undefined && this.isSecondSigner) {
              this.triggerInvSegundoTitularLogin(
                {
                  'step': this.filterSegundoTitularPageLogin(eventUrl),
                  'clientType': 'Cliente Tipo B',
                  'origen': this.origen
                }
              );
            }
            else if (this.isAltaTipoA && this.altaClienteFiltered[eventUrl] !== undefined && eventUrl !== '/404') {

              this.triggerAltaClienteNavData['clientId'] = this.getClientId();
              this.triggerAltaClienteNavData(
                { 'pageName': this.filterAltaClientePage(eventUrl) }
              );
            }
            else {
              if (eventUrl === '/bienvenido' || eventUrl === '/cuenta-joven/bienvenido') {
                this.dataToHash = undefined;
                this.isCreditLimitNav = false;
                this.isInvestmentNav = false;
              } else {
                //Error 404 de microseguros
                if (eventUrl === '/404' && eventUrlOrigin && eventUrlOrigin.indexOf('evo-protect') !== -1) {
                  errorEvoProtect = true;
                  this.profileNavData['process'] = 'Simulador de microseguros';
                  this.triggerProfileTarificationNavData(
                    { 'pageName': this.filterMicrosegPage(eventUrl) }
                  );
                }


                //Error 404 de modDatos
                const isModDataErrorPage = this.modDataErrorPageName !== undefined && this.modDataErrorPageName !== '';
                if (isModDataErrorPage) {
                  errorModData = true;
                  this.addClientId();
                  this.triggerProfileModDataNavData(
                    { 'pageName': this.modDataErrorPageName }
                  );
                }

                //Error 404 de Segundo Titular
                const isSegundoTitularErrorPage = eventUrl === '/404' && this.authService.product === ACCOUNTS.STCode;
                if (isSegundoTitularErrorPage) {
                  this.triggerSegundoTitularNavData(
                    { 'pageName': this.filterSegundoTitularPage(eventUrl) }
                  );
                  return;
                }

                //Error 404 de Alta Cliente
                const isAltaClienteErrorPage = eventUrl === '/404' && (this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true");
                if (isAltaClienteErrorPage) {
                  this.triggerAltaClienteNavData['clientId'] = this.getClientId();
                  this.triggerAltaClienteNavData(
                    { 'pageName': this.filterAltaClientePage(eventUrl) }
                  );
                  return;
                }

                // We keep the Clients ID in case of error (session is erased)
                const isErrorPages = eventUrl === '/404' || eventUrl === '/OCR'
                  || eventUrl === '/login-inversora' || eventUrl === '/configuracion-cuenta-inversora'
                if (!isErrorPages) {
                  this.addClientId();
                }
              }

              if (!this.isCampanyaModDatos && !this.isCreditLimitNav && !this.isInvestmentNav) {
                this.addProduct();
              } else if (this.isCampanyaModDatos) {
                this.defaultNavData['process'] = 'Área Personal eCommerce';
              }

              if (eventUrl) {
                switch (eventUrl) {
                  case '/confirmacion-datos':
                  case '/proceso-firma':
                  case '/contratacion-ci':
                  case '/404':
                  case '/OCR':
                    if (this.loginSrv.userData) {
                      if (errorEvoProtect) {
                        this.defaultNavData['process'] = 'Simulador microseguros';
                      } else {
                        this.defaultNavData['process'] = 'Área personal eCommerce';
                      }

                    }
                    break;
                }
                if (this.filterSteps(eventUrl) && !this.isSecondSigner && !errorEvoProtect && !errorModData && !this.isCreditLimitNav && !this.isInvestmentNav) {
                  this.addIdentificationType(eventUrl);
                  this.addRegisterType();
                  this.triggerNavData(
                    {
                      'step': this.filterSteps(eventUrl),
                      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
                      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
                    }
                  )
                }
              }
            }
            if (window['ADRUM']) {
              const virtualPage = new window['ADRUM'].events.VPageView();
              window['ADRUM'].report(virtualPage);
            }

          /*}
        }
      }*/
    }
  }
  public validationAfeterViewInit() {
    const isNotificationDetail = (sessionStorage.getItem("isNotificationDetail") === 'true');
    const isUrlDefined = (sessionStorage.getItem("isUrlDefined") === 'true');
    const isUrlAferRedirectDefined = (sessionStorage.getItem("isUrlAferRedirectDefined") === 'true' );
    const eventUrlOrigin = sessionStorage.getItem("eventUrlOrigin");
    const eventUrl = sessionStorage.getItem("eventUrl");

    this.profileNavData['process'] = 'Área Personal eCommerce';
    let errorEvoProtect = false;
    let errorModData = false;

    this.isAltaTipoA = this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true";


      if (eventUrl === '/login') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
      }

      let myProfileAndModData = this.isCampanyaModDatos && (eventUrl === '/mi-perfil');

      if (this.profileFiltered[eventUrl] !== undefined && !this.isCreditLimitNav && !this.isInvestmentNav && !myProfileAndModData && !this.isAltaTipoA) {
        this.triggerProfileNavData(
          { 'pageName': this.filterProfilePage(eventUrl) }
        );
      }
      else if (isNotificationDetail) {
        const notifTitle = decodeURI(eventUrl.replace('/notificacion/', ''))
        this.triggerProfileNavData(
          { 'pageName': `Notificaciones/ ${notifTitle}` }
        )
      }
      else if (this.evoProtectFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        this.isCreditLimitNav = false;
        this.isInvestmentNav = false;
        this.profileNavData['process'] = 'Simulador de microseguros';
        this.triggerProfileTarificationNavData(
          { 'pageName': this.filterMicrosegPage(eventUrl) }
        );
      }
      else if (this.segundoTitularFiltered[eventUrl] !== undefined && eventUrl !== '/404') {
        this.triggerSegundoTitularNavData(
          { 'pageName': this.filterSegundoTitularPage(eventUrl) }
        );
      }
      else if (this.isAltaTipoA && this.altaClienteFiltered[eventUrl] !== undefined && eventUrl !== '/404') {

        this.triggerAltaClienteNavData['clientId'] = this.getClientId();
        this.triggerAltaClienteNavData(
          { 'pageName': this.filterAltaClientePage(eventUrl) }
        );
      }
      else {
        if (eventUrl === '/bienvenido' || eventUrl === '/cuenta-joven/bienvenido') {
          this.dataToHash = undefined;
          this.isCreditLimitNav = false;
          this.isInvestmentNav = false;
        } else {
          //Error 404 de microseguros
          if (eventUrl === '/404' && eventUrlOrigin && eventUrlOrigin.indexOf('evo-protect') !== -1) {
            errorEvoProtect = true;
            this.profileNavData['process'] = 'Simulador de microseguros';
            this.triggerProfileTarificationNavData(
              { 'pageName': this.filterMicrosegPage(eventUrl) }
            );
          }

          //Error 404 de modDatos
          const isModDataErrorPage = this.modDataErrorPageName !== undefined && this.modDataErrorPageName !== '';
          if (isModDataErrorPage) {
            errorModData = true;
            this.addClientId();
            this.triggerProfileModDataNavData(
              { 'pageName': this.modDataErrorPageName }
            );
          }

          //Error 404 de Segundo Titular
          const isSegundoTitularErrorPage = eventUrl === '/404' && this.authService.product === ACCOUNTS.STCode;
          if (isSegundoTitularErrorPage) {
            this.triggerSegundoTitularNavData(
              { 'pageName': this.filterSegundoTitularPage(eventUrl) }
            );
            return;
          }

          //Error 404 de Alta Cliente
          const isAltaClienteErrorPage = eventUrl === '/404' && (this.authService.product === ACCOUNTS.TipoACode || sessionStorage.getItem("altaClienteA") === "true");
          if (isAltaClienteErrorPage) {
            this.triggerAltaClienteNavData['clientId'] = this.getClientId();
            this.triggerAltaClienteNavData(
              { 'pageName': this.filterAltaClientePage(eventUrl) }
            );
            return;
          }

          // We keep the Clients ID in case of error (session is erased)
          const isErrorPages = eventUrl === '/404' || eventUrl === '/OCR'
            || eventUrl === '/login-inversora' || eventUrl === '/configuracion-cuenta-inversora'
          if (!isErrorPages) {
            this.addClientId();
          }
        }

        if (!this.isCampanyaModDatos && !this.isCreditLimitNav && !this.isInvestmentNav) {
          this.addProduct();
        } else if (this.isCampanyaModDatos) {
          this.defaultNavData['process'] = 'Área Personal eCommerce';
        }

        if (eventUrl) {
          switch (eventUrl) {
            case '/confirmacion-datos':
            case '/proceso-firma':
            case '/contratacion-ci':
            case '/404':
            case '/OCR':
              if (this.loginSrv.userData) {
                if (errorEvoProtect) {
                  this.defaultNavData['process'] = 'Simulador microseguros';
                } else {
                  this.defaultNavData['process'] = 'Área personal eCommerce';
                }

              }
              break;
          }
          if (this.filterSteps(eventUrl) && !errorEvoProtect && !errorModData && !this.isCreditLimitNav && !this.isInvestmentNav) {
            this.triggerNavData(
              {
                'step': this.filterSteps(eventUrl),
                'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
                'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
              }
            )
          }
        }
      }
      if (window['ADRUM']) {
        const virtualPage = new window['ADRUM'].events.VPageView();
        window['ADRUM'].report(virtualPage);
      }

  }
  public subscribeToBroadcasts() {
    this.specificAnalytics.userIdBroadcast.subscribe((userId) => {
      this.userId = userId;
      this.addUserId(userId);
    });
  }
  public filterProfilePage(_page) {
    return this.profileFiltered[_page];
  }
  public filterMicrosegPage(_page) {
    return this.evoProtectFiltered[_page];
  }
  public filterSegundoTitularPage(_page) {
    return this.segundoTitularFiltered[_page];
  }

  public filterSegundoTitularPageLogin(_page) {
    return this.segundoTitularFilteredLogin[_page];
  }

  public filterAltaClientePage(_page) {
    return this.altaClienteFiltered[_page];
  }
  public filterSteps(step) {
    return this.filteredSteps[step];
  }

  public pushNavData(data: GtmData) {
    window['dataLayer'].push(data);
  }

  public triggerProfileNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileNavData,
      ...data
    });
  }

  public triggerProfileTarificationNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileTarificationNavData,
      ...data
    });
  }

  public triggerProfileModDataNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileModDataNavData,
      ...data
    });
    this.modDataErrorPageName = '';
  }

  public triggerCreditLimitDataNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileCreditLimitNavData,
      ...data
    });
    this.modDataErrorPageName = '';
    this.isCreditLimitNav = true;
    this.isInvestmentNav = false;
  }

  public triggerInvestmentDataNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileInvestmentNavData,
      ...data
    });
    this.modDataErrorPageName = '';
    this.isCreditLimitNav = false;
    this.isInvestmentNav = true;

  }

  public triggerSegundoTitularNavData(data?: GtmData) {

    data.clientId = sessionStorage.getItem("idClienteEvo");

    this.pushNavData({
      ...this.profileSegundoTitularNavData,
      ...data
    });
  }

  public triggerInvSegundoTitularNavData(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.clientId = clientData.datosPersonales.id_cliente_EVO;
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen') : '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion') : '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta') : '';
    if (clientData.caseIdSf) {
      data.product_id = `Cuenta Inteligente - ${clientData.caseIdSf}`;
    }

    this.pushNavData({
      ...this.profileInvSegundoTitularNavData,
      ...data
    });
  }

  public triggerInvSegundoTitularLogin(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.clientId = sessionStorage.getItem("idClienteEvo");
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    if(clientData.caseIdSf){
      data.product_id = `Cuenta Inteligente - ${clientData.caseIdSf}`;
    }
    
    this.pushNavData({
      ...this.profileInvSegundoTitularNavData,
      ...data
    });
  }

  public triggerSeguntoTitularPurchase(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.clientId = clientData.datosPersonales.id_cliente_EVO;
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    data.ecommerce = {
      'transaction_id': undefined,
      'currency': 'EUR',
      'value': 20,
      'items': [{
        'item_name': 'Cuenta Inteligente',
        'item_id': 'Cuenta Inteligente_generico'
      }]
    };
    if(clientData.caseIdSf){
      data.ecommerce.transaction_id = `${clientData.caseIdSf} ${Date.now()}`;
      data.product_id = `Cuenta Inteligente - ${clientData.caseIdSf}`;
    }
    this.pushNavData({
      ...this.profileSegundoTitularPurchase,
      ...data
    });
  }

  public triggerSegundoTitularBeginCheckout(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.clientId = clientData.datosPersonales.id_cliente_EVO;
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    data.product_id = `Cuenta Inteligente - ${clientData.caseIdSf}`;

    this.pushNavData({
      ...this.profileSegundoTitularBeginCheckout,
      ...data
    });
  }

  public triggerInvSegundoTitularForm(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.clientId = sessionStorage.getItem("idClienteEvo");
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    if(clientData.caseIdSf){
      data.product_id = `Cuenta Inteligente - ${clientData.caseIdSf}`;
    }

    this.pushNavData({
      ...this.profileInvSegundoTitularForm,
      ...data
    });
  }

  public triggerAltaClienteNavData(data?: GtmData) {
    this.pushNavData({
      ...this.profileAltaClienteNavData,
      ...data
    });
  }

  public triggerNavData(data?: GtmData) {
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.email) {
      data.mail = this._hashEmail(clientData.datosPersonales.email);
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';    
    this.defaultNavData['process'] = 'Contratación eCommerce';
    this.pushNavData({
      ...this.defaultNavData,
      ...data
    })
  }

  public triggerPurchase(data?: GtmData) {
    this.addProduct();
    this.addClientId();
    this.addEmail();    
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    data.ecommerce = {
      'transaction_id': undefined,
      'currency': 'EUR',
      'value': 20,
      'items': [{
        'item_name': undefined,
        'item_id': undefined
      }]
    };
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.ecommerce.transaction_id = `${clientData.caseIdSf} ${Date.now()}`;
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    this.profilePurchase['process'] = 'Contratación eCommerce';
    this.profilePurchase['product'] = this.defaultNavData['product'];
    this.profilePurchase['clientId'] = this.defaultNavData['clientId'];
    this.profilePurchase['registerType'] = this.defaultNavData['registerType'];
    this.profilePurchase['identificationType'] = this.defaultNavData['identificationType'] ? this.defaultNavData['identificationType'] : 'video';
    this.profilePurchase['mail'] = this.defaultNavData['mail'];
    data.ecommerce['items'][0]['item_name'] = this.defaultNavData['product']; 
    data.ecommerce['items'][0]['item_id'] = this.defaultNavData['product'] + '_generico';    
    this.pushNavData({
      ...this.profilePurchase,
      ...data
    });
  }

  public triggerBeginCheckout(data?: GtmData) {
    this.addProduct();
    this.addClientId();
    this.addEmail();
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    this.profileBeginCheckout['process'] = 'Contratación eCommerce';
    this.profileBeginCheckout['product'] = this.defaultNavData['product'];
    this.profileBeginCheckout['clientId'] = this.defaultNavData['clientId'];
    this.profileBeginCheckout['registerType'] = this.defaultNavData['registerType'];
    this.profileBeginCheckout['identificationType'] = this.defaultNavData['identificationType'];
    this.profileBeginCheckout['ecommerce']['items'][0]['item_name'] = this.defaultNavData['product']; 
    this.profileBeginCheckout['ecommerce']['items'][0]['item_id'] = this.defaultNavData['product'] + '_generico';
    this.profileBeginCheckout['mail'] = this.defaultNavData['mail'];
    this.pushNavData({
      ...this.profileBeginCheckout,
      ...data
    });
  }

  public triggerAddToCart(data?: GtmData, isRedirectedToCI?: boolean) {
    this.addProduct();
    this.addClientId();
    this.addEmail();
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    this.profileAddToCart['process'] = 'Contratación eCommerce';
    this.profileAddToCart['product'] = this.defaultNavData['product'];
    this.profileAddToCart['clientId'] = this.defaultNavData['clientId'];
    this.profileAddToCart['registerType'] = this.defaultNavData['registerType'];
    this.profileAddToCart['identificationType'] = this.defaultNavData['identificationType'];
    this.profileAddToCart['ecommerce']['items'][0]['item_name'] = this.defaultNavData['product'];
    this.profileAddToCart['ecommerce']['items'][0]['item_id'] = this.defaultNavData['product'] + '_generico';
    this.profileAddToCart['mail'] = this.defaultNavData['mail'];

    //Comprobamos si se ha redirigido a la CI y actualizamos el objeto profileAddToCart con CI ///MEIFO-7517
    if (isRedirectedToCI) {
      this.profileAddToCart['product'] = 'Cuenta Inteligente';
      this.profileAddToCart['ecommerce']['items'][0]['item_name'] = 'Cuenta Inteligente';
      this.profileAddToCart['ecommerce']['items'][0]['item_id'] = 'Cuenta Inteligente_generico';
    }

    this.pushNavData({
      ...this.profileAddToCart,
      ...data
    });
  }

  public triggerCTA(data?: GtmData) {
    this.addProduct();
    this.addClientId();
    this.addEmail();
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    this.profileCTA['process'] = 'Contratación eCommerce';
    this.profileCTA['step'] = this.filterSteps(this.router.url);
    this.profileCTA['product'] = this.defaultNavData['product'];
    this.profileCTA['clientId'] = this.defaultNavData['clientId'];
    this.profileCTA['registerType'] = this.defaultNavData['registerType'];
    this.profileCTA['identificationType'] = this.defaultNavData['identificationType'];
    this.profileCTA['mail'] = this.defaultNavData['mail'];
    this.pushNavData({
      ...this.profileCTA,
      ...data
    });
  }

  public triggerC2C(data?: GtmData) {
    this.addProduct();
    this.addClientId();
    this.addEmail();
    const clientData = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (clientData && clientData.datosPersonales && clientData.datosPersonales.id_cliente_EVO) {
      data.clientId = clientData.datosPersonales.id_cliente_EVO;
      if (clientData.caseIdSf) {
        data.product_id = `${this.defaultNavData['product']} - ${clientData.caseIdSf}`;
      }
    }
    data.origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
    data.seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
    data.oferta = sessionStorage.getItem('oferta') ? sessionStorage.getItem('oferta'): '';
    this.profileC2C['process'] = 'Contratación eCommerce';
    this.profileCTA['step'] = this.filterSteps(this.router.url);
    this.profileC2C['product'] = this.defaultNavData['product'];
    this.profileC2C['clientId'] = this.defaultNavData['clientId'];
    this.profileC2C['registerType'] = this.defaultNavData['registerType'];
    this.profileC2C['identificationType'] = this.defaultNavData['identificationType'];
    this.profileC2C['mail'] = this.defaultNavData['mail'];
    this.pushNavData({
      ...this.profileC2C,
      ...data
    });
  }

  public triggerProfileFormData(form, submitStatus) {

    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };

    if(!this.isSecondSigner){
      const formattedData = {
        'eventAction': `Formulario ${form}`,
        'eventLabel': submitStatus
      }
      this.pushFormData({
        ...this.profileFormData,
        ...formattedData
      })
    }
  }

  public triggerProfileModDataFormData(submitStatus, pageName?) {

    if (pageName) {
      this.modDataErrorPageName = pageName;
    }

    const formattedData = {
      'eventAction': `Formulario Modificación de datos DNI`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerProfileTarificationFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileTarificationFormData,
      ...formattedData
    })
  }

  public triggerExtendLimitFormData(submitStatus, requestedLimit) {

    this.isCreditLimitNav = true;
    this.isInvestmentNav = false;

    const formattedData = {
      'eventAction': `Formulario Límite de crédito`,
      'eventLabel': submitStatus,
      'eventValue': requestedLimit
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerInvestmentFormData(submitStatus) {

    this.isCreditLimitNav = false;
    this.isInvestmentNav = true;

    const formattedData = {
      'eventAction': `Formulario Modificación de datos`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.profileFormData,
      ...formattedData
    })
  }

  public triggerSegundoTitularFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.segundoTitularFormData,
      ...formattedData
    })
  }

  public triggerSegundoTitularCta(form) {

  }

  public triggerAltaClienteFormData(form, submitStatus) {
    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.altaClienteFormData,
      ...formattedData
    })
  }

  public triggerFormData(form, submitStatus) {
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };

    if(!this.isSecondSigner){
      const formattedData = {
        'eventAction': `Formulario ${form}`,
        'eventLabel': submitStatus
      }
      if (location.hash !== '#/confirmacion-otp-primer-paso') {
        this.defaultFormData['eventCategory'] = 'Área Personal eCommerce';
      }

      this.pushFormData({
        ...this.defaultFormData,
        ...formattedData
      })
    }
  }
  public triggerFormDataPersonalDate(form, submitStatus) {

    const formattedData = {
      'eventAction': `Formulario ${form}`,
      'eventLabel': submitStatus
    }
    this.pushFormData({
      ...this.defaultFormData,
      ...formattedData
    })
  }


  public triggerFormDataCICJ(form, submitStatus) {
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };

    if(!this.isSecondSigner){
      const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
      const origen = sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '';
      const seccion = sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): '';
      const formattedData = {
        'event': 'formulario',
        'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
        'eventAction': `Formulario ${form}`,
        'eventLabel': submitStatus,
        'cuentaId': undefined,
        'origen':origen,
        'seccion':seccion,
        'clientId': this.getClientId()
      }
      this.pushFormData({
        ...formattedData
      })
    }
  }

  public triggerFormDataIdentificationCICJ(form, submitStatus, clientId) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const formattedData = {
      'event': 'form_sent',
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': `'Acceso a información sobre identificación`,
      'eventLabel': submitStatus,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushFormData({
      ...formattedData,
      clientId
    })
  }
  public triggerFormDatCICJ(eventAction, eventLabel) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const formattedData = {
      'event': 'form_sent',
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': eventAction,
      'eventLabel': eventLabel,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushFormData({
      ...formattedData
    })
  }

  public triggerModRentista(event) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const formattedData = {
      'event': 'CTA',
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': 'Pop up Situación laboral Rentista',
      'eventLabel': event,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushFormData({
      ...formattedData
    })
  }
  public triggerModAlreadyUserTemp(event ,action , label) {
    const formattedData = {
      'event': event,
      'eventCategory':'Contratación Cuenta Inteligente',
      'eventAction': action,
      'eventLabel': label,
      'clientId': this.getClientId()
    }
    this.pushFormData({
      ...formattedData
    })
  }
  public triggerModUserExist(action ) {
    const formattedData = {
      'event': 'form_sent',
      'eventCategory':'Contratación Cuenta Inteligente',
      'eventAction': action,
      'eventLabel': 'Envío KO: DNI ya existente',
      'clientId': this.getClientId()
    }
    this.pushFormData({
      ...formattedData
    })
  }
  public triggerFormPD(submitStatus) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const formattedData = {
      'event': 'CTA',
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': `Log in - web`,
      'eventLabel': submitStatus,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushFormData({
      ...formattedData
    })
  }

  public triggerGenericaCTACiCj(event? ,eventAction? , eventLabel? , clientId?) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const formattedData = {
      'event': event,
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': eventAction,
      'eventLabel': eventLabel,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushFormData({
      ...formattedData,
      clientId
    })
  }

  public pushFormData(data: GtmData) {
    window['dataLayer'].push(data);
  }

  public triggerNotificationAction(eventAction, eventLabel?) {
    const filteredData = {
      'eventAction': eventAction,
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...this.profileNotification,
      ...filteredData
    })
  }

  public triggerTarificationNotificationAction(eventAction, eventLabel?) {
    const filteredData = {
      'eventAction': eventAction,
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...this.profileTarificationNotification,
      ...filteredData
    })
  }

  public triggerErrorAction(eventProfile, eventLabel?) {
    const filteredData = {
      'eventLabel': eventLabel
    }
    this.pushFormData({
      ...eventProfile,
      ...filteredData
    })
  }

  public triggerInteractionData(eventAction, eventLabel?, clientId?) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    let isTipoA = sessionStorage.getItem("altaClienteA") === "true";
    const filteredData = {
      'eventAction': eventAction,
      'eventCategory': isTipoA ? 'Captación clientes tipo A' : isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventLabel': eventLabel,
      'clientId': clientId
    }
    this.pushInteractionData({
      ...this.defaultInteractionData,
      ...filteredData
    })
  }
  public triggerInteractionDataCICJ(eventAction, eventLabel?, clientId?) {
    
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };

    if(!this.isSecondSigner){
      const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
      const filteredData = {
        'eventAction': eventAction,
        'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
        'eventLabel': eventLabel,
        'cuentaId': undefined,
        'clientId': this.getClientId(),
        'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
        'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
      }
      this.pushInteractionData({
        ...this.defaultInteractionData,
        ...filteredData
      })
    }
  }

  public optimizationFunnel(nameForm, field, status) {
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };

    if(!this.isSecondSigner){
      const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
      const filteredData = {
        'event': 'CTA',
        'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
        'eventAction': 'Formulario ' + nameForm + ' : ' + field,
        'eventLabel': status,
        'cuentaId': undefined,
        'clientId': this.getClientId(),
        'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
        'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
      }
      this.pushInteractionData({
        ...filteredData
      })
    }
  }

  public analitycsNieError(status) {
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    const filteredData = {
      'event': 'CTA',
      'eventCategory': isYoungAccount ? 'Contratación Cuenta Joven' : 'Contratación Cuenta Inteligente',
      'eventAction': 'Pop up NIE sin foto',
      'eventLabel': status,
      'cuentaId': undefined,
      'clientId': this.getClientId(),
      'origen':sessionStorage.getItem('origen') ? sessionStorage.getItem('origen'): '',
      'seccion':sessionStorage.getItem('seccion') ? sessionStorage.getItem('seccion'): ''
    }
    this.pushInteractionData({
      ...filteredData
    })
  }
  public analityPopUpPerfilado(eLabel) {
    const filteredData = {
      'event': 'CTA',
      'eventCategory':'Contratación Cuenta Inteligente',
      'eventAction': 'Pop up perfilado',
      'eventLabel': eLabel,
      'clientId': this.getClientId()
    }
    this.pushInteractionData({
      ...filteredData
    })
  }
  public pushInteractionData(data: GtmData) {
    const element = Object.assign({}, data);
    window['dataLayer'].push(element);
  }

  private _hashId(email) {
    return sha256(email);
  }

  public createHashId() {
    const session = this.authService.getSessionProspect();
    if (session.datosPersonales && !this.dataToHash) {
      this.dataToHash = session.datosPersonales.telefonoMovil ? session.datosPersonales.telefonoMovil : session.datosPersonales.email
    }

    if (this.dataToHash) {
      return this._hashId(this.dataToHash).toString();
    }
  }

  public getClientId() {
    if (this.loginSrv.userData && this.loginSrv.userData.persona) {
      return JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
    } else if (this.loginSrv.userData && this.loginSrv.userData.userinfo) {
      return JSON.parse(sessionStorage.getItem('userData')).userinfo.usuario;
    } else {
      const session = this.authService.getSessionProspect();
      if (session.datosPersonales) {
        return session.datosPersonales.id_cliente_EVO;
      }
    }
  }

  public getEmail() {
    const session = this.authService.getSessionProspect();
    if (session.datosPersonales && session.datosPersonales.email)
      return this._hashEmail(session.datosPersonales.email);
    else return undefined;
  }

  private _hashEmail(email) {
    return shajs('sha256').update(email).digest('hex');
  }

  public addProduct() {
    let isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
    let isCuentaInteligente = !this.authService.product || this.authService.product === ACCOUNTS.CICode;
    let isCuentaInversora = this.authService.product === ACCOUNTS.CINVCode;
    let isFondoInteligente = this.authService.product === ACCOUNTS.FINTCode;
    let isSegundoTitular = this.authService.product === ACCOUNTS.STCode;
    let product = 'Área Personal eCommerce';
    if (isYoungAccount) {
      product = 'Cuenta Joven';
    } else if (isCuentaInteligente || isSegundoTitular) {
      product = 'Cuenta Inteligente';
    } else if (isCuentaInversora) {
      product = 'Cuenta Inversora';
    } else if (isFondoInteligente) {
      product = 'Fondo Inteligente';
    }
    return this.setProduct(product);
  }

  public setProduct(product) {
    if (product === 'Área Personal eCommerce') {
      this.defaultFormData['eventCategory'] = 'Área Personal eCommerce';
    }
    this.defaultNavData['product'] = product;
    if (location.hash.indexOf('evo-protect') !== -1) {
      if (location.hash.indexOf('tarificador') !== -1) {
        this.defaultInteractionData['eventCategory'] = 'Simulador de microseguros';
        this.defaultFormData['eventCategory'] = 'Simulador de microseguros';
      }
      else {
        this.defaultInteractionData['eventCategory'] = 'Simulador de microseguros';
        this.defaultFormData['eventCategory'] = 'Simulador de microseguros';
      }

      return;
    }
    if (this.loginSrv.userData) {
      const acronym = product === 'Cuenta Inteligente' ? 'CI' : 'CJ';
      this.defaultInteractionData['eventCategory'] = `Área Personal eCommerce - Contratación ${acronym}`;
      this.defaultFormData['eventCategory'] = `Área Personal eCommerce - Contratación ${acronym}`;
    } else {
      this.defaultInteractionData['eventCategory'] = `Contratación ${product}`;
      this.defaultFormData['eventCategory'] = `Contratación ${product}`;
    }
  }

  public addUserId(userId) {
    this.profileCreditLimitNavData['userId'] = userId;
    this.profileInvestmentNavData['userId'] = userId;
    this.profileAltaClienteNavData['userId'] = userId;
    return this.profileNavData['userId'] = userId;
  }

  public addClientId() {
    return this.defaultNavData['clientId'] = this.getClientId();
  }

  public addEmail() {
    return this.defaultNavData['mail'] = this.getEmail();
  }

  public addIdentificationType(eventUrl) {
    const isVideoPages = eventUrl === '/previa-eid' 
      || eventUrl === '/identificacion-video' 
      || eventUrl === '/confirmacion-datos' 
      || eventUrl === '/previa-firma'
      || eventUrl === '/proceso-firma' 
      || eventUrl === '/ahora-nosotros';
      
    if (isVideoPages) {
      this.defaultNavData['identificationType'] = 'video';
    }
  }

  public addRegisterType() {
    const persona = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (persona && persona.datosPersonales) {
      if (persona.datosPersonales.telefonoMovil && !persona.datosPersonales.email) {
        this.defaultNavData['registerType'] = 'móvil';
      } else if (!persona.datosPersonales.telefonoMovil && persona.datosPersonales.email) {
        this.defaultNavData['registerType'] = 'correo';
      }
    }
  }

  public addTarificationEvent(step, value?) {
    if (this.errorSteps(step)) return;
    if (value) {
      value = value.replace(',', '.');
    }
    switch (step) {
      case 1:
        this.globalTarification['eventLabel'] = "Paso 01 - Acceso a formulario";
        delete this.globalTarification['eventValue'];
        break;
      case 2:
        this.globalTarification['eventLabel'] = "Paso 02 - Precio calculado";
        this.globalTarification['eventValue'] = value;
        break;
      case 3:
        this.globalTarification['eventLabel'] = "Paso 03 - Me interesa contratar";
        this.globalTarification['eventValue'] = value;
        break;
      case 4:
        this.globalTarification['eventAction'] = 'Log in';
        this.globalTarification['eventLabel'] = "Pop up microseguros";
        delete this.globalTarification['eventValue'];
        break;
      case 5:
        this.globalTarification['eventAction'] = 'Acceso a  v2.cuentainteligente.evobanco.com';
        this.globalTarification['eventLabel'] = "Pop up microseguros";
        delete this.globalTarification['eventValue'];
        break;
    }

    this.pushInteractionData(this.globalTarification);
  }

  public cancelAdhesionbulletin(step) {
    if (this.errorSteps(step)) return;
    switch (step) {
      case 1:
        this.unsubscribe['eventLabel'] = "Paso 01 - Cancelar Boletín de Adhesión"
        break;
      case 2:
        this.unsubscribe['eventLabel'] = "Paso 02 - Confirmar Cancelación"
        break;
    }
    this.pushInteractionData(this.unsubscribe);
  }

  public addPageLikeEvent(step) {
    if (this.errorSteps(step)) return;
    let pageValue = ''
    switch (step) {
      case 1:
        pageValue = "EVO Protect/Post Logalty Boletín de Adhesión";
        break;
      case 2:
        pageValue = "EVO Protect/Paso 02 – Capital cuota";
        break;
      case 3:
        pageValue = "EVO Protect/Simulador Capital cuota";
        break;
    };
    this.triggerProfileTarificationNavData(
      { 'pageName': pageValue }
    );
  }

  public errorSteps(step) {
    if (!step) {
      console.error('No se ha indicado un paso en el flujo de analítica');
      return true
    };
    return false
  }

  public stopProfileNavTags() {
    this.isCampanyaModDatos = true;
  }

}
