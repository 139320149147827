<loading [showMe]="loading"></loading>
<div
  class="main-register flex-direction">
  <box-image
      [classImgFile]="'imagen3-cj'"
      [showTextBox]="false"
      class="image-div"
    >
  ></box-image>
  <div class="form-and-step-div">
    <steps-ci [youngAccount]="true" class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div form-25">
      <p class="letter title-25">¿Menos de 25?</p>
      <p class="letter subtitle-25">
        La Cuenta Joven es para menores de 25 años, es gratuita y está llena de ventajas:
      </p>
      <div class="square-list">
        <ul class="square-ul">
          <li>
            <span class="text-list">
              Sin comisiones ni requisitos.
            </span>
          </li>
          <li>
            <span class="text-list">
              Paga fácil con tu móvil. Transferencias inmediatas y gratuitas y servicio Bizum.
            </span
            >
          </li>
          <li>
            <span class="text-list">
              Cajeros sin comisiones. 18.000 cajeros son muchos cajeros sin comisiones.
            </span>
          </li>
        </ul>
      </div>
      <p class="letter subtitle-25">
        <strong>Si tienes 25 años o más,</strong> tu producto es la Cuenta Inteligente, con muchas ventajas y sin límite de edad.
      </p>
      <div class="btn-group separatorLine">
        <hr>
          <div class="separatorLine-buttons">
          <button type="button" class="goToCI-button" (click)="gotoCI()">
            <span class="goToCI-text">IR A LA CUENTA INTELIGENTE </span>
          </button>
          <button type="button" class="goToCJ-button" (click)="gotoCJ()">
            <span class="goToCJ-text">TENGO MENOS DE 25 AÑOS</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
