import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

import { Persona } from "../../../collections/persona";
import { Register } from "../../../collections/register";

import { SITUACIONESLABORALES } from "../../../resources/situacion-laboral.model";
import { PROFESIONES } from "../../../resources/profesiones.model";
import { TIPOACTIVIDADPROFESIONAL } from "../../../resources/tipo-actividad-profesional.model";
import { CNAE } from "../../../resources/cnae.model";
import { ERRORES } from "../../../resources/errors.model";
import { ANTIGUEDAD } from "../../../resources/antiguedad.model";
import { TIPOSCONTRATO } from "../../../resources/tipo-contrato.model";

import { AuthService } from "../../../services/auth.service";
import { JsonService } from "../../../services/json.service";
import { FormValidatorService } from "../../../services/form-validator.service";
import { ErrorService } from "../../../services/error.service";
import { DeviceService } from "../../../services/device.service";
import { ModalService } from "../../../services/modal.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { HelperService } from "../../../services/helpers.service";
import { environment } from "../../../../environments/environment";
import { HeaderService } from "../../../services/headers.service";
import { Modal } from "../../../collections/modal";
import { ACCOUNTS } from "app/resources/account.model";
import { IbanService } from "../../../services/iban.service";
import { ApiIbanService } from "../../..//services/api/api.iban.service";
import { REGISTERLITERALS } from "app/resources/register-literals";
declare var dataLayer;
@Component({
  selector: "app-labor-details",
  templateUrl: "./labor-details.component.html",
  styleUrls: ["./labor-details.component.scss"],
  providers: [
    FormValidatorService,
    DeviceService,
    ModalService,
    JsonService,
    ApiIbanService,
    IbanService,
  ],
})
export class LaborDetailsComponent implements OnInit {
  loading: boolean = false;
  laborDetailsForm: FormGroup;
  situaciones = SITUACIONESLABORALES;
  profesion = PROFESIONES;
  tipoActividadProfesional = [
    { name: "Tipo de actividad profesional", value: null, profesion: null },
  ];
  descripcionActividadProfesional = [
    { name: "Descripcion profesional", value: null, profesion: null },
  ];
  cnae = CNAE;
  years = ANTIGUEDAD;
  noSelectedProfession = true;
  noSelectedTipeOfActivity = true;
  errors = ERRORES;
  contracts = TIPOSCONTRATO;
  modalRentista = "modalRentista";
  mobileErrors = [];
  descriptionCNO = [];
  modal = new Modal();
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg =
    `Lo sentimos, no podemos seguir con la solicitud online. Llámanos al ` +
    this.telephoneMsg +
    ` y te explicamos los motivos.`;
  incompleteMsg =
    "Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.";
  public isCIAccount: boolean = false;
  private currentProfesion = null;
  private currentTipoActividad = null;
  private persona: Persona = this.authService.getSessionProspect();
  private fechaAntiguedadLaboral;
  public isYoungAccount: boolean = false;
  private flujoOtpPrimerPaso: boolean = true;
  private situacionLaboral: String = "";
  tooltipEnabled = true;
  saveModal = "SaveDataSuccessModalCI";
  backModal = "BackModalCI";
  public CLIENTE_RELACIONES_CANCELADAS: boolean = false;
  public literals = REGISTERLITERALS[this.isYoungAccount ? "young" : "default"];
  titleBox: string =
  REGISTERLITERALS[
    this.deviceService.showTabletDesign()
      ? "laborBoxtitleTablet"
      : "laborBoxtitleDesktop"
  ];
  mainTitleCIMobile: string = this.literals["mainTitleMobile"];
  mainTitleCIDesktop: string = this.literals["mainTitle"];  


  @HostListener("window:resize", ["$event"])
  onResize(_event) {
    //this.listenScrollChange();
    this.titleBox =
      REGISTERLITERALS[
        this.deviceService.showTabletDesign()
          ? "laborBoxtitleTablet"
          : "laborBoxtitleDesktop"
      ];
  }  

  @HostListener("window:beforeunload", ["$event"])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    private authService: AuthService,
    private jsonService: JsonService,
    public deviceService: DeviceService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private ibanSrv: IbanService,
    private cdRef: ChangeDetectorRef
  ) {
    this.isCIAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CICode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CICode;
    this.tipoActividadProfesional = this.tipoActividadProfesional.concat(
      TIPOACTIVIDADPROFESIONAL
    );
  }

  ngOnInit() {
    this.CLIENTE_RELACIONES_CANCELADAS =
      JSON.parse(sessionStorage.getItem("currentProspect")).claseDeCliente ===
      "03";
    this.createForm();
    this.errService.mobileErrors(this.laborDetailsForm);
    this.isYoungAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CJCode ||
      location.host === environment.hostYoungAccount;
    this.flujoOtpPrimerPaso = this.persona.flujoOtpPrimerPaso;
    if (this.authService.product !== ACCOUNTS.STCode) {
      const data = Object.assign({
        abandono: true,
        flujoOtpPrimerPaso: true,
        activeOtpFirstStep: true,
      });
      this.authService.setSessionProspect(data);
    }
  }

  ngAfterViewInit() {
    if (this.CLIENTE_RELACIONES_CANCELADAS) {
      return;
    }
    if (this.laborDetailsForm.get("profesion").value) {
      this.currentProfesion = this.laborDetailsForm.get("profesion").value;
      this.tipoActividadProfesional = this.filterTipoActividad;
      this.noSelectedProfession = false;
      setTimeout(
        () =>
          this.helpers.updateFieldValue(
            this.laborDetailsForm,
            "tipoActividad",
            this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoCNO"
            )
          ),
        0
      );
    }
    if (this.laborDetailsForm.get("tipoActividad").value) {
      this.noSelectedTipeOfActivity = false;
    }
    this.cdRef.detectChanges();
  }
  onSubmit() {
    const ingresos = this.laborDetailsForm.get("ingresosMensuales"),
      pagas = this.laborDetailsForm.get("numeroPagas"),
      invalid = { valid: false };

    this.validateAdditionalFields();
    this.helpers.updateFormStatus(this.laborDetailsForm);
    this.triggerErrorAnalytics();

    //Se quita el limite de ingresos y el pop-up informativo
    /*if (!this.isSalaryCorrect) {
      window.scrollTo(0, 0);
      ingresos.setErrors(invalid);
      pagas.setErrors(invalid);
      this.modal = Object.assign({
        title: "¡Vaya!",
        message: this.exceptionMsg,
        withImage: true,
        blocking: false,
      });
      this.modService.open(this.modal.name);
      return false;
    }*/

    if (!this.laborDetailsForm.valid) {
      window.scrollTo(0, 0);

      this.modal = Object.assign({
        title: "¡Ups!",
        withImage: false,
        blocking: false,
        message: this.incompleteMsg,
      });

      this.modService.open(this.modal.name);
      return false;
    }

    this.setFields();
    this.sendProspect();
  }

  triggerErrorAnalytics() {
    const invalidString = this.setTipeError();
    if (invalidString !== "")
      return;
  }
  setTipeError(): any {
    let countWrongLabel = 0;
    let countWhiteLabel = 0;
    let typeError = "";
    const invalidFields = this.errService.errorsArray.map((error) => {
      countWhiteLabel++;
      typeError = "Envio KO: Campos pendientes de completar";
      if (error.field && this.laborDetailsForm.get(error.field).value) {
        countWrongLabel++;
      }
    });
    if (countWhiteLabel > 0 && countWhiteLabel === countWrongLabel) {
      typeError = "Envio KO: Campos erróneos";
    }
    return typeError;
  }

  maxAndminLengthCheck(event: any, tipo: any, min: any, max: any) {
    if (event < min) {
      this.laborDetailsForm.get(tipo).setValue(Math.abs(event));
    }
    if (event === null) {
      this.laborDetailsForm.get(tipo).setValue("");
    }
    if (event % 1 !== 0) {
      this.laborDetailsForm.get(tipo).setValue("");
    }
    if (event > max) {
      this.laborDetailsForm
        .get(tipo)
        .setValue(String(event).slice(0, String(max).length));
    }
  }
  maxAndminLengthCheckCI(event: any, tipo: any, min: any, max: any) {
    const valueInput = this.laborDetailsForm.get(tipo).value;
    if (valueInput < min) {
      this.laborDetailsForm.get(tipo).setValue(Math.abs(valueInput));
    }
    if (valueInput === null) {
      this.laborDetailsForm.get(tipo).setValue("");
    }
    if (valueInput % 1 !== 0) {
      this.laborDetailsForm.get(tipo).setValue("");
    }
    if (valueInput > max) {
      this.laborDetailsForm
        .get(tipo)
        .setValue(String(valueInput).slice(0, String(max).length));
    }
  }
  get filterTipoActividad() {
    const selection = this.laborDetailsForm.get("profesion").value;
    let tipoActividadProfesional = [];
    tipoActividadProfesional.push({
      name: "Tipo de actividad profesional",
      value: null,
      profesion: null,
    });
    if (selection === "" && selection === null) return tipoActividadProfesional;
    tipoActividadProfesional = tipoActividadProfesional.concat(
      TIPOACTIVIDADPROFESIONAL
    );
    tipoActividadProfesional = tipoActividadProfesional.filter((item) => {
      return selection === item.profesion;
    });
    const filteredActividadProfesional = tipoActividadProfesional.unshift({
      name: "Tipo de actividad profesional",
      value: null,
      profesion: null,
    });
    return tipoActividadProfesional;
  }

  get filterDescriptionActivity(): any {
    const selection = this.laborDetailsForm.get("tipoActividad").value;
    let descripcion = [];
    descripcion.push({
      name: "Descripcion profesional",
      value: null,
      profesion: null,
    });
    if (selection === "" && selection === null) return descripcion;
    descripcion = descripcion.concat(TIPOACTIVIDADPROFESIONAL);
    descripcion = descripcion.filter((item) => {
      return (
        item.name === selection ||
        (item.value === selection && item.name !== "Descripcion profesional")
      );
    });
    return descripcion;
  }

  valueOfdescription(): any {
    const activity = this.filterDescriptionActivity;
    if (activity.length) {
      this.descriptionCNO = this.clearArray(activity[0].descripcion_cno__c);
      this.descriptionCNO.unshift({
        name: "Descripcion profesional",
        value: null,
        profesion: null,
      });
    }
    if (this.persona.datosSocioEco.agrupacionCNO) {
      this.descriptionCNO.filter((description) => {
        if (description.name === this.persona.datosSocioEco.agrupacionCNO) {
          this.helpers.updateFieldValue(
            this.laborDetailsForm,
            "Descripcion_CNO__c",
            description.value
          );
        }
      });
    }
    return this.descriptionCNO;
  }
  clearArray(array) {
    array = array.filter((item) => {
      if (item.name !== "Descripcion profesional") {
        return item.name;
      }
    });
    return array;
  }

  get situacionLaboralValue() {
    const currentSituacion =
        this.laborDetailsForm.get("situacionLaboral").value,
      situacionObj = this.situaciones.filter((situacion) => {
        return situacion.value === currentSituacion;
      });
    return situacionObj[0]["name"];
  }

  get isSalaryCorrect() {
    const salary = this.laborDetailsForm.get("ingresosMensuales").value,
      pays = this.laborDetailsForm.get("numeroPagas").value;
    if (salary > 80000) return false;
    return salary * pays < 1000000;
  }

  get isAutonomo() {
    return (
      this.laborDetailsForm.get("situacionLaboral") &&
      this.laborDetailsForm.get("situacionLaboral").value === "02"
    );
  }

  get isFuncionario() {
    return (
      this.laborDetailsForm.get("situacionLaboral") &&
      this.laborDetailsForm.get("situacionLaboral").value === "12"
    );
  }

  get isOneField() {
    return this.laborDetailsForm.get("situacionLaboral").value != null &&
      !this.isAutonomo &&
      !this.isCuentaAjena &&
      !this.isJubiladoPensionanistaRentista &&
      !this.isFuncionario
      ? "flex-btn"
      : "flex-btn-center";
  }

  get isOneFieldForm() {
    return this.laborDetailsForm.get("situacionLaboral").value != null &&
      !this.isAutonomo &&
      !this.isCuentaAjena &&
      !this.isJubiladoPensionanistaRentista &&
      !this.isFuncionario
      ? "flex-form"
      : "";
  }

  get isCuentaAjena() {
    return (
      this.laborDetailsForm.get("situacionLaboral") &&
      this.laborDetailsForm.get("situacionLaboral").value === "01"
    );
  }

  get isJubiladoPensionanistaRentista() {
    return (
      (this.laborDetailsForm.get("situacionLaboral") &&
        this.laborDetailsForm.get("situacionLaboral").value === "05") ||
      this.laborDetailsForm.get("situacionLaboral").value === "11"
    );
  }

  sendProspect() {
    let nextStep = "/metodo-validacion";
    if (this.authService.product === ACCOUNTS.STCode) {
      nextStep = "/confirmacion-otp";
    }

    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        if (
          data.response.codigo !== "OK" &&
          data.response.codigo !== "OK_EID_CONFIRMA"
        ) {
          this.modal = this.modService.selectStatusModal(data.response);
          this.modService.open(this.modal.name, this.modal.blocking);
          return;
        }
        if (sessionStorage.getItem("cProspect") && JSON.parse(sessionStorage.getItem("currentProspect")).claseDeCliente !=="03") {
          const currentProspect: any = sessionStorage.getItem("cProspect");
          this.ibanSrv.setSessionPreviousIban(currentProspect);
          nextStep = "/confirmacion-datos";
        }
        if (this.authService.isLoggedIn) {
          this.authService.pageSubmitted = "datos-laboral";
          this.loading = true;
          this.helpers.navigateTo(nextStep);
        } else {
          this.loading = false;
          this.helpers.navigateTo(nextStep);
        }
      },
      (error) => {
        this.error();
      }
    );
  }

  error() {
    this.errService.navToError();
  }

  createForm() {
    this.laborDetailsForm = this.fb.group({
      situacionLaboral: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoSituacionLaboral"
            ) || null,
        [Validators.required],
      ],
      tipoContrato: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoContrato"
            ) || null,
      ],
      profesion: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.profesion"
            ) || null,
      ],
      tipoActividad: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.initCNO(
              this.jsonService.getKeyFromJSON(
                this.persona,
                "datosSocioEco.codigoCNO"
              )
            ) || null,
      ],
      duracionTrabajo: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.getSeniorityFromJson(
              this.jsonService.getKeyFromJSON(
                this.persona,
                "datosSocioEco.fechaAntiguedadLaboral"
              )
            ) || null,
      ],
      Descripcion_CNO__c: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoCNO"
            ) || null,
      ],
      fechaAltaAutonomo: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? null
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.fechaAltaAutonomo"
            ) || "",
      ],
      cnae: [
        this.jsonService.getKeyFromJSON(
          this.persona,
          "datosSocioEco.codigoCNAE"
        ) || null,
      ],
      ingresosMensuales: [
        this.jsonService.getKeyFromJSON(
          this.persona,
          "datosSocioEco.ingresosMensuales"
        ) || "",
      ],
      numeroPagas: [
        this.CLIENTE_RELACIONES_CANCELADAS
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.numeroPagas"
            ) || "",
        [Validators.max(20)],
      ],
    });
    this.currentProfesion = this.CLIENTE_RELACIONES_CANCELADAS
      ? null
      : this.jsonService.getKeyFromJSON(
          this.persona,
          "datosSocioEco.profesion"
        ) || null;
    this.formSubscribe();
    /**
     * Abrir modal si el usuario es rentista
     *
     */
    setTimeout(() => {
      this.openModalRentista();
    }, 1);
  }

  formSubscribe() {
    const tipoActividad = this.laborDetailsForm.get("tipoActividad");

    this.laborDetailsForm
      .get("situacionLaboral")
      .valueChanges.subscribe((control) => {
        this.validateAdditionalFields();
        //this.laborDetailsForm.markAsUntouched();
      });

    this.laborDetailsForm.valueChanges.subscribe((form) => {
      this.authService.saveUpdatedForm(this.laborDetailsForm, this, form);
      this.errService.mobileErrors(this.laborDetailsForm);
    });

    this.laborDetailsForm
      .get("profesion")
      .valueChanges.subscribe((profesion) => {
        if (!profesion) {
          this.noSelectedProfession = true;
        } else {
          this.noSelectedProfession = false;
        }

        if (profesion !== this.currentProfesion) {
          this.currentProfesion = profesion;
          this.helpers.updateFieldValue(this.laborDetailsForm, "tipoActividad");
          this.descripcionActividadProfesional = [
            { name: "Descripcion profesional", value: null, profesion: null },
          ];
        }

        this.tipoActividadProfesional = this.filterTipoActividad;
      });
    this.laborDetailsForm
      .get("tipoActividad")
      .valueChanges.subscribe((tActividad) => {
        if (!tActividad) {
          this.noSelectedTipeOfActivity = true;
        } else {
          this.noSelectedTipeOfActivity = false;
        }
        if (tActividad !== this.currentTipoActividad) {
          this.currentTipoActividad = tActividad;
          this.helpers.updateFieldValue(
            this.laborDetailsForm,
            "Descripcion_CNO__c"
          );
          this.descripcionActividadProfesional = this.valueOfdescription();

          //Si viene codigoCNO en el JSON precargamos el valor.
          if (
            !!this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoCNO"
            )
          ) {
            let valueDesc = this.descripcionActividadProfesional.find(
              (x) =>
                x.value ===
                this.jsonService.getKeyFromJSON(
                  this.persona,
                  "datosSocioEco.codigoCNO"
                )
            );

            if (!!valueDesc) {
              this.laborDetailsForm.controls["Descripcion_CNO__c"].setValue(
                valueDesc.value
              );
            }
          }
        }
      });

    /*if (this.laborDetailsForm.get('profesion').value) {
        if (profesion !== this.currentProfesion) {
          this.currentProfesion = profesion;
          this.helpers.updateFieldValue(this.laborDetailsForm, "tipoActividad");
        }
        this.tipoActividadProfesional = this.filterTipoActividad;
      });*/

    if (this.laborDetailsForm.get("profesion").value) {
      this.tipoActividadProfesional = this.filterTipoActividad;
      setTimeout(() => {
        this.helpers.updateFieldValue(
          this.laborDetailsForm,
          "tipoActividad",
          this.initCNO(
            this.jsonService.getKeyFromJSON(
              this.persona,
              "datosSocioEco.codigoCNO"
            )
          )
        ),
          0;
      });
    }
  }

  initCNO(value) {
    if (value) {
      const filteredTipo = TIPOACTIVIDADPROFESIONAL.filter((tipoActividad) => {
        for (
          let index = 0;
          index < tipoActividad.descripcion_cno__c.length;
          index++
        ) {
          const des: any = tipoActividad.descripcion_cno__c[index];
          if (des.value === value) {
            return des;
          }
        }
      });
      return filteredTipo[0] ? filteredTipo[0]["name"] : "";
    }
    return undefined;
  }

  setCNO(value) {
    const regex = /^[0-9]*$/;
    if (value) {
      if (!regex.test(value)) {
        const filteredTipo = TIPOACTIVIDADPROFESIONAL.filter(
          (tipoActividad) => {
            return (
              tipoActividad.name === value || tipoActividad.value === value
            );
          }
        );
        return filteredTipo[0]["value"];
      } else return value;
    }
    return undefined;
  }

  setFields() {
    const canal = this.deviceService.getDevice(),
      duracion = this.laborDetailsForm.get("duracionTrabajo"),
      codigoCNO = this.laborDetailsForm.get("tipoActividad");

    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      datosSocioEco: {
        codigoSituacionLaboral: this.helpers.returnValue(
          this.laborDetailsForm.get("situacionLaboral")
        ),
        codigoContrato: this.helpers.returnValue(
          this.laborDetailsForm.get("tipoContrato")
        ),
        profesion: this.helpers.returnValue(
          this.laborDetailsForm.get("profesion")
        ), //firs value of combo;
        codigoCNO: this.helpers.returnValue(
          this.laborDetailsForm.get("Descripcion_CNO__c")
        ),
        codigoCNAE: this.helpers.returnValue(this.laborDetailsForm.get("cnae")),
        fechaAltaAutonomo: this.helpers.returnValue(
          this.laborDetailsForm.get("fechaAltaAutonomo")
        ),
        agrupacionCNO: this.getDescriptionCNO(),
        fechaAntiguedadLaboral: this.calcLaborSeniority(duracion.value),
        duracion: this.calcLaborSeniority(duracion.value),
        ingresosMensuales: this.helpers.returnValue(
          this.laborDetailsForm.get("ingresosMensuales")
        ),
        numeroPagas: this.helpers.returnValue(
          this.laborDetailsForm.get("numeroPagas")
        ),
      },
    });
  }

  getDescriptionCNO() {
    let codeDescription = this.helpers.returnValue(
      this.laborDetailsForm.get("Descripcion_CNO__c")
    );
    let nameDescription = " ";
    this.tipoActividadProfesional.filter((item) => {
      let i: any = item;
      if (i.descripcion_cno__c) {
        i.descripcion_cno__c.filter((descripcion) => {
          if (codeDescription === descripcion.value) {
            nameDescription = item.name;
          }
        });
      }
    });
    return nameDescription;
  }

  calcLaborSeniority(duration) {
    const date = new Date(),
      isYear = duration && duration !== null && duration >= 1,
      isMonth = duration && duration !== null && duration < 1 && duration > 0.1,
      isDay = duration && duration !== null && duration < 0.1;

    if (!duration) {
      return undefined;
    } else if (isYear) {
      date.setFullYear(date.getFullYear() - duration);
    } else if (isMonth) {
      date.setMonth(date.getMonth() - duration * 10);
    } else if (isDay) {
      date.setDate(date.getDate() - duration * 100);
    }
    return this.formatDate(date);
  }

  getSeniorityFromJson(date) {
    if (!date) return null;
    const currentDate = new Date();

    //Si la fecha viene con / asumimos que viene en formato dd/mm/yyyy
    //y la convertimos a yyyy-mm-dd
    if (date.includes("/")) {
      date = date.split("/").reverse().join("-");
    }

    var date2 = new Date(date);
    var timeDiff = Math.abs(currentDate.getTime() - date2.getTime());

    /*if (isNaN(timeDiff)) {
      date2 = new Date(date.split("/").reverse().join("-"));
      timeDiff = Math.abs(currentDate.getTime() - date2.getTime());
    }*/
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)),
      valueDiff = Math.floor(diffDays / 30);

    if (valueDiff < 3) {
      return 0.01;
    } else if (valueDiff >= 3 && valueDiff < 9) {
      return 0.3;
    } else if (valueDiff >= 9 && valueDiff < 12) {
      return 0.9;
    } else {
      const years = Math.floor(valueDiff / 12);
      return years;
    }
  }

  formatDate(date: Date) {
    date = new Date(Number(date));
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  valueSelectDate(duration) {
    if (this.isEmpty(duration)) return undefined;
    duration = duration.split("/");
    duration = new Date(duration[2], duration[1], duration[0]);
    const yearNow = new Date().getFullYear();
    return yearNow - duration.getFullYear();
  }

  isEmpty(obj) {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
  }

  searchNameOfCode(values, code) {
    let name;
    for (const value in values) {
      if (value["value"] === code) {
        name = value["name"];
        break;
      }
    }
    return name;
  }
  validateAdditionalFields() {
    const autonomoFields = ["cnae", "fechaAltaAutonomo"],
      cuentaAjenaFields = [
        "tipoContrato",
        "Descripcion_CNO__c",
        "duracionTrabajo",
        "ingresosMensuales",
        "numeroPagas",
      ],
      jubiladoRentistaPensionistaFields = ["ingresosMensuales", "numeroPagas"],
      funcionario = [
        "profesion",
        "Descripcion_CNO__c",
        "duracionTrabajo",
        "ingresosMensuales",
        "numeroPagas",
      ],
      cuentaAjenaSinDatosIngresos = ["tipoContrato", "duracionTrabajo"],
      cuentaAjenaSinDatosIngresosNiProfesion = [
        "tipoContrato",
        "duracionTrabajo",
        "profesion",
      ];

    this.noValidators();

    if (this.isCuentaAjena) {
      this.helpers.resetFieldsState(this.laborDetailsForm, cuentaAjenaFields);
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "tipoContrato",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, "profesion", [
        Validators.required,
      ]);
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "tipoActividad",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "Descripcion_CNO__c",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "duracionTrabajo",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "ingresosMensuales",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
          //this.evoValidator.validateIncomings,
        ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, "numeroPagas", [
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        this.evoValidator.validateAnnualPayments,
      ]);
      this.cleanSpecificFields(autonomoFields);
    } else if (this.isAutonomo) {
      this.helpers.resetFieldsState(this.laborDetailsForm, autonomoFields);
      this.helpers.updateFieldValidation(this.laborDetailsForm, "profesion", [
        Validators.required,
      ]);
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "tipoActividad",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "Descripcion_CNO__c",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "fechaAltaAutonomo",
        [Validators.required, this.evoValidator.validateDate]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "ingresosMensuales",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
          //this.evoValidator.validateIncomings,
        ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, "cnae", [
        Validators.required,
      ]);
      this.cleanSpecificFields(cuentaAjenaSinDatosIngresos);
    } else if (this.isFuncionario) {
      this.helpers.resetFieldsState(this.laborDetailsForm, funcionario);
      this.helpers.updateFieldValidation(this.laborDetailsForm, "numeroPagas", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        this.evoValidator.validateAnnualPayments,
      ]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, "profesion", [
        Validators.required,
      ]);
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "ingresosMensuales",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
          //this.evoValidator.validateIncomings,
        ]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "duracionTrabajo",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "tipoActividad",
        [Validators.required]
      );
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "Descripcion_CNO__c",
        [Validators.required]
      );
      this.cleanSpecificFields(autonomoFields);
      this.cleanSpecificFields(["tipoContrato"]);
    } else if (this.isJubiladoPensionanistaRentista) {
      this.openModalRentista();
      this.helpers.resetFieldsState(
        this.laborDetailsForm,
        jubiladoRentistaPensionistaFields
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, "numeroPagas", [
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        this.evoValidator.validateAnnualPayments,
      ]);
      this.helpers.updateFieldValidation(
        this.laborDetailsForm,
        "ingresosMensuales",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
          //this.evoValidator.validateIncomings,
        ]
      );
      this.cleanSpecificFields(autonomoFields);
      this.cleanSpecificFields(cuentaAjenaSinDatosIngresosNiProfesion);
    } else if (
      !this.isAutonomo &&
      !this.isCuentaAjena &&
      !this.isJubiladoPensionanistaRentista &&
      !this.isFuncionario
    ) {
      this.cleanAllValues();
    }
  }

  cleanSpecificFields(arr) {
    arr.forEach((field) => {
      this.helpers.updateFieldValue(this.laborDetailsForm, field);
    });
  }

  openModalRentista() {
    if (this.laborDetailsForm.get("situacionLaboral").value === "11") {
      this.modService.open(this.modalRentista);
    }
  }

  closeModalRentista(clear?) {
    if (clear) {
      this.laborDetailsForm.reset();
    }
    this.modService.close(this.modalRentista);
  }
  cleanAllValues() {
    Object.keys(this.laborDetailsForm.controls).forEach((key) => {
      if (key !== "situacionLaboral") {
        this.helpers.updateFieldValue(this.laborDetailsForm, key);
      }
    });
  }

  noValidators() {
    Object.keys(this.laborDetailsForm.controls).forEach((key) => {
      if (key !== "situacionLaboral") {
        this.helpers.updateFieldValidation(this.laborDetailsForm, key);
      }
    });
  }

  isCorrectDrowDown(custom) {
    let isCorrect = true;
    if (custom) {
      isCorrect = false;
    }
    return isCorrect;
  }

  /**
   *
   * @param beforeLabel Etiqueta del label anterior por orden de aparicion en pantalla a fecha de 26/05/2020
   * @description Valida si se he rellenado el imput y si su valor es valido
   */
  validateBeforeLabel(beforeLabel) {
    const formLabel = this.laborDetailsForm.controls[beforeLabel];
    const validation = !formLabel.valid ? "error" : "completado";
    return validation;
  }

  updateDropDawnValues(inputLabel) {
    switch (inputLabel) {
      case "situacionLaboral": {
        if (
          this.situacionLaboral !==
          this.laborDetailsForm.get("situacionLaboral").value
        ) {
          this.cleanAllValues();
        }
        break;
      }
    }
  }

  updateStatusAnlitycsDropdown(dropdownInfo, nameForm?, field?) {
    const formLabel = this.laborDetailsForm.controls[dropdownInfo];
    //Logica para saber si los campos de tipo select ya tienen valor anterior y el usuario quiere modificarlo
    //El fin es enviar a G.A. los valores selecionado , completado o error de forma correcta
    let isChange = !formLabel.value ? false : true;
    for (let dLayer of dataLayer) {
      if (dLayer.eventAction === "Formulario " + nameForm + " : " + field) {
        isChange = dLayer.eventLabel === "completado" ? false : true;
      }
    }
    const statusLabel =
      !formLabel.value || !isChange
        ? "seleccionado"
        : this.validateBeforeLabel(dropdownInfo);
    return statusLabel;
  }

  async saveDataBeforeGoBack() {
    await this.setFields();
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(
      () => {
        this.loading = false;
      },
      (error) => {
        this.error();
      }
    );
  }
  async returnInit() {
    //Antes de volver a la pantalla anterior guardamos el estado
    await this.saveDataBeforeGoBack();
    this.helpers.navigateTo("datos-contacto");
  }

  closeTooltip(event) {
    event.preventDefault();
    event.stopPropagation();
    this.tooltipEnabled = false;
  }

  saveData() {
    this.loading = true;
    this.setFields();
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        this.modService.open(this.saveModal, this.modal.blocking);
      },
      (error) => {
        this.error();
      }
    );
  }
}
