import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { GetContractResponse } from '../../../collections/get-contract';
import { HelperService } from '../../../services/helpers.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ACCOUNTS } from '../../../resources/account.model';
import { User } from '../../../collections/user';

@Component({
  selector: 'info-investment-account',
  templateUrl: './info-investment-account.component.html',
  styleUrls: ['./info-investment-account.component.scss'],
  providers: [ModalService]
})
export class InfoInvestmentAccountComponent implements OnInit {

  public clientID;
  private userData: User;
  private user;
  private ibanCC;
  private ibanCD;
  public loading;
 
  stateCurrent = States.enviada;
  currentProduct:any;
  fullName:string;
  address:string;
  ACCOUNTS = ACCOUNTS;

  
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private secondHolderSrv: SecondHolderService,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    private helpersService: HelperService,
    private authService: AuthUserService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService) {
    //this.createForm();
  }

  ngOnInit() {
    /*
    const userData = this.loginSrv.userData;
    const listaParaContratar = userData.listaParaContratar;

    if (userData != null) {
      this.fullName = userData.persona.nombreCliente + " " + userData.persona.apellido1Cliente + " " + userData.persona.apellido2Cliente;
      const address = userData.persona.direccion;
      this.address = this.myProductSrv.formatAddress(address);
      */
    }

    submit() {
      this.helpers.navigateTo('obligaciones-cuenta-inversora');
    }

    /*
    loadUserData() {
      if (this.loginSrv.isLoggedIn) {
        this.loading = true;
        if (!this.helpers.isEmpty(this.loginSrv.userData) && !sessionStorage.getItem("reloadUserData")) {
          this.loading = false;
          this.clientID = this.userData.persona.id_cliente_EVO;
          this.user = `${this.userData.persona.nombreCliente} ${this.userData.persona.apellido1Cliente} ${this.userData.persona.apellido2Cliente === null ? '' : this.userData.persona.apellido2Cliente}`;
          return
        }
        //sessionStorage.removeItem("reloadUserData");
        this.authService.getUserData().subscribe(data => {
          this.loading = false;
          this.clientID = this.userData.persona.id_cliente_EVO;
          this.loginSrv.userData = this.userData;
          this.user = `${this.userData.persona.nombreCliente} ${this.userData.persona.apellido1Cliente} ${this.userData.persona.apellido2Cliente === null ? '' : this.userData.persona.apellido2Cliente}`;
        }, error => {
          this.errService.rsi = true;
          this.errService.navToError();
        });
      }
    }

    */
    private signOnProductDuo(product = ACCOUNTS.CDCode) {
      //this.userData = sessionStorage.getItem("userData");
      sessionStorage.setItem("reloadUserData", "true");
      //this.searchIbanCC();
      const nextStep = '/confirmacion-datos',
      clientID = this.user.persona.id_cliente_EVO,
      ibanCC = '',
      ibanCD = '';

      //ibanCD = this.slideObj['ibanCD'];
      sessionStorage.removeItem("currentProspect");
      sessionStorage.setItem('product-selected', JSON.stringify({ ibanCC, ibanCD, codProductoContratado: product, clientID}));
      this.helpers.navigateTo(nextStep);
    }

    private searchIbanCC () {
      for (const prod of this.user.listaContratados) {
        if (prod.idProducto === ACCOUNTS.CICode) {
         this.ibanCC = prod.ibanCC;
         this.ibanCD = prod.ibanCD;
        }
      }
    }
  }