import { Directive, Input, HostListener, Renderer2, TemplateRef, ViewContainerRef, EmbeddedViewRef, AfterContentInit } from '@angular/core';
import { DeviceService } from 'app/services/device.service';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements AfterContentInit {
  private _showOnInit: boolean = false;
  private _hideOnScroll: boolean = false;
  private _showOnMobile: boolean = true;
  private _isMobile: boolean = false;

  @Input()
  template: TemplateRef<HTMLElement>;  

  @Input()
  set showOnInit(value: boolean) {
    this._showOnInit = value;
  }
  get showOnInit() {
    return this._showOnInit;
  }

  @Input()
  set hideOnScroll(value: boolean) {
    this._hideOnScroll = value;
  }
  get hideOnScroll() {
    return this._hideOnScroll;
  }

  @Input()
  set isMobile(value: boolean) {
    this._isMobile = value;
  }
  get isMobile() {
    return this._isMobile;
  }

  @Input()
  set showOnMobile(value: boolean) {
    this._showOnMobile = value;
  }
  get showOnMobile() {
    return this._showOnMobile;
  }

  templateView: EmbeddedViewRef<HTMLElement>;

  constructor(private renderer: Renderer2, private containerRef: ViewContainerRef) {}

  ngAfterContentInit(): void {
    if ((this._showOnInit && !this._isMobile) || (this._showOnMobile && this._isMobile)) {
      this.showTemplate();
      this.setTemplatePosition();
      this.focusElement();
      this.onBlurTemplate();
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTemplate();
    this.setTemplatePosition();
    this.focusElement();
    this.onBlurTemplate();
  }

  @HostListener('window:scroll') onScroll() {
    if (this._hideOnScroll) {
      this.hide();
    }
  }

  private onBlurTemplate() {
    (this.templateView.rootNodes[0] as HTMLElement).addEventListener('blur', () => {
      this.hide();
    });
  }

  private showTemplate() {
    if (!this.templateView) this.templateView = this.containerRef.createEmbeddedView(this.template);
  }

  private setTemplatePosition() {
    if (this.templateView.rootNodes[0]) {
      this.renderer.setStyle(this.templateView.rootNodes[0], 'position', 'absolute');
    }
  }

  private focusElement() {
    (this.templateView.rootNodes[0] as HTMLElement).focus();
  }

  private hide() {
    this.containerRef.clear();
    this.templateView = null;
  }
}
