import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Modal } from 'app/collections/modal';
import { Persona } from 'app/collections/persona';
import { ACCOUNTS } from 'app/resources/account.model';
import { AnalyticsService } from 'app/services/analytics.service';
import { ApiVideoeidService } from 'app/services/api/api.video-eid.service';
import { AuthService } from 'app/services/auth.service';
import { DeviceService } from 'app/services/device.service';
import { ErrorService } from 'app/services/error.service';
import { HeaderService } from 'app/services/headers.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { VideoEidService } from 'app/services/video-eid.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-now-our-turn',
  templateUrl: './now-our-turn.component.html',
  styleUrls: ['./now-our-turn.component.scss'],
  providers: [ModalService,VideoEidService,ApiVideoeidService],
})
export class NowOurTurnComponent {
  public loading: boolean = false;
  public modal: Modal;
  public modalSrv: ModalService;
  public imageQr: any;
  public dynamicQr: any;
  msgSrvError: string = "";
  clientId: string;
  mail: string;
  modalSrvError: string = "modal-srv-error";
  private persona: Persona = this.authService.getSessionProspect();
  private flujoOtpPrimerPaso: boolean = true;
  modalInfoEid = 'modalInfoEid';
  public isCIAccount: boolean = false;

  constructor(
    public errService: ErrorService,
    public deviceService: DeviceService,
    public modService: ModalService,
    private authService: AuthService,
    private specificAnalytics: SpecificAnalyticsService,
    private videoEidSrv: VideoEidService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private _sanitizer: DomSanitizer,
    private headerSrv: HeaderService
  ) {
    this.isCIAccount = location.href.indexOf(ACCOUNTS[ACCOUNTS.CICode].constPath) !== -1 || this.authService.product === ACCOUNTS.CICode;
  }
  ngOnInit() {
    this.clientId = this.authService.getSessionProspect().datosPersonales
    ? this.authService.getSessionProspect().datosPersonales.id_cliente_EVO
    : this.errService.navToError();
    this.mail = this.setMailEncoded(this.persona);
    this.analytics.triggerPurchase({'step': 'Paso 11 - Cuenta firmada'});
    this.invokeQr();
    this.redirect();
  }
  invokeQr(){
    let body = {}
    let url = window.location.href.replace("#/","");
    if(!environment.production){
      body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligenteuat.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId, "androidInfo": { "androidPackageName": "es.evobanco.bancamovil.debug" }, "iosInfo": { "iosBundleId": "com.innocv.BMI", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" } }
    }else{
      //body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligenteuat.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId, "androidInfo": { "androidPackageName": "es.evobanco.bancamovil.debug" }, "iosInfo": { "iosBundleId": "com.innocv.BMI", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" } }
      // SUSTITUIR PARA PROBAR EN PRO
      body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligente.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId, "androidInfo": { "androidPackageName": "es.evobanco.bancamovil" }, "iosInfo": { "iosBundleId": "com.evobanco.movil", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" }}
    }

    this.videoEidSrv.getDynamicQrImage(body).subscribe(
      data =>{
        this.dynamicQr = data.shortLink;
        // llamada servicio que devolvera el qr dinamico usado en el parametro data posteriormente
        let bodyQr = {};
        bodyQr = {
          // SUSTITUIR PARA PROBAR EN PRO
          "data": environment.production ? "https://v2.cuentainteligente.evobanco.com/" : "https://v2.cuentainteligenteuat.evobanco.com/",
          //"data":"https://v2.cuentainteligenteuat.evobanco.com/",
          "height":80,
          "width":80,
          "logo":"https://www.evobanco.com/.galleries/imagenes/evo-qr.png",
        }
        // llamada servicio que devolvera el qr final
        this.videoEidSrv.getQrImage(bodyQr).subscribe(
          data =>{
            this.imageQr = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, "+ data.content);

          },
           error => console.error(error));;

      },
       error => console.error(error));;

  }
  onSubmit() {
    this.setTipoIdentificacionProspec();
    this.sendToIdentification();
  }
  redirect() {
    this.onSubmit();
  }

  setMailEncoded(mail) {
    if(mail.datosPersonales) {
      return "*****"  +  mail.datosPersonales.email.substr(5);
    }else{
      mail = 'xxxx@gmail.com'
      return mail;
    }
  }
  
  setTipoIdentificacionProspec() {
    const canal = this.deviceService.getDevice();
    this.specificAnalytics.emitIdentificationType("video");
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      otrosDatos: {
        tipoIdentificacion:"identificacion-video",
      },
    });
  }

  sendToIdentification() {
    this.loading = true;
    this.persona.estado = 'casi-lo-tenemos';
    if (JSON.parse(sessionStorage.getItem("discriminatorInfo"))) {
      let discriminator = JSON.parse(
        sessionStorage.getItem("discriminatorInfo")
      );
      this.persona.discriminador = discriminator;
    }
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        // this.loading = false;
        if (data.response.codigo === "KO") {
          this.msgSrvError = !!data.response.message
            ? data.response.message
            : "Error genérico";
          this.modalSrv.open(this.modalSrvError);
          return;
        }
        let nextstep = "ahora-nosotros";
        this.loading = false;
        this.helpers.navigateTo(nextstep);
      },
      (error) => {
        this.error();
      }
    );
  }
  error() {
    this.errService.navToError();
  }
}
