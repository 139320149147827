import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { MyProductsService } from 'app/services/my-products.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'documentation-investment-account',
  templateUrl: './documentation-investment-account.component.html',
  styleUrls: ['./documentation-investment-account.component.scss'],
  providers: [ModalService]
})

export class DocumentationInvestmentAccountComponent implements OnInit {

  @Input() title: string;
  @Input() button: string;
  @Input() document: string;

  public clientID;
  public loading;
  public showSecondStep: boolean = true;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public esFondoInteligente = sessionStorage.getItem('productoContratando') === '010' ? true : false;

  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  currentDocument: any;
  showNoCatDocuments: any;
  
  
  constructor(private fb: FormBuilder,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService) {
  }

  ngOnInit() {
    this.currentDocument = sessionStorage.getItem('currentDocument');
    this.showNoCatDocuments = sessionStorage.getItem('showNoCatDocuments') === 'true';
  }

  ngAfterViewInit() {
    this.currentDocument = sessionStorage.getItem('currentDocument');
    this.showNoCatDocuments = sessionStorage.getItem('showNoCatDocuments') === 'true';
    this.triggerInvesmentNavData();
  }

  acceptTerms() {
    let currentDocument = sessionStorage.getItem('currentDocument');
    sessionStorage.setItem(currentDocument, 'true');
    this.helpers.navigateTo('visor-cuenta-inversora');
  }

  botonVolver() {
    this.helpers.navigateTo('visor-cuenta-inversora');
  }

  triggerInvesmentNavData() {

    let product = this.esFondoInteligente ? 'Fondo Inteligente' : 'Cuenta Inversora';
    let pageName = product + '/';
    let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    
    switch (this.currentDocument) {
      case 'infoMifid':
        pageName += 'Paso 03.01 - Documentación/Información Mifid';
        break;
      case 'poliConflic':
        pageName += 'Paso 03.02 - Documentación/Política Conflicto de Interés ';
        break;
      case 'poliOpti':
        pageName += 'Paso 03.03 - Documentación/Política Óptima Ejecución';
        break;
      case 'poliSalva':
        pageName += 'Paso 03.04 - Documentación/Política Salvaguarda de Activos';
        break;
      case 'poliIncen':
        pageName += 'Paso 03.05 - Documentación/Política Incentivos';
        break;
      case 'folleto':
        pageName += 'Paso 03.06 - Documentación/Folleto Tarifa Valores';
        break;
      case 'condiciones':
        pageName += 'Paso 03.07 - Documentación/Condiciones legales';
        break;
      case 'consentimiento':
        pageName += 'Paso 03.8 - Documentación/Consentimiento comunicaciones';
        break;

      default:
        break;
    }

    if (isSecondSigner){
      pageName = pageName + '/Segundo titular';
    }

    this.analytics.triggerInvestmentDataNavData({
      'pageName': pageName,
      'product': product
    });

  }

}