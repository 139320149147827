import { Injectable } from '@angular/core';
import { Product } from '../collections/product';
import { NestedObject } from 'app/services/nestedObject.service';
import { HelperService } from 'app/services/helpers.service';

@Injectable()
export class ProductSelection {
  private _product:Product;
  constructor(public nestedObject: NestedObject, public helpers: HelperService) {
  }

  set product(product:Product){
    this._product = product;
    const previousProduct = JSON.parse(sessionStorage.getItem('product-selected')) || {};
    const currentProduct = this.nestedObject.nestedObjectAssign({}, previousProduct, product);
    sessionStorage.setItem('product-selected', JSON.stringify(currentProduct));
  }

  get product():Product{
    if(!this._product){
      this._product = JSON.parse(sessionStorage.getItem('product-selected'));
    }
    return this._product;
  }

  public getProductSelected() {

    const storage = sessionStorage.getItem('product-selected');
    const product = storage ? JSON.parse(storage) : undefined;

    return product;
  }
}
