import { Component, OnChanges, OnInit, Input, Output, forwardRef, ViewChild,
  HostListener, SimpleChanges, SimpleChange, ElementRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    FormControl,
    Validator
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'dropdown-custom',
  templateUrl: './dropdown-custom.component.html',
  styleUrls: ['./dropdown-custom.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownCustomComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DropdownCustomComponent),
    multi: true,
  }]
})
export class DropdownCustomComponent implements ControlValueAccessor, Validator, OnChanges {
  public _selectClass = '';
  public _disabled = false;
  public _selectName: string;
  public _selectValue: string;
  public _keyup = '';
  public show: Boolean = false;
  public _autocomplete = false;
  public optiontreated: Array<Object> = [];
  private positionSelect: Object;
  public classAux: string;
  public border:any = 'border-ci';
  @ViewChild('autocomplete') public eAutocomplete: ElementRef;

  @Input() id: string = '';

  @Input() name:string = '';

  @Input() isCorrect: boolean = true;

  @Input() isUp: boolean = false;

  @Input() isCi:boolean = false;
  
  @Input() nameSelect = false;

  @Input() limitNames: boolean = true;

  @Input() maxHeight = "";

  @Input() tooltip = "Introduce los primeros dígitos del código CNO o las letras de la actividad económica para seleccionar entre las distintas opciones";

  @Input() placeholder = "Introduce el código CNO o las letras";

  @Input() buttonClass: boolean = false;

  //@Input() classAdd: string;

  @Input()
  public set classAdd(value: String) {
    this.classAux = this.setInitClass(value);
  }

  public get classAdd(): String {
    return this.classAux;
  }

  @Input()
  public set autocomplete(value: boolean) {
    this._autocomplete = value;
  }

  public get autocomplete(): boolean{
    return this._autocomplete;
  }

  @Input()
  public set options(value: Array<Object>) {
    this.optiontreated = this.setInitOptiontread(value);
  }

  public get options(): Array<Object> {
    return this.optiontreated;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = Boolean(value);
    if (this._disabled) {
      this.hideOptions();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  private propagateChange = (_: any) => { };

  /*
  ngOnInit() {

  }
*/
  @HostListener('document:click', ['$event.target'])
  close(btn) {
    if (this.elementRef.nativeElement.childNodes[0] !== btn.offsetParent && btn.className !== 'autocomplete') {
      this.show = false;
    }
  }

  @HostListener('keydown', ['$event'])
  handleArrows(event) {
    let position: number = 0;
    for (let i = 0, iLen = this.optiontreated.length; i<iLen; i++){
      const name = this.optiontreated[i]['name'];
      if ( name.startsWith(event.key.toUpperCase()) ){
        position = i;
        break;
      }
    }
    const selected = this.optiontreated.filter(option => {
      if (document.getElementById(option['name'])) document.getElementById(option['name']).classList.remove('arrow-selected');
      return option === this.positionSelect;
    })
    let optionIndex = this.optiontreated.indexOf(selected[0]);
    if (this.show) {
      let direction = false;
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (optionIndex > 1) {
            optionIndex --;
            direction = true;
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (optionIndex < this.optiontreated.length - 1) {
            optionIndex ++;
            direction = false;
          }
          break;
        case 'Enter':
          this.selectItem(this.optiontreated[optionIndex]);
          this.show = false;
          break;
        default:
          if (event.key.length <= 1)this.keypressed(event);
          break
      }

      if ((event.key == 'ArrowUp') || (event.key == 'ArrowDown') || (event.key == 'Enter')){
        if (optionIndex !== -1) this.setDirection(direction, optionIndex);
      } else {
        if (optionIndex !== -1) this.setDirection(direction, position);
      }

    }else {
      this.keypressed(event);
    }
  }

  setDirection(up:boolean, optionIndex){
    this.positionSelect = this.optiontreated[optionIndex];
    let element: HTMLElement = document.getElementById(this.positionSelect['name']);
    if (element) {
      element.classList.add('arrow-selected');
      element.scrollIntoView({ behavior: "auto" });
    }
  }

  keypressed(e) {
    if (this._autocomplete) return
    let option:any;
    option = this.optiontreated.filter((item) => {
      return String(item['name'][0]).toLowerCase() === e.key;
    })
    if (option.length >= 1){
      this.selectItem(option[0]);
    }else {
      this.selectItem(option);
    }
  }


  public writeValue(obj: any) {
    //this.setInitOptiontread(this.optiontreated);
    this.setChangeValue(obj);
  }
  public registerOnTouched() { }
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public validate(c: FormControl) {
    return (c.value) ? null : {
      validateError: {
        valid: false,
      },
    };
  }

  constructor(private elementRef: ElementRef,
    public route: Router) {
  }

  getWith() {
    if (this.route.url === '/datos-laboral') {
      this.border = 'border-dl';
    }
    return this.border;
  }
  getDropWith() {
    let cssClass = 'open'
    if (this.route.url === '/datos-laboral' && this.isCi) {
      cssClass = 'open with-drop-open';
    }
    if (this.route.url === '/datos-contacto' && this.isCi) {
      cssClass = 'open with-drop-cd-open';
    }
    return cssClass;
  }
  filterOptions(event) {
    this._keyup = event.currentTarget.value.toLowerCase();
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  btnClick(e) {
    e.preventDefault();
    if (!this._disabled) {
      if (this._autocomplete) {
        if (e.target !== this.eAutocomplete.nativeElement) {
          this.show = !this.show;
          this.eAutocomplete.nativeElement.value = '';
        }
        setTimeout(() => this.eAutocomplete.nativeElement.focus(), 0);
      } else {
        this.show = !this.show;
      }
      // if (this.show){
      //   document.body.setAttribute('style', 'overflow:hidden');
      // }else{
      //   document.body.setAttribute('style', 'overflow:auto');
      // }
    }
  }

  hideOptions() {
    this.show = false;
  }

  selectItem(option) {
    let value = 'value';
    if(this.nameSelect) value = 'name';
    this._keyup = "";
    this.setChangeValue(option[value]);
    this.propagateChange(option[value]);
  }

  setChangeValue(obj) {
    let value = 'value';
    if (obj === undefined || obj === null || obj === '') {

      this.optiontreated[0]['selected'] = true;
      this._selectName = this.optiontreated[0]['name'];
      this._selectValue = this.optiontreated[0]['value'];
      this.positionSelect = this.optiontreated[0];
      this._selectClass = 'selected-placeholder';

    } else {
      
      for (let i = 0; i < this.optiontreated.length;i++){

        const item = this.optiontreated[i];
        if (this.nameSelect) value = 'name';

        if (item[value] && String(obj).toUpperCase() === String(item[value]).toUpperCase()){

          item['selected'] = true;
          this._selectName = item['name'];
          this._selectValue = item['value'];
          this.positionSelect = item;
          
          if (this._selectValue === null) {
            this._selectClass = 'selected-placeholder';
          } else {
            this._selectClass = '';
          }
          
        } else {
          item['selected'] = undefined;
        }
      }
    }
  }

  setInitOptiontread (config) {
    const array = [];
    for (let i = 0; i < config.length; i++) {
      config[i].selected = false;
      config[i].disabled = false;
      array.push(config[i]);
    }
    return array;

  }

  setInitClass (clase) {
    const classSet = clase;
    return classSet;
  }
}
