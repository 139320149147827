<logalty-load *ngIf="logaltyLoaded"></logalty-load>
<modal  [modalTitle]="'Call me'" 
        [blocking]='false' 
        [modalId]='callMeModal'
        class='modal exception-modal'
        (reload)="reloadModal($event)" >
  <div>
    <div class="row">
      <p class="ic-dudas"></p>
    </div>
    <div class="row">
      <p class="call-me-title">¿Ahora no?</p>
    </div>
    <div class="row">
      <p class="call-me-subtitle">Si en estos momentos no puedes continuar con la videoidentificación, no te preocupes, cuando estés listo volverás directamente a este paso tras incluir tu número de teléfono y validar el código.</p>
    </div>
    <div class="row btn-group_fix btn-try-again-div">
      <button class="btn-common btn-common_fix_bold" type="submit"(click)="click('video-time-out')">Volver a intentarlo</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='false' [modalId]='modal_id' class='modal' (reload)="reloadModal($event)">
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <!--ERROR TIMEOUT-->
    <div *ngIf='timeout' class="equal-buttons" [ngClass]='!link?"": "no-margin"'>
      <div *ngIf='video != ""' class="access" name="video" (click)="click('video')">
        <span>{{video | uppercase}}</span>
      </div>
      <!-- <div *ngIf='iban != ""' class="access accessIban" name="iban" (click)="click('iban')">
        <span>{{iban | uppercase}}</span>
      </div> -->
    </div>

    <div  *ngIf='!timeout' class="equal-buttons" [ngClass]='!link?"": "no-margin"'>
      <div *ngIf='video != ""' class="access" name="video" (click)="click('video')">
        <span>{{video | uppercase}}</span>
      </div>
      <!-- <div *ngIf='iban != ""' class="access accessIban" name="iban" (click)="click('iban')">
        <span>{{iban | uppercase}}</span>
      </div> -->
    </div>
    <!--<div *ngIf='link != ""'
    [ngClass]='!link?"": "no-margin"'
    class="equal-buttons"
    (click)="click('welcome')">
      <a class="link" href="#">{{link | uppercase}}</a>
    </div>-->
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='true' [modalId]='modal_notAdult' class='modal not-adult '>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <!-- <div class="equal-buttons">
      <div  class="access" name="continuar" (click)="click('continuar')">
        <span>{{link | uppercase}}</span>
      </div>
    </div> -->
  </div>
</modal>
<modal [modalTitle]="'Permisos-Denieged'" [blocking]='true' [modalId]='modalPermisionDenieged' class='modal'>
  <div class="container-permision-info">
    <div class="row-ic-webcam">
      <p class="ic_webcam"></p>
    </div>
    <div class="second-info-row">
      <p class="antes-de-continuar-e"> Activar el permiso de cámara</p>
    </div>
    <div class="options-info-1">
      <div class="marco">
        <p class="ic_android_logo"></p>
      </div>
      <div class="texto">
       <p class="estandar"> <span class="negrita">Si tienes Android:</span> Abre Chrome, ve a “configuración de sitio web”, haz clic en “cámara” y luego en “activar cámara”.</p>
      </div>
    </div>
    <div class="options-info-2">
      <div class="marco">
        <p class="ic_apple_logo"></p>
      </div>
      <div class="texto">
       <p class="estandar"><span class="negrita">Si tienes IOS:</span> ve a “ajustes”, haz clic en cámara y luego en “activar”.</p>
      </div>
    </div>
    <div class="options-info-3">
      <div class="marco">
        <p class="ic_ordenador_web"></p>
      </div>
      <div class="texto">
       <p class="estandar"><span class="negrita">PC:</span> Haz clic en “inicio” y luego en “configuración”. Dentro de “privacidad”, haz clic en “cámara” y en “permitir”.</p>
      </div>
    </div>
    <div class="options-button">
      <div  id="video" class="btn-permision-1" (click)="click('video')" name="button2">
        <a>
          <span class="text-button">Entendido</span>
        </a>
      </div>
      <!-- COMENTADO MIENTRAS ESTE DESHABILITADO CONFIRMA -->
      <!-- <div *ngIf='!userInBL' id="video" class="btn-permision-2" (click)="click('iban')" name="button2">
        <a>
          <span class="text-button">Probar con Iban</span>
        </a>
      </div> -->
    </div>
  </div>
</modal>
<modal [modalTitle]="''" [blocking]='false' [modalId]='modal_ko' class='modal'>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div  class="access" name="continuar" (click)="tryAgain()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
    <p *ngIf='caption != ""'>{{caption}}</p>
  </div>
</modal>
<div helperSteps [msghelper]="msghelper" class="msgHelper" *ngIf="!hasNieIdentification"></div>
<div *ngIf="videoType === VideoType.VIDEO_ID" [id]="videoElementId" class="identifyVideo"></div>
<div *ngIf="videoType === VideoType.VIDEO_SCAN" [id]="videoScanId" class="identifyVideo"></div>
