import { ConstantsService } from 'app/services/constants.service';
import { HostListener, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class InactivityService {
  private maxTimeIdle = 0.5 * ConstantsService.OneMinuteMillis;
  renderer: Renderer2;
  private activityTimeoutShow = false;
  private inactiveUser = true;
  private activityTimeout = null;
  public activatedModalTimeout = true;
  public eventShowModal: Subject<any> = new Subject();
  public eventShowModalC2C: Subject<any> = new Subject();
  constructor(private rendererFactory2: RendererFactory2) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.renderer.listen('document', 'mousemove', (evt) => {
      this.checkInactivity();
    });
    this.renderer.listen('document', 'keypress', (evt) => {
      this.checkInactivity();
    });
    this.renderer.listen('document', 'touchmove', (evt) => {
      this.checkInactivity();
    });
   }

  checkInactivity(){
    if (this.activityTimeout) {
      this.inactiveUser = false;
      clearTimeout(this.activityTimeout);
    }
    if (this.activityTimeoutShow === false) {
      this.activityTimeout = setTimeout(() =>  {
        this.inactiveUser = true;
        this.checkTotalActivity();
      }, this.maxTimeIdle);
    }
  }

  initTimeInactivity(time){
    //Tiempo en minutos
    this.maxTimeIdle = time * ConstantsService.OneMinuteMillis;
    this.inactiveUser = true;
    this.activityTimeout = null;
    this.checkInactivity();
  }

  checkTotalActivity() {
    if (this.inactiveUser === true) {
      //Linea que muestra la modal
      this.showModal() && this.eventShowModal.next();
    }
  }

  cancelTimeOut(){
    clearTimeout(this.activityTimeout);
  }

  showModal(){
    return this.activityTimeoutShow = this.activatedModalTimeout;
  }

}
