<!--Spinner antiguo
  <div class="loading" *ngIf="_showMe && !isCIAccount">
  <div class="image" [ngClass]="{'Edge':EdgeClass}">
  </div>
</div>
  -->
<div class="loading-ci" *ngIf="_showMe">
  <svg class="spinner">
    <circle cx="40" cy="40" r="36"></circle>
  </svg>
</div>