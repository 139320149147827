<div-show [showMe]="showError" [nameError]="nameError"></div-show>
<form class="form" action="">
  <section class="c-header--bginv">
    <div class="responsive-header">
      <div class="page-title">
        <span *ngIf="textInteligente && !isBmi">FONDO INTELIGENTE</span>
        <span *ngIf="textInversora && !isBmi">FONDOS Y VALORES</span>
      </div>
    </div>
    <div class="c-header__wizard">
      <ul class="c-header__wizard__steps">
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 1}" id="wizard-1">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 2}" id="wizard-2">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 3}" id="wizard-3">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 4}" id="wizard-4">
          </li>
      </ul>
    </div>
  </section>
  <div class="main-content">
    <section class="info--container" *ngIf="textInteligente">
      <div class="info__banner">
        <header class="info__banner__header">
          <p class="info__banner__title">DÓNDE QUIERAS Y CUANDO QUIERAS</p>
          <img class="info__banner__img" src="./assets/images/banner/banner_5.jpg" alt="Banner">
        </header>
        <footer class="info__banner__footer">
          <ul>
            <li>Todo desde tu móvil</li>
            <li>Tú eliges cómo, cuándo y dónde invertir</li>
          </ul>
        </footer>
      </div>
      <div class="info" *ngIf="!mostrarTexto1Titular">
          <i class="evo icon-wellcome-onboard info-img--wellcome"></i>
        <p  class="info-title">YA TIENES LISTO TU FONDO INTELIGENTE</p>
        <p class="info-description">En breve recibirás un email de confirmación. 
            En cuanto te llegue podrás hacer el test de conveniencia y ya tendrás todo listo para 
            hacer la primera suscripción en tu Fondo Inteligente.</p>
      </div>

      <div class="info" *ngIf="mostrarTexto1Titular">
        <i class="evo icon-wellcome-onboard info-img--wellcome"></i>
      <p class="info-title">YA TENÉIS LISTO VUESTRO FONDO INTELIGENTE </p>
      <p class="info-description">En breve recibirás un email de confirmación. 
          En cuanto te llegue podrás hacer el test de conveniencia y ya tendrás todo listo para 
          hacer la primera suscripción en vuestro Fondo Inteligente.</p>
    </div>
      
      <div class="btn-group_fix" *ngIf="!isBmi">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
          Entendido
        </button>
      </div>

        <p *ngIf="!isMobileOrTablet()" class="info--bggrey__description_final">EVO Fondo Inteligente, F.I. (Fondo Inteligente EVO) está registrado en la
          CNMV con el número 4978.La inversión está sujeta a posibles fluctuaciones en el precio de mercado, por lo que no hay garantía de
          devolución del capital invertido. El indicador de riesgos del Fondo Inteligente está compuesto por la
          combinación de los riesgos de sus compartimentos, uno de Renta Fija con riesgo 1 /1 con otro compartimento de
          Renta Variable/Ibex 35 con riesgo 7/7. El Folleto explicativo del producto y el documento de datos
          fundamentales para el inversor están disponibles en www.cnmv.es Gestora: GESCONSULT, S.A., S.G.I.I.C. Grupo
          Gestora: GESCONSULT. Depositario: BANCO INVERSIS, S.A. Grupo Depositario: BANCA MARCH. Auditor: Deloitte, SL.
          Comercializador en exclusiva: EVO Banco, S.A.U.</p>
    </section>
    <section class="info--container" *ngIf="textInversora">
      <div class="info__banner">
        <header class="info__banner__header">
          <p class="info__banner__title">DÓNDE QUIERAS Y CUANDO QUIERAS</p>
          <img class="info__banner__img" src="./assets/images/banner/banner_5.jpg" alt="Banner">
        </header>
        <footer class="info__banner__footer">
          <ul>
            <li>Todo desde tu móvil</li>
            <li>Tú eliges cómo, cuándo y dónde invertir</li>
          </ul>
        </footer>
      </div>
      <div class="info" *ngIf="!mostrarTexto1Titular">
        <i class="evo icon-wellcome-onboard info-img--wellcome"></i>
        <p  class="info-title">YA TIENES LISTA TU CUENTA INVERSORA DE FONDOS Y VALORES </p>

        <p class="info-description">En breve recibirás un email de confirmación.
           En cuanto te llegue podrás hacer el test de conveniencia y ya tendrás 
           todo listo para hacer tu primera suscripción en Fondos o comprar tus primeros Valores.</p>
      </div>

      <div class="info" *ngIf="mostrarTexto1Titular">
        <i class="evo icon-wellcome-onboard info-img--wellcome"></i>
        <p  class="info-title">YA TENÉIS LISTA VUESTRA CUENTA INVERSORA DE FONDOS Y VALORES </p>

        <p class="info-description">En breve recibirás un email de confirmación.
           En cuanto te llegue podrás hacer el test de conveniencia y ya tendrás 
           todo listo para hacer vuestra primera suscripción en Fondos o comprar tus primeros Valores.</p>
      </div>
      <div class="btn-group_fix" *ngIf="!isBmi">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
          Entendido
        </button>
      </div>
      <div class="info--bggrey__description_final" *ngIf="textInversora && !isMobileOrTablet()">La inversión está sujeta a posibles fluctuaciones en el precio de mercado, por lo que no hay garantía de
        devolución del capital invertido.Los folletos explicativos de los productos y los documentos de datos fundamentales
        para el inversor de ls fondos de inversión donde figura su indicador de Riesgo que puede ser 1 a 7 de menor a mayor
        riesgos, disponibles en www.cnmv.es</div>

    </section>
  </div>
</form>