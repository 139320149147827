import { SelectValues } from '../collections/select-type';

export const PROFESIONES:SelectValues[] = [

  { name: 'Profesión (CNO)', value: null},

  
  { name: 'PROFESIONALES DEL DERECHO', value: 'PROFESIONALES DEL DERECHO'},
  { name: 'ACTIVIDADES MILITARES', value: 'ACTIVIDADES MILITARES'},
  { name: 'DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA', value: 'DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA'},
  { name: 'EMPLEADOS DE MANTENIMIENTO, MENSAJERÍA, ALMACEN', value: 'EMPLEADOS DE MANTENIMIENTO, MENSAJERÍA, ALMACEN'},
  { name: 'EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO', value: 'EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO'},
  { name: 'OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS', value: 'OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS'},
  { name: 'PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA', value: 'PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA'},
  { name: 'PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA', value: 'PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA'},
  { name: 'TÉCNICOS Y PROFESIONALES DE APOYO', value: 'TÉCNICOS Y PROFESIONALES DE APOYO'},
  { name: 'TRABAJADORES SECTOR PRIMARIO Y SECUNDARIO', value: 'TRABAJADORES SECTOR PRIMARIO Y SECUNDARIO'},
  { name: 'TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD', value: 'TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD'},
  { name: 'TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO', value: 'TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO'},
];
