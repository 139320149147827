import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { GetContractResponse } from '../../../collections/get-contract';
import { HelperService } from '../../../services/helpers.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ACCOUNTS } from '../../../resources/account.model';
import { User } from '../../../collections/user';
import { DeviceService } from 'app/services/device.service';

@Component({
  selector: 'final-investment-account',
  templateUrl: './final-investment-account.component.html',
  styleUrls: ['./final-investment-account.component.scss'],
  providers: [ModalService]
})
export class  FinalInvestmentAccountComponent implements OnInit {

  public clientID;
  private userData: User;
  private user;
  private ibanCC;
  private ibanCD;
  public loading;
  public showSecondStep: boolean = true;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public currentPath: string;
  public step: number;
  public textInversora: boolean = false;
  public textInteligente: boolean = false;
  public mostrarTexto1Titular: boolean = false;
  public nameError: string = '';
  public showError: boolean = false;
  public isBmi: boolean = false;
  public isInversora: boolean = false;


  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;


  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private secondHolderSrv: SecondHolderService,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    private helpersService: HelperService,
    private authService: AuthUserService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    private deviceService: DeviceService) {


      this.router.events.subscribe((url: any) => {
        if (url instanceof NavigationEnd) {
          this.currentPath = url.url;
          this.setSteps();
        }
      });
  }

  ngOnInit() {
    this.getQueryParams();
    if(sessionStorage.getItem("isSecondSigner") === "true" ||
       sessionStorage.getItem("preparadoFirmaST") === "true"){
      this.showError = true;
      this.nameError = "preparadoFirmaST";
      this.triggerInvesmentNavData('Paso 04 - Confirmación firma pendiente (dos titulares)');
      this.triggerInvesmentNavData('Paso 04.01 – Envío mail Segundo titular');
      //Si el segundo titular aun no ha firmado y intenta firmar el 1º.
    }else if(sessionStorage.getItem("isSecondSigner") != "true" &&
      sessionStorage.getItem("esperaFirmaST") === "true"){
      this.showError = true;
      this.nameError = "esperaFirmaST";
      this.triggerInvesmentNavData('Paso 04 - Confirmación firma pendiente (dos titulares)');
      this.triggerInvesmentNavData('Paso 04.01 – Envío mail Segundo titular');
    }
    this.setText();
  }

  submit() {
    this.user = JSON.parse(sessionStorage.getItem("userData"));
    console.log(this.user);
    this.errService.deletePreviousSession();
    window.location.href='https://bancaelectronica.evobanco.com/';
    //this.helpers.navigateTo("/login");
  }



  botonVolver() {}

  setSteps() {
    if (this.currentPath){
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    }
    else if (!this.step){
      this.step = 1;
    }
  }

  /**
   * Establece el texto por defecto dependiendo del prodcucto que se está contratando
   */
  setText(){
    if(sessionStorage.getItem("productoContratando") === '009' || this.isInversora){
      this.textInversora = true;
      this.textInteligente = false;
    }else{
      this.textInversora = false;
      this.textInteligente = true;
    }

    if(sessionStorage.getItem('mostrarTexto1Titular') === 'true'){
      this.mostrarTexto1Titular = true;
    }
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen && params.origen.toLowerCase() === "bmi") {
          this.isBmi = true;
      }

      //Se añade para que en un abandono podamos saber que producto es.
      if (params.codProductoContratado && params.codProductoContratado === "009"){
          this.isInversora = true;
      }

      //Se añade para que en un abandono podamos saber que producto es.
      if (params.isSecondSigner === 'true'){
        this.triggerInvesmentNavData('Paso 04 - Confirmación firma ok (2º titular)');
      }
      else if (params.isSecondSigner === 'false' && this.mostrarTexto1Titular){
        this.triggerInvesmentNavData('Paso 06 - Confirmación', true, false);
      } else if (this.mostrarTexto1Titular){
        this.triggerInvesmentNavData('Paso 06 - Confirmación', false, true);
      }

    });

    // Si se accede mediante una redirección desde logalty, se comprueba si es bmi desde los datos de sesión.
    if (sessionStorage.getItem('isBMI') === 'true') {
      this.isBmi = true;
    }
  }

  isMobileOrTablet(): boolean {
    return this.deviceService.isMobileOrTablet();
  }

  triggerInvesmentNavData(pageName, showPrimerTitular = false, unicoTitular?) {
    let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    if (isSecondSigner){
      pageName = pageName + '/Segundo titular';
    } else if (unicoTitular){
      pageName = pageName + '/Un único titular';
    } else if (showPrimerTitular){
      pageName = pageName + '/Primer titular';
    }

    let product = sessionStorage.getItem("productoContratando") === '009' || this.isInversora?'Cuenta Inversora':'Fondo Inteligente';
    this.analytics.triggerInvestmentDataNavData({
                    'pageName': product + '/' + pageName,
                    'product': product
                  });
  }

}
