<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone>

<loading [showMe]="loading"></loading>

<modal [modalTitle]="'login'" [modalId]='login' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>ALGO NO NOS CUADRA</h2>
    <p>Revisa tus datos.</p>
  </div>
</modal>


<div class="heading">
  <h3>Bienvenido a tu área personal</h3>
</div>

<div class="login content-wrapper">
  <div class="image"></div>
  <div class="content-contain">
    <div class="row title">
      <h3>accede a tu área personal</h3>
      <p>Introduce tu usuario (NIF/NIE) y tu contraseña de banca a distancia.</p>
    </div>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="row">
        <div class="mobile-container">
          <label for="dni">NIF/NIE</label>
          <input autofocus 
            maxlength="9" 
            type="text" 
            name="user" 
            id="dni" 
            formControlName="user" 
            autocomplete="off"
            (blur)="validUser()" 
            (focus)='closeKeyboard();scrollToField()'>
            <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('user'),'user')">
              {{errService.desktopErrors(loginUser.get('user'),'user')}}
            </div>
        </div>
        <div class="mobile-container">
          <label for="claveSMS">Contraseña</label>
          <input type="password" 
            #password
            name="pass" 
            onfocus="blur(); click()" 
            (click)="setClassFocus()"
            formControlName="pass" 
            [modal]='false' 
            [horizontal]='true'
            keyboard 
            (numberEmmited)="numberEmmited($event)"
            autocomplete="off"
            (deleteLast)="deleteLast($event)">
            <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('pass'),'pass')">
              {{errService.desktopErrors(loginUser.get('pass'),'pass')}}
            </div>
        </div>
        <div class="mobile-container button-top-index">
          <button type="submit" class="btn-common btn-common_fix_bold" name="button" (click)="closeKeyboard()">Enviar</button>
        </div>
      </div>
    </form>
    <div class="row">
      <p class='label'>
        <b>¿No tienes claves para entrar?</b> Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</div>
