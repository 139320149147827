import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoId, VerifiEID } from '../collections/video';
import { ApiVideoeidService } from './api/api.video-eid.service';

@Injectable()
export class VideoEidService {
  constructor(private api:ApiVideoeidService) { }

  getVideo(id, videoType = 'videoid'):Observable<VideoId>{
    return this.api.getVideo(id, videoType);
  }

  verificationEID(video:VerifiEID):Observable<any>{
    return this.api.verificationEID(video);
  }

  foreignVerificationEID(video:VerifiEID, videoScan: string):Observable<any>{
    return this.api.foreignVerificationEID(video, videoScan);
  }

  getLocalVideo(){
    return this.api.getSessionVideo();
  }
  
  getAuthorizationEID(videoType: string = 'videoid'):Observable<any>{
    return this.api.getAuthorizationEID(videoType);
  }
  getQrImage(body):Observable<any>{
    return this.api.getQrImage(body);
  }
  getDynamicQrImage(body):Observable<any>{
    return this.api.getDynamicQrImage(body);
  }
}
