import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot,
  RouterStateSnapshot, CanActivateChild, NavigationExtras,
  CanLoad, Route
} from '@angular/router';
import { navigationSteps } from '../collections/navigation';

import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  navs = navigationSteps;
  lastStep = 0;

  constructor(private authService: AuthService,
              private navigationService: NavigationService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

    const url: string = state.url,
    result =  this.checkLogin(url, state);
    let formattedUrl = this.formatUrl(url).replace("/",'');
    if (result) {
      if (this.navs[formattedUrl] > this.lastStep || 
        (this.lastStep === 16 || this.lastStep === 15 && this.navs[formattedUrl] === 1)
        || (this.lastStep === 24 && this.navs[formattedUrl] === 3)
        || (this.lastStep === 29 && this.navs[formattedUrl] === 1)){
        this.lastStep = this.navs[formattedUrl];
        // Si la url 'datos-personales' , 'bienvenida-segundo-titular' o 'datos-de-contacto', a SF se envía 'datos-personal' y 'datos-contacto', respectivamente
        if (this.navs[formattedUrl] == 1) {
          formattedUrl = this.navs[23];
        } else if (this.navs[formattedUrl] == 2) {
          formattedUrl = this.navs[24];
        } else if (this.navs[formattedUrl] == 29) {
          formattedUrl = this.navs[23];
        }

        // Si la url es 'ahora-nosotros' a SF hay que enviar 'casi-lo-tenemos'
        if (this.navs[formattedUrl] == 28) {
          formattedUrl = this.navs[12];
        }

        this.authService.updateState(formattedUrl);
      }
    }
    return result;
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    //  return this.checkLogin(url, route);
    // console.log(url);
    return true;
  }

  checkLogin(url: string, state: RouterStateSnapshot): boolean {

    let defaultPath = '/bienvenido';
    const resultEstate = this.checkState(url, state);

    if (url.toLocaleLowerCase() === '/bienvenido' || url === '/') return true;
    if (resultEstate.isAllowed) return true;

    if (resultEstate.defaultPath) defaultPath  = resultEstate.defaultPath;

    this.router.navigate([defaultPath])
    return false;
  }

  checkState(url: string, state: RouterStateSnapshot) {
    return this.navigationService.isNavigationAllowed(url.replace('/',''), state);
  }

  formatUrl(url):string{
    if (url.indexOf("?") !== -1) {
      url = url.substring(0, url.indexOf("?"));
    }
    return url;
  }
}
