<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>
<modal [modalId]="optSend" [blocking]="false" class="modal">
  <div class="modal-otp-sms icon"></div>
  <div class="paragraph_otp">
    <p class="title">¡Enviado!</p>
  </div>
  <div class="paragraph_otp">
    <p class="subtitle">
      El SMS ha sido envíado al móvil que nos has indicado.
    </p>
  </div>
  <div class="paragraph_otp">
    <button class="btn-common btn-otp" (click)="closeModalResentOtp()">
      <span>Entendido</span>
    </button>
  </div>
</modal>
<modal
  [modalId]="alreadyClientModal"
  [blocking]="true"
  class="modal modal-login"
>
  <mobile-error-zone
    *ngIf="errService.errorsArray.length > 0"
    [errors]="errService.errorsArray"
    class="custom-erros"
  >
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <p *ngIf="errMsg" class="err-login">
      {{ errMsg }}
    </p>
    <form
      (keydown.enter)="$event.preventDefault()"
      [formGroup]="loginUser"
      (ngSubmit)="sendClient()"
      novalidate
    >
      <div class="equal-buttons">
        <input
          placeholder="Usuario (NIF/NIE)"
          autocomplete="off"
          type="text"
          name="user"
          formControlName="user"
          maxlength="9"
          (focus)="closeKeyboard()"
          (blur)="validUser()"
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(otpDetailsForm.get('user'), 'user')"
        >
          {{ errService.desktopErrors(otpDetailsForm.get('user'), "user") }}
        </div>
        <input
          keyboard
          placeholder="Contraseña"
          type="password"
          autocomplete="off"
          name="pass"
          formControlName="pass"
          (numberEmmited)="numberEmmited($event)"
          (deleteLast)="deleteLast($event)"
          onfocus="blur()"
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(otpDetailsForm.get('pass'), 'pass')"
        >
          {{ errService.desktopErrors(otpDetailsForm.get('pass'), "pass") }}
        </div>
      </div>
      <div class="row">
        <button
          class="btn-common btn-common_fix_bold"
          type="submit"
          (click)="closeKeyboard()"
        >
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class="label">
        ¿No tienes claves para entrar? Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te
        ayudaremos.
      </p>
    </div>
  </div>
</modal>
<div
  class="main-register flex-direction" *ngIf="!isCIAccount">
<box-image
    [classImgFile]="'imagen2-cj'"
    [showTextBox]="false"
    class="image-div"
  >
  ></box-image>
  <div class="form-and-step-div">
    <steps-ci [youngAccount]="isYoungAccount" class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div form-otp">
      <p class="letter title-otp">Valida tu número de teléfono</p>
      <p class="letter subtitle-otp" *ngIf="paramsSMS && paramsSMS.telephone">
       Mira tu móvil. Acabamos de enviarte un código de seguridad para verificarlo.
      </p>
      <form
        [formGroup]="otpDetailsForm"
        (ngSubmit)="onSubmit()"
        (keydown.enter)="$event.preventDefault()"
        novalidate
      >
        {{ showPhoneCI ? mobilePhone.focus() : keyphone.focus() }}
        <div>
          <p class="otp-placeholder-cj ci-mobile">CÓDIGO SMS</p>
          <div class="inline-flex-100">
            <div [class]="setClassKeyPhone()">

              <input class="otp-box-cj"
                type="tel-ci"
                required
                id="keyphone"
                #keyphone
                number-input
                [minLength]="maxlengthOTP != null ? maxlengthOTP : 6"
                [maxLength]="maxlengthOTP != null ? maxlengthOTP : 6"
                autocomplete="off"
                inputmode="numeric"
                formControlName="smsphone"
                [attr.disabled]="clientExists ? '' : null"
                [ngClass]="otpDetailsForm.get('smsphone').value ? 'input-noEmpty' : 'input-empty'"
              />
              <div
                class="error-text"
                *ngIf="
                  errService.desktopErrors(
                    otpDetailsForm.get('smsphone'),
                    'smsphone'
                  )
                "
              >
                {{
                  errService.desktopErrors(
                    otpDetailsForm.get('smsphone'),
                    "smsphone"
                  )
                }}
              </div>
              <!-- <p class="check-sms">Si no recibes el código pasados unos segundos comprueba que el número es correcto y vuelve a enviarlo. </p> -->
            </div>
            <div class="buttonCI buttonValidateOtp">
              <button
                type="button"
                class="btn-open-account open-account otp-validate-button-cj"
                (click)="sendCode()"
              >
                <p>VALIDAR</p>
              </button>
            </div>
          </div>
        </div>
        <!-- <div  class="form-div row"
              *ngIf="showDatePhones()">
            <p class="phone-text ci-mobile">Tu móvil <span class="phone-number"> {{ setPhoneEncoded(temporalMobilePhone || getPhone()) }} </span> </p>
        </div> -->
        <div class="row">
          <p class="letter resend-otp">Para volver a recibir el código SMS pulsa
            <a class="link-otp-cj ci-mobile" (click)="resendSMS()"> aqui</a>
          </p>
        </div>
        <div class="margin-top-8">
          <p class="letter subtitle-otp">
            Si no recibes el código comprueba que tu número es correcto.
          </p>
        </div>
        <div class="form-div whithout-margin-mb">
          <div class="phoneCI rectangle-2">
            <div class="inline-box">
              <div>
                <img class="movil-icon-cj" src="assets/images/iconos-movil-ic-movil.png">
              </div>
              <div>
                <p class="text-placeholder-cj ci-mobile">Tu móvil</p>
                <input class="check-phone-cj"
                  type="tel-ci"
                  #mobilePhone
                  required
                  number-input
                  (paste)="validatePhone($event)"
                  [minLength]="9"
                  [maxLength]="9"
                  autocomplete="off"
                  inputmode="numeric"
                  formControlName="mobilePhone"
                  [value]="getPhone()"
                />
                <div
                  class="error-text"
                  *ngIf="
                    errService.desktopErrors(
                      otpDetailsForm.get('mobilePhone'),
                      'mobilePhone'
                    )
                  "
                >
                  {{
                    errService.desktopErrors(
                      otpDetailsForm.get('mobilePhone'),
                      "mobilePhone"
                    )
                  }}
                </div>
                <div class="error-text" *ngIf="mobilePhoneDuplicated">
                  {{ errorMobilePhoneDuplicated }}
                </div>
              </div>
              <div class="edit-number">
                <p class="edit-number-text">
                  <a (click)="showInputPhoneCI()">EDITAR NUMERO</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="main-register flex-direction" *ngIf="isCIAccount">
  <box-image
    [classImgFile]="'imagen1'"
    [icon]="'ic-dinero-euro'"
    [titleCss]="'title-2 '"
    [subtitleCss]="'subtitle-2'"
    [title]="'¡GRATIS, GRATIS!'"
    [subtitle]="
      'Ni comisiones ni gastos de ningún tipo: ni de mantenimiento, ni
                            por transferencias nacionales y comunitarias (SEPA) en euros,
                            ni por ingresos de cheques nacionales.'
    "
    class="image-div"
  ></box-image>
  <div class="form-and-step-div">
    <steps-ci class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div form-otp">
      <p class="letter title-otp">Valida tu número de teléfono</p>
      <p class="letter subtitle-otp" *ngIf="paramsSMS && paramsSMS.telephone">
       Introduce la clave recibida por SMS en tu teléfono móvil {{getObfuscatedPhone()}} para poder validarlo.
      </p>
      <form
        [formGroup]="otpDetailsForm"
        (ngSubmit)="onSubmit()"
        (keydown.enter)="$event.preventDefault()"
        novalidate
      >
        {{ showPhoneCI ? "" : keyphone.focus() }}
        <div class="floating-placeholder-group row-ci half-line">
          <div [class]="setClassKeyPhone()">
            <input
              type="tel-ci"
              required
              id="keyphone"
              #keyphone
              number-input
              class="tel-ci width-input-mb"
              [minLength]="maxlengthOTP != null ? maxlengthOTP : 6"
              [maxLength]="maxlengthOTP != null ? maxlengthOTP : 6"
              autocomplete="off"
              inputmode="numeric"
              formControlName="smsphone"
              [attr.disabled]="clientExists ? '' : null"
              [ngClass]="otpDetailsForm.get('smsphone').value ? 'input-noEmpty' : 'input-empty'"
            />

            <label class="floating-placeholder">Código SMS</label>
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  otpDetailsForm.get('smsphone'),
                  'smsphone'
                )
              "
            >
              {{
                errService.desktopErrors(
                  otpDetailsForm.get('smsphone'),
                  "smsphone"
                )
              }}
            </div>
            <!-- <p class="check-sms">Si no recibes el código pasados unos segundos comprueba que el número es correcto y vuelve a enviarlo. </p> -->
          </div>
          <div class="triangulo-equilatero-bottom"></div>
          <div class="otp-info">
            <p class="ic_bombilla_encendida"></p>
            <p class="test-info-otp">
              Ten en cuenta que tiene una validez de 5 minutos.
            </p>
          </div>
        </div>
        <!-- <div  class="form-div row"
              *ngIf="showDatePhones()">
            <p class="phone-text ci-mobile">Tu móvil <span class="phone-number"> {{ setPhoneEncoded(temporalMobilePhone || getPhone()) }} </span> </p>
        </div> -->
        <div class="form-div row" *ngIf="allowEditPhone && !showPhoneCI">
          <a class="link-otp ci-mobile" (click)="showInputPhoneCI()">
            NO HE RECIBIDO EL CÓDIGO
            <span [ngClass]="showPhoneCI ? 'link-otp2' : 'link-otp1'"></span>
          </a>
        </div>
        <div class="form-div whithout-margin-mb" [hidden]="!showPhoneCI">
          <div class="row">
            <p class="text-otp">
              Comprueba que el número de teléfono es correcto. Si no lo es, corrígelo y haz
              clic en "volver a enviar código SMS".
            </p>
          </div>
          <div class="row">
            <div class="floating-placeholder-group">
              <div class="phoneCI">
                <input
                  type="tel-ci"
                  required
                  number-input
                  (paste)="validatePhone($event)"
                  [class]="
                    !otpDetailsForm.get('mobilePhone').valid &&
                    !otpDetailsForm.get('mobilePhone').untouched
                      ? 'error'
                      : 'clean'
                  "
                  [minLength]="9"
                  [maxLength]="9"
                  autocomplete="off"
                  inputmode="numeric"
                  formControlName="mobilePhone"
              [ngClass]="otpDetailsForm.get('mobilePhone').value ? 'input-noEmpty' : 'input-empty'"

                />
                <label class="floating-placeholder">Teléfono móvil</label>
                <div
                  class="error-text"
                  *ngIf="
                    errService.desktopErrors(
                      otpDetailsForm.get('mobilePhone'),
                      'mobilePhone'
                    )
                  "
                >
                  {{
                    errService.desktopErrors(
                      otpDetailsForm.get('mobilePhone'),
                      "mobilePhone"
                    )
                  }}
                </div>
                <div class="error-text" *ngIf="mobilePhoneDuplicated">
                  {{ errorMobilePhoneDuplicated }}
                </div>
              </div>
            </div>
            <!-- <div class="sms-container">
            <button   type="button"
                      class="btn-common btn-otp"
                      [disabled]="!otpDetailsForm.get('mobilePhone').valid || mobilePhoneDuplicated"
                      (click)="sendSMS()">
              <p> ENVIAR SMS </p>
            </button>
            </div> -->
          </div>
        </div>
        <div
          class="form-div row"
          [ngClass]="showPhoneCI ? 'sms-line' : ''"
          *ngIf="allowResendSMS && showPhoneCI"
        >
          <a
            class="link-otp ci-mobile"
            [attr.disabled]="getPhone() ? null : ''"
            (click)="resendSMS()"
          >
            VOLVER A ENVIAR CÓDIGO SMS
            <span class="link-otp1"></span>
          </a>
        </div>
        <hr class="line-separator hidden-movil" />
        <div class="buttonCI">
          <div class="return-text">
            <p (click)="returnInit()">
              <span class="link-otp-black"></span>
              Atrás
            </p>
          </div>
          <button
            type="button"
            class="btn-open-account open-account"
            (click)="sendCode()"
          >
            <p>CONTINUAR</p>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
