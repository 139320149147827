import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MicrosegConfigResponse, MicrosegurosConfigCapitales } from 'app/collections/microseg-config';
import { TarificationOptResponse, TarificationOpt } from 'app/collections/tarification-opt';
import { Tarification, TarificationResponse } from 'app/collections/tarification';
import { CheckBalance } from 'app/collections/check-balance';
import { MicroinsRegistry } from 'app/collections/microins-registry';
import { CancelPolicy } from 'app/collections/microseg-adhesion';
import { ListPolicies } from 'app/collections/list-policies';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiMicroinsurances {
  private options = {};
  constructor(
    private httpClient: HttpClient) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  public getConfigLists(value: string): Observable<MicrosegConfigResponse> {
    let body = { config: value };

    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/configList',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getConfigCapitales(): Observable<MicrosegurosConfigCapitales> {
    let body = { config: 'PDF_CAPITALGLOBAL' };

    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/configList',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getTarificationOpt(tarificationOpt: TarificationOpt): Observable<TarificationOptResponse> {
    let body = tarificationOpt;
    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/tarificationOpt',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getTarification(tarification: Tarification): Observable<TarificationResponse> {
    let body = tarification;
    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/tarification',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getCheckBalance(checkBalance: CheckBalance) {
    let body = checkBalance;
    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/checkBalance',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public microinsRegistryFirstCall(microRegistry: MicroinsRegistry) {
    let body = microRegistry;

    let headersMicroRegistry = {
      mfaAmount: microRegistry.expense
    };
    sessionStorage.removeItem('microRegistryCall2');
    sessionStorage.setItem('microRegistryCall1', JSON.stringify(headersMicroRegistry));

    return this.httpClient
      .post(API_URL + '/mfaServices/microinsRegistry',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public microinsRegistrySecondCall(microRegistry: MicroinsRegistry, mfaId: string, mfaValidationData:string) {
    let body = microRegistry;

    let headersMicroRegistry = {
      mfaId: mfaId,
      mfaValidationData: mfaValidationData,
      mfaAmount: microRegistry.expense.toString()
    };

    sessionStorage.removeItem('microRegistryCall1');
    sessionStorage.setItem('microRegistryCall2', JSON.stringify(headersMicroRegistry));

    return this.httpClient
      .post(API_URL + '/mfaServices/microinsRegistry',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getCertificate(requestId: string) {
    return this.httpClient
      .get(API_URL + '/microseg/integracion/mpm/certificate?requestId=' + requestId,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public cancelPolicy(policy: CancelPolicy) {
    let body = policy;
    return this.httpClient
      .post(API_URL + '/microseg/salesforce/v1/bajaPoliza',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public listPolicies(listPolicies: ListPolicies) {
    let body = listPolicies;
    return this.httpClient
      .post(API_URL + '/microseg/integracion/mpm/listPolicies',
      body,
      this.options)
      .pipe(
        map(response => {
          return response['body'];
        })
      )
  }

  public getContract() {
    return this.httpClient
    .get(API_URL + '/microseg/integracion/mpm/contract',
    this.options)
    .pipe(
      map(response => {
        return response['body'];
      })
    )
  }

}
