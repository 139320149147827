import { DocumentVideoId } from "app/collections/document-video-id";

export const DOCUMENTOS_VIDEO_ID: DocumentVideoId[] = [

  { value: '600', name: 'CHIPRE', code: 'CYP', documentsVideoId:
    {
      identity_card_td1: 47, passport: 48 
    } 
  },
  { value: '053', name: 'ESTONIA', code: 'EST', documentsVideoId:
    {
      identity_card_td1: 63, passport: 64 
    } 
  },
  { value: '032', name: 'FINLANDIA', code: 'FIN', documentsVideoId:
    {
      identity_card_td1: 66, passport: 67 
    } 
  },
  { value: '007', name: 'IRLANDA', code: 'IRL', documentsVideoId:
    {
      identity_card_td1: 192, passport: 85
    } 
  },
  { value: '005', name: 'ITALIA', code: 'ITA', documentsVideoId:
    {
      identity_card_td1: 200, passport: 90 
    } 
  },
  { value: '054', name: 'LETONIA', code: 'LVA', documentsVideoId:
    {
      identity_card_td1: 112, passport: 111 
    } 
  },
  { value: '108', name: 'LUXEMBURGO (ANTES)', code: 'LUX', documentsVideoId:
    {
      identity_card_td1: 110, passport: 109 
    } 
  },
  { value: '003', name: 'PAISES BAJOS', code: 'NLD', documentsVideoId:
    {
      identity_card_td1: 136, passport: 137 
    } 
  },
  { value: '060', name: 'POLONIA', code: 'POL', documentsVideoId:
    {
      identity_card_td1: 146, passport: 145 
    } 
  },
  { value: '010', name: 'PORTUGAL', code: 'PRT', documentsVideoId:
    {
      identity_card_td1: 148, passport: 147 
    } 
  },
  { value: '063', name: 'REPUBLICA ESLOVACA', code: 'SVK', documentsVideoId:
    {
      identity_card_td1: 165, passport: 163 
    } 
  },
  { value: '039', name: 'SUIZA', code: 'CHE', documentsVideoId:
    {
      identity_card_td1: 34, passport: 33 
    } 
  },
  { value: '009', name: 'GRECIA', code: 'GRC', documentsVideoId:
    {
      identity_card_td1: 215, passport: 76 
    } 
  },
  { value: '037', name: 'LIECHTENSTEIN', code: 'LIE', documentsVideoId:
    {
      identity_card_td1: 231, passport: 105 
    } 
  },
  { value: '024', name: 'ISLANDIA', code: 'ISL', documentsVideoId:
    {
      passport: 88 
    } 
  },
  { value: '091', name: 'ESLOVENIA', code: 'SVN', documentsVideoId:
    {
      identity_card_td1: 167, passport: 166 
    } 
  },
  { value: '030', name: 'SUECIA', code: 'SWE', documentsVideoId:
    {
      identity_card_td1: 168, passport: 169 
    } 
  },
  { value: '001', name: 'FRANCIA', code: 'FRA', documentsVideoId:
    {
      /*identity_card_td1: 296,*/ identity_card_td2: 69, passport: 68 
    } 
  },
  { value: '092', name: 'CROACIA', code: 'HRV', documentsVideoId:
    {
      identity_card_td1: 193, passport: 80 
    } 
  },
  { value: '061', name: 'REPUBLICA CHECA', code: 'CZE', documentsVideoId:
    {
      identity_card_td1: 228, identity_card_td2: 50, passport: 49 
    } 
  },
  { value: '046', name: 'MALTA', code: 'MLT', documentsVideoId:
    {
      identity_card_td1: 125, passport: 124 
    } 
  },
  { value: '004', name: 'ALEMANIA', code: 'DEU', documentsVideoId:
    {
      identity_card_td1: 53, identity_card_td2: 52, passport: 51 
    } 
  },
  { value: '066', name: 'RUMANIA', code: 'ROU', documentsVideoId:
    {
      /*identity_card_td1: 301,*/ identity_card_td2: 210, passport: 154 
    } 
  },
  { value: '055', name: 'LITUANIA', code: 'LTU', documentsVideoId:
    {
      identity_card_td1: 108, passport: 107 
    } 
  },
  { value: '038', name: 'AUSTRIA', code: 'AUT', documentsVideoId:
    {
      identity_card_td1: 11, passport: 12 
    } 
  },
  { value: '064', name: 'HUNGRIA', code: 'HUN', documentsVideoId:
    {
      identity_card_td1: 83, passport: 82 
    } 
  },
  { value: '068', name: 'BULGARIA', code: 'BGR', documentsVideoId:
    {
      identity_card_td1: 19, passport: 20 
    } 
  },
  { value: '002', name: 'BELGICA (ANTES)', code: 'BEL', documentsVideoId:
    {
      identity_card_td1: 15, passport: 16 
    } 
  },
  { value: '008', name: 'DINAMARCA', code: 'DNK', documentsVideoId:
    {
      passport: 55 
    } 
  },
  { value: '028', name: 'NORUEGA', code: 'NOR', documentsVideoId:
    {
      /*identity_card_td1: 283,*/ passport: 138 
    } 
  },
];
