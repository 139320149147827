import { Injectable } from '@angular/core';
@Injectable()
export class StepsCiService {
  passStep: any;
  fullMaximumPath: any = 0;
  public  getPassStep(): any {
    return this.passStep;
  }
  public setPassStep(val: any) {
    this.passStep = val;
  }

  public getRegisterCi(): any {
    const stepci =  [
      {
          id: 0,
          icon: 'mobile-icon',
          subURLs: [
              'bienvenido',
              'confirmacion-otp',
              'confirmacion-otp-primer-paso',
              'cuenta-joven/bienvenido',
              'cuenta-joven/confirmacion-otp',
              'cuenta-joven/confirmacion-otp-primer-paso',
              'identificacion-edad'
          ],
          tooltip: 'Bienvenido',
          active: 'inactive',
          canReturn: false
      },
      {
          id: 1,
          icon: 'ic_cara_sonrisa',
          subURLs: [
              'cuenta-joven/datos-personal',
              'datos-personal'
          ],
          tooltip: 'Datos Personales',
          active: 'inactive',
          canReturn: false
      },
      {
          id: 2,
          icon: 'ic-brujula',
          subURLs: [
              'cuenta-joven/datos-contacto',
              'datos-contacto'

          ],
          tooltip: 'Datos de Contacto',
          active: 'inactive',
          canReturn: false
      },
      {
          id: 3,
          icon: 'ic_maletin',
          tooltip: 'Datos laborales',
          subURLs: [
            'cuenta-joven/datos-laboral',
            'datos-laboral'
          ],
          active: 'inactive',
          canReturn: false
      },
      {
          id: 4,
          icon: 'ic_huella_dactilar',
          subURLs: [
              'cuenta-joven/metodo-validacion',
              'cuenta-joven/identificacion-iban',
              'cuenta-joven/identificacion-video',
              'cuenta-joven/confirmacion-datos',
              'confirmacion-datos',
              'previa-firma'
          ],
          tooltip: 'Confirmacion Datos',
          active: 'inactive',
          canReturn: false
      }
    ];
    return stepci;
  }

  public setFullMaximumPath(id): any {
    sessionStorage.setItem("fullMaximumPath", id);
  }
  public getFullMaximumPath(): any {
    this.fullMaximumPath = Number(sessionStorage.getItem("fullMaximumPath"));
    return this.fullMaximumPath;
  }
}
