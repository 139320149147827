<mobile-error-zone datepickerzone="true"
*ngIf="errorsArray.length > 0"
[errors]="errorsArray">
</mobile-error-zone>

<div class="datepicker">
  <div class="header">
    <div class="title-container">
      <span class="title">FECHA Y HORA DE INICIO</span>
    </div>
  </div>
  <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit($event)" [formGroup]="dateForm" >
    <div class="body">
        <div class="hour-picker">
            <p-calendar
                formControlName="date"
                #calendar
                id="calendar"
                name="calendar"
                [defaultDate]="defaultDate"
                [minDate]="mindate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [selectOtherMonths]="true"
                [locale]="es"
                [yearRange]="getYearRange()"
                [inline]="true">
            </p-calendar>
        </div>

        <div class="hour-picker">
            <span class="head-text">Hora de inicio de la actividad</span>
            <hr>
            <div class="row">
                <div class="left">
                    <div class="evo right-arrow arrow-up" (click)="onUpHours($event);"></div>
                    <input
                        #hours
                        id="hours"
                        name="hours"
                        number-input
                        type="tel"
                        maxlength="2"
                        placeholder="00"
                        formControlName="hours">
                    <div class="error-text" *ngIf="errService.desktopErrors(dateForm.get('hours'), 'hours')">
                        {{errService.desktopErrors(dateForm.get('hours'), 'hours')}}
                    </div>
                    <div class="evo right-arrow arrow-down" (click)="onDownHours($event)"></div>
                </div>
                <span class="alignLeft">:</span>
                <div class="right">
                    <div class="evo right-arrow arrow-up" (click)="onUpMinutes($event)"></div>
                    <input
                        #minutes
                        id="minutes"
                        name="minutes"
                        number-input
                        type="tel"
                        maxlength="2"
                        placeholder="00"
                        formControlName="minutes">
                    <div class="error-text" *ngIf="errService.desktopErrors(dateForm.get('minutes'), 'minutes')">
                        {{errService.desktopErrors(dateForm.get('minutes'), 'minutes')}}
                    </div>
                    <div class="evo right-arrow arrow-down" (click)="onDownMinutes($event)"></div>
                </div>
                <span class="alignLeft">h</span>
            </div>
        </div>
    </div>

    <p class="errorMinHour" *ngIf="errorHourMin.length > 0">{{ errorHourMin }}</p>
    <div class="menu-bottom">
        <div class="btn-group">
            <button class="btn-common btn-common_fix_bold" type="submit" [disabled]="!dateForm.valid">SELECCIONAR</button>
        </div>
    </div>
    </form>

</div>
