import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthUserService } from 'app/services/auth-user.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { PersonaService } from 'app/services/persona.service';
import { DeviceService } from 'app/services/device.service';
import { SecurityService } from 'app/services/security-registration.service';
import { HttpClient } from '@angular/common/http';
import { ERRORSFORM } from 'app/resources/errors-form';



@Component({
  selector: 'config-investment-account',
  templateUrl: './config-investment-account.component.html',
  styleUrls: ['./config-investment-account.component.scss'],
  providers: [ModalService]
})
export class ConfigInvestmentAccountComponent implements OnInit {

  public clientID;
  public loading;
  public showSecondStep: boolean = false;
  public cuentas: Array<any> = [];
  public cuentasProductos: Array<any>;
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public newAccount: boolean = true;
  public datosForm;
  public cuentaForm: FormGroup;
  public canal: string;
  public estado: string;
  public codProductoContratado: string;
  public canalOrigen: string;
  public tipoSolicitud: string;
  public tieneSolicitudes;
  public solicitudesEnCurso;
  public idCaso: string;
  public showError: boolean = false;
  public nameError: string = "";
  public oblig: boolean = false;
  public casoEnVuelo: boolean;
  public cuentaCargo: string;
  private acuerdoCuentaCargo: string;
  public nickname: string;
  public obj: Object;
  public DRF: boolean = false;
  public segundoTitular: boolean = false;
  public activarSegundoTitular: boolean = false;
  public producto: string;
  private numeroAcuerdo: string;
  private acuerdoCuentaCargoElectronica: string;
  private cuentaCargoElectronica: string;
  public datosSegTitular: Object;
  public mailSegTitular: string;
  public datosSegundoTitular;
  public formNickname: string;
  public hiddeNotification: boolean = false;
  public bannerRevisa: boolean = false;
  public formValido: boolean = false;
  public checkLegales: boolean = null;
  public invalidNickname: boolean = false;
  public invalidEmail: boolean = null;
  public ngValid: boolean = null;
  public isSecondSigner;
  public defaultAcount: string;
  public currentPath: string;
  public step: number;
  public nicknameExist: boolean = false;
  public nicknameError: boolean = false;
  public nicknameErrorCaracteres: boolean = false;
  public idExterno: string;
  public isRedireccion = true;
  public contieneEspacio: boolean = false;
  modalLegal = 'legal-modal2';
  public showInputEmail: boolean = false;
  public emailSegundoTitular: string;
  public idCliente: string;
  public action = 'onboarding';
  public esFondoInteligente: boolean = false;
  public nickNoValido: boolean = false;
  public finalText1Titular;
  public isBmi: boolean = false;
  public isMobileOrTablet: boolean = this.deviceService.isMobileOrTablet();
  public origen: string;
  public emailErrorClear: boolean = true;
  public recuperarCaso: boolean = false;
  public casoPendiente: any;
  public modificacionOK: boolean = false;
  public hideBannerNotificacionOk: boolean = false;

  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  public errors = ERRORSFORM;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public personaSrv: PersonaService,
    private securitySrv: SecurityService,
    private deviceService: DeviceService,
    private http: HttpClient) {

    this.datosForm = new FormGroup({
      cuentas: new FormControl(),
      nickname: new FormControl(),
      seleccionarCuenta: new FormControl(),
      email: new FormControl(),
      confirmEmail: new FormControl(),
      checkAccept: new FormControl(),
      checkAddAccount: new FormControl(),
      checkAcceptConditions: new FormControl(),
    });

    this.router.events.subscribe((url: any) => {
      if(url instanceof NavigationEnd) {
        this.currentPath = url.url;
        this.setSteps();
      }
    });

  }

  ngOnInit() {
    this.showError = true;
    this.nameError = "loadingCustom";

    this.hideBannerNotificacionOk = JSON.parse(sessionStorage.getItem("notificacionOk"));

    let backFromStep3 = sessionStorage.getItem('operacion-inversora') === 'operacion-inversora-cuenta-cargo';
    if (backFromStep3) {
      this.showSecondStep = true;
    }


    if (!this.showSecondStep && sessionStorage.getItem('operacion-inversora') === "operacion-inversora-nickname" || sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname') {
      this.modificacionOK =  (sessionStorage.getItem('peticion-modificacion') === 'true');
      this.triggerInvesmentNavData('Paso 02.1 - Configuración nickname');
    }

    this.errService.modDataError = true;

    //Se meten en un json los datos que no están completos y son obligatorios
    if (sessionStorage.getItem("userInfo")) {
      this.loginSrv.comprobacionDatos();
    }

    this.getQueryParams();


    if (!this.isRedireccion) {

      this.checkSessionStorage();


      if (!this.showSecondStep) { //No volvemos de visor-cuenta-inversora (Botón atrás)

        if (this.producto === '009') {//Cuenta inversora
          this.initCuentaInversora();
        } else if (this.producto === '010') {//Fondo inteligente
          this.initFondoInteligente();
        }

      }
      else {
        this.showError = false;
        this.nameError = "ocultarErrores";
      }

      this.cargarCuentas();

    }

    this.checkCondicionesLegales();

    //Matiene los tooltip del nickname actualizados
    this.updateTooltip();
  }

  initCuentaInversora() {
    if (!this.showSecondStep && sessionStorage.getItem('operacion-inversora') !== "operacion-inversora-nickname") {
      sessionStorage.setItem('operacion-inversora', 'operacion-inversora');
      this.showError = true;
      this.nameError = "loadingCustom";
    }
    else {
      this.showError = false;
      this.nameError = "ocultarErrores";
    }

    if (!this.showSecondStep && sessionStorage.getItem('operacion-inversora') === "operacion-inversora") {
      this.canal = this.deviceService.getDevice();
      if (this.isSecondSigner == true) {
        this.initCISecondSigner();
      } else {
        this.initCI();
      }
    }
  }

  initFondoInteligente() {
    if (!this.showSecondStep && sessionStorage.getItem('operacion-inversora') !== "fondo-inteligente-nickname") {
      sessionStorage.setItem('operacion-inversora', 'fondo-inteligente');
      this.showError = true;
      this.nameError = "loadingCustom";
    }
    else {
      this.showError = false;
      this.nameError = "ocultarErrores";
    }

    if (!this.showSecondStep && sessionStorage.getItem('operacion-inversora') === "fondo-inteligente") {
      //Coge los datos de la sesion
      this.canal = this.deviceService.getDevice();
      this.initCI();
    }
  }

  getQueryParams() {

    this.activatedRoute.queryParams.subscribe(params => {

      this.isFondoInteligente(params['codProductoContratado']);

      if (params['origen']) {
        this.origen = params['origen'];
      }

      this.isBmi = sessionStorage.getItem('isBMI') === 'true';

      if (params['codProductoContratado'] && !(sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname')
        && !(sessionStorage.getItem('operacion-inversora') === 'operacion-inversora-nickname') && !(sessionStorage.getItem("updateData"))) {
        this.producto = params['codProductoContratado'];
        sessionStorage.setItem("productoContratando", this.producto);
        if (params['idCaso']) {
          this.idCaso = params['idCaso'];
        }
        if (this.producto === '009') {
          sessionStorage.setItem('operacion-inversora', "operacion-inversora");
        } else {
          sessionStorage.setItem('operacion-inversora', "fondo-inteligente");
          this.producto = '010';
        }
      } else if (params['idCaso'] && !(sessionStorage.getItem('isBMI') === 'true')) {
        this.idCaso = params['idCaso'];
        sessionStorage.setItem("casoSeleccionado", this.idCaso);
        this.producto = "009";
        sessionStorage.setItem("productoContratando", this.producto);
        sessionStorage.setItem('operacion-inversora', "operacion-inversora");
      } else if (!sessionStorage.getItem("productoContratando")) {
        this.producto = "010";
        sessionStorage.setItem("productoContratando", this.producto);
        sessionStorage.setItem('operacion-inversora', "fondo-inteligente");
      }



      if (params['estado']) {
        //abandono
        // sessionStorage.setItem('auth_token', params.token);
        sessionStorage.setItem('abandono', "true");
        this.isRedireccion = true;
        sessionStorage.setItem("casoSeleccionado", this.idCaso);
        if (params['isSecondSigner'] === 'true') {
          this.isSecondSigner = true;
        } else {
          this.isSecondSigner = false;
        }
        sessionStorage.setItem('isSecondSigner', this.isSecondSigner);
        this.gestionAbandono(params['estado'], params['clientId'], params['idCaso']);
      } else if (params['isSecondSigner'] === 'true') {
        //flujo segundoTitular
        this.isSecondSigner = true;
        sessionStorage.setItem('isSecondSigner', this.isSecondSigner);
        this.idCaso = params['idCaso'];
        sessionStorage.setItem("casoSeleccionado", this.idCaso);
        this.isRedireccion = true;
        sessionStorage.setItem('abandono', "true");
        // this.gestionAbandono('abandono', params['clientId'], params['idCaso']);
        this.gestionAbandono(params['estado'], params['clientId'], params['idCaso']);
        //this.gestionSegundoTitular( params['clientId'], params['idCaso']);
      } else if (params['isSecondSigner'] === 'false') {
        //flujo primerTitular
        this.idExterno = params['clientId'];
        this.isSecondSigner = false;
        sessionStorage.setItem('isSecondSigner', this.isSecondSigner);
        this.isRedireccion = true;
        sessionStorage.setItem('abandono', "true");
        this.idCaso = params['idCaso'];
        sessionStorage.setItem("casoSeleccionado", this.idCaso);
        //Booleano para mostrar en la pantalla final el titulo correcto.
        this.finalText1Titular = true;
        sessionStorage.setItem("mostrarTexto1Titular", this.finalText1Titular);
        this.gestionAbandono("abandono", params['clientId'], params['idCaso']);
      } else if (params['origen'] && params['origen'].toLowerCase() === 'bmi') {
        sessionStorage.setItem('isBMI', "true");
        this.isBmi = true;
        if (params['codProductoContratado'] === '009') {
          sessionStorage.setItem('operacion-inversora', "operacion-inversora");
          this.producto = params['codProductoContratado'];
        } else if (params['codProductoContratado'] === '010' &&
          !(sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname')) {
          sessionStorage.setItem('operacion-inversora', "fondo-inteligente");
          this.producto = params['codProductoContratado'];
        } else {
          this.producto = params['codProductoContratado'];
        }
        //Modificar si viniese el parámerto desde la url
        sessionStorage.setItem('isSecondSigner', "false");
        sessionStorage.setItem('idInternoPe', params['idInterno']);
        sessionStorage.setItem("auth_token", params['token']);
        sessionStorage.setItem("refresh_token", params['refresh']);
        this.idExterno = params['dni'];
        // this.clientID = JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
        this.isRedireccion = true;

        let casoSeleccionado = sessionStorage.getItem("casoSeleccionado");
        if (!params['idCaso'] && casoSeleccionado == null || casoSeleccionado == 'undefined') {
          this.gestionAbandonoBMI();
        } else if (casoSeleccionado) {
          this.gestionAbandonoBMI(casoSeleccionado);
        } else {
          sessionStorage.setItem("casoSeleccionado", params['idCaso']);
          this.gestionAbandonoBMI(params['idCaso']);
        }
      }
      else if (params['origen'] && params['origen'].toLowerCase() === 'be' && sessionStorage.getItem("isLogin") !== "true") {
        this.isBmi = true;
        if (params['codProductoContratado'] === '009') {
          sessionStorage.setItem('operacion-inversora', "operacion-inversora");
          this.producto = params['codProductoContratado'];
        } else if (params['codProductoContratado'] === '010' &&
          !(sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname')) {
          sessionStorage.setItem('operacion-inversora', "fondo-inteligente");
          this.producto = params['codProductoContratado'];
        } else {
          this.producto = params['codProductoContratado'];
        }
        //Modificar si viniese el parámerto desde la url
        sessionStorage.setItem('isSecondSigner', "false");
        sessionStorage.setItem('idInternoPe', params['idInterno']);
        sessionStorage.setItem("auth_token", params['token']);
        sessionStorage.setItem("refresh_token", params['refresh']);
        this.idExterno = params['dni'];
        // this.clientID = JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
        this.isRedireccion = true;

        let casoSeleccionado = sessionStorage.getItem("casoSeleccionado");
        if (!params['idCaso'] && casoSeleccionado == null || casoSeleccionado == 'undefined') {
          this.gestionAbandonoBMI();
        } else if (casoSeleccionado) {
          this.gestionAbandonoBMI(casoSeleccionado);
        } else {
          sessionStorage.setItem("casoSeleccionado", params['idCaso']);
          this.gestionAbandonoBMI(params['idCaso']);
        }
      }
      else {
        this.isRedireccion = false;
        sessionStorage.setItem("isSecondSigner", "false");
        //NUEVA CONTRATACION
        this.producto = params['codProductoContratado'];
      }
    });
  }

  checkSessionStorage() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    let userData = JSON.parse(sessionStorage.getItem('userData'));

    if (userData) {
      this.clientID = userData.persona.id_cliente_EVO;
    } else {
      this.clientID = userInfo.datosPersonales.id_cliente_EVO;
    }
  }


  /**
     * Método que recoge de sesión el nombre y apellidos del segundo titular y lo guarda en una variable
     * que se llama nombreSegTitularCompleto
     */
  activarST(actualizarST?) {


    if (!actualizarST) {
      this.activarSegundoTitular = !this.activarSegundoTitular;
    }

    if (this.activarSegundoTitular) {
      sessionStorage.setItem('activarSegundoTitular', "true");
      this.triggerInvesmentNavData('Paso 02.2 - Invitar cliente a EVO');
    } else {
      sessionStorage.removeItem('activarSegundoTitular');
    }

    let datos = JSON.parse(sessionStorage.getItem("userData")).listaContratados;

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].ibanCC === this.datosForm.get('cuentas').value ||
        (datos[i].nombreProducto + " - " + datos[i].ibanCC === this.datosForm.get('cuentas').value)) {
        if (datos[i].nombreSegTitular) {
          this.segundoTitular = true;
          this.datosSegTitular = {
            'nombreSegTitular': datos[i].nombreSegTitular,
            'apellido1SegTitular': datos[i].apellido1SegTitular,
            'apellido2SegTitular': datos[i].apellido2SegTitular,
            'idExternoSegTitular': datos[i].idExternoSegTitular,
            'idInternoSegTitular': datos[i].idInternoSegTitular,
            'mailSegTitular': datos[i].mailSegTitular,
            'movilSegTitular': datos[i].movilSegTitular


          }
          this.mailSegTitular = datos[i].mailSegTitular;
          sessionStorage.setItem('emailSegundoTitular', this.datosSegTitular['mailSegTitular']);
        } else {
          this.segundoTitular = false;
        }
      }
    }

    //Si no existe el segundo titular, solo se muestran las casillas de introducir el email si el usuario
    //ha seleccionado añadir segundo titular
    if (this.segundoTitular) {
      this.setEmail(1);
    } else {
      this.setEmail(2);
    }

    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get("checkAcceptConditions").value === true) {
        this.checkLegales = false;
      }

      if (this.emailErrorClear) {
        this.hideNotification();
      }
      /*else{
       this.checkLegales = true;
      }*/
    });
  }

  private createEmailForm() {
    const emailRegex = /.+@.+\..+/i;
    this.cuentaForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex), Validators.maxLength(45)]],
      confirmEmail: ['', [Validators.required, Validators.pattern(emailRegex), Validators.maxLength(45)]],
      checkAcceptConditions: ['', [Validators.required]]
    })

    this.formSubscribe(this.cuentaForm);
  }

  inFocusNickname() {
    // Se ocultan los tooltip de error.
    this.nicknameExist = false;
    this.nickNoValido = false;

    this.quitarCampoError();
  }

  inFocusEmail() {
    this.emailErrorClear = true;
    this.helpers.resetFieldsState(this.datosForm, ['email']);
    this.helpers.resetFieldsState(this.datosForm, ['confirmEmail']);

    if (!this.datosForm.get('checkAcceptConditions').value !== true) {
      this.hideNotification();
    }
  }

  outFocus(input) {
    this.helpers.resetFieldsState(this.datosForm, ['email']);
    this.helpers.resetFieldsState(this.datosForm, ['confirmEmail']);
  }

  private formSubscribe(form) {

    const emailRegex = /.+@.+\..+/i;
    const userMail = this.datosForm.get('email'),
      confirmEmail = this.datosForm.get('confirmEmail');

    userMail.valueChanges.subscribe(mail => {
      if (userMail.value === "") {
        this.helpers.resetFieldsState(this.cuentaForm, ['email']);
      }
      this.helpers.updateFieldValidation(this.cuentaForm, 'email', [Validators.required, Validators.pattern(emailRegex)]);
    })

    confirmEmail.valueChanges.subscribe(confirm => {
      if (confirmEmail.value === "") {
        this.helpers.resetFieldsState(this.cuentaForm, ['confirmEmail']);
      }
      this.helpers.updateFieldValidation(this.cuentaForm, 'confirmEmail', [Validators.required, Validators.pattern(emailRegex)]);
    })

    this.cuentaForm.get('checkAcceptConditions').valueChanges.subscribe(legal => {
      const conditionsCheck = this.cuentaForm.get('checkAcceptConditions');
      if (conditionsCheck.value === false) {
        this.modService.close(this.modalLegal);
      }
      else if (conditionsCheck.value === true) {
        this.helpers.resetFieldsState(this.cuentaForm, ['checkAcceptConditions']);
        this.modService.open(this.modalLegal);

        if (this.emailErrorClear) {
          this.errService.errorsArray = [];
        }
      }
    })
  }

  submit() {
    if (this.activarSegundoTitular) {
      if (this.datosForm.get('seleccionarCuenta').value === '1') {//Está seleccionado el segundo titular de la cuenta
        //El segundo titular ya existe y solo valida que se ha seleccionado la cuenta y ha aceptado las condiciones
        this.validarFormularioCuenta2();
      } else if (this.datosForm.get('seleccionarCuenta').value === '2' ||
        this.datosForm.get('seleccionarCuenta').value === null) {//El usuario debe introducir y confirmar el email
        //Se validan que los email sean iguales, la cuenta y haya aceptado las condiciones
        this.validarFormularioCuenta();
      } else {
        this.validarFormulario();
      }
    } else {
      //comprobamos validez del nickName
      this.validarFormulario();
    }
    if (this.cuentaForm.valid) {
      this.bannerRevisa = false;
      this.showError = true;
      this.nameError = "loadingCustom";
      this.updateInversora("categorizacion-tit1");
    } else {
      this.bannerRevisa = true;
      this.hiddeNotification = false;
    }

    this.setIbanAcountSelected();
  }

  /**
   * Se mete en sesión el IBAN de la cuenta que ha seleccionado el cliente
   */
  setIbanAcountSelected() {
    let cuenta = this.datosForm.get("cuentas");
    let cuentaSeleccionada = this.cuentas.find(elem => elem.name == cuenta.value) || this.cuentas.find(elem => elem.value == cuenta.value);
    if (cuentaSeleccionada) {
      sessionStorage.setItem("ibanCuentaSeleccionada", cuentaSeleccionada.value);
    }
  }

  validarFormulario() {
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get("cuentas").value, [Validators.required]]
    });
  }

  validarFormularioCuenta() {
    this.errService.errorsArray = [];

    const emailRegex = /.+@.+\..+/i;
    if (this.datosForm.get("email").value === "" &&
      this.datosForm.get("confirmEmail").value === "") {
      document.getElementById('email').classList.remove('ng-untouched');
      document.getElementById('confirmEmail').classList.remove('ng-untouched');
    }
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get("cuentas").value, [Validators.required]],
      email: [this.datosForm.get("email").value, [Validators.required, Validators.pattern(emailRegex)]],
      confirmEmail: [this.datosForm.get("confirmEmail").value, [Validators.required, Validators.pattern(emailRegex)]],
      checkAcceptConditions: [this.datosForm.get("checkAcceptConditions").value, [Validators.requiredTrue]]
    }, { validator: this.checkEmails });

    this.cuentaForm.controls["email"].updateValueAndValidity();
    this.cuentaForm.controls["confirmEmail"].updateValueAndValidity();

    this.helpers.updateFormStatus(this.cuentaForm);
    this.checkEmails(this.cuentaForm);

    if (this.datosForm.get("checkAcceptConditions").value === true) {
      this.checkLegales = false;
    } else {
      this.checkLegales = true;
    }

    if (this.datosForm.get('email').valid || this.datosForm.get('confirmEmail').valid) {

      if (this.datosForm.get('email').value !== this.datosForm.get('confirmEmail').value) {
        this.loading = false;

        // this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Emails no coinciden');
        this.datosForm.get('email').setErrors({ notEquals: true });
        this.datosForm.get('confirmEmail').setErrors({ notEquals: true });
        this.helpers.updateFieldStatus(this.datosForm, 'email');
        this.helpers.updateFieldStatus(this.datosForm, 'confirmEmail');
      }
    }

    else {
      this.loading = false;
      this.emailErrorClear = false;
      // this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Email inválido');

      if (!this.datosForm.get('email').valid) {
        if (this.datosForm.get('email').value !== "") {
          this.datosForm.get('email').setErrors({ invalid: true });
        }

        this.helpers.updateFieldStatus(this.datosForm, 'email');
      }
      if (!this.datosForm.get('confirmEmail').valid) {
        if (this.datosForm.get('confirmEmail').value !== "") {
          this.datosForm.get('confirmEmail').setErrors({ invalid: true });
        }
        else {
        }
        this.helpers.updateFieldStatus(this.datosForm, 'confirmEmail');
      }
    }

    if (this.cuentaForm.valid) {
      this.ngValid = true;
    } else {
      this.ngValid = false;
    }
  }

  validarFormularioCuenta2() {
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get("cuentas").value, [Validators.required]],
      checkAcceptConditions: [this.datosForm.get("checkAcceptConditions").value, [Validators.requiredTrue]]
    });
    if (this.datosForm.get("checkAcceptConditions").value === true) {
      this.checkLegales = false;
    } else {
      this.checkLegales = true;
    }
  }

  /**Validación que comprueba que los email que ha introducido el usuario son iguales,
   * Devuelve null si son iguales y true si son diferentes
   */
  checkEmails(group: FormGroup) {

    let email = group.get('email').value;
    let confirmEmail = group.get('confirmEmail').value;

    if (email !== confirmEmail || (email === null && confirmEmail === null)) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }

    return email === confirmEmail ? null : { notSame: true }
  }

  onKeyUpValidateConfirmEmail(event: any) {

    event.preventDefault();
    event.stopPropagation();

    let confirmEmail = event.target.value;
    let email = this.datosForm.get("email").value;

    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (confirmEmail === null || confirmEmail === '') {
      document.getElementById('confirmEmail').classList.remove('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    } else if (email !== confirmEmail || !re.test(confirmEmail)) {
      document.getElementById('confirmEmail').classList.add('ng-invalid');
      document.getElementById('confirmEmail').classList.remove('ng-valid');
    } else {
      document.getElementById('confirmEmail').classList.add('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    }

  }

  continuarSinRedireccion() {

    this.checkSessionStorage();

    if (!this.showSecondStep) { //No volvemos de visor-cuenta-inversora (Botón atrás)

      if (this.producto === '009') {//Cuenta inversora
        this.initCuentaInversora();
      } else if (this.producto === '010') {//Fondo inteligente
        this.initFondoInteligente();
      }

    }


    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get("checkAcceptConditions").value === true) {
        this.checkLegales = false;

        if (this.emailErrorClear) {
          this.hideNotification();
        }
      }
      // else{
      //  this.checkLegales = true;
      // }
    });

    this.cargarCuentas();
    this.checkCondicionesLegales();
  }

  /**
   * Se valida antes de continuar que el cliente ha introducido un nickname valido
   * el cuál tiene que cumplir que sea mayor de 6 y menor de 12 carácteres y que previamente
   * no esté en uso por otro usuario
   */
  toContinue() {

    this.showError = true;
    this.nameError = "loadingCustom";
    let nick = this.datosForm.get("nickname").value;

    //Comprobar que la palabra no está en el fichero json
    this.http.get("assets/palabrasMalsonantes.json").subscribe(data => {
      for (let i = 0; i < data['palabras'].length && !this.nickNoValido; i++) {
        let palabra = data['palabras'][i];
        if (nick && nick.toUpperCase() === palabra.toUpperCase()) {
          this.nickNoValido = true;
          document.getElementById('nickname').classList.add('custom-bg-noerror');
          this.mostrarCampoError();
        } else {
          this.nickNoValido = false;
          this.quitarCampoError();
        }
      }

      if (nick !== null && nick !== "" && !this.nickNoValido) {
        if (nick.indexOf(" ") !== -1) {
          this.contieneEspacio = true;
        } else {
          this.contieneEspacio = false;
        }
        this.nicknameExist = false;
        this.nicknameError = false;
        this.nicknameErrorCaracteres = false;
        this.nickNoValido = false;
        if (nick.length >= 6 && nick.length <= 12 && !this.contieneEspacio) {
          this.getNickname();
        } else {
          this.nicknameError = true;
          this.invalidNickname = true;
          this.formValido = false;
          this.bannerRevisa = true;
          this.hiddeNotification = false;
          this.showError = false;
          this.nameError = "ocultarErrores";
        }
      } else {
        //this.nicknameError = true;
        this.invalidNickname = true;
        this.formValido = false;
        this.bannerRevisa = true;
        this.hiddeNotification = false;
        this.showError = false;
        this.nameError = "ocultarErrores";
      }
    });

  }

  private getNickname() {
    let nickname = this.datosForm.get("nickname").value;
    this.personaSrv.getNickName(nickname).subscribe(data => {
      this.loading = false;
      let res = data.EE_O_Consulta;
      if (res.codigoRetorno === '1' && res.Respuesta.detalleNickname === '1') {//Retorno OK.
        this.nicknameExist = false;
        this.cuentaForm = this.fb.group({
          nickname: [nickname, [
            Validators.required,
            Validators.pattern('^[a-z]|[A-Z]|[0-9]{6,12}')]]
        });
        if (this.cuentaForm.valid) {
          this.invalidNickname = false;
          this.formValido = true;
          this.bannerRevisa = false
          this.updateInversora("cuenta-cargo");
        }
      } else if (res.codigoRetorno === '1' && res.Respuesta.detalleNickname === '0') {
        //Nickname no disponible
        this.showError = false;
        this.nameError = "ocultarErrores";
        this.nicknameExist = true;
        document.getElementById('nickname').classList.add('custom-bg-noerror');
      } else if (res.codigoRetorno === '0') { //Retorno KO.
        this.showError = false;
        this.nameError = "ocultarErrores";
        this.nicknameExist = false;
        //Mostrar mensaje de error
        this.errorInversora('SATELITE-C', 'nickname', this.idCaso, 'Error servicio consulta Nickname');
      } else {
        this.showError = false;
        this.nameError = "ocultarErrores";
        //Otros Errores:Códigos HTTP 500, 401…
        this.errorInversora('SATELITE-C', 'nickname', this.idCaso, 'Error servicio consulta Nickname');
      }
    }, _error => {
      this.showError = false;
      this.nameError = "ocultarErrores";
      //Otros Errores:Códigos HTTP 500, 401…
      this.errorInversora('SATELITE-C', 'nickname', this.idCaso, 'Error servicio consulta Nickname');
    });
  }

  botonVolver() {

    if (this.showSecondStep) {
      this.updateInversora('nickname');
    } else {
      this.helpers.navigateTo('mi-perfil');
      this.updateInversora('modificacion-datos-tit1');
    }
  }


  updateStatus(valor, idCaso) {
    this.obj = {
      "estado": valor,
      "idCaso": idCaso
    };
  }

  /**Se hace el login a SF, se consultan los casos del cliente y los warnings*/
  initCI() {
    if (this.loginSrv.userData) {
      this.consultarCasosClienteInversora();
    } else {
      this.personaSrv.consultaLogin(this.idExterno).subscribe(data => {
        if (data) {
          this.consultarCasosClienteInversora();
        } else {
          this.showError = false;
          this.nameError = "ocultarErrores";
          //Otros Errores:Códigos HTTP 500, 401…
          this.goToError('SATELITE-C');
        }
      });
    }
  }

  consultarCasosClienteInversora(){
    this.personaSrv.consultarCasosClienteInversora().subscribe(data => {
      //ver casos.
      let listaCasos = data.listaCasos;
      if(listaCasos){
        for (const caso of listaCasos) {
          if(this.producto === '009' && caso.tipoCaso === "009" ||
             this.producto === '010' && caso.tipoCaso ==="010") {
            this.recuperarCaso = true;
            this.casoPendiente = caso;
          }
        }

      }
      console.log(data);
      this.getWarnings();
    }, _error => {
      this.goToError('SATELITE-C');
    });
  }

  /**Se hace el login a SF, se consultan los casos del cliente y los warnings*/
  initCISecondSigner() {
    if (this.loginSrv.userData) {
      this.getWarningsSecondSigner();
    }
    else {
      this.personaSrv.consultaLogin().subscribe(data => {
        if (data) {
          this.getWarningsSecondSigner();
        } else {
          this.goToError('SATELITE-C');
        }
      });
    }
  }

  abrirCasoInversora(pendiente?) {
    /********************Meter datos correctos en el objeto **********************/
    let object: Object = {
      'canalOrigen': this.canalOrigen,
      'canal': this.canal,
      'estado': 'registro',
      'codProductoContratado': this.producto
    }

    this.personaSrv.crearCasoCuenta(object).subscribe(data => {

      if (data.codigo === 'OK') {
        this.idCaso = data.idCaso;
        sessionStorage.setItem("casoSeleccionado", this.idCaso);

        if (this.tipoSolicitud === '01' || this.tipoSolicitud === '02' || this.tipoSolicitud === '03') {
          let datosErroneos = this.loginSrv.camposError;
          if (datosErroneos.length > 0) {
            this.updateInversora("modificacion-datos-tit1");
          }
          else {
            // REALIZAR PUT PARA IR A SUBIDA DE DOCUMENTACION
            this.updateInversora("warning-documentacion-tit1");
          }
        } else if (this.tipoSolicitud === '04') {
          //Cuenta Inversora/Error - KO Datos DRF
          this.triggerInvesmentNavData('Error - KO Datos DRF');
          this.showError = true;
          this.nameError = "faltaInformacion";
          this.updateInversora("modificacion-datos-tit1");
        } else {
          this.updateInversora("modificacion-datos-tit1");
          this.helpers.navigateTo("mi-perfil");
        }
      } else if (data.codigo === 'KO') {
        this.showError = true;
        this.nameError = "astronauta";
        this.triggerInvesmentNavData('Error - 404');
      }
    }, _error => {
      this.goToError('SATELITE-C');
    });

  }

  getWarnings() {
    this.personaSrv.getWarnings(this.clientID).subscribe(response => {
      this.tipoSolicitud = response.message;
      sessionStorage.setItem("tipoSolicitud", this.tipoSolicitud);
      switch (this.tipoSolicitud) {
        case '00': // SIN WARNINGS abrir caso ampliacion
          let pendiente = false;
          //comprobar si tiene caso en vuelo.
          if(this.recuperarCaso){
              this.continuarCaso(this.casoPendiente);
          }else{
            //Si no tiene caso en vuelo abrimos un nuevo caso de inversora.
            this.abrirCasoInversora(pendiente);
          }
          break;
        case '01': case '02': case '03': // comprobar si hay solicitudes revisandose
          this.getInfoSolicitudValidandose();
          break;
        case '04': //DRF
          this.DRF = true;
           //comprobar si tiene caso en vuelo.
           if(this.recuperarCaso){
              this.continuarCaso(this.casoPendiente);
          }else{
            //Si no tiene caso en vuelo abrimos un nuevo caso de inversora.
            this.abrirCasoInversora(pendiente);
          }
          break;
        default:
          this.goToError('SATELITE-C');
          break;
      }
    });
  }

  getWarningsSecondSigner() {
    this.personaSrv.getWarnings(this.clientID).subscribe(response => {
      this.tipoSolicitud = response.message;
      sessionStorage.setItem("tipoSolicitud", this.tipoSolicitud);
      switch (this.tipoSolicitud) {
        case '00': // SIN WARNINGS
          this.updateInversora("modificacion-datos-tit2");
          this.helpers.navigateTo("mi-perfil");
          break;
        case '01': case '02': case '03':
          this.updateInversora("warning-documentacion-tit2");
          break;
        case '04': //DRF
          this.showError = true;
          this.nameError = "faltaInformacion";
          this.updateInversora("modificacion-datos-tit2");
          break;
        default:
          this.goToError('SATELITE-C');
          break;
      }
    });
  }

  getInfoSolicitudValidandose() {
    this.personaSrv.getInfoSolicitud(this.clientID).subscribe(response => {
      this.tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.solicitudesEnCurso = [];
      if (!this.tieneSolicitudes) {
        let pendiente = false;
         //comprobar si tiene caso en vuelo.
         if(this.recuperarCaso){
             this.continuarCaso(this.casoPendiente);
        }else{
          //Si no tiene caso en vuelo abrimos un nuevo caso de inversora.
          this.abrirCasoInversora(pendiente);
        }
      } else {
        this.solicitudesEnCurso = response.solicitudes;
        for (const solicitud of this.solicitudesEnCurso) {
          if (solicitud.tipoSolicitud === "01" || solicitud.tipoSolicitud === "02" || this.tipoSolicitud === '03') {
            if (solicitud.estado != "Nueva" && solicitud.estado != "Enviada") {
              let pendiente = true;
               //comprobar si tiene caso en vuelo.
               if(this.recuperarCaso){
                   this.continuarCaso(this.casoPendiente);
              }else{
                //Si no tiene caso en vuelo abrimos un nuevo caso de inversora.
                this.abrirCasoInversora(pendiente);
              }
            }
          }
        }
        this.goToError('ASTRONAUTA-C');
      }
    }, _error => {
      console.log("error en la peticion a ampliacionLimite");
      //Redirige al 404 de Modificación de datos
      this.goToError('SATELITE-C');
    });
  }

  /**
   * Actualizar Caso
   * @param estado
   */
  updateInversora(estado) {
    let idCaso = sessionStorage.getItem("casoSeleccionado");
    // let clasificacionMiFid = sessionStorage.getItem('clasificacionMiFid');
    // let fechaMiFid = sessionStorage.getItem('fechaMiFid');
    // let categorizacionAnterior = sessionStorage.getItem('categorizacionAnterior') === 'true';

    this.obj = {
      "estado": estado,
      "idCaso": idCaso
    };

    if (this.isSecondSigner) {
      switch (estado) {
        case 'categorizacion-tit2':
          for (let i = 0; i < this.cuentas.length; i++) {
            if (this.datosForm.get("cuentas").value === this.cuentas[i].value) {
              this.cuentaCargo = this.cuentas[i].value;
              this.acuerdoCuentaCargo = this.cuentas[i].acuerdo;
            }
          }
          break;
        case 'modificacion-datos-tit2':
          this.obj = {
            "estado": estado,
            "idCaso": idCaso,
            "DRF": this.DRF
          };
          break;
        default:
          break;
      }
    }
    else {
      if (estado === 'nickname') {
        this.obj = {
          "estado": estado,
          "idCaso": idCaso,
          "nickname": ''
        };
      }
      else if (estado === 'cuenta-cargo') {
        this.obj = {
          "estado": estado,
          "idCaso": idCaso,
          "nickname": this.datosForm.get("nickname").value
        };
      } else if (estado === 'categorizacion-tit1') {
        for (let i = 0; i < this.cuentas.length; i++) {
          if (this.datosForm.get("cuentas").value === this.cuentas[i].value ||
            this.datosForm.get("cuentas").value === this.cuentas[i].name) {
            this.cuentaCargo = this.cuentas[i].value;
            this.acuerdoCuentaCargo = this.cuentas[i].acuerdo;
          }
        }
        if (this.activarSegundoTitular) {
          if (this.datosForm.get('seleccionarCuenta').value === '1') {
            //Si se activa el segundo titular se envían los siguientes objetos
            //dependiendo de si selecciona el segundo titular de la cuenta existente
            //o de si el usuario indica un mail nuevo
            this.obj = {
              "estado": estado,
              "idCaso": idCaso,
              "cuentaCargo": this.cuentaCargo,
              "acuerdoCuentaCargo": this.acuerdoCuentaCargo,
              "cuentaCargoElectronica": this.cuentaCargoElectronica,
              "acuerdoCuentaCargoElectronica": this.acuerdoCuentaCargoElectronica,
              'nombreSegundoTitular': this.datosSegTitular['nombreSegTitular'],
              'apellido1SegundoTitular': this.datosSegTitular['apellido1SegTitular'],
              'apellido2SegundoTitular': this.datosSegTitular['apellido2SegTitular'],
              'dniSegundoTitular': this.datosSegTitular['idExternoSegTitular'],
              'idInternoSegundoTitular': this.datosSegTitular['idInternoSegTitular']
            };
          } else if (this.datosForm.get('seleccionarCuenta').value === '2' ||
            this.datosForm.get('seleccionarCuenta').value === null) {
            this.emailSegundoTitular = this.datosForm.get("email").value;
            sessionStorage.setItem("emailSegundoTitular", this.emailSegundoTitular);
            this.obj = {
              "estado": estado,
              "idCaso": idCaso,
              "cuentaCargo": this.cuentaCargo,
              "acuerdoCuentaCargo": this.acuerdoCuentaCargo,
              "cuentaCargoElectronica": this.cuentaCargoElectronica,
              "acuerdoCuentaCargoElectronica": this.acuerdoCuentaCargoElectronica,
              "emailSegundoTitular": this.datosForm.get("email").value
            };
          }
        } else {
          this.obj = {
            "estado": estado,
            "idCaso": idCaso,
            "cuentaCargo": this.cuentaCargo,
            "acuerdoCuentaCargo": this.acuerdoCuentaCargo,
            "cuentaCargoElectronica": this.cuentaCargoElectronica,
            "acuerdoCuentaCargoElectronica": this.acuerdoCuentaCargoElectronica,
          };
        }
      } else if (estado === 'modificacion-datos-tit1') {
        this.obj = {
          "estado": estado,
          "idCaso": idCaso,
          "DRF": this.DRF
        };
      }
    }

    this.putInversora(estado);
  }

  /**
   * Llamada al servicio cuentaInversoraPut de SF
   * @param estado Estado de la petición/punto abandono
   */
  putInversora(estado) {
    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      switch (data.codigo) {
        case 'OK':
          if (this.isSecondSigner) {
            this.checkStatusAndRedirectSecondHolder(estado)
          }
          else {
            this.checkStatusAndRedirect(estado);
          }
          break;
        case '01':
        case '02':
        case '03':
        case '04':
        default:
          //this.goToError('SATELITE-C');
          break;
      }
    }, _error => {
      console.log("Error en el put");
      //Redirige al 404 de Modificación de datos
      this.goToError('SATELITE-C');
    });
  }


  /**
   * Comprueba el estado actual de la petición y redirige o muestra el mensaje correspondiente
   * @param estado
   */
  checkStatusAndRedirect(estado) {
    switch (estado) {
      case 'nickname':
        this.showSecondStep = false;
        this.helpers.navigateTo('configuracion-cuenta-inversora');
        break;
      case "warning-documentacion-tit1": // con warnings --> a subir documentacion
        this.showError = false;
        this.analytics.triggerInvestmentDataNavData({ 'pageName': 'Perfil/Subida de DNI' });
        this.helpers.navigateTo("subir-documentacion");
        break;
      case "warning-documentacion-tit1":
      case "modificacion-datos-tit1":
        this.showError = false;
        this.helpers.navigateTo("mi-perfil");
        break;
      case "cuenta-cargo":
        this.triggerInvesmentNavData('Paso 02.2 - Configuración cuenta');
        this.showError = false;
        this.nameError = "ocultarErrores";
        this.showSecondStep = true;
        this.scrollToTop();
        break;
      case "categorizacion-tit1":
        this.showError = false;
        this.nameError = "ocultarErrores";
        this.helpers.navigateTo('visor-cuenta-inversora');
        break;
      default:
        this.showError = false;
        break;
    }
  }

  /**
   * Comprueba el estado actual de la petición y redirige o muestra el mensaje correspondiente
   * Para segundo titular
   * @param estado
   */
  checkStatusAndRedirectSecondHolder(estado) {
    switch (estado) {
      case "warning-documentacion-tit2": // con warnings --> a subir documentacion
        if (sessionStorage.getItem('operacion-inversora')) {
          let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
          let product = sessionStorage.getItem("productoContratando") === '009'?'Cuenta Inversora':'Fondo Inteligente';
          let pageName = isSecondSigner ? product + '/'+ 'Perfil/Subida de DNI':'Perfil/Subida de DNI';
          this.analytics.triggerInvestmentDataNavData({
            'pageName': pageName,
            'product': product
          });
        }
        this.showError = false;
        this.helpers.navigateTo("subir-documentacion");
        break;
      case "warning-documentacion-tit2":
      case "modificacion-datos-tit2":
        this.showError = false;
        this.helpers.navigateTo("mi-perfil");
        break;
      case "categorizacion-tit2":
        this.showError = false;
        this.nameError = "ocultarErrores";
        this.helpers.navigateTo('visor-cuenta-inversora');
        break;
      default:
        this.showError = false;
        break;
    }
  }

  hideNotification() {
    this.hiddeNotification = true;
  }

  setSteps() {
    if (this.currentPath){
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    }
    else if (!this.step){
      this.step = 1;
    }
  }

  gestionAbandono(estado, idCliente, idCaso) {
    //obtenemos idExterno
    this.showError = true;
    this.nameError = "loadingCustom";
    this.action = 'abandono';
    sessionStorage.setItem("abandono", "true");
    //obtenemos idExterno
    this.personaSrv.consultarDatosCasoInversora(idCliente, idCaso).subscribe(data => {
      if (data.codigo === "OK") {
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        if (this.isSecondSigner) {
          this.idExterno = data.infoCaso.datosSegundoTitular.datosPersonales.idExterno;
          sessionStorage.setItem("ibanCuentaSeleccionada", data.infoCaso.IBAN);
        } else {
          this.idExterno = data.infoCaso.datosPrimerTitular.datosPersonales.idExterno;
          if (data.infoCaso.datosSegundoTitular) {
            if (data.infoCaso.puntoAbandono === "categorizacion-tit1" || data.infoCaso.puntoAbandono === "gestion-info-precontractual-tit1") {
              //Es un abandono de una invitación de primerTitular a segundoTitular.
              this.completarDatosSegundoTitular(data.infoCaso.datosSegundoTitular.datosPersonales);
            }
            else if (data.infoCaso.puntoAbandono === "firma-tit1") {
              sessionStorage.setItem("ibanCuentaSeleccionada", data.infoCaso.IBAN);
            }
          }
        }
        //Seteamos el punto de abandono del infoCaso.
        estado = data.infoCaso.puntoAbandono;


        console.log("redireccion a : ", estado)
        // sessionStorage.setItem("userData", JSON.stringify(data));

        if (this.loginSrv.userData) {
            let userData = this.loginSrv.userData;
            let codigo = sessionStorage.getItem("productoContratando");

            this.clientID = userData.persona.id_cliente_EVO;
            this.codProductoContratado = sessionStorage.getItem("productoContratando");

            this.personaSrv.getPersonaById(this.clientID, codigo).subscribe(dataInfo => {
              this.loginSrv.userInfo = dataInfo;

              //Comprobamos el punto de abandono  y redirigimos.
              sessionStorage.setItem("userData", JSON.stringify(userData));
              if (estado === "cuenta-cargo" || estado === "nickname") {
                this.cargarCuentas();
              }
              this.showError = false;
              this.nameError = "ocultarErrores";
              this.idCliente = userData.persona.id_cliente_EVO;
              this.redireccion(estado, this.isSecondSigner);
            }, _error => {
              this.goToError('SATELITE-C');
            });
        }
        else {
          this.authUserService.getUserDataToken(this.idExterno).subscribe(data => {
            let codigo = sessionStorage.getItem("productoContratando");

            this.clientID = data.persona.id_cliente_EVO;
            this.codProductoContratado = sessionStorage.getItem("productoContratando");

            this.personaSrv.getPersonaById(data.persona.id_cliente_EVO, codigo).subscribe(dataInfo => {
              this.loginSrv.userInfo = dataInfo;

              //Comprobamos el punto de abandono  y redirigimos.
              sessionStorage.setItem("userData", JSON.stringify(data));
              if (estado === "cuenta-cargo" || estado === "nickname") {
                this.cargarCuentas();
              }
              this.showError = false;
              this.nameError = "ocultarErrores";
              this.idCliente = data.persona.id_cliente_EVO;
              this.redireccion(estado, this.isSecondSigner);
            }, _error => {
              this.goToError('SATELITE-C');
            });

          }, _error => {
            this.goToError('SATELITE-C');;
          });
        }

      } else {
        this.goToError('ASTRONAUTA');
      }
    }, _error => {
      this.goToError('ASTRONAUTA');
    });
  }

  gestionAbandonoBMI(idCaso?) {
    this.showError = true;
    this.nameError = "loadingCustom";
    this.action = 'abandono';
    sessionStorage.setItem("abandono", "true");
    if (!idCaso) {//Si no tiene caso se crea uno nuevo
      if (this.loginSrv.userData) {
        this.idCliente = this.loginSrv.userData.persona.id_cliente_EVO;
        this.isRedireccion = false;
        let codigo = this.loginSrv.userData.listaContratados[0];
        this.personaSrv.getPersonaById(this.loginSrv.userData.persona.id_cliente_EVO, codigo).subscribe(data => {
          this.loginSrv.userInfo = data;
          this.loginSrv.userInfo.idExterno = this.idExterno;
          this.continuarSinRedireccion();
        }, _error => {
          this.goToError('SATELITE-C');
        });
      } else {
        this.authUserService.getUserDataToken(this.idExterno).subscribe(data => {
          sessionStorage.setItem("userData", JSON.stringify(data));
          this.idCliente = data.persona.id_cliente_EVO;
          this.isRedireccion = false;
          let codigo = data.listaContratados[0];
          this.personaSrv.getPersonaById(data.persona.id_cliente_EVO, codigo).subscribe(data => {
            this.loginSrv.userInfo = data;
            this.loginSrv.userInfo.idExterno = this.idExterno;
            this.continuarSinRedireccion();
          }, _error => {
            this.goToError('SATELITE-C');
          });
        }, _error => {
          this.goToError('SATELITE-C');
        });
      }

    } else {//Si el cliente viene de un abandono y tiene el caso creado se le redirije a su último punto
      this.personaSrv.consultarDatosCasoInversora(this.idExterno, idCaso).subscribe(data => {
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        let puntoAbandono = data.infoCaso.puntoAbandono;
        this.idExterno = data.infoCaso.datosPrimerTitular.datosPersonales.idExterno;
        let contratoDeDosTitulares = !!data.infoCaso.datosSegundoTitular;

        if (this.isSecondSigner) {
          sessionStorage.setItem("ibanCuentaSeleccionada", data.infoCaso.IBAN);
        }

        if (this.loginSrv.userData) {
          this.showError = false;
          this.nameError = "ocultarErrores";
          this.idCliente = this.loginSrv.userData.persona.id_cliente_EVO;
          this.cargarCuentas();
          this.getPersonaById();
          this.redireccion(puntoAbandono, this.isSecondSigner, contratoDeDosTitulares);
        }
        else {
          this.authUserService.getUserDataToken(this.idExterno).subscribe(data => {
            //Comprobamos el punto de abandono  y redirigimos.
            sessionStorage.setItem("userData", JSON.stringify(data));
            this.showError = false;
            this.nameError = "ocultarErrores";
            this.idCliente = data.persona.id_cliente_EVO;
            this.cargarCuentas();
            this.getPersonaById();
            this.redireccion(puntoAbandono, this.isSecondSigner, contratoDeDosTitulares);
          }, _error => {
            this.goToError('SATELITE-C');
          });
        }



      }, _error => {
        this.goToError('ASTRONAUTA');
      });
    }

  }

  continuarCaso(caso) {
    this.showError = true;
    this.nameError = "loadingCustom";
    this.action = 'abandono';
    this.setIbanAcountSelected();
    
    this.personaSrv.consultarDatosCasoInversora(this.clientID, caso.idCaso).subscribe(data => {
    if (data.codigo === "OK") {
        this.idCaso = caso.idCaso;
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        sessionStorage.setItem("casoSeleccionado", caso.idCaso);
        console.log("redireccion a : ", caso.puntoAbandono)
        this.codProductoContratado = sessionStorage.getItem("productoContratando");
        //Comprobamos el punto de abandono  y redirigimos.
        if (caso.puntoAbandono === "cuenta-cargo" || caso.puntoAbandono === "nickname") {
            this.cargarCuentas();
        }
    this.showError = false;
    this.nameError = "ocultarErrores";
    this.redireccion(caso.puntoAbandono, false);
  } else {
    this.goToError('ASTRONAUTA');
  }
  }, _error => {
    this.goToError('ASTRONAUTA');
  });
  }


  getPersonaById() {
    this.personaSrv.getPersonaById(this.idCliente, this.producto).subscribe(data => {
      this.loginSrv.userInfo = data;
    }, _error => {
      this.goToError('SATELITE-C');
    });
  }

  redireccion(estado, isSecondSigner, contratoDeDosTitulares?) {
    let nextStep;
    if (!isSecondSigner) {
      this.finalText1Titular = contratoDeDosTitulares;
      sessionStorage.setItem("mostrarTexto1Titular", this.finalText1Titular);
      switch (estado) {
        case "warning-documentacion-tit2":
        case "modificacion-datos-tit2":
        case "login-tit2":
        case "categorizacion-tit2":
        case "gestion-info-precontractual-tit2":
        case "previa-firma-tit2":
        case "firma-tit2":
          nextStep = "final-cuenta-inversora";
          sessionStorage.setItem("esperaFirmaST", "true");
          break;
        case "warning-documentacion-tit1":
          nextStep = "/subir-documentacion";
          break;
        case "registro":
        case "modificacion-datos-tit1":
        case "mi-perfil":
          this.triggerInvesmentNavData('Paso 01.1 - Revisión de datos');
          nextStep = "/mi-perfil";
          break;
        case "nickname":
          nextStep = "configuracion-cuenta-inversora";
          break;
        case "cuenta-cargo":
          this.triggerInvesmentNavData('Paso 02.2 - Configuración cuenta');
          this.showSecondStep = true;
          nextStep = "configuracion-cuenta-inversora";
          break;
        case "categorizacion-tit1":
          nextStep = "visor-cuenta-inversora";
          break;
        case "gestion-info-precontractual-tit1":
          sessionStorage.setItem("docPrecontractual", "true");
          nextStep = "visor-cuenta-inversora";
          break;
        case "previa-firma-tit1":
        case "firma-tit1":
          nextStep = "logalty-ampliacion";
          break;
        case "casi-lo-tenemos":
          nextStep = "final-cuenta-inversora";
        default:
          this.goToError('ASTRONAUTA');
      }
      //SEGUNDO TITULAR.
    } else {
      switch (estado) {
        case "warning-documentacion-tit1":
        case "modificacion-datos-tit1":
        case "nickname":
        case "cuenta-cargo":
        case "categorizacion-tit1":
        case "gestion-info-precontractual-tit1":
          this.goToError('SATELITE-C');
          break;
        case "mi-perfil":
        case "previa-firma-tit1":
        case "firma-tit1":
          nextStep = "final-cuenta-inversora";
          sessionStorage.setItem("preparadoFirmaST", "true");
          break;
        case "warning-documentacion-tit2":
          nextStep = "/subir-documentacion";
          break;
        case "modificacion-datos-tit2":
        case "login-tit2":
          nextStep = "/mi-perfil";
          break;
        case "categorizacion-tit2":
          nextStep = "visor-cuenta-inversora";
          break;
        case "gestion-info-precontractual-tit2":
          sessionStorage.setItem("docPrecontractual", "true");
          nextStep = "visor-cuenta-inversora";
          break;
        case "previa-firma-tit2":
        case "firma-tit2":
          nextStep = "logalty-ampliacion";
          break;
        case "casi-lo-tenemos":
          nextStep = "final-cuenta-inversora";
        default:
          this.errorInversora('ASTRONAUTA', estado, this.idCaso, 'Error servicio consulta Nickname');

      }
    }
    if (nextStep) {
      this.helpers.navigateTo(nextStep);
    }

  }


  gestionSegundoTitular(idCliente, idCaso) {

    this.showError = true;
    this.nameError = "loadingCustom";

    //obtenemos idExterno
    this.personaSrv.consultarDatosCasoInversora(idCliente, idCaso).subscribe(data => {
      if (data.codigo === "OK") {
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        this.idExterno = JSON.parse(sessionStorage.getItem('userInfo')).datosPersonales.idExterno;

        if (this.loginSrv.userData) {
          this.showError = false;
          this.nameError = "ocultarErrores";
          this.idCliente = idCliente;
          this.redireccion("login-tit2", this.isSecondSigner);
        }
        else {
          this.authUserService.getUserDataToken(this.idExterno).subscribe(data => {
            //Comprobamos el punto de abandono  y redirigimos.
            sessionStorage.setItem("userData", JSON.stringify(data));
            this.showError = false;
            this.nameError = "ocultarErrores";
            this.idCliente = idCliente;
            this.redireccion("login-tit2", this.isSecondSigner);
          }, _error => {
            this.goToError('SATELITE-C');
          });
        }
      } else {
        this.goToError('ASTRONAUTA');
      }
    }, _error => {
      this.goToError('ASTRONAUTA');
    });
  }

  modalOpenCondiciones() {
    if (!this.datosForm.get('checkAcceptConditions').value) {
      this.modService.open(this.modalLegal);
    }
  }

  checkCondiciones() {
    this.modService.close(this.modalLegal);
  }

  /**
   * Dependiendo del valor de seleccionarCuenta se muestran o no los campos de email y repetir email
   * si seleccionarCuetna es 1 no se muestra, si es 2 si se muestran para que el cliente pueda
   * introducir el nuevo email
   */
  setEmail(value) {
    if (value === 1) {
      this.showInputEmail = false;
    } else {
      this.showInputEmail = true;
    }
  }

  cargarCuentas() {

    this.cuentasCliente = JSON.parse(sessionStorage.getItem("userData")).listaContratados;

    let totalCuentas = this.cuentasCliente.length;
    this.cuentas = [];

    for (let i = 0; i < totalCuentas; i++) {

      this.cuentasProductos = this.cuentasCliente[i].productos;
      let totalCuentasProductos = this.cuentasProductos.length;
      let idProducto = this.cuentasCliente[i].idProducto;
      let nombreProducto = this.cuentasCliente[i].nombreProducto.concat(" - " + this.cuentasCliente[i].ibanCC);
      //Mostrar en la lista solo:cuentas inteligentes (006), cuenta base (007), cuenta joven (008).
      switch (idProducto) {
        case '007': // Cuenta Base (CUENTA DUO)
          nombreProducto = "CUENTA BASE - " + this.cuentasCliente[i].ibanCC;
        case '006': // Cuenta inteligente
        case '008': // Cuenta Joven
          this.cuentasProductos = this.cuentasCliente[i].productos;
          if (this.cuentasProductos) {
            for (let j = 0; j < totalCuentasProductos; j++) {
              if (this.cuentasProductos[j].iban === this.cuentasCliente[i].ibanCC) {
                this.numeroAcuerdo = this.cuentasProductos[j].acuerdo;
              }
              if (this.cuentasProductos[j].codigoGrupo === '55' && this.cuentasProductos[j].codigoLinea === '05') {
                this.acuerdoCuentaCargoElectronica = this.cuentasProductos[j].acuerdo;
                this.cuentaCargoElectronica = this.cuentasProductos[j].iban;
              }
            }
          }
          this.cuentas.push({
            'value': this.cuentasCliente[i].ibanCC,
            'name': nombreProducto,
            'acuerdo': this.numeroAcuerdo
          });


          break;
        default:
          break;
      }
    }

    //Ordenar cuentas
    this.cuentas.sort((a, b) => {
      if (a.name.indexOf('INTELIGENTE') !== -1 && b.name.indexOf('INTELIGENTE') === -1) {
        return -1
      } else {
        return 0;
      }
    });

    if (this.cuentas.length > 0) {
      this.defaultAcount = this.cuentas.length !== 1 ? this.cuentas[0].value : this.cuentas[0].name;
    }
    console.log(this.cuentas);

    this.datosForm.controls['cuentas'].setValue(this.defaultAcount, { onlySelf: true });

    //Para actualizar el segundo titular de cada cuenta
    this.datosForm.get('cuentas').valueChanges.subscribe(data => {
      this.activarST(true);
    })

    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get("checkAcceptConditions").value === true) {
        this.checkLegales = false;
      }

      if (this.emailErrorClear) {
        this.hideNotification();
      }
      /*else{
       this.checkLegales = true;
      }*/
    });
    this.checkCondicionesLegales();

  }

  updateTooltip() {
    this.datosForm.get('nickname').valueChanges.subscribe(data => {
      document.getElementById('nickname').classList.remove('custom-bg-noerror');

      let nickname: string = data;
      // Se ocultan los tooltip de error.
      this.nickNoValido = false;
      this.nicknameErrorCaracteres = false;
      this.nicknameError = false;
      this.contieneEspacio = false;
      this.nicknameExist = false;

      if (nickname === "") {
        this.quitarCampoError();
      }
      for (let index = 0; index < data.length; index++) {
        let car = nickname.charCodeAt(index);
        //Se evita quitar el error del espacio al entrar a comprobar el siguiente carácter
        if (this.contieneEspacio !== true) {
          if (car === 32 && this.nicknameError === false) {
            this.contieneEspacio = true;
            this.nicknameError = true;
            this.mostrarCampoError();
          } else {
            this.contieneEspacio = false;
            this.quitarCampoError();
          }
        }
        //Si no contiene espacios se comprueba si no tiene carácteres especiales
        if (this.nicknameErrorCaracteres === false && this.contieneEspacio === false) {
          if ((car >= 48 && car <= 57) || (car >= 65 && car <= 90)
            || (car >= 97 && car <= 122) || car === 32) {
            //Es un carácter válido
            this.nicknameErrorCaracteres = false;
            this.quitarCampoError();

          } else {
            //Es un carácter especial no válido
            this.nicknameErrorCaracteres = true;
            this.mostrarCampoError();
          }
        }
      }
    });

  }

  mostrarCampoError() {
    document.getElementById('nickname').classList.remove('ng-pristine');
    document.getElementById('nickname').classList.remove('ng-untouched');
    document.getElementById('nickname').classList.add('ng-dirty');
    document.getElementById('nickname').classList.add('invalid-nickname');
  }

  quitarCampoError() {
    document.getElementById('nickname').classList.add('ng-pristine');
    document.getElementById('nickname').classList.add('ng-untouched');
    document.getElementById('nickname').classList.remove('ng-dirty');
    document.getElementById('nickname').classList.remove('invalid-nickname');
    this.bannerRevisa = false;
  }

  isFondoInteligente(codProducto) {

    if (sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname' ||
      sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente' ||
      codProducto === '010') {
      this.esFondoInteligente = true;
    }

  }

  completarDatosSegundoTitular(data) {
    sessionStorage.setItem('activarSegundoTitular', "true");
    //Si tenemos nombreCliente, es un segundo titular añadido desde la cuenta
    if (data.nombreCliente) {
      sessionStorage.setItem("nombreSegTitular", data.nombreCliente);

    } else {
      //Y si no, es una invitación por mail.
      sessionStorage.setItem("emailSegundoTitular", data.email);
    }
  }

  triggerInvesmentNavData(pageName) {
    let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    if (isSecondSigner){
      pageName = pageName + '/Segundo titular';
    }
    let product = this.producto === '010' ? 'Fondo Inteligente' : 'Cuenta Inversora';
    this.analytics.triggerInvestmentDataNavData({
      'pageName': product + '/' + pageName,
      'product': product
    });
  }

  errorInversora(errorMessage, estado, idCaso, errorDescription) {
    this.obj = {
      'estado': estado,
      'idCaso': idCaso,
      'description': errorDescription
    };
    this.personaSrv.modificarCuenta(this.obj).subscribe();
    this.goToError(errorMessage);
  }


  goToError(errorMessage) {
    this.showError = false;
    this.nameError = "ocultarErrores";
    this.triggerInvesmentNavData('Error - 404');
    this.errService.navToErrorModData(errorMessage, 'ENTENDIDO', 'bancaelectronica');
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  onKeyUpValidateEmail(event: any) {

    event.preventDefault();
    event.stopPropagation();

    let email = event.target.value;
    let confirmEmail = this.datosForm.get("confirmEmail").value;

    let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email === null || email === '') {
      document.getElementById('email').classList.remove('ng-invalid');
      document.getElementById('email').classList.remove('ng-valid');
    }
    else if (!re.test(email)) {
      document.getElementById('email').classList.add('ng-invalid');
      document.getElementById('email').classList.remove('ng-valid');
    }
    else {
      document.getElementById('email').classList.add('ng-valid');
      document.getElementById('email').classList.remove('ng-invalid');
    }

    if (confirmEmail === null || confirmEmail === '') {
      document.getElementById('confirmEmail').classList.remove('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    }
    else if (email !== confirmEmail || !re.test(confirmEmail)) {
      document.getElementById('confirmEmail').classList.add('ng-invalid');
      document.getElementById('confirmEmail').classList.remove('ng-valid');
    }
    else {
      document.getElementById('confirmEmail').classList.add('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    }

  }

  checkCondicionesLegales() {
    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get("checkAcceptConditions").value === true) {
        this.checkLegales = false;
      } else {
        this.checkLegales = true;
      }
    });
  }

  hideBannerNotifyOk() {
    this.hideBannerNotificacionOk = true;
    //metemos en sesion si ha cerrado el baner.
    sessionStorage.setItem("notificacionOk", "true");
  }

}

