import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { getIban } from '../../collections/iban';
import { Persona } from '../../collections/persona';
import { NestedObject } from '../nestedObject.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiIbanService {
  sessionIban: getIban;
  private options = {};
  constructor(private http: HttpClient,
    public nestedObject: NestedObject) {
    this.options = { withCredentials: true, observe: 'response' };
    this.sessionIban = this.getSessionImagesIban();
  }

  public setSessionImagesIban(iban: getIban) {
    let previousIban = JSON.parse(sessionStorage.getItem('currentImagesIban')) || {};
    let currentIban = this.nestedObject.nestedObjectAssign({}, previousIban, iban);
    sessionStorage.setItem('currentImagesIban', JSON.stringify(currentIban) || null);
  }

  public getSessionImagesIban(): getIban {
    let iban = JSON.parse(sessionStorage.getItem('currentImagesIban'));
    if (iban && iban.confirma) return iban;
    return new getIban();
  }

  public setSessionPreviuosIban(iban: getIban) {
    let previousIban = JSON.parse(sessionStorage.getItem('cProspect')) || {};
    let currentIban = this.nestedObject.nestedObjectAssign({}, previousIban, iban);
    sessionStorage.setItem('cProspect', JSON.stringify(currentIban) || null);
  }

  public getSessionPreviousIban(): getIban {
    let iban = JSON.parse(sessionStorage.getItem('cProspect'));
    if (iban && iban.confirma) return iban;
    return new getIban();
  }

  public sendImages(persona: Persona): Observable<any> {
    let prospect = JSON.parse(sessionStorage.getItem('currentProspect'));
    if (prospect && prospect.datosPersonales.id_cliente_EVO)
      return this.http
        .put(`${API_URL}/fullonline/integracion/confirma/recieveImages/${prospect.datosPersonales.id_cliente_EVO}`, persona, this.options)
        .pipe(
          map(response => {
            return response['body'];
          })
        )
  };
}
