import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { AuthTokenService } from "app/services/token/auth.token.service";
import { Otp } from "app/collections/otp";
import { OtpService } from "app/services/otp.service";
import { ApiOtpService } from "app/services/api/api.otp.service";
import { ModalService } from "app/services/modal.service";
import { OtpDetailsComponent } from "app/components/otp-details/otp-details.component";
import { HelperService } from "app/services/helpers.service";
import { DeviceService } from "app/services/device.service";
import { environment } from "environments/environment";
import { ErrorService } from "app/services/error.service";
import { Persona } from "app/collections/persona";
import { ACCOUNTS } from "app/resources/account.model";
import {
  ValidateSecurizedOtp,
  ValidateSecurizedOtpTypeA,
} from "app/collections/validate.securized.otp";
import { Modal } from "app/collections/modal";
import { AnalyticsService } from "app/services/analytics.service";

@Component({
  selector: "app-otp-confirm-first-step",
  templateUrl: "./otp-confirm-first-step.component.html",
  styleUrls: ["./otp-confirm-first-step.component.scss"],
  providers: [ModalService, DeviceService],
})
export class OtpConfirmFirstStepComponent implements OnInit {
  public paramsSMS: Otp;
  private persona: Persona;
  private isTipoA: boolean;
  clientExists = false;
  msgSrvError: string = "";
  modalSrvError: string = "modal-srv-error";
  isPressResent: boolean = false;

  @ViewChild(OtpDetailsComponent) otpDetails: OtpDetailsComponent;

  constructor(
    private authService: AuthService,
    public modService: ModalService,
    private helpers: HelperService,
    private deviceService: DeviceService,
    private otpService: OtpService,
    private error: ErrorService,
    private analytics: AnalyticsService,
    private authTokenSrv: AuthTokenService
  ) {}

  ngOnInit() {
    // Si no hay datos de sesión (por ejemplo, al navegar hacia atrás desde la siguiente ventana),
    // se redirige a la ventana de Error.
    if (!sessionStorage.getItem("persona")) {
      this.error.altaClienteCancel = true;
      this.helpers.navigateTo("/404");
      return;
    } else {
      this.persona = JSON.parse(sessionStorage.getItem("persona"));
    }

    sessionStorage.removeItem("loginClienteTipoA");
    this.otpDetails.loading = true;
    this.isTipoA =
      this.authService.product === ACCOUNTS.TipoACode ||
      sessionStorage.getItem("altaClienteA") === "true";
    this.authService.setSessionProspect(this.persona);
    this.paramsSMS = new Otp({
      telephone: this.persona.datosPersonales.telefonoMovil,
      product: this.isTipoA ? "TipoA" : "CuentaInteligente",
      proveedor: this.isTipoA && this.persona.proveedor, // Parametro de la URL
      userId: "",
    });

    this.setFields();
    this.sendProspect();
    this.sendSmsOTP();
  }

  sendSmsOTP(phone: string = null) {
    const params = this.paramsSMS;
    if (phone) {
      params.telephone = phone;
    }

    if (typeof params.telephone === "undefined") {
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.title = "¡Ups!";
      this.otpDetails.modal.message =
        "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
      this.otpDetails.loading = false;
      this.otpService.openModal(false);
      return;
    }

    if (this.persona.datosPersonales.id_cliente_EVO) {
      this.generateSecurizedOtp(params);
    } else {
      this.otpDetails.loading = true;
      this.generateTokenOTP(params);
    }
  }
  generateModalMessage(code) {
    let mesage = "";
    switch (code) {
      //Mensaje para otp caducada
      case 2: {
        mesage =
          "Parece que has tardado más de la cuenta en introducir el código." +
          " Haz clic en “Volver a enviar código SMS” y continua con el proceso";
        break;
      }
      //Mensaje para otp incorrecta
      case 3: {
        mesage =
          "Parece que ha habido un error en el código introducido. Comprueba que el código" +
          " indicado es el correcto e inténtalo de nuevo" +
          ". Si necesitas cambiar el teléfono, haz clic en “Editar teléfono”.";
        break;
      }
      //Mensaje por defecto
      default: {
        mesage =
          "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o " +
          "el código que nos has indicado es el correcto e inténtalo de nuevo.";
        break;
      }
    }
    return mesage;
  }
  generateSecurizedOtp(params) {
    this.otpService.generateSecurizedOtp(params).subscribe(
      (data) => {
        if (data.operationErrorMessage) {
          this.otpDetails.modal.title = "¡Ups!";
          this.otpDetails.modal.message =
            "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
          this.otpDetails.loading = false;
          this.otpService.openModal(false);
          if (this.isTipoA) {
            this.otpDetails.analytics.triggerAltaClienteFormData(
              "OTP enviado",
              `Envio KO${data.operationErrorMessage}`
            );
          }
          return;
        }
        if (!data.sent) {
          this.otpDetails.modal.title = "¡Oh-Oh!";
          this.otpDetails.modal.message =
            "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
          this.otpDetails.loading = false;
          this.otpService.openModal(false);
          return;
        }

        this.otpDetails.otpSentCount = 0;
        this.otpDetails.otpId = data.id;
        this.otpDetails.loading = false;
      },
      (error) => {
        if (error.status === 401) {
          this.error.navToError();
        } else {
          this.otpDetails.modal.title = "¡Ups!";
          this.otpDetails.modal.message =
            "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
          this.otpDetails.loading = false;
          this.otpService.openModal(false);
        }

        if (this.isTipoA) {
          this.otpDetails.analytics.triggerAltaClienteFormData(
            "OTP enviado",
            `Envio KO: Servicio caído`
          );
        }
        this.otpDetails.loading = false;
      }
    );
  }

  generateTokenOTP(params) {
    this.otpService.generateTokenOTP(params.telephone).subscribe(
      (_data) => {
        this.otpService.generateOTP(params).subscribe(
          (data) => {
            if (data.operationErrorMessage) {
              this.otpDetails.modal.title = "¡Ups!";
              this.otpDetails.modal.message =
                "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
              this.otpDetails.loading = false;
              this.otpService.openModal(false);
              if (this.isTipoA) {
                this.otpDetails.analytics.triggerAltaClienteFormData(
                  "OTP enviado",
                  `Envio KO:${data.operationErrorMessage}`
                );
              }
              return;
            }

            if (!data.sent) {
              this.otpDetails.modal.title = "¡Oh-Oh!";
              this.otpDetails.modal.message =
                "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
              this.otpDetails.loading = false;
              this.otpService.openModal(false);
              return;
            }
            if (!data.operationErrorMessage && this.isPressResent) {
              this.otpDetails.openModalResentOtp();
              if (this.isTipoA) {
                this.otpDetails.analytics.triggerAltaClienteFormData(
                  "OTP enviado",
                  `Envio OK`
                );
              }
            }
            this.otpDetails.otpSentCount = 0;
            this.otpDetails.otpId = data.id;
            this.otpDetails.loading = false;
          },
          (error) => {
            if (error.status === 401) {
              this.error.navToError();
            } else {
              this.otpDetails.modal.title = "¡Oh-Oh!";
              this.otpDetails.modal.message =
                "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
              this.otpDetails.loading = false;
              this.otpService.openModal(false);
            }

            if (this.isTipoA) {
              this.otpDetails.analytics.triggerAltaClienteFormData(
                "OTP enviado",
                `Envio KO: Servicio caído`
              );
            }
            this.otpDetails.loading = false;
          }
        );
      },
      (_error) => {
        this.error.navToError();
      }
    );
  }

  onResendSMS(event) {
    console.log(event);
    this.isPressResent = true;
    this.sendSmsOTP(event);
  }

  onSendSMS(event) {
    this.otpDetails.loading = true;
    if (this.otpDetails.temporalMobilePhone !== this.paramsSMS.telephone) {
      this.persona.datosPersonales.telefonoMovil =
        this.otpDetails.temporalMobilePhone;
    }
    this.sendSmsOTP(this.otpDetails.temporalMobilePhone);
  }

  goBack(event) {
    this.helpers.navigateTo("bienvenido");
  }

  onSendCode(event) {
    let isYoungAccount = location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount;

    const params: ValidateSecurizedOtp = event;
    const paramsTypeA: ValidateSecurizedOtpTypeA = event;

    if (this.authService.product === ACCOUNTS.TipoACode) {
      paramsTypeA.indConsentimientoTipoA = this.persona.indConsentimientoTipoA;
      paramsTypeA.indPerfiladoCliente = this.persona.indPerfiladoCliente;
    }else if(this.authService.product === ACCOUNTS.CICode || this.authService.product === ACCOUNTS.CJCode){
      params.indConsentimientoTipoA = this.persona.indConsentimientoTratamientoTipoA;
      params.canal = this.deviceService.getDevice();
    }
    if (!this.isTipoA) {
      params.estado = this.persona.estado || "confirmacion-otp-primer-paso";
    }
    params.indPubliPe = this.persona.otrosDatos.indPubliPe.valueOf();
    params.origenPromocion = this.persona.origenPromocion;
    params.codProductoContratado = this.authService.product;
    params.oficina = environment.oficina;
    params.promocion = this.persona.promocion;
    params.origen = this.isTipoA
      ? "Proveedor"
      : isYoungAccount
      ? "Cuenta Joven"
      : "Cuenta Inteligente";
    params.proveedor = this.isTipoA ? this.persona.proveedor : "";
    params.nombre = this.persona.datosPersonales.nombreCliente;
    params.apellido1 = this.persona.datosPersonales.apellido1Cliente;
    params.apellido2 = this.persona.datosPersonales.apellido2Cliente;
    params.idExterno = this.persona.datosPersonales.idExterno;
    params.email = this.persona.datosPersonales.email;
    params.password = this.persona.datosPersonales.password;
    params.indConsentimientoTratamientoTipoA =
      this.persona.indConsentimientoTratamientoTipoA;
    params.consentimientoTGSS = this.persona.consentimientoTGSS;
    params.indPoliticaPrivacidad = this.persona.indPoliticaPrivacidad;

    if (typeof params.code === "undefined") {
      this.otpDetails.modal.title = "¡Ups!";
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.message =
        "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
      this.otpService.openModal(false);
      return;
    }
    this.otpDetails.loading = true;

    const paramsToSend =
      this.authService.product === ACCOUNTS.TipoACode
        ? Object.assign(paramsTypeA, params)
        : params;

    this.otpService.validateSecurizedOtp(paramsToSend).subscribe(
      (data) => {
        if (!data.validated) {
          this.otpDetails.otpSentCount++;
          this.otpDetails.modal.title = this.isTipoA ? "¡Oh-Oh!" : "¡Ups!";
          if (this.isTipoA) {
            this.otpDetails.analytics.triggerAltaClienteFormData(
              "OTP enviado",
              `Envio KO: ${data["operationErrorMessage"]}`
            );
          }
          this.otpDetails.modal.message = this.generateModalMessage(
            data.operationErrorCode
          );
          this.otpDetails.modal.withImage = false;
          this.otpDetails.loading = false;
          this.otpService.openModal(false);
        } else {
          this.authService.validatedOtp = data.validated;

          if (this.isTipoA) {
            this.continueFlowTypeA(data.response);
          } else {
            this.continueNormalFlow(data);
          }
        }
      },
      (error) => {
        this.otpDetails.loading = false;

        if (this.isTipoA) {
          this.otpDetails.analytics.triggerAltaClienteFormData(
            "OTP enviado",
            `Envio KO: 500 Server error`
          );
          this.error.navToErrorTypeA();
        }

        if (error.status === 401) {
          this.error.navToError();
        }
        this.otpDetails.modal.title = "¡Ups!";
        this.otpDetails.modal.withImage = false;
        this.otpDetails.modal.blocking = false;
        this.otpDetails.modal.message =
          "Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.";
        this.otpService.openModal(false);
      }
    );
  }

  continueNormalFlow(data) {
    this.clientExists = data.response && data.response.codigo === "1002";

    if (data.idPersona) {
      this.recoveryAbandonment(data.idPersona);
    } else {
      this.otpDetails.loading = false;
      const modalToShow = this.modService.selectStatusModal(data.response);
      this.otpDetails.modal.withImage = true;
      this.otpDetails.modal.title = modalToShow.title;
      this.otpDetails.modal.message = modalToShow.message;
      this.otpDetails.modal.blocking = modalToShow.blocking;
      this.otpService.openModal(this.otpDetails.modal.blocking);
      return;
    }
  }

  getNewMobilePhone() {
    const canal = this.deviceService.getDevice();
    return {
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil: this.otpDetails.temporalMobilePhone,
      },
    };
  }

  setFields() {
    this.persona.flujoOtpPrimerPaso = true;
  }

  sendProspect() {
    this.otpDetails.loading = true;
  }

  handleError() {
    this.otpDetails.loading = false;
    this.otpDetails.errService.handleError;
    this.otpDetails.errService.navToError();
  }

  recoveryAbandonment(idPersona: string) {
    this.otpDetails.loading = true;
    this.authService.getProspect(idPersona).subscribe(
      (data) => {
        let isFlujoIncial =
          data.estado === "registro" ||
          data.estado === "confirmacion-otp-primer-paso";
        let navigateTo = data.estado;

        if (isFlujoIncial) {
          this.persona = data; //Recuperar datos si ya lso tiene en SF
          this.persona.flujoOtpPrimerPaso = true;
          this.persona.estado = "confirmacion-otp-primer-paso";
          this.persona.datosPersonales.id_cliente_EVO = idPersona;
          this.persona.logalty = Object.assign({
            codProductoContratado: this.authService.product,
          });
          let isYoungAccount = location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode  || location.host === environment.hostYoungAccount;

          if (isYoungAccount) {
            navigateTo = "identificacion-edad";
          } else {
            navigateTo = "datos-personal";
          }

          this.authService.setSessionProspect(this.persona);
        } else {
          data.flujoOtpPrimerPaso = true;
          data.activeOtpFirstStep = this.persona.activeOtpFirstStep;
          //Si viene de confirma-datos se debe redirigir a identificacion-video
          if (data.estado === "confirmacion-datos") {
            if (data.otrosDatos.tipoIdentificacion === "identificacion-video") {
              data.estado = "identificacion-video";
            } else if (
              data.otrosDatos.tipoIdentificacion === "identificacion-iban"
            ) {
              data.estado = "identificacion-iban";
            }
          }
          //Indicamos que viene de un abandono para poder navegar al estado.
          data.abandono = true;
          navigateTo = data.estado;

          if (data.estado === 'casi-lo-tenemos' && data.otrosDatos && data.otrosDatos.tipoIdentificacion === "identificacion-video"){
            navigateTo = 'ahora-nosotros';
          }

          this.authService.setSessionProspect(data);
        }
        // Redirigimos
        // this.otpDetails.loading = false;
        this.helpers.navigateTo(navigateTo);
      },
      (_error) => {
        this.goToError();
      }
    );
  }

  goToError() {
    if (this.isTipoA) {
      this.error.altaClienteError = true;
    }
    this.error.navToError();
  }

  continueFlowTypeA(response) {
    switch (response.codigo) {
      case "OK":
        this.otpDetails.analytics.triggerAltaClienteFormData(
          "OTP enviado",
          `Envio OK`
        );
        this.goToLogin();
        break;
      case "1002":
        sessionStorage.setItem("responseAlta", "1002");
        sessionStorage.removeItem("currentProspect");
        this.helpers.navigateTo("/alta-cliente", { replaceUrl: true });
        break;
      case "1009":
      case "KO":
      default:
        this.otpDetails.analytics.triggerAltaClienteFormData(
          "OTP enviado",
          `Envio KO: código ${response["codigo"]}`
        );
        this.error.navToErrorTypeA();
    }
  }

  goToLogin() {
    sessionStorage.setItem("loginClienteTipoA", "true");
    const ssoURL = environment.ssoURL;
    const proveedor = this.persona.proveedor;
    const constEncodedURI = this.helpers.encode(this.persona.retorno);

    window.location.href =
      ssoURL +
      "?client_id=" +
      proveedor +
      "&redirect_uri=" +
      constEncodedURI +
      "&scope=openid&response_type=code";
  }
}
