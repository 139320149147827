

<div class="flecha-div">
  <div [class]="arraowUp"
        (click)="goPrevious()"></div>
</div>
<div  class="father-div-step" 
      *ngFor="let step of stepsCi; let i = index ;" >
  <div  [ngClass]="[step.active, isCursorActive(step)]"
        (click)="changeRoute(step)"> 
    <div [ngClass]="step.icon"></div>
  </div>
</div>
<div  class="flecha-div">
  <div  [class]="arraowDown"
        (click)="goNext()"></div>
</div>


