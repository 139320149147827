import { PersonaService } from "app/services/persona.service";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Persona } from "../../../collections/persona";

import { AuthService } from "../../../services/auth.service";
import { FormValidatorService } from "./../../../services/form-validator.service";
import { ErrorService } from "./../../../services/error.service";
import { ModalService } from "./../../../services/modal.service";
import { DeviceService } from "../../../services/device.service";
import { OtpService } from "app/services/otp.service";
import { ApiRecoveryService } from "../../../services/api/api.recovery.service";
import { RecoveryService } from "../../../services/recovery.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { SpecificAnalyticsService } from "../../../services/specific-analytics.service";
import { HelperService } from "../../../services/helpers.service";
import { LoginService } from "../../../services/login.service";
import { environment } from "environments/environment";
import { AuthGuard } from "../../../services/auth-guard.service";
import { Modal } from "../../../collections/modal";
import { REGISTERLITERALS } from "app/resources/register-literals";
import { ACCOUNTS } from "app/resources/account.model";
import { SecurityService } from "../../../services/security-registration.service";
import { ERRORSFORM } from "app/resources/errors-form";
import { VideoEidService } from "app/services/video-eid.service";
import { ApiVideoeidService } from "app/services/api/api.video-eid.service";
import { AuthTokenService } from "app/services/token/auth.token.service";

@Component({
  selector: "app-reactive-email",
  templateUrl: "./reactive-email.component.html",
  styleUrls: ["./reactive-email.component.scss"],
  providers: [
    FormValidatorService,
    ModalService,
    DeviceService,
    RecoveryService,
    VideoEidService,
    ApiRecoveryService,
    ApiVideoeidService,
  ],
})
export class ReactiveEmailComponent implements OnInit {
  public _isTokenized: boolean = false;
  private isEqualsValid: boolean = true;
  private isEmptyInput: boolean = false;
  public isScrollAtBottom: boolean = false;
  modalLegal = "legal-modal";
  modalLegalCI = "legal-modal-ci";
  modalPrivacy = "privacy-modal";
  public modal = new Modal();
  loading: boolean = false;
  isCheckedPrivacidad: boolean;
  isCheckedReactiveCheckConditions: boolean;
  public emailPhoneForm: FormGroup;
  private email: boolean;
  private persona: Persona;
  private phoneNumber: boolean = true;
  private recoveryToken: string;
  private token: string;
  private refresh: string;
  private idSfc: string;
  private clientID: string;
  private isSecondSigner: boolean = false;
  private signatureId: string;
  private codProduct: string;
  private origenPromocion: string;
  private origen: string;
  private seccion: string;
  private oferta: string;
  private codOriginacion = true;
  public loginUser: FormGroup;
  public action = "onboarding";
  @ViewChild("modalTextBox") public modalTextBox: ElementRef;
  // public showInitialMsg: boolean = false;
  public phoneIncomplete: boolean = false;
  public phoneEmpty: boolean = false;
  public blurNeeded: boolean = false;
  //propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep: boolean = true;
  public confirmEmailPhone = {
    text: "",
    class: "",
    errorText: [],
    valid: false,
    pristine: false,
    equals: false,
    filled: false,
    isEmail: false,
    isPhone: false,
    isChecks: false,
    isPhoneIncomplete: false,
  };
  public isYoungAccount: boolean =
    location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||
    this.authService.product === ACCOUNTS.CJCode ||
    location.host === environment.hostYoungAccount;

  public literals = REGISTERLITERALS[this.isYoungAccount ? "young" : "default"];
  public literals_mod = REGISTERLITERALS["modal"];
  public errors = ERRORSFORM;
  titleBox: string =
    REGISTERLITERALS[
      this.deviceService.showTabletDesign()
        ? "boxtitleTablet"
        : "boxtitleDesktop"
    ];
  public promoConditionText = REGISTERLITERALS["legalCondition"];
  mainTitleCIMobile: string = this.literals["mainTitleMobile"];
  mainTitleCIDesktop: string = this.literals["mainTitle"];
  mainTitleCJMobile: string = this.literals.bienvenido.title;
  mainTitleCJDesktop: string = this.literals.bienvenido.title;
  auth;
  videoId;
  @ViewChild("ephone") private ephone: ElementRef;
  @ViewChild("ephoneCJ") private ephoneCJ: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(_event) {
    this.listenScrollChange();
    this.titleBox =
      REGISTERLITERALS[
        this.deviceService.showTabletDesign()
          ? "boxtitleTablet"
          : "boxtitleDesktop"
      ];
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    public deviceService: DeviceService,
    private recoveryService: RecoveryService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private loginSrv: LoginService,
    private authGuard: AuthGuard,
    private securitySrv: SecurityService,
    private otpSrv: OtpService,
    private videoEidSrv: VideoEidService,
    private personaSrv: PersonaService,
    private authTokenSrv: AuthTokenService
  ) {
    this.createForm();
    this.createFormLogin();
    this.initModal();
    this.isScrollAtBottom = false;
    this.modalTextBox = null;
  }

  initModal() {
    this.modal = Object.assign({
      title: "",
      message:
        " Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.",
    });
  }

  ngOnInit() {
    if(this.isYoungAccount)
      this.phoneEmpty = true;

    this.authGuard.lastStep = 0;
    this.authService.pageSubmitted = "";
    this.getQueryParams();
    if (this.isRecoveryURL) {
      this.recoveryAbandonmentSSO();
    } else {
      this.errService.mobileErrors(this.emailPhoneForm);
      this.sessionControl();
    }
  }

  private listenScrollChange(): void {
    if (this.modalTextBox && this.modalTextBox.nativeElement) {
      const { clientHeight, scrollHeight } = this.modalTextBox.nativeElement;

      if (clientHeight < scrollHeight) {
        this.isScrollAtBottom = false;
        this.modalTextBox.nativeElement.onscroll = () => {
          const { scrollTop } = this.modalTextBox.nativeElement;
          const scrollToBottom = scrollHeight - scrollTop - clientHeight;
          if (scrollToBottom < 30) {
            this.isScrollAtBottom = true;
          }
        };
      } else {
        this.isScrollAtBottom = true;
      }
    }
  }

  ngAfterContentInit() {
    if (!this.isTokenizedURL && !this.isRecoveryURL) {
      if(!this.isYoungAccount) 
        this.ephone.nativeElement.focus();
      else
        this.ephoneCJ.nativeElement.focus();
    }
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (JSON.stringify(params) === "{}") {
        params = this.getParamsBeforeHash();
      }
      if (params.token) {
        this.recoveryToken = params.token;
        this.token = params.token;
      }

      if (params.refresh) {
        this.refresh = params.refresh;
        this.authTokenSrv.setRefreshToken(this.refresh);
      }

      if (params.idSfc) {
        this.idSfc = params.idSfc;
      }
      if (params.clientId) {
        this.clientID = params.clientId;
      }
      if (params.isSecondSigner) {
        this.isSecondSigner = params.isSecondSigner;
      }
      if (params.signatureId) {
        this.signatureId = params.signatureId;
      }
      if (params.codProductoContratado) {
        this.codProduct = params.codProductoContratado;
      }
      if (params.promoActiva) {
        sessionStorage.setItem("promoActiva", params.promoActiva);
      }
      if (params.numPromo) {
        sessionStorage.setItem("numPromo", params.numPromo);
      }
      if (!!params.codRecommendation) {
        this.origenPromocion = params.codRecommendation;
      }
      if (params.promoActiva) {
        sessionStorage.setItem("promoActiva", params.promoActiva);
      }
      if (params.origen) {
        this.origen = params.origen;
        sessionStorage.setItem('origen', params.origen)
      }
      if (params.seccion) {
        this.seccion = params.seccion;
        sessionStorage.setItem('seccion', params.seccion)
      }
      if (params.oferta) {
        this.oferta = params.oferta;
        sessionStorage.setItem('oferta', params.oferta)
      }
      if (!!params.codOriginacion) {
        if (params.codOriginacion === "false") {
          this.codOriginacion = false;
        }
      }
    });
  }

  get isTokenizedURL() {
    return this.recoveryToken && this.clientID;
  }

  get isRecoveryURL() {
    return this.token && this.refresh && this.idSfc;
  }

  getParamsBeforeHash(): any {
    let params = {};
    const urlInicio = window.location.href;
    const paramsBeforeHash = urlInicio.substring(
      urlInicio.lastIndexOf("?") + 1,
      urlInicio.lastIndexOf("#")
    );
    if (paramsBeforeHash.substring(0, 4) !== "http") {
      params = JSON.parse(
        '{"' +
          decodeURI(paramsBeforeHash)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    }
    return params;
  }
  sessionControl() {
    if (this.isTokenizedURL) {
      this._isTokenized = true;
      this.loading = true;
      this.authService.product = this.codProduct;
      this.recoveryService
        .recoveryValidation(
          this.recoveryToken,
          this.clientID,
          this.isSecondSigner,
          this.signatureId,
          this.codProduct
        )
        .subscribe(
          (isValid) => {
            this.loading = false;
            this.action = "abandono";
          },
          (_error) => {
            this._isTokenized = false;
            this.loading = false;
            this.modal = this.modService.selectStatusModal({ codigo: "1001" });
            this.modService.open(this.modal.name);
          }
        );
    } else {
      this.authService.deletePreviousSession();
      if (this.origen) {
        sessionStorage.setItem('origen', this.origen);
      }
      if (this.seccion) {
        sessionStorage.setItem('seccion', this.seccion);
      }
      if (this.oferta) {
        sessionStorage.setItem('oferta', this.oferta);
      }
    }
  }
  setFields() {
    const phone = this.confirmEmailPhone.isPhone
      ? this.emailPhoneForm.get("reactiveEmailPhone").value
      : undefined;

    const email = this.confirmEmailPhone.isEmail
      ? this.emailPhoneForm.get("reactiveEmailPhone").value
      : undefined;

    const canal = this.deviceService.getDevice();
    const codProductoContratado = this.isYoungAccount
      ? ACCOUNTS.CJCode
      : ACCOUNTS.CICode;
    const codOrigenPromocion = this.origenPromocion || "";
    this.authService.product = codProductoContratado;
    this.persona = Object.assign({
      canal: canal,
      estado: "registro",
      codigoEntidad: "0239",
      origenPromocion: codOrigenPromocion,
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil: phone,
        email: email,
      },
      otrosDatos: {
        indPubliPe: false,
      },
      activeOtpFirstStep: this.activeOtpFirstStep,
      promocion: this.codOriginacion,
      indConsentimientoTratamientoTipoA:
        this.helpers.returnValue(
          this.emailPhoneForm.get("reactiveCheckConditions")
        ) || false,
      consentimientoTGSS: true,
      indPoliticaPrivacidad:
        this.helpers.returnValue(
          this.emailPhoneForm.get("reactiveCheckPoliticaPrivacidad")
        ) || false,
    });
    sessionStorage.setItem("persona", JSON.stringify(this.persona));
  }

  checkCondiciones() {
    this.emailPhoneForm.controls["reactiveCheckConditions"].setValue(true);
    this.modService.close(this.modalLegal);
  }

  checkLegal() {
    this.emailPhoneForm.controls["reactiveCheckPoliticaPrivacidad"].setValue(
      true
    );
    this.modService.close(this.modalPrivacy);
  }

  closeModalCi() {
    this.modService.close(this.modalLegalCI);
  }

  closeModalPrivacy() {
    this.modService.close(this.modalPrivacy);
  }

  checkCondicionesCI(value: boolean) {
    this.emailPhoneForm.controls["reactiveCheckPoliticaPrivacidad"].setValue(
      value ? value : this.isCheckedPrivacidad
    );
    this.emailPhoneForm.controls["reactiveCheckConditions"].setValue(
      value ? value : this.isCheckedReactiveCheckConditions
    );
    if (!this.emailPhoneForm.get("reactiveCheckConditions").value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title: "¡Ups!",
        message:
          "Debes haber aceptar los términos y condiciones que tienen * .",
        withImage: true,
      });
      this.modService.open(this.modal.name);
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
      this.helpers.navigateTo("/confirmacion-otp-primer-paso");
    }
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: ["", Validators.required],
      pass: ["", Validators.required],
    });
  }

  triggerErrorAnalytics() {
    const noLegal = ", Condiciones legales no firmadas";
    const lenghtMobileValue =
      this.emailPhoneForm.get("reactiveEmailPhone").value.length;
    let invalidString = "";

    if (
      !this.emailPhoneForm.get("reactiveEmailPhone").valid &&
      this.errService.errorsArray.length === 0
    ) {
      if (lenghtMobileValue === 0) {
        invalidString = invalidString.concat("Móvil sin valor");
      } else {
        invalidString = invalidString.concat(
          "El móvil no tiene una longitud de 9 caracteres"
        );
      }
    }

    if (
      !this.emailPhoneForm.get("reactiveEmailPhone").valid &&
      this.errService.errorsArray.length !== 0
    ) {
      if (lenghtMobileValue !== 9) {
        invalidString = invalidString.concat(
          "El móvil no tiene una longitud de 9 caracteres"
        );
      } else {
        invalidString = invalidString.concat(
          "El móvil no empieza ni por 6 ni por 7"
        );
      }
    }

    if (!this.emailPhoneForm.get("reactiveCheckConditions").value) {
      invalidString = invalidString.concat(noLegal);
    }

    if (invalidString === noLegal) {
      invalidString = invalidString.replace(", ", "");
    }

    if (invalidString !== "") {
      return;
    }
  }

  onSubmit() {
    Object.keys(this.emailPhoneForm.controls).forEach((key) => {
      this.emailPhoneForm.get(key).markAsTouched();
      this.emailPhoneForm.get(key).markAsDirty();
    });
    this.phoneEmpty = false;
    this.phoneIncomplete = false;
    this.blurNeeded = true;
    let returned = false;
    this.triggerErrorAnalytics();
    sessionStorage.setItem("urlOrigen", window.location.href);
    if (!this.emailPhoneForm.get("reactiveEmailPhone").valid) {
      window.scrollTo(0, 300);
      this.modal = Object.assign({
        title: "¡Ups!",
        message: "Rellena el campo con un formato correcto.",
        withImage: true,
      });
      if (
        this.emailPhoneForm.get("reactiveEmailPhone").value === "" ||
        this.emailPhoneForm.get("reactiveEmailPhone").value === ""
      ) {
        // this.showInitialMsg = true;
        this.phoneEmpty = true;
        this.isEmptyInput = true;
      } else {
        this.phoneIncomplete = true;
        // this.showInitialMsg = false;
      }

      returned = true;
    }
    if (!this.emailPhoneForm.get("reactiveCheckPoliticaPrivacidad").value) {
      this.emailPhoneForm
        .get("reactiveCheckPoliticaPrivacidad")
        .setErrors({ notChecked: true });
      returned = true;
    }
    if (!this.emailPhoneForm.get("reactiveCheckConditions").value) {
      window.scrollTo(0, 300);
      this.modal = Object.assign({
        title: "¡Ups!",
        message:
          "Debes haber leído y aceptado los términos y condiciones legales para poder continuar.",
        withImage: true,
      });

      this.emailPhoneForm
        .get("reactiveCheckConditions")
        .setErrors({ notChecked: true });
      returned = true;
    }

    if (returned) {
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
      this.helpers.navigateTo("/confirmacion-otp-primer-paso");
    }
  }

  changeLegalCondiciones() {
    if (this.emailPhoneForm.get("reactiveCheckConditions").value) {
      this.emailPhoneForm.controls["reactiveCheckConditions"].setValue(false);
    } else {
      this.openLegal();
    }
  }

  changePrivacy() {
    if (this.emailPhoneForm.get("reactiveCheckPoliticaPrivacidad").value) {
      this.emailPhoneForm.controls["reactiveCheckPoliticaPrivacidad"].setValue(
        false
      );
    } else {
      this.openPrivacy();
    }
  }

  modalOpenCondiciones() {
    if (!this.emailPhoneForm.get("reactiveCheckConditions").value) {
      this.modService.open(this.modalLegal);
    } else {
      this.emailPhoneForm.controls["reactiveCheckConditions"].setValue(false);
    }
  }
  modalOpenCondicionesCI() {
    if (!this.emailPhoneForm.get("reactiveEmailPhone").valid) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title: "¡Ups!",
        message: "Rellena el campo con un formato correcto.",
        withImage: true,
      });
      this.isEmptyInput = true;
      this.modService.open(this.modal.name);
      return false;
    } else {
      this.modService.open(this.modalLegalCI);
    }
  }

  selectCheck(e) {
    if (e.key !== " ") return;
    e.preventDefault();
    this.helpers.updateFieldValue(
      this.emailPhoneForm,
      "reactiveCheckConditions",
      !e.currentTarget.parentElement.firstElementChild.checked
    );
  }
  selectCheckPubli(e) {
    if (e.key !== " ") return;
    e.preventDefault();
    this.helpers.updateFieldValue(
      this.emailPhoneForm,
      "reactiveCheckPoliticaPrivacidad",
      !e.currentTarget.parentElement.firstElementChild.checked
    );
  }

  openLegal($event?) {
    if ($event) {
      $event.stopPropagation();
    }
    this.modService.open(this.modalLegal, null, true);
  }

  openPrivacy($event?) {
    if ($event) {
      $event.stopPropagation();
    }
    this.isScrollAtBottom = false;
    this.modService.open(this.modalPrivacy, null, true);
    this.listenScrollChange();
  }

  goToMap() {
    window.open("https://www.evobanco.com/donde-estamos/");
  }

  isFormFilled(initForm, lengthField) {
    if (
      initForm.reactiveEmailPhone &&
      initForm.reactiveEmailPhone.length > lengthField &&
      initForm.reactiveEquals.length > lengthField
    ) {
      return true;
    }
    if (initForm.reactiveEmailPhone === initForm.reactiveEquals) {
      return true;
    }
    return false;
  }

  updateFormProperties(initForm) {
    const emailRexp = /.+@.+\..+/i,
      phoneRexp = /^[6-7][0-9]{8}|8{9}/i,
      phoneIncomplete = /^[6-7][0-9]/i,
      isEmail = emailRexp.test(initForm.reactiveEmailPhone),
      isPhone = phoneRexp.test(initForm.reactiveEmailPhone),
      isPhoneIncomplete = phoneIncomplete.test(initForm.reactiveEmailPhone),
      lengthField: number = isPhone ? 8 : 6;

    return {
      textField: isPhone ? "móvil" : "correo",
      textClass: isPhone ? "phone" : "email",
      isEmail: isEmail,
      isPhone: isPhone,
      isPhoneIncomplete: isPhoneIncomplete,
      filled: this.isFormFilled(initForm, lengthField),
      equals:
        this.helpers.hasEqualsChars(
          initForm.reactiveEmailPhone,
          initForm.reactiveEquals
        ) && this.emailPhoneForm.get("reactiveEquals").valid,
      isChecks: initForm.reactiveCheckConditions === true,
    };
  }

  setFirstTooltip(_reactiveEmailPhone) {
    // if (_reactiveEmailPhone.value !== "") {
    //   this.showInitialMsg = false;
    // }
    if (this.phoneEmpty) {
      return this.errService.getErrorFromField("reactiveEmailNotPhone");
    }
    if (this.isEmptyInput) {
      return this.errService.getErrorFromField("reactiveEmailNotPhone");
    } else {
      return this.errService.reactiveDesktopErrors(
        _reactiveEmailPhone,
        "reactiveEmailPhone"
      );
    }
  }
  phoneTextChange(_reactiveEmailPhone) {
    if (
      (_reactiveEmailPhone.value.length < 9 &&
        _reactiveEmailPhone.value.length > 0 &&
        _reactiveEmailPhone.value.substring(0, 1) !== "6" &&
        _reactiveEmailPhone.value.substring(0, 1) !== "7") ||
      (_reactiveEmailPhone.value.substring(0, 1) !== "6" &&
        _reactiveEmailPhone.value.substring(0, 1) !== "7" &&
        _reactiveEmailPhone.value !== "" &&
        _reactiveEmailPhone.value.length === 9)
    ) {
      this.phoneIncomplete = true;
      this.phoneEmpty = false;
    } else if (_reactiveEmailPhone.value === "") {
      this.phoneEmpty = true;
      this.phoneIncomplete = false;
    } else {
      this.phoneEmpty = false;
      this.phoneIncomplete = false;
    }
  }
  phoneTextChangeBlur(_reactiveEmailPhone) {
    if (
      _reactiveEmailPhone.value.length < 9 &&
      _reactiveEmailPhone.value.length > 0
    ) {
      this.phoneIncomplete = true;

      this.blurNeeded = false;
    } else if (_reactiveEmailPhone.value === "") {
      this.phoneIncomplete = false;

      this.blurNeeded = false;
    }

    if (
      (!this.emailPhoneForm.get("reactiveCheckConditions").value ||
        !this.emailPhoneForm.get("reactiveCheckPoliticaPrivacidad").value) &&
      _reactiveEmailPhone.value.length < 9 &&
      _reactiveEmailPhone.value.length > 0
    ) {
      this.onSubmit();
    }

    this.blurNeeded = true;

    setTimeout(() => {
      if (
        _reactiveEmailPhone.value.length < 9 &&
        _reactiveEmailPhone.value.length > 0
      ) {
        this.phoneEmpty = false;
      } else if (_reactiveEmailPhone.value === "") {
        this.phoneEmpty = true;
      }
    }, 500);
  }
  setClass(_camp) {
    if (this.confirmEmailPhone.equals) {
      return this.confirmEmailPhone.class;
    } else {
      if (!this.phoneNumber) {
        return this.confirmEmailPhone.class;
      } else {
        return "error";
      }
    }
  }
  setFirstInputClass(ephone) {
    if (this.isEmptyInput) {
      return "error";
    }
    if (ephone.value.length < 5) {
      return "";
    } else {
      if (this.confirmEmailPhone.valid) {
        return this.confirmEmailPhone.class;
      } else {
        return "error";
      }
    }
  }
  setSecondInputClass(dequals) {
    if (!this.isEqualsValid) {
      return "error";
    }
    if (dequals.value.length < 1) return this.confirmEmailPhone.class;
    else if (this.confirmEmailPhone.equals) {
      return this.confirmEmailPhone.class;
    } else {
      return "error";
    }
  }

  setDivClass(dequals) {
    if (!this.isEqualsValid) {
      return "";
    }
    if (dequals.value.length < 1) return this.confirmEmailPhone.class;
    else if (this.confirmEmailPhone.equals) return this.confirmEmailPhone.class;
    else return "";
  }

  setPropertiesForm(formProperties) {
    this.confirmEmailPhone = Object.assign({
      text: "Confirma tu " + formProperties.textField,
      class: formProperties.textClass,
      errorText: [
        { Error: "Formato de " + formProperties.textField + " no válido" },
      ],
      valid: this.emailPhoneForm.get("reactiveEmailPhone").valid,
      pristine: this.emailPhoneForm.get("reactiveEmailPhone").pristine,
      equals: formProperties.equals,
      filled: formProperties.filled,
      isEmail: formProperties.isEmail,
      isPhone: formProperties.isPhone,
      isChecks: formProperties.isChecks,
      isPhoneIncomplete: formProperties.isPhoneIncomplete,
    });
  }

  private createForm() {
    this.emailPhoneForm = this.fb.group({
      reactiveEmailPhone: ["", this.evoValidator.validateEmailPhone],
      // unused code?
      reactiveEquals: [""],
      reactiveCheckConditions: [false, Validators.requiredTrue],
      reactiveCheckPoliticaPrivacidad: [false, Validators.requiredTrue],
    });
    //Pone el foco en el input del telefono
    this.emailPhoneForm.get("reactiveEmailPhone").markAsTouched();
    this.emailPhoneForm.get("reactiveEmailPhone").markAsPristine();
    this.emailPhoneForm.get("reactiveEmailPhone").markAsDirty();
    this.formSubscribe();
  }

  private formSubscribe() {
    this.emailPhoneForm.get("reactiveEmailPhone").valueChanges.subscribe(() => {
      this.isEmptyInput = false;
      if (!this.confirmEmailPhone.valid) {
        this.helpers.updateFieldValue(this.emailPhoneForm, "reactiveEquals");
      }
    });

    this.emailPhoneForm
      .get("reactiveEquals")
      .valueChanges.subscribe((_equals) => {
        this.isEqualsValid = true;
      });

    this.emailPhoneForm.valueChanges.subscribe((emailForm) => {
      const formProperties = this.updateFormProperties(emailForm);

      this.setPropertiesForm(formProperties); //always before of setFields
      this.setFields(); //assign values to the global object persona
      this.phoneNumber =
        isNaN(emailForm.reactiveEquals) && this.confirmEmailPhone.isPhone;

      this.errService.reactiveErrors(this.emailPhoneForm);
    });
  }

  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? "móvil" : "correo";
  }

  /**
   * Si han venido los parámetros token e idSfc en la url recupera el estado con el getPersona y redirige hacia la página correspondiente
   */
  recoveryAbandonmentSSO() {
    this.authTokenSrv.setToken(this.token);
    this.loading = true;
    this.authService.product =
      this.codProduct || this.isYoungAccount
        ? ACCOUNTS.CJCode
        : ACCOUNTS.CICode;
    this.personaSrv
      .getPersonaById(this.idSfc, this.authService.product)
      .subscribe(
        (data) => {
          let isFlujoIncial =
            data.estado === "registro" ||
            data.estado === "confirmacion-otp-primer-paso";
          let navigateTo = data.estado;
          this.persona = data;
          if (isFlujoIncial) {
            this.persona.flujoOtpPrimerPaso = true;
            this.persona.estado = "confirmacion-otp-primer-paso";
            this.persona.datosPersonales.id_cliente_EVO = this.idSfc;
            this.persona.logalty = Object.assign({
              codProductoContratado: this.authService.product,
            });
            navigateTo = this.isYoungAccount
              ? "identificacion-edad"
              : "datos-personal";
          } else {
            data.flujoOtpPrimerPaso = true;
            data.activeOtpFirstStep = true;
            //Si viene de confirma-datos se debe redirigir a identificacion-video
            if (data.estado === "confirmacion-datos") {
              if (
                data.otrosDatos.tipoIdentificacion === "identificacion-video"
              ) {
                data.estado = "identificacion-video";
              } else if (
                data.otrosDatos.tipoIdentificacion === "identificacion-iban"
              ) {
                data.estado = "identificacion-iban";
              }
            }

            //Indicamos que viene de un abandono para poder navegar al estado.
            data.abandono = true;
            navigateTo = data.estado;
          }

          this.authService.setSessionProspect(this.persona);
          this.loading = false;
          this.helpers.navigateTo(navigateTo);
        },
        (_error) => {
          this._isTokenized = false;
          this.loading = false;
          this.helpers.navigateTo("/404");
        }
      );
  }
  openWelcomePackPromo(){
    let fileUrl = '/assets/pdf/info_CI_Bienvenida__EVO.pdf';
    window.open(fileUrl, '_blank');
  }

  isTablet() {
    return this.deviceService.isTablet() || (this.deviceService.showTabletDesign() && !this.deviceService.isMobile());
  }
}
