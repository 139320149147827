import { appRoutes } from './routes/default';
import { MonitoringConfig } from '@evobanco/arq-otel-ng-monitoring-legacy';

const monitoringConfig: MonitoringConfig = {
  serviceName: 'dev-env',
  metricsUrl: 'https://apiuat.evobanco.com:8443/rpl/metrics',
  spansUrl: 'https://apiuat.evobanco.com:8443/rpl/spans',
  logsUrl: 'https://apiuat.evobanco.com:8443/rpl/logs',
  ignoredPaths: ['?format=json']
}

const obj = {
  production: false,
  apiUrl: `${location.origin}`,
  urlLogalty: 'https://www.demo.logalty.es/lgt/lgtfrontend/login/samlLogin',
  videoId: '366516b3-9465-48e0-8584-634aba24e288', // 084171d3-629e-462a-9f8f-75849bb59ca1
  videoUrl: 'https://etrust-sandbox.electronicid.eu/js/videoid-3.x/videoid.js',
  urlAdrum: 'fra-col.eum-appdynamics.com',
  appKey: 'EC-AAB-PGW',
  oficina: '0806',
  urlCookies: `${location.origin}/onetrust/v1/evobanco/com/oneTrust_production/consent/d09ff370-4049-4641-8671-4943228a0dcb/otSDKStub.js`,
  type: 'CI',
  appRoutes,
  version: '',
  evoprotectUrl: '/evo-protect/simulador',
  otpClientId: '5cd3375d-f845-4375-ab86-7d59b2363d9a',
  otpClientSecret: 'dcad6955-dccb-4127-b964-b7a69f8a4e97',
  ssoURL: 'https://svcuat.evobanco.com:2443/auth/realms/onboarding/protocol/openid-connect/auth',
  urlBackFragment: 'portal/cinteligente/redirect',
  hostYoungAccount: 'cuentajovenuat.evobanco.com',
  hostRegister: 'registrouat.evobanco.com',
  monitoringConfig,
  urlMulticuenta: 'https://v2.cuentainteligenteuat.evobanco.com/multicuenta'
};
const date = new Date();
const version = `${date} v${obj.type}`;
obj.version = version;

export const environment = obj;

export const environmentDev = obj;
