export enum VideoType {
  VIDEO_ID = 'videoId',
  VIDEO_SCAN = 'videoScan',
}

export class VideoStatus {
  private videoState = {
    [VideoType.VIDEO_ID]: { finished: false },
    [VideoType.VIDEO_SCAN]: { finished: false }
  };

  public setVideoFinished(videoType: VideoType, finished: boolean) {
    this.videoState[videoType].finished = finished;
  }

  public isVideoFinished(videoType: VideoType): boolean {
    return this.videoState[videoType].finished;
  }
}