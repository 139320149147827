import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Modal } from "app/collections/modal";
import { Persona } from "app/collections/persona";
import { ACCOUNTS } from "app/resources/account.model";
import { AnalyticsService } from "app/services/analytics.service";
import { ApiVideoeidService } from "app/services/api/api.video-eid.service";
import { AuthService } from "app/services/auth.service";
import { ErrorService } from "app/services/error.service";
import { HelperService } from "app/services/helpers.service";
import { SpecificAnalyticsService } from "app/services/specific-analytics.service";
import { VideoEidService } from "app/services/video-eid.service";
import { environment } from "environments/environment";
import { DeviceService } from "../../../services/device.service";
import { ModalService } from "../../../services/modal.service";

@Component({
  selector: "app-verify-identity-video",
  templateUrl: "./verify-identity-video.component.html",
  styleUrls: ["./verify-identity-video.component.scss"],
  providers: [ModalService,VideoEidService,ApiVideoeidService],
})
export class VerifyIdentityVideoComponent implements OnInit {
  public loading: boolean = false;
  public modal: Modal;
  public modalSrv: ModalService;
  public imageQr: any;
  public previewLink: any;
  public dynamicQr: any;
  msgSrvError: string = "";
  clientId: string;
  modalSrvError: string = "modal-srv-error";
  private persona: Persona = this.authService.getSessionProspect();
  private flujoOtpPrimerPaso: boolean = true;
  modalInfoEid = 'modalInfoEid';
  isSecondSigner = false;
  isYoungAccount = false;
  noPhotoIdCard = false;
  standardModalTextNIE: string = 'Corre a por tu DNI o NIE'; 
  subtitleText: string = 'Tienes dos opciones: '

  constructor(
    public errService: ErrorService,
    public deviceService: DeviceService,
    public modService: ModalService,
    private authService: AuthService,
    private specificAnalytics: SpecificAnalyticsService,
    private videoEidSrv: VideoEidService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private _sanitizer: DomSanitizer
  ) {
    this.modal = new Modal();
     this.clientId = this.authService.getSessionProspect().datosPersonales
      ? this.authService.getSessionProspect().datosPersonales.id_cliente_EVO
      : this.errService.navToError();
  }

  ngOnInit() {
    this.flujoOtpPrimerPaso = this.persona.flujoOtpPrimerPaso;
    if (this.authService.product !== ACCOUNTS.STCode) {
      const data = Object.assign({
        abandono: true,
        flujoOtpPrimerPaso: true,
        activeOtpFirstStep: true,
      });
      this.authService.setSessionProspect(data);
    }
    if (sessionStorage.getItem('product-selected') && JSON.parse(sessionStorage.getItem('product-selected')).codProductoContratado === '999') {
      this.isSecondSigner = true;
    };
    if (sessionStorage.getItem('product') && sessionStorage.getItem('product') === '008') {
      this.isYoungAccount = true;
    };

    this.invokeQr();

    this.noPhotoIdCard = (this.persona.datosPersonales.codigoIdExterno == '53')

    if (this.noPhotoIdCard) {
      this.subtitleText = 'Ahora vamos a comenzar con la identificación a través de video'
    }
  }
  invokeQr(){
    let body = {}
    let initialUrl = window.location.href;
    let url = window.location.origin+ initialUrl.substring(initialUrl.indexOf("previa-eid")-1,initialUrl.length)//+"/previa-eid/previa-eid";
    url = url.replace("#","");
    console.log("url link",url);
    console.log("entorno actual: ",environment.production);
    if(!environment.production){
     JSON.stringify( body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligenteuat.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId,"androidInfo": { "androidPackageName": "es.evobanco.bancamovil.debug" }, "iosInfo": { "iosBundleId": "com.innocv.BMI", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" }});
    }else{
      // SUSTITUIR PARA PROBAR EN PRO
     JSON.stringify( body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligente.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId,"androidInfo": { "androidPackageName": "es.evobanco.bancamovil" }, "iosInfo": { "iosBundleId": "com.evobanco.movil", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" }});
     //JSON.stringify( body = { "dynamicLinkInfo": { "domainUriPrefix": "https://evobancocuentainteligenteuat.page.link", "link": url +"?token="+ this.authService.getToken() +"&idSfc="+this.clientId,"androidInfo": { "androidPackageName": "es.evobanco.bancamovil.debug" }, "iosInfo": { "iosBundleId": "com.innocv.BMI", "iosAppStoreId": "542752481" } }, "suffix": { "option": "UNGUESSABLE" }});
    }


    this.videoEidSrv.getDynamicQrImage(body).subscribe(
      data =>{
        this.dynamicQr = data.shortLink;
        this.previewLink = data.previewLink;
        let bodyQr = {};
        bodyQr = {
          //SUSTITUIR PARA PROBAR EN PRO
          "data":  environment.production ? "https://api.evobanco.com:8443/evobanco/boarding/fullonline/v1/redirect/"+ this.dynamicQr.substring(this.dynamicQr.lastIndexOf("/")+1,this.dynamicQr.length) : "https://apiuat.evobanco.com:8443/evobanco/boarding/fullonline/v1/redirect/" + this.dynamicQr.substring(this.dynamicQr.lastIndexOf("/")+1,this.dynamicQr.length),
          //"data":"https://apiuat.evobanco.com:8443/evobanco/boarding/fullonline/v1/redirect/" + this.dynamicQr.substring(47,this.dynamicQr.length),
          "height":200,
          "width":200,
          "logo":"https://www.evobanco.com/.galleries/imagenes/evo-qr.png",
        }
        this.videoEidSrv.getQrImage(bodyQr).subscribe(
          data =>{
            this.imageQr = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, "+ data.content);

          },
           error => console.error(error));;

      },
       error => console.error(error));;
  }

  onSubmit() {
    this.setTipoIdentificacionProspec();
    this.sendToIdentification();
  }
  redirect() {
    //if (!this.deviceService.isMobile())
    this.onSubmit();
  }
  setTipoIdentificacionProspec() {
    const canal = this.deviceService.getDevice();
    this.specificAnalytics.emitIdentificationType("video");
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      otrosDatos: {
        tipoIdentificacion:"identificacion-video",
      },
    });
  }

  sendToIdentification() {
    this.loading = true;
    if (JSON.parse(sessionStorage.getItem("discriminatorInfo"))) {
      let discriminator = JSON.parse(
        sessionStorage.getItem("discriminatorInfo")
      );
      this.persona.discriminador = discriminator;
    }
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        if (data.response.codigo === "KO") {
          this.msgSrvError = !!data.response.message
            ? data.response.message
            : "Error genérico";
          this.modalSrv.open(this.modalSrvError);
          return;
        }
        let nextstep = "identificacion-video";
        this.loading = false;
        this.helpers.navigateTo(nextstep);
      },
      (error) => {
        this.error();
      }
    );
  }
  error() {
    this.errService.navToError();
  }

  showModalEid() {
    if (this.persona.datosPersonales.codigoIdExterno == '53') {
      this.standardModalTextNIE = 'Corre a por tu DNI del país de origen o tu pasaporte y tu certificado de registro de Ciudadanos de la Unión Europea'
    } 
    this.modService.open(this.modalInfoEid);
  }

}
