<loading [showMe]="loading"></loading>


<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<div class="banner banner-dni-caducado" *ngIf="showWarningUpdateDocument && !hiddeBanner">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
          <i class="evo ico-warning-white" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        ¡Vaya! Tenemos tu documento de identificación caducado, necesitamos que lo actualices.
      </span>
    </div>
    <div class="banner-button" (click)="subirDocumentacion()">
      Actualizar
    </div>
  </div>
</div>

<div class="banner banner-dni-caducado" *ngIf="showWarningNoDocument && !hiddeBanner">
    <div class="banner-content">
      <div class="evo close banner-close" (click)="hideBanner()">
      </div>
      <div class="message-container">
        <span class="banner-icon">
            <i class="evo ico-warning-white" style="font-size: 28px;"></i>
        </span>
        <span class="banner-message">
          ¡Vaya! Hemos detectado que nos falta la copia de tu documento de identificación,necesitamos que lo actualices
        </span>
      </div>
      <div class="banner-button" (click)="subirDocumentacion()">
        Actualizar
      </div>
    </div>
  </div>

<div class="banner banner-validando-solicitud" *ngIf="showWarningValidating && !hiddeBanner">
    <div class="banner-content">
      <div class="evo close banner-close" (click)="hideBanner()">
      </div>
      <div class="message-container">
        <span class="banner-icon">
            <i class="evo ic-reloj" style="font-size: 32px;"></i>
        </span>
        <span class="banner-message">
          Estamos validando tu solicitud. Nos pondremos en contacto contigo pronto.
        </span>
      </div>
    </div>
  </div>

<modal [modalTitle]="'Incomplete'" [blocking]='false' [modalId]='incompleteModal' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{modalText}}</p>
  </div>
</modal>

<modal [modalTitle]="'Incomplete'" [blocking]='false' [modalId]='errorSendModal' class='modal'>
    <div class="container">
      <h2>¡VAYA!</h2>
      <p class="invalid-text">
        No podemos enviar tu invitación para ser segundo titular al email que nos has facilitado.
        Llámanos al <a class="link-blue" href="tel:910900900">910 900 900</a> para que podamos ayudarte a continuar.</p>
    </div>
  </modal>


<modal [modalTitle]="'Condiciones Legales'" [blocking]='false' [modalId]='modalLegal' class='modal'>
  <div class="container">
    <h2>Aceptación de las condiciones legales</h2>
    <p>Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y tratar lo datos personales de la persona que facilito en este apartado, con la finalidad de solicitar la contratación de la Cuenta Inteligente como segundo titular.</p>
  </div>
</modal>
<modal [modalTitle]="'Segundo titular existente'" [blocking]='true' [full-screen]="true" [modalId]='errorSecondHolder' class='modal'>
  <div class="container"> 
    <h2 style="text-align: center;">¡VAYA!</h2>
    <p> Ya existe un cliente con el email introducido y con el producto a contratar .</p>
    <div class="btn-group modal-accept-button">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="closeErrorSend()">ACEPTAR</button>
    </div>
  </div>
</modal>
<modal [modalTitle]="'Recomienda'" [blocking]='false' [modalId]='recomiendaModal' class='modal recomienda'>
  <iframe #iframereco class="recomiendaiframe" width="100%" height="100%"></iframe>
</modal>

<profile-slider [interval]="photoInterval"
[noWrap]="noLoopSlides" class="desktop"
[title]="'Area personal'"
[text]="'Somos un banco, pero no todos son números. Descubre todo lo que puedes hacer desde tu área personal.'">
  <slide *ngFor="let slidez of slidesEmulated; let index=index" [active]="slidez.active">
    <img class="wide-carousel" [src]="slidez.image">
  </slide>
</profile-slider>
<div class="main-profile">
  <div class="background-image"></div>
  <div class="content-contain">
    <div *ngIf='!ibanIdentified'>
      <div class="row">



        <div class="info-container info-container-fill">
          <div class="info-container-content">

            <h3>¡HOLA {{userName}}!</h3>
            <p class="datos-completos">Tienes todos tus datos completados.
            </p>
            <div class="row">
              <div class="progress-contain">
                <span>{{porcentajeBarra}}%</span>
                <div class="progress-bar-container">
                  <div class="progress-bar progress-bar-bottom"></div>
                  <div class="progress-bar progress-bar-top" [ngStyle]="barraStyle"
                  ></div>
                </div>
            </div>
          </div>
            <div class="row ver-mas" [ngClass]="showWarningUpdateDocument || showWarningNoDocument?'perfil':'perfil-no-banner'"  (click)="goToMyProfile()">
              <p class='uppercase-info'>ver más</p>
              <i class="evo right-arrow"></i>
            </div>
            <!-- <div class="row info">
              <p class='uppercase-info'>Ir a mi perfil</p><i class="evo right-arrow"></i>
            </div> -->
            <span class="top-right-icon">
                <!--<i class="evo ico-warning-pink" style="font-size: 32px;"></i>-->
            </span>
              </div>

          <div class="row" *ngIf="showWarningUpdateDocument">
            <div class="notification-dni" (click)="subirDocumentacion()">
              <span>
                <span>
                    <i class="evo ico-warning-white" style="font-size: 28px;"></i>
                </span>
                <span>
                  Tenemos tu DNI/NIE caducado, actualízalo aquí
                </span>
              </span>
              <span>
                <i class="evo right-arrow"></i>
              </span>
              </div>
          </div>

          <div class="row" *ngIf="showWarningNoDocument">
            <div class="notification-dni" (click)="subirDocumentacion()">
              <span>
                <span>
                    <i class="evo ico-warning-white" style="font-size: 28px;"></i>
                </span>
                <span>
                  Hemos detectado que falta la copia de tu  DNI/NIE, súbelo desde aquí
                </span>
              </span>
              <span>
                <i class="evo right-arrow"></i>
              </span>
            </div>
            </div>

          <div class="row" *ngIf="showWarningValidating && !hiddeNotification">
            <div class="notification-dni">
              <span>
                <span>
                    <i class="evo ic-reloj" style="font-size: 32px;"></i>
                </span>
                <span>
                  Estamos validando tu petición.
                </span>
              </span>
              <span (click)='hideNotification()'>
                <i class="evo close"></i>
              </span>
          </div>
        </div>

        </div>

        <div class="info-container constrain-small">
          <h3>mi tarjeta</h3>
          <p>Ya hemos creado tu tarjeta.</p>
          <div class="row tarjeta">
            <i class='evo tarjeta-creada big pink'></i>
            <span class="big pink">CREADA</span>
          </div>
          <!-- <div class="row info">
            <p class='uppercase-info'>ver más</p><i class="evo right-arrow"></i>
          </div> -->
        </div>
        <div class="info-container" *ngIf="producto != null">
          <h3>Mi {{ producto.nombreProducto }}</h3>
          <!-- <p class="mobile-only">Son dos cuentas conectadas entre sí: una cuenta para tu día a día y otra para tus ahorros al 0,20% T.A.E.</p> -->
          <div class="row account">
            <div class="row">
              <p class='false-bold'>Nombre del {{ myProductsService.getTypeUser(producto.relacionAcuerdoPersona) }}:
                <span>{{user}}</span>
              </p>
            </div>
            <div class="row" [ngClass]="secondUser?'':'hide'">
              <p class='false-bold'>Segundo titular: </p>

            </div>
            <div *ngIf="producto.ibanCC != null" class="row">
              <p class='false-bold'>IBAN CC:
                <span> {{producto.ibanCC}}</span>
              </p>
            </div>
            <div *ngIf="producto.ibanCD != null" class="row">
              <p class='false-bold'>IBAN CD:
                <span> {{producto.ibanCD}}</span>
              </p>
            </div>
          </div>
          <div class="row info" (click)="myProductsService.goToProductDetail(producto)">
            <p class='uppercase-info'>ver más</p>
            <i class="evo right-arrow"></i>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf='canHaveSecondHolder' class="container-secondholder info-container">
          <div class="constrain-big" *ngIf='canHaveSecondHolder' [ngClass]="veilSecondUser?'veil':''">
            <form (keydown.enter)='$event.preventDefault()' [formGroup]="secondHolderForm" (ngSubmit)="onSubmit()" *ngIf='!isSendSecondUser'>
              <h3>{{addSecondHolder}}</h3>
              <p>Indícanos la cuenta de correo del segundo titular para que podamos enviarle un correo y que inicie el proceso.</p>
              <div class="row flex">
                <div class="mobile-container div-secondholder">
                  <input type="text"
                    #emailSecond
                    name="email"
                    formControlName="email"
                    value="" placeholder="Email del segundo titular"
                    autocomplete="off"
                    (blur)="outFocus(emailSecond)">
                  <div class="error-text" *ngIf="errService.desktopErrors(secondHolderForm.get('email'), 'email')">
                    {{errService.desktopErrors(secondHolderForm.get('email'), 'email')}}
                  </div>
              </div>
              <div class="mobile-container div-secondholder">
                  <input type="text"
                    #confirmSecond
                    onCopy='return false' onPaste='return false'
                    name="confirmEmail"
                    formControlName="confirmEmail"
                    value="" placeholder="Confirma su email"
                    autocomplete="off"
                    (blur)="outFocus(confirmSecond)">
                    <div class="error-text" *ngIf="errService.desktopErrors(secondHolderForm.get('confirmEmail'), 'confirmEmail')">
                      {{errService.desktopErrors(secondHolderForm.get('confirmEmail'), 'confirmEmail')}}
                    </div>
              </div>

              </div>
              <div class="row conditions">
                <div class="checkbox-group">
                  <div class="form-checkbox">
                    <input type="checkbox"
                    name="checkLegal"
                    formControlName="checkLegal"
                    id="check_conditions"
                    (click)='openLegal()'
                    value="">
                    <label for="check_conditions" tabindex="3">He leido y acepto las <a class='legal-conditions'>condiciones legales</a></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mobile-container center mobile">
                  <button class="btn-common btn-common_fix_bold" type="submit">
                    Añadir segundo titular
                  </button>
                </div>
              </div>
            </form>
            <div *ngIf='isSendSecondUser'>
                <h3>segundo titular enviado</h3>
                <div class="row">
                  <p>Has enviado un email a {{secondHolderForm.get('email').value}} para darle de alta como segundo titular.</p>
                </div>
                <div class="row top-margin">
                  <div class="mobile-container center mobile">
                      <form (keydown.enter)='$event.preventDefault()' (ngSubmit)="addOtherSecondHolder()">
                        <button class="btn-common btn-common_fix_bold" type="submit">
                          Añadir otro titular
                        </button>
                      </form>
                  </div>
                </div>
                <div class="need-help">
                  <p>¿Necesitas ayuda?</p>
                  <div class="phone">
                    <i class="phone-image"></i>
                    <span class="phone-text pink">
                      <a href='tel:910900900'>LLÁMANOS 910 900 900</a>
                    </span>
                  </div>
                </div>
            </div>
          </div>
          <!-- send second user -->
          <!--
          <div class="info-container top-margin constrain-big" *ngIf='isSendSecondUser'>
            
          </div>
        -->
        </div>

        <div class="info-container calendar-container">
          <h3>¿qué necesito saber?</h3>
          <div class="calendar scrollable">
            <ul>
              <li *ngFor='let notification of notifications'
              (click)='goToDetail(notification)'>
                <div class="row timetable">
                  <b>{{notification['title']}}</b>
                </div>
                <div class="row timetable">
                  <p>{{notification['date']}}</p>
                </div>
              </li>
          </ul>
        </div>
      </div>

      </div>
      <div class="row">
        <div class="center container">
          <h3 class="whiteText">también te puede interesar</h3>
          <small-slider [interval]="photoInterval" [noWrap]="noLoopSlides" [text]="" [arrowStyle]='slides.length < 1? "hide":""'>
            <small-slide *ngFor="let slide of slides; let index=index" [slideObj]='slide' [clientID]='clientID'>
              <img class="restricted-carousel" [src]="slide.image">
            </small-slide>
          </small-slider>
        </div>
      </div>
    </div>
  </div>

  <div class="row recomienda">
    <i class="big evo people"></i>
    <div class="flex-column">
      <div class="text-container">
        <h6>recomienda evo</h6>
        <p>Si te ha gustado el proceso y te ha parecido fácil, contagia a tus amigos la forma de ahorrar.</p>
      </div>
      <div class="mobile-container flex-right">
        <h6 class="uppercase-info" (click)='goToRecomienda()'>recomendar</h6>
        <i class="evo right-arrow"></i>
      </div>
    </div>
  </div>


</div>
<!-- <client-advantages></client-advantages> -->
