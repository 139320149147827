import { environment } from 'environments/environment';
import { InactivityService } from 'app/service/inactivity.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { ModalService } from './../../services/modal.service';
import { BySideCarService } from '../../services/bysidecar.service';
import { ApiBySideCarService } from '../../services/api/api.bysidecar.service';
import { AuthService } from './../../services/auth.service';
import { ErrorService } from './../../services/error.service';
import { AuthGuard } from './../../services/auth-guard.service';
import { DeviceService } from 'app/services/device.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';

export type GtmData = { [key: string]: any };

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ModalService,
    BySideCarService,
    ApiBySideCarService,
    DeviceService]
})
export class HeaderComponent implements OnInit {
  bysidecarFormNew = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl('')
  });

  public callMeModal = 'call-me';
  private step;
  public isPhoneSent: boolean = false;
  public modalTitle = '¿Quieres que te llamemos?';
  public desktopPhone: string = '';
  public isCampanyaCaptacion: boolean =  location.href.indexOf('codRecommendation') !== -1;
  public mobilePhone: number = 0;
  userHasPhone: boolean = false;
  public isYoungAccount: boolean = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
  public isAltaClienteTipoA: boolean = (this.authService.product === ACCOUNTS.TipoACode);
  public isPreviaEid: boolean = false;

  private desktopStepsToPhone: Object = {
    'bienvenido': '',
    'datos-personal': '',
    'datos-contacto': '',
    'datos-personales': '',
    'datos-de-contacto': '',
    'datos-laboral': '',
    'confirmacion-otp': '',
    'metodo-validacion': '910900900',
    'identificacion-video': '910900900',
    'identificacion-iban': '910900900',
    'confirmacion-datos': '',
    'previa-firma': '',
    'proceso-firma': '',
    'contratacion-ci': '',
    'casi-lo-tenemos': '',
    'casi-esta': '',
    'ya-esta': '',
    'identificacion-edad': '',
    'confirmacion-otp-primer-paso': '',
    'Edad': '',
    '404': '',
    'OCR': '',
    'abandono': '',
    'verificacion-cuenta': '910900900',
    'pendiente-verificacion-transferencia': '910900900',
    'previa-eid': '',
    'ahora-nosotros': ''

  };
  private mobileStepsToPhone: Object = {
    'bienvenido': 0,
    'datos-personal': 0,
    'datos-contacto': 0,
    'datos-personales': 0,
    'datos-de-contacto': 0,
    'datos-laboral': 0,
    'confirmacion-otp': 0,
    'metodo-validacion': 910900900,
    'identificacion-video': 910900900,
    'identificacion-iban': 910900900,
    'confirmacion-datos': 0,
    'previa-firma': 0,
    'proceso-firma': 0,
    'contratacion-ci': 0,
    'casi-lo-tenemos': 0,
    'casi-esta': 0,
    'ya-esta': 0,
    'identificacion-edad': 0,
    'confirmacion-otp-primer-paso': 0,
    'Edad': 0,
    '404': 0,
    'OCR': 0,
    'abandono': 0,
    'verificacion-cuenta': 910900900,
    'pendiente-verificacion-transferencia': 910900900,
    'previa-eid': 910900900,
    'ahora-nosotros': 0
  };

  constructor(public modService: ModalService,
    private bysidecar: BySideCarService,
    private authService: AuthService,
    private authGuard: AuthGuard,
    public errService: ErrorService,
    private route: ActivatedRoute,
    public deviceService: DeviceService,
    private router: Router,
    public analytics: AnalyticsService,
    public inactivitySrv: InactivityService
  ) {
  }

  printdata(){
    console.log('bysidecarForm: ', this.bysidecarFormNew)
  }

  ngOnInit() {
    if (!this.isYoungAccount) {
      this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    };
    if(!this.isCampanyaCaptacion) {
      this.isCampanyaCaptacion =  location.href.indexOf('codRecommendation') !== -1;
    }
    if (!this.isAltaClienteTipoA) {
      this.isAltaClienteTipoA = (this.authService.product === ACCOUNTS.TipoACode);
    };
    if(location.href.indexOf('previa-eid') !== -1){
      this.isPreviaEid =true;
    }
    this.getPhoneNumber();
    if (this.router.url) {
      this.refreshPhones(this.router.url.replace('/', ''))
    }
    if (!this.isYoungAccount && !this.isCampanyaCaptacion && !this.isAltaClienteTipoA && !this.isPreviaEid) {
      this.inactivitySrv.eventShowModalC2C.subscribe(data => {
        if (this.desktopPhone !== '' || this.mobilePhone !== 0 ) {
          this.openModal();
        }
      });
      this.bysidecar.eventShowModal.subscribe(data => {
        if (this.desktopPhone !== '' || this.mobilePhone !== 0 ) {
          this.openModal();
        }
      });
    }
  }

  getTitleResponsive() {

    if(this.errService.rsi || this.authService.product === ACCOUNTS.TipoACode) {
      return '';
    }
    if (this.isYoungAccount) {
      return 'CONTRATA TU CUENTA JOVEN';
    }
    return 'CONTRATA TU CUENTA INTELIGENTE BIENVENIDA';
  }

  openModal() {
    this.analytics.triggerCTA({});
    if (this.userHasPhone) this.callUserWithPhone();
    return this.modService.open(this.callMeModal);
  }

  informAnalytics() {
    return this.analytics.triggerCTA({});
  }

  getPhoneNumber() {
    this.router.events.subscribe(params => {
      if (params instanceof NavigationEnd) {
        let route = params.url.replace('/', '');
        if (route === 'undefined') route = 'bienvenido';
        this.refreshPhones(route);
      }
    })
  }

  refreshPhones(step) {
    let stepWithoutParams = step.toLocaleLowerCase().split('?')[0];
    this.step = this.desktopStepsToPhone[stepWithoutParams];
    this.desktopPhone = this.desktopStepsToPhone[stepWithoutParams];
    this.mobilePhone = this.mobileStepsToPhone[stepWithoutParams];
    if (this.desktopPhone !== '' && !this.isPreviaEid) {
      if (this.authService.getSessionProspect().datosPersonales && this.authService.getSessionProspect().datosPersonales.telefono) {
        this.userHasPhone = true;
      }
      this.bysidecar.initTimeInactivity(3);
    }
    else{
      this.bysidecar.cancelTimeOut();
    }
  }

  callUserWithPhone(data?: GtmData) {
    this.isPhoneSent = true;
    const phone = this.authService.getSessionProspect().datosPersonales.telefono;
    const name = this.authService.getSessionProspect().datosPersonales.nombreCliente;
    this.modalTitle = '¡Oído!'
    this.bysidecar.callUser().subscribe(data => {
      this.analytics.triggerC2C({});
      this.bysidecar.cscService(phone, name,data).subscribe(
        data =>{}
      )
    }, error => console.error(error));
  }

  callUser(f: NgForm) {
    const phone = f.value.phone;
    const name = f.value.name;
    if (f.valid) {
      this.isPhoneSent = true;
      this.modalTitle = '¡Oído!'
      this.bysidecar.callUser().subscribe(
        data =>{
          this.analytics.triggerC2C({});
          this.bysidecar.cscService(phone, name,data).subscribe(
            data =>{}
          )
        },
        error => console.error(error)
      );
      f.value.phone = '';
      f.value.name = '';
      if(!this.isYoungAccount){
        this.modService.close(this.callMeModal);
      }
    }
  }
}
