import { Directive, ElementRef, HostListener, Input, OnChanges, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UserTooltip } from '../components/user-tooltip/user-tooltip.component'

@Directive({
  selector: '[user-tooltip]'
})

export class UserTooltipDirective {


  tooltip: any;
  elemPosition: any;
  tooltipOffset: number = 8;

  @Input('tooltip') tooltipText: any;

  @Input('specificClass') specificClass: string;

  @HostListener('mouseover')
  onMouseOver() {
    this.createSetTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.removeView();
  }
  constructor(
    private elementRef: ElementRef,
    private viewContainer: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver
  ) { }

  createSetTooltip() {
    if (this.viewContainer.length < 1 && this.tooltipText !== '' && window.innerWidth > 992) {
      this.getElemPosition().then((solved) => {
        this.createDialog(UserTooltip).then((usertooltip) => {
          const component: any = usertooltip;
          this.insertText(usertooltip).then(() => {
            setTimeout(() => {
              this.setPosition(component)
            }, 1000);
          })
        });
      })
    }
  }

  getElemPosition() {
    return new Promise((resolve) => {
      resolve(this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect());
    })
  }

  createDialog(tooltip: { new(): UserTooltip }) {
    return new Promise((resolve) => {
      let tooltipFactory =
        this.componentResolver.resolveComponentFactory(tooltip);
      let tooltipRef = this.viewContainer.createComponent(tooltipFactory);
      resolve(tooltipRef);
    })
  }

  insertText(errorComponentRef) {
    return new Promise((resolve) => {
      errorComponentRef.instance.sClass = this.specificClass;
      resolve(errorComponentRef.instance.text = this.tooltipText)
    })
  }

  removeView() {
    this.viewContainer.clear();
  }

  checkLines() {
    let extraPixels = 0;
    if (this.tooltipText === "Método de identificación" || this.tooltipText === "Confirmación teléfono") extraPixels = 0;
    if (this.tooltipText === "INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ" ||
      this.tooltipText === "Introduce los primeros dígitos del código CNO o las letras de la actividad económica para seleccionar entre las distintas opciones") extraPixels = 55;
    if (this.tooltipText === "Introduce las letras de la profesión para seleccionar entre las distintas opciones") extraPixels = 25;

    return extraPixels;
  }

  getLeftOffest() {
    let extraPixels = 0;
    if (this.tooltipText === "INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ" ||
      this.tooltipText === "Introduce los primeros dígitos del código CNO o las letras de la actividad económica para seleccionar entre las distintas opciones" ||
      this.tooltipText === "Introduce las letras de la profesión para seleccionar entre las distintas opciones") {
        extraPixels = -20;
      }
      else {
        extraPixels = 30;
      }

    return extraPixels;
  }

   setPosition(component) {
    const marginTopHeader = 48;
    this.tooltip = component.location.nativeElement;
    const heightFirstDivOfTooltip = this.tooltip.children[0].offsetHeight + 40;
    const leftOffset = this.getLeftOffest();

    this.tooltip.style.position = 'relative';
    this.tooltip.style.top = "-" + (marginTopHeader + heightFirstDivOfTooltip + this.checkLines()) + "px";
    this.tooltip.style.left = leftOffset + "px";
  }
}
