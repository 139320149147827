<loading [showMe]="loading"></loading>
<!-- <mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone> -->

<modal
  [blocking]="false"
  [modalId]="modalPrivacy"
  [modal-wide]="true"
  class="modal privacy-modal"
>
  <div class="privacy-modal__container container">
    <p class="privacy-modal__icon"></p>
    <h2 class="privacy-modal__title">
      Información básica en protección de datos
    </h2>
    <div class="privacy-modal__text" #modalTextBox>
      <privacy-text></privacy-text>
    </div>
    <div class="privacy-modal__warning" *ngIf="!isScrollAtBottom">
      <i class="evo ico-warning-white privacy-modal__icon-warning"></i>
      Tienes que leer toda la información para poder continuar.
    </div>
    <div class="buttonCI privacy-modal__button-box">
      <button
        class="btn-open-account open-account privacy-modal__confirm"
        [disabled]="!isScrollAtBottom"
        (click)="checkLegal()"
        [ngClass]="{
          'privacy-modal__confirm--disabled': !isScrollAtBottom
        }"
      >
        ACEPTAR
      </button>
    </div>
  </div>
</modal>

<modal
  [blocking]="false"
  [modalId]="modalLegal"
  [modal-wide]="true"
  class="modal terms-condition-modal"
>
  <div class="terms-condition-modal__container container">
    <p class="terms-condition-modal__icon"></p>
    <h2 class="terms-condition-modal__title">
      Términos y condiciones servicios a distancia EVO
    </h2>
    <div class="terms-condition-modal__text">
      <terms-and-conditions></terms-and-conditions>
    </div>
    <div class="buttonCI terms-condition-modal__button-box">
      <button
        class="btn-open-account open-account terms-condition-modal__confirm"
        (click)="checkCondiciones()"
      >
        ACEPTAR
      </button>
    </div>
  </div>
</modal>

<modal
  [modalTitle]="'Condiciones Legales CI'"
  [blocking]="true"
  [modalId]="modalLegalCI"
  class="modal"
>
  <div class="container">
    <a class="close" (click)="closeModalCi()"></a>
    <p style="font-size: 15px; font-weight: bold">
      Por favor, lee y acepta los siguientes términos y condiciones.
    </p>
    <p style="font-size: 15px; font-weight: bold">
      Los que tiene * son obligatorios para continuar con la contratación :
    </p>
    <div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="isCheckedReactiveCheckConditions" />
        <span class="slider round"></span>
      </label>
      <p>*{{ literals_mod.literal }}</p>
    </div>
    <div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="isCheckedPrivacidad" />
        <span class="slider round"></span>
      </label>
      <p>{{ literals_mod.comunicaciones_comerciales }}</p>
    </div>
    <p class="enlaceModal" (click)="checkCondicionesCI(undefined)">
      CONTINUAR CON LAS OPCIONES SELECCIONADAS
    </p>
    <div class="btn-group">
      <button
        type="button"
        class="btn-common btn-common_fix_bold"
        (click)="checkCondicionesCI(true)"
      >
        SI A TODO
      </button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>

<app-header></app-header>
<div [hidden]="isYoungAccount">
  <product-risk></product-risk>
  <div class="main-register flex-direction welcome">
    <box-image  [sizeBox]="'hideImage'"
                [classImgFile]="'reactive-email-banner'"
                [title]="titleBox"
                [textBox]="'text-reactive-email'"
                [titleCss]="'title-1'"
                class="image-div">
    </box-image>
    <div class="form-and-step-div">
      <steps-ci class="step-component"></steps-ci>
      <hr class="line-separator not-hidden-movil" />
      <div class="form-div">
        <p  class="letter title registerCI"
        [innerHTML]="mainTitleCIMobile"></p>
        <p  class="letter title registerCIDesktop"
            [innerHTML]="mainTitleCIDesktop"></p>
        <p class="letter subtitle-welcome" style="margin-top: 30px;"
        >Antes de empezar recuerda:</p>
        <div class="square-list">
          <ul class="square-ul">
            <li>
              <span class="text-list">
                Debes tributar en España y tener a mano tu <strong>DNI/NIE</strong>. Si eres ciudadano europeo, además necesitarás 
                tu pasaporte o DNI del país de origen.
              </span>
            </li>
            <li>
              <span class="text-list">
                Cuando tu cuenta ya esté abierta, <strong>podrás incluir un segundo titular desde tu app.</strong></span
              >
            </li>
            <li>
              <span class="text-list">Amplía <u class="more-info" (click)="openWelcomePackPromo()">más información</u> sobre la Cuenta Inteligente Bienvenida.</span>
            </li>
          </ul>
        </div>
        <div class="continue-process-information">
          <i class="ic_bombilla_encendida"></i>
          <span>
            Si tienes un <strong>proceso de contratación a medias,</strong> introduce tu número de teléfono y continúa en el punto donde lo dejaste.
          </span>
        </div>
        <p class="go-title">¡Empezamos! Ahora necesitamos tu número de teléfono para saber que eres tú.</p>
        <form class="form-checks" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="emailPhoneForm">
          <div class="floating-placeholder-group row-ci one-line phone-line">
            <input
              type="tel-init-ci"
              required
              #ephone
              no-whitespace
              number-input
              class="tel-init-ci width-input-mb"
              [minLength]="confirmEmailPhone.isPhone ? 8 : 1"
              [maxLength]="confirmEmailPhone.isPhone ? 9 : 9"
              autocomplete="off"
              tabindex="1"
              inputmode="numeric"
              formControlName="reactiveEmailPhone"
              (blur)="phoneTextChangeBlur(emailPhoneForm.get('reactiveEmailPhone'))"
              (input)="phoneTextChange(emailPhoneForm.get('reactiveEmailPhone'))"
              [ngClass]="emailPhoneForm.get('reactiveEmailPhone').value ? 'input-noEmpty' : 'input-empty'"
            />
            <label class="floating-placeholder">Nº de teléfono</label>
            <div class="warnnig-mesage" *ngIf="phoneIncomplete && emailPhoneForm.get('reactiveEmailPhone').value != '888888888'"><!--setFirstTooltip(emailPhoneForm.get('reactiveEmailPhone')) ||-->
              ¡Vaya! Parece que el número de teléfono introducido no es correcto. Tiene que ser un número de teléfono móvil español.
            </div>
            <!-- <div class="warnnig-mesage" *ngIf="(!setFirstTooltip(emailPhoneForm.get('reactiveEmailPhone'))) || phoneEmpty">
              Para comenzar el proceso de contratación necesitamos un número de teléfono móvil.
            </div> -->
          </div>
          <p class="check-sms" *ngIf="emailPhoneForm.get('reactiveEmailPhone').valid ">
            ¡Perfecto! Te enviaremos un código de validación/seguridad a este número de teléfono. ¡Tenlo a mano! Tu número de teléfono no será conservado a menos que nos lo indiques en la siguiente pantalla
          </p>
          <div class="form-checkbox-ci mg-top-2" (click)="changeLegalCondiciones()">
            <input type="checkbox" name="check_conditions" id="check_conditions" value="Acepto" formControlName="reactiveCheckConditions" />
            <label
              class="text-label"
              [ngClass]="{ isWrong: emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked') }"
              tabindex="3"
            >
              <div class="text-publicity">
                <span class="text-check-ci">
                  He leído y acepto los
                  <a (click)="openLegal($event)">Términos y Condiciones de los servicios a distancia EVO (Obligatorio)</a>
                </span>
              </div>
            </label>
          </div>
          <div class="form-checkbox-ci mg-top-2" (click)="changePrivacy()">
            <input
              type="checkbox"
              name="check_publicity"
              id="check_publicity"
              value="Acepto"
              formControlName="reactiveCheckPoliticaPrivacidad"
            />
            <label
              class="text-label"
              [ngClass]="{ isWrong: emailPhoneForm.get('reactiveCheckPoliticaPrivacidad').hasError('notChecked') }"
              tabindex="4"
            >
              <div class="text-publicity">
                <span class="text-check-ci">
                  He leído y acepto la
                  <u (click)="openPrivacy($event)">política de privacidad (Obligatorio)</u>
                </span>
              </div>
            </label>
          </div>
          <div
            class="error-text checks-mandatory-error"
            *ngIf="emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked') || emailPhoneForm.get('reactiveCheckPoliticaPrivacidad').hasError('notChecked')">
            <i class="evo ico-warning-white privacy-modal__icon-warning icon-error"></i> {{ errors.checksMandatoryToContinue }}
          </div>
          <hr class="line-separator hidden-movil" />
          <div class="buttonCI">
            <button type="submit" class="btn-open-account open-account"
              [ngClass]="{ 'welcome__submit--disabled': !emailPhoneForm.valid }">
              <p>ABRIR CUENTA</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <legal-condition [textLegal] = "promoConditionText"></legal-condition>
  <app-footer-center class="hidden-movil"></app-footer-center>
</div>
<div [hidden]="!isYoungAccount">
  <div *ngIf="!isTablet()"><product-risk></product-risk></div>
  <div *ngIf="isTablet()">
    <img class="bannerCJ" src="assets/images/img-banner-1-cj-tablet.png" alt="">
  </div>
  <div class="main-register flex-direction welcome">
    <div *ngIf="!isTablet()">
      <box-image  [classImgFile]="'imagen1-cj'"
        [showTextBox]="false">
      </box-image>
   </div>
    <div class="form-and-step-div">
      <steps-ci [youngAccount]="isYoungAccount" class="step-component"></steps-ci>
      <hr class="line-separator not-hidden-movil" />
      <div class="form-div">
        <div *ngIf="isYoungAccount" class="super-index">
          <p  class="letter title registerCIDesktop"
          [innerHTML]="mainTitleCJDesktop"></p><sup>1</sup>
        </div>
        <p class="letter subtitle-welcome" style="margin-top: 15px;"
        >Antes de empezar recuerda:</p>
        <div class="square-list">
          <ul class="square-ul">
            <li>
              <span class="text-list">
                Debes tener tu <strong>DNI o NIE con foto</strong>, a mano.
              </span>
            </li>
            <li>
              <span class="text-list">
                Debes tributar en España.
                </span
              >
            </li>
            <li>
              <span class="text-list">Solo puedes ser titular de una Cuenta Joven.</span>
            </li>
          </ul>
        </div>
        <div class="continue-process-information">
          <i class="ic_bombilla_encendida"></i>
          <span>
            Si tienes un <strong>proceso de contratación a medias,</strong> introduce tu número de teléfono y continúa en el punto donde lo dejaste.
          </span>
        </div>
        <p class="go-title">¡Empezamos! Ahora necesitamos tu número de móvil para saber que eres tú.</p>
        <form class="form-checks" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="emailPhoneForm">
          <div class="row-ci one-line phone-line-cj">
            <p class="text-phone-box-cj">Nº DE TELÉFONO MOVIL</p>
            <input class="phone-box-cj"
              type="tel-init-cj"
              required
              #ephoneCJ
              no-whitespace
              number-input
              [minLength]="confirmEmailPhone.isPhone ? 8 : 1"
              [maxLength]="confirmEmailPhone.isPhone ? 9 : 9"
              autocomplete="off"
              tabindex="1"
              inputmode="numeric"
              formControlName="reactiveEmailPhone"
              placeholder="Escribe aquí tu número de teléfono móvil"
              (blur)="phoneTextChangeBlur(emailPhoneForm.get('reactiveEmailPhone'))"
              (input)="phoneTextChange(emailPhoneForm.get('reactiveEmailPhone'))"
            />
            <div [ngClass]="phoneIncomplete || isEmptyInput? 'warnnig-mesage-cj-error' : 'warnnig-mesage-cj'"*ngIf="phoneEmpty || phoneIncomplete"><!--setFirstTooltip(emailPhoneForm.get('reactiveEmailPhone')) ||-->
              Para comenzar la contratación necesitamos tu número de móvil.
            </div>
            <!-- <div class="warnnig-mesage" *ngIf="(!setFirstTooltip(emailPhoneForm.get('reactiveEmailPhone'))) || phoneEmpty">
              Para comenzar el proceso de contratación necesitamos un número de teléfono móvil.
            </div> -->
          </div>
          <p class="check-sms" *ngIf="emailPhoneForm.get('reactiveEmailPhone').valid ">
            ¡Perfecto! Te enviaremos un código de validación/seguridad a este número de teléfono. ¡Tenlo a mano!
          </p>
          <div class="form-checkbox-ci mg-top-2" (click)="changeLegalCondiciones()">
            <input type="checkbox" name="check_conditions" id="check_conditions" value="Acepto" formControlName="reactiveCheckConditions" />
            <label
              class="text-label"
              [ngClass]="{ isWrong: emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked') }"
              tabindex="3"
            >
              <div class="text-publicity">
                <span class="text-check-ci">
                  He leído y acepto los
                  <a (click)="openLegal($event)">Términos y Condiciones de los servicios a distancia EVO (Obligatorio)</a>
                </span>
              </div>
            </label>
          </div>
          <div class="form-checkbox-ci mg-top-2" (click)="changePrivacy()">
            <input
              type="checkbox"
              name="check_publicity"
              id="check_publicity"
              value="Acepto"
              formControlName="reactiveCheckPoliticaPrivacidad"
            />
            <label
              class="text-label"
              [ngClass]="{ isWrong: emailPhoneForm.get('reactiveCheckPoliticaPrivacidad').hasError('notChecked') }"
              tabindex="4"
            >
              <div class="text-publicity">
                <span class="text-check-ci">
                  He leído y acepto la
                  <u (click)="openPrivacy($event)">política de privacidad (Obligatorio)</u>
                </span>
              </div>
            </label>
          </div>
          <div
            class="error-text checks-mandatory-error"
            *ngIf="emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked') || emailPhoneForm.get('reactiveCheckPoliticaPrivacidad').hasError('notChecked')">
            <i class="evo ico-warning-white privacy-modal__icon-warning icon-error"></i> {{ errors.checksMandatoryToContinue }}
          </div>
          <hr class="line-separator hidden-movil" />
          <div class="buttonCI">
            <button type="submit" class="btn-open-account open-account"
              [ngClass]="{ 'welcome__submit--disabled': !emailPhoneForm.valid }">
              <p>ABRIR CUENTA</p>
            </button>
          </div>
        </form>
        <div class="super-index-paragraph">
          <p>
            <sub>
              1. Condiciones exclusivas Cuenta Joven EVO. Para disfrutar de todas sus ventajas solo necesitas ser mayor de edad y
              tener menos de 25 años. Sin comisiones<span> TIN 0 % /  TAE 0 %.</span>
            </sub>

          </p>
        </div>
      </div>
    </div>
  </div>
</div>
