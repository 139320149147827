import {
  Component,
  HostListener,
  OnInit,
  ViewContainerRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ESTADO_CIVIL } from "../../../resources/civil-status.model";
import { GENEROS } from "../../../resources/genders.model";
import { PAISES } from "../../../resources/countries.model";
import { REGIMENES } from "../../../resources/regimen-economico.model";
import { DOCUMENTOS } from "../../../resources/document.model";
import { PROVINCES } from "../../../resources/provincias.model";

import { Register } from "../../../collections/register";
import { Persona } from "../../../collections/persona";

import { AuthService } from "../../../services/auth.service";
import { AuthUserService } from "../../../services/auth-user.service";
import { ApiLocalidadesService } from "../../../services/api/api.localidades.service";
import { LocalidadesService } from "../../../services/localidades.service";
import { FormValidatorService } from "../../../services/form-validator.service";
import { ErrorService } from "../../../services/error.service";
import { ModalService } from "../../../services/modal.service";
import { DeviceService } from "../../../services/device.service";
import { JsonService } from "../../../services/json.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { HelperService } from "../../../services/helpers.service";
import { HeaderService } from "../../../services/headers.service";
import { InjectScriptService } from "../../../services/inject-script.service";
import { LoginService } from "../../../services/login.service";
import { PersonaService } from "../../../services/persona.service";

import { Modal } from "app/collections/modal";
import { environment } from "environments/environment";
import { ProductSelection } from "app/services/product-selection.service";
import { ACCOUNTS } from "app/resources/account.model";
import { User } from "../../../collections/user";
import { SpecificAnalyticsService } from "../../../services/specific-analytics.service";
import { SecurityService } from "../../../services/security-registration.service";
import { ERRORSFORM } from "app/resources/errors-form";
import { REGISTERLITERALS } from "app/resources/register-literals";
import { SelectItem } from "primeng/primeng";
import { BUSINESSRELATIONSHIP } from "app/resources/business-relationship.model";

@Component({
  selector: "app-personal-details",
  templateUrl: "./personal-details.component.html",
  styleUrls: ["./personal-details.component.scss"],
  providers: [
    FormValidatorService,
    ModalService,
    DeviceService,
    JsonService,
    LocalidadesService,
    ApiLocalidadesService,
  ],
})
export class PersonalDetailsComponent implements OnInit {
  loading: boolean = false;
  personalDetailsForm: FormGroup;
  personalDetailsValues: Register;
  private persona: Persona = this.authService.getSessionProspect();
  firstElement: any;
  modalLegal = "legal-modal";
  modal = new Modal();
  public bureauModal = "bureauModal";
  alreadyClientModal = "already-client";
  modalLopd = "modal-lopd";
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg =
    "Lo sentimos, no podemos seguir con la solicitud online. Llámanos al " +
    this.telephoneMsg +
    " y te explicamos los motivos.";
  incompleteMsg =
    "Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.";
  public modalBureau = false;
  documents = DOCUMENTOS;
  countries = PAISES;
  genders = GENEROS;
  provinces = PROVINCES;
  civilStatus = ESTADO_CIVIL;
  economicSystems = REGIMENES;
  localizations;
  loginUser: FormGroup;
  errMsg;
  private passStr = "";
  public firstTouch: boolean = true;
  public firstTouchPrevented: boolean = true;
  public isYoungAccount: boolean = false;
  private userData: User;
  modalLog: boolean = false;
  dniExist: boolean = false;
  public idUser: string;
  public mouseover: boolean = false;
  public mostrarFaq: boolean = false;
  //propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep: boolean = true;
  sessionDatosPrimerTitular: any;
  public codigoRetornoDni: number;
  //Variables collapse
  public estadoCollapse0: boolean = false;
  public estadoCollapse1: boolean = false;
  public estadoCollapse2: boolean = false;
  public estadoCollapse3: boolean = false;
  public estadoCollapse4: boolean = false;
  public errors = ERRORSFORM;
  public checks = REGISTERLITERALS["checks"];

  businessRelationship: SelectItem[] = BUSINESSRELATIONSHIP;
  selectedBusinessRelationship: string[] = [];
  items: SelectItem[];

  item: string;

  datosSocioEco = {
    ahorroInversion: "N",
    nominaPensionOtros: "N",
    financiacion: "N",
    actividadProfesionalPBC: "N",
    ServiciosPago: "N",
    OtrosProductos: "N",
  };

  @HostListener("window:beforeunload", ["$event"])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    private viewContainer: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
    private authService: AuthService,
    private authUserService: AuthUserService,
    private loginSrv: LoginService,
    public deviceService: DeviceService,
    private jsonService: JsonService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private localidadsSrv: LocalidadesService,
    private product: ProductSelection,
    private inject: InjectScriptService,
    private personaSrv: PersonaService,
    private securitySrv: SecurityService
  ) {
    this.sortCountries(this.countries);
    this.createForm();
    this.createFormLogin();
    this.initModal();
  }

  setFields(modalLog) {
    const canal = this.deviceService.getDevice(),
      nombreProvinciaNacimiento = this.searchSelectCodeFromValue(
        this.provinces,
        this.personalDetailsForm.get("provincia").value
      );

    if (!modalLog) {
      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        datosPersonales: {
          oficinaCliente: environment.oficina,
          codigoIdExterno: this.isDNI ? "51" : "66",
          idExterno: this.helpers.returnValue(
            this.personalDetailsForm.get("numeroDocumento")
          )
            ? this.helpers
                .returnValue(this.personalDetailsForm.get("numeroDocumento"))
                .toUpperCase()
            : undefined,
          // paisNacionalidad : this.helpers.returnValue(this.personalDetailsForm.get('pais')),
          codigoPaisNacimiento: this.helpers.returnValue(
            this.personalDetailsForm.get("pais")
          ),
          codigoProvincia: this.helpers.returnValue(
            this.personalDetailsForm.get("provincia")
          ),
          nombreProvinciaNacimiento: nombreProvinciaNacimiento
            ? nombreProvinciaNacimiento.name
            : undefined,
          nombreLocalidadNacimiento: this.helpers.returnValue(
            this.personalDetailsForm.get("localidadnacimiento")
          ),
          codigoEstadoCivil: this.helpers.returnValue(
            this.personalDetailsForm.get("estadoCivil")
          ),
          codigoRegimenEconomico: this.helpers.returnValue(
            this.personalDetailsForm.get("regimenEconomico")
          ),
        },
        datosSocioEco: {
          obligacionesFiscales:
            this.helpers.returnValue(
              this.personalDetailsForm.get("obligacionesFiscales")
            ) === "no"
              ? false
              : true,
          ahorroInversion: this.datosSocioEco.ahorroInversion,
          nominaPensionOtros: this.datosSocioEco.nominaPensionOtros,
          financiacion: this.datosSocioEco.financiacion,
          actividadProfesionalPBC: this.datosSocioEco.actividadProfesionalPBC,
          ServiciosPago: this.datosSocioEco.ServiciosPago,
          OtrosProductos: this.datosSocioEco.OtrosProductos
        },
        otrosDatos: {
          indPubliPe:
            this.helpers.returnValue(
              this.personalDetailsForm.get("publicidad")
            ) || false,
        },
        activeOtpFirstStep: this.activeOtpFirstStep,
        indPerfiladoCliente:
          this.helpers.returnValue(
            this.personalDetailsForm.get("reactiveCheckPerfilado")
          ) || false,
        indCesiEmpGrupo:
          this.helpers.returnValue(
            this.personalDetailsForm.get("reactiveCheckCesion")
          ) || false,
      });
      this.setLocalidadName();
    } else {
      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        estado: "cliente-existente",

        datosSocioEco: {
          obligacionesFiscales:
            this.helpers.returnValue(
              this.personalDetailsForm.get("obligacionesFiscales")
            ) === "no"
              ? false
              : true,
        },
      });
    }
  }

  initModal() {
    this.modal = Object.assign({
      title: "¡Vaya!",
      withImage: false,
      message: `Tenemos un problema con tu solicitud online.
      <br>
      Llámanos al <a href='tel:910900900'>910 900 900</a> para que podamos ayudarte a continuar.`,
    });
  }

  ngOnInit() {
    this.modService.open(this.bureauModal);
    this.idUser = JSON.parse(
      sessionStorage.getItem("currentProspect")
    ).datosPersonales.id_cliente_EVO;
    this.localidades();
    this.errService.mobileErrors(this.personalDetailsForm);
    this.firstElement = this.documents[0];
    this.validateDocuments(this.personalDetailsForm.get("documento").value);
    //control if the users has sent the id (email|phone)
    this.isYoungAccount =
      location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 ||
      this.authService.product === ACCOUNTS.CJCode ||
      location.host === environment.hostYoungAccount;
  }

  localidades(cb?) {
    this.localidadsSrv.getLocalidadesData().subscribe(
      (data) => {
        this.localizations = data.localidades;
        return cb;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  goToMap() {
    window.open("https://www.evobanco.com/donde-estamos/");
  }

  filterValidControls(form) {
    const validControls = Object.keys(form.controls).filter((control) => {
      if (control == "numeroDocumento") return false;
      return form.controls[control].valid && !form.controls[control].untouched;
    });
  }

  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, "user", [
      Validators.required,
      Validators.minLength(9),
    ]);
    this.helpers.updateFieldValidation(this.loginUser, "pass", [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get("pass");
    const userInput = this.loginUser.get("user");
    if (userInput.invalid || passInput.invalid) {
      return;
    }

    const login: string = `username=${userInput.value.toUpperCase()}&password=${
      passInput.value
    }`;

    this.loading = true;
    const tag = "GTM-TFMGJVG";
    this.inject.addScriptGoogle("script", tag);
    //this.inject.addScriptGoogle('noscript', tag);
    this.authService.login(login).subscribe(
      (data) => {
        //this.loginSrv.getLogin(login).subscribe(data => {
        //this.loadUserData();
        //const userData = this.loginSrv.userData;
        //console.log("----DATA", this.userData);

        if (this.loginSrv.isLoggedIn) {
          if (
            !this.helpers.isEmpty(this.loginSrv.userData) &&
            !sessionStorage.getItem("reloadUserData")
          ) {
            this.userData = this.loginSrv.userData;
            //console.log("USERDATA", this.userData);
            //return
            this.redirectLog();
          } else {
            sessionStorage.removeItem("reloadUserData");
            this.authUserService.getUserData().subscribe(
              (data) => {
                this.userData = data;
                //console.log("USERDATA2", this.userData);
                this.loginSrv.userData = this.userData;
                this.redirectLog();
              },
              (error) => {
                this.errService.rsi = true;
                this.errService.navToError();
              }
            );
          }
        } else {
          this.helpers.navigateTo("/login");
        }
      },
      (error) => {
        this.loading = false;
        this.errMsg = `Parece que hay algún error, comprueba que has introducido
      correctamente los datos. Recuerda que por seguridad,
      tres errores consecutivos en la contraseña bloquearán el acceso`;
        if (passInput.value.length === 6) {
          this.helpers.updateFieldValue(this.loginUser, "pass", "");
        }
      }
    );
  }

  redirectLog() {
    if (
      this.product.product &&
      this.product.product.codProductoContratado === ACCOUNTS.STCode
    ) {
      const listaContratados = this.userData.listaContratados;
      for (const product of listaContratados) {
        if (product.idProducto === ACCOUNTS.CICode) {
          //this.loading = false;
          //this.errService.errUsername = this.userData.persona.nombreCliente;
          this.errService.isTitularInteligente = true;
          this.errService.navToError();
          break;
        }
        if (product.idProducto === ACCOUNTS.CJCode) {
          //this.loading = false;
          //this.errService.errUsername = this.userData.persona.nombreCliente;
          this.errService.isTitularJoven = true;
          this.errService.navToError();
          break;
        }
        this.helpers.navigateTo("confirmacion-datos");
      }
      // if anidado
      // data.listacontratados.productos
      //this.errService;
    } else {
      this.helpers.navigateTo("perfil");
      /*
      this.errService.errUsername = this.userData.persona.nombreCliente;
      this.errService.isTitularJoven = true;
      this.errService.navToError();
      */
    }
  }

  loadUserData() {
    if (this.loginSrv.isLoggedIn) {
      this.loading = true;
      if (
        !this.helpers.isEmpty(this.loginSrv.userData) &&
        !sessionStorage.getItem("reloadUserData")
      ) {
        this.loading = false;
        this.userData = this.loginSrv.userData;
        console.log("USERDATA", this.userData);
        return;
      }
      sessionStorage.removeItem("reloadUserData");
      this.authUserService.getUserData().subscribe(
        (data) => {
          this.loading = false;
          this.userData = data;
          console.log("USERDATA2", this.userData);
          this.loginSrv.userData = this.userData;
        },
        (error) => {
          this.errService.rsi = true;
          this.errService.navToError();
        }
      );
    } else {
      this.helpers.navigateTo("/login");
    }
  }

  validUser() {
    const userInput = this.loginUser.get("user");
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, "user", [
        Validators.required,
        Validators.minLength(9),
      ]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [""],
      pass: [""],
    });

    this.loginUser.get("user").valueChanges.subscribe((user) => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ["user"]);
      }
    });

    this.loginUser.valueChanges.subscribe((loginUser) => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get("pass").value.length < inputMaxLength) {
      this.passStr =
        this.loginUser.get("pass").value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, "pass", []);
      this.helpers.updateFieldValue(this.loginUser, "pass", this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, "pass", [
        Validators.required,
        Validators.minLength(6),
      ]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, "user", []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, "pass", this.passStr);
  }

  hasNoPassword() {
    // mail with pass
    this.modService.close(this.alreadyClientModal);
  }

  isNotMe() {
    // mail user
    this.modService.close(this.alreadyClientModal);
  }

  sortCountries(arrayCountries) {
    arrayCountries.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    const countryLiteral = arrayCountries.filter((obj) => {
      return obj.value === null;
    });
    const spain = arrayCountries.filter((obj) => {
      return obj.name === "ESPAÑA";
    });
    arrayCountries.splice(arrayCountries.indexOf(spain[0]), 1);
    arrayCountries.unshift(spain[0]);
    arrayCountries.splice(arrayCountries.indexOf(countryLiteral[0]), 1);
    arrayCountries.unshift(countryLiteral[0]);
  }

  triggerErrorAnalytics() {
    const invalidFields = this.errService.errorsArray.map((error) => {
      return (error = error.text);
    });
    const invalidString = invalidFields.join(", ");

    if (invalidString !== "")
      return;
  }

  onSubmit() {
    //Se comprueba si el formulario es válido antes de hacer las llamadas a Bureaus
    if (!this.personalDetailsForm.valid) {
      this.modal = Object.assign({
        title: "¡Ups!",
        withImage: true,
        blocking: false,
        message: this.incompleteMsg,
      });

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    this.loading = true;
    this.fillSocioeconomicData();
    this.helpers.updateFormStatus(this.personalDetailsForm);
    this.authService.updateProspect(this.persona).subscribe((data) => {
      if (
        this.personalDetailsForm.get("numeroDocumento").value == "00000000T" &&
        JSON.parse(sessionStorage.getItem("currentProspect")).datosPersonales
          .telefonoMovil === "888888888"
      ) {
        this.continuarSubmit();
      } else if (data.response.codigo === "1003") {
        this.loading = false;
        this.dniExist = true;
        this.modalBureau = true;
        window.scrollTo(0, 0);
        //this.modService.open(this.alreadyClientModal);
      } else if (
        data.response.codigo !== "1003" ||
        this.personalDetailsForm.get("numeroDocumento").value !== "00000000T"
      ) {
        this.dniExist = false;
        this.continuarSubmit();
      }
    });
  }

  fillSocioeconomicData() {
    
    let selectedData = this.personalDetailsForm.get(
      "businessRelationship"
    ).value;

    if (selectedData.length) {
      selectedData.forEach((selected) => {
        switch (selected) {
          case "Ahorro":
            this.datosSocioEco.ahorroInversion = "S";
            break;
          case "Nómina":
            this.datosSocioEco.nominaPensionOtros = "S";
            break;

          case "Financiación":
            this.datosSocioEco.financiacion = "S";
            break;
          case "Actividad Profesional":
            this.datosSocioEco.actividadProfesionalPBC = "S";
            break;

          case "Servicio de Pago":
            this.datosSocioEco.ServiciosPago = "S";
            break;

          case "Otros":
            this.datosSocioEco.OtrosProductos = "S";
            break;
        }
      });
    } else {
      this.personalDetailsForm.controls["businessRelationship"].setValue("");
      this.personalDetailsForm.controls["businessRelationship"].markAsTouched();
    }
    return this.datosSocioEco;
  }
  continuarSubmit() {
    const estadosUnidos = "400",
      españa = "011",
      incompleteModal = {
        title: "¡Ups!",
        blocking: false,
        withImage: true,
        message: this.incompleteMsg,
      };
    if (
      this.personalDetailsForm.get("pais").value === españa &&
      this.personalDetailsForm.get("localidadnacimiento").value === ""
    ) {
      this.helpers.updateFieldValue(
        this.personalDetailsForm,
        "localidadnacimiento",
        this.setLocalidadName()
      );
    }
    this.helpers.updateFormStatus(this.personalDetailsForm);
    this.triggerErrorAnalytics();

    if (
      this.personalDetailsForm.get("pais").value === estadosUnidos ||
      this.personalDetailsForm.get("obligacionesFiscales").value === "si"
    ) {
      this.loading = false;
      this.modal = Object.assign({
        title: "¡Vaya!",
        message: this.exceptionMsg,
        blocking: false,
        withImage: true,
      });

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    const numeroDocumento = this.personalDetailsForm.get("numeroDocumento");

    if (
      numeroDocumento &&
      (numeroDocumento.value.length !== 9 ||
        this.isValidID(numeroDocumento.value) === false)
    ) {
      this.loading = false;
      this.personalDetailsForm
        .get("numeroDocumento")
        .setErrors({ valid: false });

      this.modal = Object.assign(incompleteModal);
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.personalDetailsForm.valid) {
      this.loading = false;
      this.modal = Object.assign(incompleteModal);
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    this.sendProspect(this.modalLog);
  }

  sendProspect(modalLog) {
    this.loading = true;
    this.setFields(modalLog);
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        if (
          this.personalDetailsForm.get("numeroDocumento").value == "00000000T"
        ) {
          this.continueRegister();
        } else {
          if (data.response.codigo !== "OK") {
            if (data.response.codigo === "1003") {
              this.modalBureau = true;
              window.scrollTo(0, 0);
            } else {
              const modalToShow = this.modService.selectStatusModal(
                data.response
              );
              this.modal = modalToShow;
              this.modService.open(this.modal.name, this.modal.blocking);
            }
          } else {
            this.continueRegister();
          }
        }
      },
      (error) => {
        this.handleError();
      }
    );
  }

  continueRegister() {
    const nextStep = "/datos-de-contacto";
    this.authService.pageSubmitted = "datos-personales";
    if (this.dniExist == false) {
      this.helpers.navigateTo(nextStep);
    }
  }

  private createForm() {
    const clienteRelacionesCanceladas: boolean =
      JSON.parse(sessionStorage.getItem("currentProspect")).claseDeCliente ===
      "03";

    this.personalDetailsForm = this.fb.group({
      documento: [this.setInitialValueDocument(), [Validators.required]],
      numeroDocumento: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.idExterno"
            ) || "",
        [Validators.required],
      ],
      estadoCivil: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.codigoEstadoCivil"
            ) || "",
        [Validators.required],
      ],
      pais: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.codigoPaisNacimiento"
            ) || null,
        [Validators.required],
      ],
      provincia: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.codigoProvincia"
            ) || null,
      ],
      localidadnacimiento: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.nombreLocalidadNacimiento"
            ) || "",
        [
          Validators.required,
          Validators.pattern("(?:(?![×Þß÷þø])[-A-Za-zÀ-ÿ/() .'·])+"),
        ],
      ],
      obligacionesFiscales: ["no", [Validators.required]],
      regimenEconomico: [
        clienteRelacionesCanceladas
          ? ""
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "datosPersonales.codigoRegimenEconomico"
            ) || "",
      ],
      reactiveCheckPerfilado: [
        clienteRelacionesCanceladas
          ? false
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "indPerfiladoCliente"
            ) || false,
      ],
      reactiveCheckCesion: [
        clienteRelacionesCanceladas
          ? false
          : this.jsonService.getKeyFromJSON(this.persona, "indCesiEmpGrupo") ||
            false,
      ],
      publicidad: [
        clienteRelacionesCanceladas
          ? false
          : this.jsonService.getKeyFromJSON(
              this.persona,
              "otrosDatos.indPubliPe"
            ) || false,
      ],
      businessRelationship: [this.loadAccountPurposeData(),
        [Validators.required],]
    });

    this.formSubscribe();
  }
  loadAccountPurposeData() {

    const datosSocioEco = {
      ahorroInversion: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.ahorroInversion"
      ),
      nominaPensionOtros: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.nominaPensionOtros"
      ),
      financiacion: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.financiacion"
      ),
      actividadProfesionalPBC: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.actividadProfesionalPBC"
      ),
      ServiciosPago: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.ServiciosPago"
      ),
      OtrosProductos: this.jsonService.getKeyFromJSON(
        this.persona,
        "datosSocioEco.OtrosProductos"
      ),
    };

    const purposeData = [];

    if (datosSocioEco.ahorroInversion === "S") {
      purposeData.push("Ahorro");
    }
    if (datosSocioEco.nominaPensionOtros === "S") {
      purposeData.push("Nómina");
    }
    if (datosSocioEco.financiacion === "S") {
      purposeData.push("Financiación");
    }
    if (datosSocioEco.actividadProfesionalPBC === "S") {
      purposeData.push("Actividad Profesional");
    }
    if (datosSocioEco.ServiciosPago === "S") {
      purposeData.push("Servicio de Pago");
    }
    if (datosSocioEco.OtrosProductos === "S") {
      purposeData.push("Otros");
    }
    this.selectedBusinessRelationship = purposeData;
  }
  setInitialValueDocument() {
    let initValue = "dni";
    if (this.persona.datosPersonales) {
      if (this.persona.datosPersonales.codigoIdExterno === "66") {
        initValue = "nie";
      }
    }
    return initValue;
  }

  private formSubscribe() {
    const that = this;

    this.personalDetailsForm
      .get("obligacionesFiscales")
      .valueChanges.subscribe((documento) => {
        if (documento === "si") {
          // this.hasInvalidFiscalObligations();
          this.modal = Object.assign({
            title: "¡Vaya!",
            withImage: true,
            blocking: false,
            message: this.exceptionMsg,
          });
          this.modService.open(this.modal.name);
        }
      });

    this.personalDetailsForm
      .get("numeroDocumento")
      .valueChanges.subscribe((numero) => {
        if (numero.length !== 9) return;
        if (this.isValidID(numero) === false) {
          this.personalDetailsForm
            .get("numeroDocumento")
            .setErrors({ valid: false });
        }
        if (this.isDNI) this.validateDocuments("dni");
        if (!this.isDNI) this.validateDocuments("nie");
      });

    this.personalDetailsForm
      .get("estadoCivil")
      .valueChanges.subscribe((eCivil) => {
        if (!this.isMarried) {
          this.helpers.updateFieldValue(
            this.personalDetailsForm,
            "regimenEconomico"
          );
          this.helpers.updateFieldValidation(
            this.personalDetailsForm,
            "regimenEconomico"
          );
        } else {
          this.helpers.updateFieldValidation(
            this.personalDetailsForm,
            "regimenEconomico",
            [Validators.required]
          );
        }
        this.helpers.resetFieldsState(this.personalDetailsForm, [
          "regimenEconomico",
        ]);
      });

    this.personalDetailsForm
      .get("provincia")
      .valueChanges.subscribe((provincia) => {
        this.helpers.updateFieldValue(
          this.personalDetailsForm,
          "localidadnacimiento",
          this.setLocalidadName()
        );
      });

    this.personalDetailsForm.get("pais").valueChanges.subscribe((pais) => {
      this.evaluateCountry(pais);
      this.helpers.updateFieldValue(this.personalDetailsForm, "provincia");
      this.helpers.updateFieldValue(
        this.personalDetailsForm,
        "localidadnacimiento"
      );
    });
    this.personalDetailsForm.valueChanges.subscribe((form) => {
      const numDocumento =
        this.personalDetailsForm.get("numeroDocumento").value;
      if (this.isValidID(numDocumento)) {
        this.authService.saveUpdatedForm(this.personalDetailsForm, this, form);
      }
      this.errService.mobileErrors(this.personalDetailsForm);
    });
    this.personalDetailsForm.get('businessRelationship').valueChanges.subscribe((selectData) => {
      selectData.forEach(element => {
        switch (element) {
          case "Ahorro":
            this.datosSocioEco.ahorroInversion = "S";
            break;
          case "Nómina":
            this.datosSocioEco.nominaPensionOtros = "S";
            break;
          case "Financiación":
            this.datosSocioEco.financiacion = "S";
            break;
          case "Actividad Profesional":
            this.datosSocioEco.actividadProfesionalPBC = "S";
            break;
          case "Servicio de Pago":
            this.datosSocioEco.ServiciosPago = "S";
            break;
          case "Otros":
            this.datosSocioEco.OtrosProductos = "S";
            break;
        }
      });
    })
  }

  evaluateCountry(pais) {
    const estadosUnidos = "400",
      españa = "011";

    switch (pais) {
      case estadosUnidos:
        this.modal = Object.assign({
          title: "¡Vaya!",
          withImage: true,
          message: this.exceptionMsg,
        });
        this.modService.open(this.modal.name);
        break;
      case españa:
        this.helpers.updateFieldValidation(
          this.personalDetailsForm,
          "localidadnacimiento"
        );
        this.helpers.updateFieldValidation(
          this.personalDetailsForm,
          "provincia",
          [Validators.required]
        );
        break;
      default:
        this.helpers.updateFieldValidation(
          this.personalDetailsForm,
          "localidadnacimiento",
          [
            Validators.required,
            Validators.pattern("(?:(?![×Þß÷þø])[-A-Za-zÀ-ÿ/() .'·])+"),
          ]
        );
        this.helpers.updateFieldValidation(
          this.personalDetailsForm,
          "provincia"
        );
        this.helpers.updateFieldValue(this.personalDetailsForm, "provincia");
        break;
    }
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "localidadnacimiento"
    );
    this.helpers.resetFieldsState(this.personalDetailsForm, [
      "provincia",
      "localidadnacimiento",
    ]);
  }

  validateDocuments(documento) {
    const numDocumento = this.personalDetailsForm.get("numeroDocumento");

    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "documento",
      documento
    );

    if (
      numDocumento.value.length === 9 &&
      this.isValidID(numDocumento.value) === true
    ) {
      this.checkDNI(numDocumento);
    }
  }

  hasInvalidFiscalObligations() {
    this.persona = Object.assign({
      datosSocioEco: {
        obligacionesFiscales: true,
      },
    });
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
      },
      (error) => {
        this.handleError();
      }
    );
  }

  checkDNI(documento) {
    const consulta = {
      EE_I_Consulta: {
        codigoEntidad: "0239",
        idExterno: documento.value,
        idCliente: this.idUser,
      },
    };
    this.loading = true;
    this.setFields(this.modalLog);
    this.authService.checkDuplicateDNI(consulta).subscribe(
      (data) => {
        this.codigoRetornoDni = data.codigoRetorno;
        if (this.codigoRetornoDni == undefined) {
          this.handleError();
        } else {
          if (
            Number(data.codigoRetorno) === 1 &&
            documento.value !== "00000000T"
          ) {
            if (
              this.persona.datosPersonales.id_cliente_EVO !== data.idCliente
            ) {
              this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
              this.idUser = data.idCliente;
              this.authService.setSessionProspect(this.persona);
              if (data.access_token && data.refresh_token) {
                sessionStorage.setItem("auth_token", data.access_token);
                sessionStorage.setItem("refresh_token", data.refresh_token);
              }
            }
            this.dniExist = true;
            this.modalLog = true;
            this.sendProspect(this.modalLog);
            this.initModal();
            this.modal.blocking = false;
            this.modal.withImage = true;
            this.modService.open(this.alreadyClientModal);
          } else if (data.lopd) {
            this.modService.open(this.modalLopd);
          } else if (data.claseCliente === "03") {
            if (
              this.persona.datosPersonales.id_cliente_EVO !== data.idCliente
            ) {
              this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
              this.idUser = data.idCliente;
              this.authService.setSessionProspect(this.persona);
              // this.updatePersonData(data.idCliente);
            }
          } else {
            this.dniExist = false;
            this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
            this.authService.setSessionProspect(this.persona);
            if (data.access_token && data.refresh_token) {
              sessionStorage.setItem("auth_token", data.access_token);
              sessionStorage.setItem("refresh_token", data.refresh_token);
            }
          }
          this.loading = false;
        }
      },
      (error) => {
        this.authService.pageSubmitted = "";
        this.handleError();
      }
    );
  }

  handleError() {
    this.loading = false;
    this.errService.navToError();
  }

  setPersonalDetailFields() {
    const canal = this.deviceService.getDevice();
    const nombreProvinciaNacimiento = this.searchSelectCodeFromValue(
      this.provinces,
      this.personalDetailsForm.get("provincia").value
    );
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
        codigoIdExterno: this.isDNI ? "51" : "66",
        idExterno: this.personalDetailsForm
          .get("numeroDocumento")
          .value.toUpperCase(),
        // paisNacionalidad : this.helpers.returnValue(this.personalDetailsForm.get('pais')),
        codigoPaisNacimiento: this.helpers.returnValue(
          this.personalDetailsForm.get("pais")
        ),
        codigoProvincia: this.helpers.returnValue(
          this.personalDetailsForm.get("provincia")
        ),
        nombreProvinciaNacimiento: nombreProvinciaNacimiento
          ? nombreProvinciaNacimiento.name
          : undefined,
        nombreLocalidadNacimiento: this.helpers.returnValue(
          this.personalDetailsForm.get("localidadnacimiento")
        ),
        codigoEstadoCivil: this.helpers.returnValue(
          this.personalDetailsForm.get("estadoCivil")
        ),
        codigoRegimenEconomico: this.helpers.returnValue(
          this.personalDetailsForm.get("regimenEconomico")
        ),
      },
      datosSocioEco: {
        obligacionesFiscales:
          this.helpers.returnValue(
            this.personalDetailsForm.get("obligacionesFiscales")
          ) === "no"
            ? false
            : true,
      },
      otrosDatos: {
        indPubliPe: this.helpers.returnValue(
          this.personalDetailsForm.get("publicidad")
        ),
      },
      activeOtpFirstStep: this.activeOtpFirstStep,
    });
    this.setLocalidadName();
  }

  setLocalidadName() {
    if (this.localizations) {
      if (this.persona.datosPersonales.codigoProvincia) {
        const provincia = this.persona.datosPersonales.codigoProvincia;
        const localidad = this.localizations.filter((localidad) => {
          return (
            localidad.value === this.persona.datosPersonales.codigoProvincia
          );
        });
        this.persona.datosPersonales.nombreLocalidadNacimiento =
          localidad[0].name;
        return localidad[0].name;
      }
    } else {
      this.localidades((cb) => {
        this.setLocalidadName();
      });
    }
  }

  get isDNI() {
    const reg = /^\d+$/;
    return reg.test(this.personalDetailsForm.get("numeroDocumento").value[0]);
  }

  get isMarried() {
    return (
      String(
        this.personalDetailsForm.get("estadoCivil").value
      ).toUpperCase() === "05"
    );
  }

  get getDocumentType() {
    return this.personalDetailsForm.get("documento").value === "dni"
      ? "51"
      : "66";
  }

  get hideDNI() {
    return this.getDocumentType === "51";
  }

  get isSpain() {
    return this.personalDetailsForm.get("pais").value === "011";
  }

  get isCountrySelected() {
    return this.personalDetailsForm.get("pais").value !== null;
  }

  get hideNIF() {
    return this.getDocumentType === "66";
  }

  modalClick(_ev) {
    _ev.stopPropagation();
  }

  closeModal() {
    this.modalBureau = false;
    this.helpers.navigateTo("/bienvenido");
  }

  searchSelectCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item["value"] === value && item["value"] !== null) {
        name = item;
      }
    });
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item["selected"] && item["value"] !== null) {
        name = item;
      }
    });
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  private isValidID(value) {
    const invalid = false,
      validChars = "TRWAGMYFPDXBNJZSQVHLCKET",
      nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
      str = value.toString().toUpperCase(),
      nie = str.replace(/^[X]/, "0").replace(/^[Y]/, "1").replace(/^[Z]/, "2"),
      letter = str.substr(-1),
      charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (str.length !== 9) return invalid;

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return invalid;
    }

    if (validChars.charAt(charIndex) === letter) {
      return true;
    }

    return invalid;
  }
  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? "móvil" : "correo";
  }

  selectCheckCesion(e) {
    if (e.key !== " ") {
      return;
    }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "reactiveCheckCesion",
      newValue
    );
  }

  selectCheckPerfilado(e) {
    if (e.key !== " ") {
      return;
    }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "reactiveCheckPerfilado",
      newValue
    );
  }

  selectCheckPubli(e) {
    if (e.key !== " ") return;
    e.preventDefault();
    this.helpers.updateFieldValue(
      this.personalDetailsForm,
      "publicidad",
      !e.currentTarget.parentElement.firstElementChild.checked
    );
  }

  /**
   * Si existen datos en la sesión del primer titular devuelve un true, sino devuelve un false
   */
  existFirstTitular() {
    this.sessionDatosPrimerTitular = JSON.parse(
      sessionStorage.getItem("currentProspect")
    ).datosPrimerTitular;
    if (this.sessionDatosPrimerTitular) {
      return true;
    } else {
      return false;
    }
  }

  // public onMouseOut() {
  //   this.mouseover = false;
  //   this.modal.name="modalFaq";
  //   this.modService.close(this.modal.name);
  //   this.mostrarFaq = false;
  // }

  // public collapse(event){
  //   event.currentTarget
  //     this.estadoCollapse = !this.estadoCollapse;
  //     console.log("estadoCollapse: ", this.estadoCollapse);
  // }

  public onMouseClick() {
    this.closeQuestCollapse();
    this.modService.open("faqModal");
  }

  public onMouseOver() {
    this.closeQuestCollapse();
    this.modService.open("faqModal");
  }

  public closeQuestCollapse() {
    this.estadoCollapse0 = false;
    this.estadoCollapse1 = false;
    this.estadoCollapse2 = false;
    this.estadoCollapse3 = false;
    this.estadoCollapse4 = false;
  }

  /**Devuelve true si es CI, false en caso contrario */
  public esCuentaInteligente() {
    if (
      this.persona.logalty &&
      this.persona.logalty.codProductoContratado == "006"
    ) {
      return true;
    } else {
      return false;
    }
  }



  closeModalLopd() {
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      },
      (error) => {
        this.loading = false;
        window.location.href = "https://www.evobanco.com/";
      }
    );
  }

  goToBE() {
    window.location.href = "https://evobanco.com/";
  }
}
