<div class="container">
    <div class="column-a">
        <fixed-step></fixed-step>
    </div>
    <div class="column-b">
        <description-text [ciName]="completeName"
                          (beginEvent)="begin($event)"></description-text>
    </div>
    <div class="column-c">
        <image-welcome></image-welcome>
    </div>
</div>