import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as sha256 from 'crypto-js/sha256';


@Injectable()
export class SpecificAnalyticsService {

  registerTypeBroadcast: BehaviorSubject<string> = new BehaviorSubject<string>('');
  identificationTypeBroadcast: BehaviorSubject<string> = new BehaviorSubject<string>('');
  errorPageBroadcast: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  idLeadBroadcast: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userIdBroadcast: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  emitUserId(userId) {
    this.userIdBroadcast.next(this._hashId(userId).toString());
  }

  emitRegisterType(type) {
    this.registerTypeBroadcast.next(type);
  }

  emitIdentificationType(type) {
    this.identificationTypeBroadcast.next(type);
  }

  emitErrorPage(error) {
    this.errorPageBroadcast.next(error);
  }

  emitIdLead(hashId) {
    this.idLeadBroadcast.next(hashId);
  }

  private _hashId(email) {
      return sha256(email);
    }

}
