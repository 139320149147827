<div>
  <p class="welcome-title">¡Bienvenido!</p>
  <p class="welcome-sub-title">Estás a punto de tener <span class="bold">la Cuenta Inteligente de {{ciName}}.</span> Recuerda que necesitas:</p>
  <div class="square-list">
    <ul class="square-ul">
      <li>
        <span class="text-list">
          Tener a mano tu DNI o NIE con foto.
        </span>
      </li>
      <li>
        <span class="text-list">
          Tener un número de teléfono móvil español.
        </span>
      </li>
      <li>
        <span class="text-list">Tributar en España.</span>
      </li>
    </ul>
  </div>
  <p class="welcome-sub-title"><span class="bold">Y EMPIEZA A DISFRUTAR DE TODAS SUS VENTAJAS.</span></p>
  <div class="button-container">
    <hr class="linea-separacion" />
    <button type="submit" 
            class="button-style"
            (click)="startProcess()">
              <p class="text-button">COMENZAR</p>
    </button>
  </div>
</div>