import { Injectable, EventEmitter, HostListener, Renderer2, RendererFactory2 } from '@angular/core';
import { ApiBySideCarService } from './api/api.bysidecar.service';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConstantsService } from './constants.service';

@Injectable()
export class BySideCarService {
  private maxTimeIdle = 3 * ConstantsService.OneMinuteMillis;
  renderer: Renderer2;
  private activityTimeoutShow = false;
  private inactiveUser = true;
  private activityTimeout = null;
  public isCampanyaCaptacion: boolean = location.href.indexOf('codRecommendation') !== -1;
  public isAltaClienteTipoA: boolean = (this.authService.product === ACCOUNTS.TipoACode);
  public eventShowModal: Subject<any> = new Subject();

  constructor(
    private api: ApiBySideCarService,
    private authService: AuthService,
    private router: Router,
    private rendererFactory2: RendererFactory2
     ) {
      this.renderer = this.rendererFactory2.createRenderer(null, null);
      this.renderer.listen('document', 'mousemove', (evt) => {
        this.checkInactivity();
      });
      this.renderer.listen('document', 'keypress', (evt) => {
        this.checkInactivity();
      });
      this.renderer.listen('document', 'touchmove', (evt) => {
        this.checkInactivity();
      });
  }

  checkInactivity(){
    if (this.activityTimeout) {
      this.inactiveUser = false;
      clearTimeout(this.activityTimeout);
    }
    if (this.activityTimeoutShow === false) {
      this.activityTimeout = setTimeout(() =>  {
        this.inactiveUser = true;
        this.checkTotalActivity();
      }, this.maxTimeIdle);
    }
  }

  initTimeInactivity(time){
    //Tiempo en minutos
    this.maxTimeIdle = time * ConstantsService.OneMinuteMillis;
    // console.log( 'initTimeInactivity', time);
    if(!this.isCampanyaCaptacion) {
      this.isCampanyaCaptacion =  location.href.indexOf('codRecommendation') !== -1;
    }
    if(!this.isAltaClienteTipoA) {
      this.isAltaClienteTipoA =  (this.authService.product === ACCOUNTS.TipoACode);
    }
    if (!this.isCampanyaCaptacion && !this.activityTimeoutShow && !this.isAltaClienteTipoA) {
      this.inactiveUser = true;
      this.activityTimeout = null;
      this.checkInactivity();
    }
  }

  checkTotalActivity() {
    if (this.inactiveUser === true) {
      this.activityTimeoutShow = true;
      //Linea que muestra la modal
      if(this.showCallInfo()){
        this.eventShowModal.next();
      }
    }
  }

  callUser(): Observable<any> {
    return this.api.getCalled();
  }

  cscService(phone,name, token){
    const userInfo = {
      APhone: phone,
      AName: name,
      AServiceId: "11312",
      ALoadId: "51997",
      AScheduleDate: new Date().toJSON().slice(0,20),
      AStatus:"1"
    }
    return this.api.c2cService(userInfo ,token);
  }
  cancelTimeOut(){
    clearTimeout(this.activityTimeout);
  }

  showCallInfo(){
    const currentRoute = this.router.url;
    if(currentRoute === '/datos-personal' || currentRoute === '/datos-contacto' || currentRoute === '/datos-laboral' ){
      return false;
    }
    return true;
  }
}
