import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { AuthTokenService } from '../token/auth.token.service';
import { InterceptorErrorSrv } from './interceptor.errors.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { ErrorService } from 'app/services/error.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ((request['url'].indexOf('/login_be') !== -1)) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      });
    }
    else if ((request['url'].indexOf('/auth/oauth/v2/token') !== -1)) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      });
    } else if ((request['url'].indexOf('/mfaServices') !== -1)) {
      const token = this.auth.getToken();
      if (token) {
        if (sessionStorage.getItem('microRegistryCall1')) {
          const param = JSON.parse(sessionStorage.getItem('microRegistryCall1'))['mfaAmount'];
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'mfaAmount': param
            }
          });
        } else if (sessionStorage.getItem('microRegistryCall2')) {

          const mfaId = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaId'];
          const mfaValidationData = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaValidationData'];
          const mfaAmount = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaAmount'];

          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'mfaId': mfaId,
              'mfaValidationData': mfaValidationData,
              'mfaAmount': mfaAmount
            }
          });
        }
      }
    } else if ((request['url'].indexOf('api.ipify.org') !== -1)) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      });
    } else {
      const token = this.auth.getToken();
      if (token) {
        if(request['url'].indexOf('/v1/createlink') !== -1){
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Headers': 'Content-Type',
              Authorization: `Bearer ${token}`
            }
          });
        }else if(request['url'].indexOf('/v1/shortLinks') !== -1){
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Headers': 'Content-Type',
             // Authorization: `Bearer ${token}`
            }
          });
        }else if(request['url'].indexOf('/utilities/qr') !== -1){
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Headers': 'Content-Type',
              Authorization: `Bearer ${token}`
            }
          });
        } else if (request['url'].indexOf('/assets') !== -1) {
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          });
        } else {
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}

@Injectable()
export class RegisterInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthTokenService,
    public interceptErr: InterceptorErrorSrv
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request['url'];
    return next.handle(request).do((res: HttpEvent<any>) => {
      if (res instanceof HttpResponse) {
        let bearer, refreshToken;
        if (res.body['auth_token']) {
          bearer = res.body['auth_token']
        } 
        else if (res.body['access_token']) { //GENERATE OTP
          bearer = res.body['access_token']
        }
        else if (res.body['fullonlineToken']){ //VALIDATE OTP
          bearer = res.body['fullonlineToken']
          refreshToken = res.body['refreshToken'];
        }
        else {
          bearer = (res.headers.getAll('fullonline_token')) ? res.headers.getAll('fullonline_token')[0] : false;
        }
        if (bearer) {
          this.auth.setToken(bearer);
          if(res.body.scope !=='fullonline_client' && res.body.scope !=='clicktocall'){
            const refresh_token = refreshToken? refreshToken : (res.body['refresh_token']) ? res.body['refresh_token'] : res.headers.getAll('refresh_token')[0];
            this.auth.setRefreshToken(refresh_token);
          }
          return next.handle(request);
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.auth.isUnauth = true;
        }
        if (url.indexOf('mpm') >= 0) {
          this.interceptErr.mpmError = true;
        }
      }
    });
  }
}
