<loading [showMe]="loading"></loading>
<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray"
>
</mobile-error-zone>

<modal
  [modalId]="alreadyClientModal"
  [blocking]="true"
  class="modal modal-login"
>
  <mobile-error-zone
    *ngIf="errService.errorsArray.length > 0"
    [errors]="errService.errorsArray"
    class="custom-erros"
  >
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <form
      (keydown.enter)="$event.preventDefault()"
      [formGroup]="loginUser"
      (ngSubmit)="sendClient()"
      novalidate
    >
      <div class="equal-buttons">
        <input
          placeholder="Usuario (NIF/NIE)"
          type="text"
          name="user"
          formControlName="user"
          (focus)="closeKeyboard()"
          (blur)="validUser()"
          autocomplete="off"
          maxlength="9"
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(loginUser.get('user'), 'user')"
        >
          {{ errService.desktopErrors(loginUser.get("user"), "user") }}
        </div>
        <input
          placeholder="Contraseña"
          type="password"
          name="pass"
          formControlName="pass"
          maxlength="6"
          (numberEmmited)="numberEmmited($event)"
          autocomplete="off"
          (deleteLast)="deleteLast($event)"
          onfocus="blur()"
          keyboard
        />
        <div
          class="error-text"
          *ngIf="errService.desktopErrors(loginUser.get('pass'), 'pass')"
        >
          {{ errService.desktopErrors(loginUser.get("pass"), "pass") }}
        </div>
      </div>
      <div class="row">
        <button
          class="btn-common btn-common_fix_bold"
          type="submit"
          (click)="closeKeyboard()"
        >
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class="label">
        ¿No tienes claves para entrar? Llámanos al
        <a href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>
<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>

<div class="contactDetails">
  <div class="image" [ngClass]="{ young: isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>DATOS DE CONTACTO</h2>
        <!-- <p>Esta información la conoces de memoria, en menos de un minuto estarás en el siguiente paso.</p> -->
      </legend>
    </div>

    <form
      (keydown.enter)="$event.preventDefault()"
      [formGroup]="contactDetailsForm"
      (ngSubmit)="onSubmit()"
      novalidate
    >
      <div class="row">
        <div class="mobile-container">
          <div class="group-radio">
            <p class="false-label">¿Tu país de residencia es España?</p>
            <div class="options">
              <input
                type="radio"
                id="si"
                value="ESP"
                name="paisResidencia"
                formControlName="paisResidencia"
              />
              <label id="si-label" class="button-label" for="si"></label>
              <input
                type="radio"
                id="no"
                value="noES"
                name="paisResidencia"
                formControlName="paisResidencia"
              />
              <label id="no-label" class="button-label" for="no"></label>
            </div>
          </div>
        </div>
        <div class="mobile-container" *ngIf="secondHolder">
          <input
            type="text"
            id="telefonoMovil"
            no-whitespace
            (blur)="validatePhone($event)"
            name="telefonoMovil"
            required
            maxlength="9"
            number-input
            inputmode="numeric"
            placeholder="Número de teléfono"
            formControlName="telefonoMovil"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('telefonoMovil'),
                'telefonoMovil'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("telefonoMovil"),
                "telefonoMovil"
              )
            }}
          </div>
        </div>
        <div class="mobile-container" *ngIf="!secondHolder">
          <input
            type="text"
            id="email"
            no-whitespace
            name="email"
            required
            maxlength="50"
            placeholder="Tu email"
            formControlName="email"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(contactDetailsForm.get('email'), 'email')
            "
          >
            {{
              errService.desktopErrors(contactDetailsForm.get("email"), "email")
            }}
          </div>
        </div>
        <div class="mobile-container hollow"></div>
      </div>
      <div class="row">
        <div class="group-toggle">
          <p>Asegúrate de indicar la dirección completa de tu domicilio</p>
        </div>
      </div>
      <div class="row">
        <div class="mobile-container no-display">
          <dropdown
            class="dropdown"
            id="tipoVia"
            name="genero"
            formControlName="tipoVia"
            [options]="vias"
          ></dropdown>
        </div>
        <div class="mobile-container">
          <input
            type="text"
            id="direccion"
            name="direccion"
            required
            #direction
            placeholder="Dirección"
            maxlength="45"
            formControlName="direccion"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('direccion'),
                'direccion'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("direccion"),
                "direccion"
              )
            }}
          </div>
        </div>
        <div class="mobile-container w80">
          <input
            type="text"
            id="numero"
            name="numero"
            required
            placeholder="Nº"
            pattern="\d*"
            size="4"
            maxlength="4"
            formControlName="numero"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('numero'),
                'numero'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("numero"),
                "numero"
              )
            }}
          </div>
        </div>
        <div class="mobile-container w80">
          <input
            type="text"
            id="planta"
            name="planta"
            placeholder="Piso"
            size="3"
            maxlength="3"
            formControlName="planta"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('planta'),
                'planta'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("planta"),
                "planta"
              )
            }}
          </div>
        </div>
        <div class="mobile-container w80">
          <input
            type="text"
            id="puerta"
            name="puerta"
            placeholder="Puerta"
            maxlength="2"
            formControlName="puerta"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('puerta'),
                'puerta'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("puerta"),
                "puerta"
              )
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="group-toggle" [ngClass]="!hidden ? 'open' : ''">
          <div
            class="mobile-container"
            [ngClass]="{ 'hidden-container': hidden }"
          >
            <input
              type="text"
              id="portal"
              name="portal"
              placeholder="Portal"
              maxlength="2"
              formControlName="portal"
              autocomplete="off"
            />
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('portal'),
                  'portal'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("portal"),
                  "portal"
                )
              }}
            </div>
          </div>
          <div
            class="mobile-container"
            [ngClass]="{ 'hidden-container': hidden }"
          >
            <input
              type="text"
              id="bloque"
              name="bloque"
              maxlength="2"
              placeholder="Bloque"
              formControlName="bloque"
              autocomplete="off"
            />
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('bloque'),
                  'bloque'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("bloque"),
                  "bloque"
                )
              }}
            </div>
          </div>
          <div
            class="mobile-container"
            [ngClass]="{ 'hidden-container': hidden }"
          >
            <input
              type="text"
              id="escalera"
              name="escalera"
              placeholder="Escalera"
              maxlength="2"
              formControlName="escalera"
              autocomplete="off"
            />
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('escalera'),
                  'escalera'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("escalera"),
                  "escalera"
                )
              }}
            </div>
          </div>
          <div
            class="mobile-container"
            [ngClass]="{ 'hidden-container': hidden }"
          >
            <input
              type="text"
              id="fase"
              name="fase"
              placeholder="Fase"
              maxlength="3"
              formControlName="fase"
              autocomplete="off"
            />
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(contactDetailsForm.get('fase'), 'fase')
              "
            >
              {{
                errService.desktopErrors(contactDetailsForm.get("fase"), "fase")
              }}
            </div>
          </div>
          <div
            class="mobile-container"
            [ngClass]="{ 'hidden-container': hidden }"
          >
            <input
              type="text"
              id="parcela"
              name="parcela"
              placeholder="Parcela"
              maxlength="45"
              formControlName="parcela"
              autocomplete="off"
            />
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  contactDetailsForm.get('parcela'),
                  'parcela'
                )
              "
            >
              {{
                errService.desktopErrors(
                  contactDetailsForm.get("parcela"),
                  "parcela"
                )
              }}
            </div>
          </div>
          <p class="mobile-only" [hidden]="!hidden">
            Si te faltan campos para completar tu dirección puedes desplegar
            este acordeón para ver más opciones.
          </p>
          <div class="mobile-container">
            <button
              aria-expanded="false"
              class="btn-toggle"
              [ngClass]="!hidden ? 'open' : ''"
              role="buttom"
              type="button"
              title="AÑADIR DATOS ADICIONALES"
              (click)="(isHiddenAditional); validateAdditionalFields()"
            >
              <span class="filter-option" [hidden]="!hidden"
                >AÑADIR DATOS ADICIONALES</span
              >
              <span class="filter-option mobile" [hidden]="hidden"
                >OCULTAR DATOS ADICIONALES</span
              >
              <span class="caret"> </span>
            </button>
          </div>
          <p class="desktop" [hidden]="!hidden">
            Si te faltan campos para completar tu dirección puedes desplegar
            este acordeón para ver más opciones.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="mobile-container">
          <input
            type="tel"
            id="codigoPostal"
            name="codigoPostal"
            required
            placeholder="Código postal"
            size="5"
            pattern="\d*"
            number-input
            inputmode="numeric"
            maxlength="5"
            formControlName="codigoPostal"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('codigoPostal'),
                'codigoPostal'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("codigoPostal"),
                "codigoPostal"
              )
            }}
          </div>
        </div>
        <div class="mobile-container">
          <dropdown
            class="dropdown"
            id="Localidad"
            name="Localidad"
            formControlName="localidad"
            [options]="selectLocalidades"
            [isUp]="true"
          ></dropdown>
        </div>
        <div class="mobile-container">
          <input
            type="text"
            id="provincia"
            name="provincia"
            placeholder="Provincia"
            disabled
            formControlName="provincia"
            autocomplete="off"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                contactDetailsForm.get('provincia'),
                'provincia'
              )
            "
          >
            {{
              errService.desktopErrors(
                contactDetailsForm.get("provincia"),
                "provincia"
              )
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mobile-container">
          <dropdown
            class="dropdown"
            id="situacionVivienda"
            name="situacion"
            formControlName="situacionVivienda"
            [options]="situaciones"
            [isUp]="true"
          ></dropdown>
        </div>
        <div *ngIf="hideRental">
          <div class="mobile-container hollow"></div>
          <div class="mobile-container hollow"></div>
        </div>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn-common btn-common_fix_bold">
          CONTINUAR
        </button>
      </div>
    </form>
  </div>
</div>
