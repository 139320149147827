<loading [showMe]="loading"></loading>

<app-header></app-header>

<section class="container">
  <div class="image-container">
    <!--<div class="call-us">
      <span class="ic-telefono-movil"></span>
      <p>LLÁMANOS</p>
    </div>-->
    <div *ngIf="isCIAccount" class="image"></div>
    <div *ngIf="!isCIAccount" class="image-cj"></div>
  </div>
  <div class="our-turn-container">
    <div class="title" style="text-align: left;">Es nuestro turno</div>
    <p class="subtitle" style="text-align: left;">Nos toca comprobar tus datos</p>
    <section class="info">
      <div class="title">Si falta algo</div>
      <div class="card">
        <div class="card-content">
          <div class="icon-ot">
            <div class="ic-sobre-carta-abierta"></div>
          </div>
          <div class="content">
            <p class="letter">Te enviaremos un email con instrucciones para que puedas completarlo.</p>
          </div>
        </div>
      </div>
      <div class="title">Si todo va bien:</div>
      <div class="card">
        <div class="card-content">
          <div class="icon-ot">
            <div class="ic-bocadillo-lineas"></div>
          </div>
          <div class="content">
            <p class="letter">Recibirás dos SMS con:</p>
            <ul>
              <li>Las <strong>claves de tu Banca Móvil.</strong></li>
              <li>Tu <strong>PIN</strong> de la tarjeta.</li>
            </ul>
            <hr class="not-hidden-movil" />
          </div>
        </div>
        <div class="card-content">
          <div class="icon-ot">
            <div class="ic-sobre-carta-abierta"></div>
          </div>
          <div class="content">
            <p class="letter">
              <strong>Te enviaremos a {{mail}}</strong> tu número de cuenta para que realices tu primera transferencia.
            </p>
            <hr class="not-hidden-movil" />
          </div>
        </div>
        <div class="card-content">
          <div class="icon-ot">
            <div class="ic-tarjetas"></div>
          </div>
          <div class="content">
            <p *ngIf="isCIAccount" class="letter">
              Cuando tengas saldo en tu cuenta, en 7 días hábiles <strong>recibirás en casa tu Tarjeta Inteligente.</strong>
            </p>
            <p *ngIf="!isCIAccount" class="letter">
              Cuando tengas saldo en tu cuenta, en 7 días hábiles <strong>recibirás en casa tu Tarjeta Joven.</strong>
            </p>
            <!-- <hr class="not-hidden-movil" /> -->
          </div>
        </div>
         <!-- DES/COMENTAR PARA MOSTRAR EL BLOQUE QR-->
        <!-- <div *ngIf="isCIAccount"  class="card-content now-our-turnApp">
            <div class="now-our-turnApp-content">
              <div class="icon-ot">
                <div class="ic-movil-escudo"></div>
              </div>
              <div class="content">
                <p class="letter"><strong>Puedes descargar la App</strong> escaneando el código QR con tu móvil.</p>
                <div class="app-types">
                  <div class="ic-appstore"></div>
                  <div class="ic-playstore"></div>
                  <div class="ic-huawei"></div>
                </div>
              </div>
            </div>

           <div class="loading-ci"[style.visibility]="!imageQr ? 'visible' : 'hidden'" [style.display]="imageQr ? 'none' : 'flex'">
            <svg class="spinner">
              <circle cx="40" cy="40" r="36"></circle>
            </svg>
            </div>

          <div class="app-download">
            <div class="qr-code">
              <div class="bit-map">
                <img [style.visibility]="imageQr ? 'visible' : 'hidden'" [src]="imageQr"/></div>
            </div>
          </div>
        </div> -->
      </div>
      <footer>
        <p class="letter">
          Recuerda por seguridad, <strong>no activar tu tarjeta hasta recibirla</strong> en la dirección que nos has dado.
        </p>
        <div class="title" *ngIf="isCIAccount">y además...</div>
        <div class="deposit-simulator-container-info" *ngIf="isCIAccount">
          <div class="icon-container">
              <div class="icon-money"></div>
          </div>
          <div class="information">
              <div class="title">AHORA TU DINERO AL <span class=tae-title-font>3,05 % TAE</span></div>
              <div class="subtitle">
                Cuando tu cuenta esté activada, recibirás un email de bienvenida a EVO Banco, donde podrás contratar el
                Depósito<sup>1</sup> a 4 meses al <span class=tae-font>3,05 % TAE.</span>
              </div>
          </div>
        </div>
      </footer>
    </section>
  </div>
</section>
<div class="promo" *ngIf="isCIAccount">
  <p class="promoCondition"><span class="negrita">1. Depósito plazo fijo a 4 meses con liquidación de intereses al vencimiento y
    renovable automáticamente cada 4 meses según remuneración vigente. A continuación, mostramos un ejemplo representativo en el cual la TAE
    se ha calculado a 1 año, bajo la hipótesis de que el tipo de interés no varía: para un saldo total en depósito de 60.000 € al 3,02 % TIN y <span class="tae-font">3,05 % TAE</span>,
    percibiendo una remuneración a los 4 meses de 604 €, a los siguientes 4 meses de otros 604 € y a los siguientes 4 meses de otros 604 €.</span>
    No se aplica penalización por reembolso parcial o cancelación anticipada. Importe mínimo 1 € e importe máximo 1.000.000 €.
    Solo para dinero procedente de otra entidad distinta a EVO. Para clientes nuevos a partir del 01/05/2024 y promoción válida hasta el 31/10/2024.

  </p>
</div>
