<div>

  <p style='text-align:center;margin: 20px 0'>
    <b>
      <span>INFORMACIÓN DEL MEDIADOR</span>
    </b>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;'>
    <b>
      <span>Mediador</span>
    </b>
    <span style="font-size:9.5pt;">: EVO BANCO, S.A.U. (en adelante el “Mediador”) Operador de Banca-Seguros Vinculado, con CIF
      A-70386024 y domicilio social en Madrid, CP2806, Calle Don Ramón de la Cruz 84, inscrita en el Registro Mercantil de Madrid,
      Tomo 31.840, Folio 88, Hoja M-572.999, Inscripción 2ª, y en el Registro Administrativo Especial de Mediadores de Seguros,
      Corredores de Reaseguros y de sus Altos Cargos, a cargo de la Dirección General de Seguros y Fondos de Pensiones (Pº
      de la Castellana, 44, 28046 Madrid, www.dgsfp.mineco.es) con el nº OV-0075. Opera bajo la supervisión del Banco de
      España con el número de registro 0239.</span>
  </p>

  <p style='font-size:9.5pt;margin-top:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;'>
    <span>A los efectos oportunos, se hace constar que:</span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;tab-stops:list 2.0cm;text-autospace:none'>
    <span style='font-size:9.5pt;'>
      <span>(i)
        <span style='font:7.0pt '>&nbsp;&nbsp;&nbsp;&nbsp; </span>
      </span>
    </span>
    <span style='font-size:9.5pt;'>El Mediador no posee ninguna participación directa o indirecta superior al 10 % en el capital social o en los derechos
      de voto de ninguna entidad aseguradora.</span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;tab-stops:list 2.0cm;text-autospace:none'>
    <span style='font-size:9.5pt;'>
      <span>(ii)
        <span style='font:7.0pt '>&nbsp;&nbsp;&nbsp; </span>
      </span>
    </span>
    <span style='font-size:9.5pt;'>El Mediador dispone del seguro de responsabilidad civil profesional y de la capacidad financiera exigidos por la Ley
      26/2006, de 17 de julio, de Mediación de Seguros y Reaseguros Privados.</span>
  </p>

  <p style='font-size:9pt;margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;tab-stops:list 2.0cm;text-autospace:none'>

    <span style='font-size:9.5pt;'>
      <span>(iii)
        <span style='font:7.0pt'>&nbsp;&nbsp; </span>
      </span>
    </span>

    <span style='font-size:9.5pt;'>En caso de queja o reclamación respecto a la actuación del Mediador, el cliente podrá dirigirse al departamento o Servicio
      de Atención al Cliente de la Entidad Aseguradora, o en su caso, al defensor del cliente designado por la Entidad Aseguradora.
      En caso de no recibir respuesta en un plazo de dos meses o esta no es satisfactoria, puede formular su queja o reclamación
      ante el Servicio de Reclamaciones de la Dirección General de Seguros y Fondos de Pensiones (Paseo de la Castellana
      44, 28046 Madrid) al Ministerio de Economía. </span>
  </p>

  <p style='font-size:9pt;margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;tab-stops:list 2.0cm;text-autospace:none'>

    <span style='font-size:9.5pt;'>
      <span>(iv)
        <span style='font:7.0pt '>&nbsp;&nbsp; </span>
      </span>
    </span>

    <span style='font-size:9.5pt;'>El tratamiento de los datos de carácter personal se hará de conformidad con lo establecido en el Reglamento 
      679/2016 General de Protección de Datos, y normativa de desarrollo. Los datos de carácter personal proporcionados con motivo de la 
      contratación del seguro así como los que se generen como consecuencia del desarrollo y ejecución de la citada relación serán incorporados 
      a un fichero cuyo responsable es la Entidad Aseguradora con la que contrata el seguro, y tratados con sujeción al régimen descrito en la 
      cláusula sobre Protección de Datos que figura en las Condiciones del seguro, y a los efectos de la determinación del aseguramiento, 
      así como del desarrollo, mantenimiento, cumplimiento y control del contrato, la evaluación del riesgo, la tramitación del siniestro 
      y/o pago de la prima. La negativa a facilitar la información requerida facultará a las Aseguradoras a no celebrar el contrato. 
      Mantendremos la información personal mientras exista una relación contractual y/o comercial con el asegurado, o mientras éste no ejerza 
      su derecho de supresión, cancelación y/o limitación del tratamiento de sus datos. En estos casos, se mantendrán bloqueados, limitando su 
      tratamiento a la atención de las posibles responsabilidades sólo durante el plazo de prescripción de las mismas. El interesado podrá 
      ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación al tratamiento de sus datos de carácter 
      personal dirigiendo un escrito, al que deberá adjuntar copia de su DNI, al domicilio de la Entidad Aseguradora. Le informamos que tiene 
      derecho a recabar la tutela de la Agencia Española de Protección de datos a través de su página web <a href="http:/www.agpd.es">www.agpd.es</a>. 
      Responsable del tratamiento: SANTA LUCÍA, S.A., Compañía de Seguros y Reaseguros (en adelante, entidad aseguradora), 
      ha nombrado un Delegado de Protección de Datos, cuyo canal de comunicación es: <a href="mailto:dpo@santalucia.es">dpo@santalucia.es</a></span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
      <span style='font-size:9.5pt;'>
        Los datos del Tomador/Asegurado serán tratados por el mediador para las siguientes finalidades:
      </span>
  </p>

  
    <ul style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
      <li><span style='font-size:9.5pt;'>Tramitación solicitudes de seguro, así como mantenimiento, desarrollo y control de la relación contractual, así como de medidas precontractuales.</span></li>
      <li><span style='font-size:9.5pt;'>Cumplimiento de obligaciones legales aplicables a santalucía.</span></li>
      <li><span style='font-size:9.5pt;'>Realización de perfilado para valoración, análisis y tarificación de riesgo.</span></li>
      <li><span style='font-size:9.5pt;'>Gestión de actividades necesarias para la evaluación de su solvencia patrimonial y de crédito.</span></li>
      <li><span style='font-size:9.5pt;'>Tratamiento de datos de salud exclusivamente para las finalidades propias de la ejecución del contrato de seguro. Tales datos serán tratados exclusivamente en caso de que santalucía se encuentre legitimada para ello. Vd. consiente expresamente el tratamiento de sus datos de salud, en la medida en que dicho tratamiento sea necesario de acuerdo con lo descrito.</span></li>
      <li><span style='font-size:9.5pt;'>Gestión y desarrollo de actividades necesarias para la prevención, detección y control del fraude, así como para la prevención y&#47;o detección de blanqueo de capitales y&#47;o financiación del terrorismo.</span></li>
    </ul>
  

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        Tramitación de solicitudes de seguro, así como mantenimiento, desarrollo y control de la relación contractual, 
        así como medidas precontractuales.
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        Los datos del tomador/asegurado serán tratados por la entidad aseguradora para las siguientes finalidades:
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        El mediador actúa respecto de sus datos como encargado del tratamiento, por cuenta y en cumplimiento de las instrucciones 
        recibidas de la entidad aseguradora responsable del tratamiento. El mediador, en su condición de encargado del tratamiento, 
        queda sujeto a la obligación de confidencialidad y al deber de secreto respecto de los datos de los asegurados, y los tratará 
        con arreglo a las instrucciones de la entidad aseguradora y al objeto exclusivo de cumplir con la finalidad para la que fueron 
        recabados, sin poder tratarlos para finalidad distinta, ni comunicarlos, ni siquiera para su conservación, a ningún tercero. 
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        La base legitimadora para llevar a cabo el tratamiento de sus datos tanto por el mediador como por la entidad 
        aseguradora es el consentimiento del titular a la contratación del producto de seguro, cumplimiento de obligaciones 
        legales aplicables al a la entidad aseguradora y el interés legítimo de la Aseguradora. 
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        Mediante el presente documento pueden ser proporcionados por Vd. datos personales de otras personas que se encuentren 
        bajo la cobertura de la póliza. Mediante la suscripción del presente documento Vd. garantiza haber obtenido el 
        consentimiento de dichas personas para la realización de los tratamientos de datos descritos en el citado documento 
        (en el caso de menores de edad, Vd. deberá haber obtenido el consentimiento de sus representantes legales si Vd. no 
        es uno de ellos), así como haber informado a dichas personas de los derechos que les asisten y de las finalidades de 
        tratamiento de datos.
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
      El tomador/asegurado tiene derecho a acceder, rectificar y suprimir los datos, limitar su tratamiento, oponerse al tratamiento 
      y ejercer su derecho a la portabilidad de los datos de carácter personal, todo ello de forma gratuita, tal como se detalla en 
      la información completa sobre protección de datos. Puede ejercer tales derechos mediante escrito remitido a SANTA LUCÍA, S.A., 
      Compañía de Seguros y Reaseguros, Plaza de España, nº 15, 28008 Madrid o bien a <a href="mailto:arcolopd@santalucia.es">arcolopd@santalucia.es</a> 
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
        Resulta obligatorio que Vd. proporcione sus datos de carácter personal y restantes declaraciones requeridas para la 
        formalización del contrato de seguro. En caso de no proporcionar alguna de la información requerida, no resultará posible 
        tramitar ni formalizar el contrato de seguro.
    </span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>
      Le aconsejamos consulte información detallada sobre protección de datos disponible en las Condiciones Particulares de 
      su póliza, así como a través de <a href="http://www.santalucia.es/politica-sobre-proteccion-datos-personales-polizas.html">
        www.santalucia.es/politica-sobre-proteccion-datos-personales-polizas.html</a></span>
  </p>

  <p style='margin-top:0cm;margin-bottom:0cm;margin-left:1.0cm;margin-bottom:.0001pt;text-align:justify;text-indent:-18.0pt;tab-stops:list 2.0cm;text-autospace:none'>

    <span style='font-size:9.5pt;'>
      <span>(v)
        <span style='font:7.0pt'>&nbsp;&nbsp;&nbsp; </span>
      </span>
    </span>

    <span style='font-size:9.5pt;'>El Mediador no está contractualmente obligado a realizar actividades de mediación en seguros exclusivamente con una o
      varias entidades aseguradoras, y no facilita asesoramiento con arreglo a la obligación de llevar a cabo un análisis
      objetivo. El asesoramiento prestado se facilita con la finalidad de contratar un seguro y no cualquier otro producto.</span>
  </p>

  <p>
    <span style='font-size:9.5pt;'>En relación a la suscripción del seguro se informa al Cliente que es importante revisar las condiciones del seguro junto
      con el resto de límites, condiciones y exclusiones del seguro que se le entregan en el Boletín de Adhesión pudiendo
      contactar con el Mediador para cualquier aclaración que pueda precisar o para rectificarlas en tiempo y forma si fuera
      el caso. Las formas de contacto con el Mediador están a su disposición en la página web </span>
    <a href="http://www.evobanco.com">
      <span style='font-size:9.5pt;line-height:107%;color:windowtext;text-decoration:none;text-underline:none'>www.evobanco.com</span>
    </a>
    <span style='font-size:9.5pt;'>.</span>
  </p>

  <p style='text-align:center;margin: 20px 0'>
    <b>
      <span>NOTA INFORMATIVA</span>
    </b>
  </p>

  <p style='margin-top:.35pt;text-align:justify;line-height:11.0pt;'>
    <span style='font-size:9.5pt;'>(La presente información de finalidad comercial se facilita en cumplimiento de lo dispuesto en el artículo 96 de la Ley
      20/2015, de 14 de julio, de ordenación, supervisión y solvencia de las entidades aseguradoras y reaseguradoras, de
      los artículos 122 a 126 de su Reglamento de desarrollo, aprobado por el Real Decreto 1060/2015, de 20 de noviembre,
      del artículo 7 de la Ley 22/2007, de 11 de julio, sobre comercialización a distancia de servicios financieros destinados
      a los consumidores y del artículo 42 de la Ley 26/2006, de 17 de julio, de Mediación de Seguros y Reaseguros Privados.
      Esta información, así como las condiciones contractuales, se presentan en lengua española. Las prestaciones del contrato
      se ejecutarán asimismo en lengua española)</span>
  </p>

  <table border=0 cellspacing=0 cellpadding=0 style='margin-left:7.1pt;border-collapse:collapse;border:none;'>
    <tr>
      <td valign=top style='width:169.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
        <p style='margin-top:.35pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:11.0pt;'>
          <b>
            <span style='font-size:10.0pt;position:relative;top:.5pt;'>ENTIDAD ASEGURADORA:</span>
          </b>
          <span style='font-size:10.0pt;'></span>
        </p>
      </td>
      <td valign=top style='width:380.75pt;padding:0cm 5.4pt 0cm 5.4pt'>
        <p style='margin-top:.35pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:11.0pt;'>
          <span style='font-size:10.0pt;'>SANTA LUCÍA, S.A., Compañía de Seguros y Reaseguros</span>
        </p>
      </td>
    </tr>
    <tr>
      <td valign=top style='width:169.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
        <p style='margin-top:.35pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:11.0pt;'>
        </p>
      </td>
      <td valign=top style='width:380.75pt;padding:0cm 5.4pt 0cm 5.4pt'>
        <p style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:11.0pt;'>
          <span style='font-size:10.0pt;position:relative;top:.5pt;'>Domicilio Social: Plaza de España, nº 15</span>
        </p>
        <p style='margin-top:.2pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;'>
          <span style='font-size:10.0pt;'>28008 MADRID (ESPAÑA)</span>
        </p>
      </td>
    </tr>
  </table>

  <p style='margin-top:.35pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:11.0pt;'>
    <span style='font-size:10.0pt;'></span>
  </p>
  <p style='text-align:justify;line-height:100%'>
    <b>
      <span style='font-size:10.0pt;line-height:100%;'>ÓRGANO DE CONTROL Y SUPERVISIÓN DE LA ACTIVIDAD:</span>
    </b>
    <span style='font-size:9.5pt;line-height:100%;letter-spacing:.1pt'>Dirección General de Seguros y Fondos de Pensiones del Ministerio de Economía, Industria y Competitividad.</span>
    <span style='font-size:10.0pt;line-height:100%;'></span>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>JURISDICCIÓN Y LEGISLACIÓN APLICABLE: </span>
    </b>
    <span style='font-size:9.5pt;'>El contrato de seguro se encuentra sometido a la jurisdicción de los tribunales españoles, así como a la Ley 50/1980,
      de 8 de octubre, de Contrato de Seguro; la Ley 20/2015, de 14 de julio, de ordenación, supervisión y solvencia de las
      entidades aseguradoras y reaseguradoras, su Reglamento de desarrollo (Real Decreto 1060/2015, de 20 de noviembre),
      la Ley 22/2007, de 11 de julio, sobre comercialización a distancia de servicios financieros destinados a los consumidores,
      <span> </span>la Ley 26/2006, de 17 de julio, de Mediación de Seguros y Reaseguros Privados, así como por cuantas normas
      sustituyan, desarrollen o complementen a las mencionadas.</span>
    <span style='font-size:10.0pt;'></span>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;letter-spacing:.05pt'>PERFECCIÓN Y DURACIÓN DEL CONTRATO:</span>
    </b>
    <span style='font-size:10.0pt;letter-spacing:.05pt'> </span>
    <span style='font-size:9.5pt;letter-spacing:.05pt'>El contrato se perfecciona por el consentimiento manifestado por las partes contratantes. El presente seguro se contrata
      por el período de tiempo de un año renovable tácitamente cada anualidad.
    </span>
    <span style='font-size:9.5pt;'> </span>
    <b>
      <span style='font-size:10.0pt;letter-spacing:.05pt'></span>
    </b>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;letter-spacing:.05pt'>DESCRIPCIÓN DE LAS GARANTÍAS Y EXCLUSIONES:</span>
    </b>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <b>
      <span style='font-size:10.0pt;'>BÁSICAS</span>
    </b>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;color:black'>- En caso de fallecimiento del Asegurado por accidente deportivo, el Asegurador abonará el capital señalado en el Certificado
      Individual de Seguro.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;color:black'>- En caso de Invalidez Permanente y Absoluta por accidente deportivo, el Asegurador abonará el capital señalado en el
      Certificado Individual de Seguro.</span>
  </p>

  <p style='text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;color:black'>- En caso de Invalidez Permanente Parcial por accidente deportivo, el Asegurador abonará el importe que resulte de aplicar
      el Baremo de Indemnización incluido en el Boletín de Adhesión al capital señalado en el Certificado Individual de Seguro.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <b>
      <span style='font-size:10.0pt;'>OPCIONAL</span>
    </b>
  </p>

  <p style='text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;color:black'>- En caso de accidente deportivo del Asegurado, el Asegurador reembolsará los gastos médicos prestados al Asegurado,
      hasta el límite de la suma asegurada reflejada en el Certificado Individual de Seguro.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <b>
      <span style='font-size:10.0pt;'>EXCLUSIONES </span>
    </b>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <b>
      <span style='font-size:10.0pt;'>Para todas las garantías:</span>
    </b>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>a) Actos dolosos o intencionados.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>b) Las enfermedades de todo tipo.
    </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>c) Las situaciones de invalidez temporal e invalidez total. </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>d) Cualquier accidente que no tenga la consideración de accidente deportivo de conformidad con las definiciones de la
      presente póliza reflejadas en el Boletín de Adhesión.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>e) Cualquier accidente que no se haya producido durante la práctica de la actividad deportiva declarada por el Asegurado
      o fuera del periodo de tiempo y el lugar declarado por el Asegurado.
    </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>f) Las agravaciones de accidentes deportivos ocurridos con anterioridad a la activación de la cobertura a través de la
      plataforma facilitada por el Mediador.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>g) Los accidentes ocasionados a consecuencia de un acto doloso o intencionado o de imprudencia temeraria del Asegurado,
      así como los derivados de su participación activa en delitos, apuestas, duelos, desafíos o riñas, salvo en los casos
      de legítima defensa o estado de necesidad. </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>h) La práctica profesional y/o federada de deportes.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>i) Cualquier deporte no recogido en el Certificado Individual de Seguro. </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>j) Cualquier actividad practicada a más de 5.000 metros de altura. </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>k) Los accidentes deportivos ocasionados por la participación en competiciones o pruebas deportivas organizadas, aunque
      sea como aficionado, así como la práctica de estos deportes en pistas/zonas que se encuentren cerradas al público o
      en las que haya prohibición o advertencia de las autoridades o competencias del lugar. </span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>l) El uso de bicicletas de alquiler.
    </span>
  </p>

  <p style='text-align:justify;text-autospace:none'>
    <span style='font-size:9.5pt;'>m) Los riesgos de carácter extraordinario.</span>
  </p>

  <p style='margin-top:.9pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:12.0pt;'>
    <b>
      <span style='font-size:10.0pt;letter-spacing:.05pt'>PRIMAS Y TRIBUTOS:</span>
    </b>
  </p>

  <p style='margin-top:.9pt;text-align:justify;line-height:12.0pt;'>
    <span style='font-size:9.5pt;letter-spacing:.05pt'>La prima se devenga de forma inmediata al inicio de cada actividad, en el momento en el que el Asegurado activa y confirma
      la garantía/s concreta a través de la plataforma del Mediador, y se cargará al Asegurado en la cuenta de domiciliación
      de la que es titular en EVO Banco. </span>
  </p>

  <div style="padding-right:30px;min-width:280px">
    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">
      <tbody>
        <tr>
          <td colspan="7" style="border:solid windowtext 1.0pt;background:#70AD47;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:white;">PRIMA TOTAL</span>
              </b>
            </p>
          </td>
        </tr>
        <tr >
          <td colspan="2" rowspan="2" style="border:solid windowtext 1.0pt;border-top:none;background:#70AD47;padding:0cm 3.5pt 0cm 3.5pt;height:13.5pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:white;">Módulo de Contratación</span>
              </b>
            </p>
          </td>
          <td rowspan="2" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#70AD47;padding:0cm 3.5pt 0cm 3.5pt;height:13.5pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:white;">CAPITAL</span>
              </b>
            </p>
          </td>
          <td colspan="4" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#70AD47;padding:0cm 3.5pt 0cm 3.5pt;height:13.5pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:white;">DURACIÓN</span>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.6pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">3 h.</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.6pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">6 h.</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.6pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">12 h.</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.6pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">24 h.</span>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="3" style="border:solid windowtext 1.0pt;border-top:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">A</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">Fallecimiento</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">20.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,16 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,33 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,66 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:8.2pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,31 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Absoluta y Permanente</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">20.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,13 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,27 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,52 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:6.25pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,05 €</span>
            </p>
          </td>
        </tr>
        <tr style="height:7.9pt">
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Permanente Parcial</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">20.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,13 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,27 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,52 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:7.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,05 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
        </tr>
        <tr >
          <td rowspan="3" style="border:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">B</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">Fallecimiento</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">30.000 €</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,24 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,49 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,98 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,96 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Absoluta y Permanente</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">30.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,19 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,39 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,79 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;height:11.9pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,57 €</span>
            </p>
          </td>
        </tr>
        <tr >
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Permanente Parcial</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">30.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,19 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,39 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,79 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,57 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
        </tr>
        <tr >
          <td rowspan="3" style="border:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">C</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">Fallecimiento</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">50.000 €</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,41 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,82 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,64 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">3,27 €</span>
            </p>
          </td>
        </tr>
        <tr >
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Absoluta y Permanente</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">30.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,19 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,39 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,79 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,57 €</span>
            </p>
          </td>
        </tr>
        <tr >
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Permanente Parcial</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">30.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,19 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,39 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,79 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,57 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
        </tr>
        <tr>
          <td rowspan="3" style="border:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">D</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">Fallecimiento</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">75.000 €</span>
              </b>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,62 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,23 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">2,45 €</span>
            </p>
          </td>
          <td style="border:solid windowtext 1.0pt;border-left:none;padding:0cm 3.5pt 0cm 3.5pt;height:3.1pt">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">4,91 €</span>
            </p>
          </td>
        </tr>
        <tr >
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Absoluta y Permanente</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">37.500 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,24 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,49 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,98 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,96 €</span>
            </p>
          </td>
        </tr>
        <tr >
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">I. Permanente Parcial</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">37.500 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,24 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,49 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">0,98 €</span>
            </p>
          </td>
          <td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,96 €</span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
          <td style="padding:0cm 3.5pt 0cm 3.5pt;height:8.6pt"></td>
        </tr>
        <tr >
          <td colspan="2" style="border:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">Reembolso de Gatos Sanitarios</span>
              </b>
            </p>
          </td>
          <td style="border-top:solid windowtext 1.0pt;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;background:#C6E0B4;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <b>
                <span style="font-size:10.0pt;color:#375623;">4.000 €</span>
              </b>
            </p>
          </td>
          <td style="border-top:solid windowtext 1.0pt;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">1,01 €</span>
            </p>
          </td>
          <td style="border-top:solid windowtext 1.0pt;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">2,01 €</span>
            </p>
          </td>
          <td style="border-top:solid windowtext 1.0pt;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">2,51 €</span>
            </p>
          </td>
          <td style="border-top:solid windowtext 1.0pt;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 3.5pt 0cm 3.5pt;">
            <p style="margin-bottom:0cm;margin-bottom:.0001pt;text-align:center;">
              <span style="font-size:10.0pt;color:#375623;">3,01 €</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p style='margin-top:.9pt;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:12.0pt;text-autospace:ideograph-other;vertical-align:baseline'>
    <span style='font-size:8.0pt;color:#4F6228;letter-spacing:.05pt'>*La prima correspondiente a la activación de la cobertura durante varios días es el resultado de multiplicar la prima
      de la activación durante 24h por el número de días activados hasta un máximo de cinco.</span>
  </p>

  <p style='margin-top:.9pt;text-align:justify;line-height:12.0pt;'>
    <span style='font-size:9.5pt;letter-spacing:.05pt'>La cobertura de las garantías quedará supeditada, en todo caso, a que se abone la prima y se emita para cada Asegurado
      el correspondiente Certificado Individual de Seguro, en el que se harán constar inicialmente las garantías activadas,
      el periodo de cobertura, la actividad deportiva declarada y los capitales asegurados. La prima incluye los impuestos
      y recargos que son legalmente aplicables. </span>
  </p>

  <p style='margin-top:.9pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify;line-height:12.0pt;'>
    <span style='font-size:9.5pt;letter-spacing:.05pt'>Para el cumplimiento por el Consorcio de Compensación de Seguros de sus funciones en materia de compensación de pérdidas
      producidas por acontecimientos extraordinarios acaecidos en España, en la presente Póliza se aplica el recargo de riesgos
      extraordinarios obligatorio a favor de dicha entidad pública de conformidad con el Estatuto legal del Consorcio de
      Compensación de Seguros, el Reglamento del Seguro de Riesgos Extraordinarios y la Resolución de 27 de Noviembre de
      2006 de la Dirección General de Seguros y Fondos de Pensiones. Igualmente, las primas están sujetas a un recargo de
      liquidación de entidades aseguradoras del 1,5 por mil.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;tab-stops:14.2pt'>
    <b>
      <span style='font-size:10.0pt;'>INSTANCIAS DE RECLAMACIÓN:</span>
    </b>
    <span style='font-size:10.0pt;'></span>
  </p>

  <p style='text-align:justify;'>
    <span style='font-size:9.5pt;'>Como procedimiento previo de reclamación, antes de acudir a la vía judicial, el Tomador del seguro, Asegurado, Beneficiario
      o derechohabientes de cualquiera de ellos, podrán formular su queja o reclamación ante el Servicio de Atención al Cliente
      de</span>
    <b>
      <span style='font-size:9.5pt;color:#0067BB;'> santalucía </span>
    </b>
    <span style="font-size:9.5pt;">conforme al procedimiento regulado en la Orden ECO/734/2004, de 11 de marzo, sobre los departamentos y servicios de atención
    al cliente y el defensor del cliente de las entidades financieras. A su vez, y conforme a lo dispuesto en el artículo
    30 de la Ley 44/2002, de 22 de noviembre, de Medidas de Reforma del Sistema Financiero y en la Orden ECC/2502/2012, de
    16 de noviembre, por la que se regula el procedimiento de presentación de reclamaciones ante los servicios de reclamaciones
    del Banco de España, la Comisión Nacional del Mercado de Valores y la Dirección General de Seguros y Fondos de Pensiones,
    si fuera denegada la admisión a trámite de la queja o reclamación por parte del Servicio de Atención al Cliente o desestimada,
    total o parcialmente, su petición o transcurrido el plazo de dos meses desde la fecha de su presentación sin que haya
    sido resuelta, el interesado podrá presentar su queja o reclamación ante el servicio de reclamaciones del Banco de España
    o el de la Comisión Nacional del Mercado de Valores, con independencia de su contenido, o directamente ante el Servicio
    de Reclamaciones de la Dirección General de Seguros y Fondos de Pensiones (Paseo de la Castellana nº 44, 28046 Madrid),
    debiendo acreditar haberla formulado previamente ante el Servicio de Atención al Cliente de
    </span>
    <b>
      <span style='font-size:9.5pt;color:#0067BB;'> santalucía, </span>
    </b>
    <span style='font-size:9.5pt;color:black;'>salvo que el objeto de dicha queja o reclamación sea la demora o incumplimiento de una decisión o resolución favorable
      al cliente.</span>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>RESOLUCIÓN ANTICIPADA Y DERECHO DE DESISTIMIENTO:</span>
    </b>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>Impago de prima: </span>
    </b>
    <span style='font-size:9.5pt;'>Si por culpa del Tomador o el Asegurado la prima única no ha sido pagada, el Asegurador tiene derecho a resolver el contrato
      o a exigir el pago de la prima debida en vía ejecutiva con base en la póliza. Salvo pacto en contrario, si la prima
      no ha sido pagada antes de que se produzca el siniestro, el Asegurador quedará liberado de su obligación.</span>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>Oposición a la prórroga: </span>
    </b>
    <span style='font-size:9.5pt;'>El Tomador y/o Asegurado pueden oponerse a la prórroga del Boletín de Adhesión mediante una notificación escrita remitida
      al Asegurador, efectuada con un plazo de, al menos, un mes de anticipación a la conclusión de su periodo de validez.
      En el caso del Asegurado dicha notificación deberá enviarse a través del Mediador.</span>
  </p>

  <p style='text-align:justify;'>
    <span style='font-size:9.5pt;'>El Asegurador deberá comunicar al Tomador, al menos con dos meses de antelación a la conclusión del período en curso,
      su voluntad de no prorrogar el contrato.
    </span>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>Desistimiento: </span>
    </b>
    <span style='font-size:9.5pt;'>El Asegurado tiene derecho a resolver unilateralmente el seguro sin indicación de los motivos y sin penalización alguna
      en el plazo de 30 días a contar desde la fecha en la que el Asegurador le entregue el Boletín de Adhesión. La facultad
      unilateral de resolución del contrato deberá ejercitarse por el Asegurado mediante comunicación a través de un soporte
      duradero, disponible y accesible, que permita dejar constancia de la notificación, que deberá ir dirigida a la atención
      del Mediador quien lo comunicará a al Asegurador. Las diferentes formas de contacto se encuentran en la web www.evobanco.com.
    </span>
  </p>

  <p style='text-align:justify;'>
    <span style='font-size:9.5pt;'>A partir de la fecha en que se expida la comunicación, cesará la cobertura del riesgo por parte del Asegurador y el Asegurado
      tendrá derecho a la devolución de la prima que hubiera pagado, salvo la parte correspondiente al período de tiempo
      en que la cobertura hubiera tenido vigencia, siempre que esta cancelación se efectúe 30 minutos antes del inicio de
      la actividad que generó dicha prima. </span>
  </p>

  <p style='text-align:justify;'>
    <b>
      <span style='font-size:10.0pt;'>INFORME DE SITUACIÓN FINANCIERA Y DE SOLVENCIA:</span>
    </b>
  </p>

  <p style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:justify;'>
    <span style='font-size:9.5pt;'>El informe sobre la situación financiera y de solvencia de Santa Lucía, S.A., Compañía de Seguros y Reaseguros, regulado
      en el artículo 80 de la Ley 20/2015, de 14 de julio, se encuentra accesible en:
      <a href="http://www.santalucia.es/situacion-financiera-solvencia-santalucia.pdf">
        <span style='line-height:107%;color:windowtext;text-decoration:none;text-underline:none'>http://www.santalucia.es/situacion-financiera-solvencia-santalucia.pdf</span>
      </a>
    </span>
  </p>
</div>
