import { Injectable }    from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {

  canActivate() {
    const completeUrl = window.location.href;
    const params = completeUrl.indexOf("?") < 0 ? "" : completeUrl.substring(completeUrl.indexOf("?")+ 1);
    const url = `${environment.urlMulticuenta}?tipoFlujo=MULTI_CI_2TIT_FRIO&${params}`;
    window.location.href = url;
    return true;
  }

}
