import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'legal-condition',
  templateUrl: './legal-condition.component.html',
  styleUrls: ['./legal-condition.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LegalConditionComponent implements OnInit {
  @Input() textLegal: { text: 
                        { txt: string; 
                          subClase: boolean 
                        },
                        class:string }[] = [];
  constructor() { }

  ngOnInit() {
  }
  
}
