<div class="container">
  <p class="productRiskTitle"> INDICADOR DE RIESGO DE CUENTAS EVO</p>
  <table>
    <tr>
      <td class="first-column">
        <div class="container-td">
          <div class="inline-div first-part">
            <p class="inline-text numbers"><span class="text-1">1</span>/6</p>
          </div>
          <div class="inline-div second-part">
            <p class="inline-text text-2">Este número es indicativo del riesgo del producto, siendo 1/6 indicativo de menor riesgo y 6/6 de mayor riesgo.</p>
          </div>
        </div>
      </td>
      <td class="second-column">
        <p class="text-3">
          EVO Banco, S.A. está adherido al Fondo Español de Garantía de Depósitos de Entidades de Crédito. El fondo garantiza los depósitos de dinero hasta 100.000 euros por titular y entidad.
        </p>
      </td>
    </tr>
  </table>
</div>