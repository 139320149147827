import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { SpecificAnalyticsService } from '../../../services/specific-analytics.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { ErrorService } from '../../../services/error.service';
import { HelperService } from '../../../services/helpers.service';
import { LoginService } from 'app/services/login.service';
import { ModalService } from 'app/services/modal.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { PersonaService } from '../../../services/persona.service';
import { ApiRecoveryService } from '../../../services/api/api.recovery.service';
import { RecoveryService } from '../../../services/recovery.service';
import { Modal } from '../../../collections/modal';
import { Subject } from 'rxjs/Subject';
import { SecurityService } from '../../../services/security-registration.service';

@Component({
  selector: 'app-login-inv',
  templateUrl: './login-inversora.component.html',
  styleUrls: ['./login-inversora.component.scss'],
  providers: [ModalService,
    RecoveryService,
    ApiRecoveryService]

})
export class LoginCuentaInversoraComponent implements OnInit, AfterViewInit {
  public loginUser: FormGroup;
  loading = false;
  login = 'modal-login';
  modalNoCliente = 'modal_noCliente';
  modal = new Modal();
  private passStr = '';
  public errMsg;
  public param = null;
  public idCliente;
  public cdgoCI: string ="009";
  public cdgoFI: string ="010";
  public contratacionCI: boolean = false;
  public contratacionFI: boolean = false;
  public codProductoContratado: string;
  public esCliente: boolean = false;
  public clientID: string;
  public auth_token: string;
  public esST: boolean = false;
  public obsCookies = new Subject<string>();

  @ViewChild('password') password: ElementRef;

  public showPasswordField:boolean = false;

  constructor(
    private authService: AuthUserService,
    private helpers: HelperService,
    private fb: FormBuilder,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public deviceSrv: DeviceService,
    private loginSrv: LoginService,
    private analytics: AnalyticsService,
    private modalSrv: ModalService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private personaSrv: PersonaService,
    private recoveryService: RecoveryService,
    private apiRecoveryService: ApiRecoveryService,
     private securitySrv: SecurityService,
     private specificAnalyticsSrv: SpecificAnalyticsService
  ) { }

  ngOnInit() {

    this.analytics.isInvestmentNav = true;
    this.activatedRoute.queryParams.subscribe(params => {

      // Si en la url no trae ClientId (no es un abandono)
      // Si en la url no trae origen(BE) (no es una nueva contratacion)
      // Y redirigimos al login normal.


      if(params['isSecondSigner'] === 'true'){
        this.esST = true;
      }else{
        this.esST = false;
      }

      if(!params['clientId'] && params['origen'] !="BE"){

        let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        this.router.navigateByUrl(url);
        this.helpers.navigateTo('/login');

      }else if(params['origen'] ==="BE" || params['isSecondSigner'] === 'true'){

        this.clientID = params['clientId'];
        this.auth_token = params['token'];

      }

      this.codProductoContratado = params['codProductoContratado'];
      if(this.codProductoContratado === this.cdgoCI){
        this.contratacionCI = true;
      }else if(this.codProductoContratado === this.cdgoFI){
        this.contratacionFI = true;
      }

    });

    // this.helpers.keyboardClose.subscribe(val => {
    //   this.renderer.removeClass(this.password.nativeElement, 'focusKeyboard');
    // });

    this.loading = false;
    this.loginSrv.logOut();
    sessionStorage.setItem("isLogin", "true");
    // sessionStorage.setItem('auth_token', this.auth_token);
    this.createFormLogin();
    if (this.deviceSrv.isMobile()) {
      this.scrollToField();
    }

  }
  abrirModal(){
    this.modalSrv.open(this.modalNoCliente);
  }


  ngAfterViewInit() {
    const timeout = setTimeout(() => {
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      clearTimeout(timeout);
    });
  }

  sendClient() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);

    this.errMsg = undefined;


    // Enviamos dato para ocultar frase del footer.
    this.obsCookies.next("SendClient");
    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');

    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };
    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;
    this.loading = true;
    this.loginSrv.getLogin(login).subscribe(data => {
      this.analytics.triggerProfileFormData('Log in', 'Envio OK');
      const idExterno = data.userinfo.idExterno;
      if(this.contratacionCI || this.contratacionFI){
        this.authService.getUserData(idExterno).subscribe(data => {
          console.log("userdata"+data);
          this.idCliente = data.persona.id_cliente_EVO;
          sessionStorage.setItem("idClienteEvo", this.idCliente);
          sessionStorage.setItem("userData", JSON.stringify(data));
          this.specificAnalyticsSrv.emitUserId(this.idCliente);
          this.personaSrv.getPersonaById(this.idCliente, this.codProductoContratado).subscribe(data => {
            this.loading = false;
            this.loginSrv.userInfo = data;
            this.helpers.navigateTo('configuracion-cuenta-inversora');

          }, _error => {
            this.errService.modDataError = true;
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
          });
        },
        _error => {
          this.errService.modDataError = true;
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
        });
      }
      else{
        //JSON.stringify(data) !=='{}'
      //redirigir aqui en campaña subida documentacion
      this.helpers.navigateTo('configuracion-cuenta-inversora');
      }
    }, error => {
      this.loading = false;
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: 500 Server error');
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
                     correctamente los datos. Recuerda que por seguridad,
                     tres errores consecutivos en la contraseña bloquearán el acceso`;

      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    });
  }

  getInfoSolicitud() {
    this.personaSrv.getInfoSolicitud(this.idCliente).subscribe(response => {
      console.log("info solicitud "+response)
      const tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');

      this.getWarnings(tieneSolicitudes);

    }, error => {
      //this.errService.navToError();
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'bancaelectronica');
    });
  }

  getWarnings(tieneSolicitudes) {
    this.personaSrv.getWarnings(this.idCliente).subscribe(response => {
      console.log("info warnings "+response)
      const tipoSolicitud = response.message;
      if (tipoSolicitud === '00') {
        // SIN WARNINGS
        // Tenga o no solicitudes se manda a modificacion
          this.helpers.navigateTo('mi-perfil');

        //console.log("Todo correcto");
      }
      else if (tipoSolicitud === '01' || tipoSolicitud === '02' || tipoSolicitud === '03') {
        // CON WARNINGS
        if(tieneSolicitudes){
          this.helpers.navigateTo('mi-perfil');
        }
        else{
          sessionStorage.setItem('tipoSolicitud', tipoSolicitud);
          this.helpers.navigateTo('subir-documentacion');
        }
        //console.log("DNI está caducado o no tiene doc en RSI o ambas");
      }
      else if (tipoSolicitud !== '00' && tipoSolicitud !== '01' && tipoSolicitud !== '02' && tipoSolicitud !== '03'){
        //console.log("error al obtener los datos");
        this.errService.modDataError = true;
        this.errService.navToError();
      }

    }, error => {
      this.errService.modDataError = true;
      this.errService.navToError();
    });
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }

    let invalidID = this.evoValidator.validateID(userInput)

    let errors = this.evoValidator.validateID(userInput);

    if (errors){
      userInput.setErrors(errors);
      this.showPasswordField = false;
    }
    else{
      this.showPasswordField = true;
    }

  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.get('user').valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
    this.getVariableCampain();
  }

  getVariableCampain() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      //console.log("parametros "+JSON.stringify(params));
      if (params.subidaDocumentacion) {
        this.param = params.subidaDocumentacion;
        sessionStorage.setItem("subidaDocumentacion", "true");
      }
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass',[Validators.required, Validators.minLength(6)]);
    }
  }

  // closeKeyboard() {
  //   this.helpers.updateFieldValidation(this.loginUser, 'user', []);
  //   this.helpers.closeLoginKeyboard();
  //   this.renderer.removeClass(this.password.nativeElement, 'focusKeyboard');
  // }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.passStr = '';
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  scrollToField() {
    const target = document.getElementById('dni'),
      headerHeight = 92,
      position = target.offsetTop - target.clientHeight - headerHeight;

    document.body.scrollTop = position;
    window.scrollTo(0,position);
  }

  setClassFocus() {
    this.renderer.addClass(this.password.nativeElement, 'focusKeyboard');

  }

  cambiarValor(documento){
    if(this.esST){
      if (documento.length === 9) {
        this.loading = true;
        this.apiRecoveryService.recoverProcess( this.auth_token, this.clientID)
        .subscribe(data => {
          const consulta = { EE_I_Consulta: {
            codigoEntidad: "0239",
            idExterno: documento,
            idCliente: this.clientID }
          };

          this.personaSrv.checkDuplicateDNI(consulta).subscribe(data =>{
            if(data.codigoRetorno === "1"){
              this.esCliente = true;
            }else if(data.codigoRetorno === "0"){
              this.esCliente = false;
              this.abrirModal();
            }else{
              this.errService.modDataError = true;
              this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
            }
            this.loading = false;
          }, error => {
            this.errService.modDataError = true;
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
          });
        }
        , (error) => {
          this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/KO Error servicio'});
          this.errService.modDataError = true;
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
        });
      }
    }
  }




  triggerInvesmentNavData(pageName) {
    let isCuentaInversora = sessionStorage.getItem('productoContratando') === '009';
    let product = isCuentaInversora ? 'Cuenta Inversora': 'Fondo Inteligente';
    let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    if (isSecondSigner){
      pageName = 'Paso 01.0 - DNI /Segundo titular';
    }
    this.analytics.triggerInvestmentDataNavData({
                    'pageName': product + '/' + pageName,
                    'product': product
                  });
  }

}
