export class Logalty {

  mobileNumber: string;
  product:string = 'fullonline';
  urlBackFragment:string;
  logaltySignatureId:string;
  isSecondSigner:boolean = false;
  signatureUserData: {
    firstName: string;
    lastName1: string;
    lastName2: string;
    email: string;
    treatment: string;
    signatureDate: string;
    signatureId: string;
    identityDocumentType: string;
    nif: string;
    userId: string;
    mobileNumber: string;
    countryResidence: {
      alpha3Code: string;
      alpha2Code: string;
      englishDescription: string
    }
  };
  signatureUserData2Signer: {
    firstName: string;
    lastName1: string;
    lastName2: string;
    email: string;
    treatment: string;
    signatureDate: string;
    signatureId: string;
    identityDocumentType: string;
    nif: string;
    userId: string;
    mobileNumber: string;
    countryResidence: {
      alpha3Code: string;
      alpha2Code: string;
      englishDescription: string
    }
  };
  consents:{
    perfilado: string;
    publi: string;
    cesion: string;
  };
  jobInformation: {
    labourStatus: string;
    salaryRank: string;
    salaryMonth: string;
    cno: string;
    currentJobSeniority: string;
    startingDateOfTheCurrentJob: string;
    companyName: string;
    jobTitle: string;
    salaryNumber: string;
    monthlySalary: string;
    globalLaborSeniority: string
  };
  ownershipConfirmation: {
    ibanThirdParty: string;
    officeName: string;
  };
  bankInformation: {
    ibanAccount: string;
    ibanInvestment: string;
    cardNumber: string;
    userElectronicBank: string;
    userAlerts: string
  };
  bankInformation2Signer: {
    ibanAccount: string;
    ibanInvestment: string;
    cardNumber: string;
    userElectronicBank: string;
    userAlerts: string
  };
  personalInformation: {
    treatment:string;
    firstName: string;
    firstLastName: string;
    secondLastName: string;
    nationality: string;
    countryResidence: string;
    identityDocumentType: string;
    identityNumber: string;
    identityDocumentExpirationDate: string;
    maritalStatus: string;
    birthdate: string;
    birthCountry: string;
    address: {
      street: string;
      buildingNumber: string;
      floorLevel: string;
      door: string;
      portal: string;
      block: string;
      stairway: string;
      phase: string;
      plot: string;
      locality: string;
      postalCode: string
    };
    province: string;
    localization: string;
    roadType: string;
    homeSituation: string;
    monthlyRentImport: string
  };
  promoData: {
    numeroPack: string;
    idPersona: string;
    fechaInicioPromo: string;
    fechaFinPromo: string;
    activo: boolean;
  };
  businessPurpose: {
    payrollPensionOthers: string;
    servicePayment: string;
    professionalActivity: string;
    financing: string;
    savingsInvestment: string;
    otherProducts: string
  }
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class LogaltyMicroseg {
  mobileNumber: string; //666202772,
  product: "microseg";
  urlBackFragment: string;
  logaltySignatureId: string; //123-123-123-123.par,
  signatureUserData: {
    identityDocumentType: string; //DNI, NIE
    countryResidence: {
      alpha3Code: string; //011,
      alpha2Code: string; //123,
      englishDescription: string; //ESP
    };
    treatment: string; // M,
    email: string; //nachobt90@gmail.com,
    signatureId: string;//IDE_SIGID_TESTMICROSEG_3,
    userId: string;//IDE-00000037_aaa,
    signatureDate: string;//30/11/2017,
    policyNumber: string;//0987654321,
    policyHolderName: string;//Ignacio,
    policyHolderLastname1: string;//Bartolomé,
    policyHolderLastname2: string;//Tabanera,
    policyHolderNif: string;//47284982H,
    signatureDay: string;//30,
    signatureMonth: string;// Noviembre,
    signatureYear: string;//2017,
    policyHolderMandateReference: string;//MANDATE REFERENCE,
    policyHolderIban: string;//ES20381839000000000000,
    policyHolderAddress: string;// c/ FALSA, 123,
    policyHolderFloor: string;//66,
    policyHolderNumber: string;//54,
    policyHolderPostalCode: string;//28035,
    policyHolderLocality: string;//Madrid,
    policyHolderProvince: string;//Madrid
  }
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class LogaltyAmpliacion {
  mobileNumber: string; //666202772,
  product: "limitecred";
  urlBackFragment: string;
  logaltySignatureId: string; //123-123-123-123.par,
  signatureUserData: {
    firstName: string;
    lastName1: string;
    lastName2: string;
    email: string;//nachobt90@gmail.com,
    treatment: string; // M,
    mobileNumber: string; //666202772,
    signatureDate: string;//30/11/2017,
    identityDocumentType: string; //DNI, NIE
    signatureId: string;//IDE_SIGID_TESTMICROSEG_3,
    nif: string;
    userId: string;//IDE-00000037_aaa,
    countryResidence: {
      alpha3Code: string; //011,
      alpha2Code: string; //123,
      englishDescription: string; //ESP
    }
  }
  contractData: {
    personalData: {
      address: {
        typeOfRoad: string;
        street: string;
        buildingNumber: string;
        floorLevel: string;
        door: string;
        portal: string;
        block: string;
        phase: string;
        plot: string;
        postalCode: string;
        stairway: string;
        locality: string;
        province: string;
        country: string;
      }
      documentExpiryDate: string;
      birthdate: string;
      birthCountry: string;
      birthLocality: string;
      residenceCountry: string;
      nationalityCountry: string;
      maritalStatus: string;
      civilStatus: string;
    }
    economicData: {
      yearSinceActualPoblation: string;
      yearsInCountry: string;
      homeSituation: string;
      numberOfFamilyComponents: string;
      labourStatus: string;
      contractEndDate: string;
      profesion: string;
      companyName: string;
      activity: string;
      startingDateOfTheCurrentJob: string;
      yearsWorked: string;
      numberOfPayments: string;
      monthlyNetFixedIncome: string;
      otherNetFixedIncome: string;
      monthlyNetVariableIncome: string;
      otherLoansPayments: string;
      monthlyRentImport: string;
      compensatoryPensionsExpenses: string;
    }
  };
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class LogaltyInversora {
  mobileNumber: string; //666202772,
  product: "cinv";
  urlBackFragment: string;
  signatureUserData: {
    firstName: string;
    lastName1: string;
    lastName2: string;
    email: string;//nachobt90@gmail.com,
    treatment: string; // M,
    mobileNumber: string; //666202772,
    signatureDate: string;//30/11/2017,
    identityDocumentType: string; //DNI, NIE
    signatureId: string;//IDE_SIGID_TESTMICROSEG_3,
    nif: string;
    userId: string;//IDE-00000037_aaa,
    countryResidence: {
      alpha3Code: string; //011,
      alpha2Code: string; //123,
      englishDescription: string; //ESP
    }
  }
  contractData: {
    personalData: {
      address: {
        typeOfRoad: string;
        street: string;
        buildingNumber: string;
        floorLevel: string;
        door: string;
        portal: string;
        block: string;
        phase: string;
        plot: string;
        postalCode: string;
        stairway: string;
        locality: string;
        province: string;
        country: string;
      }
      documentExpiryDate: string;
      birthdate: string;
      birthCountry: string;
      birthLocality: string;
      residenceCountry: string;
      nationalityCountry: string;
      maritalStatus: string;
      civilStatus: string;
    }
    economicData: {
      yearSinceActualPoblation: string;
      yearsInCountry: string;
      homeSituation: string;
      numberOfFamilyComponents: string;
      labourStatus: string;
      contractEndDate: string;
      profesion: string;
      companyName: string;
      activity: string;
      startingDateOfTheCurrentJob: string;
      yearsWorked: string;
      numberOfPayments: string;
      monthlyNetFixedIncome: string;
      otherNetFixedIncome: string;
      monthlyNetVariableIncome: string;
      otherLoansPayments: string;
      monthlyRentImport: string;
      compensatoryPensionsExpenses: string;
      associateAcount: string;
			categorized: boolean;
    }
  };
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class LogaltyInversoraTwice {
  mobileNumber: string; //666202772,
  product: "cinv";
  urlBackFragment: string;
  secondHolder: boolean; //true,
  signatureUserData: {
    firstName: string;
    lastName1: string;
    lastName2: string;
    email: string;//nachobt90@gmail.com,
    treatment: string; // M,
    mobileNumber: string; //666202772,
    signatureDate: string;//30/11/2017,
    identityDocumentType: string; //DNI, NIE
    signatureId: string;//IDE_SIGID_TESTMICROSEG_3,
    nif: string;
    userId: string;//IDE-00000037_aaa,
    countryResidence: {
      alpha3Code: string; //011,
      alpha2Code: string; //123,
      englishDescription: string; //ESP
    }
  }
  contractData: {
    personalData: {
      address: {
        typeOfRoad: string;
        street: string;
        buildingNumber: string;
        floorLevel: string;
        door: string;
        portal: string;
        block: string;
        phase: string;
        plot: string;
        postalCode: string;
        stairway: string;
        locality: string;
        province: string;
        country: string;
      }
      documentExpiryDate: string;
      birthdate: string;
      birthCountry: string;
      birthLocality: string;
      residenceCountry: string;
      nationalityCountry: string;
      maritalStatus: string;
      civilStatus: string;
      firstName: string;//"nombre",
			lastName1: string //"apellido1",
			lastName2: string; //"apellido2",
			email: string; //"email@cliente.es",
			treatment: string; //"M",
			mobileNumber: string; //"666202772",
			nif: string; //"18869415P"
    }
    economicData: {
      yearSinceActualPoblation: string;
      yearsInCountry: string;
      homeSituation: string;
      numberOfFamilyComponents: string;
      labourStatus: string;
      contractEndDate: string;
      profesion: string;
      companyName: string;
      activity: string;
      startingDateOfTheCurrentJob: string;
      yearsWorked: string;
      numberOfPayments: string;
      monthlyNetFixedIncome: string;
      otherNetFixedIncome: string;
      monthlyNetVariableIncome: string;
      otherLoansPayments: string;
      monthlyRentImport: string;
      compensatoryPensionsExpenses: string;
      associateAcount: string;
			categorized: boolean;
    }
  };

  contractData2Holder: {
    personalData: {
      address: {
        typeOfRoad: string;
        street: string;
        buildingNumber: string;
        floorLevel: string;
        door: string;
        portal: string;
        block: string;
        phase: string;
        plot: string;
        postalCode: string;
        stairway: string;
        locality: string;
        province: string;
        country: string;
      }
      documentExpiryDate: string;
      birthdate: string;
      birthCountry: string;
      birthLocality: string;
      residenceCountry: string;
      nationalityCountry: string;
      maritalStatus: string;
      civilStatus: string;
      firstName: string;//"nombre",
			lastName1: string //"apellido1",
			lastName2: string; //"apellido2",
			email: string; //"email@cliente.es",
			treatment: string; //"M",
			mobileNumber: string; //"666202772",
			nif: string; //"18869415P"
    }
    economicData: {
      yearSinceActualPoblation: string;
      yearsInCountry: string;
      homeSituation: string;
      numberOfFamilyComponents: string;
      labourStatus: string;
      contractEndDate: string;
      profesion: string;
      companyName: string;
      activity: string;
      startingDateOfTheCurrentJob: string;
      yearsWorked: string;
      numberOfPayments: string;
      monthlyNetFixedIncome: string;
      otherNetFixedIncome: string;
      monthlyNetVariableIncome: string;
      otherLoansPayments: string;
      monthlyRentImport: string;
      compensatoryPensionsExpenses: string;
      associateAcount: string;
			categorized: boolean;
    }
  }



  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class signLogalty{
  operationErrorCode: number;
  operationErrorMessage: string;
  operationErrorTrace: string;
  logaltySignatureId: any;
  userId: string;
  resultCode: number;
  resultMessage: string;
  signatureId: string;
  saml: any;
}

export class setCheckLogalty{
  product:string = 'fullonline';
  guids:any;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class CheckLogalty{
  states: [
    {
      operationErrorCode: string;
      operationErrorMessage: string;
      operationErrorTrace: string;
      userId: string;
      resultCode: number;
      resultMessage: string;
      signatureId: string;
      cancelCode: string;
      cancelMessage: string;
      stateCode: number;
      stateMessage: string;
    }
  ];
  operationErrorCode: number;
  operationErrorMessage: string;
  operationErrorTrace: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


