import { Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { VideoSelectionService } from '../../../services/video-selection.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { RecomiendaService } from '../../../services/recomienda.service';
import { ModalService } from '../../../services/modal.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-everything-second-sign',
  templateUrl: './everything-second-sign.component.html',
  styleUrls: ['./everything-second-sign.component.scss'],
  providers: [
    VideoSelectionService,
    ModalService,
    RecomiendaService
  ]
})
export class EverythingSecondSignComponent implements OnInit {

  recomiendaURL:string;
  showPlay:boolean = true;
  recomiendaModal: string = 'recomienda';
  vidPlaying:boolean = false;

  @ViewChild('video') public video:ElementRef;
  @ViewChild('iframereco') public iframereco: ElementRef;
  constructor(private router: Router,
              public vidService: VideoSelectionService,
              private renderer: Renderer2,
              private analytics: AnalyticsService,
              private helpers: HelperService,
              private recomienda: RecomiendaService,
              public modService: ModalService,
              public authService: AuthService
              ) {  }

  ngOnInit() {
    this.authService.isLoggedIn = false;
    this.vidService.selectVideo();
  }

  goToAppStore() {
    this.analytics.triggerInteractionData('Descarga app', 'iOS')
    // TODO route to appstore
    window.open('http://apple.co/2AcrBrr');
  }

  goToPlayStore() {
    this.analytics.triggerInteractionData('Descarga app', 'Android')
    // TODO route to appstore
    window.open('http://bit.ly/2Bwntkj');
  }

  goToInfo() {
    window.open('http://eresinteligente.evobanco.es/');
  }

  goToRecomienda() {
    const sessionProspect = this.authService.getSessionProspect(),
          email = sessionProspect.datosPersonales.email,
          productoContratado = sessionProspect.logalty.codProductoContratado;
    this.recomiendaURL = this.recomienda.getRecomiendaURL(email, productoContratado);
    this.modService.open(this.recomiendaModal);
    this.iframereco.nativeElement.src = this.recomiendaURL;
  }

  closeRecomienda(event:any){
    this.renderer.addClass(event.path[1], 'no-display');
  }

  goToProfile() {
    const nextStep = '/perfil';
    this.helpers.navigateTo(nextStep);
  }

  hidePlayer() {
    return this.showPlay = false;
  }

  playVid() {
    if (!this.vidPlaying) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
    this.vidPlaying = !this.vidPlaying
  }
}
