<loading [showMe]="loading"></loading>
<!-- <mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone> -->
<modal
  [modalTitle]="'modalNeedYourAprobation'"
  [blocking]="false"
  [modalId]="modalNeedYourAprobation"
  class="modal"
>
  <div class="row">
    <p class="ic-triangulo-exclamacion"></p>
  </div>
  <div>
    <p class="text-1">¡Necesitamos tu aprobación!</p>
  </div>
  <div>
    <p class="text-2">
      Para poder enviarte ofertas personalizadas, necesitamos permiso para usar
      tus datos. ¿Qué nos dices?
    </p>
  </div>
  <div>
    <p class="text-3">
      {{ checks.perfilado }}
    </p>
  </div>
  <div>
    <button class="button-refuse" (click)="refusePerfil()">
      <p class="text">RECHAZAR</p>
    </button>
    <button class="button-acept" (click)="aceptPerfil()">
      <p class="text">ACEPTAR</p>
    </button>
  </div>
</modal>
<modal
  [modalTitle]="'modalLopd'"
  [blocking]="true"
  [modalId]="modalLopd"
  class="modal"
>
  <div class="row">
    <p class="ic-triangulo-exclamacion"></p>
  </div>
  <div>
    <p class="text-1">Lo sentimos...</p>
  </div>
  <div>
    <p class="text-2">
      No cumples con los requisitos para disfrutar de una Cuenta Inteligente
    </p>
  </div>
  <div class="row-button">
    <button (click)="closeModalLopd()" class="bt-info" name="button1">
      <p class="etiqueta">Entendido</p>
    </button>
  </div>
</modal>
<modal
  [modalTitle]="'Tarjeta de residencia'"
  [blocking]="true"
  [modalId]="modalResidenceCard"
  class="modal"
>
  <div class="container-user-info">
    <div class="row-ic-triangulo-exclamacion">
      <p class="ic-triangulo-exclamacion"></p>
    </div>
    <div class="row-title">
      <p class="oh-vaya">Oh, ¡vaya!</p>
    </div>
    <div class="row-info">
      <p class="nuestro-flujo-de-con">
        No hemos podido realizar la apertura de tu cuenta ya que el documento
        identificativo facilitado no es válido. ¡Lo sentimos!
      </p>
    </div>
    <div class="row-button">
      <button
        (click)="closeModalResidenceCard()"
        class="bt-info"
        name="button1"
      >
        <p class="etiqueta">Entendido</p>
      </button>
    </div>
    <div class="row-change-response">
      <p class="s-tengo-Tarjeta-de">
        Sí, tengo Tarjeta de residencia con foto.
        <span
          class="text-style-1"
          (click)="closeModalResidenceCardandchangeResponse()"
          >cambiar mi respuesta</span
        >
      </p>
    </div>
  </div>
</modal>
<modal
  [modalTitle]="'Tarjeta de residencia'"
  [blocking]="false"
  [modalId]="modalInfoNie"
  class="modal"
>
  <div class="container-user-info">
    <div class="row-ic-info">
      <p class="ic-info"></p>
    </div>
    <div class="row-title">
      <p class="ten-en-cuenta">Ten en cuenta que...</p>
    </div>
    <div class="row-info">
      <p class="ten-en-cuenta">
        Si eres extranjero y tienes un número identificativo NIE, seguramente
        cuentes con uno de los siguientes documentos:
      </p>
    </div>
    <div class="row-nie">
      <div class="nie-sin-foto">
        <p class="texto-info">Sin foto:</p>
        <p class="ic-nie-sin-foto"></p>
      </div>
      <div class="nie-foto">
        <p class="texto-info">Con foto:</p>
        <p class="ic-nie-foto"></p>
      </div>
    </div>
    <div class="row-desc">
      <p class="ten-en-cuenta">
        Para que podamos continuar con la identificación,
        <span style="font-weight: bold">
          necesitamos una Tarjeta de residencia con foto.
        </span>
      </p>
    </div>
    <div class="row-button">
      <button (click)="closeInfoModalNie()" class="bt-info" name="button1">
        <p class="etiqueta">Entendido</p>
      </button>
    </div>
  </div>
</modal>

<modal modalId="faqModal" [modalTitle]="'Faq'" class="modal">
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse0 = !estadoCollapse0">
      ¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?
    </h3>
    <div
      [ngClass]="{
        'collapse-block': estadoCollapse0,
        'collapse-none': !estadoCollapse0
      }"
    >
      <p>
        Ahora, disfrutar de las ventajas de tu Cuenta Inteligente es más fácil
        que nunca. Lo único que tienes que hacer es mantener 10 puntos. No te
        preocupes, es muy fácil conseguirlo ¿cómo? Solo cumpliendo con una de
        estas opciones tendrás esos 10 puntos.
      </p>
      <ul>
        <li>Domiciliar tu nómina, pensión o desempleo</li>
        <li>Domiciliar 4 recibos</li>
        <li>Mantener 10.000 euros de saldo en cualquier producto EVO</li>
        <li>Domiciliar ingresos mensuales de al menos 600€</li>
        <li>Tener con nosotros una hipoteca, préstamo o tarjeta revolving</li>
        <li>
          Contratar 1 producto distintos a la Cuenta Inteligente y domiciliar 2
          recibos
        </li>
        <li>Contratar 2 productos distintos a la Cuenta Inteligente</li>
      </ul>
      <br />
      <h4 class="collapsible" (click)="estadoCollapse1 = !estadoCollapse1">
        ¿Y SI A PESAR DE TODO NO TENGO LOS 10 PUNTOS?
      </h4>
      <div
        [ngClass]="{
          'collapse-block': estadoCollapse1,
          'collapse-none': !estadoCollapse1
        }"
      >
        <p>
          No te preocupes, puedes seguir disfrutando de todos los productos,
          servicios y ventajas de la cuenta inteligente por solo 8€/mes y con
          una remuneración en la cuenta a plazo del 0,01% TIN.Condiciones
          exclusivas Cuenta Inteligente EVO. Cuenta a plazo remunerada durante 4
          meses al 0,20% TIN para cualquier importe y sin comisiones de ningún
          tipo. Liquidación mensual de intereses. Sin penalización por
          reintegros antici pados. Renovable automáticamente cada 4 meses según
          remuneración vigente. Para disfrutar de las ventajas de la Cuenta
          Inteligente tendrás que conseguir 10 puntos a través del esquema de
          vinculación que puedes consultar en
          www.evobanco.com/cuenta-inteligente , en el caso de no conse guir los
          10 puntos, pasarás a la Cuenta Sin Vínculos con la que sigues
          manteniendo todos los productos, servicios y ventajas de la Cuenta In
          teligente pero con un coste por mantenimiento de 8€ al mes y una
          remuneración en la cuenta a plazo del 0,01% TIN. Ejemplos
          representativos del cálculo de la TAE bajo la hipótesis de que la
          remuneración se mantiene invariable durante todo el año: Cuenta
          inteligente: 9.000 euros en plazo al 0,20% TIN, TAE del 0,20% y Cuenta
          Sin Vínculos: 9.000 euros en plazo al 0,01% TIN y comisión
          mantenimiento 8€/mes, TAE del -1,056%. Debes saber que aunque EVO no
          cobre nada por retirar dinero a débito en el extranjero, algunas
          entidades propietarias de cajeros pueden aplicar una tasa de recargo
          por su utilización. Debes mantener todos los productos gratuitos que
          integran la Cuenta Inteligente EVO (Cuenta corriente, Cuenta a plazo,
          Tarjeta Inteligente, Banca a Distancia y Servicio de Alertas). Puedes
          disponer de un máximo de dos Tarjetas Inteligentes bonificadas. Puedes
          cancelar tu Cuenta Inteligente sin coste de cancelación.
        </p>
      </div>
    </div>
  </div>
  <div class="modalContent"></div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse2 = !estadoCollapse2">
      ¿HAY COMISIONES DE MANTENIMIENTO?
    </h3>
    <div
      [ngClass]="{
        'collapse-block': estadoCollapse2,
        'collapse-none': !estadoCollapse2
      }"
    >
      <p>
        Olvídate de las comisiones. La Cuenta Inteligente no tiene ninguna. Y
        por supuesto, con tu dinero siempre disponible. Tiene tarjetas de Débito
        y Crédito GRATIS, sin comisión de emisión ni renovación. Puedes sacar
        dinero gratis de cualquier cajero de España si retiras 120€ o más. Sea
        del color que sea. Y para retiradas gratis sin cantidad mínima y sin
        límite de operaciones, tienes a tu disposición una de las mayores redes
        de cajeros de España, formada por los cajeros EVO, la red EURO6000
        (excepto CaixaBank). Kutxabank, Ibercaja, Grupo Liberbank, Abanca,
        Unicaja, Grupo Caja Rural y Grupo Cooperativo Cajamar, cajeros de Banco
        Popular y EAc, Banco Pastor y Targo Bank, Bankinter y Caja de
        Ingenieros. . ¡Son más de 16.000! Y con muchos más servicios gratis:
        transferencias y traspasos nacionales y comunitarios en euros, ingreso
        de cheques nacionales, Banca Electrónica, alertas a móvil...
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse3 = !estadoCollapse3">
      VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN COMISIONES?
    </h3>
    <div
      [ngClass]="{
        'collapse-block': estadoCollapse3,
        'collapse-none': !estadoCollapse3
      }"
    >
      <p>
        Sí, las tarjetas EVO te acompañan allí donde vayas. Con tu Tarjeta de
        Débito (la negra) o con la Tarjeta Inteligente, EVO no te cobra ni un
        céntimo por retirar dinero a débito de cajeros en el extranjero. Puedes
        hacerlo todas las veces que quieras y sin cantidad mínima. Estés en el
        país que estés. Eso sí, es posible que, aunque EVO no te cobre ninguna
        comisión por sacar dinero con la Tarjeta de Débito o la Tarjeta
        Inteligente, fuera de la Unión Europea las entidades propietarias de los
        cajeros pueden aplicar una tasa de recargo por su utilización. En todo
        caso, y para tu tranquilidad (y la nuestra) es obligatorio que, para que
        puedan cobrarte esta comisión, el cajero te avise por pantalla y te
        especifique cuánto te va a costar. Si quieres, podrás cancelar la
        operación, porque no todos los cajeros cobran esta tasa e incluso puede
        no ser siempre la misma.
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse4 = !estadoCollapse4">
      ¿PUEDO AÑADIR UN SEGUNDO TITULAR?
    </h3>
    <div
      [ngClass]="{
        'collapse-block': estadoCollapse4,
        'collapse-none': !estadoCollapse4
      }"
    >
      <p>
        ¡No hay problema! Porque la Cuenta Inteligente admite dos titulares. Eso
        sí, cada cliente, solo podrá ser titular de una única Cuenta Inteligente
        EVO, así que tú decides: solo/a, o con pareja.
      </p>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p
      [ngClass]="modal.withImage ? '' : 'invalid-text'"
      [innerHTML]="modal.message"
    ></p>
  </div>
</modal>

<modal
  [modalTitle]="'Email caracteres especiales'"
  [blocking]="true"
  [full-screen]="true"
  [modalId]="errorSendModalEmail"
  class="modal"
>
  <div class="container">
    <h2 style="text-align: center">¡VAYA!</h2>
    <p>
      El email contiene caracteres especiales, lo que impide continuar con el
      proceso. Es necesario que introduzcas uno válido .
    </p>
    <div class="btn-group modal-accept-button">
      <button
        type="button"
        class="btn-common btn-common_fix_bold"
        (click)="closeErrorEmailSend()"
      >
        ACEPTAR
      </button>
    </div>
  </div>
</modal>

<div *ngIf="modalBureau" class="modal-backdrop" id="backdropId">
  <div class="modal-container" (click)="modalClick($event)">
    <div class="image-modal"></div>
    <span class="evo close ko cross" (click)="closeModal()"></span>
    <div class="content">
      <h2>¡VAYA!</h2>
      <p class="invalid-text">
        Lo sentimos, no podemos seguir con la solicitud online. Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te
        explicamos los motivos.
      </p>
    </div>
  </div>
</div>

<modal [modalId]="alreadyClientTestAB" [blocking]="false" class="modal">
  <div class="container-user-info">
    <div class="row-ic-triangulo-exclamacion">
      <p class="ic-triangulo-exclamacion"></p>
    </div>
    <div class="row-title">
      <p class="oh-vaya">¡Vaya! Algo no cuadra...</p>
    </div>

    <p class="info-text">Por favor , dinos qué necesitas :</p>
    <div
      [class]="optionExistUserInfo === 'cuenta' ? 'row-selected' : 'row-option'"
      [ngClass]="
        optionExistUserInfo && optionExistUserInfo !== 'cuenta'
          ? 'disabled-info'
          : 'abled-info'
      "
      (click)="showInfoUserExist('cuenta')"
    >
      <p>Quiero abrir una Cuenta Inteligente.</p>
    </div>
    <div *ngIf="optionExistUserInfo === 'cuenta'" class="explication-row">
      <div class="ic_bombilla_apagada"></div>
      <div>
        <p>
          Sólo puedes tener una Cuenta Inteligente. Si deseas añadir un segundo
          titular puedes hacerlo accediendo a nuestra
          <a
            href="https://bit.ly/3uVOKqR"
            (click)="getInfoAndUpdateAnalitics('Banca electrónica')"
          >
            Banca Electrónica
          </a>
          o desde
          <a
            href="https://bit.ly/3fY4JjZ"
            (click)="getInfoAndUpdateAnalitics('app')"
          >
            nuestra app
          </a>
        </p>
      </div>
    </div>
    <div
      [class]="optionExistUserInfo === 'claves' ? 'row-selected' : 'row-option'"
      [ngClass]="
        optionExistUserInfo && optionExistUserInfo !== 'claves'
          ? 'disabled-info'
          : 'abled-info'
      "
      (click)="showInfoUserExist('claves')"
    >
      <p>Tengo problemas con mis claves de acceso.</p>
    </div>
    <div *ngIf="optionExistUserInfo === 'claves'" class="explication-row">
      <div class="ic_bombilla_apagada"></div>
      <div>
        <p>
          Si no consigues acceder con tus claves ,
          <a
            href="https://bit.ly/3im0Sia"
            (click)="getInfoAndUpdateAnalitics('aqui')"
          >
            aquí</a
          >
          te explicamos como puedes hacerlo.
        </p>
      </div>
    </div>
    <div
      [class]="optionExistUserInfo === 'dudas' ? 'row-selected' : 'row-option'"
      [ngClass]="
        optionExistUserInfo && optionExistUserInfo !== 'dudas'
          ? 'disabled-info'
          : 'abled-info'
      "
      (click)="showInfoUserExist('dudas')"
    >
      <p>Tengo dudas sobre la Cuenta Inteligente.</p>
    </div>
    <div *ngIf="optionExistUserInfo === 'dudas'" class="explication-row">
      <div class="ic_bombilla_apagada"></div>
      <div>
        <p>
          Para más información sobre la Cuenta Inteligente<a
            href="https://bit.ly/2SZp0wx"
            (click)="getInfoAndUpdateAnalitics('dudas-aqui')"
          >
            aquí</a
          >
          o también puedes enviar un correo
          <a
            href="mailto:evo_soportelab@evobanco.com"
            (click)="getInfoAndUpdateAnalitics('buzon laboratorio')"
            >aquí</a
          >
        </p>
      </div>
    </div>
  </div>
</modal>

<modal [modalId]="alreadyClientModal" [blocking]="true" class="modal">
  <div class="container-user-info">
    <div class="row-ic-triangulo-exclamacion">
      <p class="ic-triangulo-exclamacion"></p>
    </div>
    <div class="row-title">
      <p class="oh-vaya">¡Vaya! Algo no cuadra...</p>
    </div>
    <div class="row-info">
      <p class="nuestro-flujo-de-con">
        Si quieres operar con tu cuenta o añadir a un segundo titular, por
        favor,<span style="font-weight: bold">
          accede a nuestra Banca Electrónica o descárgate nuestra App.</span
        >
      </p>
    </div>
    <div class="row-info1">
      <p class="nuestro-flujo-de-con">
        Si lo que quieres es abrir una nueva Cuenta Inteligente,<span
          style="font-weight: bold"
        >
          lamentamos decirte que solo puedes tener una.</span
        >
      </p>
    </div>
    <div class="row-button">
      <button (click)="goToBE()" class="bt-info" name="button1">
        <p class="etiqueta">Entendido</p>
      </button>
    </div>
  </div>
</modal>

<div class="personalDetails" *ngIf="!isCIAccount">
  <div class="image" [ngClass]="{ young: isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <div class="row">
          <h2>DATOS PERSONALES</h2>
          <span *ngIf="esCuentaInteligente()">
            <!-- (mouseleave)="onMouseOut()" -->
            <img
              (click)="onMouseClick()"
              (mouseenter)="onMouseOver()"
              class="icon-info"
              src="assets/images/icon-info.svg"
            />
          </span>
          <div class="" *ngIf="mostrarFaq">
            <ul>
              <li>
                ¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA
                INTELIGENTE?
              </li>
              <li>¿HAY COMISIONES DE MANTENIMIENTO?</li>
              <li>
                VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN
                COMISIONES?
              </li>
              <li>¿PUEDO AÑADIR UN SEGUNDO TITULAR?</li>
            </ul>
          </div>
        </div>
        <p>
          Ahora que vas a formar parte de EVO, necesitamos conocerte un poco
          más.
        </p>
      </legend>
    </div>
    <form
      [formGroup]="personalDetailsForm"
      (keydown.enter)="$event.preventDefault()"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
      novalidate
    >
      <div class="row">
        <div class="mobile-container">
          <input
            type="text"
            id="dni"
            name="dni"
            autocomplete="off"
            placeholder="DNI/NIE"
            formControlName="numeroDocumento"
            (focus)="updateAnalyticsIn('numeroDocumento')"
            (focusout)="updateAnalyticsOut('numeroDocumento')"
            maxlength="9"
            class="young-account-input"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('numeroDocumento'),
                'numeroDocumento'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("numeroDocumento"),
                "numeroDocumento"
              )
            }}
          </div>
        </div>
        <!-- TODO 1: RECUPERACIÓN DE ESTA SECCIÓN -->
        <div class="mobile-container row-info" [hidden]="hideInfoResidenceCard">
          <div class="info-text">
            <div class="icono" (click)="openInfoModalNie()">
              <p class="ic-info"></p>
            </div>
            <div class="texto">
              <p class="info-letter">¿Tienes tarjeta de residencia con foto?</p>
            </div>
          </div>
          <div class="info-input">
            <div class="check-1">
              <div class="checkbox-group">
                <div class="form-checkbox">
                  <input
                    type="checkbox"
                    name="check_conditionsT"
                    id="check_conditionsT"
                    value="true"
                    type="checkbox"
                    (click)="selectResidenceCardOption('residenceCardT')"
                    formControlName="residenceCardT"
                  />
                  <label for="check_conditionsT"> Si </label>
                </div>
              </div>
            </div>
            <div class="check-2">
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  name="check_conditionsF"
                  id="check_conditionsF"
                  value="true"
                  (click)="selectResidenceCardOption('residenceCardF')"
                  formControlName="residenceCardF"
                />
                <label for="check_conditionsF"> No </label>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="mobile-container">
          <input
            type="text"
            id="nombre"
            name="nombre"
            autocomplete="off"
            placeholder="Nombre"
            formControlName="nombre"
            (focus)="updateAnalyticsIn('nombre')"
            (focusout)="updateAnalyticsOut('nombre')"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('nombre'),
                'nombre'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("nombre"),
                "nombreCI"
              )
            }}
          </div>
        </div>
        <div class="mobile-container">
          <input
            type="text"
            id="primerApellido"
            name="primerApellido"
            autocomplete="off"
            placeholder="Primer apellido"
            formControlName="primerApellido"
            (focus)="updateAnalyticsIn('primerApellido')"
            (focusout)="updateAnalyticsOut('primerApellido')"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('primerApellido'),
                'primerApellido'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("primerApellido"),
                "primerApellido"
              )
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mobile-container">
          <input
            type="text"
            id="segundoApellido"
            name="segundoApellido"
            autocomplete="off"
            placeholder="Segundo apellido"
            formControlName="segundoApellido"
            (focus)="updateAnalyticsIn('segundoApellido')"
            (focusout)="updateAnalyticsOut('segundoApellido')"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('segundoApellido'),
                'segundoApellido'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("segundoApellido"),
                "segundoApellido"
              )
            }}
          </div>
        </div>
        <div class="mobile-container">
          <input
            type="text"
            id="email"
            name="email"
            autocomplete="off"
            placeholder="Email"
            formControlName="email"
            (focus)="updateAnalyticsIn('email')"
            (focusout)="updateAnalyticsOut('email')"
          />
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('email'),
                'email'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("email"),
                "email"
              )
            }}
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox" (click)="modalOpenCondiciones($event)">
            <input
              type="checkbox"
              name="check_conditions"
              id="check_conditions"
              value="Acepto"
              formControlName="reactiveCheckTipoA"
            />
            <label
              for="check_conditions"
              (keypress)="selectCheckTipoA($event)"
              tabindex="1"
              >He leido y acepto los <a>Términos y Condiciones </a>
            </label>
            <div
              class="error-text"
              *ngIf="
                personalDetailsForm
                  .get('reactiveCheckTipoA')
                  .hasError('notChecked')
              "
            >
              {{ errors.checkMandatory }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input
              type="checkbox"
              name="check_prefilado"
              id="check_prefilado"
              value="Acepto"
              formControlName="reactiveCheckPerfilado"
            />
            <label
              id="check_prefilado_label"
              for="check_prefilado"
              (keypress)="selectCheckConsentimiento($event)"
              tabindex="2"
            >
              {{ checks.perfilado }}
            </label>
          </div>
        </div>
      </div>
      <br />
      <div class="btn-group">
        <button
          class="button btn-common"
          (click)="this.modService.close(this.saveModal)"
        >
          Entendido
        </button>
      </div>
    </form>
  </div>
</div>

<modal modalTitle="SaveDataSuccessModalCI" [modalId]="saveModal" class="modal">
  <div class="save-data-success-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">Tus datos han sido guardados correctamente</p>
      <p class="sub-title">
        Recuerda que <strong>puedes volver donde lo dejaste.</strong> Solo
        tendrás que incluir tu teléfono y un código de seguridad que recibirás
        en tu móvil.
      </p>
      <button class="button btn-common">Entendido</button>
    </div>
  </div>
</modal>

<modal modalTitle="BackModalCI" [modalId]="backModal" class="modal">
  <div class="back-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">¿Quieres guardar antes de salir?</p>
      <p class="sub-title">
        Guarda la información para retomar el proceso donde lo dejaste la
        próxima vez que entres.
      </p>
      <button
        class="btn-common button"
        (click)="this.modService.close(this.backModal); saveData()"
      >
        Guardar
      </button>
      <button
        class="btn-common button button-back"
        (click)="this.modService.close(this.backModal)"
      >
        Ahora no
      </button>
    </div>
  </div>
</modal>

<div class="main-register flex-direction" *ngIf="isCIAccount">
  <box-image
    [classImgFile]=" !isYoungAccount ? 'datos_personales02' : 'newDesign-img' "
    [sizeBox]="'tabletSize'"
    [icon]="'ic-billetes-cajero'"
    [titleCss]="'title-2 margin-ld'"
    [subtitleCss]="'personal-details-subtitle margin-ld'"
    [title]=" !isYoungAccount ? 'SACA EFECTIVO SIEMPRE QUE QUIERAS' : 'Cajeros sin comisiones' "
    [textBox]=" !isYoungAccount ? 'text-personal-detais' : 'newDesign-text-box' "
    [subtitle]=" !isYoungAccount ?
      'En España tienes más de 18.000 cajeros gratis a débito sin cantidad mínima y sin límite de retiradas. Cualquier otro cajero en España también es gratis si retiras 200€ o más. En el extranjero nunca te cobraremos nada por retirar dinero a débito. Es posible que las entidades propietarias del cajero apliquen alguna tasa.' : '18.000 cajeros son muchos cajeros sin comisiones.'"
    class="image-div"
  ></box-image>
  <div class="form-and-step-div">
    <steps-ci [youngAccount]="isYoungAccount" class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div">
      <p class="letter title">Datos personales</p>
      <p class="letter subtitle">
        Ahora que vas a formar parte de EVO, necesitamos conocerte un poco más.
      </p>
      <!-- Focus en el input del dni al mostrar pantalla -->
      {{
        personalDetailsForm.get("numeroDocumento").value ? "" : dniInput.focus()
      }}
      <form
        [formGroup]="personalDetailsForm"
        (keydown.enter)="$event.preventDefault()"
        (ngSubmit)="onSubmit()"
        autocomplete="off"
        novalidate
      >
        <div class="floating-placeholder-group row-ci one-line"
        [ngClass]="{'container-newDesign': isYoungAccount}"
        >
        <label
          *ngIf="isYoungAccount"
            class="label-newDesign"
            >DNI/NIE</label
          >
          <input
            type="dni-ci"
            required
            #dniInput
            no-whitespace
            class="dni-ci"
            autocomplete="off"
            tabindex="1"
            formControlName="numeroDocumento"
            (focus)="updateAnalyticsIn('numeroDocumento')"
            (focusout)="updateAnalyticsOut('numeroDocumento')"
            maxlength="9"
            [ngClass]="{'input-noEmpty': personalDetailsForm.get('numeroDocumento').value,
                        'input-empty': !personalDetailsForm.get('numeroDocumento').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
            [placeholder]="isYoungAccount? placeHolderText: ''"
          />
          <label
          *ngIf="!isYoungAccount"
            class="floating-placeholder"
            [ngClass]="isTouchedOfhaveValue() ? '' : 'no-width'"
            >DNI/NIE</label
          >

          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('numeroDocumento'),
                'numeroDocumento'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("numeroDocumento"),
                "numeroDocumento"
              )
            }}
          </div>
        </div>
        <!-- TODO 3: RECUPERACIÓN DE ESTA SECCIÓN -->
        <div class="row margin-desktop" [hidden]="hideInfoResidenceCard">
          <div class="mobile-container row-info">
            <div class="info-text">
              <div class="icono-ci" (click)="openInfoModalNie()">
                <p class="ic-info-ci"></p>
              </div>
              <div class="texto-ci">
                <p class="letter subtitle">
                  ¿Tienes tarjeta de residencia con foto?
                </p>
              </div>
            </div>
            <div class="info-input-ci">
              <div class="check-1">
                <div class="checkbox-group">
                  <div
                    class="form-checkbox-cd"
                    (click)="selectResidenceCardOption('residenceCardT')"
                  >
                    <input
                      type="checkbox"
                      name="check_conditionsT"
                      id="check_conditionsT"
                      value="true"
                      formControlName="residenceCardT"
                    />
                    <label class="letter subtitle"> Si </label>
                  </div>
                </div>
              </div>
              <div class="check-2">
                <div
                  class="form-checkbox-ci"
                  (click)="selectResidenceCardOption('residenceCardF')"
                >
                  <input
                    type="checkbox"
                    name="check_conditionsF"
                    id="check_conditionsF"
                    value="true"
                    (click)="selectResidenceCardOption('residenceCardF')"
                    formControlName="residenceCardF"
                  />
                  <label class="letter subtitle"> No </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        [ngClass]="{'container-newDesign': isYoungAccount}"
        class="floating-placeholder-group row-ci one-line">
          <label
          *ngIf="isYoungAccount"
            class="label-newDesign"
            >Nombre</label
          >
          <input
            type="nombre-ci"
            required
            tabindex="1"
            class="nombre-ci"
            formControlName="nombre"
            (focus)="updateAnalyticsIn('nombre')"
            (focusout)="updateAnalyticsOut('nombre')"
            (input)="toUpperCase('nombre', personalDetailsForm.get('nombre').value)"
            [ngClass]="{'input-noEmpty': personalDetailsForm.get('nombre').value,
                        'input-empty': !personalDetailsForm.get('nombre').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
            [placeholder]="isYoungAccount? placeHolderText: ''"
          />
          <label
          *ngIf="!isYoungAccount"
          class="floating-placeholder">Nombre</label>
          <div
            class="error-text"
            *ngIf="
              errService.desktopErrors(
                personalDetailsForm.get('nombre'),
                'nombre'
              )
            "
          >
            {{
              errService.desktopErrors(
                personalDetailsForm.get("nombre"),
                "nombreCI"
              )
            }}
          </div>
        </div>
        <div
        [ngClass]="{'container-newDesign': isYoungAccount}"
         class="floating-placeholder-group row-ci">
          <div class="col-ci-left">
            <label
          *ngIf="isYoungAccount"
            class="label-newDesign"
            >Primer Apellido</label
          >
            <input
              type="text-ci"
              required
              class="text-ci"
              formControlName="primerApellido"
              (focus)="updateAnalyticsIn('primerApellido')"
              (focusout)="updateAnalyticsOut('primerApellido')"
              (input)="toUpperCase('primerApellido', personalDetailsForm.get('primerApellido').value)"
              [ngClass]="{'input-noEmpty': personalDetailsForm.get('primerApellido').value,
                        'input-empty': !personalDetailsForm.get('primerApellido').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
              [placeholder]="isYoungAccount? placeHolderText: ''"

            />
            <label
            *ngIf="!isYoungAccount"
            class="floating-placeholder">Primer apellido</label>
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  personalDetailsForm.get('primerApellido'),
                  'primerApellido'
                )
              "
            >
              {{
                errService.desktopErrors(
                  personalDetailsForm.get("primerApellido"),
                  "primerApellido"
                )
              }}
            </div>
          </div>
          <div
          [ngClass]="{'container-newDesign': isYoungAccount}"
            class="col-ci-right">
            <label
            *ngIf="isYoungAccount"
            class="label-newDesign"
            >Segundo Apellido</label
          >
            <input
              type="text-ci"
              required
              class="text-ci"
              autocomplete="off"
              formControlName="segundoApellido"
              (focus)="updateAnalyticsIn('segundoApellido')"
              (focusout)="updateAnalyticsOut('segundoApellido')"
              (input)="toUpperCase('segundoApellido', personalDetailsForm.get('segundoApellido').value)"
              [ngClass]="{'input-noEmpty': personalDetailsForm.get('segundoApellido').value,
                        'input-empty': !personalDetailsForm.get('segundoApellido').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
              [placeholder]="isYoungAccount? placeHolderText: ''"
            />
            <label
            *ngIf="!isYoungAccount"
            class="floating-placeholder">Segundo apellido</label>
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  personalDetailsForm.get('segundoApellido'),
                  'segundoApellido'
                )
              "
            >
              {{
                errService.desktopErrors(
                  personalDetailsForm.get("segundoApellido"),
                  "segundoApellido"
                )
              }}
            </div>
          </div>
        </div>
        <div
        [ngClass]="{'container-newDesign': isYoungAccount}"
        class="floating-placeholder-group row-ci">
          <!-- Autocompletar del campo email -->
          <div class="col-ci-left">
            <label
          *ngIf="isYoungAccount"
            class="label-newDesign"
            >Email</label
          >
            <input
              type="text-ci"
              required
              class="text-ci"
              autocomplete="on"
              formControlName="email"
              (focus)="updateAnalyticsIn('email')"
              (focusout)="updateAnalyticsOut('email')"
              (input)="inputChangeEmail()"
              (keydown.tab)="tabKeyPressedEmail()"
              (click)="tabKeyPressedEmail()"
              style="position: relative; background-color: transparent"
              [ngClass]="{'input-noEmpty': personalDetailsForm.get('email').value,
                        'input-empty': !personalDetailsForm.get('email').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
              [placeholder]="isYoungAccount? placeHolderText: ''"
            />
            <!-- Input donde se muestra el placeholder del autocompletado para el campo email -->
            <input
              type="text"
              disabled
              style="
                position: absolute;
                top: 2px;
                left: 1px;
                color: grey;
                z-index: -1;
                height: 3px;
                border: none;
                background-color: transparent;
              "
              [value]="placeholderEmailValue"
            />
            <label
              *ngIf="!isYoungAccount"
              class="floating-placeholder">Email</label>
            <div
              class="error-text"
              *ngIf="
                errService.desktopErrors(
                  personalDetailsForm.get('email'),
                  'email'
                )
              "
            >
              {{
                errService.desktopErrors(
                  personalDetailsForm.get("email"),
                  "email"
                )
              }}
            </div>

          </div>
          <!-- Autocompletar del campo email -->
          <div
          [ngClass]="{'container-newDesign': isYoungAccount}"
          class="col-ci-right">
          <label
          *ngIf="isYoungAccount"
            class="label-newDesign"
            >Confirmar Email</label
          >
            <input
              type="text-ci"
              required
              class="text-ci"
              autocomplete="on"
              formControlName="confirmEmail"
              [attr.disabled]="
                personalDetailsForm.get('email').value === '' ? true : null
              "
              onPaste="return false"
              (focus)="updateAnalyticsIn('confirmEmail')"
              (focusout)="updateAnalyticsOut('confirmEmail')"
              (input)="inputChangeConfirmEmail()"
              (keydown.tab)="tabKeyPressedConfirmEmail()"
              (click)="tabKeyPressedConfirmEmail()"
              style="position: relative; background-color: transparent"
              [ngClass]="{'input-noEmpty': personalDetailsForm.get('confirmEmail').value,
                        'input-empty': !personalDetailsForm.get('confirmEmail').value,
                        'input-newDesign young-account-input': isYoungAccount
                        }"
              [placeholder]="isYoungAccount? placeHolderText: ''"
            />
            <!-- Input donde se muestra el placeholder del autocompletado para el campo email -->
            <input
              type="text"
              disabled
              [ngClass]="confirmEmail ? 'input-noEmpty' : 'input-empty'"
              style="
                position: absolute;
                top: 2px;
                left: 1px;
                color: grey;
                z-index: -1;
                height: 3px;
                border: none;
                background-color: transparent;
              "
              [value]="placeholderConfirmEmailValue"
            />
            <label
            *ngIf="!isYoungAccount"
             class="floating-placeholder">Confirmar Email</label>

            <!-- <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('confirmEmail'), 'confirmEmail')">
              {{ errService.desktopErrors(personalDetailsForm.get('confirmEmail'), 'confirmEmail') }}
            </div> -->

            <div
              class="error-text"
              *ngIf="
                personalDetailsForm
                  .get('confirmEmail')
                  .getError('emailConfirmation')
              "
            >
              {{ errors.correosNoCoinciden }}
            </div>
            <div
              class="error-text"
              *ngIf="
                personalDetailsForm
                  .get('confirmEmail').value === '' && personalDetailsForm.get('confirmEmail').touched

              "
            >
              {{ errors.emailValido }}
            </div>
          </div>
        </div>
        <div class="row-ci margin-check">
          <div class="legal-switch-container">
            <div class="switch-title text">
              Selecciona el uso principal que le vas a dar a tu cuenta
            </div>
            <div
            [ngClass]="{'container-multiselect-newDesign': isYoungAccount}"
            class="container-multiselect">
             <div class="container-multiselect-content-label">
                  <label
                  *ngIf="!isYoungAccount"
                 [ngClass]="{
                 'floating-placeholder': personalDetailsForm.get(
                 'businessRelationship').valid || !personalDetailsForm.get('businessRelationship').touched,
                 'floating-placeholder-error': personalDetailsForm.get(
                  'businessRelationship').invalid && personalDetailsForm.get('businessRelationship').touched
                 }"
                   >Uso de la cuenta</label
                  >
                  <label
                  *ngIf="isYoungAccount"
                  class="label-newDesign"
                  >Uso de la Cuenta</label
               >
               </div>
                <p-multiSelect
                  [options]="businessRelationship"
                  [(ngModel)]="selectedBusinessRelationship"
                  formControlName="businessRelationship"
                  [panelStyle]="{ minWidth: '12em' }"
                  [filter]="false"
                  [showToggleAll]="false"
                  [showHeader]="!isYoungAccount"
                >
                  <ng-template let-value pTemplate="selectedItems">
                    <div
                      *ngFor="let val of value"
                      class="ui-multiselected-item-token ui-corner-all"
                    >
                      <span>{{ val }}
                        <button class="remove-item" (click)="removeItem(val, $event)">X</button>
                      </span>
                    </div>
                    <span
                      *ngIf="!value || value.length === 0"
                      class="ui-multiselected-empty-token ui-corner-all"
                      >Escoge el propósito principal de la cuenta</span
                    >
                  </ng-template>
                  <ng-template let-businessRelationship pTemplate="item">
                    <div style="font-size: 14px; float: right; margin-top: 4px">
                      {{ businessRelationship.label }}
                    </div>
                  </ng-template>
                </p-multiSelect>
                <div
                  class="error-text"
                  *ngIf="
                    personalDetailsForm
                      .get('businessRelationship').invalid && personalDetailsForm
                      .get('businessRelationship').touched
                  "
                >
                  {{ errors.propositoNegocio }}
              </div>
            </div>


            <div class="switch-checkbox-container">
              <div class="text">Aceptar todos los permisos</div>
              <label class="switch">
                <input type="checkbox" formControlName="selectAllPermissions" />
                <span class="bg-slider round"></span>
                <span class="slider-ball round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row-ci margin-check">
          <div class="checkbox-group">
            <div class="form-checkbox-ci">
              <input
                type="checkbox"
                name="check_prefilado"
                id="check_prefilado"
                value="Acepto"
                formControlName="reactiveCheckPerfilado"
              />
              <label
                class="check-text-dp-ci"
                for="check_prefilado"
                (keypress)="
                  selectCheckConsentimiento($event, 'reactiveCheckPerfilado')
                "
                tabindex="1"
              >
                <span class="text-check">{{ checks.perfilado }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row-ci">
          <div class="checkbox-group">
            <div class="form-checkbox-ci">
              <input
                type="checkbox"
                name="check_publicidad"
                id="check_publicidad"
                value="Acepto"
                formControlName="reactiveCheckPublicidad"
              />
              <label
                class="check-text-dp-ci"
                for="check_publicidad"
                (keypress)="
                  selectCheckConsentimiento($event, 'reactiveCheckPublicidad')
                "
                tabindex="2"
              >
                <span class="text-check">{{ checks.publicidad }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row-ci">
          <div class="checkbox-group">
            <div class="form-checkbox-ci">
              <input
                type="checkbox"
                name="check_cesion"
                id="check_cesion"
                value="Acepto"
                formControlName="reactiveCheckCesion"
              />
              <label
                class="check-text-dp-ci"
                for="check_cesion"
                (keypress)="
                  selectCheckConsentimiento($event, 'reactiveCheckCesion')
                "
                tabindex="3"
              >
                <span class="text-check">{{ checks.cesion }}</span>
              </label>
            </div>
          </div>
        </div>
        <hr class="line-separator hidden-movil" />
        <div class="buttonCI">
          <div class="return-text">
            <p (click)="returnInit()">
              <span class="link-otp-black"></span>
              Atrás
            </p>
          </div>
          <button type="submit" class="btn-open-account open-account">
            <p>CONTINUAR</p>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
