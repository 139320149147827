import {Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';
import { HelperService } from '../../../../services/helpers.service';

import {SmallSliderComponent, Direction} from  '../small-slider.component';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { environment } from 'environments/environment.dev';

@Component({
    selector: 'small-slide',
    template: `
    <div [class.active]="active"
      class="item text-center"
      (click)='goToInterest(slideObj["goTo"])'>
    <div class='img-container'>
      <ng-content></ng-content>
    </div>
    <div class='text'>
    <h6>{{slideObj['title']}}</h6>
    <p>{{slideObj['text']}}</p>
    </div>
    </div>
  `,
    styleUrls: ['./small-slide.component.scss'],

})
export class SmallSlide implements OnInit, OnDestroy {
    @Input() public index:number;
    @Input() public direction:Direction;
    @Input() public slideObj:Object;
    @Input() public clientID: string;
    @HostBinding('class.active')
    @Input() public active:boolean;

    @HostBinding('class.item')
    @HostBinding('class.carousel-item')
    private addClass:boolean = true;
    userData;

    constructor(
      private carousel:SmallSliderComponent,
      private helpers: HelperService,
      private authService: AuthService,
      ) {
    }

    public ngOnInit() {
        this.carousel.addSlide(this);

    }

    private signOnProduct(product = ACCOUNTS.CICode) {
      sessionStorage.setItem("reloadUserData", "true");
      const nextStep = '/confirmacion-datos',
      clientID = this.clientID,
      ibanCC = this.slideObj['ibanCC'],
      ibanCD = this.slideObj['ibanCD'];
      sessionStorage.removeItem("currentProspect");
      sessionStorage.setItem('product-selected', JSON.stringify({ ibanCC, ibanCD, codProductoContratado: product, clientID}));
      this.helpers.navigateTo(nextStep);
    }

    private signOnProductDuo(product = ACCOUNTS.CDCode) {
      sessionStorage.setItem("reloadUserData", "true");
      const nextStep = '/confirmacion-datos',
      clientID = this.clientID,
      ibanCC = this.slideObj['ibanCC'],
      ibanCD = this.slideObj['ibanCD'];
      sessionStorage.removeItem("currentProspect");
      sessionStorage.setItem('product-selected', JSON.stringify({ ibanCC, ibanCD, codProductoContratado: product, clientID}));
      this.helpers.navigateTo(nextStep);
    }

    public goToInterest(str) {
      switch (str) {
        case 'laboratorio':
          window.open('https://www.evobanco.com/servicios-digitales/smartphone-banking/laboratorio-de-ideas/')
          break;
        case 'inversion' :
          window.open('https://www.evobanco.com/productos-inteligentes/prevision-y-seguros/plan-inversion-inteligente/')
          break;
        case 'travel':
          window.open('https://www.evobanco.com/servicios-digitales/promociones-evo-banco/evo-travel-cash/')
          break;
        case 'evoProtect':
          const nextStepEvoProtect = '/evo-protect/simulador-publico';
          if (environment.production) {
            window.location.href = environment.evoprotectUrl;
          } else {
            this.helpers.navigateTo(environment.evoprotectUrl);
          }
          break;
        case 'cuentaInteligente':
        case 'cuentaJoven':
          this.signOnProduct(this.slideObj['idProducto'])
          break;
        case 'cuentaDuo':
        //this.signOnProductDuo(this.slideObj['idProducto'])
        this.helpers.navigateTo('info-cuenta-duo');
          break;
        case 'microseguros':
          this.authService.product = ACCOUNTS.MSCode;
          sessionStorage.setItem("reloadUserData", "true");
          const nextStepMicroinsurances = '/evo-protect/configura-microseguro';
          this.helpers.navigateTo(nextStepMicroinsurances);
          break;
      }
    }

    public ngOnDestroy() {
        this.carousel.removeSlide(this);
    }
}
