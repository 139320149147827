<loading [showMe]="loading"></loading>
<div class="main-register flex-direction">
  <div class="verify-identity" >
    <steps-ci class="step-component-verify"></steps-ci>
    <hr class="line-separator-visibility" />
    <div class="verify-identity__container">
      <div class="verify-identity__options">
        <div class="verify-identity__option">
          <div class="verify-identity__option-desc">
            A continuación necesitamos que nos presente el 
            <strong>Certificado de Registro de Ciudadano de la UE.</strong>
              </div>
          <div class="verify-identity__image-wrapper">
            <img src="../../../../assets/images/certificado-de-registro.png" alt="Certificado Ciudadano" class="identity-nie-image">
          </div>

        <div class="verify-identity__option-action">
          <button type="submit" 
                  class="btn-common btn-common_fix_bold verify-identity__option-action-button"
                  (click)="onSubmit()" >
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>