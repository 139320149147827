import { Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { VideoSelectionService } from '../../../services/video-selection.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { RecomiendaService } from '../../../services/recomienda.service';
import { ModalService } from '../../../services/modal.service';
import { AuthService } from '../../../services/auth.service';
import { ProductSelection } from '../../../services/product-selection.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { InjectScriptService } from '../../../services/inject-script.service';

@Component({
  selector: 'app-everything-first-sign',
  templateUrl: './everything-first-sign.component.html',
  styleUrls: ['./everything-first-sign.component.scss'],
  providers: [
    FormValidatorService,
    VideoSelectionService,
    ModalService,
    RecomiendaService
  ]
})
export class EverythingFirstSignerComponent implements OnInit {

  recomiendaURL:string;
  showPlay = true;
  recomiendaModal = 'recomienda';
  vidPlaying = false;
  alreadyClientModal = 'already-client';
  loginUser: FormGroup;
  loading = false;
  errMsg;
  private passStr = '';
  public firstTouch: boolean = true;
  public firstTouchPrevented: boolean = true;

  @ViewChild('video') public video:ElementRef;
  @ViewChild('iframereco') public iframereco: ElementRef;
  constructor(private router: Router,
              public vidService: VideoSelectionService,
              private renderer: Renderer2,
              private analytics: AnalyticsService,
              private helpers: HelperService,
              private recomienda: RecomiendaService,
              public modService: ModalService,
              public authService: AuthService,
              private fb: FormBuilder,
              private product: ProductSelection,
              private evoValidator:FormValidatorService,
              public errService:ErrorService,
              private inject: InjectScriptService,
              ) {  }

  ngOnInit() {
    this.authService.isLoggedIn = false;
    this.createFormLogin();
    this.vidService.selectVideo();
    this.startAnalytics();
  }

  goToAppStore() {
    this.analytics.triggerInteractionDataCICJ('Descarga app', 'iOS')
    //TODO route to appstore
    window.open('http://apple.co/2AcrBrr');
  }

  goToPlayStore() {
    this.analytics.triggerInteractionDataCICJ('Descarga app', 'Android')
    //TODO route to appstore
    window.open('http://bit.ly/2Bwntkj');
  }

  goToInfo() {
    window.open('http://eresinteligente.evobanco.es/');
  }

  goToRecomienda() {
    const sessionProspect = this.authService.getSessionProspect(),
          email = sessionProspect.datosPersonales.email,
          productoContratado = sessionProspect.logalty.codProductoContratado;
    this.recomiendaURL = this.recomienda.getRecomiendaURL(email, productoContratado);
    this.modService.open(this.recomiendaModal);
    this.iframereco.nativeElement.src = this.recomiendaURL;
  }

  closeRecomienda(event: any) {
    this.renderer.addClass(event.path[1], 'no-display');
  }

  goToProfile() {
    /*this.modService.open(this.alreadyClientModal);
    const nextStep = '/login';
    this.helpers.navigateTo(nextStep);*/
    window.location.href = 'https://ecommerce.evobanco.com';
  }

  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');
    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };

    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;

    this.loading = true;
    const tag = 'GTM-TFMGJVG';
    this.inject.addScriptGoogle('script', tag);
    //this.inject.addScriptGoogle('noscript', tag);
    this.authService.login(login).subscribe(data => {
      this.loading = false;
      this.analytics.triggerProfileFormData('Log in', 'Envio OK');
      if (this.product.product && this.product.product.codProductoContratado === '999') {
        this.helpers.navigateTo('confirmacion-datos');
      } else {
        this.helpers.navigateTo('perfil');
      }
      this.modService.close(this.alreadyClientModal);
    }, error => {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: 500 Server error');
      this.loading = false;
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
      correctamente los datos. Recuerda que por seguridad,
      tres errores consecutivos en la contraseña bloquearán el acceso`;
      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    });
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.get('user').valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  hidePlayer() {
    return this.showPlay = false;
  }

  playVid() {
    if (!this.vidPlaying) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
    this.vidPlaying = !this.vidPlaying
  }

  startAnalytics() {
    this.analytics.triggerInvSegundoTitularNavData({'step': 'Paso 12 - Cuenta firmada',
                                                    'clientType': 'Cliente Tipo B'});
    this.analytics.triggerSeguntoTitularPurchase({'step': 'Paso 12 - Cuenta firmada'});
  }
}
