import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../collections/user';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GetContractResponse } from '../../collections/get-contract';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiUserService {
  private refresh: object = {};
  private options = {};
  constructor(
    private http: HttpClient) {
      this.options = { withCredentials: true, observe: 'response' };
    }

  // TODO
  // API: POST /holders
  public getUserData(user): Observable<User> {

    return this.http
      .post(`${API_URL}/fullonline/salesforce/personainfo/v1/login`,
            user,
            this.options)
            .pipe(
              map(res => {
                return res['body'];
              })
            )
  }

  public getMyContract(codigoProducto): Observable<GetContractResponse>  {
    return this.http
    .get(API_URL + '/fullonline/integracion/firma/contract?codProductoContratado=' + codigoProducto,
    this.options)
    .pipe(
      map(res => {
        return res['body'];
      })
    )
  }

}
