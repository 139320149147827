import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HelperService } from 'app/services/helpers.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { AuthService } from 'app/services/auth.service';
import { ModalService } from 'app/services/modal.service';

import { Modal } from 'app/collections/modal';
import { Persona } from 'app/collections/persona';
import { ErrorService } from 'app/services/error.service';



@Component({
  selector: 'app-more-25years-redirect',
  templateUrl: './more-25years-redirect.component.html',
  styleUrls: ['./more-25years-redirect.component.scss'],
  providers: [ModalService]

})
export class More25yearsComponentRedirect implements AfterViewInit {

  loading: boolean = false;
  persona: Persona;
  public modal = new Modal();


  constructor(
    private helper: HelperService,
    public analytics: AnalyticsService,
    private authService: AuthService,
    public modService: ModalService,
    public errService: ErrorService,
  ) { }

  ngOnInit() {
    this.persona = JSON.parse(sessionStorage.getItem("persona"));
  }

  ngAfterViewInit() {
    document.getElementById('user-path').classList.add('hide');
  }
  gotoCI() {
    this.analytics.triggerInteractionData('¿Más de 25?', 'Tengo más de 25');

    this.authService.updateProspect(this.persona).subscribe((data) => {
      if (data.response.codigo === 'OK') {
        this.helper.navigateTo('/previa-firma');
      } else {
        this.modal = this.modService.selectStatusModal(data.response);
        this.modService.open(this.modal.name, this.modal.blocking);

      }
    }, error => {
      this.errService.navToError();
    });
  }

}
