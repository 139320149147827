import { Injectable } from '@angular/core';
import { ResponseLogin } from '../collections/login';
import { ApiLoginService } from './api/api.login.service';
import { Observable } from 'rxjs';
import { AuthTokenService } from 'app/services/token/auth.token.service';
import { Persona } from 'app/collections/persona';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class LoginService {
  private _token:Object;
  private _camposError = [];
  private _userInfo;

  private _campanyaSubDoc;
  private _dniCaducado;

  private itemsSubject = new Subject<boolean>();
  public itemsObservable$ = this.itemsSubject.asObservable();


  constructor(private api: ApiLoginService, private authTokenSrv: AuthTokenService) { }

  getLogin(login):Observable<ResponseLogin>{
    this.itemsSubject.next(true);
    return this.api.login(login);
  }

  get loginData(): ResponseLogin{
    return this.api.sessionLogin;
  }

  get isLoggedIn(): boolean{
    return this.authTokenSrv.isAuthenticated();
  }

  logOut() {
    sessionStorage.clear();
  }

  set tokenUser(token){
    this._token = token;
  }

  get tokenUser(){
    return this._token;
  }

  set userData(data) {
    sessionStorage.setItem("userData", JSON.stringify(data));
  }

  get userData() {
    const userData = sessionStorage.getItem("userData");
    return JSON.parse(userData);
  }

  set userInfo(data) {
    this._userInfo = JSON.stringify(data);
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  }

  get userInfo() {
    this._userInfo = sessionStorage.getItem("userInfo");
    return JSON.parse(this._userInfo);
  }

  set listPolicies(data) {
    sessionStorage.setItem("listPolicies", JSON.stringify(data));
  }

  get listPolicies() {
    const listPolicies = sessionStorage.getItem("listPolicies");
    return JSON.parse(listPolicies);
  }

  set camposError(camposError) {
    this._camposError = camposError;
  }

  get camposError() {
    return this._camposError;
  }

  set campanyaSubDoc(data) {
    this._campanyaSubDoc = JSON.stringify(data);
    sessionStorage.setItem("subidaDocumentacion", this._campanyaSubDoc);
  }

  get campanyaSubDoc() {
    this._campanyaSubDoc = sessionStorage.getItem("subidaDocumentacion");
    return JSON.parse(this._campanyaSubDoc);
  }

  set dniCaducado(data) {
    this._dniCaducado = JSON.stringify(data);
    sessionStorage.setItem("dniCaducado", this._dniCaducado);
  }

  get dniCaducado() {
    this._dniCaducado = sessionStorage.getItem("dniCaducado");
    return JSON.parse(this._dniCaducado);
  }

  comprobacionDatos(){
    this._camposError = [];
    let datosPersona = JSON.parse(sessionStorage.getItem("userInfo"));
    let datosObligatoriosPersonales = ['codigoEstadoCivil'];
    let datosObligatoriosDomicilio = ['codigoDomicilioTipoVia',
                                      'nombreVia',
                                      'domicilioNumero',
                                      'codigoPostal',
                                      'nombreProvinciaDomicilio',
                                      'nombreLocalidad'];
    let datosObligatoriosSocioEco = ['situacionVivienda','codigoSituacionLaboral'];

    const autonomoFields = ['codigoCNO', 'codigoCNAE', 'fechaAltaAutonomo', 'profesion'],
          cuentaAjenaFields = ['codigoCNO','numeroPagas', 'nombreEmpresa', 'codigoContrato', 
                                'profesion','ingresosMensuales','fechaAntiguedadLaboral'];

    //DATOS PERSONALES
    if (this.hasProp(datosPersona, 'datosPersonales')){

      let codigoEstadoCivilInformado = this.hasProp(datosPersona.datosPersonales, 'codigoEstadoCivil');
      let estadoCivilCasado = codigoEstadoCivilInformado && datosPersona.datosPersonales.codigoEstadoCivil === '05';

      //Añadir codigoRegimenEconomico a los campos obligatorios si casado
      if (codigoEstadoCivilInformado && estadoCivilCasado) {
        datosObligatoriosPersonales.push('codigoRegimenEconomico');
      }

      this.checkCamposObligatorios(datosPersona.datosPersonales, datosObligatoriosPersonales);

    } else {
      this._camposError = this._camposError.concat(datosObligatoriosPersonales);
    }

    //DATOS SOCIO ECO
    if (this.hasProp(datosPersona, 'datosSocioEco')){

      let situacionViviendaInformada = this.hasProp(datosPersona.datosSocioEco, 'situacionVivienda');
      let situacionLaboralInformada = this.hasProp(datosPersona.datosSocioEco, 'codigoSituacionLaboral');

      if (situacionViviendaInformada){
        let hipoteca = datosPersona.datosSocioEco.situacionVivienda === '4';
        let alquiler = datosPersona.datosSocioEco.situacionVivienda === '2';
        if (hipoteca){
          datosObligatoriosDomicilio.push('anioAdquisicion');
        }
        if (alquiler){
          datosObligatoriosDomicilio.push('cuotaAlquilerMensual');
        }
      }

      if (situacionLaboralInformada){
        let cuentaAjena = datosPersona.datosSocioEco.codigoSituacionLaboral === '01';
        let autonomo = datosPersona.datosSocioEco.codigoSituacionLaboral === '02';
        if (cuentaAjena){
          datosObligatoriosSocioEco = datosObligatoriosSocioEco.concat(cuentaAjenaFields);
        }
        if (autonomo){
          datosObligatoriosSocioEco = datosObligatoriosSocioEco.concat(autonomoFields);
        }
      }

      this.checkCamposObligatorios(datosPersona.datosSocioEco, datosObligatoriosSocioEco);
    }
    else{
      this._camposError = this._camposError.concat(datosObligatoriosSocioEco);
    }

    //DOMICILIO (ES IMPORTANTE QUE VAYA DESPUES DE SOCIO ECO ALQUILER O ANIO ADQUISICION )
    if (this.hasProp(datosPersona, 'datosDomicilio')){
      this.checkCamposObligatorios(datosPersona.datosDomicilio, datosObligatoriosDomicilio);
    } else {
      this._camposError = this._camposError.concat(datosObligatoriosDomicilio);
    }
  }

  hasProp (obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  checkCamposObligatorios(data, fields){
    let result = true;
    let camposSinInformar = [];
    fields.forEach(field => {
      if (!this.hasProp (data, field)){
        camposSinInformar.push(field);
      }
    })
    if (camposSinInformar.length > 0){
      result = false;
      this._camposError = this._camposError.concat(camposSinInformar);
    }
    return result;
  }

}
