import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'product-risk',
  templateUrl: './product-risk.component.html',
  styleUrls: ['./product-risk.component.scss']
})
export class ProductRiskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
