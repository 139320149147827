import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StepCi } from "app/collections/step-ci";
import { StepsCiService } from "./service/steps-ci.service";
@Component({
  selector: "steps-ci",
  templateUrl: "./steps-ci.component.html",
  styleUrls: ["./steps-ci.component.scss"],
  providers: [StepsCiService],
})
export class StepsCiComponent implements OnInit {
  stepsCi: StepCi[];
  classIcon: String;
  previousStep: any;
  arraowUp = "ic-flecha-up";
  arraowDown = "ic-flecha-down";

  @Input("view") view;
  @Input("youngAccount") isYoungAccount : boolean = false;

  constructor(private router: Router, private stepService: StepsCiService) {}
  ngOnInit(): void {
    this.stepsCi = this.setCurrentStep(this.stepService.getRegisterCi());   
  }
  setCurrentStep(steps) {
    const path = this.router.url.split("?")[0].replace("/", "");
    this.previousStep = this.stepService.getPassStep()
      ? this.stepService.getPassStep()
      : 0;
    let isCurrentPath;
    //Recorre cada una de las propiedades del step
    for (let step of steps) {
      isCurrentPath = false;
      for (let i = 0; i < step.subURLs.length; i++) {
        if (path === step.subURLs[i]) {
          isCurrentPath = true;
        }
        if (i === step.subURLs.length - 1) {
          // Aplica el css dependiendo de si es el paso actual , el acterior o el posterior
          this.updateValueCurrentStep(isCurrentPath, step, path);
        }
      }
    }
    return steps;
  }

  updateValueCurrentStep(isCurrentPath, step, path) {
    let cssIcon = step.icon + " " + step.icon;
    /**
     * Almaceno el id del step actual para saber si el resto de step son anteriores o posteriores
     * Marco el step actual como activo : El css marcara el div en color rosa
     * Cambia el color del icono a blanco
     */
    if (isCurrentPath) {
      this.stepService.setPassStep(step.id);
      step.icon = "";
      step.active = "active";
      step.icon = cssIcon + "-active";
      this.updateValueArrowUp(step, path);
      this.updateValueArrowDown(step, path);
    } else {
      step.icon = cssIcon + this.isFutureStep(step, path);
    }
  }
  updateValueArrowUp(step, path) {
    if (step.id <= 3 && path !== "bienvenido") {
      this.arraowUp = "ic-flecha-up-pink";
    }
  }
  updateValueArrowDown(step, path) {
    if (this.stepService.getFullMaximumPath() < step.id) {
      this.stepService.setFullMaximumPath(step.id);
    }
    if (
      step.id > 0 &&
      step.id <= 3 &&
      step.id < this.stepService.getFullMaximumPath()
    ) {
      this.arraowDown = "ic-flecha-down-pink";
    }
  }
  isFutureStep(step, path): string {
    let endOfCssClass = "-inactive";
    if (
      step.id > this.stepService.getPassStep() ||
      path === "previa-firma" ||
      path === "confirmacion-datos"
    ) {
      endOfCssClass = "-future";
    } else {
      endOfCssClass = endOfCssClass;
    }
    return endOfCssClass;
  }

  isCursorActive(step): String {
    let cursor = " cursor-inactive";
    /**Comprueba que los pasos anteriores sean los datos de contacto
     * En caso afirmativo activa la posibilidad de volver al paso anterior
     */
    if (
      this.stepService.getPassStep() < 4 &&
      step.id < this.stepService.getPassStep() &&
      step.id !== 0
    ) {
      cursor = " cursor-active";
    }
    return cursor;
  }

  async changeRoute(step) {
    if (this.isCursorActive(step) === " cursor-active") {
      //OJO Solo hay una sub-ruta ,Si cambia el json revisar lógica
      if (this.view) await this.view.saveDataBeforeGoBack();

      this.router.navigateByUrl(step.subURLs[0]);
    }
  }

  goPrevious() {
    this.changeToPrevious(this.getCurrentStep());
  }
  getCurrentStep(): any {
    const path = this.router.url.split("?")[0].replace("/", "");
    let rCurrentStep;
    //Recorre cada una de las propiedades del step
    for (let step of this.stepsCi) {
      for (let i = 0; i < step.subURLs.length; i++) {
        if (path === step.subURLs[i]) {
          rCurrentStep = step.id;
        }
      }
    }
    return rCurrentStep;
  }
  goNext() {
    this.changeToNext(this.getCurrentStep());
  }
  changeToPrevious(id) {
    if(!this.isYoungAccount){
      switch (id) {
        case 0: {
          this.router.navigateByUrl("bienvenido");
          break;
        }
        case 1: {
          this.router.navigateByUrl("bienvenido");
          break;
        }
        case 2: {
          this.router.navigateByUrl("datos-personal");
          break;
        }
        case 3: {
          this.router.navigateByUrl("datos-contacto");
          break;
        }
      }
    }
    else{
      switch (id) {
        case 0: {
          this.router.navigateByUrl("cuenta-joven/bienvenido");
          break;
        }
        case 1: {
          this.router.navigateByUrl("cuenta-joven/bienvenido");
          break;
        }
        case 2: {
          this.router.navigateByUrl("cuenta-joven/datos-personal");
          break;
        }
        case 3: {
          this.router.navigateByUrl("cuenta-joven/datos-contacto");
          break;
        }
      }
    }
  }
  changeToNext(id) {
    if(!this.isYoungAccount){
      switch (id) {
        case 1: {
          this.router.navigateByUrl("datos-contacto");
          break;
        }
        case 2: {
          this.router.navigateByUrl("datos-laboral");
          break;
        }
      }
    }
    else{
      switch (id) {
        case 1: {
          this.router.navigateByUrl("cuenta-joven/datos-contacto");
          break;
        }
        case 2: {
          this.router.navigateByUrl("cuenta-joven/datos-laboral");
          break;
        }
      }
    }
  }
}
