import { Component, HostListener, OnInit } from '@angular/core';
import { ModalService } from './../../../services/modal.service';
import { AuthService } from './../../../services/auth.service';
import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Persona } from '../../../collections/persona';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { Modal } from '../../../collections/modal';
import { ACCOUNTS } from 'app/resources/account.model';
import { ERRORSFORM } from 'app/resources/errors-form';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-logalty-ready',
  templateUrl: './logalty-ready.component.html',
  styleUrls: ['./logalty-ready.component.scss'],
  providers: [ModalService, DeviceService]
})
export class LogaltyReadyComponent implements OnInit {
  loading: boolean = false;
  persona: Persona;
  msgSrvError = '';
  modalSrvError = 'modal-srv-error';
  modal = new Modal();
  public isYoungAccount: boolean = false;
  public isCIAccount: boolean = false;
  public errors = ERRORSFORM;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    return $event.returnValue = '¿Estás seguro?';
  }
  constructor(public modService: ModalService,
    private authService: AuthService,
    public deviceService: DeviceService,
    public errService: ErrorService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService
  ) { }

  ngOnInit() {
    this.isCIAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CICode].constPath) !== -1 ||
                        this.authService.product === ACCOUNTS.CICode);
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode || location.host === environment.hostYoungAccount);
  }

  onSubmit() {
    if (this.authService.isLoggedIn) {
      const nextStep = '/proceso-firma';
      this.helpers.navigateTo(nextStep);
    } else {
      this.errService.navToError();
    }
  }
}
