import { LogaltyLoadSliderComponent } from './logalty-load-slider/logalty-load-slider.component';
// modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// custom modules
import { RegisterRoutingModule } from './register-routing.module';
import { SharedModule } from '../shared/shared.module';

import { HeaderService } from '../../services/headers.service';
import { PersonaService } from '../../services/persona.service';
import { ApiPersonService } from '../../services/api/api.persona.service';
import { SecurityService } from '../../services/security-registration.service';
import { ApiSecurityService } from '../../services/api/api.security.service';
import { LoginService } from '../../services/login.service';
import { ApiLoginService } from '../../services/api/api.login.service';
import { InactivityService } from 'app/service/inactivity.service';

import { AgmCoreModule } from '@agm/core';
// components
import { HeaderComponent } from '../../components/header/header.component';
import { HeaderNewComponent } from '../../components/header-new/header-new.component';
import { ReactiveEmailComponent } from './reactive-email/reactive-email.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { IdentifyDetailsComponent } from './identify-details/identify-details.component';
import { IdentifyVideoComponent } from './identify-video/identify-video.component';
import { IdentifyIbanComponent } from './identify-iban/identify-iban.component';
import { MobileErrorZoneComponent } from '../../components/mobile-error-zone/mobile-error-zone.component';
import { UserTooltip } from '../../components/user-tooltip/user-tooltip.component';
import { RegisterScreenComponent } from './register-screen/register-screen.component';
import { LaborDetailsComponent } from './labor-details/labor-details.component';
import { OtpDetailsComponent } from 'app/components/otp-details/otp-details.component';
import { ConfirmPersonalComponent } from './confirm-personal/confirm-personal.component'; // quitar y poner en shared
import { LogaltyComponent } from './logalty/logalty.component'; // quitar y poner en shared
import { LogaltyReadyComponent } from './logalty-ready/logalty-ready.component';
import { ErrorEidComponent } from './error-eid/error-eid.component';
import { ErrorDefaultComponent } from './error-default/error-default.component';
import { EverythingDoneComponent } from './everything-done/everything-done.component';
import { EverythingNearlyComponent } from './everything-nearly/everything-nearly.component';
import { EverythingFirstSignerComponent } from './everything-first-sign/everything-first-sign.component';
import { EverythingSecondSignComponent } from './everything-second-sign/everything-second-sign.component';
import { AbandonmentComponent } from './abandonment/abandonment.component';
import { Less25yearsComponent } from './less-25years/less-25years.component';
import { Less25yearsComponentRedirect } from './less-25years-redirect/less-25years-redirect.component';
import { More25yearsComponentRedirect } from './more-25years-redirect/more-25years-redirect.component';
import { ErrorLess25yearsComponent } from './error-less-25years/error-less-25years.component';
import { OtpConfirmComponent } from './otp-confirm/otp-confirm.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterInterceptor, TokenInterceptor } from './../../services/token/token.interceptor';
import { LogaltyLoadComponent } from './logalty-load/logalty-load.component';
import { MessageIbanLoadComponent } from './message-iban-load/message-iban-load.component';

import { OtpConfirmFirstStepComponent } from './otp-confirm-first-step/otp-confirm-first-step.component';
import { ContactDetailsCICJComponent } from './contact-details-CICJ/contact-details.component';
import { PersonalDetailsCICJComponent } from './personal-details-CICJ/personal-details.component';
import { PersonalDetailsRegisterComponent } from './personal-details-register/personal-details-register.component';

import { IdentifyIbanConfirmComponent } from './identify-iban-confirm/identify-iban-confirm.component';
import { IdentifyIbanImportComponent } from './identify-iban-import/identify-iban-import.component';

import { IberpayService } from 'app/services/iberpay.service';
import { TermAndCoditionsComponent } from './reactive-email/components/terms-and-conditions.component';
import { PrivacyTextComponent } from './reactive-email/components/privacy.component';
import { VerifyIdentitySelectComponent } from './verify-identity-select/verify-identity-select.component';
import { VerifyIdentityVideoComponent } from './verify-identity-video/verify-identity-video.component';

import { NowOurTurnComponent } from './now-our-turn/now-our-turn.component';
import { ProductRiskComponent } from './reactive-email/components/product-risk/product-risk.component';
import { WelcomeSecondHolderComponent } from './welcome-second-holder/welcome-second-holder.component';
import { FixedStepComponent } from './welcome-second-holder/fixed-step/fixed-step.component';
import { DescriptionTextComponent } from './welcome-second-holder/description-text/description-text.component';
import { ImageWelcomeComponent } from './welcome-second-holder/image-welcome/image-welcome.component';
import { ConsentComponent } from './verify-identity-select/components/consent/consent.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { RegisterNieScreenComponent } from './register-nie-screen/register-nie-screen.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RegisterRoutingModule,
    SharedModule,
    MultiSelectModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyATvr8WLPUDA6ZszdCb8WIO7biPRT28oYo', carlos
      // apiKey: 'AIzaSyDbz9vKpOsqb_FMsxEs30nIBrkf8xLHRuY', primera evo
      apiKey: 'AIzaSyCgQ8FHjMdQih5CvgHFZqyxy0Kg0VkUZP0',
      libraries: ['places']
    })
  ],
  declarations: [
    HeaderComponent,
    HeaderNewComponent,
    ReactiveEmailComponent,
    PersonalDetailsComponent,
    PersonalDetailsCICJComponent,
    PersonalDetailsRegisterComponent,
    ContactDetailsComponent,
    ContactDetailsCICJComponent,
    IdentifyDetailsComponent,
    IdentifyVideoComponent,
    LogaltyComponent,
    IdentifyIbanComponent,
    RegisterScreenComponent,
    LaborDetailsComponent,
    VerifyIdentitySelectComponent,
    VerifyIdentityVideoComponent,
    ConfirmPersonalComponent,
    UserTooltip,
    LogaltyReadyComponent,
    ErrorEidComponent,
    ErrorDefaultComponent,
    EverythingDoneComponent,
    EverythingNearlyComponent,
    EverythingFirstSignerComponent,
    EverythingSecondSignComponent,
    AbandonmentComponent,
    Less25yearsComponent,
    Less25yearsComponentRedirect,
    More25yearsComponentRedirect,
    ErrorLess25yearsComponent,
    OtpConfirmComponent,
    LogaltyLoadComponent,
    MessageIbanLoadComponent,
    OtpConfirmFirstStepComponent,
    LogaltyLoadSliderComponent,
    IdentifyIbanConfirmComponent,
    IdentifyIbanImportComponent,
    TermAndCoditionsComponent,
    PrivacyTextComponent,
    NowOurTurnComponent,
    ProductRiskComponent,
    WelcomeSecondHolderComponent,
    FixedStepComponent,
    DescriptionTextComponent,
    ImageWelcomeComponent,
    ConsentComponent,
    RegisterNieScreenComponent
  ],
  providers: [
    PersonaService,
    ApiPersonService,
    SecurityService,
    ApiSecurityService,
    LoginService,
    ApiLoginService,
    InactivityService,
    IberpayService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RegisterInterceptor,
      multi: true
    }
  ],
  entryComponents: [UserTooltip]
})
export class RegisterModule {}
