import { Injectable } from '@angular/core';
import { ErrorService } from '../error.service';
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';

 
const API_URL = environment.apiUrl;
const CLIENT_ID = environment.production ? '825b64e0-6d5e-405c-bc31-4bb7294f22f3' : '3dae7c60-08d7-41bf-a0a7-eb3e13e90066';
const CLIENT_SECRET = environment.production ? '5679b09c-f2f0-4837-b753-36827ead98a1' : '51d6fbd2-90ce-4584-a905-60d1247b1dcd';
@Injectable()
export class ApiBySideCarService {
  constructor(private http : HttpClient,
              public errService: ErrorService) { }

  // // API: POST
  public getCalled() {
    const params = `grant_type=client_credentials&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&scope=clicktocall`;
    const headers= new HttpHeaders()
  .set('Content-type', 'application/x-www-form-urlencoded')

  // Migration
/*     const options = new RequestOptions({
      headers: new Headers({
        "Content-type": "application/x-www-form-urlencoded",
      }),
    }); */
    const body = {};
    return this.http.post(
      `${API_URL}/auth/oauth/v2/token?${params}`,
      body,
      { headers }
    );
  }
  public c2cService(userInfo, token) {
    const headers= new HttpHeaders()
    .set('Authorization',  "Bearer " + token.access_token);
    const body = userInfo;
    return this.http.post(`${API_URL}/clicktocall/v4`, body, { headers }).pipe(
      map((response) => {
        return response;
      })
    );
  }

}
