<div class="footer">
  <div class="logo-container">
    <h3 class="logo"></h3>
  </div>
  <div class="frame"></div>
  <p *ngIf="showCookies"> 2018 EVO Banco, S.A.U. Todos los derechos reservados 
      |<a href="https://www.evobanco.com/politica-de-privacidad/"> Privacidad</a>
      |<a href="https://www.evobanco.com/cookies/"> Cookies </a>
      |<a href="https://www.evobanco.com/aviso-legal/"> Aviso legal </a>
  </p>
  <p *ngIf="!showCookies"></p>
</div>