<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p [ngClass]="modal.withImage ? '' : 'invalid-text'" [innerHTML]="modal.message"></p>
  </div>
</modal>

<app-header></app-header>
<div *ngIf="!isCIAccount" class="contactDetails">
  <!-- <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div> -->
  <div class="container-newCJDesigner">
    <div class="container-newCJDesigner__sideBar screenSizeError-sideBar">
      <div class="container-newCJDesigner__sideBar-img">
        <img class="desktopSizeImage" src="assets/images/img-fol-cj-10.png" alt="Firma">
        <img class="tabletSizeImage" src="assets/images/img-fol-cj-11.png" alt="Firma">
      </div>
      <div class="container-newCJDesigner__sideBar-content">
        <div class="container-newCJDesigner__sideBar-content-paragraph screenSizeError-paragraph">
          <img src="assets/images/icon-lock-feel/ic-porcentaje.svg" alt="Porcentaje">
          <h3>Descuentos para viajar</h3>
          <P>Descuentos en tus reservas al pagar con tu Tarjeta Joven.</P>
        </div>
      </div>
    </div>
    <div class="container-newCJDesigner__main">
      <div class="container-newCJDesigner__step-component">
        <steps-ci class="step-component"></steps-ci>
      </div>
      <hr class="screenSizeSeparator">
      <div class="container-newCJDesigner__content screenSizeError signature">
        <div class="container-newCJDesigner__content-title">
          <h2>¿Listo para firmar?</h2>
          <p>Te vamos a redirigir a nuestro partner de confianza, Logalty, para realizar la firma del contrato de tu Cuenta Joven de
          forma totalmente segura.
          </p>
        </div>
        <div class="container-newCJDesigner__sign">
          <div class="container-newCJDesigner__sign-row margin-title">
            <h2>Y recuerda:</h2>
          </div>
          <div class="container-newCJDesigner__sign-group">
            <div class="container-newCJDesigner__sign-row icons-column">
              <div class="container-newCJDesigner__sign-row-content icons-column-logalty">
                <div class="logalty-page-icons ic-docs-lupa">
                </div>
                <div class="logalty-page-icons-separator">
                  <hr>
                </div>
                <div class="logalty-page-icons ic-descargar">
                </div>
                <div class="logalty-page-icons-separator">
                  <hr>
                </div>
                <div class="logalty-page-icons ic-clave-puntos">
                </div>
              </div>
            </div>
            <div class="container-newCJDesigner__sign-row paragraph-row">
              <div class="container-newCJDesigner__sign-row-content paragraph-row-content">
                <p><span>Lee detenidamente </span>el contrato de tu Cuenta Joven.</p>
              </div>
              <div class="container-newCJDesigner__sign-row-content paragraph-row-content">
                <p>Si quieres puedes <span>descargarlo</span> o <span>imprimirlo.</span></p>
              </div>
              <div class="container-newCJDesigner__sign-row-content paragraph-row-content">
                <p><span>Valida tu firma</span> con el código que te enviaremos por SMS.</p>
              </div>
            </div>

          </div>
          <div class="container-newCJDesigner__sign-row last-row">
            <div class="container-newCJDesigner__sign-row-content submitButton">
              <div class="btn-group" (click)="onSubmit()">
                <button type="submit" tabindex="3" class="btn-common btn-common_fix_bold">CONTINUAR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isCIAccount" class="main-register flex-direction">
  <box-image
    [classImgFile]="'previa_firma07'"
    [icon]="'ic-cartera-pf'"
    [titleCss]="'title-2 '"
    [subtitleCss]="'subtitle-2'"
    [title]="'AGREGADOR DE CUENTAS'"
    [subtitle]="'En un abrir y cerrar de ojos la información de todas tus cuentas de todos tus bancos juntas, desde la App de EVO.'"
    class="image-div"
  ></box-image>
  <div class="form-and-step-div">
    <steps-ci class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div">
      <p class="letter title">¿Listo para firmar?</p>
      <p class="letter subtitle-pf">Te vamos a redirigir a nuestro partner de confianza</p>
      <p class="letter entradilla-logalty">
        Te vamos a redirigir a Logalty, nuestro tercero de confianza, para realizar la firma del contrato de tu Cuenta Inteligente de forma
        totalmente segura
      </p>
      <p class="letter title">Y RECUERDA:</p>
      <div class="card">
        <div class="remember-stepper">
          <div class="dots"></div>
          <div class="step">
            <div class="ic documento-lapiz"></div>
            <p class="letter entradilla-logalty"><strong>Lee detenidamente</strong> el contrato de tu cuenta inteligente.</p>
          </div>
          <div class="step">
            <div class="ic flecha"></div>
            <p class="letter entradilla-logalty">Si deseas puedes <strong>descargarlo o imprimirlo.</strong></p>
          </div>
          <div class="step">
            <div class="ic documento-lupa"></div>
            <p class="letter entradilla-logalty"><strong>Valida tu firma</strong> con el código que te enviaremos por SMS.</p>
          </div>
        </div>
      </div>
      <hr class="line-separator hidden-movil" />
      <div class="btn-group_fix" (click)="onSubmit()">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold">Continuar</button>
      </div>
    </div>
  </div>
</div>
