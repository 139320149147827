<loading [showMe]="loading"></loading>
<!-- <mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone> -->

<modal modalTitle="SaveDataSuccessModalCI" modalId="SaveDataSuccessModalCI" class="modal">
  <div class="save-data-success-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">Tus datos han sido guardados correctamente</p>
      <p class="sub-title">
        Recuerda que <strong>puedes volver donde lo dejaste.</strong> Solo tendrás que incluir tu teléfono y un código de seguridad que
        recibirás en tu móvil.
      </p>
      <button class="button btn-common" (click)="this.modService.close(this.saveModal);">Entendido</button>
    </div>
  </div>
</modal>

<modal modalTitle="BackModalCI" modalId="BackModalCI" class="modal">
  <div class="back-modal">
    <div class="container">
      <div class="icon"></div>
      <p class="title">¿Quieres guardar antes de salir?</p>
      <p class="sub-title">Guarda la información para retomar el proceso donde lo dejaste la próxima vez que entres.</p>
      <button class="btn-common button" (click)="this.modService.close(this.backModal);saveData()">Guardar</button>
      <button class="btn-common button button-back" (click)="this.modService.close(this.backModal);">Ahora no</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]="modal.name" class="modal">
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage ? 'false-container' : 'container'">
    <h2>{{ modal.title }}</h2>
    <p [ngClass]="modal.withImage ? '' : 'invalid-text'" [innerHTML]="modal.message"></p>
  </div>
</modal>
<modal [modalTitle]="'Jubilado Rentista'" [blocking]="false" [modalId]="modalRentista" class="modal">
  <div class="container-user-info">
    <div class="row-modal">
      <p class="ic-dudas"></p>
    </div>
    <div class="row-modal">
      <p class="title text-bold">¿Confirmas la opción?</p>
    </div>
    <div class="row-modal">
      <p class="descripcion">
        A continuación incluimos una definición de "rentista/<br class="isMobile" />pensionista" para asegurar tu elección.
      </p>
    </div>
    <div class="row-modal">
      <p class="descripcion">
        <span class="text-bold"> Rentista/pensionista: </span> Persona que vive de sus rentas o <br class="isMobile" />
        ingresos por inversiones y no trabaja , o que obtiene ingresos
        <br class="isMobile" />
        de actividades económicas no productivas.
      </p>
    </div>
    <div class="row-button">
      <button (click)="closeModalRentista()" class="bt-info" name="button1">
        <p class="etiqueta">Continuar</p>
      </button>
      <button (click)="closeModalRentista(true)" class="bt-info-1" name="button1">
        <p class="etiqueta">Cambiar de Opción</p>
      </button>
    </div>
  </div>
</modal>
<div class="contactDetails" *ngIf="!isCIAccount">
  <div class="container-newCJDesigner">
    <div class="container-newCJDesigner__sideBar labor-detail-page-sideBar">
      <div class="container-newCJDesigner__sideBar-img">
        <img class="desktopSizeImage" src="assets/images/img-fol-cj-5.png" alt="Datos laborales">
        <img class="tabletSizeImage" src="assets/images/img-fol-cj-6.png" alt="Datos de laborales">
      </div>
      <div class="container-newCJDesigner__sideBar-content">
        <div class="container-newCJDesigner__sideBar-content-paragraph">
          <img src="assets/images/icon-lock-feel/ic-bizum.svg" alt="Bizum">
          <h3>SERVICIO BIZUM</h3>
          <P>Transferencias desde tu móvil a tus amigos.</P>
        </div>
      </div>
    </div>
    <div class="container-newCJDesigner__main labor-detail-page-main">
        <div class="container-newCJDesigner__step-component">
          <steps-ci class="step-component"></steps-ci>
        </div>
        <hr class="screenSizeSeparator">
        <div class="container-newCJDesigner__content screenSizeError">
          <div class="container-newCJDesigner__content-title">
            <h2>Datos laborales</h2>
            <p>Ya solo quedan tus datos laborales, ¡Y listo!</p>
          </div>
          <div class="container-newCJDesigner__form labor-detail-page">
              <form
                [formGroup]="laborDetailsForm"
                (keydown.enter)="$event.preventDefault()"
                (ngSubmit)="onSubmit()"
                novalidate
              >
                <div class="container-newCJDesigner__form-row">
                  <div class="container-newCJDesigner__form-input">
                  <label for="situacionLaboral" class="form-label">Situación laboral</label>
                    <dropdown
                      class="dropdown"
                      id="situacionLaboral"
                      name="situacionLaboral"
                      formControlName="situacionLaboral"
                      (click)="updateDropDawnValues('situacionLaboral')"
                      [options]="situaciones"
                    ></dropdown>
                    <!-- {{laborDetailsForm.get('situacionLaboral').valid}} -->
                  </div>
                  <div class="container-newCJDesigner__form-input" *ngIf="isCuentaAjena">
                    <label for="tipoContrato" class="form-label">Tipo de contrato</label>
                    <dropdown
                      class="dropdown"
                      id="tipoContrato"
                      name="tipoContrato"
                      formControlName="tipoContrato"
                      (click)="updateDropDawnValues('tipoContrato')"
                      [options]="contracts"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoContrato'), 'tipoContrato')">
                      {{ errService.desktopErrors(laborDetailsForm.get('tipoContrato'), 'tipoContrato') }}
                    </div>
                  </div>
                  <!-- <div class="mobile-container hollow" *ngIf="isCuentaAjena"></div> -->
                  <!--TODO esto es CNAE-->
                  <div class="container-newCJDesigner__form-input" *ngIf="isAutonomo">
                    <label for="cnae" class="form-label">Tipo de actividad económica</label>
                    <!-- TODO autocomplete -->
                    <dropdown
                      class="dropdown"
                      id="cnae"
                      name="cnae"
                      formControlName="cnae"
                      (click)="updateDropDawnValues('cnae')"
                      [options]="cnae"
                      [autocomplete]="true"
                    ></dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('cnae'), 'cnae')">
                      {{ errService.desktopErrors(laborDetailsForm.get('cnae'), 'cnae') }}
                    </div>
                  </div>
                  <!-- <div class="mobile-container hollow" *ngIf="isAutonomo"></div> -->
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isAutonomo">
                  <div class="container-newCJDesigner__form-input">
                    <label for="profesionAutonomo" class="form-label">Profesión</label>
                    <dropdown
                      class="dropdown"
                      id="profesionAutonomo"
                      name="profesionAutonomo"
                      formControlName="profesion"
                      (click)="updateDropDawnValues('profesion')"
                      [options]="profesion"
                      maxHeight="300px"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
                      {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="tipoActividad" class="form-label">Tipo de actividad profesional</label>
                    <dropdown
                      class="dropdown"
                      id="tipoActividadAutonomo"
                      name="tipoActividad"
                      formControlName="tipoActividad"
                      (click)="updateDropDawnValues('tipoActividad')"
                      [nameSelect]="true"
                      [options]="tipoActividadProfesional"
                      [disabled]="noSelectedProfession"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')">
                      {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isAutonomo">
                  <div class="container-newCJDesigner__form-input">
                    <label for="Descripcion_CNO__c" class="form-label">Descripción de la actividad profesional</label>
                    <dropdown
                      class="dropdown"
                      id="Descripcion_CNO__c"
                      name="Descripcion_CNO__c"
                      formControlName="Descripcion_CNO__c"
                      (click)="updateDropDawnValues('Descripcion_CNO__c')"
                      [disabled]="noSelectedTipeOfActivity"
                      [options]="descripcionActividadProfesional"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')">
                      {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isAutonomo">
                  <div class="container-newCJDesigner__form-input">
                   <label for="autonomoFecha" class="form-label">Fecha de autónomo</label>
                    <input
                      date-input
                      type="tel"
                      class="dropdown"
                      id="autonomoFecha"
                      maxlength="10"
                      name="autonomoFecha"
                      required
                      placeholder="Fecha de alta como autónomo"
                      formControlName="fechaAltaAutonomo"
                      autocomplete="off"
                      style="margin: 0;"
                    />
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('fechaAltaAutonomo'), 'fechaAltaAutonomo')">
                      {{ errService.desktopErrors(laborDetailsForm.get('fechaAltaAutonomo'), 'fechaAltaAutonomo') }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isCuentaAjena">
                  <div class="container-newCJDesigner__form-input">
                   <label for="profesion" class="form-label">Profesión</label>
                    <dropdown-custom
                      class="dropdown"
                      id="profesion"
                      name="profesion"
                      [autocomplete]="true"
                      formControlName="profesion"
                      (click)="updateDropDawnValues('profesion')"
                      [nameSelect]="true"
                      [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion'))"
                      [options]="profesion"
                      placeholder="Introduce las letras"
                      tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU CATEGORÍA PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
                      [buttonClass]="true"
                      maxHeight="300px"
                    ></dropdown-custom>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
                      {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="tipoActividad" class="form-label">Tipo de actividad</label>
                    <dropdown-custom
                      class="dropdown"
                      id="tipoActividad"
                      name="tipoActividad"
                      [autocomplete]="true"
                      formControlName="tipoActividad"
                      (click)="updateDropDawnValues('tipoActividad')"
                      [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad'))"
                      [nameSelect]="true"
                      [options]="tipoActividadProfesional"
                      [disabled]="noSelectedProfession"
                      placeholder="Introduce el código CNO o las letras"
                      tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
                      [buttonClass]="true"
                      maxHeight="300px"
                    ></dropdown-custom>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')">
                      {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isCuentaAjena">
                  <div class="container-newCJDesigner__form-input">
                  <label for="Descripcion_CNO__c" class="form-label">Descripción de la actividad profesional</label>
                    <dropdown
                      class="dropdown"
                      id="Descripcion_CNO__c"
                      name="Descripcion_CNO__c"
                      formControlName="Descripcion_CNO__c"
                      (click)="updateDropDawnValues('Descripcion_CNO__c')"
                      [disabled]="noSelectedTipeOfActivity"
                      [options]="descripcionActividadProfesional"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')">
                      {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
                    </div>
                  </div>
                </div>
                <!-- <div class="row" *ngIf='isCuentaAjena'>
                  <div class="mobile-container">

                  </div>
                  <div class="mobile-container">

                  </div>
                </div> -->
                <div class="container-newCJDesigner__form-row" *ngIf="isCuentaAjena">
                  <div class="container-newCJDesigner__form-input">
                  <label for="duracionTrabajo" class="form-label">Antigüedad en la empresa</label>
                    <dropdown
                      class="dropdown"
                      id="duracionTrabajo"
                      name="duracionTrabajo"
                      formControlName="duracionTrabajo"
                      (click)="updateDropDawnValues('duracionTrabajo')"
                      [options]="years"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo')">
                      {{ errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="ingresosMensuales" class="form-label">Ingresos mensuales netos</label>
                    <input
                      type="number"
                      (ngModelChange)="maxAndminLengthCheck($event, 'ingresosMensuales', 0, 999999)"
                      class="dropdown"
                      id="ingresosMensuales"
                      name="ingresosMensuales"
                      required
                      placeholder="Ingresos mensuales netos"
                      formControlName="ingresosMensuales"
                      autocomplete="off"
                      style="margin: 0;"
                    />

                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
                      {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                    <label for="numeroPagas" class="form-label">Número de pagas</label>
                    <input
                      type="number"
                      (ngModelChange)="maxAndminLengthCheck($event, 'numeroPagas', 0, 99)"
                      class="dropdown"
                      id="numeroPagas"
                      name="numeroPagas"
                      required
                      placeholder="Número de pagas"
                      formControlName="numeroPagas"
                      autocomplete="off"
                      style="margin: 0;"

                    />
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas')">
                      {{ errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas') }}
                    </div>
                  </div>
                </div>

                <!-- FUNCIONARIO INICIO -->

                <div class="container-newCJDesigner__form-row" *ngIf="isFuncionario">
                  <div class="container-newCJDesigner__form-input">
                  <label for="profesion" class="form-label">Descripción de la actividad profesional</label>
                    <!--TODO esto es CNO-->
                    <dropdown
                      class="dropdown"
                      id="profesionAutonomo"
                      name="profesionAutonomo"
                      formControlName="profesion"
                      [options]="profesion"
                      (click)="updateDropDawnValues('profesion')"
                      maxHeight="300px"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
                      {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                  <label for="tipoActividad" class="form-label">Tipo de actividad profesional</label>
                    <dropdown
                      class="dropdown"
                      id="tipoActividadAutonomo"
                      name="tipoActividad"
                      formControlName="tipoActividad"
                      (click)="updateDropDawnValues('tipoActividad')"
                      [nameSelect]="true"
                      [options]="tipoActividadProfesional"
                      [disabled]="noSelectedProfession"
                      maxHeight="300px"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')">
                      {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
                    </div>
                  </div>
                </div>
                <div class="container-newCJDesigner__form-row" *ngIf="isFuncionario">
                  <div class="container-newCJDesigner__form-input">
                    <label for="Descripcion_CNO__c" class="form-label">Descripcion de la Actividad Profesional</label>
                    <dropdown
                      class="dropdown"
                      id="Descripcion_CNO__c"
                      name="Descripcion_CNO__c"
                      formControlName="Descripcion_CNO__c"
                      (click)="updateDropDawnValues('Descripcion_CNO__c')"
                      [disabled]="noSelectedTipeOfActivity"
                      [options]="descripcionActividadProfesional"

                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')">
                      {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input">
                  <label for="duracionTrabajo" class="form-label">Duración del tiempo de trabajo</label>
                    <dropdown
                      class="dropdown"
                      id="duracionTrabajo"
                      name="duracionTrabajo"
                      formControlName="duracionTrabajo"
                      (click)="updateDropDawnValues('duracionTrabajo')"
                      [options]="years"
                    >
                    </dropdown>
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo')">
                      {{ errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo') }}
                    </div>
                  </div>
                </div>

                <!-- FUNCIONARIO FIN -->

                <!-- JubiladoPensionanistaRentista INICIO -->
                <div class="container-newCJDesigner__form-row" *ngIf="isJubiladoPensionanistaRentista || isFuncionario || isAutonomo">
                  <div class="container-newCJDesigner__form-input">
                    <label for="ingresosMensuales" class="form-label">Ingresos mensuales netos</label>
                    <input
                      type="number"
                      class="dropdown"
                      id="ingresosMensuales"
                      name="ingresosMensuales"
                      (ngModelChange)="maxAndminLengthCheck($event, 'ingresosMensuales', 0, 999999)"
                      placeholder="Ingresos mensuales netos"
                      formControlName="ingresosMensuales"
                      autocomplete="off"
                      style="margin: 0;"
                    />
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
                      {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
                    </div>
                  </div>
                  <div class="container-newCJDesigner__form-input" *ngIf="!isAutonomo">
                  <label for="numeroPagas" class="form-label">Número de pagas</label>
                    <input
                      type="number"
                      class="dropdown"
                      id="numeroPagas"
                      name="numeroPagas"
                      required
                      (ngModelChange)="maxAndminLengthCheck($event, 'numeroPagas', 0, 99)"
                      placeholder="Número de pagas"
                      formControlName="numeroPagas"
                      autocomplete="off"
                      style="margin : 0;"
                    />
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas')">
                      {{ errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas') }}
                    </div>
                  </div>
                </div>
                <!-- JubiladoPensionanistaRentista FIN -->
                <div>

                </div>
                <div class="container-newCJDesigner__form-row last-row">
                  <div class="container-newCJDesigner__form-input input-last-row">
                  <div class="return-label">
                    <p (click)="returnInit()">
                      <img src="assets/images/icon-lock-feel/ic-flecha-black.svg" alt="icon">
                      Atrás
                    </p>
                  </div>
                </div>
                  <div class="container-newCJDesigner__form-input input-last-row">
                    <div class="btn-group" [ngClass]="isOneField">
                      <button type="submit" class="btn-common btn-common_fix_bold"
                      [disabled]="laborDetailsForm.invalid"
                      >CONTINUAR</button>
                    </div>
                  </div>
                </div>
              </form>
          </div>
            <!-- {{laborDetailsForm.get('situacionLaboral').value | json}} -->
            <!-- formulario valido : {{laborDetailsForm.valid}}
          {{laborDetailsForm.value | json}} -->
        </div>
    </div>
  </div>
</div>


<div class="main-register flex-direction" *ngIf="isCIAccount">
  <box-image
    [sizeBox]="''"
    [classImgFile]="'reactive-email-banner-labor'"
    [title]="titleBox"
    [textBox]="'text-datos-laborales'"
    [titleCss]="''"
    class="image-div">
</box-image>
  <div class="form-and-step-div">
    <steps-ci [view]="this" class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil" />
    <div class="form-div tab-width">
      <div class="title-container">
        <p class="title letter title-form">Datos laborales</p>
        <div class="save-button" (click)="saveData()">
          <p class="title letter title-save">Guardar</p>
          <div class="save-button-icon">
            <div *ngIf="tooltipEnabled" class="tooltip-button" tooltip [template]="tooltipTemplate" placement="bottom"></div>
          </div>
        </div>
      </div>
      <p class="letter subtitle">¡Ya casi lo tenemos! Ahora necesitamos tus datos laborales para poder continuar</p>
      <form
        [class]="isOneFieldForm"
        [formGroup]="laborDetailsForm"
        (keydown.enter)="$event.preventDefault()"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="floating-placeholder-group row-ci">
          <div class="col-ci-left width-mobile-dropdown">
            <dropdown
              class="dropdown"
              id="situacionLaboral"
              name="situacionLaboral"
              formControlName="situacionLaboral"
              [isCi]="true"
              (click)="updateDropDawnValues('situacionLaboral')"
              [options]="situaciones"
            ></dropdown>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('situacionLaboral'))"
              >Situación laboral</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="errService.desktopErrors(laborDetailsForm.get('situacionLaboral'), 'situacionLaboral')"
            >
              {{ errService.desktopErrors(laborDetailsForm.get('situacionLaboral'), 'situacionLaboral') }}
            </div>
          </div>
          <!--TRABAJADO POR CUENTA AJENA-->
          <div class="col-ci-right width-mobile-dropdown margin-col-ci-right" *ngIf="isCuentaAjena">
            <dropdown
              class="dropdown"
              id="tipoContrato"
              name="tipoContrato"
              formControlName="tipoContrato"
              (click)="updateDropDawnValues('tipoContrato')"
              [isCi]="true"
              [options]="contracts"
            >
            </dropdown>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('tipoContrato'))"
              >Tipo de contrato</label
            >
            <div class="error-text error-drop-ci" *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoContrato'), 'tipoContrato')">
              {{ errService.desktopErrors(laborDetailsForm.get('tipoContrato'), 'tipoContrato') }}
            </div>
          </div>
          <!--AUTONOMO-->
          <div class="col-ci-right width-mobile-dropdown margin-col-ci-right" *ngIf="isAutonomo">
            <!-- TODO autocomplete -->
            <dropdown
              class="dropdown"
              id="cnae"
              name="cnae"
              formControlName="cnae"
              (click)="updateDropDawnValues('cnae')"
              [options]="cnae"
              [isCi]="true"
              [autocomplete]="true"
              maxHeight="300px"
            ></dropdown>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('cnae'))"
              >Tipo de actividad económica</label
            >
            <div class="error-text error-drop-ci" *ngIf="errService.desktopErrors(laborDetailsForm.get('cnae'), 'cnae')">
              {{ errService.desktopErrors(laborDetailsForm.get('cnae'), 'cnae') }}
            </div>
          </div>
        </div>
        <!--INICIO FORMULARIO AUTONOMO-->
        <div class="floating-placeholder-group row-ci margin-top-drop" *ngIf="isAutonomo">
          <div class="col-ci-left width-mobile-dropdown margin-col-ci-left">
            <!--TODO esto es CNO-->
            <dropdown
              class="dropdown"
              id="profesionAutonomo"
              name="profesionAutonomo"
              formControlName="profesion"
              (click)="updateDropDawnValues('profesion')"
              [options]="profesion"
              [isCi]="true"
              maxHeight="300px"
            >
            </dropdown>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('profesion'))">Profesión</label>
            <div class="error-text error-drop-ci" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
              {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
            </div>
          </div>
          <div class="col-ci-right width-mobile-dropdown margin-col-ci-right">
            <dropdown
              class="dropdown"
              id="tipoActividadAutonomo"
              name="tipoActividad"
              formControlName="tipoActividad"
              (click)="updateDropDawnValues('tipoActividad')"
              [nameSelect]="true"
              [options]="tipoActividadProfesional"
              [isCi]="true"
              [disabled]="noSelectedProfession"
            >
            </dropdown>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('tipoActividad'))"
              >Tipo de actividad profesional</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')"
            >
              {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
            </div>
          </div>
        </div>
        <div class="floating-placeholder-group row-ci one-line line-top margin-top-drop" *ngIf="isAutonomo">
          <dropdown
            class="dropdown"
            id="Descripcion_CNO__c"
            name="Descripcion_CNO__c"
            formControlName="Descripcion_CNO__c"
            (click)="updateDropDawnValues('Descripcion_CNO__c')"
            [isCi]="true"
            [needSplit]="false"
            [disabled]="noSelectedTipeOfActivity"
            [options]="descripcionActividadProfesional"
          >
          </dropdown>

          <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('Descripcion_CNO__c'))"
            >Descripción profesional</label
          >
          <div
            class="error-text error-drop-ci"
            *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')"
          >
            {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
          </div>
        </div>
        <div class="floating-placeholder-group row-ci fecha-input" *ngIf="isAutonomo">
          <div class="floating-placeholder-group col-ci-left">
            <input
              date-input
              type="tel-ci"
              required
              class="form-control"
              id="autonomoFecha"
              maxlength="10"
              name="autonomoFecha"
              class="width-input-mb"
              required
              formControlName="fechaAltaAutonomo"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('fechaAltaAutonomo').value ? 'input-noEmpty' : 'input-empty'"

            />
            <label class="floating-placeholder">Fecha de alta como autónomo</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('fechaAltaAutonomo'), 'fechaAltaAutonomo')">
              {{ errService.desktopErrors(laborDetailsForm.get('fechaAltaAutonomo'), 'fechaAltaAutonomo') }}
            </div>
          </div>
          <div class="floating-placeholder-group col-ci-right">
            <input
              type="tel-ci"
              required
              class="form-control"
              id="ingresosMensuales"
              name="ingresosMensuales"
              class="width-input-mb"
              formControlName="ingresosMensuales"
              (change)="maxAndminLengthCheckCI($event, 'ingresosMensuales', 0, 999999)"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('ingresosMensuales').value ? 'input-noEmpty' : 'input-empty'"
            />
            <label class="floating-placeholder">Ingresos mensuales netos</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
              {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
            </div>
          </div>
        </div>
        <!--FIN FORMULARIO AUTONOMO-->
        <!--INICIO FORMULARIO TRABAJADOR POR CUENTA AJENA-->
        <div class="floating-placeholder-group row-ci line-top margin-top-drop" *ngIf="isCuentaAjena">
          <div class="col-ci-left width-mobile-dropdown">
            <dropdown-custom
              class="dropdown"
              id="profesion"
              name="profesion"
              [autocomplete]="true"
              formControlName="profesion"
              (click)="updateDropDawnValues('profesion')"
              [nameSelect]="true"
              [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion'))"
              [options]="profesion"
              [isCi]="true"
              placeholder="Introduce las letras"
              tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU CATEGORÍA PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
              [buttonClass]="true"
              maxHeight="300px"
            ></dropdown-custom>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('profesion'))">CNO</label>
            <div class="error-text error-drop-ci" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
              {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
            </div>
          </div>
          <div class="col-ci-right width-mobile-dropdown margin-col-ci-right">
            <dropdown-custom
              class="dropdown"
              id="tipoActividad"
              name="tipoActividad"
              [autocomplete]="true"
              formControlName="tipoActividad"
              (click)="updateDropDawnValues('tipoActividad')"
              [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad'))"
              [nameSelect]="true"
              [options]="tipoActividadProfesional"
              [disabled]="noSelectedProfession"
              placeholder="Introduce el código CNO o las letras"
              [isCi]="true"
              tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
              [buttonClass]="true"
              maxHeight="300px"
            ></dropdown-custom>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('tipoActividad'))"
              >Tipo de actividad profesional</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')"
            >
              {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
            </div>
          </div>
        </div>
        <div class="floating-placeholder-group row-ci one-line line-top margin-top-drop" *ngIf="isCuentaAjena">
          <dropdown
            class="dropdown"
            id="Descripcion_CNO__c"
            name="Descripcion_CNO__c"
            formControlName="Descripcion_CNO__c"
            (click)="updateDropDawnValues('Descripcion_CNO__c')"
            [isCi]="true"
            [needSplit]="false"
            [disabled]="noSelectedTipeOfActivity"
            [options]="descripcionActividadProfesional"
          >
          </dropdown>
          <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('Descripcion_CNO__c'))"
            >Descripción profesional</label
          >
          <div
            class="error-text error-drop-ci"
            *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')"
          >
            {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
          </div>
        </div>
        <div class="floating-placeholder-group row-ci one-line line-top margin-top-drop" *ngIf="isCuentaAjena">
          <!-- <div class="col-ci-left width-mobile-dropdown"> -->
          <dropdown
            class="dropdown"
            id="duracionTrabajo"
            name="duracionTrabajo"
            formControlName="duracionTrabajo"
            (click)="updateDropDawnValues('duracionTrabajo')"
            [needSplit]="false"
            [isCi]="true"
            [options]="years"
          >
          </dropdown>
          <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('duracionTrabajo'))"
            >Antigüedad en la empresa</label
          >
          <div
            class="error-text error-drop-ci"
            *ngIf="errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo')"
          >
            {{ errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo') }}
          </div>
          <!-- </div> -->
        </div>
        <div class="floating-placeholder-group row-ci ingresos-input" *ngIf="isCuentaAjena">
          <div class="floating-placeholder-group col-ci-left">
            <input
              type="number"
              (ngModelChange)="maxAndminLengthCheckCI($event, 'ingresosMensuales', 0, 999999)"
              class="form-control"
              id="ingresosMensuales"
              name="ingresosMensuales"
              class="width-input-mb"
              required
              formControlName="ingresosMensuales"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('ingresosMensuales').value ? 'input-noEmpty' : 'input-empty'"

            />
            <label class="floating-placeholder">Ingresos mensuales netos</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
              {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
            </div>
          </div>
          <!-- </div>
        <div class="floating-placeholder-group row-ci ingresos-input-second"  *ngIf='isCuentaAjena'> -->
          <div class="floating-placeholder-group col-ci-right">
            <input
              type="number"
              class="form-control"
              id="numeroPagas"
              name="numeroPagas"
              class="width-input-mb"
              required
              formControlName="numeroPagas"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('numeroPagas').value ? 'input-noEmpty' : 'input-empty'"

            />
            <label class="floating-placeholder">Número de pagas</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas')">
              {{ errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas') }}
            </div>
          </div>
        </div>
        <!--FIN FORMULARIO TRABAJADOR POR CUENTA AJENA-->
        <!--INICIO FORMULARIO FUNCIONARIO-->
        <div class="floating-placeholder-group row-ci margin-top-drop" *ngIf="isFuncionario">
          <div class="col-ci-left width-mobile-dropdown margin-col-ci-left">
            <dropdown-custom
              class="dropdown"
              id="profesion"
              name="profesion"
              [autocomplete]="true"
              formControlName="profesion"
              (click)="updateDropDawnValues('profesion')"
              [nameSelect]="true"
              [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion'))"
              [options]="profesion"
              [isCi]="true"
              placeholder="Introduce las letras"
              tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU CATEGORÍA PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
              [buttonClass]="true"
              maxHeight="300px"
            ></dropdown-custom>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('profesion'))">CNO</label>
            <div class="error-text error-drop-ci" *ngIf="errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion')">
              {{ errService.desktopErrors(laborDetailsForm.get('profesion'), 'profesion') }}
            </div>
          </div>
          <div class="col-ci-right width-mobile-dropdown margin-col-ci-right">
            <dropdown-custom
              class="dropdown"
              id="tipoActividad"
              name="tipoActividad"
              [autocomplete]="true"
              formControlName="tipoActividad"
              (click)="updateDropDawnValues('tipoActividad')"
              [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad'))"
              [nameSelect]="true"
              [options]="tipoActividadProfesional"
              [disabled]="noSelectedProfession"
              placeholder="Introduce el código CNO o las letras"
              [isCi]="true"
              tooltip="INTRODUCE LAS PRIMERAS LETRAS DE TU ACTIVIDAD PROFESIONAL Y SELECCIONA LA OPCIÓN QUE MÁS SE ADECUÉ"
              [buttonClass]="true"
              maxHeight="300px"
            ></dropdown-custom>
            <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('tipoActividad'))"
              >Tipo de actividad profesional</label
            >
            <div
              class="error-text error-drop-ci"
              *ngIf="errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad')"
            >
              {{ errService.desktopErrors(laborDetailsForm.get('tipoActividad'), 'tipoActividad') }}
            </div>
          </div>
        </div>
        <div class="floating-placeholder-group row-ci one-line line-top margin-top-drop" *ngIf="isFuncionario">
          <dropdown
            class="dropdown"
            id="Descripcion_CNO__c"
            name="Descripcion_CNO__c"
            formControlName="Descripcion_CNO__c"
            (click)="updateDropDawnValues('Descripcion_CNO__c')"
            [isCi]="true"
            [needSplit]="false"
            [disabled]="noSelectedTipeOfActivity"
            [options]="descripcionActividadProfesional"
          >
          </dropdown>
          <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('Descripcion_CNO__c'))"
            >Descripción profesional</label
          >
          <div
            class="error-text error-drop-ci"
            *ngIf="errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c')"
          >
            {{ errService.desktopErrors(laborDetailsForm.get('Descripcion_CNO__c'), 'Descripcion_CNO__c') }}
          </div>
        </div>
        <div class="floating-placeholder-group row-ci one-line line-top margin-top-drop" *ngIf="isFuncionario">
          <dropdown
            class="dropdown"
            id="duracionTrabajo"
            name="duracionTrabajo"
            formControlName="duracionTrabajo"
            (click)="updateDropDawnValues('duracionTrabajo')"
            [isCi]="true"
            [options]="years"
          >
          </dropdown>
          <label class="floating-placeholder-check" *ngIf="helpers.returnValue(this.laborDetailsForm.get('duracionTrabajo'))"
            >Antigüedad en la empresa</label
          >
          <div
            class="error-text error-drop-ci"
            *ngIf="errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo')"
          >
            {{ errService.desktopErrors(laborDetailsForm.get('duracionTrabajo'), 'duracionTrabajo') }}
          </div>
        </div>
        <div class="floating-placeholder-group row-ci ingresos-input" *ngIf="isFuncionario">
          <div class="floating-placeholder-group col-ci-left">
            <input
              type="number"
              class="form-control"
              id="ingresosMensuales"
              name="ingresosMensuales"
              class="width-input-mb"
              required
              formControlName="ingresosMensuales"
              (ngModelChange)="maxAndminLengthCheck($event, 'ingresosMensuales', 0, 999999)"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('ingresosMensuales').value ? 'input-noEmpty' : 'input-empty'"
            />
            <label class="floating-placeholder">Ingresos mensuales netos</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
              {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
            </div>
          </div>
          <div class="floating-placeholder-group col-ci-right">
            <input
              type="number"
              class="form-control width-input-mb"
              id="numeroPagas"
              name="numeroPagas"
              required
              formControlName="numeroPagas"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('numeroPagas').value ? 'input-noEmpty' : 'input-empty'"

            />
            <label class="floating-placeholder">Número de pagas</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas')">
              {{ errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas') }}
            </div>
          </div>
        </div>
        <!--FIN FORMULARIO FUNCIONARIO-->
        <!--INICIO FORMULARIO JUBILADO/RENTISTA-->
        <div class="floating-placeholder-group row-ci ingresos-input" *ngIf="isJubiladoPensionanistaRentista">
          <div class="floating-placeholder-group col-ci-left">
            <input
              type="number"
              (ngModelChange)="maxAndminLengthCheckCI($event, 'ingresosMensuales', 0, 999999)"
              class="form-control width-input-mb"
              id="ingresosMensuales"
              name="ingresosMensuales"
              required
              formControlName="ingresosMensuales"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('ingresosMensuales').value ? 'input-noEmpty' : 'input-empty'"
            />
            <label class="floating-placeholder">Ingresos mensuales netos</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales')">
              {{ errService.desktopErrors(laborDetailsForm.get('ingresosMensuales'), 'ingresosMensuales') }}
            </div>
          </div>
          <div class="floating-placeholder-group col-ci-right jubilado-pagas">
            <input
              type="number"
              class="form-control width-input-mb"
              id="numeroPagas"
              name="numeroPagas"
              required
              formControlName="numeroPagas"
              autocomplete="off"
              [ngClass]="laborDetailsForm.get('numeroPagas').value ? 'input-noEmpty' : 'input-empty'"
            />
            <label class="floating-placeholder">Número de pagas</label>
            <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas')">
              {{ errService.desktopErrors(laborDetailsForm.get('numeroPagas'), 'numeroPagas') }}
            </div>
          </div>
        </div>
        <!--FIN FORMULARIO JUBILADO/RENTISTA-->
        <hr class="line-separator hidden-movil line-separator-tab" />
        <div class="buttonCI tab-width">
          <div class="return-text">
            <p (click)="returnInit()">
              <span class="link-otp-black"></span>
              Atrás
            </p>
          </div>
          <button type="submit" class="btn-open-account open-account">
            <p>CONTINUAR</p>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="promo" *ngIf="isCIAccount">
  <p class="promoCondition"><span class="negrita">1. Depósito plazo fijo a 4 meses con liquidación de intereses al vencimiento y
    renovable automáticamente cada 4 meses según remuneración vigente. A continuación, mostramos un ejemplo representativo en el cual la TAE
    se ha calculado a 1 año, bajo la hipótesis de que el tipo de interés no varía: para un saldo total en depósito de 60.000 € al 3,02 % TIN y <span class="tae-font">3,05 % TAE</span>,
    percibiendo una remuneración a los 4 meses de 604 €, a los siguientes 4 meses de otros 604 € y a los siguientes 4 meses de otros 604 €.</span>
    No se aplica penalización por reembolso parcial o cancelación anticipada. Importe mínimo 1 € e importe máximo 1.000.000 €.
    Solo para dinero procedente de otra entidad distinta a EVO. Para clientes nuevos a partir del 01/05/2024 y promoción válida hasta el 31/10/2024.

  </p>
</div>
<ng-template #tooltipTemplate>
  <div class="tooltip" tabindex="0" >
    <i class="icon-info"></i>
    <div class="text-container">
      <p class="tooltip-title">¡No pierdas ningún dato!</p>
      <p>Si lo prefieres, puedes guardar los datos y avanzar en el proceso cuando quieras.</p>
    </div>
    <hr>
    <div class="button-container">
      <span (click)="closeTooltip($event)">ENTENDIDO</span>
    </div>
  </div>
</ng-template>
