export var ACCOUNTS = {
  CJCode: "008",
  CICode: "006",
  CDCode: "007",
  STCode: "999",
  MSCode: "998",
  CINVCode: "009",
  FINTCode:"010",
  TipoACode: "060",

 relacionAcuerdoPersona: {
    '01': 'Titular',
    "02": 'Autorizado',
    '15': 'Representante',
    '24': 'Usufructuario'
 },

  '006': {
    nombre: 'Cuenta inteligente',
    product: {
      ibanAccount: '03111030637',
      ibanInvestment: '0321CINP632',
      cardNumber: '01511020632',
      userAlerts: '05581010002',
      userElectronicBank: '05551010001',
    }
  },
  '008': {
    nombre: 'Cuenta joven',
    constPath: 'cuenta-joven',
    product: {
      ibanAccount: '03111010632',
      ibanInvestment: '0321CINP632',
      cardNumber: '05511010138',
      userAlerts: '05581010002',
      userElectronicBank: '05551010001',
    }
  },
  '007': {
    nombre: 'Cuenta DUO',
    product: {
      ibanAccount: '03111010137',
      ibanInvestment: '0321CINP632',
      cardNumber: '05511010137',
      userAlerts: '05581010002',
      userElectronicBank: '05551010001',
    }
  },
  '998': {
    nombre: 'Microseguros'
  },
  '999': {
    nombre: 'Segundo titular'
  },
  '009': {
    nombre: 'Cuenta Inversora',
    constPath: 'cuentainversora',
  },
  '010': {
    nombre: 'Fondo Inteligente',
    constPath: 'fondointeligente',
  }
};
