<app-header-new></app-header-new>
<loading [showMe]="loading"></loading>
<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'RÉGIMEN DE TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL'" [blocking]='false'
  [modalId]='modalLegal' [modal-wide]="true" class='modal'>
  <div class="container">
    <h2>RÉGIMEN DE TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL</h2>
      <p>
        EVO Banco, S.A con domicilio en Calle de Don Ramón de la Cruz, 84, 28006 Madrid es responsable del tratamiento de los datos de
        carácter personal que usted, como USUARIO, proporciona a través del presente formulario. Los datos serán tratados, al amparo de la
        ejecución contractual, con la finalidad de prestar los Servicios a Distancia objeto de los presentes T&C.
      </p>
      <p>
        Adicionalmente, le informamos qué EVO Banco tiene interés legítimo para enviarle comunicaciones comerciales, por cualquier canal, sobre
        productos y servicios que EVO pueda ofrecerle y sean similares a los Servicios a Distancia; y a elaborar perfiles
        con el objetivo de que las comunicaciones comerciales que le envíe se ajusten todo lo posible a sus necesidades,
        gustos y preferencias. El USUARIO podrá oponerse a estos tratamientos solicitándolo en la dirección de correo
        electrónico bajas@evobanco.com produciéndose la cancelación inmediata del envío de este tipo de comunicaciones comerciales.
      </p>
      <p>
        Sus datos se conservarán mientras dure su relación con EVO y, una vez finalizada, se bloquearán con el objetivo de cumplir
        con nuestras obligaciones legales. Una vez cumplidas, se procederá a la supresión de sus datos. Adicionalmente,
        le informamos que sus datos no serán comunicados a terceros ni dentro ni fuera del Espacio Económico Europeo.
      </p>
      <p>
        Por otro lado, ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación
        del tratamiento previstos en el Reglamento General de Protección de datos 679/2016 y normativa vigente aplicable
        sobre protección de datos en cada momento, dirigiéndose mediante correo electrónico a protecciondedatos@evobanco.com
        o por escrito a EVO BANCO, S.A. en la siguiente dirección: Calle de Don Ramón de la Cruz, 84, 28006 Madrid- indicando
        “LOPD” e incluyendo una copia de su documento oficial acreditativo de su identidad. Para más información accede a
        la Política de Privacidad de EVO Banco, S.A. disponible en la web www.evobanco.com. Le informamos que tiene
        derecho a recabar la tutela de la Agencia Española de Protección de datos a través de su página web www.agpd.es./es
      </p>
      <p>
        En cualquier caso, si tuviera cualquier duda acerca del tratamiento de sus datos, puede escribir al Delegado de Protección
        de Datos de EVO en el siguiente mail:<a href="mailto:dpo.evo@evobanco.com"> dpo.evo@evobanco.com</a>.
      </p>
      <p>
        Finalmente, usted declara que los datos personales proporcionados a EVO son ciertos, exactos y veraces, y se compromete a
        comunicar de inmediato a la EVO cualquier cambio de los mismos, y a mantenerlos permanentemente actualizados.
      </p>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="checkLegal()">ACEPTAR</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Tipo A'" [blocking]='false' [modalId]='modalTipoA' [modal-wide]="true" class='modal'>
  <div class="container">
    <h2 style="text-align:center">TÉRMINOS Y CONDICIONES SERVICIOS A DISTANCIA EVO</h2>
    <p>&nbsp;</p>
    <p>Al acceder al presente sitio web y aceptar los presentes T&eacute;rminos y Condiciones (en adelante
      &ldquo;T&amp;C&rdquo;) a trav&eacute;s del bot&oacute;n habilitado al efecto, aceptas la prestaci&oacute;n por
      parte de EVO Banco, S.A. de Servicios a Distancia al margen de su oferta de productos de car&aacute;cter
      financiero en tanto entidad de cr&eacute;dito sometida a la supervisi&oacute;n prudencial del Banco de
      Espa&ntilde;a.</p>
    <p>&nbsp;</p>
    <p><strong>CONDICI&Oacute;N DE USUARIO.- </strong>Una vez aceptados los mismos pasamos a considerarte USUARIO a los
      efectos de los presentes T&amp;C. En este sentido, consideramos que cuando el USUARIO persona f&iacute;sica no nos
      indique que interviene en el marco de su actividad profesional o comercial, y siempre que tal circunstancia
      tampoco resulte evidente a partir de la informaci&oacute;n que nos aporte, recibir&aacute; por parte de EVO el
      trato de consumidor.</p>
    <p>&nbsp;</p>
    <p><strong>OBJETO.- </strong>Los presentes T&amp;C tienen por objeto establecer el marco jur&iacute;dico/negocial
      que regule el uso de los servicios a distancia ofertados por EVO, al margen de su oferta de productos de
      car&aacute;cter financiero, al aceptar el USUARIO los presentes T&amp;C.</p>
    <p>Estos servicios te permiten acceder a trav&eacute;s de los diferentes canales de los que dispone EVO (i.e. (i)
      v&iacute;a telef&oacute;nica; (ii) por v&iacute;a electr&oacute;nica a trav&eacute;s de Internet y/o (iii)
      mediante el tel&eacute;fono m&oacute;vil) as&iacute; como a trav&eacute;s de cualquier otro medio que EVO habilite
      en un futuro que, en su caso, ser&aacute;n debidamente comunicados al efecto, a los servicios a distancia
      proporcionados por EVO y detallados a continuaci&oacute;n.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>SERVICIOS A DISTANCIA ASOCIADOS.- </strong>Los servicios a distancia asociados son todos los que EVO pone
      a disposici&oacute;n del USUARIO, en virtud de los presentes T&amp;C, y que se encuentran debidamente
      detallados&nbsp; en la App M&oacute;vil de EVO habilitada para clientes de productos no financieros (en adelante
      los &ldquo;<em>Servicios a Distancia</em>&rdquo;).</p>
    <p>El USUARIO acepta que el sistema recogido en los presentes T&amp;C tiene car&aacute;cter meramente instrumental y
      operativo, a los efectos aqu&iacute; recogidos. Por ello, tanto el USUARIO como EVO expresamente manifiestan que
      en el caso de que el USUARIO pase a ser cliente de EVO, d&aacute;ndose de alta como tal y mediante la
      contrataci&oacute;n de cualesquiera de los productos o servicios financieros ofertados por EVO en su calidad de
      entidad de cr&eacute;dito, la relaci&oacute;n jur&iacute;dica que vincula a ambos en virtud de los presentes
      T&amp;C pasar&aacute; a quedar sin efecto alguno y cancelados entre ellos, ya que su relaci&oacute;n
      jur&iacute;dico/negocial pasar&aacute; a regirse por lo establecido en la documentaci&oacute;n y contratos
      reguladores de los productos o servicios en cuesti&oacute;n que en cada caso EVO te requiera, de los que los
      Servicios a Distancia asociados a los presentes T&amp;C tambi&eacute;n forman parte.</p>
    <p>&nbsp;</p>
    <p><strong>FORMA DE OPERAR.- </strong>El USUARIO conoce y acepta que para navegar y operar con los Servicios a
      Distancia ofertados por EVO no se permite la identificaci&oacute;n del USUARIO mediante firma aut&oacute;grafa.
    </p>
    <p>Por ello, para poder acceder y para el funcionamiento de los Servicios a Distancia objeto de los presentes
      T&amp;C, EVO facilita al USUARIO las siguientes claves secretas: (i) un usuario &uacute;nico
      (&laquo;<em>Usuario</em>&raquo;) y (ii) una contrase&ntilde;a (&laquo;<em>Contrase&ntilde;a</em>&raquo;) de acceso
      a Servicios a Distancia. El Usuario y Contrase&ntilde;a proporcionados por EVO son personales e intransferibles.
      El USUARIO reconoce como operaciones por &eacute;l realizadas las efectuadas una vez incluidos el Usuario y la
      Contrase&ntilde;a al efecto proporcionados por EVO y que figuren registradas en la propia&nbsp; App Movil de EVO
      que el USUARIO se descarga.</p>
    <p>En todo caso, las operaciones que realice el USUARIO a trav&eacute;s del servicio se regir&aacute;n adem&aacute;s
      por las condiciones espec&iacute;ficamente dispuestas/proporcionadas para cada una de ellas, de ser el caso. Las
      &oacute;rdenes e instrucciones que, reuniendo las condiciones aqu&iacute; se&ntilde;aladas, EVO reciba del USUARIO
      a trav&eacute;s de los Servicios a Distancia recogidos en los presentes T&amp;C, se presumir&aacute;n en todo caso
      correctas, consider&aacute;ndose que han sido autorizadas y cursadas por el USUARIO o por persona/s con facultades
      suficientes al efecto. En todo caso, las &oacute;rdenes cursadas por el USUARIO y ejecutadas por EVO en virtud de
      los presentes T&amp;C, gozar&aacute;n de plenos efectos y presunci&oacute;n de legitimidad, sin que aqu&eacute;l
      pueda alegar u oponer excusa alguna en contra o la falta de firma para excepcionar la validez de la
      operaci&oacute;n o incumplir las obligaciones que hubiera asumido como consecuencia de su orden, de ser el caso.
      Aceptando expresamente que su conformidad, en la forma habilitada por EVO al efecto, sirve como perfeccionamiento
      de las operaciones a las que pueda acceder a trav&eacute;s de este sistema.</p>
    <p>El USUARIO autoriza a EVO a no ejecutar aquellas &oacute;rdenes recibidas utilizando las claves secretas, cuando
      tenga dudas razonables de la identidad de la persona que est&aacute; emitiendo la orden, hasta que EVO haya
      entrado en contacto directo y personal con el USUARIO y esclarecido la situaci&oacute;n. Asimismo, por razones de
      seguridad, los Servicios a Distancia quedar&aacute;n bloqueados en el caso de que se produzcan tres errores
      consecutivos en la consignaci&oacute;n de cualquiera de las claves secretas. En tal caso la EVO dar&aacute; aviso
      de esta incidencia al USUARIO. En estos casos, si las circunstancias as&iacute; lo aconsejan, EVO proceder&aacute;
      a anular las claves anteriores y a proporcionar al USUARIO unas nuevas con las debidas medidas de seguridad,
      previo contacto con el USUARIO y a solicitud por escrito de &eacute;ste.</p>
    <p>Asimismo, el Usuario y Contrase&ntilde;a podr&aacute;n tambi&eacute;n ser bloqueadas por EVO o a petici&oacute;n
      del USUARIO, para preservar la seguridad de la informaci&oacute;n.</p>
    <p>&nbsp;</p>
    <p><strong>REGISTRO DE LAS OPERACIONES.- </strong>El USUARIO autoriza a EVO a grabar y registrar telef&oacute;nica,
      magnetof&oacute;nica, inform&aacute;tica, electr&oacute;nicamente o por cualquier otro medio, la totalidad de los
      datos y circunstancias de las operaciones o consultas que realice a trav&eacute;s de los Servicios a Distancia.
      Los registros resultantes de las grabaciones antedichas podr&aacute;n ser utilizados como medio de prueba en
      cualquier procedimiento judicial o extrajudicial que pudiera derivarse de la presente relaci&oacute;n negocial.
      EVO se compromete a guardarlos durante el per&iacute;odo establecido por la legislaci&oacute;n vigente en cada
      momento. En todo caso, el USUARIO podr&aacute; solicitar de EVO copia sonora de su voz en dichas conversaciones.
    </p>
    <p>&nbsp;</p>
    <p><strong>SEGURIDAD.- </strong>EVO podr&aacute; adoptar cuantas medidas, generales o especiales, de registro,
      acreditaci&oacute;n y verificaci&oacute;n considere oportunas para salvaguardar el buen uso y garantizar la
      confidencialidad del servicio, incluida, en su caso, la confirmaci&oacute;n escrita de las &oacute;rdenes cursadas
      por el USUARIO.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>DURACI&Oacute;N.- </strong>La relaci&oacute;n jur&iacute;dico/negocial recogida en los presentes T&amp;C
      se pacta con una duraci&oacute;n indefinida, pudi&eacute;ndose resolver a instancia de cualquiera de las partes,
      sin necesidad de alegar motivo alguno. El USUARIO podr&aacute; resolver el contrato en cualquier momento de su
      vigencia, previa comunicaci&oacute;n a EVO por cualquiera de las v&iacute;as de comunicaci&oacute;n habilitadas
      por EVO y previa liquidaci&oacute;n de obligaciones respectivas. EVO, por su parte, podr&aacute; resolverlo
      transcurridos dos meses desde que lo haya comunicado al USUARIO, salvo que &eacute;ste no sea consumidor, en cuyo
      caso el preaviso ser&aacute; de un mes.</p>
    <p>Igualmente, tal y como se ha indicado anteriormente, si el USUARIO pasa a ser cliente de EVO (i.e. d&aacute;ndose
      de alta como tal y mediante la contrataci&oacute;n de cualesquiera de los productos o servicios financieros
      ofertados por EVO en su calidad de entidad de cr&eacute;dito), los presentes T&amp;C quedar&iacute;an sin efecto
      alguno y cancelados entre el USUARIO y EVO, ya que su relaci&oacute;n jur&iacute;dico/negocial pasar&iacute;a a
      regirse por lo establecido en la documentaci&oacute;n y contratos reguladores de los productos o servicios
      contratados en cuesti&oacute;n, en los que los Servicios a Distancia asociados a los presentes T&amp;C se
      encuentran incluidos.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>FORMA DE EFECTUAR NOTIFICACIONES O COMUNICACIONES.- </strong>EVO queda autorizada para efectuar las
      comunicaciones, requerimientos y cualquier clase de notificaci&oacute;n a trav&eacute;s de la direcci&oacute;n de
      correo electr&oacute;nico, por correo ordinario o por n&uacute;mero de tel&eacute;fono m&oacute;vil que el USUARIO
      le haya proporcionado al obtener las claves secretas, teniendo en todo caso preferencia la direcci&oacute;n de
      correo electr&oacute;nico frente a las otras dos, y el n&uacute;mero de tel&eacute;fono m&oacute;vil frente al
      correo postal.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>OBLIGACIONES DE EVO.-</strong></p>
    a) Garantizar al USUARIO el secreto bancario, en los t&eacute;rminos establecidos en la normativa aplicable, de
    todas las operaciones y actuaciones relacionadas con la utilizaci&oacute;n del sistema, y espec&iacute;ficamente
    en relaci&oacute;n con las claves de acceso<br>
    b) Mantener en perfecto estado y en buen funcionamiento los sistemas de uso de los servicios a distancia<br>
    c) Actuar conforme a las indicaciones del USUARIO en cada momento, con la diligencia debida y observ&aacute;ndose
    todos los requisitos formales y de seguridad de acceso, as&iacute; como cualesquiera otros exigidos por EVO en
    virtud de regulaci&oacute;n que pueda considerarse de aplicaci&oacute;n<br>
    d) Establecer las normas de seguridad adecuadas para la protecci&oacute;n de los datos contenidos en los
    registros inform&aacute;ticos que se hubieran creado como consecuencia de la utilizaci&oacute;n del servicio a
    distancia objeto de los presentes T&amp;C<br>
    e) Establecer las medidas de seguridad adecuadas para la protecci&oacute;n del presente sistema y del Servicio a
    Distancia<br>
    <p>&nbsp;</p>
    <p><strong>OBLIGACIONES DEL USUARIO.-</strong></p>
    a) El USUARIO se compromete a guardar y se responsabiliza de la correcta utilizaci&oacute;n de las claves de
    acceso, facilitadas con car&aacute;cter estrictamente confidencial por EVO. En caso de p&eacute;rdida o
    sustracci&oacute;n de las claves o si el USUARIO tuviera dudas de que las claves secretas pudieran ser conocidas
    por terceras personas, deber&aacute; comunicarlo a EVO sin demora indebida en cuanto tuviere conocimiento de ello.
    De no producirse comunicaci&oacute;n, EVO no incurrir&aacute; en responsabilidad como consecuencia de las
    operaciones efectuadas indebidamente en la medida en que haya observado las cautelas legalmente exigibles para
    comprobar la identidad y circunstancias del USUARIO. La no exoneraci&oacute;n de responsabilidad para EVO se
    producir&aacute; a partir del acuse de recibo de la citada comunicaci&oacute;n. No cesar&aacute; la
    responsabilidad del USUARIO hasta que no obre en poder de EVO la notificaci&oacute;n de utilizaci&oacute;n
    fraudulenta, no autorizada o incorrecta, en cuyo momento EVO proceder&aacute; a anular el nombre de usuario y/o
    contrase&ntilde;a correspondiente<br>
    b) Asumir los gastos de conservaci&oacute;n, mantenimiento y reposici&oacute;n de los equipos propiedad del
    USUARIO necesarios para la utilizaci&oacute;n de los Servicios a Distancia, que deber&aacute;n reunir las
    condiciones t&eacute;cnicas suficientes para la utilizaci&oacute;n del Servicio a Distancia contratado, sin coste
    alguno para el USUARIO los derivados del uso del servicio<br>
    c) El USUARIO deber&aacute; realizar todas las operaciones seg&uacute;n las especificaciones recibidas por EVO y
    ser&aacute; responsable de la mala o incorrecta utilizaci&oacute;n de este Servicio a Distancia<br>
    d) El USUARIO responde de su buen uso de las conexiones, comprometi&eacute;ndose expresamente a evitar cualquier
    tipo de acci&oacute;n que pueda da&ntilde;ar a sistemas, equipos o servicios accesibles directa o indirectamente a
    trav&eacute;s de EVO, incluyendo la congesti&oacute;n intencionada de sistemas o enlaces, todo ello de acuerdo lo
    establecido en los presentes T&amp;C<br>
    e) Admitir cualquier modificaci&oacute;n t&eacute;cnica introducida por EVO en relaci&oacute;n con los medios a
    trav&eacute;s de los cuales presta el Servicio a Distancia, y siempre que tales modificaciones no impliquen costes
    adicionales para el USUARIO de los asumidos en el momento de contratar el Servicio a Distancia<br>
    f) Disponer de los elementos necesarios para la conexi&oacute;n, entre ellos el ordenador personal, m&oacute;dem,
    l&iacute;nea telef&oacute;nica y tel&eacute;fono m&oacute;vil<br>
    g) Aceptar cualquier modificaci&oacute;n t&eacute;cnica del Servicio a Distancia que EVO considere necesario
    introducir, procurando que no afecte a la calidad del mismo, incluido el cambio del Usuario y Contrase&ntilde;a,
    las cuales ser&aacute;n notificadas previamente al USUARIO<br>
    h) Notificar a la mayor prontitud a EVO la modificaci&oacute;n de cualesquiera circunstancias personales de las
    proporcionados al aceptar los presentes T&amp;C, siendo responsable de su veracidad y de cualesquiera
    consecuencias que se deriven de su inexactitud<br>
    i) El USUARIO no podr&aacute; anular o revocar una orden que haya realizado por medio de este Servicio a
    Distancia<br>
    j) El USUARIO est&aacute; conforme con el hecho de que el Servicio a Distancia se presta a trav&eacute;s de
    terceras entidades, principalmente la que presta el Servicio a Distancia telef&oacute;nico o electr&oacute;nico en
    cuanto se refiere a la llamada al Servicio, sin coste alguno para el USUARIO<br>
    <p>&nbsp;</p>
    <p><strong>LIMITACI&Oacute;N DE LA RESPONSABILIDAD.-</strong> Atendiendo a las caracter&iacute;sticas del servicio
      prestado, EVO no ser&aacute; responsable en los siguientes casos:</p>
    <p>1.- De los da&ntilde;os y perjuicios que pudieran ocasionarse al USUARIO o a un tercero como consecuencia de:</p>
    <p>- Deficiencias en el funcionamiento o mal uso por parte del USUARIO de los canales habilitados por EVO, salvo
      cuando se trate de deficiencias en el funcionamiento que sean directamente imputables a EVO.</p>
    <p>-Interferencias, omisiones, interrupciones, suspensiones o desconexiones en el funcionamiento de los Servicios a
      Distancia o de acceso a la red de Internet o cualquier aver&iacute;a en la red o en los sistemas
      inform&aacute;ticos utilizados.</p>
    <p>-Retrasos derivados de deficiencias o sobrecargas de la red que afecten o impidan la utilizaci&oacute;n de los
      Servicios a Distancia o que se causen por intromisiones ileg&iacute;timas de terceros.</p>
    <p>2.- Del acceso por terceras personas a informaci&oacute;n relativa al USUARIO, cuando tal acceso se produzca a
      consecuencia de actuaciones delictivas o indebidas y no est&eacute; relacionado con un posible incumplimiento por
      parte de EVO de sus obligaciones conforme a los presentes T&amp;C. En caso de p&eacute;rdida, robo o
      sustracci&oacute;n de las claves o si el USUARIO tuviera dudas de que las claves secretas pudieran ser conocidas
      por terceras personas, deber&aacute; comunicarlo a EVO sin demora indebida en cuanto tuviere conocimiento de ello.
      De no producirse comunicaci&oacute;n, EVO no incurrir&aacute; en responsabilidad como consecuencia de las
      operaciones efectuadas indebidamente en la medida en que haya observado las cautelas legalmente exigibles para
      comprobar la identidad y circunstancias del USUARIO. La no exoneraci&oacute;n de responsabilidad para EVO se
      producir&aacute; a partir del acuse de recibo de la citada comunicaci&oacute;n. No cesar&aacute; la
      responsabilidad del USUARIO hasta que no obre en poder de EVO la notificaci&oacute;n de utilizaci&oacute;n
      fraudulenta, no autorizada o incorrecta, en cuyo momento EVO proceder&aacute; a anular el nombre de usuario y/o
      contrase&ntilde;a correspondiente.</p>
    <p>3.- EVO aplicar&aacute; los medios razonables para que la informaci&oacute;n suministrada a trav&eacute;s de los
      Servicios a Distancia sea correcta y se encuentre actualizada, sin que sea responsable de los da&ntilde;os y
      perjuicios que el USUARIO o un tercero puedan sufrir como consecuencia de errores, defectos y omisiones en dicha
      informaci&oacute;n, si la misma procede del propio USUARIO o de terceros, en su caso.</p>
    <p>&nbsp;</p>
    <p><strong>JURISDICCI&Oacute;N Y COMPETENCIA.-</strong> Los presentes T&amp;C se rigen por la legislaci&oacute;n
      espa&ntilde;ola. El USUARIO y EVO se someten, para cuantas cuestiones y controversias se susciten respecto de la
      interpretaci&oacute;n, aplicaci&oacute;n, cumplimiento y ejecuci&oacute;n, as&iacute; como para el ejercicio de
      cuantas acciones y reclamaciones pudieran corresponderles a los Juzgados y Tribunales de Madrid capital. La lengua
      que regir&aacute; las relaciones comerciales de la presente relaci&oacute;n negocial ser&aacute; el castellano.
    </p>
    <p>&nbsp;</p>
    <p><strong>RECLAMACIONES Y QUEJAS.- </strong>Para la resoluci&oacute;n de cuantas
      reclamaciones o quejas pueda plantear el USUARIO, en relaci&oacute;n con la interpretaci&oacute;n,
      aplicaci&oacute;n, cumplimiento y ejecuci&oacute;n de estos T&amp;C, sin perjuicio de las acciones judiciales que
      le correspondan, podr&aacute; dirigirse al Servicio de Atenci&oacute;n al Cliente EVO, calle Don Ram&oacute;n de la Cruz,
      n&uacute; 84, 28006, Madrid, o por correo electr&oacute;nico a atencioncliente@evobanco.com, incluyendo: nombre apellidos,
      NIF, firma, direcci&oacute;n del cliente y el motivo de la misma. El Servicio de Atenci&oacute;n Cliente
      resolver&aacute; las quejas y reclamaciones recibidas en los plazos legalmente establecidos a tal efecto, que
      est&aacute;n debidamente recogidos en el Reglamento del Servicio de Atenci&oacute;n al Cliente de EVO que tiene a
      su disposici&oacute;n en su web: www.evobanco.com o solicit&aacute;ndolo por escrito o bien llamando al 902 123
      445. En &uacute;ltima instancia, si la respuesta no fuese satisfactoria o si transcurridos los plazos
      correspondientes no obtuviera respuesta, puede dirigirse a organismos p&uacute;blicos de consumo y/o Departamento
      de Conducta de Mercado y Reclamaciones del Banco de Espa&ntilde;a (Calle Alcal&aacute; 48 C.P.28014 Madrid).</p>
    <p>Del mismo modo, EVO informa al USUARIO de su posibilidad de acudir a otras entidades u organismos de
      resoluci&oacute;n alternativa de litigios sobre servicios y operaciones de pago as&iacute; como del procedimiento
      aplicable, de acuerdo con la normativa vigente en cada momento, que estar&aacute;n a disposici&oacute;n del
      USUARIO en internet: <a href="http://www.evobanco.com">www.evobanco.com</a>.</p>
    <p>EVO Banco, S.A. est&aacute; adherida a la Asociaci&oacute;n para la Autorregulaci&oacute;n de la
      Comunicaci&oacute;n Comercial (AUTOCONTROL), en caso de controversias relativas a la publicidad, podr&aacute;
      acudir al sistema de resoluci&oacute;n extrajudicial de controversias del Jurado de la Publicidad de AUTOCONTROL
      (www.autocontrol.es).</p>
    <p>&nbsp;</p>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="checkTipoA()">ACEPTAR</button>
    </div>
  </div>
</modal>

<modal modalId="faqModal" [modalTitle]="'Faq'" class="modal">
  <div class="modalContent">

    <h3 class="collapsible" (click)="estadoCollapse0 = !estadoCollapse0">
      ¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?
    </h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse0, 'collapse-none' : !estadoCollapse0}">
      <p>Ahora, disfrutar de las ventajas de tu Cuenta Inteligente es más fácil que nunca. Lo único que tienes que hacer
        es mantener 10 puntos.
        No te preocupes, es muy fácil conseguirlo ¿cómo? Solo cumpliendo con una de estas opciones tendrás esos 10
        puntos.
      </p>
      <ul>
        <li>Domiciliar tu nómina, pensión o desempleo</li>
        <li>Domiciliar 4 recibos</li>
        <li>Mantener 10.000 euros de saldo en cualquier producto EVO</li>
        <li>Domiciliar ingresos mensuales de al menos 600€</li>
        <li>Tener con nosotros una hipoteca, préstamo o tarjeta revolving</li>
        <li>Contratar 1 producto distintos a la Cuenta Inteligente y domiciliar 2 recibos</li>
        <li>Contratar 2 productos distintos a la Cuenta Inteligente</li>
      </ul>
      <br />
      <h4 class="collapsible" (click)="estadoCollapse1 = !estadoCollapse1">¿Y SI A PESAR DE TODO NO TENGO LOS 10 PUNTOS?
      </h4>
      <div [ngClass]="{'collapse-block' : estadoCollapse1, 'collapse-none' : !estadoCollapse1}">
        <p>
          No te preocupes, puedes seguir disfrutando de todos los productos, servicios y ventajas de la cuenta
          inteligente por solo 8€/mes y con una
          remuneración en la cuenta a plazo del 0,01% TIN.Condiciones exclusivas Cuenta Inteligente EVO. Cuenta a plazo
          remunerada durante 4 meses al
          0,20% TIN para cualquier importe y sin comisiones de ningún tipo. Liquidación mensual de intereses. Sin
          penalización por reintegros antici
          pados. Renovable automáticamente cada 4 meses según remuneración vigente. Para disfrutar de las ventajas de la
          Cuenta Inteligente tendrás que
          conseguir 10 puntos a través del esquema de vinculación que puedes consultar en
          www.evobanco.com/cuenta-inteligente , en el caso de no conse
          guir los 10 puntos, pasarás a la Cuenta Sin Vínculos con la que sigues manteniendo todos los productos,
          servicios y ventajas de la Cuenta In
          teligente pero con un coste por mantenimiento de 8€ al mes y una remuneración en la cuenta a plazo del 0,01%
          TIN. Ejemplos representativos
          del cálculo de la TAE bajo la hipótesis de que la remuneración se mantiene invariable durante todo el año:
          Cuenta inteligente: 9.000 euros en
          plazo al 0,20% TIN, TAE del 0,20% y Cuenta Sin Vínculos: 9.000 euros en plazo al 0,01% TIN y comisión
          mantenimiento 8€/mes, TAE del -1,056%.
          Debes saber que aunque EVO no cobre nada por retirar dinero a débito en el extranjero, algunas entidades
          propietarias de cajeros pueden aplicar
          una tasa de recargo por su utilización. Debes mantener todos los productos gratuitos que integran la Cuenta
          Inteligente EVO (Cuenta corriente,
          Cuenta a plazo, Tarjeta Inteligente, Banca a Distancia y Servicio de Alertas). Puedes disponer de un máximo de
          dos Tarjetas Inteligentes bonificadas.
          Puedes cancelar tu Cuenta Inteligente sin coste de cancelación.

        </p>
      </div>
    </div>
  </div>

  <div class="modalContent">

  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse2 = !estadoCollapse2">¿HAY COMISIONES DE MANTENIMIENTO?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse2, 'collapse-none' : !estadoCollapse2}">
      <p>
        Olvídate de las comisiones. La Cuenta Inteligente no tiene ninguna. Y por supuesto, con tu dinero siempre
        disponible. Tiene tarjetas de Débito y
        Crédito GRATIS, sin comisión de emisión ni renovación. Puedes sacar dinero gratis de cualquier cajero de España
        si retiras 120€ o más. Sea del
        color que sea. Y para retiradas gratis sin cantidad mínima y sin límite de operaciones, tienes a tu disposición
        una de las mayores redes de cajeros
        de España, formada por los cajeros EVO, la red EURO6000 (excepto CaixaBank). Kutxabank, Ibercaja, Grupo
        Liberbank, Abanca, Unicaja, Grupo Caja Rural
        y Grupo Cooperativo Cajamar, cajeros de Banco Popular y EAc, Banco Pastor y Targo Bank, Bankinter y Caja de
        Ingenieros. . ¡Son más de 16.000!
        Y con muchos más servicios gratis: transferencias y traspasos nacionales y comunitarios en euros, ingreso de
        cheques nacionales, Banca Electrónica,
        alertas a móvil...
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse3 = !estadoCollapse3">VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR
      DINERO SIN COMISIONES?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse3, 'collapse-none' : !estadoCollapse3}">
      <p>
        Sí, las tarjetas EVO te acompañan allí donde vayas.
        Con tu Tarjeta de Débito (la negra) o con la Tarjeta Inteligente, EVO no te cobra ni un céntimo por retirar
        dinero a débito de cajeros en el extranjero.
        Puedes hacerlo todas las veces que quieras y sin cantidad mínima. Estés en el país que estés. Eso sí, es posible
        que, aunque EVO no te cobre ninguna comisión
        por sacar dinero con la Tarjeta de Débito o la Tarjeta Inteligente, fuera de la Unión Europea las entidades
        propietarias de los cajeros pueden aplicar una tasa
        de recargo por su utilización. En todo caso, y para tu tranquilidad (y la nuestra) es obligatorio que, para que
        puedan cobrarte esta comisión, el cajero te
        avise por pantalla y te especifique cuánto te va a costar. Si quieres, podrás cancelar la operación, porque no
        todos los cajeros cobran esta tasa e incluso
        puede no ser siempre la misma.
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse4 = !estadoCollapse4">¿PUEDO AÑADIR UN SEGUNDO TITULAR?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse4, 'collapse-none' : !estadoCollapse4}">
      <p>
        ¡No hay problema! Porque la Cuenta Inteligente admite dos titulares. Eso sí, cada cliente, solo podrá ser
        titular de una única Cuenta Inteligente EVO, así
        que tú decides: solo/a, o con pareja.
      </p>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<div *ngIf="modalBureau" class="modal-backdrop" id="backdropId">
  <div class="modal-container" (click)="modalClick($event)">
    <div class="image-modal"></div>
    <span class="evo close ko cross" (click)='closeModal()'></span>
    <div class="content" style="">
      <h2>¡VAYA!</h2>
      <p class="invalid-text">
        Lo sentimos, no podemos seguir con la solicitud online. Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te explicamos los motivos.</p>
    </div>
  </div>
</div>

<div class="personalDetails">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <div class="row">
          <h2>COMUNIDAD EVO LOVERS</h2>
          <span *ngIf="esCuentaInteligente()">
          </span>
        </div>
        <p>Para disfrutar de las ventajas de EVO necesitamos saber tus datos personales.</p>
      </legend>
    </div>
    <form [formGroup]="personalDetailsForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()"
      autocomplete="off" novalidate>

      <div class="row">
        <div class="mobile-container">
          <input type="text" id="dni" name="dni" autocomplete="off" placeholder="DNI/NIE"
            formControlName="numeroDocumento" maxlength="9">
          <div class="error-text"
            *ngIf="errService.desktopErrors(personalDetailsForm.get('numeroDocumento'), 'numeroDocumento')">
            {{errService.desktopErrors(personalDetailsForm.get('numeroDocumento'), 'numeroDocumento')}}
          </div>
        </div>
        <div class="mobile-container">
          <input type="text" id="nombre" name="nombre" autocomplete="off" placeholder="Nombre" formControlName="nombre">
          <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('nombre'), 'nombre')">
            {{errService.desktopErrors(personalDetailsForm.get('nombre'), 'nombreCI')}}
          </div>
        </div>
        <div class="mobile-container">
          <input type="text" id="primerApellido" name="primerApellido" autocomplete="off" placeholder="Primer apellido"
            formControlName="primerApellido">
          <div class="error-text"
            *ngIf="errService.desktopErrors(personalDetailsForm.get('primerApellido'), 'primerApellido')">
            {{errService.desktopErrors(personalDetailsForm.get('primerApellido'), 'primerApellido')}}
          </div>
        </div>
      </div>
      <div class="row">

      </div>
      <div class="row">
        <div class="mobile-container">
          <input type="text" id="segundoApellido" name="segundoApellido" autocomplete="off"
            placeholder="Segundo apellido" formControlName="segundoApellido">
          <div class="error-text"
            *ngIf="errService.desktopErrors(personalDetailsForm.get('segundoApellido'), 'segundoApellido')">
            {{errService.desktopErrors(personalDetailsForm.get('segundoApellido'), 'segundoApellido')}}
          </div>
        </div>
        <div class="mobile-container">
          <input type="text" id="email" name="email" autocomplete="off" placeholder="Email" formControlName="email">
          <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('email'), 'email')">
            {{errService.desktopErrors(personalDetailsForm.get('email'), 'email')}}
          </div>
        </div>
        <div class="mobile-container">
          <input type="text" id="telefonoMovil" no-whitespace name="telefonoMovil" required maxlength="9" number-input
            inputmode="numeric" placeholder="Teléfono móvil" formControlName="telefonoMovil" autocomplete="off">
          <div class="error-text"
            *ngIf="errService.desktopErrors(personalDetailsForm.get('telefonoMovil'), 'telefonoMovil')">
            {{errService.desktopErrors(personalDetailsForm.get('telefonoMovil'), 'telefonoMovil')}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mobile-container">
          <input type="password" id="password" name="password" autocomplete="off" maxlength="6" number-input
            inputmode="numeric" placeholder="Contraseña" formControlName="password">
          <div class="help-text">
            La contraseña tiene que tener 6 cifras
          </div>
          <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.get('password'), 'password')">
            {{errService.desktopErrors(personalDetailsForm.get('password'), 'password')}}
          </div>
        </div>
        <div class="mobile-container">
          <input type="password" id="confirmPassword" name="confirmPassword" autocomplete="off" maxlength="6"
            number-input inputmode="numeric" placeholder="Repite contraseña" formControlName="confirmPassword">
          <div class="help-text">
            Repite la contraseña con 6 cifras
          </div>
          <div class="error-text"
            *ngIf="errService.desktopErrors(personalDetailsForm.get('confirmPassword'), 'confirmPassword')">
            {{errService.desktopErrors(personalDetailsForm.get('confirmPassword'), 'confirmPassword')}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="checkbox-group first">
          <div class="form-checkbox" (click)="modalOpenPrivacidad($event)">
            <input type="checkbox" name="check_privacidad" id="check_privacidad" value="Acepto"
              formControlName="reactiveCheckLegal">
            <label for="check_privacidad" (keypress)="selectCheckLegal($event)" tabindex="1">He leido y acepto el
              <a>Régimen de Tratamiento de Datos de Carácter Personal
              </a></label>
              <div class="error-text" *ngIf="personalDetailsForm.get('reactiveCheckLegal').hasError('notChecked')">
                {{errors.regimenTratamientoDatos}}
              </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox" (click)="modalOpenTipoA($event)">
            <input type="checkbox" name="check_conditions" id="check_conditions" value="Acepto"
              formControlName="reactiveCheckTipoA">
            <label for="check_conditions" (keypress)="selectCheckTipoA($event)" tabindex="1">He leido y acepto los
              <a>Términos y Condiciones
              </a></label>
              <div class="error-text" *ngIf="personalDetailsForm.get('reactiveCheckTipoA').hasError('notChecked')">
                {{errors.terminosCondiciones}}
              </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input type="checkbox" name="check_publicidad" id="check_publicidad" value="Acepto"
              formControlName="reactiveCheckPublicidad">
            <label id="check_publicity_label" for="check_publicidad" (keypress)="selectCheckPublicidad($event)"
              tabindex="3">
              Consiento que mis datos personales sean utilizados para que EVO Banco pueda ponerse en contacto conmigo
              a través de cualquier canal (incluido los electrónicos) para informarme sobre otros productos o servicios
              que no tengan similitud alguna con los que tengo con ustedes, que sean ofrecidos por Evo,
              empresas del Grupo Bankinter y terceras empresas con las que haya suscrito acuerdos de colaboración.
            </label>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input type="checkbox" name="check_prefilado" id="check_prefilado" value="Acepto"
              formControlName="reactiveCheckSolvencia">
            <label id="check_publicity_label" for="check_prefilado" (keypress)="selectCheckConsentimiento($event)"
              tabindex="2">
              Consiento que mis datos personales sean utilizados para que EVO Banco puedan acceder e incorporar
              en sus ficheros datos accesibles al público o de terceras empresas para elaborar perfiles y personalizar
              ofertas y adaptarlas a mis necesidades, gustos y preferencias.
            </label>
          </div>
        </div>
      </div>

      <br />
      <div class="btn-group">
        <button type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
      </div>
    </form>
  </div>
</div>
