import { Component, OnInit } from '@angular/core';
import { Persona } from 'app/collections/persona';
import { LoadingComponent } from 'app/components/loading/loading.component';
import { StepsCiComponent } from 'app/components/steps-ci/steps-ci.component';
import { AuthService } from 'app/services/auth.service';
import { DeviceService } from 'app/services/device.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { environment } from 'environments/environment.dev';

@Component({
  selector: 'app-register-nie-screen',
  templateUrl: './register-nie-screen.component.html',
  styleUrls: ['./register-nie-screen.component.scss'],
  providers: [
    ModalService,
    LoadingComponent,
    StepsCiComponent
  ]
})
export class RegisterNieScreenComponent implements OnInit {
  loading: boolean = false;
  private persona: Persona = this.authService.getSessionProspect();
  msgSrvError: string = "";
  modalSrvError: string = "modal-srv-error";

  constructor(
    private helpers: HelperService,
    private authService: AuthService,
    private specificAnalytics: SpecificAnalyticsService,
    public deviceService: DeviceService,
    public errService: ErrorService,
    public modService: ModalService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.setTipoIdentificacionProspec();
    this.sendToIdentification();
  }

  setTipoIdentificacionProspec() {
    const canal = this.deviceService.getDevice();
    this.specificAnalytics.emitIdentificationType("video");
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
      },
      otrosDatos: {
        tipoIdentificacion:"identificacion-video",
      },
    });
  }

  sendToIdentification() {
    this.loading = true;
    if (JSON.parse(sessionStorage.getItem("discriminatorInfo"))) {
      let discriminator = JSON.parse(
        sessionStorage.getItem("discriminatorInfo")
      );
      this.persona.discriminador = discriminator;
    }
    this.authService.updateProspect(this.persona).subscribe(
      (data) => {
        if (data.response.codigo === "KO") {
          this.msgSrvError = !!data.response.message
            ? data.response.message
            : "Error genérico";
          this.modService.open(this.modalSrvError);
          return;
        }
        let nextStep = "identificacion-video"
        this.loading = false;
        this.helpers.navigateTo(nextStep);
      },
      (error) => {
        this.error();
      }
    );
  }

  error() {
    this.errService.navToError();
  }
}
