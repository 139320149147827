<modal [modalId]='alreadyClientModal' [blocking]='true' class='modal modal-login'>
  <mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray" class="custom-erros">
  </mobile-error-zone>
  <div class="container login">
    <h2>¿Ya eres cliente?</h2>
    <p>
      Accede con tus datos a tu área personal.
    </p>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="equal-buttons">
        <input placeholder="Usuario (NIF/NIE)" autocomplete="off" type="text" name="user" formControlName="user" autofocus
          maxlength="9" 
          (focus)="closeKeyboard()"
          (blur)="validUser()">
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('user'),'user')">
            {{errService.desktopErrors(loginUser.get('user'),'user')}}
          </div>
          <input keyboard placeholder="Contraseña" autocomplete="off" type="password" name="pass" formControlName="pass"
            (numberEmmited)="numberEmmited($event)" (deleteLast)="deleteLast($event)" onfocus="blur()">
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.get('pass'),'pass')">
            {{errService.desktopErrors(loginUser.get('pass'),'pass')}}
          </div>
      </div>
      <div class="row">
        <button class="btn-common btn-common_fix_bold" type="submit" (click)="closeKeyboard()">
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class='label'>
        <b>¿No tienes claves para entrar?</b> Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>
<app-header></app-header>
<div class="everything-first-sign">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="main-icon evo ya-esta"></span>
        <h2>YA está</h2>
        <p class="constrain big-letter">TU CUENTA INTELIGENTE YA TIENE UN SEGUNDO TITULAR.</p>
        <p class="constrain margin-height">A partir de ahora puedes ver tus datos actualizados en tu aréa personal.</p>
      </legend>
    </div>
    <!-- <div class="row">
      <div class="mobile-container center">
        <button class='btn-common tall' (click)='goToProfile()'>
          ÁREA PERSONAL
        </button>
      </div>
    </div> -->
  </div>
</div>
