<div class="image-list">
  <div class="column">
    <img src="assets/images/icon-lock-feel/ic_cara_sonrisa-inactive.svg" alt="Imagen 1">
  </div>
  <div class="column">
    <img src="assets/images/icon-lock-feel/ic-brujula-inactive.svg" alt="Imagen 2">
  </div>
  <div class="column">
    <img src="assets/images/icon-lock-feel/ic_maletin-inactive.svg" alt="Imagen 3">
  </div>
  <div class="column">
    <img src="assets/images/icon-lock-feel/ic_huella_dactilar-inactive.svg" alt="Imagen 3">
  </div>
</div>