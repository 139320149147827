import { Component, HostListener } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'app/services/login.service';
import { AuthUserService } from 'app/services/auth-user.service';
// import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { AuthService } from 'app/services/auth.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { InjectScriptService } from '../../../../services/inject-script.service';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'tarificador-view',
  templateUrl: './tarificador.view.component.html',
  styleUrls: ['./tarificador.view.component.scss'],
  providers: [ModalService]
})
export class TarificadorView {
  public msgSrvError = '';
  public msgErrorAlreadyRegistryPolicy = 'Ya estás adherido a EVO Protect Deportes.';
  public msgErrorNotAdult = 'La edad para contratar EVO Protect es de 18 a 65 años.';
  public modalLogin = 'modalLogin';
  public modalError = 'modalError';
  public navigate = "";
  public loginUser: FormGroup;
  public loading = false;
  private passStr = "";
  public errMsg;
  public firstTouch: boolean = true;
  public firstTouchPrevented: boolean = true;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    return $event.returnValue = '¿Estás seguro?';
  }

  constructor(
    private modService: ModalService,
    private helpers: HelperService,
    private fb: FormBuilder,
    private loginSrv: LoginService,
    private authUserService: AuthUserService,
    private authservice: AuthService,
    private analytics: AnalyticsService,
    // public specificAnalytics: SpecificAnalyticsService,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    private inject: InjectScriptService
  ) {
    this.createFormLogin();
  }

  sendClient() {
    this.analytics.addTarificationEvent(4);

    this.errMsg = undefined;
    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');

    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileTarificationFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };
    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;

    this.loading = true;

    this.loginSrv.getLogin(login).subscribe(data => {
      this.authUserService.getUserData().subscribe(data => {
        this.loading = false;
        this.loginSrv.userData = data;
        this.authservice.product = ACCOUNTS.MSCode;
        this.loading = true;
        this.authservice.loadPolicies().subscribe(data => {
          this.loading = false;
          if (data != null && data.error != null) {
            this.msgSrvError = data.error;
            this.analytics.triggerProfileTarificationFormData('Log in', `Envio KO: ${this.msgSrvError}`);
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgSrvError);
            this.modService.open(this.modalError);
            return ;
          }
          if (this.loginSrv.listPolicies == null) {
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.modalLogin);
            this.modService.close(this.modalLogin);
            if (!this.helpers.isAdultNot65Years(this.loginSrv.userData.persona.fechaNacimiento)) {
              this.showErrorAdultModal();
              return ;
            }
            this.analytics.triggerProfileTarificationFormData('Log in', 'Envio OK');
            this.helpers.navigateTo('/evo-protect');
          } else {
            this.logoutAndPolicyAlready();
          }
        });
      }, err => {
        this.loading = false;
        this.error();
      })
    }, err => {
      this.loading = false;
      this.analytics.triggerProfileTarificationFormData('Log in', 'Envio KO: 500 Server error');
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
                     correctamente los datos. Recuerda que por seguridad,
                     tres errores consecutivos en la contraseña bloquearán el acceso`;
      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    })
  }

  showErrorAdultModal() {
    this.msgSrvError = this.msgErrorNotAdult;
    this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
    this.modService.open(this.modalError);
    this.modService.onclose.subscribe(() => {
      this.helpers.navigateTo('/perfil');
    });
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.get('user').valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass',[Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = '';
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  logoutAndPolicyAlready() {
    this.authservice.logout();
    this.msgSrvError = this.msgErrorAlreadyRegistryPolicy;
    this.analytics.triggerProfileTarificationFormData('Log in', `Envio KO: ${this.msgSrvError}`);
    this.analytics.triggerErrorAction(this.analytics.globalTarificationError, this.msgSrvError);
    this.modService.open(this.modalError);
  }

  error() {
    this.analytics.triggerTarificationNotificationAction('Descartar notificación',this.modalLogin);
    this.modService.close(this.modalLogin);
    this.errService.navToError();
  }
  // Modal
  openModal(modalId) {
    //this.analytics.triggerTarificationNotificationAction('Mostrar notificación', modalId);
    this.modService.open(modalId);
  }

  newClient() {
    this.analytics.addTarificationEvent(5);
    window.open('https://www.evobanco.com/cuenta-inteligente/');
  }

  eventHandler(e) {
    if (!this.loginSrv.isLoggedIn) {
      this.openModal(e);
    } else {
      if(!this.helpers.isAdultNot65Years(this.loginSrv.userData.persona.fechaNacimiento)) {
        this.showErrorAdultModal();
        return ;
      }
      this.authservice.product = ACCOUNTS.MSCode;
      this.helpers.navigateTo('/evo-protect');
    }
  }

}
