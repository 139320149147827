import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

  public static OneMinuteMillis = 60000;

  // Posibles respuestas del servicio de validación de cliente
  public static RespuestasValidation = {
    UsuarioNoEncontrado: 'KO', // Ok, Usuario no encontrado
    UsuarioEncontrado: 'OK', // Ok, Usuario encontrado
  };

  // TODO Posibles respuestas del servicio de verificación de iban - acepta transferencias inmediatas
  public static ImmediateTranfersResponseCode = {
    IndicadorTransferenciaInmediata: 'I'
  };

  public static VerifyImportResponseCode = {
    ValidValue: '01', // Ok, Valor correcto
    InvalidValue: '02', // Ok, Valor incorrecto
    TooManyRetries: '03', // Ok, Número de reintentos máximo alcanzado
    NoPendingValidation: '04', // Ok, cliente sin validación pendiente
  };
}
