<loading [showMe]="loading"></loading>


<modal [modalTitle]="'¡Solo te falta verificar la transferencia!'" [blocking]='false' [modalId]='modalImporte'>
  <div class="container modal-importe">
    <a  class="close"></a>
    <i class="ic-warning-pink"></i>
    <h4 class="title">¡Solo te falta verificar la transferencia!</h4>
    <p>
      Introduce el importe transferido a tu cuenta y habrás finalizado el proceso de contratación de la Cuenta Inteligente.
    </p>


    <div class="btn-group">
      <button class="btn-common" (click)="closeModalImporteEntendido()"><span class="text-button">Entendido</span></button>
    </div>



  </div>
</modal>

<!-- TODO Modal error en el servicio -->
<modal [modalTitle]="'¡Oh-Oh!'" [blocking]='false' [modalId]='modal_ko'>
  <div class="container modal-importe">
    <a  class="close"></a>
    <i class="ic-warning-pink"></i>
    <h4 class="title">¡Oh-Oh!</h4>
    <p>
      <strong>Parece que algo está fallando.</strong>
    </p>
    <p>
      Inténtalo de nuevo
    </p>
    <div class="btn-group">
      <button class="btn-common" (click)="validateImport()"><span class="text-button">Reintentar</span></button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'¡Vaya!'" [blocking]='false' [modalId]='modalVaya'>
  <div class="container modal-importe">
    <a  class="close"></a>
    <i class="ic-warning-pink"></i>
    <h4 class="title">¡Vaya!</h4>
    <p>
      <strong>Parece que estás teniendo problemas</strong> para validar el importe transferido.
    </p>
    <p>
      {{isSecondSigner ? textModalVayaTwoHolders : textModalVaya}}
    </p>

    <div class="btn-group">
      <button class="btn-common" (click)="closeModalVaya()"><span class="text-button"> {{isSecondSigner ? 'Entendido' : 'Prueba de nuevo'}} </span></button>
    </div>

  </div>
</modal>




<div class="main-register flex-direction">
  <box-image  [classImgFile]="classImgFile"
              [icon]="icon"
              [titleCss]="'title-2 '"
              [subtitleCss]="'subtitle-2'"
              [title]="titleBox"
              [subtitle]="subtitle"
              class="image-div"></box-image>
  <div class="form-and-step-div">
    <steps-ci class="step-component"></steps-ci>
    <hr class="line-separator not-hidden-movil">
    <div class="form-div">

      <p class="title"> Verificación del importe</p>

      <p>Para finalizar el proceso de contratación, introduce el importe transferido desde EVO Banco a la cuenta <strong>{{iban}}.</strong>   </p>

      <form  [formGroup]='identifyIbanConfirmForm' class="flex-form form-importe" >

        <p [ngClass]="{'input-import-error': invalidImport}">Importe transferencia</p>
        <div class="input-group-importe">
          <input type="tel" id="importe_entero" formControlName="importe_entero" number-input [appAutoTab]="'importe_decima'" [ngClass]="{'input-import-error': invalidImport}" maxlength='1' required autocomplete="off">
          <span> ,</span>
          <input type="tel" id="importe_decima" formControlName="importe_decima" number-input [appAutoTab]="'importe_centesima'" [ngClass]="{'input-import-error': invalidImport}" maxlength='1' required autocomplete="off">
          <input type="tel" id="importe_centesima" formControlName="importe_centesima" number-input [ngClass]="{'input-import-error': invalidImport}" maxlength='1' required autocomplete="off">
          <span>€</span>
        </div>
        <p *ngIf="showErrorImporte" class="input-import-error">¡Vaya! Parece que el importe introducido no es correcto. Asegúrate de que incluyes el importe exacto que te hemos enviado.</p>
      </form>

      <div>
        <hr class="line-separator hidden-movil">
        <button class="btn-common btn-submit" (click)="validateImport()" [disabled]='invalidImport'>VALIDAR</button>
      </div>

    </div>

  </div>

</div>
