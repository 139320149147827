import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConsultaTipoTransferenciaResponse, GenericResponse, UserValidationResponse } from "app/collections/iberpay";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const API_URL = environment.apiUrl;

@Injectable()
export class ApiIberpayService {
    options = {};
    constructor(private http: HttpClient) {
        this.options = { withCredentials: true, observe: 'response' };
    }

    public validateUser(idSfc: string): Observable<UserValidationResponse> {
        return this.http
            .get(`${API_URL}/evobanco/customer-locks/v1/blacklist?concept=1&value=${idSfc}&reason=1`,
                this.options
            )
            .pipe(
                map(response => {
                    return response['body'];
                })
            )
    }

    public verifyIban(iban: string): Observable<ConsultaTipoTransferenciaResponse> {
        return this.http
            .get(`${API_URL}/evobanco/payment-methods/entity-payments/iberpay/v1/transferencias-esinmediata` +
                `?codigoEntidad=0239&ibanBeneficiario=${iban}&importeNominal=1`,
                this.options
            )
            .pipe(
                map(response => {
                    return response['body'];
                })
            )
    }

    public verifyAmount(idUser: string, amount: string): Observable<GenericResponse> {
        return this.http
            .post(`${API_URL}/fullonline/salesforce/validacionTransferencia/${idUser}`,
                { importe: amount },
                this.options
            )
            .pipe(
                map(response => {
                    return response['body'];
                })
            )
    }
}
